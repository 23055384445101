import { loginedApi, notLoginedApi } from './api-base';

export const eduApi = {
  getEdu: (token: any, subMenu: string, type: string, holder: string) =>
    notLoginedApi.get('/edu', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
      params: {
        subMenu,
        type,
        holder,
      },
    }),
  getEduWithSubTab: (token: any, subMenu: string, type: string, holder: string, subTab: string) =>
    notLoginedApi.get('/edu', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
      params: {
        subMenu,
        type,
        holder,
        subTab,
      },
    }),
  getEduWithStep: (
    token: any,
    subMenu: string,
    type: string,
    holder: string,
    currentStep: string,
  ) =>
    notLoginedApi.get('/edu', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
      params: {
        subMenu,
        type,
        holder,
        currentStep,
      },
    }),
  getEduWithSubTabAndStep: (
    token: any,
    subMenu: string,
    type: string,
    holder: string,
    subTab: string,
    currentStep: string,
  ) =>
    notLoginedApi.get('/edu', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
      params: {
        subMenu,
        type,
        holder,
        subTab,
        currentStep,
      },
    }),
  deleteEdu: (token: any, eduId: Number) =>
    loginedApi.delete('/edu', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
      params: {
        eduId,
      },
    }),
  createEdu: (token: any, Data: FormData) =>
    loginedApi.post('/edu', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
  createBulkEdu: (token: any, Data: FormData) =>
    loginedApi.post('/edu/bulk', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
  updateVisible: (token: any, Data: FormData) =>
    loginedApi.post('/edu/updateVisible', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  updateEduPictures: (token: any, Data: FormData) =>
    loginedApi.post('/edu/updateEduPictures', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  getEduPictures: (token: any, Data: FormData) =>
    loginedApi.post('/edu/getEduPictures', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
};

import React from 'react';

import * as util from '../../util/util';
import * as s from './ActivityStyled';
import NotFound from '../../components/notFound';

import { saveAs } from 'file-saver';
interface ActivityProps {
  ageList: any;
  subMenuList: string[];
  selectedAge: {
    comnCd: string;
    comnCdNm: string;
  };
  setSelectedAge: React.Dispatch<
    React.SetStateAction<{
      comnCd: string;
      comnCdNm: string;
    }>
  >;
  selectedSubMenuList: any;
  setSelectedSubMenuList: any;
  onChangeAge: React.MouseEventHandler<HTMLLIElement>;
  onChangeSubMenuList: (item: any) => void;
  programList: any;
  setProgramId: any;
  unableDownload: React.MouseEventHandler<HTMLDivElement>;
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  pageList: never[];
  lastPageNum: number;
  isFirst: boolean;
  isLast: boolean;
  holderList: never[];
  selectedHolderList: {
    comnCd: string;
    comnCdNm: string;
  }[];
  onChangeHolderList: (item: any) => void;
  selectedMenu: any;
  setSelectedMenu: React.Dispatch<any>;
  infantCdList: string[];
  childCdList: string[];
}

const ActivityPresenter = (props: ActivityProps) => {
  return (
    <s.Container>
      <s.ActivityHeader>
        <s.ActivityHeaderTitle>활동프로그램</s.ActivityHeaderTitle>
        <s.ActivityHeaderRightArea>
          <s.ActivityHeaderRightText>
            <s.ActivityHeaderRightIcon src="/static/img/home_icon.jpg" />
            {'HOME > 활동프로그램'}
          </s.ActivityHeaderRightText>
        </s.ActivityHeaderRightArea>
      </s.ActivityHeader>


      <s.SearchConditionArea>
        <s.SearchConditionAgeArea>
          <s.searchConditionHeader>연령 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            {
              props.ageList.map((item: any, index: number) => (
                <s.SearchConditionItem 
                  selected={item.comnCd === props.selectedAge.comnCd}
                  onClick={() => props.onChangeAge(item)}
                  >{'만 ' + item.comnCdNm}</s.SearchConditionItem>
              ))
            }
          </s.SearchConditionBody>
        </s.SearchConditionAgeArea>
        <s.SearchConditionMonthArea>
          <s.searchConditionHeader>월 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            <s.SearchConditionItem
              selected={props.selectedHolderList.length === 0}
              onClick={() => props.onChangeHolderList(null)}
            >
              전체
            </s.SearchConditionItem>
            {
              props.holderList.map((item: any, index: number) => (
                <s.SearchConditionItem 
                  selected={props.selectedHolderList
                    .map((item: any) => item.comnCd)
                    .includes(item.comnCd)}
                  onClick={() => props.onChangeHolderList(item)}
                  >{item.comnCdNm}</s.SearchConditionItem>
              ))
            }
          </s.SearchConditionBody>
        </s.SearchConditionMonthArea>
        <s.SearchConditionMediaArea>
          <s.searchConditionHeader>놀이 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            <s.SearchConditionItem
              selected={props.selectedSubMenuList.length === 0}
              onClick={() => props.onChangeSubMenuList(null)}
            >
              전체
            </s.SearchConditionItem>
            {
              props.subMenuList
              .filter((item: any) =>
                props.selectedAge.comnCd === 'AUTH0101' || props.selectedAge.comnCd === 'AUTH0102'
                  ? props.infantCdList.includes(item.menuCd)
                  : props.childCdList.includes(item.menuCd),
              )
              .map((item: any, index: number) => (
                <s.SearchConditionItem 
                  key={'subMenu' + index}
                  selected={props.selectedSubMenuList
                    .map((item: any) => item.menuCd)
                    .includes(item.menuCd)}
                  onClick={() => props.onChangeSubMenuList(item)}
                  >{item.menuNm}</s.SearchConditionItem>
              ))
            }
          </s.SearchConditionBody>
        </s.SearchConditionMediaArea>
      </s.SearchConditionArea>





      {/* <s.ActivityTabArea>
        {props.ageList.map((item: any, index: number) => (
          <s.ActivityTab
            key={'age' + index}
            active={item.comnCd === props.selectedAge.comnCd}
            onClick={() => props.onChangeAge(item)}
          >
            {'만 ' + item.comnCdNm}
          </s.ActivityTab>
        ))}
      </s.ActivityTabArea> */}
      {/* <s.SelectArea>
        <s.SelectLabel>월(호) 선택</s.SelectLabel>
        <s.SelectDiv></s.SelectDiv>
        <s.SelectForm selectType={'holder'}>
          <s.SelectItem
            key={'holder99'}
            active={props.selectedHolderList.length === 0}
            selectType={'holder'}
            onClick={() => props.onChangeHolderList(null)}
          >
            전체
          </s.SelectItem>
          {props.holderList.map((item: any, index: number) => (
            <s.SelectItem
              key={'holder' + index}
              active={props.selectedHolderList
                .map((item: any) => item.comnCd)
                .includes(item.comnCd)}
              selectType={'holder'}
              onClick={() => props.onChangeHolderList(item)}
            >
              {item.comnCdNm}
            </s.SelectItem>
          ))}
        </s.SelectForm>
      </s.SelectArea>
      <s.SelectArea>
        <s.SelectLabel>놀이 선택</s.SelectLabel>
        <s.SelectDiv></s.SelectDiv>
        <s.SelectForm selectType={'activity'}>
          <s.SelectItem
            key={'subMenu99'}
            active={props.selectedSubMenuList.length === 0}
            selectType={'field'}
            onClick={() => props.onChangeSubMenuList(null)}
          >
            전체
          </s.SelectItem>
          {props.subMenuList
            .filter((item: any) =>
              props.selectedAge.comnCd === 'AUTH0101' || props.selectedAge.comnCd === 'AUTH0102'
                ? props.infantCdList.includes(item.menuCd)
                : props.childCdList.includes(item.menuCd),
            )
            .map((item: any, index: number) => (
              <s.SelectItem
                key={'subMenu' + index}
                active={props.selectedSubMenuList
                  .map((item: any) => item.menuCd)
                  .includes(item.menuCd)}
                selectType={'field'}
                onClick={() => props.onChangeSubMenuList(item)}
              >
                {item.menuNm}
              </s.SelectItem>
            ))}
        </s.SelectForm>
      </s.SelectArea> */}




      
      <s.ProgramListArea>
        <s.ProgramTypeTitleArea>
          <s.ProgramTypeTitleFixedPart>만</s.ProgramTypeTitleFixedPart>
          <s.ProgramTypeTitlePart>{props.selectedAge.comnCdNm}</s.ProgramTypeTitlePart>
          {props.selectedHolderList.map((item: any, index: number) => (
            <s.ProgramTypeTitlePart key={'Holder' + index}>{item.comnCdNm}</s.ProgramTypeTitlePart>
          ))}
          {props.selectedSubMenuList.map((item: any, index: number) => (
            <s.ProgramTypeTitlePart key={'Type' + index}>{item.menuNm}</s.ProgramTypeTitlePart>
          ))}
          <s.ProgramTypeTitleFixedPart>프로그램</s.ProgramTypeTitleFixedPart>
          <s.ProgramSearchTextArea>
            <s.ProgramSearchText type="text" name="keyword" />
            <s.ProgramSearchButton>
              <s.ProgramSearchImg src="/static/img/lens_2.png" />
            </s.ProgramSearchButton>
          </s.ProgramSearchTextArea>
        </s.ProgramTypeTitleArea>
        <s.ProgramOptionArea>
          <s.ProgramOptionLeft>
            <s.ProgramFieldList>
              <s.ProgramFieldListItem
                current={props.selectedMenu === null}
                onClick={() => props.setSelectedMenu(null)}
              >
                전체
                <s.ProgramFieldListItemImg
                  src={
                    props.selectedMenu === null
                      ? 'https://storage.googleapis.com/habicastle_storage/static/icon/check_1.png'
                      : 'https://storage.googleapis.com/habicastle_storage/static/icon/check_2.png'
                  }
                />
              </s.ProgramFieldListItem>
              {[
                { MenuNm: '활동지', MenuCd: 'MN0401' },
                { MenuNm: '멀티미디어', MenuCd: 'MN05' },
                { MenuNm: '동요/음원', MenuCd: 'MN0402' },
                { MenuNm: '문서/서식', MenuCd: 'MN0403' },
                { MenuNm: '지침서', MenuCd: 'MN0406' },
              ].map((item) => (
                <s.ProgramFieldListItem
                  current={props.selectedMenu === item.MenuCd}
                  onClick={() => props.setSelectedMenu(item.MenuCd)}
                >
                  {item.MenuNm}
                  <s.ProgramFieldListItemImg
                    src={
                      props.selectedMenu === item.MenuCd
                        ? 'https://storage.googleapis.com/habicastle_storage/static/icon/check_1.png'
                        : 'https://storage.googleapis.com/habicastle_storage/static/icon/check_2.png'
                    }
                  />
                </s.ProgramFieldListItem>
              ))}
            </s.ProgramFieldList>
          </s.ProgramOptionLeft>
          <s.ProgramOptionRight>
            이름순
            <s.ProgramOptionImg src={'/static/img/d_shaft.png'} />
          </s.ProgramOptionRight>
        </s.ProgramOptionArea>
        <s.ProgramList>
          {!props.programList || props.programList.length === 0 ? (
            <NotFound />
          ) : (
            props.programList.map((item: any, index: number) => (
              <s.ProgramForm key={'program' + index} idx={index}>
                <s.ProgramThumbnailArea
                  onClick={() =>
                    !item.programId
                      ? util.makeMsg('권한이 부족합니다.', 'error')
                      : props.setProgramId(item.programId)
                  }
                >
                  {item.thumbnail ? (
                    <s.ProgramThumbnail imgsrc={item.thumbnail.fileUrl}>
                      <s.ProgramDarkness>
                        <s.ProgramLensImg src={'/static/img/lens.png'} />
                      </s.ProgramDarkness>
                    </s.ProgramThumbnail>
                  ) : (
                    <s.ProgramThumbnail imgsrc={'/static/img/lens.png'}>
                      <s.ProgramDarkness>
                        <s.ProgramLensImg src={'/static/img/lens.png'} />
                      </s.ProgramDarkness>
                    </s.ProgramThumbnail>
                  )}
                </s.ProgramThumbnailArea>
                <s.ProgramTitle>{item.title}</s.ProgramTitle>
                <s.ProgramLinkArea>
                  <s.ProgramScrapLinkArea
                    onClick={() => util.makeMsg('준비중인 기능입니다.', 'info')}
                  >
                    <s.ProgramScrapLink>스크랩</s.ProgramScrapLink>
                  </s.ProgramScrapLinkArea>
                  <s.ProgramDownloadLinkArea>
                    {!item.programImages ? (
                      <s.ProgramDownloadLink
                        onClick={() => util.makeMsg('권한이 부족합니다.', 'error')}
                      >
                        다운로드
                      </s.ProgramDownloadLink>
                    ) : item.programImages.length !== 0 ? (
                      <s.ProgramDownloadLink
                        onClick={() =>
                          saveAs(
                            item.programImages[0].file.fileUrl,
                            item.programImages[0].file.originalFileName,
                          )
                        }
                      >
                        다운로드
                      </s.ProgramDownloadLink>
                    ) : (
                      <s.ProgramDownloadLink onClick={props.unableDownload}>
                        다운로드
                      </s.ProgramDownloadLink>
                    )}
                  </s.ProgramDownloadLinkArea>
                </s.ProgramLinkArea>
              </s.ProgramForm>
            ))
          )}
        </s.ProgramList>
      </s.ProgramListArea>
      <s.PagingNav>
        {props.isFirst ? null : (
          <s.PagingLink onClick={() => props.setPageNum(props.pageNum - 1)}>
            <s.PagingLinkImg src="/static/img/l_shaft.png" />
          </s.PagingLink>
        )}

        <s.PagingNumArea>
          {props.pageList.map((page: number, index: number) => (
            <s.PagingNum
              key={'page' + index}
              onClick={() => props.setPageNum(page)}
              current={props.pageNum === page}
            >
              {page}
            </s.PagingNum>
          ))}
        </s.PagingNumArea>
        {props.isLast ? null : (
          <s.PagingLink onClick={() => props.setPageNum(props.pageNum + 1)}>
            <s.PagingLinkImg src="/static/img/r_shaft.png" />
          </s.PagingLink>
        )}
      </s.PagingNav>
    </s.Container>
  );
};

export default ActivityPresenter;

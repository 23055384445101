import React from 'react';

import * as util from '../../util/util';
import * as s from './PartnersStyled';
import NotFound from '../../components/notFound';
import { Link } from 'react-router-dom';

interface PartnersProps {
  mainItems: any[];
  linkTo: (param: string) => void;
}

const PartnersPresenter = (props: PartnersProps) => {
  return (
    <s.Container>
      <s.MainImgArea>
        <s.MainImg src={'static/img/shopping_banner.png'} />
      </s.MainImgArea>

      <s.ContentsArea>
        <s.TopArea>
          <s.TopAreaText>엄지교육 프로그램을 주문할 수 있는 자사전용 공간입니다.</s.TopAreaText>
          <s.TopAreaButtons>
            <s.TopAreaButton
              onClick={() => {
                props.linkTo('shopping/order');
              }}
            >
              주문하기
            </s.TopAreaButton>
            <s.TopAreaButton
              onClick={() => {
                util.makeMsg('준비중입니다.', 'info');
              }}
            >
              공지사항
            </s.TopAreaButton>
            <s.TopAreaButton
              onClick={() => {
                util.makeMsg('준비중입니다.', 'info');
              }}
            >
              영업자료
            </s.TopAreaButton>
          </s.TopAreaButtons>
        </s.TopArea>

        <s.ProgramArea>
          <s.ProgramText>Program</s.ProgramText>
          <s.ProgramItemArea>
            {props.mainItems.map((item) => {
              return (
                <s.ProgramItem to={'edu?title=' + item.link}>
                  <s.ProgramItemImg src={item.photo} />
                </s.ProgramItem>
              );
            })}
          </s.ProgramItemArea>
        </s.ProgramArea>
      </s.ContentsArea>
    </s.Container>
  );
};

export default PartnersPresenter;

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import * as recoilItem from '../../../../util/recoilitem';

import { planApi } from '../../../../api/api-plan';

import { saveAs } from 'file-saver';
import DailyPlanPresenter from './DailyPlanPresenter';
type DailyPlanProps = {
  notSupport: () => void;
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  option: {
    age: any;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      year: number;
      month: number;
      week: number;
      day: number;
    }>
  >;
  ageAuths: any;
  icons: any;
  color: string;
  eduTitle: string;
};

const DailyPlanContainer = (props: DailyPlanProps) => {
  const token = useRecoilValue(recoilItem.token);
  const [planId, setPlanId] = useRecoilState(recoilItem.planId);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const [dailyPlan, setDailyPlan] = useState(null);

  const fetchData = async () => {
    try {
      console.log(2);
      setIsLoading(true);
      const res: any = await planApi.getPlanDaily(
        token,
        props.ageAuths[props.option.age].comnCd,
        props.option.day,
        props.option.week,
        props.option.month,
        props.option.year,
      );
      if (res.data.rsltCd === '00') {
        setDailyPlan(res.data.dailyPlan);
      } else {
        setDailyPlan(null);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setDailyPlan(null);
    fetchData();
  }, [props.option.age, props.option.day]);

  const createPlanFile = async (planId: string) => {
    try {
      setIsLoading(true);
      let data: FormData = new FormData();
      data.append('planId', planId);
      await planApi.createPlanDailyFile(token, data).then((res) => {
        console.log(res);
        if (res.data.rsltCd === '00') {
          setDailyPlan(res.data.dailyPlan);
          saveAs(res.data.dailyPlan.file.fileUrl, res.data.dailyPlan.file.originalFileName);
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <DailyPlanPresenter
      notSupport={props.notSupport}
      option={props.option}
      ageAuths={props.ageAuths}
      icons={props.icons}
      dailyPlan={dailyPlan}
      setPlanId={setPlanId}
      createPlanFile={createPlanFile}
      color={props.color}
      eduTitle={props.eduTitle}
    />
  );
};

DailyPlanContainer.defaultProps = {
  type: 'daily',
};

export default DailyPlanContainer;

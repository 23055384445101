import React, { Dispatch, SetStateAction } from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { SetterOrUpdater } from 'recoil';
import SelectAge from './component/SelectAge';
import SelectField from './component/SelectField';
import SelectHolder from './component/SelectHolder';

import * as s from './UpdateProgramStyled';

interface ActivityPlanProps {
  getProgramList: () => Promise<void>;
  fieldList: {
    menuCd: string;
    menuNm: string;
    viewOrder: number;
  }[];
  programSearch: {
    keyword?: string | undefined;
    field?: string | undefined;
  };
  setProgramSearch: React.Dispatch<
    React.SetStateAction<{
      keyword?: string | undefined;
      field?: string | undefined;
    }>
  >;
  programList: any[];
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  pageList: number[];
  lastPageNum: number;
  isFirst: boolean;
  isLast: boolean;
  onChangeSelectedProgramList: (programId: any, icon: string) => void;
  selectedProgramList: any[];
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  setProgramId: SetterOrUpdater<number | null>;
  modalProgram: any;
  setModalProgram: React.Dispatch<any>;
  onChangeSelectedProgramPage: (program: any, page: string) => void;
  deleteProgram: (programId: number) => Promise<void>;
  isModifyPage: boolean;
  setIsModifyPage: React.Dispatch<React.SetStateAction<boolean>>;
  modifiedProgram: any;
  setModifiedProgram: React.Dispatch<any>;
  option: {
    holder: string;
    field: string;
    age: string;
    title: string;
    description: string;
    files: any[];
    thumbnail: any;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  holderList: any;
  ageList: any;
  goModify: (program: any) => void;
  goSearch: () => void;
  update: () => Promise<void>;
  isEduTool: boolean;
  setIsEduTool: React.Dispatch<React.SetStateAction<boolean>>;

  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  open: () => void;
  acceptedFiles: File[];
  submitFileList: File[];
  isDragActive: boolean;
}

const UpdateProgramPresenter = (props: ActivityPlanProps) => {
  return (
    <s.Container>
      <s.ActivityPlanHeader>
        <s.ActivityPlanHeaderTitleArea>
          <s.ActivityPlanHeaderTitle>프로그램 수정</s.ActivityPlanHeaderTitle>
        </s.ActivityPlanHeaderTitleArea>
        <s.ActivityPlanHeaderRightArea>
          <s.ActivityPlanHeaderRightText>
            <s.ActivityPlanHeaderRightIcon src="/static/img/home_icon.jpg" />
            {'HOME > 마이페이지 > 글수정'}
          </s.ActivityPlanHeaderRightText>
        </s.ActivityPlanHeaderRightArea>
      </s.ActivityPlanHeader>
      {props.isModifyPage ? (
        <s.RegisterContentArea>
          <SelectAge
            ageList={props.ageList}
            onChangeOption={props.onChangeOption}
            option={props.option}
          />
          <SelectHolder
            holderList={props.holderList}
            onChangeOption={props.onChangeOption}
            option={props.option}
          />
          <SelectField
            fieldList={props.fieldList}
            onChangeOption={props.onChangeOption}
            option={props.option}
          />
          <s.UploadProgramAreaInputArea>
            <s.UploadProgramAreaHeaderLabel>제목</s.UploadProgramAreaHeaderLabel>
            <s.UploadProgramAreaHeaderInput
              value={props.option.title}
              onChange={(e) => props.onChangeOption('title', e.target.value)}
              placeholder={'텍스트를 입력해주세요.'}
            />
          </s.UploadProgramAreaInputArea>
          <s.UploadProgramAreaInputArea>
            <s.UploadProgramAreaHeaderLabel>설명</s.UploadProgramAreaHeaderLabel>
            <s.UploadProgramAreaHeaderInput
              value={props.option.description}
              onChange={(e) => props.onChangeOption('description', e.target.value)}
              placeholder={'텍스트를 입력해주세요.'}
            />
            <s.UploadProgramAreaSelectType
              id={'isEduTool'}
              defaultChecked={false}
              onChange={(e) => {
                props.setIsEduTool(e.target.checked);
              }}
              type={'checkbox'}
            />
            <s.UploadProgramAreaSelectTypeLabel htmlFor={'isEduTool'}>
              {props.isEduTool ? (
                <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
              ) : null}
            </s.UploadProgramAreaSelectTypeLabel>
            <s.UploadProgramAreaSelectText htmlFor={'isEduTool'}>
              교구
            </s.UploadProgramAreaSelectText>
          </s.UploadProgramAreaInputArea>

          <s.UpdateProgramAreaFileArea>
            <s.UpdateProgramHeaderArea>
              <s.UpdateProgramTh>썸네일</s.UpdateProgramTh>
              <s.UpdateProgramTh>이미지</s.UpdateProgramTh>
            </s.UpdateProgramHeaderArea>
            <s.UpdateProgramTr>
              <s.UpdateProgramTd>{props.option.thumbnail.originalFileName}</s.UpdateProgramTd>
              <s.UpdateProgramTd>
                <img
                  src={props.option.thumbnail.fileUrl}
                  style={{ width: '200px', height: '200px', objectFit: 'contain' }}
                />
              </s.UpdateProgramTd>
            </s.UpdateProgramTr>
          </s.UpdateProgramAreaFileArea>

          <s.UploadProgramAreaFileUploadArea
            isDrag={props.isDragActive}
            {...props.getRootProps({ className: 'dropzone' })}
          >
            <s.UploadProgramAreaFileUploadAreaInput {...props.getInputProps()} />
            {props.submitFileList.length > 0 ? (
              <s.UploadProgramAreaFileUploadAreaButtonIcon
                onClick={props.open}
                src="/static/img/pdf_icon_1.png"
              />
            ) : null}
            <s.UploadProgramAreaFileUploadAreaText>
              {props.submitFileList.length > 0
                ? props.submitFileList.length === 1
                  ? props.submitFileList[0].name
                  : props.submitFileList[0].name + ' 외 ' + (props.submitFileList.length - 1) + '개'
                : '썸네일을 수정할 파일을 선택해주세요.'}
            </s.UploadProgramAreaFileUploadAreaText>
            <s.UploadProgramAreaFileUploadAreaButtonIcon
              onClick={props.open}
              src={
                props.submitFileList.length > 0
                  ? '/static/img/file_exist.png'
                  : '/static/img/selectFile.png'
              }
            />
          </s.UploadProgramAreaFileUploadArea>

          {/* <s.UpdateProgramAreaFileArea>
            <s.UpdateProgramHeaderArea>
              <s.UpdateProgramTh>순번</s.UpdateProgramTh>
              <s.UpdateProgramTh>이름</s.UpdateProgramTh>
              <s.UpdateProgramTh>유형</s.UpdateProgramTh>
            </s.UpdateProgramHeaderArea>
            {props.option.files?.length > 0 ? (
              props.option.files.map((item: any, index: number) => {
                return (
                  <s.UpdateProgramTr>
                    <s.UpdateProgramTd>{index + 1}</s.UpdateProgramTd>
                    <s.UpdateProgramTd>{item.name}</s.UpdateProgramTd>
                    <s.UpdateProgramTd>{item.type}</s.UpdateProgramTd>
                  </s.UpdateProgramTr>
                );
              })
            ) : (
              <s.UpdateProgramTr>
                <s.UpdateProgramTd colSpan={3}>자료가 없습니다.</s.UpdateProgramTd>
              </s.UpdateProgramTr>
            )}
          </s.UpdateProgramAreaFileArea> */}
          <s.RegisterContentButtonArea>
            <s.RegisterContentButton onClick={props.goSearch}>뒤로가기</s.RegisterContentButton>
            <s.RegisterContentButton onClick={props.update}>수정</s.RegisterContentButton>
          </s.RegisterContentButtonArea>
        </s.RegisterContentArea>
      ) : (
        <s.RegisterContentArea>
          <s.FileUploadForm>
            <s.UploadProgramAreaSelectHeader>놀이 선택</s.UploadProgramAreaSelectHeader>
            <s.UploadProgramAreaSelectDv />
            <s.UploadProgramAreaHeaderSelect>
              {props.fieldList.map((subMenu: any) => (
                <>
                  <s.UploadProgramAreaSelectType
                    id={subMenu.menuCd}
                    value={subMenu.menuCd}
                    defaultChecked={props.programSearch.field === subMenu.menuCd}
                    onChange={(e) =>
                      props.programSearch.field === e.target.value
                        ? props.setProgramSearch({ ...props.programSearch, field: '' })
                        : props.setProgramSearch({
                            ...props.programSearch,
                            field: e.target.value,
                          })
                    }
                    type={'checkbox'}
                    disabled={false}
                  />
                  <s.UploadProgramAreaSelectTypeLabel htmlFor={subMenu.menuCd}>
                    {props.programSearch.field === subMenu.menuCd ? (
                      <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                    ) : null}
                  </s.UploadProgramAreaSelectTypeLabel>
                  <s.UploadProgramAreaSelectText htmlFor={subMenu.menuCd}>
                    {subMenu.menuNm}
                  </s.UploadProgramAreaSelectText>
                </>
              ))}
            </s.UploadProgramAreaHeaderSelect>
            <s.ContentList>
              <s.SearchText
                type="text"
                name={`keyword`}
                value={props.programSearch.keyword}
                onChange={(e) =>
                  props.setProgramSearch({ ...props.programSearch, keyword: e.target.value })
                }
                placeholder={'텍스트를 입력해주세요.'}
                onKeyPress={props.onKeyPress}
              ></s.SearchText>
              <s.Button onClick={props.getProgramList}>검색</s.Button>
            </s.ContentList>
            <s.ResultContentWrapper>
              {props.programList && props.programList.length > 0 ? (
                props.programList.map((program) => {
                  return (
                    <s.ResultEachItemWrapper key={program.programId}>
                      <s.ResultItemInfoTopic>
                        {program.field
                          ? program.field.menuNm
                          : program.subMenu
                          ? program.subMenu.menuNm
                          : program.topMenu.menuNm}
                      </s.ResultItemInfoTopic>
                      <s.ResultItemInfoNm onClick={() => props.setProgramId(program.programId)}>
                        {program.title}
                      </s.ResultItemInfoNm>
                      <s.ResultItemButton onClick={() => props.goModify(program)}>
                        <s.ContentListButtonImg src={'/static/img/pen.png'} />
                      </s.ResultItemButton>
                      <s.ResultItemButton onClick={() => props.deleteProgram(program.programId)}>
                        <s.ContentListButtonImg src={'/static/img/button_minus.png'} />
                      </s.ResultItemButton>
                    </s.ResultEachItemWrapper>
                  );
                })
              ) : (
                <s.NoResult>검색 결과가 없습니다.</s.NoResult>
              )}
            </s.ResultContentWrapper>
            <s.PagingNav>
              {props.isFirst ? null : (
                <s.PagingLink onClick={() => props.setPageNum(props.pageNum - 1)}>
                  <s.PagingLinkImg src="/static/img/l_shaft.png" />
                </s.PagingLink>
              )}

              <s.PagingNumArea>
                {props.pageList.map((page: number, index: number) => (
                  <s.PagingNum
                    key={'page' + index}
                    onClick={() => props.setPageNum(page)}
                    current={props.pageNum === page}
                  >
                    {page}
                  </s.PagingNum>
                ))}
              </s.PagingNumArea>
              {props.isLast ? null : (
                <s.PagingLink onClick={() => props.setPageNum(props.pageNum + 1)}>
                  <s.PagingLinkImg src="/static/img/r_shaft.png" />
                </s.PagingLink>
              )}
            </s.PagingNav>
          </s.FileUploadForm>
        </s.RegisterContentArea>
      )}
    </s.Container>
  );
};

export default UpdateProgramPresenter;

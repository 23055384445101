import * as s from '../UpdateEduStyled';

interface SelectStepProps {
  option: {
    holder: string;
    edu: string;
    type: string;
    step: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
}

const SelectStep = (props: SelectStepProps) => {
  return (
    <s.SelectArea>
      <s.SelectLabel>프로그램 단계 선택</s.SelectLabel>
      <s.SelectDiv></s.SelectDiv>
      <s.SelectForm selectType={'step'}>
        <s.SelectItem
          key={'step-0'}
          active={props.option.step === '0'}
          selectType={'step'}
          onClick={() => props.onChangeOption('step', '0')}
        >
          0단계
        </s.SelectItem>
        <s.SelectItem
          key={'step-1'}
          active={props.option.step === '1'}
          selectType={'step'}
          onClick={() => props.onChangeOption('step', '1')}
        >
          1단계
        </s.SelectItem>
        <s.SelectItem
          key={'step-2'}
          active={props.option.step === '2'}
          selectType={'step'}
          onClick={() => props.onChangeOption('step', '2')}
        >
          2단계
        </s.SelectItem>
        <s.SelectItem
          key={'step-3'}
          active={props.option.step === '3'}
          selectType={'step'}
          onClick={() => props.onChangeOption('step', '3')}
        >
          3단계
        </s.SelectItem>
        <s.SelectItem
          key={'step-none'}
          active={props.option.step === 'none'}
          selectType={'step'}
          onClick={() => props.onChangeOption('step', 'none')}
        >
          단계없음
        </s.SelectItem>
      </s.SelectForm>
    </s.SelectArea>
  );
};

export default SelectStep;

import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import SoundPresenter from './SoundPresenter';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { comnApi } from '../../api/api-comn';
import { programApi } from '../../api/api-program';
import * as recoilItem from '../../util/recoilitem';

interface SoundProps extends RouteComponentProps {}

const SoundContainer = (props: SoundProps) => {
  const token = useRecoilValue(recoilItem.token);
  const setProgramId = useSetRecoilState(recoilItem.programId);
  const audioRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState<string | undefined>('');
  const [audioPlaying, setAudioPlaying] = useState<string | undefined>();
  const [openModal, setOpenModal] = useState<{ idx: number; type: string } | undefined>();

  const blockPageNum: string = '5';
  const pagePostCount: string = '10';

  const [headerList, setHeaderList] = useState<null | {
    ageList: never[];
    holderList: never[];
  }>(null);

  const [selectedIdxList, setSelectedIdxList] = useState<string[]>([]);

  const [selectedAge, setSelectedAge] = useState({ comnCd: 'AUTH0101', comnCdNm: '1세' });
  const [selectedHolderList, setSelectedHolderList] = useState<
    { comnCd: string; comnCdNm: string }[]
  >([]);
  const [programList, setProgramList] = useState(null);

  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const [pageNum, setPageNum] = useState(1);
  const [pageList, setPageList] = useState([]);
  const [isFirst, setIsFirst] = useState(false);
  const [lastPageNum, setLastPageNum] = useState(1);
  const [isLast, setIsLast] = useState(false);

  const onChangeAge = (item: any) => {
    setSelectedAge({ comnCd: item.comnCd, comnCdNm: item.comnCdNm });
    setPageNum(1);
  };

  const onChangeHolderList = (item: any) => {
    if (!item) {
      setSelectedHolderList([]);
      return;
    }
    const holder = { comnCd: item.comnCd, comnCdNm: item.comnCdNm };

    // let tempHolderList = [...selectedHolderList];

    // if (tempHolderList.map((item: any) => item.comnCd).includes(holder.comnCd)) {
    //   const idx: number = tempHolderList.map((item: any) => item.comnCd).indexOf(holder.comnCd);
    //   tempHolderList.splice(idx, 1);
    // } else {
    //   tempHolderList.push(holder);
    // }

    // setSelectedHolderList(tempHolderList);
    setSelectedHolderList([holder]);
    setPageNum(1);
  };

  const firstFetchData = async () => {
    setIsLoading(true);

    let res1: any = null;
    let res2: any = null;
    let res3: any = null;

    let formData: FormData = new FormData();
    formData.append('topMenu', 'MN04');
    formData.append('subMenu', 'MN0402');
    formData.append('age', selectedAge.comnCd);
    formData.append('page', pageNum.toString());
    formData.append('blockPageNum', blockPageNum);
    formData.append('pagePostCount', pagePostCount);
    try {
      let headerItems = { ...headerList };
      res1 = await comnApi.getCd('AUTH01');
      res2 = await comnApi.getCd('HOL01');
      res3 = await programApi.filter(token, formData);
      if (res1.data.rsltCd === '00' && res2.data.rsltCd === '00') {
        setHeaderList({
          ...headerItems,
          ageList: res1.data.ComnCd.subCdList,
          holderList: res2.data.ComnCd.subCdList,
        });
      }
      if (res3.data.rsltCd === '00') {
        let resultList = res3.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res3.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setProgramList(resultList);
        setIsFirst(res3.data.programList.first);
        setIsLast(res3.data.programList.last);
        setLastPageNum(res3.data.programList.totalPages);
        setPageList(res3.data.pageList);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    let res: any = null;

    let formData: FormData = new FormData();

    formData.append('topMenu', 'MN04');
    formData.append('subMenu', 'MN0402');
    formData.append('age', selectedAge.comnCd);
    formData.append('page', pageNum.toString());
    formData.append('blockPageNum', blockPageNum);
    formData.append('pagePostCount', pagePostCount);
    if (selectedHolderList.length > 0) {
      formData.append('holder', selectedHolderList.map((item: any) => item.comnCd).join(','));
    }

    try {
      res = await programApi.filter(token, formData);
      if (res.data.rsltCd === '00') {
        let resultList = res.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setProgramList(resultList);
        setIsFirst(res.data.programList.first);
        setIsLast(res.data.programList.last);
        setLastPageNum(res.data.programList.totalPages);
        setPageList(res.data.pageList);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    firstFetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedAge, selectedHolderList, pageNum]);

  return (
    <>
      <Header {...props} />
      <SoundPresenter
        headerList={headerList}
        selectedAge={selectedAge}
        setSelectedAge={setSelectedAge}
        selectedHolderList={selectedHolderList}
        onChangeHolderList={onChangeHolderList}
        onChangeAge={onChangeAge}
        programList={programList}
        setProgramId={setProgramId}
        pageNum={pageNum}
        setPageNum={setPageNum}
        pageList={pageList}
        lastPageNum={lastPageNum}
        isFirst={isFirst}
        isLast={isLast}
        selectedIdxList={selectedIdxList}
        setSelectedIdxList={setSelectedIdxList}
        audioRef={audioRef}
        audioUrl={audioUrl}
        setAudioUrl={setAudioUrl}
        audioPlaying={audioPlaying}
        setAudioPlaying={setAudioPlaying}
        openModal={openModal}
        setOpenModal={setOpenModal}
      ></SoundPresenter>
      <Footer {...props} />
    </>
  );
};

export default SoundContainer;

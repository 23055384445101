import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1180px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const Banner = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
  min-width: 1180px;
`;

export const NewUpdateArea = styled.div`
  width: 1180px;
  display: inline-block;
  margin: 10px auto 0px;
  text-align: start;
  border-bottom: 1px solid #e4007f;
  padding: 10px;
`;

export const NewUpdateTitle = styled.div`
  display: inline-block;
  color: #e4007f;
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
`;

export const NewUpdateText = styled.div`
  display: inline-block;
  color: #666666;
  font-size: 14px;
  font-weight: 300;
`;
export const MainContentArea = styled.div`
  width: 1180px;
  display: inline-block;
  margin: 0 auto;
`;

export const MainContentHorizontalSection1 = styled.div`
  width: 100%;
  height: 720px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
`;

export const MainContentDiv1 = styled.div`
  width: 164px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MainContentDiv2 = styled.div`
  width: 700px;
  height: 100%;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MainContentDiv3 = styled.div`
  width: 285px;
  height: 100%;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`;

export const MainContentDiv4 = styled.div`
  width: 285px;
  height: 100%;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`;

export const MainContentTitle = styled.div`
  font-size: 18px;
  color: #4d4d4d;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 9999;
`;

export const MainContentTitleGoto = styled.img`
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-left: 5px;
  cursor: pointer;
`;

export const MainContentDiv1ListContainer = styled.div`
  margin-top: 10px;
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MainContentDiv1ListRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
`;

export const MainContentDiv1ListItemType1 = styled.div`
  width: 82px;
  height: 100px;
  background-color: #dfdfdf;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const MainContentDiv1ListItemType2 = styled.div`
  width: 82px;
  height: 100px;
  background-color: #bebebe;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const MainContentDiv1ListItemImg = styled.img`
  width: 95%;
  height: auto;
  object-fit: contain;
`;

export const MainContentDiv1ListItemTextBig = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: #4d4d4d;
`;
export const MainContentDiv1ListItemTextSmall = styled.div`
  font-size: 18px;
  color: #4d4d4d;
`;

export const MainContentDiv1AdBox = styled.div`
  width: 100%;
  height: 71px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainContentDiv1BannerBox = styled(Link)`
  width: 100%;
  height: 180px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainContentDiv2Row = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: row;
  border: 1px solid #dedede;
  padding: 5px;
  gap: 10px;
`;

export const MainContentDiv2RowBox = styled.div`
  width: 340px;
  height: 100%;
  position: relative;
  cursor: pointer;
`;

export const MainContentDiv2RowBoxSmall = styled.div`
  width: 167px;
  height: 100%;
  position: relative;
  cursor: pointer;
`;

export const MainContentDiv2RowBoxImg = styled.img`
  width: 100%;
  height: 190px;
  object-fit: cover;
`;

export const MainContentDiv2RowBoxTextArea = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 38px;
  display: flex;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.8);
  align-items: center;
`;

export const MainContentDiv2RowBoxTextAreaSmall = styled.div`
  position: absolute;
  bottom: 0;
  width: 167px;
  height: 38px;
  display: flex;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.8);
  align-items: center;
`;

export const MainContentDiv2RowBoxTextCategory = styled.div<{ color: string }>`
  margin-left: 10px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  width: 20%;
  height: 50%;
  border-radius: 5px;
  background-color: ${(props) => props.color};
`;

export const MainContentDiv2RowBoxTextContent = styled.div`
  margin-left: 5px;
  font-size: 10px;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainContentDiv2NoItemsPlaceHolder = styled.div`
  width: 100%;
  height: 220px;
  margin-left: 5px;
  font-size: 12px;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dedede;
`;

export const MainContentDiv3RowVert = styled.div`
  width: 100%;
  height: 167px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const MainContentDiv3VideoArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainContentDiv3SmallText = styled.div`
  margin-top: 5px;
  font-size: 10px;
  color: #0087ed;
  text-align: left;
`;

export const MainContentDiv3BigText = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: #4d4d4d;
  text-align: left;
`;

export const MainContentDiv3RowHoriz = styled.div`
  width: 100%;
  height: 167px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

export const MainContentDiv3RowItemArea = styled.div`
  width: 137px;
  height: 167px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const MainContentDiv3RowItemImage = styled.img`
  width: 100%;
  height: 135px;
  object-fit: stretch;
`;

export const MainContentDiv4LoginButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  height: 26px;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const MainContentDiv4LoginSaveCheckBox = styled.input`
  width: 15px;
  height: 15px;
`;

export const MainContentDiv4LoginSaveText = styled.div`
  font-size: 10px;
  color: #838383;
  text-align: left;
  vertical-align: middle;
  margin-left: 5px;
`;

export const MainContentDiv4LoginSection = styled.div`
  width: 100%;
  height: 132px;
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

export const MainContentDiv4LoginMainSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
`;

export const MainContentDiv4LoginInputSection = styled.div`
  height: 80px;
  width: 190px;
  margin-right: 5px;
`;

export const MainContentDiv4LoginInput = styled.input`
  width: 100%;
  height: 39px;
  border: 1px solid #dedede;
  font-size: 10px;
  padding: 5px 5px 5px 5px;
  color: #4d4d4d;
  &::placeholder {
    color: #bebebe;
  }
`;

export const MainContentDiv4LoginButton = styled.div`
  width: 95px;
  height: 80px;
  background-color: #bebebe;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #4d4d4d;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
`;

export const MainContentDiv4LoginSmallItemSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

export const MainContentDiv4LoginSmallItem = styled.div`
  font-size: 10px;
  color: #4d4d4d;
  margin-right: 5px;
  cursor: pointer;
  &:hover {
    color: #0087ed;
  }
`;

export const MainContentDiv4LoginBigItemSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

export const MainContentDiv4LoginBigItem = styled.div`
  font-size: 12px;
  color: #838383;
  cursor: pointer;
  margin-right: 15px;
  &:hover {
    filter: invert(34%) sepia(53%) saturate(5821%) hue-rotate(189deg) brightness(106%);
  }
`;

export const MainContentDiv4LoginBigItemImg = styled.img`
  width: 12px;
  height: 12px;
  margin-left: 3px;
  margin-right: 3px;
  object-fit: contain;
`;

export const MainContentDiv4AfterLoginTopDiv = styled.div`
  width: 100%;
  height: 26px;
  background-color: #dfdfdf;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 7px;
`;

export const MainContentDiv4UserInfoSection = styled.div`
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const MainContentDiv4AfterLoginTopName = styled.div`
  font-size: 12px;
  color: #6c6c6c;
  text-align: left;
  padding-left: 10px;
`;

export const MainContentDiv4AfterLoginTopType = styled.div`
  font-size: 10px;
  font-weight: bold;
  color: #00a700;
  text-align: left;
  margin-left: 5px;
`;

export const MainContentDiv4AfterLoginLogoutText = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: #909090;
  text-align: right;
  width: 20%;
  padding-right: 10px;
  cursor: pointer;
`;

export const MainContentDiv4AfterLoginButtonSection = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
`;

export const MainContentDiv4AfterLoginButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
`;

export const MainContentDiv4AfterLoginButtonTextArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const MainContentDiv4AfterLoginButtonTextLabel = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: #a9a9a9;
  text-align: center;
  margin-right: 10px;
`;

export const MainContentDiv4AfterLoginButtonTextData = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #00a700;
  text-align: center;
`;

export const MainContentDiv4AfterLoginMyPageButton = styled.div`
  width: 90%;
  background-color: #00a700;
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 5px;
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
`;

export const MainContentDiv4AfterLoginMyShoppingButton = styled.div`
  width: 90%;
  background-color: #6e6e6e;
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 5px;
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
`;

export const MainContentDiv4AfterLoginDataSection = styled.div`
  width: 100%;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const MainContentDiv4AfterLoginDataTextLabel = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: #a9a9a9;
  text-align: center;
`;

export const MainContentDiv4AfterLoginDataTextData = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #00a700;
  text-align: center;
`;

export const MainContentDiv4LinkSection = styled.div`
  width: 100%;
  height: 118px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const MainContentDiv4LinkTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  color: #ffffff;
`;

export const MainContentDiv4LinkSplitter = styled.div`
  height: 15px;
  width: 25px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ffffff;
`;

export const MainContentDiv4LinkTextArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const MainContentDiv4LinkText = styled.div`
  font-size: 10px;
  color: #ffffff;
  line-height: 16px;
  &: hover {
    color: #0087ed;
  }
`;

export const MainContainerHorizontalSection2 = styled.div`
  width: 100%;
  height: 140px;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
`;

export const MainContentPlanBoardArea = styled.div`
  width: 585px;
  height: 100%;
  padding: 10px;
  border: 1px solid #dedede;
`;

export const MainContentWorkbookBoardArea = styled.div`
  width: 585px;
  height: 100%;
  margin-left: 10px;
  padding: 10px;
  border: 1px solid #dedede;
`;

export const MainContentBoardTitleArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const MainContentBoardTitle = styled.div`
  font-size: 18px;
  width: 80%;
  font-weight: 500;
  color: #4d4d4d;
  text-align: left;
`;

export const MainContentBoardItem = styled.div`
  font-size: 12px;
  color: #7a7a7a;
  margin-top: 10px;
  text-align: left;
  margin-left: 15px;
  cursor: pointer;

  &:hover {
    color: #0087ed;
  }
`;

export const MainContentBoardMoreText = styled.div`
  font-size: 10px;
  width: 20%;
  text-align: end;
  color: #5e5e5e;
  align-self: center;
  cursor: pointer;
  &:hover {
    color: #0087ed;
  }
`;

export const EducationPlanArea = styled.div`
  margin: 20px auto 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const EducationPlanTitleArea = styled.div`
  display: inline-block;
  text-align: start;
  vertical-align: middle;
  width: 27%;
`;

export const EducationPlanTitle = styled.div`
  width: auto;
  font-size: 36px;
  color: #4d4d4d;
  font-weight: 700;
  letter-spacing: -2px;
  margin-bottom: 10px;
  text-indent: -4 px;
`;

export const EducationPlanSubTitle = styled.div`
  width: auto;
  font-size: 18px;
  color: #4d4d4d;
`;

export const EducationPlanBtnArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: start;
  vertical-align: middle;
  width: 70%;
  gap: 25px;
`;

export const EducationPlanBtn = styled.div<{ number: Number }>`
  display: inline-block;
  width: 140px;
  height: 136px;
  background: ${(props) => `url("/static/img/button_${props.number}.png")`};
  background-size: 100% 100%;
  text-align: center;
  cursor: pointer;
`;

export const EducationPlanBtnText = styled.div`
  color: #ffffff;
  position: relative;
  font-size: 32px;
  font-family: 'GyeonggiTitleM';
  margin-top: 81px;
  letter-spacing: 1.5px;
`;

export const EducationSupportArea = styled.div`
  margin: 50px auto 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SupportProgramArea = styled.div`
  display: inline-block;
  text-align: start;
  vertical-align: middle;
  width: 60%;
  padding: 10px;
`;

export const SupportProgramTitle = styled.div`
  font-size: 36px;
  color: #4d4d4d;
  font-weight: 700;
  letter-spacing: -2px;
  text-indent: -5px;
`;

export const SupportProgramBtnArea = styled.div`
  display: flex;
  margin: 30px auto;
  text-align: start;
  justify-content: space-between;
  vertical-align: middle;
  width: 100%;
`;

export const SupportProgramBtn = styled.div`
  display: inline-block;
  margin: 5px 0;
  width: 200px;
  height: 267px;
  text-align: center;
  cursor: pointer;
`;

export const SupportProgramBtnImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const SupportSignArea = styled.div`
  text-align: start;
  vertical-align: top;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

export const SupportSignBtn = styled.div`
  margin: 5px 15px;
  width: 354px;
  height: 105px;
  text-align: center;
  cursor: pointer;
`;

export const SupportSignBtnImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const SupportSignBtnArea = styled.div`
  margin: 0 auto;
  vertical-align: middle;
  padding: 0 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;

export const SupportSignBtnLeftCheckBox = styled.input`
  margin-right: 8px;
  zoom: 1.5;
`;
export const SupportSignBtnLeftText = styled.div`
  font-size: 18px;
  color: #4d4d4d;
  font-weight: 300;
`;
export const SupportSignBtnLeft = styled.div`
  font-size: 11px;
  color: #4d4d4d;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SupportSignBtnRight = styled.div`
  font-size: 18px;
  color: #4d4d4d;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SupportWeatherArea = styled.div`
  margin-top: 10px;
  text-align: start;
  vertical-align: top;
  width: 354px;
  padding: 10px 0;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
`;

export const SupportWeatherTitleArea = styled.div`
  margin: 0 auto;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SupportWeatherTitle = styled.div`
  display: inline-block;
  font-size: 24px;
  color: #4d4d4d;
  margin-left: 30px;
  margin-right: 30px;
  line-height: 16px;
`;
export const SupportWeatherTitleBtn = styled.div`
  display: inline-block;
  width: 12px;
  height: 15px;
  cursor: pointer;
`;
export const SupportWeatherTitleBtnImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const SupportWeatherInfoArea = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

export const SupportWeatherInfoLeftArea = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SupportWeatherInfoRightArea = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SupportWeatherInfoIcon = styled.div`
  width: 40px;
  display: inline-block;
  margin-right: 10px;
`;

export const SupportWeatherInfoIconImg = styled.img`
  width: 100%;
  height: 100%;
`;
export const SupportMicroDustInfoIconImg = styled.img`
  width: 33px;
  height: 32px;
`;
export const SupportWeatherInfoTextArea = styled.div`
  vertical-align: middle;
  text-align: start;
  display: inline-block;
`;
export const SupportWeatherInfoTitle = styled.div`
  font-size: 20px;
  color: #4d4d4d;
  word-wrap: normal;
`;
export const SupportWeatherInfoText = styled.div<{ color: string }>`
  font-size: 20px;
  color: ${(props) => props.color};
`;

export const EducationContentArea = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 100%;
  min-height: 512px;
  background: #ffdbdc;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const EducationMaterialArea = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 100%;
  min-height: 400px;
  background: #d3f3ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EducationContentTopArea = styled.div`
  width: 1200px;
  display: flex;
  margin: 35px auto 0px;
  text-align: start;
  padding: 10px 50px;
  vertical-align: bottom;
  justify-content: space-between;
`;

export const EducationContentTitle = styled.div`
  display: inline-block;
  font-size: 36px;
  color: #4d4d4d;
  font-weight: 700;
  letter-spacing: -2px;
  padding: 5px;
  margin-left: 10px;
  margin-right: 20px;
  text-align: start;
  width: auto;
  align-self: center;
`;

export const EducationContentCategoryArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
  text-align: start;
  vertical-align: middle;
  gap: 10px;
`;

export const EducationContentCategory = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 60px;
  height: 60px;
  margin: 1px;
  font-size: 16px;
  cursor: pointer;
  border: ${(props) => (props.active ? '#fff' : '1px solid #a9a9a9')};
  background-color: ${(props) => (props.active ? '#E76F70' : '#fff')};
  color: ${(props) => (props.active ? '#FCF3F3' : '#a9a9a9')};
`;

// export const EducationContentCategory = styled.div`
//   display: inline-block;
//   width: 60px;
//   height: 60px;
//   margin: 1px;
//   cursor: pointer;
// `;

export const EducationContentCategoryImg = styled.img`
  width: 80%;
  height: 80%;
  vertical-align: bottom;
`;

export const EducationContentMoreBtn = styled.div`
  display: inline-block;
  float: right;
  width: 90px;
  height: 38px;
  margin-top: 5px;
  margin-right: 15px;
  vertical-align: middle;
  cursor: pointer;
`;

export const EducationContentMoreBtnImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const EducationContentContentArea = styled.div`
  width: 1200px;
  min-height: 357px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  padding: 10px 0;
  vertical-align: bottom;
  background: transparent;
`;

export const EducationContentSlide = styled.div`
  width: 270px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
`;

export const EducationContentSlideImg = styled.img`
  width: 100%;
  height: 300px;
`;

export const EducationContentSlideTextArea = styled.div`
  text-align: left;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #4b4b4b;
`;

export const EducationContentSlideTextImg = styled.img`
  width: 28px;
  height: auto;
  display: inline-block;
  margin-right: 8px;
`;
export const EducationContentSlideTextIcon = styled.div<{ color: string }>`
  width: 38px;
  height: 27px;
  display: flex;
  margin-right: 8px;
  background-color: ${(props) => props.color};
  color: #fff;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
`;

export const EducationContentSlideText = styled.div`
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 1.5px;
  display: inline-block;
  vertical-align: bottom;
`;

export const SliderArrowIcon = styled.div`
  display: inline-block;
  width: 20px;
  height: 30px;
  cursor: pointer;
`;

export const SliderArrowIconImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const EducationMaterialContentArea = styled.div`
  width: 1200px;
  display: flex;
  margin: 0 auto;
  text-align: start;
  padding: 20px 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const EducationMaterialLeft = styled.div`
  display: inline-block;
  width: 35%;
  vertical-align: middle;
`;

export const EducationMaterialTitle = styled.div`
  font-size: 36px;
  color: #4d4d4d;
  font-weight: 700;
  letter-spacing: -2px;
  padding: 5px;
  text-align: start;
  width: 100%;
`;

export const EducationMaterialCategotyArea = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
`;

export const EducationMaterialCategotyItem = styled.div<{ active: boolean }>`
  display: flex;
  width: 128px;
  height: 49px;
  cursor: pointer;
  border: 1px solid ${(props) => (props.active ? '#5aa9db' : '#e2e2e2')};
  background-color: ${(props) => (props.active ? '#5aa9db' : '#fff')};
  color: ${(props) => (props.active ? '#fff' : '#7e7e7e')};
  font-size: 20px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
`;

export const EducationMonthlyContentMoreItemArea = styled.div`
  align-self: center;
`;

export const EducationMonthlyContentMoreItem = styled.div<{ active: boolean }>`
  display: flex;
  width: 125px;
  height: 46px;
  cursor: pointer;
  border: 1px solid ${(props) => (props.active ? '#E76F70' : '#e2e2e2')};
  background-color: ${(props) => (props.active ? '#E76F70' : '#fff')};
  color: ${(props) => (props.active ? '#fff' : '#7e7e7e')};
  font-size: 20px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
`;

export const EducationMaterialCategotyItemImg = styled.img`
  width: 100%;
  height: 40px;
`;

export const EducationMaterialRight = styled.div`
  display: inline-block;
  width: 65%;
  vertical-align: middle;
`;

export const EducationMaterialItem = styled.div<{ url: String }>`
  width: 280px;
  height: 320px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  background: ${(props) => `url("${props.url}")`};
  background-size: 100% 100%;
  border-radius: 10px;
  margin-right: 20px;
`;

export const EducationMaterialItemTitle = styled.div<{ index: number }>`
  position: absolute;
  bottom: 0;
  width: 280px;
  height: 50px;
  background: ${(props) => (props.index % 2 === 0 ? '#5aa9db' : '#5aa9db')};
  opacity: 0.8;
  color: #ffffff;
  vertical-align: middle;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EducationMaterialItemTitleSpan = styled.span`
  font-size: 18px;
  font-weight: 400;
`;

export const EducationVideoArea = styled.div`
  width: 1200px;
  display: inline-block;
  margin: 50px auto;
  text-align: start;
  background: #fff6ed;
  height: 361px;
`;

export const EducationVideoVideo = styled.div`
  width: 60%;
  display: inline-block;
`;

export const EducationVideoTextArea = styled.div`
  width: 40%;
  height: 100%;
  display: inline-block;
  padding: 55px;
  text-align: center;
  vertical-align: top;
`;

export const EducationVideoTitle = styled.div`
  font-size: 36px;
  color: #4d4d4d;
  font-weight: 700;
  letter-spacing: -2px;
  text-align: start;
  margin-bottom: 20px;
`;

export const EducationVideoSubTitle = styled.div`
  font-size: 24px;
  color: #4d4d4d;
  font-weight: 500;
  letter-spacing: 1.4px;
  text-align: start;
  margin-bottom: 20px;
`;

export const EducationVideoText = styled.div`
  font-size: 18px;
  color: #4d4d4d;
  font-weight: 300;
  letter-spacing: 1px;
  text-align: start;
  line-height: 30px;
`;

export const EducationUpdateArea = styled.div`
  width: 1200px;
  display: inline-block;
  margin: 10px auto 20px;
  height: 314px;
  transform: skew(-0.1deg);
`;

export const EducationUpdateLeft = styled.div`
  display: inline-block;
  width: 50%;
  height: 100%;
  background: url('https://storage.googleapis.com/habicastle_storage/static/main/board_background_1.png');
  background-size: 100% 100%;
`;

export const EducationUpdateFirst = styled.div`
  margin: 100px 50px 0px;
  text-align: start;
  font-size: 20px;
  color: #4b4b4b;
  cursor: pointer;
  font-weight: 300;
  font-family: 'Noto Sans KR';
`;

export const EducationUpdateSecond = styled.div`
  margin: 44px 50px 0px;
  text-align: start;
  font-size: 20px;
  color: #4b4b4b;
  cursor: pointer;
  font-weight: 300;
  font-family: 'Noto Sans KR';
`;

export const EducationUpdateLast = styled.div`
  margin: 44px 50px 0px;
  text-align: start;
  font-size: 20px;
  color: #4b4b4b;
  cursor: pointer;
  font-weight: 300;
  font-family: 'Noto Sans KR';
`;

export const EducationUpdateRight = styled.div`
  display: inline-block;
  width: 50%;
  height: 100%;
  background: url('https://storage.googleapis.com/habicastle_storage/static/main/board_background_2.png');
  background-size: 100% 100%;
`;

export const EducationMoreBtnArea = styled.div`
  width: 1200px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: 35px auto 100px;
  height: 250px;
`;

export const EducationMoreBtn = styled.div`
  width: 280px;
  display: inline-block;
  cursor: pointer;
`;

export const EducationMoreBtnImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const AdImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

export const MainContentDiv2BannerWrapper = styled(Link)`
  width: 100%;
  height: 92px;
  margin: 15px 0;
`;

export const MainContentDiv2Banner = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

export const MainContentDiv4Link = styled(Link)`
  width: 100%;
  height: 103px;
`;

export const MainContentDiv4ArticleWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  margin: 20px 0 8px;
  border: 1px solid #dedede;
`;

export const MainContentDiv4TabAreaWrapper = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
`;

export const MainContentDiv4TabAreaItem = styled.div<{ active: boolean }>`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.active ? '#FFFFFF' : '#DEDEDE')};
  cursor: pointer;
`;

export const MainContentDiv4ArticleList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 170px;
  padding: 10px;
  gap: 12px;
`;

export const MainContentDiv4ArticleItem = styled(Link)`
  width: 100%;
  display: flex;
  gap: 3px;
  font-size: 12px;
  align-items: center;
  color: #4d4d4d;
  position: relative;
`;

export const MainContentDiv4ArtivleItemDate = styled.div`
  font-size: 10px;
  color: #ababab;
  position: absolute;
  right: 0;
`;

export const MainContentDiv4ArtivleNoItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ababab;
`;

export const MainContentDiv4CustomerWrapper = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #dedede;
`;

export const MainContentDiv4CustomerTitleBig = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 22px;
  color: #4d4d4d;
`;

export const MainContentDiv4CustomerTitleSmall = styled.div`
  font-size: 16px;
  color: #ababab;
`;

export const MainContentDiv4CustomerPhone = styled.div`
  font-size: 26px;
  font-weight: bold;
  color: #0091ff;
`;

export const MainContentDiv4CustomerExplanation = styled.div`
  display: flex;
  font-size: 12px;
  color: #4d4d4d;
  font-weight: bold;
  align-items: center;
  justify-content: center;
`;

export const MainContentDiv4CustomerIconList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const MainContentDiv4CustomerIconWrapper = styled.div`
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const MainContentDiv4CustomerIcon = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`;

export const MainContentDiv4CustomerIconText = styled.div`
  font-size: 10px;
  color: #bebebe;
`;

import { notLoginedApi, loginedApi } from './api-base';

export const userApi = {
  login: (Data: FormData) => notLoginedApi.post('/users/sign/login', Data),

  registerAdmin: (Data: FormData) => notLoginedApi.post('/users/admincrud', Data),

  registerUser: (Data: FormData) => notLoginedApi.post('/users/usercrud', Data),

  idDupCheck: (Data: FormData) => notLoginedApi.post('/users/idDupCheck', Data),

  sendRegMail: (Data: FormData) => notLoginedApi.post('/users/sendRegMail', Data),

  checkRegno: (Data: FormData) => notLoginedApi.post('/users/checkRegno', Data),

  registerIndividual: (Data: FormData) => notLoginedApi.post('/users/registerIndividual', Data),

  assignAgency: (token: any, Data: FormData) =>
    loginedApi.post('/users/assign', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  getUserList: (token: any, Data: FormData) =>
    loginedApi.post('/users/getList', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  updateUserType: (token: any, Data: FormData) =>
    loginedApi.post('/users/updateUserType', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
};

import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';

import Header from '../header';
import Footer from '../footer';

interface NotFoundProps extends RouteComponentProps {}

const NotFoundContainer = (props: NotFoundProps) => (
  <>
    <Header {...props} />
    <Container>
      <Img src={'/static/img/404page.png'} />
    </Container>
    <Footer {...props} />
  </>
);

const Container = styled.div`
  max-width: 1180px;
  width: 100%;
  height: 100%;
  margin: 100px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
`;
export default NotFoundContainer;

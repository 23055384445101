import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';

import DocuformPresenter from './DocuformPresenter';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { comnApi } from './../../api/api-comn';
import { menuApi } from './../../api/api-menu';
import { programApi } from './../../api/api-program';
import * as recoilItem from '../../util/recoilitem';
import * as util from '../../util/util';

interface DocuformProps extends RouteComponentProps {}

const DocuformContainer = (props: DocuformProps) => {
  const token = useRecoilValue(recoilItem.token);
  const [programId, setProgramId] = useRecoilState(recoilItem.programId);

  const blockPageNum: string = '5';
  const pagePostCount: string = '12';

  const [typeList, setTypeList] = useState([]);
  const [holderList, setHolderList] = useState([]);

  const [selectedType, setSelectedType] = useState<any>(null);

  const [selectedHolderList, setSelectedHolderList] = useState<
    { comnCd: string; comnCdNm: string }[]
  >([]);

  const [programList, setProgramList] = useState(null);

  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [pageNum, setPageNum] = useState(1);
  const [pageList, setPageList] = useState([]);
  const [isFirst, setIsFirst] = useState(false);
  const [lastPageNum, setLastPageNum] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [totalItemLength, setTotalItemLength] = useState(0);

  const onChangeType = (item: any) => {
    if (!item) {
      setSelectedType(null);
      return;
    }
    setSelectedType({ comnCd: item.comnCd, comnCdNm: item.comnCdNm });
    setPageNum(1);
  };

  const onChangeHolderList = (item: any) => {
    if (!item) {
      setSelectedHolderList([]);
      return;
    }
    const holder = { comnCd: item.comnCd, comnCdNm: item.comnCdNm };

    // let tempHolderList = [...selectedHolderList];

    // if (tempHolderList.map((item: any) => item.comnCd).includes(holder.comnCd)) {
    //   const idx: number = tempHolderList.map((item: any) => item.comnCd).indexOf(holder.comnCd);
    //   tempHolderList.splice(idx, 1);
    // } else {
    //   tempHolderList.push(holder);
    // }

    // setSelectedHolderList(tempHolderList);
    setSelectedHolderList([holder]);
    setPageNum(1);
  };

  const firstFetchData = async () => {
    setIsLoading(true);

    let res1: any = null;
    let res2: any = null;
    let res3: any = null;

    let formData: FormData = new FormData();

    formData.append('topMenu', 'MN04');
    formData.append('subMenu', 'MN0403');
    formData.append('page', pageNum.toString());
    formData.append('blockPageNum', blockPageNum);
    formData.append('pagePostCount', pagePostCount);

    try {
      res1 = await comnApi.getCd('TYPE03');
      res2 = await comnApi.getCd('HOL01');
      res3 = await programApi.filter(token, formData);
      if (res1.data.rsltCd === '00') {
        setTypeList(res1.data.ComnCd.subCdList);
      }
      if (res2.data.rsltCd === '00') {
        setHolderList(res2.data.ComnCd.subCdList);
      }
      if (res3.data.rsltCd === '00') {
        let resultList = res3.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res3.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setProgramList(resultList);
        setIsFirst(res3.data.programList.first);
        setIsLast(res3.data.programList.last);
        setLastPageNum(res3.data.programList.totalPages);
        setPageList(res3.data.pageList);
        setTotalItemLength(res3.data.programList.totalElements);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const unableDownload = () => {
    util.makeMsg('해당하는 파일이 없습니다.', 'error');
  };

  const fetchData = async () => {
    setIsLoading(true);

    let res: any = null;

    let formData: FormData = new FormData();

    formData.append('topMenu', 'MN04');
    formData.append('subMenu', 'MN0403');
    if (selectedType !== null) {
      formData.append('type', selectedType.comnCd);
    }
    formData.append('page', pageNum.toString());
    formData.append('blockPageNum', blockPageNum);
    formData.append('pagePostCount', pagePostCount);
    if (selectedHolderList.length > 0) {
      formData.append('holder', selectedHolderList.map((item: any) => item.comnCd).join(','));
    }

    try {
      res = await programApi.filter(token, formData);

      if (res.data.rsltCd === '00') {
        let resultList = res.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setProgramList(resultList);
        setIsFirst(res.data.programList.first);
        setIsLast(res.data.programList.last);
        setLastPageNum(res.data.programList.totalPages);
        setPageList(res.data.pageList);
        setTotalItemLength(res.data.programList.totalElements);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    firstFetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedType, selectedHolderList, pageNum]);

  return (
    <>
      <Header {...props} />
      <DocuformPresenter
        typeList={typeList}
        holderList={holderList}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        selectedHolderList={selectedHolderList}
        onChangeType={onChangeType}
        onChangeHolderList={onChangeHolderList}
        programList={programList}
        setProgramId={setProgramId}
        unableDownload={unableDownload}
        pageNum={pageNum}
        setPageNum={setPageNum}
        pageList={pageList}
        lastPageNum={lastPageNum}
        isFirst={isFirst}
        isLast={isLast}
      ></DocuformPresenter>
      <Footer {...props} />
    </>
  );
};

export default DocuformContainer;

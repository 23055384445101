import * as s from '../YearltPlanStyled';
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import YearlyWeek from './YearlyWeek';
import BeatLoader from 'react-spinners/BeatLoader';

interface YearlyMonthProps extends RouteComponentProps {
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  months: {
    monthId: number | undefined;
    month: number | undefined;
    num: number | undefined;
    week: number;
    file: any;
    subject: string | undefined;
    uploadStatus: string;
    weeks: {
      weekId: number | undefined;
      week: number | undefined;
      subject: string | undefined;
      file: any;
      uploadStatus: string;
    }[];
  }[];
  monthIndex: number;
  setMonths: React.Dispatch<
    React.SetStateAction<
      {
        monthId: number | undefined;
        month: number | undefined;
        num: number | undefined;
        week: number;
        file: any;
        subject: string | undefined;
        uploadStatus: string;
        weeks: {
          weekId: number | undefined;
          week: number | undefined;
          subject: string | undefined;
          file: any;
          uploadStatus: string;
        }[];
      }[]
    >
  >;
  submitYearlyPlanMonth: (index: number) => Promise<false | undefined>;
  removeYearlyPlanMonth: (index: number) => void;
}

const YearlyMonth = (props: YearlyMonthProps) => {
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files) => {
      let tempList = [...props.months];
      tempList[props.monthIndex].file = files[0];
      tempList[props.monthIndex].uploadStatus = 'exist';
      props.setMonths(tempList);
    },
  });
  const [isModifying, setIsModifying] = useState<boolean>(false);

  const onChangeMonth = (text: string, type: string) => {
    let tempList = [...props.months];
    let numberRegex = /^[0-9]*$/;
    switch (type) {
      case 'month':
        if (numberRegex.test(text)) {
          tempList[props.monthIndex].month = parseInt(text) ? parseInt(text) : undefined;
          props.setMonths(tempList);
        }
        break;
      case 'num':
        if (numberRegex.test(text)) {
          tempList[props.monthIndex].num = parseInt(text) ? parseInt(text) : undefined;
          props.setMonths(tempList);
        }
        break;
      case 'subject':
        tempList[props.monthIndex].subject = text;
        props.setMonths(tempList);
        break;
      case 'week':
        if (numberRegex.test(text)) {
          tempList[props.monthIndex].week = parseInt(text);
          let weekList: {
            weekId: number | undefined;
            week: number | undefined;
            subject: string | undefined;
            uploadStatus: string;
            file:
              | File
              | {
                  fileId: number;
                  filePath: string;
                  saveFileName: string;
                  originalFileName: string;
                  fileUrl: string;
                }
              | undefined;
          }[] = [];
          Array.from({ length: parseInt(text) }).map((item, index) =>
            !tempList[props.monthIndex].weeks[index]
              ? weekList.push({
                  weekId: undefined,
                  week: index + 1,
                  subject: undefined,
                  file: undefined,
                  uploadStatus: 'ready',
                })
              : weekList.push(tempList[props.monthIndex].weeks[index]),
          );
          tempList[props.monthIndex].weeks = weekList;
          props.setMonths(tempList);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!props.months[props.monthIndex].monthId) {
      setIsModifying(true);
    } else {
      setIsModifying(false);
    }
  }, [props.option]);

  return (
    <s.YealyPlanUploadContentArea>
      <s.YealyPlanUploadContentAreaHeader>
        <s.YealyPlanUploadContentAreaHeaderLeft>
          <s.YealyPlanUploadContentAreaHeaderIcon
            src={!isModifying ? '/static/img/pen.png' : '/static/img/red_pen.png'}
          />
          <s.YealyPlanUploadContentAreaHeaderLeftText
            onClick={() => (!isModifying ? setIsModifying(true) : null)}
            isColor={!isModifying}
          >
            수정
          </s.YealyPlanUploadContentAreaHeaderLeftText>
        </s.YealyPlanUploadContentAreaHeaderLeft>
        <s.YealyPlanUploadContentAreaHeaderRight>
          {props.months[props.monthIndex].uploadStatus === 'loading' ? (
            <BeatLoader size={8} color={'#acacac'} />
          ) : (
            <>
              <s.YealyPlanUploadContentAreaHeaderRightText
                onClick={() => isModifying && props.removeYearlyPlanMonth(props.monthIndex)
                }
                isColor={isModifying}
                >삭제</s.YealyPlanUploadContentAreaHeaderRightText>
              <s.YealyPlanUploadContentAreaHeaderIcon
                src={isModifying ? '/static/img/check.png' : '/static/img/green_check.png'}
              />
              <s.YealyPlanUploadContentAreaHeaderRightText
                onClick={() =>
                  isModifying && props.submitYearlyPlanMonth(props.monthIndex)
                    ? setIsModifying(false)
                    : null
                }
                isColor={isModifying}
              >
                완료
              </s.YealyPlanUploadContentAreaHeaderRightText>
            </>
          )}
        </s.YealyPlanUploadContentAreaHeaderRight>
      </s.YealyPlanUploadContentAreaHeader>
      <s.YealyPlanUploadMonthArea>
        <s.YealyPlanUploadMonthAreaHeader>
          {isModifying ? (
            <s.YealyPlanUploadMonthAreaInput
              value={
                props.months[props.monthIndex].month ? props.months[props.monthIndex].month : ''
              }
              onChange={(e) => onChangeMonth(e.target.value, 'month')}
              placeholder="입력"
            />
          ) : null}
          <s.YealyPlanUploadMonthAreaHeaderText style={{ cursor: 'default' }}>
            {isModifying
              ? '월'
              : props.months[props.monthIndex].month
              ? props.months[props.monthIndex].month + '월'
              : ''}
          </s.YealyPlanUploadMonthAreaHeaderText>
          {isModifying ? (
            <s.YealyPlanUploadMonthAreaInput
              value={props.months[props.monthIndex].num ? props.months[props.monthIndex].num : ''}
              onChange={(e) => onChangeMonth(e.target.value, 'num')}
              placeholder="입력"
            />
          ) : null}
          <s.YealyPlanUploadMonthAreaHeaderText style={{ cursor: 'default' }}>
            {isModifying
              ? '호'
              : props.months[props.monthIndex].num
              ? props.months[props.monthIndex].num + '호'
              : ''}
          </s.YealyPlanUploadMonthAreaHeaderText>
          {isModifying ? (
            <>
              <s.YealyPlanUploadMonthAreaSelectWeek
                id={`weekselect-${props.monthIndex}-0`}
                value={'0'}
                defaultChecked={props.months[props.monthIndex].week === 0}
                type={'radio'}
              />
              <s.YealyPlanUploadMonthAreaSelectWeek
                id={`weekselect-${props.monthIndex}-1`}
                value={'3'}
                defaultChecked={props.months[props.monthIndex].week === 3}
                onChange={(e) =>
                  props.months[props.monthIndex].week === 3
                    ? onChangeMonth('0', 'week')
                    : onChangeMonth(e.currentTarget.value, 'week')
                }
                type={'checkbox'}
              />
              <s.YealyPlanUploadMonthAreaSelectWeekLabel
                htmlFor={`weekselect-${props.monthIndex}-1`}
              >
                {props.months[props.monthIndex].week === 3 ? (
                  <s.YealyPlanUploadMonthAreaSelectWeekLabelIcon src="/static/img/check.png" />
                ) : null}
              </s.YealyPlanUploadMonthAreaSelectWeekLabel>
              <s.YealyPlanUploadMonthAreaHeaderText htmlFor={`weekselect-${props.monthIndex}-1`}>
                1~3주
              </s.YealyPlanUploadMonthAreaHeaderText>
              <s.YealyPlanUploadMonthAreaSelectWeek
                id={`weekselect-${props.monthIndex}-2`}
                value={'4'}
                defaultChecked={props.months[props.monthIndex].week === 4}
                onChange={(e) =>
                  props.months[props.monthIndex].week === 4
                    ? onChangeMonth('0', 'week')
                    : onChangeMonth(e.currentTarget.value, 'week')
                }
                type={'checkbox'}
              />
              <s.YealyPlanUploadMonthAreaSelectWeekLabel
                htmlFor={`weekselect-${props.monthIndex}-2`}
              >
                {props.months[props.monthIndex].week === 4 ? (
                  <s.YealyPlanUploadMonthAreaSelectWeekLabelIcon src="/static/img/check.png" />
                ) : null}
              </s.YealyPlanUploadMonthAreaSelectWeekLabel>
              <s.YealyPlanUploadMonthAreaHeaderText htmlFor={`weekselect-${props.monthIndex}-2`}>
                1~4주
              </s.YealyPlanUploadMonthAreaHeaderText>
              <s.YealyPlanUploadMonthAreaSelectWeek
                id={`weekselect-${props.monthIndex}-3`}
                value={'5'}
                defaultChecked={props.months[props.monthIndex].week === 5}
                onChange={(e) =>
                  props.months[props.monthIndex].week === 5
                    ? onChangeMonth('0', 'week')
                    : onChangeMonth(e.currentTarget.value, 'week')
                }
                type={'checkbox'}
              />
              <s.YealyPlanUploadMonthAreaSelectWeekLabel
                htmlFor={`weekselect-${props.monthIndex}-3`}
                onClick={() =>
                  props.months[props.monthIndex].week === 5 ? onChangeMonth('0', 'week') : null
                }
              >
                {props.months[props.monthIndex].week === 5 ? (
                  <s.YealyPlanUploadMonthAreaSelectWeekLabelIcon src="/static/img/check.png" />
                ) : null}
              </s.YealyPlanUploadMonthAreaSelectWeekLabel>
              <s.YealyPlanUploadMonthAreaHeaderText htmlFor={`weekselect-${props.monthIndex}-3`}>
                1~5주
              </s.YealyPlanUploadMonthAreaHeaderText>
            </>
          ) : (
            <s.YealyPlanUploadMonthAreaHeaderText style={{ cursor: 'default' }}>
              {props.months[props.monthIndex].week === 3
                ? '1~3주'
                : props.months[props.monthIndex].week === 4
                ? '1~4주'
                : props.months[props.monthIndex].week === 5
                ? '1~5주'
                : null}
            </s.YealyPlanUploadMonthAreaHeaderText>
          )}
        </s.YealyPlanUploadMonthAreaHeader>
        {/* <s.YealyPlanUploadMonthAreaFileUploadArea
          isDrag={isDragActive}
          {...getRootProps({ className: 'dropzone' })}
        >
          <s.YealyPlanUploadMonthAreaFileUploadAreaInput {...getInputProps()} />
          {props.months[props.monthIndex].uploadStatus !== 'ready' ? (
            <s.YealyPlanUploadMonthAreaFileUploadAreaButtonIcon
              onClick={open}
              src="/static/img/pdf_icon_1.png"
            />
          ) : null}
          <s.YealyPlanUploadMonthAreaFileUploadAreaText>
            {props.months[props.monthIndex].uploadStatus !== 'ready'
              ? props.months[props.monthIndex].file.originalFileName
                ? props.months[props.monthIndex].file.originalFileName
                : props.months[props.monthIndex].file.name
              : '월간계획안'}
          </s.YealyPlanUploadMonthAreaFileUploadAreaText>
          <s.YealyPlanUploadMonthAreaFileUploadAreaButtonIcon
            onClick={open}
            src={
              props.months[props.monthIndex].uploadStatus !== 'ready'
                ? '/static/img/file_exist.png'
                : '/static/img/selectFile.png'
            }
          />
        </s.YealyPlanUploadMonthAreaFileUploadArea> */}
        <s.YealyPlanUploadMonthAreaSubjectArea>
          <s.YealyPlanUploadMonthAreaSubjectAreaLabel>
            생활주제
          </s.YealyPlanUploadMonthAreaSubjectAreaLabel>
          {isModifying ? (
            <s.YealyPlanUploadMonthAreaSubjectAreaInput
              value={
                props.months[props.monthIndex].subject ? props.months[props.monthIndex].subject : ''
              }
              onChange={(e) => onChangeMonth(e.target.value, 'subject')}
              placeholder={'텍스트를 입력해주세요.'}
            />
          ) : (
            <s.YealyPlanUploadMonthAreaSubjectAreaText>
              : {props.months[props.monthIndex].subject}
            </s.YealyPlanUploadMonthAreaSubjectAreaText>
          )}
        </s.YealyPlanUploadMonthAreaSubjectArea>
      </s.YealyPlanUploadMonthArea>
      {Array.from({ length: props.months[props.monthIndex].week }).map((item, weekIndex) => (
        <YearlyWeek
          key={'Week' + weekIndex}
          {...props}
          weekIndex={weekIndex}
          isModifying={isModifying}
        />
      ))}
    </s.YealyPlanUploadContentArea>
  );
};

export default YearlyMonth;

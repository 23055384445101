import * as s from '../UpdateEduStyled';

interface SelectFieldProps {
  option: {
    field: string;
    visible: string;
    age: string;
    mediaSubMenu: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
  fieldList: {
    menuCd: string;
    menuNm: string;
    viewOrder: number;
  }[];
}

const SelectField = (props: SelectFieldProps) => {
  return (
    <s.UploadProgramAreaHeader>
      <s.UploadProgramAreaSelectHeader>놀이 선택</s.UploadProgramAreaSelectHeader>
      <s.UploadProgramAreaSelectDv />
      <s.UploadProgramAreaHeaderSelect>
        {props.fieldList.map((type: any) => (
          <>
            <s.UploadProgramAreaSelectType
              id={type.menuCd}
              value={type.menuCd}
              defaultChecked={props.option.field === type.menuCd}
              onChange={(e: any) =>
                props.option.field === e.target.value
                  ? props.onChangeOption('field', '')
                  : props.onChangeOption('field', e.target.value)
              }
              type={'checkbox'}
              disabled={!props.isModifying}
            />
            <s.UploadProgramAreaSelectTypeLabel htmlFor={type.menuCd}>
              {props.option.field === type.menuCd ? (
                <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
              ) : null}
            </s.UploadProgramAreaSelectTypeLabel>
            <s.UploadProgramAreaSelectText htmlFor={type.menuCd}>
              {type.menuNm}
            </s.UploadProgramAreaSelectText>
          </>
        ))}
      </s.UploadProgramAreaHeaderSelect>
    </s.UploadProgramAreaHeader>
  );
};

export default SelectField;

import React, { useState, useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import OrderRegisterPresenter from './OrderRegisterPresenter';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import * as recoilItem from '../../../util/recoilitem';
import queryString from 'query-string';
import * as util from '../../../util/util';
import { comnApi } from '../../../api/api-comn';
import { shoppingApi } from '../../../api/api-shopping';
import DaumPostcode from 'react-daum-postcode';

interface OrderRegisterProps extends RouteComponentProps {}

const OrderRegisterContainer = (props: OrderRegisterProps) => {
  const token = useRecoilValue(recoilItem.token);
  const [productList, setProductList] = useState<any[]>([]);

  const [address, setAddress] = useState(''); // 주소
  const [addressDetail, setAddressDetail] = useState(''); // 상세주소
  const [isOpenPost, setIsOpenPost] = useState(false);

  const [mobile, setMobile] = useState('');
  const [telno, setTelno] = useState('');
  const [orderDetail, setOrderDetail] = useState('');

  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };

  const onCompletePost = (data: any) => {
    let fullAddr = data.address;
    let extraAddr = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddr += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
    }

    setAddress(data.zonecode);
    setAddressDetail(fullAddr);
    setIsOpenPost(false);
  };

  const location = useLocation<{ orderProductList: [] }>();

  useEffect(() => {
    console.log(location.state.orderProductList);
    setProductList(location.state.orderProductList);
  }, []);

  const registerOrder = async () => {
    let formData = new FormData();

    if (!address || !addressDetail || !mobile || !telno) {
      util.makeMsg('필수값을 입력해주세요', 'warning');
      return;
    }

    console.log(address);
    console.log(addressDetail);
    console.log(mobile);
    console.log(telno);
    console.log(orderDetail);
    console.log(productList);

    formData.append('zipCode', address);
    formData.append('address', addressDetail);
    formData.append('mobile', mobile);
    formData.append('telno', telno);
    formData.append('orderDetail', orderDetail);
    productList.map((item: any, index: number) => {
      formData.append(`orderProducts[${index}].productId`, productList[index].productId);
      formData.append(`orderProducts[${index}].count`, productList[index].count);
    });

    let res = await shoppingApi.registerOrder(token, formData);

    if (res.data.rsltCd === '00') {
      util.makeMsg('주문이 완료되었습니다', 'success');
      props.history.push('/shopping');
    } else {
      util.makeMsg('주문에 실패했습니다', 'error');
    }
  };

  return (
    <>
      {isOpenPost ? (
        <DaumPostcode
          style={{ position: 'absolute', width: '100vw', height: '100vh', zIndex: '99999' }}
          autoClose
          onComplete={onCompletePost}
        />
      ) : null}
      <Header {...props} />
      <OrderRegisterPresenter
        productList={productList}
        onChangeOpenPost={onChangeOpenPost}
        onCompletePost={onCompletePost}
        isOpenPost={isOpenPost}
        address={address}
        addressDetail={addressDetail}
        setAddressDetail={setAddressDetail}
        mobile={mobile}
        setMobile={setMobile}
        telno={telno}
        setTelno={setTelno}
        orderDetail={orderDetail}
        setOrderDetail={setOrderDetail}
        registerOrder={registerOrder}
      ></OrderRegisterPresenter>
      <Footer {...props} />
    </>
  );
};

export default OrderRegisterContainer;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1180px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const TopTitle = styled.div`
  margin: 0 0 20px;
  font-size: 28px;
  font-weight: bold;
  color: #4d4d4d;
  text-align: left;
`;

export const TopTableArea = styled.div`
  width: 1075px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
`;

export const TopTable = styled.table`
  width: 1075px;
  border-spacing: 0px;
  border-collapse: collapse;
  border-top: 1px solid #4d4d4d;
  border-bottom: 1px solid #4d4d4d;
`;

export const TopTbody = styled.tbody``;

export const TopTr = styled.tr``;

export const TopTh = styled.th`
  text-align: center;
  padding: 5px 10px;
  background-color: #efefef;
  color: #4d4d4d;
  font-size: 16px;
  border-right: 1px solid #cecece;
  font-weight: normal;
`;

export const TopTd = styled.td`
  border-top: 1px solid #bebebe;
  text-align: center;
  padding: 10px 30px;
  color: #4d4d4d;
  font-size: 18px;
  border-right: 1px solid #cecece;
`;

export const TopTdSpan = styled.span`
  font-size: 18px;
`;

export const TopThSpan = styled.span`
  width: 100%;
  text-align: right;
  color: #0091ff;
  font-size: 20px;
  font-weight: bold;
`;

export const OrderInfoArea = styled.div`
  width: 1075px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
`;

export const OrderTable = styled.table`
  width: 1075px;
  border-spacing: 0px;
  border-collapse: collapse;
  border-top: 1px solid #4d4d4d;
  border-bottom: 1px solid #4d4d4d;
`;

export const OrderTbody = styled.tbody``;

export const OrderTr = styled.tr`
  border-bottom: 1px solid #cecece;
`;

export const OrderTh = styled.th`
  text-align: center;
  padding: 5px 10px;
  background-color: #efefef;
  color: #4d4d4d;
  font-size: 16px;
  border-right: 1px solid #cecece;
  font-weight: normal;
  width: 20%;
`;

export const OrderTd = styled.td`
  text-align: left;
  padding: 5px 10px;
  color: #4d4d4d;
  font-size: 18px;

  width: 80%;
`;

export const OrderTdInput = styled.input`
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #c8c8c8;
  placeholder-color: #c8c8c8;
  color: #4d4d4d;
`;

export const OrderTdTextArea = styled.textarea`
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #c8c8c8;
  placeholder-color: #c8c8c8;
  color: #4d4d4d;
  resize: none;
  width: 90%;
  height: 100px;
`;

export const AddressTdContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const AddressTdInputButton = styled.div`
  width: 122px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0087ed;
  background-color: #ffffff;
  color: #0087ed;
  font-size: 16px;
  cursor: pointer;
`;

export const AddressTdRow = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const AddressTdInput = styled.input`
  width: 250px;
  height: 35px;
  border: 1px solid #ababab;
  padding: 0 10px;
`;

export const AddressTdInputArea = styled.textarea`
  width: 250px;
  height: 35px;
  border: 1px solid #ababab;
  padding: 10px;
  resize: none;
`;

export const PaymentInfoArea = styled.div`
  width: 1075px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
`;

export const PaymentSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaymentSelectRadio = styled.input`
  font-size: 16px;
  color: #4d4d4d;
  margin-right: 10px;
`;

export const PaymentSelectRadioLabel = styled.label`
  font-size: 16px;
  color: #4d4d4d;
  margin-right: 30px;
`;

export const PaymentButton = styled.div`
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #0091ff;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  margin: 30px auto 50px;
`;

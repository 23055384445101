import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import * as s from './RegisterSelectStyled';

interface RegisterSelectProps extends RouteComponentProps {
  level1Menu: number;
  setLevel1Menu: React.Dispatch<React.SetStateAction<number>>;
  changeMenu: (index: number) => void;
}

const RegisterSelectPresenter = (props: RegisterSelectProps) => {
  return (
    <s.Container>
      <s.SelectArea>
        <s.Level1Area>
          <s.LevelItem>회원 생성</s.LevelItem>
          <s.LevelItem>교육계획안</s.LevelItem>
          <s.LevelItem>멀티미디어</s.LevelItem>
          <s.LevelItem>교수자료실</s.LevelItem>
          <s.LevelItem></s.LevelItem>
          <s.LevelItem>교육프로그램</s.LevelItem>
          <s.LevelItem>시스템</s.LevelItem>
          <s.LevelItem>쇼핑몰</s.LevelItem>
        </s.Level1Area>
        <s.Level2Area>
          <s.LevelLinkWrapper>
            <s.LevelLink to="/register">회원 생성</s.LevelLink>
            <s.LevelLink to="/userList">회원 관리</s.LevelLink>
          </s.LevelLinkWrapper>
          <s.LevelLinkWrapper>
            <s.LevelLink to="/regactivityplan">1.계획안 팝업 생성</s.LevelLink>
            <s.LevelLink to="/regplan">2.계획안 생성</s.LevelLink>
            <s.LevelLink to="/updateActivityPlan">계획안 팝업 수정</s.LevelLink>
          </s.LevelLinkWrapper>
          <s.LevelLinkWrapper>
            <s.LevelLink to="/registerProgram?type=MN05">멀티미디어 생성</s.LevelLink>
            <s.LevelLink to="/updateProgram">프로그램 수정</s.LevelLink>
          </s.LevelLinkWrapper>
          <s.LevelLinkWrapper>
            <s.LevelLink to="/registerProgram?type=MN0401">활동지 생성</s.LevelLink>
            <s.LevelLink to="/registerProgram?type=MN0402">동요/음원 생성</s.LevelLink>
            <s.LevelLink to="/registerProgram?type=MN0403">문서/서식 생성</s.LevelLink>
            <s.LevelLink to="/registerProgram?type=MN0404">
              가정통신문
              <br />
              부모교육
              <br />
              묶음자료 생성
            </s.LevelLink>
            <s.LevelLink to="/registerProgram?type=MN0406">지침서 생성</s.LevelLink>
          </s.LevelLinkWrapper>
          <s.LevelLinkWrapper>
            <s.LevelLink to="/registerProgram?type=MN0408">교수동영상 생성</s.LevelLink>
          </s.LevelLinkWrapper>
          <s.LevelLinkWrapper>
            <s.LevelLink to="/registerEdu">교육프로그램 생성</s.LevelLink>
            <s.LevelLink to="/updateVisibleEdu">프로그램 수정</s.LevelLink>
            <s.LevelLink to="/registerEduImage">프로그램 사진등록</s.LevelLink>
          </s.LevelLinkWrapper>
          <s.LevelLinkWrapper>
            <s.LevelLink to="/registerBulkEdu">교육프로그램 벌크 생성</s.LevelLink>
            <s.LevelLink to="/registerBulkProgram">프로그램 벌크 생성</s.LevelLink>
            <s.LevelLink to="/banner">배너 관리</s.LevelLink>
            <s.LevelLink to="/shopping/order/list">주문관리</s.LevelLink>
          </s.LevelLinkWrapper>
          <s.LevelLinkWrapper>
            <s.LevelLink to="/shopping/open/create">제품등록</s.LevelLink>
            <s.LevelLink to="/shopping/admin/orders">주문관리</s.LevelLink>
          </s.LevelLinkWrapper>
        </s.Level2Area>
      </s.SelectArea>
      {/* <s.ButtonArea>
        <s.Button to="/register">회원 생성</s.Button>
        <s.Button to="/regplan">계획안 생성</s.Button>
        <s.Button to="/regactivityplan">계획안 팝업 생성</s.Button>
        <s.Button to="/registerEdu">교육프로그램 생성</s.Button>
        <s.Button to="/registerProgram?type=MN05">멀티미디어 생성</s.Button>
        <s.Button to="/registerProgram?type=MN0401">활동지 생성</s.Button>
        <s.Button to="/registerProgram?type=MN0402">동요/음원 생성</s.Button>
        <s.Button to="/registerProgram?type=MN0403">문서/서식 생성</s.Button>
        <s.Button to="/registerProgram?type=MN0404">가정통신문 생성</s.Button>
        <s.Button to="/registerProgram?type=MN0406">지침서 생성</s.Button>
        <s.Button to="/registerBulkProgram">프로그램 벌크 생성</s.Button>
        <s.Button to="/registerBulkEdu">교육프로그램 벌크 생성</s.Button>
        <s.Button to="/updateVisibleEdu">교육프로그램 수정</s.Button>
        <s.Button to="/updateProgram">프로그램 수정</s.Button>
        <s.Button to="/banner">배너 관리</s.Button>
        <s.Button to="/updateActivityPlan">계획안 팝업 수정</s.Button>
      </s.ButtonArea> */}
    </s.Container>
  );
};

export default RegisterSelectPresenter;

import styled from 'styled-components';

export const Container = styled.div`
  width: 1091px;
  margin: 0 auto;
  text-align: center;
`;
export const PlanningDiv = styled.div``;
export const PlanNotFoundText = styled.div`
  display: flex;
  margin: 100px auto;
  color: #0087ed;
  font-size: 20px;
  display: inline-block;
  line-height: 32px;
  font-weight: 800;
`;

export const PlanningTitleArea = styled.div`
  width: 100%;
  margin: 20px auto 0;
  height: auto;
  padding: 0px;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: space-between;
`;

export const PlanningTitle = styled.div`
  font-size: 18px;
  display: inline-block;
  line-height: 32px;
  font-weight: 500;
`;

export const PlanningTitleSpan = styled.span`
  color: #0087ed;
`;




export const PlanningAllDownloadBtn = styled.div`
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #0087ed;
  width: 210px;
  height: 27px;
  cursor: pointer;
`;

export const DownloadBtnIcon = styled.img`
  width: 16px;
  height: 14px;
  display: inline-block;
  margin: 0px 3px;
`;

export const PlanningAllDownloadText = styled.div`
display: inline-block;
font-weight: 500;
line-height: 20px;
font-size: 14px;
color: #4d4d4d;
vertical-align: bottom;
`;

export const YearlyPlanTable = styled.div`
  width: 1091px;
  margin: 10px auto;
  margin-bottom: 50px;
  height: auto;
  border: 2px solid #e2e2e2;
  border-top: 2px solid #0087ED;
`;

export const YearlyPlanTableHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  color: #4D4D4D;
  font-size: 16px;
  background-color: #C7E7FF;
`;

export const YearlyPlanTableHeaderMonth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 169px;
  height: 44px;
`;

export const YearlyPlanTableHeaderSubject = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 369px;
  height: 44px;
  border-left: 1px solid #ccc;
`;

export const YearlyPlanTableHeaderWeek = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 44px;
  border-left: 1px solid #ccc;
`;

export const YearlyPlanTableHeaderDetailSubject = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 304px;
  height: 44px;
  border-left: 1px solid #ccc;
`;

export const YearlyPlanTableHeaderDownload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 166px;
  height: 44px;
  border-left: 1px solid #ccc;
`;

export const YearlyPlanMonthContent = styled.div`
  display: flex;
  width: 1091px;
  color: #4d4d4d;
`;

export const BorderLine = styled.div`
  width: 1198px;
  border: 2px solid #0087ed;
  position: relative;
  right: 1px;
`;

export const MonthContentMonthArea = styled.div`
  display: flex;
  width: 172px;
  font-weight: 500;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
`;

export const MonthContentMonth = styled.div`
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const MonthContentHolder = styled.div`
  font-size: 16px;
  color: #A7A7A7;
`;

export const MonthContentSubjectArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
  width: 368px;
  border-top: 1px solid #ccc;
  position: relative;
  font-size: 20px;
`;

export const MonthContentSubject = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const MonthContentDownloadLink = styled.div`
  display: flex;
  width: 136px;
  height: 27px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid #0087ed;
  margin: 10px 20px 20px 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const MonthContentSubjectFirst = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
  font-size: 20px;
  font-weight: 500;
  height: 50%;
  width: 100%;
`;

export const MonthContentSubjectLast = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ccc;
  height: 50%;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
`;

export const MonthContentDownloadLink2 = styled.div`
  display: flex;
  position: absolute;
  width: 187px;
  height: 43px;
  font-size: 14px;
  border-radius: 20px;
  border-right: 1px solid #0087ed;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
`;

export const MonthContentWeekArea = styled.div`
  width: 548px;
  font-size: 14px;
`;

export const MonthContentWeekContent = styled.div`
  display: flex;
  width: 100%;
  line-height: 35px;
`;

export const MonthContentWeek = styled.div`
  width: 76px;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`;

export const MonthContentDetailSubject = styled.div`
  width: 305px;
  text-align: left;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding-left: 20px;
  line-height: 30px;
`;

export const MonthContentDownloadArea = styled.div`
  display: flex;
  width: 169px;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const MonthContentDownloadImg = styled.img`
  width: 20px;
  height: 18px;
  display: inline-block;
  
`;

export const MonthContentDownloadText = styled.div`
  font-size: 14px;
`;

// export const YearlyPlanTable = styled.table`
//   margin: 10px auto;
//   width: 80%;
//   border: none;
//   *border-collapse: collapse; /* IE7 and lower */
//   border-spacing: 0;
// `;

// export const YearlyPlanTableHead = styled.thead``;
// export const YearlyPlanTableHeadRow = styled.tr``;
// export const YearlyPlanTableHeadHeader = styled.th`
//   color: #fff;
//   font-size: 20px;
//   padding: 20px 10px;
//   background-color: #0087ed;
//   border-left: 1px solid #ccc;
//   border-right: 1px solid #ccc;

//   &:first-of-type {
//     border-top-left-radius: 15px;
//   }
//   &:last-of-type {
//     border-top-right-radius: 15px;
//   }
// `;

// export const YearlyPlanTableBody = styled.tbody``;
// export const YearlyPlanTableBodyRow = styled.tr``;
// export const YearlyPlanTableBodyMonth = styled.th`
//   font-size: 22px;
//   border-top: none;
//   border-left: 1px solid #ccc;
//   border-right: 1px solid #ccc;
//   border-bottom: 5px solid #0087ed;
//   vertical-align: middle;
//   color: #4d4d4d;
//   line-height: 24px;
//   font-weight: 800;
// `;
// export const YearlyPlanTableBodyMonthSpan = styled.div`
//   font-size: 18px;
//   color: #4d4d4d;
//   line-height: 24px;
//   margin-top: 5px;
//   font-weight: 800;
// `;
// export const YearlyPlanTableBodySubject = styled.th`
//   font-size: 20px;
//   border-top: none;
//   border-left: 1px solid #ccc;
//   border-right: 1px solid #ccc;
//   border-bottom: 1px solid #ccc;
//   vertical-align: middle;
//   color: #4d4d4d;
//   font-weight: 800;
// `;
// export const YearlyPlanTableBodySubjectLast = styled.th`
//   font-size: 20px;
//   border-top: none;
//   border-left: 1px solid #ccc;
//   border-right: 1px solid #ccc;
//   border-bottom: 5px solid #0087ed;
//   vertical-align: middle;
//   color: #4d4d4d;
//   font-weight: 800;
// `;
// export const YearlyPlanTableBodyWeek = styled.th`
//   font-size: 16px;
//   border-top: none;
//   border-left: 1px solid #ccc;
//   border-right: 1px solid #ccc;
//   border-bottom: 1px solid #ccc;
//   vertical-align: middle;
//   color: #4d4d4d;
//   font-weight: 400;
// `;
// export const YearlyPlanTableBodyWeekLast = styled.th`
//   font-size: 16px;
//   border-top: none;
//   border-left: 1px solid #ccc;
//   border-right: 1px solid #ccc;
//   border-bottom: 5px solid #0087ed;
//   vertical-align: middle;
//   color: #4d4d4d;
//   font-weight: 400;
// `;
// export const YearlyPlanTableBodySubSubject = styled.th`
//   font-size: 16px;
//   border-top: none;
//   border-left: 1px solid #ccc;
//   border-right: 1px solid #ccc;
//   border-bottom: 1px solid #ccc;
//   vertical-align: middle;
//   color: #4d4d4d;
//   font-weight: 400;
//   text-align: start;
//   padding-left: 10px;
// `;
// export const YearlyPlanTableBodySubSubjectLast = styled.th`
//   font-size: 16px;
//   border-top: none;
//   border-left: 1px solid #ccc;
//   border-right: 1px solid #ccc;
//   border-bottom: 5px solid #0087ed;
//   vertical-align: middle;
//   color: #4d4d4d;
//   font-weight: 400;
//   text-align: start;
//   padding-left: 10px;
// `;
// export const YearlyPlanTableBodyDownload = styled.th`
//   font-size: 13px;
//   border-top: none;
//   border-left: 1px solid #ccc;
//   border-right: 1px solid #ccc;
//   border-bottom: 1px solid #ccc;
//   vertical-align: middle;
//   color: #4d4d4d;
//   font-weight: 400;
//   line-height: 20px;
//   height: 60px;
// `;
// export const YearlyPlanTableBodyDownloadLast = styled.th`
//   font-size: 13px;
//   border-top: none;
//   border-left: 1px solid #ccc;
//   border-right: 1px solid #ccc;
//   border-bottom: 5px solid #0087ed;
//   vertical-align: middle;
//   color: #4d4d4d;
//   font-weight: 400;
//   line-height: 20px;
//   height: 60px;
// `;

// export const PlanningMonthDownloadBtn = styled.div`
//   display: inline-block;
//   border-radius: 20px;
//   width: 180px;
//   border: 1px solid #0087ed;
//   padding: 5px 20px;
//   margin: 30px auto 0;
// `;
// export const PlanningMonthAbsoluteDownloadBtn = styled.div`
//   background: #fff;
//   position: absolute;
//   width: 180px;
//   border-radius: 20px;
//   border: 1px solid #0087ed;
//   padding: 5px 20px;
//   margin: 30px 25px 0;
// `;

// export const MonthDownloadBtnIcon = styled.img`
//   width: 20px;
//   height: 18px;
//   display: inline-block;
//   margin: 0px 5px;
// `;

// export const PlanningMonthDownloadText = styled.div`
//   display: inline-block;
//   font-size: 13px;
//   font-weight: 400;
//   line-height: 20px;
//   color: #4d4d4d;
//   vertical-align: bottom;
// `;


export const TextBullet = styled.div`
  width: 3px;
  height: 3px;
  background-color: #4D4D4D;
  display: inline-block;
  margin-right: 5px;
  border-radius: 10px;
  vertical-align: middle;
`
import React, { useState, useEffect } from 'react';
import * as util from '../../util/util';
import * as recoilItem from '../../util/recoilitem';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as s from './ModalStyled';

import { eduApi } from '../../api/api-edu';
import Slider from '../bigSlider/Slider';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';
import { saveAs } from 'file-saver';

import colorData from './eduinfo';

interface HeaderProps {}

const HeaderPresenter = (props: HeaderProps) => {
  const [token] = useRecoilState(recoilItem.token);
  const [eduSearch, setEduSearch] = useRecoilState(recoilItem.eduSearch);
  const userInfo = useRecoilValue(recoilItem.userInfo);

  const [edu, setEdu] = useState<any>(null);
  const [currentIdx, setCurrentIdx] = useState<number>(0);

  const [subTab, setSubTab] = useState<any>(null);
  const [currentStep, setCurrentStep] = useState<any>(null);
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);

  const fetchData = async () => {
    try {
      if (eduSearch !== null) {
        setIsLoading(true);
        await eduApi
          .getEdu(token, eduSearch.subMenu, eduSearch.type, eduSearch.holder)
          .then((res) => {
            console.log(res);
            if (res.data.rsltCd === '00') {
              setEdu(res.data.edu);
              setCurrentStep(res.data.edu.currentStep);
              res.data.edu.subTab && setSubTab(res.data.edu.subTab.menuCd);
            } else {
              setEduSearch(null);
              util.makeMsg('존재하지 않는 자료입니다.', 'warn');
            }
          });
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const reFetchData = async () => {
    try {
      if (eduSearch !== null) {
        setIsLoading(true);
        if (currentStep !== null && subTab !== null) {
          await eduApi
            .getEduWithSubTabAndStep(
              token,
              eduSearch.subMenu,
              eduSearch.type,
              eduSearch.holder,
              subTab,
              String(currentStep),
            )
            .then((res) => {
              console.log(1, res.data.edu);
              if (res.data.rsltCd === '00') {
                setEdu(res.data.edu);
              } else {
                setEduSearch(null);
                util.makeMsg('존재하지 않는 자료입니다.', 'warn');
              }
            });
        } else if (currentStep !== null) {
          console.log(eduSearch);
          console.log(currentStep);
          await eduApi
            .getEduWithStep(
              token,
              eduSearch.subMenu,
              eduSearch.type,
              eduSearch.holder,
              String(currentStep),
            )
            .then((res) => {
              console.log(2, res.data.edu);
              if (res.data.rsltCd === '00') {
                setEdu(res.data.edu);
              } else {
                setEduSearch(null);
                util.makeMsg('존재하지 않는 자료입니다.', 'warn');
              }
            });
        } else if (subTab !== null) {
          await eduApi
            .getEduWithSubTab(token, eduSearch.subMenu, eduSearch.type, eduSearch.holder, subTab)
            .then((res) => {
              console.log(3, res.data.edu);
              if (res.data.rsltCd === '00') {
                setEdu(res.data.edu);
              } else {
                setEduSearch(null);
                util.makeMsg('존재하지 않는 자료입니다.', 'warn');
              }
            });
        } else {
          await eduApi
            .getEdu(token, eduSearch.subMenu, eduSearch.type, eduSearch.holder)
            .then((res) => {
              console.log(4, res.data.edu);
              if (res.data.rsltCd === '00') {
                setEdu(res.data.edu);
              } else {
                setEduSearch(null);
                util.makeMsg('존재하지 않는 자료입니다.', 'warn');
              }
            });
        }
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setCurrentIdx(0);
    reFetchData();
  }, [subTab, currentStep]);
  useEffect(() => {
    setCurrentIdx(0);
    setCurrentStep(null);
    setSubTab(null);
    fetchData();
  }, [eduSearch]);

  const cancle = () => {
    setEduSearch(null);
    setEdu(null);
  };

  const getYoutubeId = (url: string) => {
    var videoid = url.match(
      /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/,
    );
    if (videoid != null) {
      return videoid[1];
    } else {
      return '';
    }
  };
  const ActivityProgramModal = () => {
    return (
      <s.ModalContainer>
        <s.SmallTitleArea>
          <s.TitleLeft>
            {/* <s.SmallTitleLeftIcon
                src={`/static/img/${program.field.menuCd}.png`}
              /> */}
            <s.TitleLeftText>{edu.title}</s.TitleLeftText>
          </s.TitleLeft>
          <s.TitleRight>
            {edu.stepLength !== 0 && edu.stepLength !== null ? (
              <s.TitleRightTop>
                {edu.stepLength === 4 || edu.subTab === 'MN0208' ? (
                  <s.StepSelect
                    color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['0']}
                    onClick={() => setCurrentStep(0)}
                    active={currentStep === 0}
                  >
                    0단계
                  </s.StepSelect>
                ) : null}
                <s.StepSelect
                  color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['1']}
                  onClick={() => setCurrentStep(1)}
                  active={currentStep === 1}
                >
                  1단계
                </s.StepSelect>
                <s.StepSelect
                  color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['2']}
                  onClick={() => setCurrentStep(2)}
                  active={currentStep === 2}
                >
                  2단계
                </s.StepSelect>
                {edu.subMenu.menuCd !== 'MN0215' ? (
                  <s.StepSelect
                    color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['3']}
                    onClick={() => setCurrentStep(3)}
                    active={currentStep === 3}
                  >
                    3단계
                  </s.StepSelect>
                ) : null}
              </s.TitleRightTop>
            ) : null}
            <s.TitleRightTitle>{edu.title}</s.TitleRightTitle>
            <s.TitleRightDescription>{edu.description}</s.TitleRightDescription>
          </s.TitleRight>
        </s.SmallTitleArea>
        {edu.subMenu.menuCd === 'MN0205' ? (
          <s.SmallTitleArea>
            <s.SubSelectArea>
              <s.SubSelectLabel>
                {'교육'}
                <br />
                {'선택'}
              </s.SubSelectLabel>
              <s.SubSelect
                color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['4']}
                onClick={() => setSubTab('MN0206')}
                active={subTab === 'MN0206'}
              >
                엔젤쁘레
              </s.SubSelect>
              <s.SubSelect
                color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['4']}
                onClick={() => setSubTab('MN0208')}
                active={subTab === 'MN0208'}
              >
                슈필매스
              </s.SubSelect>
            </s.SubSelectArea>
          </s.SmallTitleArea>
        ) : null}
        <Slider
          sildeList={edu.programs[0].programImages.map((item: any, index: number) => (
            <s.SlideThumbnailArea
              current={index === currentIdx}
              onClick={() => setCurrentIdx(index)}
            >
              <s.SlideThumbnail src={item.file.fileUrl} />
            </s.SlideThumbnailArea>
          ))}
          slideSize={9.5}
          currentIdx={currentIdx}
          nextBtn={<s.SlideBtn src="/static/img/Rshaft.png" />}
          prevBtn={<s.SlideBtn src="/static/img/Lshaft.png" />}
        />
        <s.MultContentArea>
          <s.ContentArea>
            {edu.programs[0].programImages && edu.programs[0].programImages.length > 0 ? (
              <s.ContentImage src={edu.programs[0].programImages[currentIdx].file.fileUrl} />
            ) : (
              <s.noContentToShow>데이터가 없습니다.</s.noContentToShow>
            )}
          </s.ContentArea>
        </s.MultContentArea>
        <s.ButtonArea>
          <s.WhiteButton
            onClick={() =>
              userInfo.userId && userInfo.userTypeCd !== 'NAIVUSER'
                ? saveAs(
                    edu.programs[0].programImages[currentIdx].file.fileUrl,
                    edu.programs[0].programImages[currentIdx].file.originalFileName,
                  )
                : console.log('')
            }
          >
            <s.DownloadBtnIcon src="/static/img/download_button.png" />
            {edu.type.comnCdNm} 다운로드
          </s.WhiteButton>
          <s.BlueButton onClick={cancle}>닫기</s.BlueButton>
        </s.ButtonArea>
      </s.ModalContainer>
    );
  };

  const SoundProgramModal = () => {
    return (
      <s.AudioModalContainer>
        <s.SmallTitleArea>
          <s.TitleLeft>
            <s.TitleLeftText>{edu.title}</s.TitleLeftText>
          </s.TitleLeft>
          <s.TitleRight>
            {edu.stepLength !== 0 && edu.stepLength !== null ? (
              <s.TitleRightTop>
                {edu.stepLength === 4 || edu.subTab === 'MN0208' ? (
                  <s.StepSelect
                    color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['0']}
                    onClick={() => setCurrentStep(0)}
                    active={currentStep === 0}
                  >
                    0단계
                  </s.StepSelect>
                ) : null}
                <s.StepSelect
                  color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['1']}
                  onClick={() => setCurrentStep(1)}
                  active={currentStep === 1}
                >
                  1단계
                </s.StepSelect>
                <s.StepSelect
                  color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['2']}
                  onClick={() => setCurrentStep(2)}
                  active={currentStep === 2}
                >
                  2단계
                </s.StepSelect>
                <s.StepSelect
                  color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['3']}
                  onClick={() => setCurrentStep(3)}
                  active={currentStep === 3}
                >
                  3단계
                </s.StepSelect>
              </s.TitleRightTop>
            ) : null}
            <s.TitleRightTitle>{edu.title}</s.TitleRightTitle>
            <s.TitleRightDescription>{edu.description}</s.TitleRightDescription>
          </s.TitleRight>
        </s.SmallTitleArea>
        <s.SubTitleArea>
          {!edu.programs[0].programSounds[0].tune ? (
            <>
              <s.SubTitleText>{edu.programs[0].programSounds[0].composer}</s.SubTitleText>
              <s.SubTitleSeparator />
              <s.SubTitleText>{edu.programs[0].programSounds[0].lyricist}</s.SubTitleText>
            </>
          ) : (
            <s.SubTitleText>{edu.programs[0].programSounds[0].tune}</s.SubTitleText>
          )}
        </s.SubTitleArea>{' '}
        {edu.subMenu.menuCd === 'MN0205' ? (
          <s.SmallTitleArea>
            <s.SubSelectArea>
              <s.SubSelectLabel>
                {'교육'}
                <br />
                {'선택'}
              </s.SubSelectLabel>
              <s.SubSelect
                color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['4']}
                onClick={() => setSubTab('MN0206')}
                active={subTab === 'MN0206'}
              >
                엔젤쁘레
              </s.SubSelect>
              <s.SubSelect
                color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['4']}
                onClick={() => setSubTab('MN0208')}
                active={subTab === 'MN0208'}
              >
                슈필매스
              </s.SubSelect>
            </s.SubSelectArea>
          </s.SmallTitleArea>
        ) : null}
        <s.AudioImageArea>
          {edu.programs[0].programSounds[0].soundLyrics ? (
            <s.AudioImage src={edu.programs[0].programSounds[0].soundLyrics.fileUrl} />
          ) : (
            <>
              <s.AudioImage src={'/static/img/audio_default.png'} />
              <s.AudioDefaultDataArea>
                <s.AudioDefaultIcon src={'/static/img/audio_icon.png'} />
                <s.AudioInfoArea>
                  <s.AudioInfoTitle>{edu.programs[0].title}</s.AudioInfoTitle>
                  <s.AudioInfoDetails>
                    {edu.programs[0].programSounds[0].composer}
                    {' | '}
                    {edu.programs[0].programSounds[0].lyricist}
                  </s.AudioInfoDetails>
                </s.AudioInfoArea>
              </s.AudioDefaultDataArea>
            </>
          )}
        </s.AudioImageArea>
        <s.AudioArea>
          <ReactPlayer
            // Disable download button
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            // Disable right click
            onContextMenu={(e: { preventDefault: () => any }) => e.preventDefault()}
            width={'100%'}
            height={'auto'}
            url={edu.programs[0].programSounds[0].soundSoundtrack?.fileUrl}
            controls
          />
        </s.AudioArea>
        <s.ButtonArea>
          {edu.programs[0].programSounds[0].lyrics ? (
            <s.BigWhiteButton
              onClick={() =>
                userInfo.userId && userInfo.userTypeCd !== 'NAIVUSER'
                  ? saveAs(
                      edu.programs[0].programSounds[0].lyrics.fileUrl,
                      edu.programs[0].programSounds[0].lyrics.originalFileName,
                    )
                  : console.log('')
              }
            >
              <s.DownloadBtnIcon src="/static/img/download_button.png" />
              가사 다운로드
            </s.BigWhiteButton>
          ) : null}
          {edu.programs[0].programSounds[0].sheet ? (
            <s.BigWhiteButton
              onClick={() =>
                userInfo.userId && userInfo.userTypeCd !== 'NAIVUSER'
                  ? saveAs(
                      edu.programs[0].programSounds[0].sheet.fileUrl,
                      edu.programs[0].programSounds[0].sheet.originalFileName,
                    )
                  : console.log('')
              }
            >
              <s.DownloadBtnIcon src="/static/img/download_button.png" />
              악보 다운로드
            </s.BigWhiteButton>
          ) : null}
          <s.BigBlueButton onClick={cancle}>닫기</s.BigBlueButton>
        </s.ButtonArea>
      </s.AudioModalContainer>
    );
  };

  const MediaProgramModal = () => {
    return (
      <s.ModalContainer>
        <s.SmallTitleArea>
          <s.TitleLeft>
            <s.TitleLeftText>{edu.title}</s.TitleLeftText>
          </s.TitleLeft>
          <s.TitleRight>
            {edu.stepLength !== 0 && edu.stepLength !== null ? (
              <s.TitleRightTop>
                {edu.stepLength === 4 || edu.subTab === 'MN0208' ? (
                  <s.StepSelect
                    color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['0']}
                    onClick={() => setCurrentStep(0)}
                    active={currentStep === 0}
                  >
                    0단계
                  </s.StepSelect>
                ) : null}
                <s.StepSelect
                  color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['1']}
                  onClick={() => setCurrentStep(1)}
                  active={currentStep === 1}
                >
                  1단계
                </s.StepSelect>
                <s.StepSelect
                  color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['2']}
                  onClick={() => setCurrentStep(2)}
                  active={currentStep === 2}
                >
                  2단계
                </s.StepSelect>
                <s.StepSelect
                  color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['3']}
                  onClick={() => setCurrentStep(3)}
                  active={currentStep === 3}
                >
                  3단계
                </s.StepSelect>
              </s.TitleRightTop>
            ) : null}
            <s.TitleRightTitle>{edu.title}</s.TitleRightTitle>
            <s.TitleRightDescription>{edu.description}</s.TitleRightDescription>
          </s.TitleRight>
        </s.SmallTitleArea>
        {edu.subMenu.menuCd === 'MN0205' ? (
          <s.SmallTitleArea>
            <s.SubSelectArea>
              <s.SubSelectLabel>
                {'교육'}
                <br />
                {'선택'}
              </s.SubSelectLabel>
              <s.SubSelect
                color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['4']}
                onClick={() => setSubTab('MN0206')}
                active={subTab === 'MN0206'}
              >
                엔젤쁘레
              </s.SubSelect>
              <s.SubSelect
                color={edu.subMenu.menuCd && colorData[edu.subMenu.menuCd]['4']}
                onClick={() => setSubTab('MN0208')}
                active={subTab === 'MN0208'}
              >
                슈필매스
              </s.SubSelect>
            </s.SubSelectArea>
          </s.SmallTitleArea>
        ) : null}
        <Slider
          sildeList={edu.programs[0].programMedias.map((item: any, index: number) => (
            <s.SlideThumbnailArea
              current={index === currentIdx}
              onClick={() => setCurrentIdx(index)}
            >
              <s.SlideThumbnail src={item.thumbnail?.fileUrl} />
            </s.SlideThumbnailArea>
          ))}
          slideSize={9.5}
          currentIdx={currentIdx}
          nextBtn={<s.SlideBtn src="/static/img/Rshaft.png" />}
          prevBtn={<s.SlideBtn src="/static/img/Lshaft.png" />}
        />
        <s.VideoContentArea>
          {edu.programs[0].programMedias.length !== 0 ? (
            edu.programs[0].programMedias[currentIdx].url ? (
              <YouTube
                videoId={getYoutubeId(edu.programs[0].programMedias[currentIdx].url)}
                opts={{
                  height: '500',
                  width: '940',
                  playerVars: {
                    autoplay: 0,
                  },
                }}
              />
            ) : (
              <ReactPlayer
                // Disable download button
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                // Disable right click
                onContextMenu={(e: { preventDefault: () => any }) => e.preventDefault()}
                width={'940px'}
                height={'auto'}
                url={edu.programs[0].programMedias[currentIdx].file.fileUrl}
                controls
              />
            )
          ) : null}
        </s.VideoContentArea>
        <s.ButtonArea>
          <s.BlueButton onClick={cancle}>닫기</s.BlueButton>
        </s.ButtonArea>
      </s.ModalContainer>
    );
  };

  const SwitchModal = () => {
    switch (edu.type?.comnCd) {
      case 'TYPE1201':
        return <ActivityProgramModal />;
      case 'TYPE1202':
        return <ActivityProgramModal />;
      case 'TYPE1203':
        return <SoundProgramModal />;
      case 'TYPE1204':
        return <MediaProgramModal />;
      default:
        util.makeMsg('존재하지 않는 자료입니다.', 'warning');
        cancle();
        return null;
    }
  };
  return eduSearch !== null && edu !== null ? (
    <s.Container>
      <SwitchModal />
    </s.Container>
  ) : null;
};

HeaderPresenter.defaultProps = {};

export default HeaderPresenter;

import * as s from '../YearltPlanStyled';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

interface YearlyWeekProps extends RouteComponentProps {
  months: {
    monthId: number | undefined;
    month: number | undefined;
    num: number | undefined;
    week: number;
    file: any;
    subject: string | undefined;
    uploadStatus: string;
    weeks: {
      weekId: number | undefined;
      week: number | undefined;
      subject: string | undefined;
      file: any;
      uploadStatus: string;
    }[];
  }[];
  monthIndex: number;
  setMonths: React.Dispatch<
    React.SetStateAction<
      {
        monthId: number | undefined;
        month: number | undefined;
        num: number | undefined;
        week: number;
        file: any;
        subject: string | undefined;
        uploadStatus: string;
        weeks: {
          weekId: number | undefined;
          week: number | undefined;
          subject: string | undefined;
          file: any;
          uploadStatus: string;
        }[];
      }[]
    >
  >;
  weekIndex: number;
  isModifying: boolean;
}

const YearlyWeek = (props: YearlyWeekProps) => {
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files) => {
      let tempList = [...props.months];
      tempList[props.monthIndex].weeks[props.weekIndex].file = files[0];
      tempList[props.monthIndex].weeks[props.weekIndex].uploadStatus = 'exist';
      props.setMonths(tempList);
    },
  });

  const onChangeWeek = (text: string, type: string) => {
    let tempList = [...props.months];
    switch (type) {
      case 'subject':
        tempList[props.monthIndex].weeks[props.weekIndex].subject = text;
        props.setMonths(tempList);
        break;
      default:
        break;
    }
  };

  return (
    <s.YealyPlanUploadWeekArea>
      <s.YealyPlanUploadWeekAreaHeader>
        <s.YealyPlanUploadWeekAreaHeaderSection style={{ flex: 1 }}>
          <s.YealyPlanUploadWeekAreaHeaderTitle>
            {props.months[props.monthIndex].weeks[props.weekIndex].week}주차
          </s.YealyPlanUploadWeekAreaHeaderTitle>
        </s.YealyPlanUploadWeekAreaHeaderSection>
        <s.YealyPlanUploadWeekAreaHeaderSection style={{ flex: 8 }}>
          <s.YealyPlanUploadWeekAreaHeaderLabel>주제</s.YealyPlanUploadWeekAreaHeaderLabel>
          {props.isModifying ? (
            <s.YealyPlanUploadWeekAreaHeaderInput
              value={
                props.months[props.monthIndex].weeks[props.weekIndex].subject
                  ? props.months[props.monthIndex].weeks[props.weekIndex].subject
                  : ''
              }
              onChange={(e) => onChangeWeek(e.target.value, 'subject')}
              placeholder={'텍스트를 입력해주세요.'}
            />
          ) : (
            <s.YealyPlanUploadWeekAreaHeaderText>
              {props.months[props.monthIndex].weeks[props.weekIndex].subject}
            </s.YealyPlanUploadWeekAreaHeaderText>
          )}
        </s.YealyPlanUploadWeekAreaHeaderSection>
        <s.YealyPlanUploadWeekAreaHeaderSection style={{ flex: 1 }} />
      </s.YealyPlanUploadWeekAreaHeader>
      {/* <s.YealyPlanUploadWeekAreaFileUploadArea
        isDrag={isDragActive}
        {...getRootProps({ className: 'dropzone' })}
      >
        <s.YealyPlanUploadWeekAreaFileUploadAreaInput {...getInputProps()} />
        {props.months[props.monthIndex].weeks[props.weekIndex].uploadStatus !== 'ready' ? (
          <s.YealyPlanUploadWeekAreaFileUploadAreaButtonIcon
            onClick={open}
            src="/static/img/pdf_icon_1.png"
          />
        ) : null}
        <s.YealyPlanUploadWeekAreaFileUploadAreaText>
          {props.months[props.monthIndex].weeks[props.weekIndex].uploadStatus !== 'ready'
            ? props.months[props.monthIndex].weeks[props.weekIndex].file.originalFileName
              ? props.months[props.monthIndex].weeks[props.weekIndex].file.originalFileName
              : props.months[props.monthIndex].weeks[props.weekIndex].file.name
            : '주간계획안'}
        </s.YealyPlanUploadWeekAreaFileUploadAreaText>
        <s.YealyPlanUploadWeekAreaFileUploadAreaButtonIcon
          onClick={open}
          src={
            props.months[props.monthIndex].weeks[props.weekIndex].uploadStatus !== 'ready'
              ? '/static/img/file_exist.png'
              : '/static/img/selectFile.png'
          }
        />
      </s.YealyPlanUploadWeekAreaFileUploadArea> */}
    </s.YealyPlanUploadWeekArea>
  );
};

export default YearlyWeek;

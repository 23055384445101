import React from 'react';
import { SetterOrUpdater } from 'recoil';

import * as s from './SearchModalStyled';

const check = '/static/img/check.png';
const lens = '/static/img/search-icon.png';
const burgerMenu = '/static/img/burgerMenu.png';
const leftArrow = '/static/img/L_arrow.png';
const rightArrow = '/static/img/R_arrow.png';

interface SearchModalProps {
  ageAuths: any;
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };

  topicList: any[];
  subTopicList: any[];
  colorList: any[];

  setPlanId: SetterOrUpdater<number | null>;

  keyword: string;
  onSetKeyword: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  activityList: any[];
  onSearch: () => void;

  page: number;
  setPage: (arg0: number) => void;
  onNextPage: () => void;
  onPrevPage: () => void;
  nowPage: number;
  pagePostCount: number;
  pageList: number[];

  addActivityList: any[];
  onIsInActivityList: (arg0: any) => boolean;
  onSetAddActivityList: (arg0: any) => void;
  onCheckAddActivityList: (arg0: any) => void;

  chooseSubTopic: any;
  setChooseSubTopic: any;

  chooseColor: any;
  setChooseColor: any;

  onConfirm: () => void;
  onClose: () => void;
  modalPosition: {
    topIndex: number;
    preLen: number;
    subLength: number;
  };
  setModalPosition: React.Dispatch<
    React.SetStateAction<{
      topIndex: number;
      preLen: number;
      subLength: number;
    }>
  >;
}

const SearchModalPresenter = (props: SearchModalProps) => {
  return (
    <s.Container>
      <s.ModalContainer
        topIndex={props.modalPosition.topIndex}
        preLen={props.modalPosition.preLen}
        subLength={props.modalPosition.subLength}
      >
        <s.TitleWrapper>
          <s.Title>자료 가져오기</s.Title>
          <s.EachContentSearchWrapper>
            <s.EachContentSearchBar
              value={props.keyword}
              onChange={props.onSetKeyword}
              onKeyPress={props.onKeyPress}
              disabled={false}
            />
            <s.EachContentSearchButton onClick={props.onSearch} disabled={false}>
              <s.EachContentSearchButtonImg src={lens} />
            </s.EachContentSearchButton>
          </s.EachContentSearchWrapper>
        </s.TitleWrapper>
        <s.ContentWrapper>
          <s.TopicWrapper>
            <s.TopicIcon src={burgerMenu} />
            {props.subTopicList.map((subTopic: any) => {
              return (
                <s.Topic
                  isSelect={props.chooseSubTopic && subTopic.comnCd === props.chooseSubTopic.comnCd}
                  onClick={() => props.setChooseSubTopic(subTopic)}
                >
                  {subTopic.comnCdNm}
                </s.Topic>
              );
            })}
          </s.TopicWrapper>
          <s.ResultContentWrapper>
            {props.activityList.length ? (
              props.activityList.map((activity) => {
                return (
                  <s.ResultEachItemWrapper key={activity.activityId}>
                    <s.ResultItemInfoTopic>{activity.domain.comnCdNm}</s.ResultItemInfoTopic>
                    <s.ResultItemInfoNm onClick={() => props.setPlanId(activity.activityId)}>
                      {activity.activityNm}
                      {activity.materialList && activity.materialList.length
                        ? activity.materialList.map((icon: any) => (
                            <s.ResultItemInfoNmImg src={`/static/img/${icon.icon.comnCd}.jpg`} />
                          ))
                        : null}
                    </s.ResultItemInfoNm>
                    <s.ResultItemInfoAge>
                      {props.option.age ? `만 ${props.ageAuths[props.option.age].comnCdNm}` : null}
                    </s.ResultItemInfoAge>
                    <s.ResultItemButton
                      onClick={() => props.onCheckAddActivityList(activity)}
                      disabled={false}
                    >
                      {props.onIsInActivityList(activity) ? (
                        <s.ResultItemButtonCheckImg src={check} />
                      ) : (
                        <s.ResultItemButtomEmptyImg />
                      )}
                    </s.ResultItemButton>
                  </s.ResultEachItemWrapper>
                );
              })
            ) : (
              <s.NoResult>검색 결과가 없습니다.</s.NoResult>
            )}
          </s.ResultContentWrapper>
          <s.PageWrapper>
            {props.page > 5 ? (
              <s.PageButton onClick={props.onPrevPage}>
                <s.PagePrev src={leftArrow} />
              </s.PageButton>
            ) : null}
            {props.pageList
              .slice(props.nowPage * props.pagePostCount, (props.nowPage + 1) * props.pagePostCount)
              .map((page: number) => (
                <s.EachPage onClick={() => props.setPage(page)} isSelect={props.page === page}>
                  {page}
                </s.EachPage>
              ))}
            {props.pageList.length > (props.nowPage + 1) * props.pagePostCount ? (
              <s.PageButton onClick={props.onNextPage}>
                <s.PageNext src={rightArrow} />
              </s.PageButton>
            ) : null}
          </s.PageWrapper>
          <s.BigButtonWrapper>
            <s.BigButton isCloseButton={false} onClick={props.onConfirm}>
              <s.BigButtonText>가져오기</s.BigButtonText>
            </s.BigButton>
            <s.BigButton isCloseButton={true} onClick={props.onClose}>
              <s.BigButtonText>닫기</s.BigButtonText>
            </s.BigButton>
          </s.BigButtonWrapper>
        </s.ContentWrapper>
      </s.ModalContainer>
    </s.Container>
  );
};

export default SearchModalPresenter;

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as recoilItem from '../../../util/recoilitem';

import { planApi } from '../../../api/api-plan';
import WeeklyPlanPresenter from './WeeklyPlanPresenter';

import { saveAs } from 'file-saver';
import _ from 'lodash';
type WeeklyPlanProps = {
  notSupport: () => void;
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      year: number;
      month: number;
      week: number;
      day: number;
    }>
  >;
  ageAuths: any;
};

const WeeklyPlanContainer = (props: WeeklyPlanProps) => {
  const token = useRecoilValue(recoilItem.token);

  const [planId, setPlanId] = useRecoilState(recoilItem.planId);

  const [weeklyPlan, setWeeklyPlan] = useState<any>(null);
  const [tableDatas, setTableDatas] = useState<any>();
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const isShowHeader = (comnCd: string) => {
    return comnCd.includes('TYPE0406') && comnCd !== 'TYPE0406';
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await planApi.getPlanWeekly(
        token,
        props.option.age !== null ? props.option.age : '',
        props.option.week,
        props.option.month,
        props.option.year,
      );

      if (res.data.rsltCd === '00') {
        let sortedData = res.data.weeklyPlan.dataList.sort((a: any, b:any) => a.y < b.y ? -1 : 1).sort((a: any, b: any) => a.x < b.x ? -1 : 1);
        let groupedDatas = _.mapValues(_.groupBy(sortedData, 'y'),
                          clist => clist.map(data => _.omit(data, 'y')));
        console.log("GROUPED :: ", groupedDatas);
        const keys = Object.keys(groupedDatas);
        let groupedArray = [];
        for (let key in keys) {
          groupedArray.push(groupedDatas[key]);
        }
        setTableDatas(groupedArray);
        setWeeklyPlan(res.data.weeklyPlan);
      } else {
        setWeeklyPlan(null);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const createPlanFile = async (planId: string) => {
    try {
      setIsLoading(true);
      let data: FormData = new FormData();
      data.append('planId', planId);
      await planApi.createPlanWeeklyFile(token, data).then((res) => {
        if (res.data.rsltCd === '00') {
          setWeeklyPlan(res.data.weeklyPlan);
          saveAs(res.data.weeklyPlan.file.fileUrl, res.data.weeklyPlan.file.originalFileName);
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setWeeklyPlan(null);
    fetchData();
  }, [props.option.age, props.option.week, props.option.month, props.option.year]);

  return (
    <WeeklyPlanPresenter
      notSupport={props.notSupport}
      option={props.option}
      weeklyPlan={weeklyPlan}
      setPlanId={setPlanId}
      isShowHeader={isShowHeader}
      createPlanFile={createPlanFile}
      tableDatas={tableDatas}
    />
  );
};

WeeklyPlanContainer.defaultProps = {
  type: 'weekly',
};

export default WeeklyPlanContainer;

import React from 'react';
import DomainModalPresenter from './DomainModalPresenter';

interface DomainModalContainerProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedActivity: any;
  setSelectedActivity: React.Dispatch<any>;
  domainList: any;
}

const DomainModalContainer = (props: DomainModalContainerProps) => {
  return (
    <DomainModalPresenter
      isModalOpen={props.isModalOpen}
      setIsModalOpen={props.setIsModalOpen}
      selectedActivity={props.selectedActivity}
      setSelectedActivity={props.setSelectedActivity}
      domainList={props.domainList}
    />
  );
};
export default DomainModalContainer;

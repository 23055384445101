import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { userApi } from '../../../api/api-user';
import { programApi } from '../../../api/api-program';
import * as recoilItem from '../../../util/recoilitem';
import queryString from 'query-string';
import * as util from '../../../util/util';
import CreateProductPresenter from './CreateProductPresenter';

import { useDropzone } from 'react-dropzone';
import { shoppingApi } from '../../../api/api-shopping';

interface Props extends RouteComponentProps {}

const CreateProductContainer = (props: Props) => {
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const token = useRecoilValue(recoilItem.token);
  const setUserInfo = useSetRecoilState(recoilItem.userInfo);

  const [thumbnailList, setThumbnailList] = useState<any>([]);
  const [thumbnailFileList, setThumbnailFileList] = useState<any>([]);

  const [detailPageList, setDetailPageList] = useState<any>([]);
  const [detailPageFileList, setDetailPageFileList] = useState<any>([]);

  const [productNm, setProductNm] = useState<string>('');
  const [productDv, setProductDv] = useState<string>('0');
  const [productDetailDv, setProductDetailDv] = useState<string>('0');
  const [month, setMonth] = useState<string>('0');
  const [price, setPrice] = useState<number>(0);

  const [type, setType] = useState<string>('');
  const [material, setMaterial] = useState<string>('');
  const [size, setSize] = useState<string>('');
  const [credNumber, setCredNumber] = useState<string>('');
  const [openYear, setOpenYear] = useState<string>('');
  const [madeIn, setMadeIn] = useState<string>('');
  const [bestYn, setBestYn] = useState<string>('N');
  const [useYn, setUseYn] = useState<string>('Y');

  const {
    getRootProps: getThumbnailRootProps,
    getInputProps: getThumbnailInputProps,
    open: thumbnailOpen,
    acceptedFiles: thumbnailAcceptedFiles,
    isDragActive: thumbnailIsDragActive,
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: (files) => {
      let newFiles = files.map((file: File, index: number) => ({
        fileName: file.name,
        fileIndex: index,
        text: '',
      }));
      setThumbnailList([...thumbnailList, ...newFiles]);
      setThumbnailFileList([...thumbnailFileList, ...files]);
    },
  });

  const {
    getRootProps: getDetailPageRootProps,
    getInputProps: getDetailPageInputProps,
    open: detailPageOpen,
    acceptedFiles: detailPageAcceptedFiles,
    isDragActive: detailPageIsDragActive,
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: (files) => {
      let newFiles = files.map((file: File, index: number) => ({
        fileName: file.name,
        fileIndex: index,
        text: '',
      }));
      setDetailPageList([...detailPageList, ...newFiles]);
      setDetailPageFileList([...detailPageFileList, ...files]);
    },
  });

  useEffect(() => {}, []);

  const submit = async () => {
    try {
      setIsLoading(true);

      let formData: FormData = new FormData();

      if (thumbnailList.length < 1) {
        util.makeMsg('썸네일은 필수입니다.', 'error');
        setIsLoading(false);
        return;
      }

      if (detailPageList.length < 1) {
        util.makeMsg('상세페이지는 필수입니다.', 'error');
        setIsLoading(false);
        return;
      }

      if (!productNm.trim()) {
        util.makeMsg('상품명은 필수입니다.', 'error');
        setIsLoading(false);
        return;
      }

      if (!productDv || productDv === '0' || !productDetailDv || productDetailDv === '0') {
        util.makeMsg('상품구분은 필수입니다.', 'error');
        setIsLoading(false);
        return;
      }

      if (!price || price === 0) {
        util.makeMsg('가격은 필수입니다.', 'error');
        setIsLoading(false);
        return;
      }

      let thumbnailViewOrderOk = true;
      let detailPageViewOrderOk = true;

      for (let i = 0; i < thumbnailList.length; i++) {
        let item = thumbnailList[i];
        if (!item.text) {
          thumbnailViewOrderOk = false;
        }
      }

      if (!thumbnailViewOrderOk) {
        util.makeMsg('썸네일 출력순서는 필수입니다.', 'error');
        setIsLoading(false);
        return;
      }

      for (let i = 0; i < detailPageList.length; i++) {
        let item = detailPageList[i];
        if (!item.text) {
          detailPageViewOrderOk = false;
        }
      }

      if (!detailPageViewOrderOk) {
        util.makeMsg('상세페이지 출력순서는 필수입니다.', 'error');
        setIsLoading(false);
        return;
      }

      formData.append('productNm', productNm);
      formData.append('productDv', String(productDv));
      formData.append('productDetailDv', String(productDetailDv));
      formData.append('price', String(price));
      formData.append('type', type);
      formData.append('material', material);
      formData.append('size', size);
      formData.append('credNumber', credNumber);
      formData.append('openYear', openYear);
      formData.append('madeIn', madeIn);
      formData.append('bestYn', bestYn);
      formData.append('useYn', useYn);

      if (month && month !== '0') {
        formData.append('month', month);
      }

      thumbnailList.forEach((item: any, index: number) => {
        formData.append(`thumbnailList[${index}].file`, thumbnailFileList[index]);
        formData.append(`thumbnailList[${index}].viewOrder`, item.text);
      });

      detailPageList.forEach((item: any, index: number) => {
        formData.append(`detailList[${index}].file`, detailPageFileList[index]);
        formData.append(`detailList[${index}].viewOrder`, item.text);
      });

      let res = await shoppingApi.createOpenProduct(token, formData);

      if (res.data.rsltCd === '00') {
        util.makeMsg('상품이 등록되었습니다.', 'success');
        setIsLoading(false);
        props.history.push('/registerSelect');
      } else {
        util.makeMsg('상품등록에 실패하였습니다.', 'error');
        console.log(res);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      util.makeMsg('오류가 발생했습니다.', 'error');
      console.log(e);
    }
  };

  const removeThumb = async (item: any) => {
    let curList = thumbnailList;
    let targetItemIdx = thumbnailList.findIndex(
      (i: any) =>
        i.fileName === item.fileName && i.fileIndex === item.fileIndex && i.text === item.text,
    );
    if (targetItemIdx > -1) {
      let targetItem = thumbnailList[targetItemIdx];
      let curFileList = thumbnailFileList;
      curFileList.splice(targetItem.fileIndex, 1);
      curList.splice(targetItemIdx, 1);
      setThumbnailFileList([...curFileList]);
      setThumbnailList([...curList]);
    }
  };

  const removeDetailPage = async (item: any) => {
    let curList = detailPageList;
    let targetItemIdx = detailPageList.findIndex(
      (i: any) =>
        i.fileName === item.fileName && i.fileIndex === item.fileIndex && i.text === item.text,
    );
    if (targetItemIdx > -1) {
      let targetItem = detailPageList[targetItemIdx];
      let curFileList = detailPageFileList;
      curFileList.splice(targetItem.fileIndex, 1);
      curList.splice(targetItemIdx, 1);
      setDetailPageFileList([...curFileList]);
      setDetailPageList([...curList]);
    }
  };

  return (
    <>
      <Header {...props} />
      <CreateProductPresenter
        {...props}
        getThumbnailRootProps={getThumbnailRootProps}
        getThumbnailInputProps={getThumbnailInputProps}
        thumbnailOpen={thumbnailOpen}
        thumbnailAcceptedFiles={thumbnailAcceptedFiles}
        thumbnailIsDragActive={thumbnailIsDragActive}
        getDetailPageRootProps={getDetailPageRootProps}
        getDetailPageInputProps={getDetailPageInputProps}
        detailPageOpen={detailPageOpen}
        detailPageAcceptedFiles={detailPageAcceptedFiles}
        detailPageIsDragActive={detailPageIsDragActive}
        thumbnailFileList={thumbnailFileList}
        thumbnailList={thumbnailList}
        setThumbnailList={setThumbnailList}
        detailPageFileList={detailPageFileList}
        detailPageList={detailPageList}
        setDetailPageList={setDetailPageList}
        submit={submit}
        productNm={productNm}
        setProductNm={setProductNm}
        productDv={productDv}
        setProductDv={setProductDv}
        price={price}
        setPrice={setPrice}
        type={type}
        setType={setType}
        material={material}
        setMaterial={setMaterial}
        size={size}
        setSize={setSize}
        credNumber={credNumber}
        setCredNumber={setCredNumber}
        openYear={openYear}
        setOpenYear={setOpenYear}
        madeIn={madeIn}
        setMadeIn={setMadeIn}
        bestYn={bestYn}
        setBestYn={setBestYn}
        useYn={useYn}
        setUseYn={setUseYn}
        productDetailDv={productDetailDv}
        setProductDetailDv={setProductDetailDv}
        month={month}
        setMonth={setMonth}
        removeThumb={removeThumb}
        removeDetailPage={removeDetailPage}
      ></CreateProductPresenter>
      <Footer {...props} />
    </>
  );
};

export default CreateProductContainer;

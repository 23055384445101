import styled from 'styled-components';

export const Container = styled.div`
  width: 60%;
  height: 520px;
  z-index: 99;
  position: fixed;

  top: 20vh;
  display: flex;
  flex-direction: column;
  align-self: center;
  background-color: #fff;

  border: 1px solid #0087ed;
  border-radius: 5px;
  overflow: scroll;
`;

export const ModalOffButtonWrapper = styled.div`
  flex: 2;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: none;

  padding: 0 2%;
`;

export const Title = styled.div`
  flex: 3;

  font-weight: 700;
  font-size: 17px;
  // letter-spacing : 1.2px;

  color: #343434;
`;

export const ModalOffButtonWrapperDiv = styled.div`
  flex: 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ModalOffButton = styled.button`
  border: none;
  background-color: transparent;

  cursor: pointer;
  transition: 0.1s all;

  &:hover {
    opacity: 0.5;
  }
`;

export const ModalOffButtonImg = styled.img`
  border: none;
  height: 20px;
  width: 20px;
`;

export const YearListWrapper = styled.div`
  flex: 2;

  overflow: scroll;
  border: none;
  background-color: transparent;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 2% 0;

  ::-webkit-scrollbar {
    width: 0px;
    height: 3px;
    margin-top: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #0087ed;
  }
`;

export const EachYearButton = styled.button<{ isSelect: boolean }>`
  height: 30px;
  min-width: 100px;
  max-width: 100px;
  margin: 0 20px;

  border: ${(props) => (props.isSelect ? '1px solid #0087ed' : '1px solid #a0a0a0')};
  background-color: ${(props) => (props.isSelect ? '#0087ed' : 'transparent')};
  color: ${(props) => (props.isSelect ? '#fff' : '#a0a0a0')};

  border-radius: 30px;

  transition: 0.25s all;

  &:hover {
    background-color: #0087ed;
    color: white;
    border: 1px solid #0087ed;
  }
`;

export const EachYearButtonText = styled.div`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.2px;
`;

export const MonthListWrapper = styled.div`
  flex: 2;

  overflow: scroll;
  border: none;
  background-color: transparent;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 2% 0;

  ::-webkit-scrollbar {
    width: 0px;
    height: 3px;
    margin-top: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #0087ed;
  }
`;

export const EachMonthButton = styled.button<{ isSelect: boolean }>`
  height: 25px;
  min-width: 85px;
  max-width: 85px;
  margin: 0 15px;

  border: ${(props) => (props.isSelect ? '1px solid #0087ed' : '1px solid #a0a0a0')};
  background-color: ${(props) => (props.isSelect ? '#0087ed' : 'transparent')};
  color: ${(props) => (props.isSelect ? '#fff' : '#a0a0a0')};

  border-radius: 30px;

  transition: 0.25s all;

  &:hover {
    background-color: #0087ed;
    color: white;
    border: 1px solid #0087ed;
  }
`;

export const EachMonthButtonText = styled.div`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.2px;
`;

export const AgeListWrapper = styled.div`
  flex: 1.5;

  overflow: scroll;
  border: none;
  background-color: transparent;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 2% 0;

  ::-webkit-scrollbar {
    width: 0px;
    height: 3px;
    margin-top: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #0087ed;
  }
`;

export const EachAgeButton = styled.button<{ isSelect: boolean }>`
  height: 24px;
  min-width: 80px;
  max-width: 80px;
  margin: 0 18px;

  border: ${(props) => (props.isSelect ? '1px solid #0087ed' : '1px solid #a0a0a0')};
  background-color: ${(props) => (props.isSelect ? '#0087ed' : 'transparent')};
  color: ${(props) => (props.isSelect ? '#fff' : '#a0a0a0')};

  border-radius: 30px;

  transition: 0.25s all;

  &:hover {
    background-color: #0087ed;
    color: white;
    border: 1px solid #0087ed;
  }
`;

export const EachAgeButtonText = styled.div`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
`;

export const PrevPlanWrapper = styled.div`
  flex: 8;

  border: 1px solid #ddd;
`;

export const EachPrevPlan = styled.button<{ isSelect: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 20%;
  width: 100%;

  border: none;
  border-bottom: 1px solid #ddd;
  background-color: ${(props) => (props.isSelect ? '#0087ed' : 'transparent')};
  color: ${(props) => (props.isSelect ? '#fff' : '343434')};

  transition: 0.15s all;

  &:hover {
    background-color: #0087ed;
    color: white;
  }
`;

export const EachPrevPlanInfo = styled.div`
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.2px;
`;

export const EachPrevPlanDetail = styled.div`
  flex: 1;
`;

export const PageListWrapper = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

export const EachPage = styled.button<{ isSelect: boolean }>`
  margin: 0 1%;
  border: none;
  background-color: transparent;

  color: ${(props) => (props.isSelect ? '#0087ed' : '#343434')};

  transition: 0.25s all;

  &:hover {
    opacity: 0.7;
  }
`;

export const PageArrowButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: transparent;
`;

export const PageArrowButtonImg = styled.img`
  height: 10px;
  width: 10px;
`;

export const ButtonWrapper = styled.div`
  border: none;
  flex: 2;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 10%;
`;

export const Button = styled.button`
  border: 1px solid #343434;
  border-radius: 30px;
  background-color: transparent;

  color: #343434;

  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;

  height: 40px;
  width: 100px;

  transition: 0.25s all;

  &:hover {
    background-color: #0087ed;
    border: 1px solid #0087ed;
    color: #fff;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1180px;
  min-height: 700px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  transform: skew(-0.1deg);

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const ActivityPlanHeader = styled.div`
  width: 980px;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  padding: 35px 0 10px;
  vertical-align: middle;
  border-bottom: 1px solid #e2e2e2;
`;

export const ActivityPlanHeaderTitleArea = styled.div`
  text-align: center;
  vertical-align: middle;
`;

export const ActivityPlanHeaderTitle = styled.div`
  float: left;
  font-size: 27px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const ActivityPlanHeaderRightArea = styled.div`
  float: right;
  text-align: center;
  vertical-align: middle;
`;

export const ActivityPlanHeaderRightIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 5px;
  vertical-align: middle;
`;

export const ActivityPlanHeaderRightText = styled.div`
  font-weight: 400;
  color: #4d4d4d;
  vertical-align: middle;
`;

export const RegisterContentArea = styled.div`
  width: 980px;
  margin: 40px auto 0;
`;

export const RegisterContentStepBar = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
`;

export const RegisterContentStep = styled.div<{ active: boolean }>`
  border-radius: 100%;
  width: ${(props) => (props.active ? '27px' : '18px')};
  height: ${(props) => (props.active ? '27px' : '18px')};
  background-color: #ffd68f;
  margin: 0 4px;
  cursor: ${(props) => (!props.active ? 'pointer' : null)};
`;

export const RegisterContentStepNum = styled.div`
  line-height: 27px;
`;

export const RegisterContentStepText = styled.div`
  height: 100%;
`;

export const RegisterContentHeader = styled.div`
  width: 100%;
  height: 40px;
  margin: 10px 0;
  background-color: #ff9043;
`;

export const RegisterContentHeaderTitle = styled.div`
  float: left;
  line-height: 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-left: 50px;
`;

export const RegisterContentForm = styled.div`
  width: 95%;
  border: 1px solid #4d4d4d;
  border-radius: 5px;
  margin: 25px auto;
  font-size: 14px;
  font-weight: 600;
  color: #4d4d4d;
`;

export const RegisterContentRow = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  border-bottom: 1px solid #4d4d4d;
  padding: 0 5%;
`;

export const RegisterContentLastRow = styled.div`
  display: flex;
  width: 100%;
  line-height: 50px;
  padding: 0 5%;
`;

export const RegisterContentElement = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
`;
export const RegisterContentFullElement = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const RegisterContentElementDomain = styled.div`
  width: 85px;
  justify-content: flex-start;
  text-align: left;
`;

export const RegisterContentFullElementDomain = styled.div`
  width: 85px;
  justify-content: flex-start;
  padding-top: 15px;
  text-align: left;
`;

export const RegisterContentElementInput = styled.input`
  width: 70%;
  height: 30px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding: 0 10px;
  margin: 10px 0;
`;

export const RegisterContentButtonArea = styled.div`
  display: flex;
  width: 95%;
  height: 45px;
  margin: 30px auto 0;
  justify-content: center;
`;

export const RegisterContentButton = styled.div`
  width: 35%;
  line-height: 45px;
  font-size: 15px;
  color: #fff;
  margin: 0 50px;
  border-radius: 25px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  background-color: #ff6e0a;
  cursor: pointer;
`;

export const ContentTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ContentTitle = styled.div<{ titleType: string }>`
  width: ${(props) =>
    props.titleType === 'expectation'
      ? '130px'
      : props.titleType === 'factor'
      ? '180px'
      : props.titleType === 'method'
      ? '120px'
      : props.titleType === 'extension'
      ? '115px'
      : '0'};
  line-height: 35px;
  margin: 20px 0;
  font-size: 17px;
  color: #fff;
  border-radius: 20px;
  background-color: ${(props) =>
    props.titleType === 'expectation'
      ? '#00dda0'
      : props.titleType === 'factor'
      ? '#a78ffd'
      : props.titleType === 'method'
      ? '#ff7917'
      : props.titleType === 'extension'
      ? '#ff8278'
      : '#fff'};
`;

export const ContentSubTitle = styled.div`
  font-size: 15px;
  margin-bottom: 20px;
`;

export const ContentListArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ContentList = styled.div`
  display: flex;
  width: 60%;
  margin: 5px 0;
  justify-content: space-evenly;
  align-items: center;
`;

export const ContentListDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #0087ed;
`;

export const ContentListText = styled.input`
  width: 500px;
  height: 30px;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;

  &::placeholder {
    color: #d2d2d2;
  }
`;

export const ContentListTextArea = styled.textarea`
  width: 500px;
  height: 200px;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;

  &::placeholder {
    color: #d2d2d2;
  }
`;
export const SearchText = styled.input`
  width: 500px;
  height: 40px;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;

  &::placeholder {
    color: #d2d2d2;
  }
`;

export const ContentListButton = styled.div`
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

export const ContentListButtonImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

export const AddTextButtonArea = styled.div`
  width: 100%;
  height: 33px;
  margin: 10px 0 15px;
`;

export const AddTextButton = styled.div`
  width: 100px;
  height: 33px;
  margin-right: 120px;
  float: right;
  cursor: pointer;
`;

export const AddTextButtonImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

export const MethodArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 10px 0;
`;

export const SubContentList = styled.div`
  display: flex;
  width: 60%;
  margin: 5px 0;
  justify-content: space-evenly;
  align-items: center;
`;

export const SubContentListDot = styled.div`
  width: 8px;
  height: 8px;
`;

export const SubContentTextArea = styled.div`
  display: flex;
  width: 500px;
  height: 30px;
`;

export const SubContentTextMark = styled.div`
  display: flex;
  width: 20px;
  height: 30px;
  font-size: 13px;
  font-weight: 900;
  justify-content: center;
  align-items: center;
`;

export const SubContentText = styled.input`
  width: 480px;
  height: 30px;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;

  &::placeholder {
    color: #d2d2d2;
  }
`;

export const ContentExtensionTextArea = styled.div`
  width: 75%;
  height: 250px;
  margin: 0 auto 50px;
  text-align: center;
`;

export const ContentExtensionText = styled.textarea`
  width: 100%;
  height: 100%;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 50px;

  &::placeholder {
    color: #d2d2d2;
  }
`;

export const RegisterContentElementTextArea = styled.textarea`
  width: 85%;
  height: 120px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 50px;
  margin: 10px 0;

  &::placeholder {
    color: #d2d2d2;
  }
`;
export const FileUploadForm = styled.div`
  width: 100%;
  border-radius: 0 0 5px 5px;
  margin: 0 auto 25px;
  font-size: 14px;
  font-weight: 600;
  color: #4d4d4d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FileUploadHeader = styled.div`
  width: 100%;
  height: 40px;
  margin-top: 10px;
  background-color: #ff9043;
`;

export const FileUploadHeaderTitle = styled.div`
  float: left;
  line-height: 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-left: 50px;
`;

export const FileUploadHeaderSubTitleArea = styled.div`
  display: flex;
  float: right;
  line-height: 40px;
  margin-right: 50px;
  align-items: center;
`;

export const FileUploadHeaderImg = styled.img`
  width: 25px;
  height: 22px;
  margin-right: 10px;
`;

export const FileUploadHeaderSubTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #fff;
`;

export const FileUploadSelectIconArea = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  border: 0.5px solid #acacac;
  border-top: 0;
  justify-content: center;
  align-items: center;
`;

export const FileUploadIconArea = styled.div`
  display: flex;
  line-height: 50px;
  margin: 0 10px;
  align-items: center;
`;

export const FileUploadIconCheck = styled.input`
  display: none;
`;

export const FileUploadIconCheckBox = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  border: 1px solid #4d4d4d;
  border-radius: 3px;
  margin: 0 4px;
  justify-content: center;
  align-items: center;
`;

export const FileUploadIconCheckImg = styled.img<{ active: boolean }>`
  width: 70%;
  height: 70%;
  opacity: ${(props) => (props.active ? '1' : '0')};
  transition: all 0.2s;
`;

export const FileUploadIconLabel = styled.label`
  display: flex;
  font-size: 17px;
  font-weight: 600;
  color: #4d4d4d;
  align-items: center;
  cursor: pointer;
`;

export const FileUploadInputArea = styled.div<{ isDragActive: boolean }>`
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  opacity: ${(props) => (props.isDragActive ? 0.5 : 1)};
`;

export const FileUploadInput = styled.input``;

export const FileUploadInputImg = styled.img`
  margin-bottom: 10px;
`;

export const FileUploadInputText = styled.div`
  color: #4d4d4d;
  margin-bottom: 20px;
`;

export const FileUploadInputButtonArea = styled.div`
  width: 156px;
  height: 48px;
  cursor: pointer;
`;

export const FileUploadInputButtonImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

export const FileUploadInputButton = styled.div`
  padding: 10px 20px;
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const FileUploadInputButtonIcon = styled.img`
  width: 20px;
  height: auto;
  margin-right: 10px;
`;
export const FileUploadInputButtonText = styled.div`
  font-size: 18px;
  color: #9c9c9c;
  font-weight: 600;
`;

export const FileUploadStatusArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 50px;
  background-color: #fff;
  border: 1px solid #acacac;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const FileUploadStatusName = styled.div`
  font-size: 16px;
  color: #6f6f6f;
  font-weight: 600;
`;

export const FileUploadStatus = styled.div`
  padding: 8px 35px;
  border-radius: 30px;
  background-color: #fff;
  border: 1.5px solid #acacac;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #acacac;
  font-weight: 600;
`;

export const FileUploadStatusCancle = styled.div`
  padding: 8px 35px;
  border-radius: 30px;
  background-color: #fff;
  border: 1.5px solid #ff7a5b;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #ff7a5b;
  font-size: 14px;
  font-weight: 600;
`;

export const AddFileButtonArea = styled.div`
  width: 100%;
  height: 36px;
  margin: 5px 0 15px;
`;

export const AddFileButton = styled.div`
  width: 125px;
  height: 36px;
  float: right;
  cursor: pointer;
`;

export const AddFileButtonImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

export const UploadProgramAreaSelectHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #7d7d7d;
  padding: 10px;
  margin: 10px 0 0;
`;
export const UploadProgramAreaSelectDv = styled.div`
  width: 20px;
  border: 1px solid #cdcdcd;
  height: 1px;
`;
export const UploadProgramAreaHeaderSelect = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: 10px;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const UploadProgramAreaSelectType = styled.input`
  display: none;
`;
export const UploadProgramAreaSelectTypeLabel = styled.label`
  border: 1px solid #dfdfdf;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
`;
export const UploadProgramAreaSelectTypeLabelIcon = styled.img`
  width: 60%;
  height: 60%;
`;

export const UploadProgramAreaSelectText = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: #7d7d7d;
  margin-right: 10px;
  cursor: pointer;
`;

export const Button = styled.div`
  border: 1px solid #343434;
  border-radius: 30px;
  background-color: transparent;

  color: #343434;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;

  height: 40px;
  width: 80px;

  transition: 0.25s all;

  &:hover {
    background-color: #ff6e0a;
    border: 1px solid #ff6e0a;
    color: #fff;
  }
`;
export const CloseButton = styled.div`
  border: 1px solid #343434;
  border-radius: 30px;
  background-color: #7c7c7c;

  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;

  height: 40px;
  width: 80px;
  cursor: pointer;

  transition: 0.25s all;
`;

export const ResultContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0px 0 0 0;

  height: 300px;
`;

export const ResultTopicContentWrapper = styled.div`
  overflow: scroll;

  height: 300px;

  &::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0087ed;
  }
`;

export const NoResult = styled.div`
  flex: 1;

  display: flex;

  justify-content: center;
  align-items: center;

  color: #a0a0a0;
`;

export const ResultEachItemWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  border: none;
  border-bottom: 1px solid #ddd;
  background-color: transparent;

  height: 60px;
  width: 900px;

  &:last-of-type {
    border-right: none;
  }
`;

export const ResultItemInfoTopic = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: 800;
  color: #343434;

  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
`;

export const ResultItemInfoNm = styled.div`
  flex: 3;
  margin: 0px 10px;

  font-size: 12px;
  font-weight: 500;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: none;

  transition: 0.25s all;

  &:hover {
    color: #0087ed;
  }
`;

export const ResultItemInfoNmImg = styled.img`
  margin: 0 0 0 5px;
  height: 12px;
  width: 12px;
`;

export const ResultItemInfoAge = styled.div`
  flex: 2;
  font-size: 12px;
  font-weight: 500;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;

  border: none;
`;

export const ResultItemButton = styled.div`
  border: none;
  background-color: transparent;
  margin: 0 4px;

  font-size: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  word-wrap: normal;

  transition: 0.25s all;

  &:hover {
    opacity: 0.5;
  }
`;

export const ResultItemButtonCheckImg = styled.img`
  height: 15px;
  width: 15px;
  padding: 2.5px;
  margin-right: 5px;
`;

export const ResultItemButtomEmptyImg = styled.div`
  height: 15px;
  width: 15px;
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: transparent;
  margin-right: 5px;
`;
export const PagingNav = styled.div`
  width: 100%;
  height: 40px;
  padding: 3px;
  margin-top: 50px;
  text-align: center;
  vertical-align: middle;
`;

export const PagingLink = styled.div`
  display: inline-block;
  width: 12px;
  height: 100%;
  margin: 0 3px;

  &:hover {
    cursor: pointer;
  }
`;

export const PagingLinkImg = styled.img``;

export const PagingNumArea = styled.ul`
  display: inline-block;
  height: 100%;
  margin: auto 15px;
  font-size: 17px;
  color: #7e7e7e;
  text-align: center;
  vertical-align: middle;
`;

export const PagingNum = styled.li<{ current: boolean }>`
  display: inline-block;
  margin: 5.5px 2px;
  font-weight: ${(props) => (props.current ? '800' : '600')};
  color: ${(props) => (props.current ? '#4d4d4d' : 'default')};
  padding: 2px 6px;

  &:hover {
    cursor: pointer;
    color: #4d4d4d;
    text-decoration: underline;
    text-decoration-color: #4d4d4d;
  }
`;

export const SelectedProgramArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
`;
export const SelectedProgramButton = styled.div<{ icon: string }>`
  border-radius: 20px;
  cursor: pointer;
  background-color: ${(props) =>
    props.icon === 'ICON0101'
      ? '#ED8E53'
      : props.icon === 'ICON0102'
      ? '#3ABD50'
      : props.icon === 'ICON0103'
      ? '#0085EB'
      : props.icon === 'ICON0104'
      ? '#FFB95E'
      : props.icon === 'ICON0105'
      ? '#9F7FE3'
      : props.icon === 'ICON0106'
      ? '#FF8178'
      : '#3c3c3c'};
  color: #fff;
  padding: 10px 20px;
`;

export const ModalContainer = styled.div`
  width: 1080px;
  height: 70%;
  margin: 0 auto;
  z-index: 9991;
  text-align: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(45, 45, 45, 0.7);
  border-radius: 20px;
  padding: 20px 50px;
  left: 50%;
  transform: translate(-50%, 0);
`;
export const SmallTitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TitleLeft = styled.div`
  margin-right: 15px;
  width: 160px;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eee;
`;

export const TitleLeftIcon = styled.img`
  height: 100%;
  margin: 0px 5px;
`;

export const TitleLeftText = styled.div`
  color: #fff;
  font-family: 'Gosanja';
  font-size: 20px;
`;
export const SmallTitleLeftIcon = styled.img`
  width: 80px;
  height: auto;
  margin: 0px 5px;
`;

export const TitleSeparator = styled.div`
  background-color: #fff;
  width: 2px;
  height: 10px;
  margin: 0 10px;
`;

export const TitleRight = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const TitleRightTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 30px;
  align-items: center;
  margin-bottom: 12px;
`;
export const TitleRightTopText = styled.div`
  color: #fff;
  font-size: 18px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const TitleRightTopIcon = styled.div`
  color: #fff;
  font-size: 14px;
  background-color: #c55c65;
  padding: 5px 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const TitleRightBottom = styled.div`
  color: #fff;
  font-family: 'Gosanja';
  font-size: 24px;
  white-space: normal;
`;

export const TitleFull = styled.div`
  flex: 1;
  background-color: #fff;
  font-family: 'Gosanja';
  font-size: 24px;
  white-space: normal;
  padding: 10px;
  border-radius: 10px;
`;

export const TitleFullBlue = styled.div`
  flex: 1;
  background-color: #0087ed;
  color: #fff;
  font-family: 'Gosanja';
  font-size: 24px;
  white-space: normal;
  padding: 10px;
  border-radius: 10px;
`;

export const SubTitleArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  flex-direction: row;
  width: 100%;
  height: 15px;
  margin: 10px;
  padding: 0 10px;
`;

export const SubTitleSeparator = styled.div`
  background-color: #a2a2a2;
  width: 1px;
  height: 5px;
  margin: 0 10px;
`;

export const SubTitleText = styled.div`
  width: auto;
  color: #a2a2a2;
  font-family: 'Gosanja';
  font-size: 10px;
  white-space: normal;
`;

export const ContentArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 900px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  gap: 20px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #3c3c3c;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: grey;
    border-radius: 20px;
  }
`;

export const ContentImage = styled.img`
  height: auto;
  width: 150px;
  border-radius: 10px;
  cursor: pointer;
`;

import React, { ReactElement } from 'react';
import styled from 'styled-components';

const SlideContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

type SlideProps = {
  silde: ReactElement;
};

const Slide = (props: SlideProps) => {
  return <SlideContainer>{props.silde}</SlideContainer>;
};

Slide.defaultProps = {
  silde: null,
};

export default Slide;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1075px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const MainImgArea = styled.div`
  width: 1091px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px auto;
  align-items: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid #e2e2e2;
`;

export const MainImg = styled.img`
  width: 1091px;
  height: 264px;
  object-fit: cover;
`;

export const ContentsArea = styled.div`
  width: 1091px;
  margin: 30px auto 0px;
`;

export const TopArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TopAreaText = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: 16px;
  color: #4D4D4D;
  width: 40%;
`;

export const TopAreaButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  width: 60%;
`;

export const TopAreaButton = styled.div`
  border-radius: 50px;
  width: 174px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0087ED;
  color: #0087ED;
  background-color: #FFFFFF;
  font-size: 16px;
  cursor: pointer;
`;

export const ProgramArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 100px;
`;

export const ProgramText = styled.div`
  font-size: 48px;
  color: #4D4D4D;
  font-weight: bold;
  margin-bottom: 30px;
`;

export const ProgramItemArea = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 16px;
`;

export const ProgramItem = styled(Link)`
  width: 202px;
  height: 153px;
  border: 1px solid #DEDEDE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ProgramItemImg = styled.img`
  height: 90%;
  width: 90%;
  object-fit: contain;
`;

export const ProgramItemText = styled.div`
  height: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;
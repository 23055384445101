import React, { useEffect, useState } from 'react';

import { useRecoilValue, useRecoilState } from 'recoil';
import * as recoilUtil from '../../../../util/recoilitem';

import SearchModalPresenter from './SearchModalPresenter';
import { planApi } from '../../../../api/api-plan';

interface SearchModalProps {
  type: string;
  ageAuths: any;
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };

  topicList: any;
  subTopicList: any;
  colorList: any;

  layout: any;
  popupOn: any;
  setPopupOn: (arg0: any) => void;
  onPushActivityInLayout: (arg0: any[], { x, y }: any, arg1: string) => void;
}

const SearchModalContainer = (props: SearchModalProps) => {
  const token: any = useRecoilValue(recoilUtil.token);
  const [planId, setPlanId] = useRecoilState(recoilUtil.planId);
  const [loading, setLoading] = useRecoilState(recoilUtil.isLoading);

  const [topicList, setTopicList] = useState<any>(
    new Array(...Object.entries(props.topicList).map((item) => item[1])),
  );
  const [subTopicList, setSubTopicList] = useState<any>(
    new Array(...Object.entries(props.subTopicList).map((item) => item[1])),
  );
  const [colorList, setColorList] = useState<any>(
    new Array(...Object.entries(props.colorList).map((item) => item[1])),
  );

  const [activityList, setActivityList] = useState<any>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [description, setDescription] = useState<string>(props.popupOn.description);
  const [addActivityList, setAddActivityList] = useState<any>(
    props.popupOn.activityList && props.popupOn.activityList.length
      ? props.popupOn.activityList
      : [],
  );

  const [chooseSubTopic, setChooseSubTopic] = useState<any>(
    props.popupOn.activityList && props.popupOn.activityList.length
      ? props.popupOn.activityList[0].domain
      : {},
  );

  const [beforeSubTopic, setBeforeSubTopic] = useState<any>(null);

  const [chooseColor, setChooseColor] = useState<any>(
    props.popupOn.activityList && props.popupOn.activityList.length
      ? props.popupOn.activityList[0].color
      : null,
  );

  const [page, setPage] = useState<number>(1);
  const [nowPage, setNowPage] = useState<number>(0);
  const [pageList, setPageList] = useState<any>([]);
  const pagePostCount: number = 5; //한 페이지에 표시되는 아이템 수
  const blockPageNum: number = 5; //5개 단위의 페이지로 나눔

  const [getType, setGetType] = useState<string>('search');

  const onSetKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(1);
    }
  };

  const onIsInActivityList = (activity: any) => {
    return addActivityList.map((item: any) => item.planActivityId).includes(activity.activityId);
  };

  //체크가 되어있으면(=이미 등록되어있으면) 해제, 체크가 안되어 있다면 등록
  const onCheckAddActivityList = (activity: any) => {
    onIsInActivityList(activity)
      ? onDeleteAddActivityItem(activity)
      : onSetAddActivityList(activity);
  };

  const onSetAddActivityList = (activity: any) => {
    props.popupOn.x >= 2
      ? setAddActivityList([
          ...addActivityList,
          {
            planActivityId: activity.activityId,
            typeCd: activity.domain ? activity.domain.comnCd : null,
            color: chooseColor,
          },
        ])
      : setAddActivityList([
          {
            planActivityId: null,
            typeCd: activity.comnCd,
            typeCdNm: activity.comnCdNm,
            color: chooseColor,
          },
        ]);
  };

  const onDeleteAddActivityItem = (activity: any) => {
    const index = addActivityList
      .map((item: any) => item.planActivityId)
      .indexOf(activity.activityId);
    setAddActivityList([...addActivityList.slice(0, index), ...addActivityList.slice(index + 1)]);
  };

  const onSearch = async (refreshPage?: number) => {
    let Data: FormData = new FormData();

    if (refreshPage) {
      setPage(1);
      setNowPage(0);
    }
    if (keyword) {
      Data.append('activityNm', keyword);
    }

    console.log(chooseSubTopic);
    if (chooseSubTopic && chooseSubTopic.comnCd) {
      Data.append('domain', chooseSubTopic.comnCd);
    }
    Data.append('page', refreshPage ? String(refreshPage) : String(page));
    Data.append('blockPageNum', String(blockPageNum));
    Data.append('pagePostCount', String(pagePostCount));

    try {
      setLoading(true);
      const res = await planApi.searchPlanActivity(token, Data);
      console.log(res);
      if (res.data.rsltCd === '00') {
        console.log('CHECK');

        setActivityList(res.data.activityList.content);
        setPageList(
          new Array(res.data.activityList.totalPages)
            .fill(0)
            .map((item: any, index: number) => index + 1),
        );
      } else {
        setActivityList([]);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const onNextPage = () => {
    setPage((Math.floor((page - 1) / pagePostCount) + 1) * pagePostCount + 1);
    setNowPage(nowPage + 1);
  };

  const onPrevPage = () => {
    setPage((Math.floor((page - 1) / pagePostCount) - 1) * pagePostCount + 1);
    setNowPage(nowPage - 1);
  };

  const onClose = async () => {
    props.setPopupOn({
      ...props.popupOn,
      x: null,
      y: null,
    });
    setAddActivityList([]);
  };

  const onConfirm = async () => {
    props.popupOn.x >= 2
      ? props.onPushActivityInLayout(addActivityList, props.popupOn, description)
      : props.onPushActivityInLayout(
          addActivityList.length > 0
            ? [
                {
                  ...addActivityList[0],
                  color: chooseColor,
                },
              ]
            : addActivityList,
          props.popupOn,
          description,
        );
    onClose();
  };

  useEffect(() => {
    console.log(beforeSubTopic);
    console.log(chooseSubTopic);
    if (beforeSubTopic?.comnCd !== chooseSubTopic?.comnCd) {
      setBeforeSubTopic(chooseSubTopic);
      onSearch(1);
    } else {
      onSearch();
    }
  }, [page, chooseSubTopic]);

  useEffect(() => {
    setChooseSubTopic(
      props.popupOn.activityList &&
        props.popupOn.activityList.length &&
        props.popupOn.activityList[0].domain
        ? props.popupOn.activityList[0].domain
        : null,
    );
  }, [props.popupOn]);

  return (
    <SearchModalPresenter
      type={props.type}
      ageAuths={props.ageAuths}
      option={props.option}
      topicList={topicList}
      subTopicList={subTopicList}
      colorList={colorList}
      popupOn={props.popupOn}
      setPopupOn={props.setPopupOn}
      setPlanId={setPlanId}
      keyword={keyword}
      onSetKeyword={onSetKeyword}
      activityList={activityList}
      onSearch={onSearch}
      page={page}
      setPage={setPage}
      onNextPage={onNextPage}
      onPrevPage={onPrevPage}
      nowPage={nowPage}
      pagePostCount={pagePostCount}
      pageList={pageList}
      addActivityList={addActivityList}
      onIsInActivityList={onIsInActivityList}
      onSetAddActivityList={onSetAddActivityList}
      onCheckAddActivityList={onCheckAddActivityList}
      chooseSubTopic={chooseSubTopic}
      setChooseSubTopic={setChooseSubTopic}
      chooseColor={chooseColor}
      setChooseColor={setChooseColor}
      onConfirm={onConfirm}
      onClose={onClose}
      getType={getType}
      setGetType={setGetType}
      description={description}
      setDescription={setDescription}
      onKeyPress={onKeyPress}
    />
  );
};

export default SearchModalContainer;

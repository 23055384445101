import React, { useState, useEffect } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import AdminOrdersPresenter from './AdminOrdersPresenter';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Floating from '../../../components/floating';
import { useRecoilValue, useRecoilState } from 'recoil';

import * as recoilItem from '../../../util/recoilitem';
import * as util from '../../../util/util';
import { shoppingApi } from '../../../api/api-shopping';

interface AdminOrdersProps extends RouteComponentProps {}

const AdminOrdersContainer = (props: AdminOrdersProps) => {
  const [token, setToken] = useRecoilState(recoilItem.token);
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);
  const [orderList, setOrderList] = useState<any>([]);
  const [displayOrderList, setDisplayOrderList] = useState<any>([]);
  const [keyword, setKeyword] = useState<string>('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let myCartParams: FormData = new FormData();

    let orderParams: FormData = new FormData();
    let orderRes = await shoppingApi.getOpenOrderList(token, orderParams);
    console.log(orderRes);
    if (orderRes.data.rsltCd === '00') {
      let newList = orderRes.data.orderList
        .map((item: any) => ({
          ...item,
          totalPrice: item.totalPrice > 50000 ? item.totalPrice : item.totalPrice + 3000,
          orderSeq: item.orderDt + '-' + item.orderId,
        }))
        .sort((a: any, b: any) => b.orderId - a.orderId);
      console.log(newList);
      setOrderList(newList);
      setDisplayOrderList(newList);
    } else {
      setOrderList([]);
      setDisplayOrderList([]);
    }
  };

  useEffect(() => {
    filterDisplayOrders();
  }, [keyword]);

  const filterDisplayOrders = async () => {
    if (keyword === '' || !keyword) {
      setDisplayOrderList(orderList);
    } else {
      let filtered = orderList.filter(
        (item: any) =>
          item.orderTitle.includes(keyword) ||
          item.orderSeq.includes(keyword) ||
          item.orderDt.includes(keyword) ||
          item.receiverNm.includes(keyword) ||
          item.receiverAddress.includes(keyword) ||
          item.receiverTelno.includes(keyword),
      );

      setDisplayOrderList(filtered);
    }
  };

  const changeItem = async (key: string, value: any, index: number) => {
    let items = orderList;
    items[index][key] = value;
    setOrderList([...items]);
  };

  const saveItem = async (index: number) => {
    let item = orderList[index];
    let params: FormData = new FormData();

    params.append('orderId', item.orderId);
    params.append('sender', item.sender);
    params.append('senderNum', item.senderNum);
    params.append('status', item.status);

    let res = await shoppingApi.updateOpenOrder(token, params);
    if (res.data.rsltCd === '00') {
      util.makeMsg('저장되었습니다.', 'success');
      fetchData();
    } else {
      util.makeMsg('저장에 실패하였습니다.', 'error');
    }
  };

  return (
    <>
      <Header {...props} />
      <AdminOrdersPresenter
        {...props}
        userInfo={userInfo}
        orderList={orderList}
        displayOrderList={displayOrderList}
        keyword={keyword}
        setKeyword={setKeyword}
        changeItem={changeItem}
        saveItem={saveItem}
      />
      <Footer {...props} />
    </>
  );
};

export default AdminOrdersContainer;

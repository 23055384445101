import React, { Dispatch, SetStateAction } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router-dom';
import SelectEdu from '../registerEdu/component/SelectEdu';
import SelectHolder from '../registerEdu/component/SelectHolder';

import * as s from './RegisterEduImageStyled';

interface RegisterEduImageProps extends RouteComponentProps {
  eduList: any[];
  holderList: any[];
  fileList: any[];
  setFileList: Dispatch<SetStateAction<any>>;
  deletedFileList: any[];
  setDeletedFileList: Dispatch<SetStateAction<any>>;
  option: any;
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
  onSubmit: () => void;
}

const RegisterEduImagePresenter = (props: RegisterEduImageProps) => {
  return (
    <s.Container>
      <SelectEdu
        eduList={props.eduList}
        onChangeOption={props.onChangeOption}
        option={props.option}
        isModifying={props.isModifying}
      />
      <SelectHolder
        holderList={props.holderList}
        onChangeOption={props.onChangeOption}
        option={props.option}
        isModifying={props.isModifying}
      />

      {props.option.subMenu && props.option.holder ? (
        <>
          <s.FileArea>
            {/* Add button always comes first. */}
            <s.AddButton
              onClick={() => {
                props.setFileList([
                  ...props.fileList,
                  {
                    fileId: null,
                    fileUrl: '',
                    name: '',
                    viewOrder: 1,
                    file: null,
                    currentStep: null,
                  },
                ]);
              }}
            >
              <FaPlus size={'4rem'} color={'#0091FF'} />
            </s.AddButton>
            {props.fileList.map((file: any, index: number) => (
              <s.FileContainer>
                {file.fileUrl ? (
                  <s.FileImage src={file.fileUrl} />
                ) : (
                  <s.FileInputArea
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length > 0) {
                        let curFile = e.target.files[0];
                        const reader = new FileReader();
                        reader.readAsDataURL(e.target.files[0]);
                        reader.onloadend = async () => {
                          const base64Image = reader.result;

                          let temp = props.fileList;
                          temp[index].fileUrl = base64Image;
                          temp[index].file = curFile;
                          props.setFileList([...temp]);
                        };
                      }
                    }}
                  />
                )}

                <s.FileName
                  type="text"
                  value={file.name}
                  onChange={(e) => {
                    let temp = props.fileList;
                    temp[index].name = e.target.value;
                    props.setFileList([...temp]);
                  }}
                  placeholder={'사진이름을 입력하세요'}
                />
                <s.OrderRow>
                  <s.OrderLabel>순서</s.OrderLabel>
                  <s.FileOrder
                    type="number"
                    value={file.viewOrder}
                    onChange={(e) => {
                      let temp = props.fileList;
                      temp[index].viewOrder = e.target.value;
                      props.setFileList([...temp]);
                    }}
                  />
                </s.OrderRow>
                <s.OrderRow>
                  <s.OrderLabel>단계</s.OrderLabel>
                  <s.FileOrder
                    type="number"
                    value={file.currentStep}
                    onChange={(e) => {
                      let temp = props.fileList;
                      temp[index].currentStep = e.target.value;
                      props.setFileList([...temp]);
                    }}
                  />
                </s.OrderRow>

                <s.RemoveButton
                  onClick={() => {
                    let temp = [];
                    for (let i = 0; i < props.fileList.length; i++) {
                      if (i !== index) {
                        temp.push(props.fileList[i]);
                      } else {
                        if (props.fileList[i].fileId) {
                          props.setDeletedFileList([
                            ...props.deletedFileList,
                            props.fileList[i].fileId,
                          ]);
                        }
                      }
                    }
                    props.setFileList([...temp]);
                  }}
                >
                  <FaMinus size={'2rem'} color={'#ffffff'} />
                </s.RemoveButton>
              </s.FileContainer>
            ))}
          </s.FileArea>
          <s.SaveButton onClick={props.onSubmit}>저장하기</s.SaveButton>
        </>
      ) : (
        <s.FileArea
          style={{
            fontSize: '2rem',
            fontFamily: 'Gosanja',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '15rem',
            color: '#cecece',
          }}
        >
          교육프로그램과 호수를 선택하세요.
        </s.FileArea>
      )}
    </s.Container>
  );
};

export default RegisterEduImagePresenter;

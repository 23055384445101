import React, { Dispatch, SetStateAction } from 'react';

import * as util from '../../../util/util';
import * as s from './OrderRegisterStyled';
import NotFound from '../../../components/notFound';
import { Link } from 'react-router-dom';
import DaumPostcode from 'react-daum-postcode';

interface OrderRegisterProps {
  productList: any[];
  onChangeOpenPost: () => void;
  onCompletePost: (data: any) => void;
  isOpenPost: boolean;

  address: string;
  addressDetail: string;
  setAddressDetail: Dispatch<SetStateAction<string>>;

  mobile: string;
  telno: string;
  orderDetail: string;
  setMobile: Dispatch<SetStateAction<string>>;
  setTelno: Dispatch<SetStateAction<string>>;
  setOrderDetail: Dispatch<SetStateAction<string>>;

  registerOrder: () => void;
}

const OrderRegisterPresenter = (props: OrderRegisterProps) => {
  return (
    <>
      <s.Container>
        <s.OrderRegisterHeader>
          <s.OrderRegisterHeaderTitleArea>
            <s.OrderRegisterHeaderTitle>주문목록</s.OrderRegisterHeaderTitle>
          </s.OrderRegisterHeaderTitleArea>
          <s.OrderRegisterHeaderRightArea>
            <s.OrderRegisterHeaderRightText>
              <s.OrderRegisterHeaderRightIcon src="/static/img/home_icon.jpg" />
              {'HOME > 자사전용공간 > 주문등록'}
            </s.OrderRegisterHeaderRightText>
          </s.OrderRegisterHeaderRightArea>
        </s.OrderRegisterHeader>

        <s.ProgramListArea>
          <s.ProgramList>
            <s.ProgramListHeader>
              <s.ProgramListCenterCol>번호</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>상품종류</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>상품명</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>연령</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>단가</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>수량</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>총액</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>배송비</s.ProgramListCenterCol>
            </s.ProgramListHeader>
          </s.ProgramList>
          {!props.productList || props.productList.length === 0 ? (
            <NotFound />
          ) : (
            props.productList.map((item: any, index: number) => (
              <s.ProgramForm key={index}>
                <s.ProgramListCenterCol>{index + 1}</s.ProgramListCenterCol>
                <s.ProgramListCenterCol>{item.productType}</s.ProgramListCenterCol>
                <s.ProgramListCenterCol>{item.productNm}</s.ProgramListCenterCol>
                <s.ProgramListCenterCol>
                  {item.age ? '만 ' + item.age + '세' : '-'}
                </s.ProgramListCenterCol>
                <s.ProgramListCenterCol>{util.priceToString(item.price)}</s.ProgramListCenterCol>
                <s.ProgramListCenterCol>{util.priceToString(item.count)}</s.ProgramListCenterCol>
                <s.ProgramListCenterCol>
                  {util.priceToString(item.count * item.price)}
                </s.ProgramListCenterCol>
                <s.ProgramListCenterCol>{item.deliveryYn}</s.ProgramListCenterCol>
              </s.ProgramForm>
            ))
          )}
          <s.SelectDoneButton to="/shopping/order">뒤로가기</s.SelectDoneButton>
        </s.ProgramListArea>

        <s.OrderRegisterHeader>
          <s.OrderRegisterHeaderTitleArea>
            <s.OrderRegisterHeaderTitle>배송지 정보 입력</s.OrderRegisterHeaderTitle>
          </s.OrderRegisterHeaderTitleArea>
        </s.OrderRegisterHeader>
        <s.AddressTable>
          <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: '35%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '35%' }} />
          </colgroup>
          <s.AddressTr>
            <s.AddressTh>주소</s.AddressTh>
            <s.AddressTd colSpan={3}>
              <s.AddressTdContent>
                <s.AddressTdInputButton onClick={props.onChangeOpenPost}>
                  우편번호찾기
                </s.AddressTdInputButton>
                <s.AddressTdRow>
                  <s.AddressTdInput
                    disabled={true}
                    style={{ width: '122px', backgroundColor: '#DEDEDE' }}
                    value={props.address}
                  ></s.AddressTdInput>
                  <s.AddressTdInput
                    style={{ marginLeft: '16px', width: '400px' }}
                    value={props.addressDetail}
                    onChange={(e) => {
                      props.setAddressDetail(e.target.value);
                    }}
                  />
                </s.AddressTdRow>
              </s.AddressTdContent>
            </s.AddressTd>
          </s.AddressTr>
          <s.AddressTr>
            <s.AddressTh>휴대폰</s.AddressTh>
            <s.AddressTd>
              <s.AddressTdInput
                value={props.mobile}
                onChange={(e) => {
                  props.setMobile(e.target.value);
                }}
              />
            </s.AddressTd>
            <s.AddressTh>전화번호</s.AddressTh>
            <s.AddressTd>
              <s.AddressTdInput
                value={props.telno}
                onChange={(e) => {
                  props.setTelno(e.target.value);
                }}
              />
            </s.AddressTd>
          </s.AddressTr>
          <s.AddressTr>
            <s.AddressTh>배송요청사항</s.AddressTh>
            <s.AddressTd colSpan={3}>
              <s.AddressTdInputArea
                value={props.orderDetail}
                onChange={(e) => {
                  props.setOrderDetail(e.target.value);
                }}
                style={{ width: '100%', height: '200px', overflow: 'auto' }}
                draggable={false}
              />
            </s.AddressTd>
          </s.AddressTr>
        </s.AddressTable>
        <s.OrderButton onClick={props.registerOrder}>주문하기</s.OrderButton>
      </s.Container>
    </>
  );
};

export default OrderRegisterPresenter;

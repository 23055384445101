import React, { Dispatch, SetStateAction } from 'react';

import * as util from '../../../util/util';
import * as s from './OrderStyled';
import NotFound from '../../../components/notFound';
import { Link } from 'react-router-dom';

interface OrderProps {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  ageList: any;
  holderList: never[];
  selectedAge: {
    comnCd: string;
    comnCdNm: string;
  };
  setSelectedAge: React.Dispatch<
    React.SetStateAction<{
      comnCd: string;
      comnCdNm: string;
    }>
  >;
  selectedHolderList: any;
  onChangeAge: React.MouseEventHandler<HTMLLIElement>;
  onChangeHolderList: (item: any) => void;
  productList: any;
  selectedIdxList: any[];
  setSelectedIdxList: React.Dispatch<React.SetStateAction<any[]>>;
  checkOrder: () => void;
  addToList: (item: any, id: any) => void;
}

const OrderPresenter = (props: OrderProps) => {
  return (
    <s.Container>
      <s.OrderHeader>
        <s.OrderHeaderTitleArea>
          <s.OrderHeaderTitle>상품선택</s.OrderHeaderTitle>
          <s.OrderHeaderSubTitle>엄지교육 어린이교재</s.OrderHeaderSubTitle>
        </s.OrderHeaderTitleArea>
        <s.OrderHeaderRightArea>
          <s.OrderHeaderRightText>
            <s.OrderHeaderRightIcon src="/static/img/home_icon.jpg" />
            {'HOME > 자사전용공간 > 상품선택'}
          </s.OrderHeaderRightText>
        </s.OrderHeaderRightArea>
      </s.OrderHeader>

      <s.SearchConditionArea style={{ display: 'none' }}>
        <s.SearchConditionAgeArea>
          <s.searchConditionHeader>연령 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            {props.ageList.map((item: any, index: number) => (
              <s.SearchConditionItem
                selected={item.comnCd === props.selectedAge.comnCd}
                onClick={() => props.onChangeAge(item)}
              >
                {'만 ' + item.comnCdNm}
              </s.SearchConditionItem>
            ))}
          </s.SearchConditionBody>
        </s.SearchConditionAgeArea>
        <s.SearchConditionMonthArea>
          <s.searchConditionHeader>상품 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            <s.SearchConditionItem
              selected={props.selectedHolderList.length === 0}
              onClick={() => props.onChangeHolderList(null)}
            >
              전체
            </s.SearchConditionItem>
            {props.holderList.map((item: any, index: number) => (
              <s.SearchConditionItem
                selected={props.selectedHolderList
                  .map((item: any) => item.comnCd)
                  .includes(item.comnCd)}
                onClick={() => props.onChangeHolderList(item)}
              >
                {item.comnCdNm}
              </s.SearchConditionItem>
            ))}
          </s.SearchConditionBody>
        </s.SearchConditionMonthArea>
      </s.SearchConditionArea>

      <s.ProgramListArea>
        <s.ProgramTypeTitleArea>
          <s.ProgramTypeTitleFixedPart>
            선택 ({props.selectedIdxList.length})
          </s.ProgramTypeTitleFixedPart>
        </s.ProgramTypeTitleArea>
        <s.ProgramList>
          <s.ProgramListHeader>
            <s.ProgramListSelect>
              {/* <s.ProgramCheckBox
                type={'checkbox'}
                checked={
                  props.productList &&
                  props.productList.length > 0 &&
                  props.selectedIdxList.length === props.productList.length
                }
                onClick={(e) => {
                  if (
                    props.productList &&
                    props.productList.length > 0 &&
                    props.selectedIdxList.length === props.productList.length
                  ) {
                    props.setSelectedIdxList([]);
                  } else {
                    let tempList = props.productList.map((item: any) => item.productId);
                    props.setSelectedIdxList(tempList);
                  }
                }}
              /> */}
            </s.ProgramListSelect>
            <s.ProgramListCenterCol>번호</s.ProgramListCenterCol>
            <s.ProgramListCenterCol>상품종류</s.ProgramListCenterCol>
            <s.ProgramListCenterCol>상품명</s.ProgramListCenterCol>
            <s.ProgramListCenterCol>재고</s.ProgramListCenterCol>
            <s.ProgramListCenterCol>연령</s.ProgramListCenterCol>
            <s.ProgramListCenterCol>금액</s.ProgramListCenterCol>
            <s.ProgramListCenterCol>배송비</s.ProgramListCenterCol>
            <s.ProgramListCenterCol>수량</s.ProgramListCenterCol>
          </s.ProgramListHeader>
        </s.ProgramList>
        {!props.productList || props.productList.length === 0 ? (
          <NotFound />
        ) : (
          props.productList.map((item: any, index: number) => (
            <s.ProgramForm key={index}>
              <s.ProgramListSelect>
                <s.ProgramCheckBox
                  type={'checkbox'}
                  id={`checkbox${index}`}
                  checked={props.selectedIdxList
                    .map((it: any) => it.productId)
                    .includes(item.productId)}
                  onClick={(e) => {
                    props.addToList(item, `#count${index}`);
                    // if (!item.productId || !$(`#count${index}`).val()) {
                    //   util.makeMsg('수량은 필수항목입니다.', 'error');
                    // } else if (
                    //   props.selectedIdxList.map((it: any) => it.productId).includes(item.productId)
                    // ) {
                    //   let tempList = [...props.selectedIdxList];
                    //   tempList = tempList.filter((element) => element.productId !== item.productId);
                    //   props.setSelectedIdxList(tempList);
                    // } else {
                    //   let tempList = [...props.selectedIdxList];
                    //   tempList.push({
                    //     productId: item.productId,
                    //     count: $(`#count${index}`).val(),
                    //   });
                    //   props.setSelectedIdxList(tempList);
                    // }
                  }}
                />
              </s.ProgramListSelect>
              <s.ProgramListCenterCol>{index + 1}</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>{item.productType}</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>{item.productNm}</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>
                {item.stock ? util.priceToString(item.stock) : 0}
              </s.ProgramListCenterCol>
              <s.ProgramListCenterCol>
                {item.age ? '만 ' + item.age + '세' : '-'}
              </s.ProgramListCenterCol>
              <s.ProgramListCenterCol>{util.priceToString(item.price)}</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>{item.deliveryYn}</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>
                <input
                  type="number"
                  id={`count${index}`}
                  style={{ width: '100%', padding: '5px', color: '#4d4d4d' }}
                  disabled={props.selectedIdxList
                    .map((it: any) => it.productId)
                    .includes(item.productId)}
                />
              </s.ProgramListCenterCol>
            </s.ProgramForm>
          ))
        )}
      </s.ProgramListArea>
      <s.SelectDoneButton onClick={props.checkOrder}>선택완료</s.SelectDoneButton>
    </s.Container>
  );
};

export default OrderPresenter;

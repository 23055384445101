import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1180px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const TopTableArea = styled.div`
  width: 1075px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
`;

export const TopTable = styled.table`
  width: 1075px;
  border-spacing: 0px;
  border-collapse: collapse;
  border-top: 1px solid #4d4d4d;
  border-bottom: 1px solid #4d4d4d;
`;

export const TopTbody = styled.tbody``;

export const TopTr = styled.tr``;

export const TopTh = styled.th`
  text-align: center;
  padding: 5px 10px;
  background-color: #efefef;
  color: #4d4d4d;
  font-size: 16px;
`;

export const TopTd = styled.td`
  border-top: 1px solid #bebebe;
  text-align: center;
  padding: 5px 10px;
  color: #4d4d4d;
  font-size: 18px;
`;

export const TopTdSpan = styled.span`
  font-size: 20px;
  font-weight: 600;
`;

export const TopCheckbox = styled.input`
  width: 30px;
  height: 30px;
`;

export const CountComponentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CountComponent = styled.div`
  width: 120px;
  height: 30px;
  display: flex;
`;

export const CountDownButton = styled.div`
  width: 35px;
  height: 30px;
  border-top: 1px solid #cecece;
  border-left: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CountUpButton = styled.div`
  width: 35px;
  height: 30px;
  border-top: 1px solid #cecece;
  border-right: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CountItem = styled.div`
  width: 50px;
  height: 30px;
  border: 1px solid #cecece;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  width: 1075px;
  margin: 20px auto;
  position: relative;
`;

export const DeleteButton = styled.div`
  width: 180px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background-color: #ababab;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  left: 0;
`;

export const GoToShoppingButton = styled.div`
  width: 180px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 1px solid #0091ff;
  color: #0091ff;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  right: 0;
`;

export const TotalPriceBox = styled.div`
  display: flex;
  width: 1075px;
  margin: 0 auto 50px;
  position: relative;
`;

export const TotalPriceProduct = styled.div`
  position: relative;
  width: 370px;
  height: 200px;
  border-top: 1px solid #cecece;
  border-left: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
`;

export const TotalPriceDelivery = styled.div`
  position: relative;
  width: 335px;
  height: 200px;
  border: 1px solid #cecece;
`;

export const TotalPriceSummary = styled.div`
  position: relative;
  width: 370px;
  height: 200px;
  border-top: 1px solid #cecece;
  border-right 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  background-color: #e8f5ff;
`;

export const SignCircle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  border: 1px solid #bebebe;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

export const BoxTitle = styled.div`
  position: absolute;
  top: 20px;
  left: 30px;
  font-size: 20px;
  color: #4d4d4d;
`;

export const BoxPrice = styled.div`
  position: absolute;
  font-size: 30px;
  color: #4d4d4d;
  bottom: 20px;
  right: 30px;
`;

export const OrderButtonWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin: 0 auto 100px;
  align-items: center;
  justify-content: center;
`;

export const OrderSelected = styled.div`
  width: 220px;
  height: 60px;
  background-color: #0091ff;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
`;

export const OrderAll = styled.div`
  width: 220px;
  height: 60px;
  background-color: #7d7d7d;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
`;

export const InfoArea = styled.div`
  width: 1075px;
  padding: 30px;
  font-size: 16px;
  background-color: #efefef;
  border: 1px solid #cecece;
  color: #bebebe;
  margin: 0 auto 50px;
  text-align: left;
  line-height: 30px;
  position: relative;
`;

export const InfoTitle = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  top: 20px;
  left: 10;x
  font-size: 20px;
  color: #4d4d4d;
`;

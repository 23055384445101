import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1180px;
  min-height: 700px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const ProgramHeader = styled.div`
  width: 1080px;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  padding: 35px 10px 5px;
  vertical-align: middle;
  border-bottom: 1px solid #ececec;
`;

export const ProgramHeaderTitle = styled.div`
  float: left;
  font-size: 27px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const ProgramHeaderRightArea = styled.div`
  float: right;
  padding: 10px 0;
  text-align: center;
  vertical-align: middle;
`;
export const ProgramHeaderRightIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 5px;
  vertical-align: middle;
`;

export const ProgramHeaderRightText = styled.div`
  font-weight: 400;
  color: #4d4d4d;
  vertical-align: middle;
`;

export const FullSelectArea = styled.div`
  width: 1040px;
  margin: 0 auto;
  text-align: center;
`;

export const FullSelectDiv = styled.div`
  width: 2.5%;
  margin: 20px auto;
  text-align: center;
  border-bottom: 1px solid;
`;

export const FullSelectLabel = styled.div`
  margin: 40px auto 5px;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const FullSelectForm = styled.div`
  width: 70%;
  margin: 5px auto;
  text-align: center;
  padding: 10px 0;
`;

export const FullSelectForm100 = styled.div`
  width: 100%;
  margin: 5px auto;
  text-align: center;
  padding: 10px 0;
  display: flex;
  justify-content: center;
`;

export const AgeItem = styled.div<{ active: boolean }>`
  padding: 10px 20px;
  font-family: 'Gosanja';
  font-size: 16px;
  width: 115px;
  color: ${(props) => (props.active ? '#fff' : '#9d9d9d')};
  border: 1px solid ${(props) => (props.active ? '#3A96CF' : '#ccc')};
  border-radius: 30px;
  margin: 0 12px;
  background: ${(props) =>
    props.active ? 'linear-gradient(to bottom,#59A9DA 95%, #59A9DA 5px,  #3B89C9 5%)' : '#fff'};
  cursor: pointer;
`;

export const HolderItem = styled.div<{ active: boolean }>`
  padding: 10px;
  font-family: 'Gosanja';
  font-size: 12px;
  width: 60px;
  color: ${(props) => (props.active ? '#fff' : '#9d9d9d')};
  border: 1px solid ${(props) => (props.active ? '#3A96CF' : '#ccc')};
  border-radius: 30px;
  margin: 0 5px;
  background: ${(props) =>
    props.active ? 'linear-gradient(to bottom,#59A9DA 95%, #59A9DA 5px,  #3B89C9 5%)' : '#fff'};
  cursor: pointer;
`;

import swal from 'sweetalert';
import jwt_decode from 'jwt-decode';
import { tokenApi } from '../api/api-token';

export const makeMsg = (msg: string, type: string) => {
  switch (type) {
    case 'success':
      swal({
        text: msg,
        buttons: ['확인', false],
        icon: 'success',
        timer: Infinity,
      });
      break;
    case 'error':
      swal({
        text: msg,
        buttons: ['확인', false],
        icon: 'error',
        timer: Infinity,
      });
      break;
    default:
      swal({
        text: msg,
        buttons: ['확인', false],
        timer: Infinity,
      });
      break;
  }
};

export const tokenCheck = (token: any) => {
  if (!token || !token.accessToken || token.accessToken === '' || token.accessToken === null) {
    localStorage.clear();
    return false;
  }
  let decode: any = jwt_decode(token.accessToken);
  let exp: any = decode.exp;
  let now: any = Date.now() / 1000;
  if (exp < now) {
    let refreshDecode: any = jwt_decode(token.refreshToken);
    let refreshExp: any = refreshDecode.exp;
    if (refreshExp < now) {
      localStorage.clear();
      return false;
    } else {
      // refresh 로직
      localStorage.clear();
      return false;
    }
  } else {
    return true;
  }
};
export const getNewToken = async (token: any) => {
  let result = await tokenApi.getNewToken(token);
  // console.log("NewToken :: ", result);
  await localStorage.setItem('token', JSON.stringify(result.data.sessionToken));
  return result.data.sessionToken;
};

export const chkResultCd = (rsltCd: any, msg?: string) => {
  switch (rsltCd) {
    case '00':
      return true;
    case '02':
      makeMsg('잘못된 권한입니다. (' + msg + ')', 'error');
      break;
    case '03':
      makeMsg('잘못된 입력값입니다. (' + msg + ')', 'error');
      break;
    case '06':
      makeMsg('잘못된 데이터입니다. (' + msg + ')', 'error');
      break;
    case '10':
      makeMsg('사용자를 찾을 수 없습니다. 회원가입을 해주세요.', 'error');
      break;
    case '12':
      makeMsg('사용자를 찾을 수 없습니다. SNS회원가입을 해주세요.', 'error');
      break;
    case '07':
      makeMsg('관리자가 승인한 후 이용가능합니다.', 'error');
      break;
    case '17':
      makeMsg('이메일 인증 후 이용가능합니다.', 'error');
      break;
    case '11':
      makeMsg('비밀번호가 일치하지 않습니다.', 'error');
      break;
    case '05':
      makeMsg('데이터가 존재하지않습니다. (' + msg + ')', 'error');
      break;
    case '13':
      makeMsg('sns 토큰이 유효하지 않습니다.', 'error');
      break;
    default:
      makeMsg('실패했습니다. (' + msg + ')', 'error');
      break;
  }
  return false;
};

export const priceToString = (price: number) => {
  price = Math.floor(price);
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

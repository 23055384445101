import moment from 'moment';
import React, { Dispatch, Props, SetStateAction } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router-dom';
import { priceToString } from '../../../util/util';
import * as s from './AdminOrdersStyled';

interface AdminOrdersProps extends RouteComponentProps {
  userInfo: {
    userKey: any;
    userId: any;
    userNm: any;
    userTypeCd: any;
    useYn: any;
    regDtm: any;
    userEmail: any;
    userMobno: any;
  };
  orderList: any;
  displayOrderList: any;
  keyword: string;
  setKeyword: Dispatch<SetStateAction<string>>;
  changeItem: (key: string, value: any, index: number) => void;
  saveItem: (index: number) => void;
}

const AdminOrdersPresenter = (props: AdminOrdersProps) => {
  return (
    <s.Container>
      <s.OrderListArea>
        <s.TopTitle>주문목록</s.TopTitle>
        <s.KeywordWrapper>
          <s.KeywordLabel>통합검색</s.KeywordLabel>
          <s.KeywordInput
            type="text"
            value={props.keyword}
            onChange={(e) => props.setKeyword(e.target.value)}
          />
        </s.KeywordWrapper>

        <s.OrderListTable>
          <s.OrderListTbody>
            <s.OrderListTr>
              <s.OrderListTh style={{ width: '6%' }}>주문서번호</s.OrderListTh>
              <s.OrderListTh style={{ width: '5%' }}>주문일시</s.OrderListTh>
              <s.OrderListTh style={{ width: '9%' }}>주문상품</s.OrderListTh>
              <s.OrderListTh style={{ width: '5%' }}>주문금액</s.OrderListTh>
              <s.OrderListTh style={{ width: '5%' }}>주문자</s.OrderListTh>
              <s.OrderListTh style={{ width: '15%' }}>주소</s.OrderListTh>
              <s.OrderListTh style={{ width: '5%' }}>연락처</s.OrderListTh>
              <s.OrderListTh style={{ width: '7%' }}>상품명</s.OrderListTh>
              <s.OrderListTh style={{ width: '7%' }}>수량</s.OrderListTh>
              <s.OrderListTh style={{ width: '10%' }}>배송회사</s.OrderListTh>
              <s.OrderListTh style={{ width: '10%' }}>운송장번호</s.OrderListTh>
              <s.OrderListTh style={{ width: '7%' }}>상태</s.OrderListTh>
              <s.OrderListTh style={{ width: '10%' }}>수정</s.OrderListTh>
            </s.OrderListTr>
            {props.displayOrderList.length > 0 ? (
              props.displayOrderList.map((item: any, index: number) => {
                return item.productList.map((product: any, index: number) =>
                  index === 0 ? (
                    <s.OrderListTr>
                      <s.OrderListTd rowSpan={item.productList.length}>
                        {item.orderSeq}
                      </s.OrderListTd>
                      <s.OrderListTd rowSpan={item.productList.length}>
                        {item.orderDt}
                      </s.OrderListTd>
                      <s.OrderListTd rowSpan={item.productList.length}>
                        {item.orderTitle}
                      </s.OrderListTd>
                      <s.OrderListTd rowSpan={item.productList.length}>
                        {priceToString(item.totalPrice)}&nbsp;원
                      </s.OrderListTd>
                      <s.OrderListTd rowSpan={item.productList.length}>
                        {item.receiverNm}
                      </s.OrderListTd>
                      <s.OrderListTd rowSpan={item.productList.length}>
                        {item.receiverAddress}
                      </s.OrderListTd>
                      <s.OrderListTd rowSpan={item.productList.length}>
                        {item.receiverTelno}
                      </s.OrderListTd>
                      <s.OrderListTd>{product.productNm}</s.OrderListTd>
                      <s.OrderListTd>{product.count}</s.OrderListTd>

                      <s.OrderListTd rowSpan={item.productList.length}>
                        <s.OrderListTdInput
                          type="text"
                          placeholder="배송사"
                          value={item.sender}
                          onChange={(e) => {
                            props.changeItem('sender', e.target.value, index);
                          }}
                          readOnly={
                            item.status === 'DELIVERED' ||
                            item.status === 'DONE' ||
                            item.status === 'CANCELED'
                          }
                          style={{
                            backgroundColor:
                              item.status === 'DELIVERED' ||
                              item.status === 'DONE' ||
                              item.status === 'CANCELED'
                                ? '#ABABAB'
                                : '#FFFFFF',
                          }}
                        />
                      </s.OrderListTd>
                      <s.OrderListTd rowSpan={item.productList.length}>
                        <s.OrderListTdInput
                          type="text"
                          placeholder="송장번호"
                          value={item.senderNum}
                          onChange={(e) => {
                            props.changeItem('senderNum', e.target.value, index);
                          }}
                          readOnly={
                            item.status === 'DELIVERED' ||
                            item.status === 'DONE' ||
                            item.status === 'CANCELED'
                          }
                          style={{
                            backgroundColor:
                              item.status === 'DELIVERED' ||
                              item.status === 'DONE' ||
                              item.status === 'CANCELED'
                                ? '#ABABAB'
                                : '#FFFFFF',
                          }}
                        />
                      </s.OrderListTd>
                      <s.OrderListTd rowSpan={item.productList.length}>
                        <s.OrderListTdSelect
                          value={item.status}
                          onChange={(e) => {
                            props.changeItem('status', e.target.value, index);
                          }}
                        >
                          <option value="ORDERED">배송준비중</option>
                          <option value="DELIVERING">배송중</option>
                          <option value="DELIVERED">배송완료</option>
                          <option value="DONE">확정완료</option>
                          <option value="CANCELED">주문취소</option>
                        </s.OrderListTdSelect>
                      </s.OrderListTd>
                      <s.OrderListTd rowSpan={item.productList.length}>
                        <s.UpdateItem
                          onClick={() => {
                            props.saveItem(index);
                          }}
                        >
                          수정
                        </s.UpdateItem>
                      </s.OrderListTd>
                    </s.OrderListTr>
                  ) : (
                    <s.OrderListTr>
                      <s.OrderListTd>{product.productNm}</s.OrderListTd>
                      <s.OrderListTd>{product.count}</s.OrderListTd>
                    </s.OrderListTr>
                  ),
                );
              })
            ) : (
              <s.OrderListTr>
                <s.OrderListTd colSpan={13}>주문이 없습니다.</s.OrderListTd>
              </s.OrderListTr>
            )}
          </s.OrderListTbody>
        </s.OrderListTable>
      </s.OrderListArea>
    </s.Container>
  );
};

export default AdminOrdersPresenter;

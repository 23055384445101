import React, { useState, useEffect } from 'react';
import * as recoilItem from '../../util/recoilitem';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as s from './ModalStyled';

import { programApi } from '../../api/api-program';
import Slider from '../bigSlider/Slider';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';
import { saveAs } from 'file-saver';
import { wait } from '@testing-library/react';

interface HeaderProps {}

const HeaderPresenter = (props: HeaderProps) => {
  const token = useRecoilValue(recoilItem.token);
  const [programId, setProgramId] = useRecoilState(recoilItem.programId);
  const [programSubId, setProgramSubId] = useRecoilState(recoilItem.programSubId);
  const userInfo = useRecoilValue(recoilItem.userInfo);

  const [program, setProgram] = useState<any>(null);
  const [currentIdx, setCurrentIdx] = useState<number>(0);

  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const fetchData = async () => {
    try {
      if (programId !== null) {
        setIsLoading(true);
        if (programSubId !== null) {
          await programApi.getProgram(token, programId, programSubId).then((res) => {
            if (res.data.rsltCd === '00') {
              console.log(res.data.program);
              setProgram(res.data.program);
            }
          });
        } else {
          await programApi.getProgram(token, programId).then((res) => {
            if (res.data.rsltCd === '00') {
              console.log(res.data.program);
              setProgram(res.data.program);
            }
          });
        }
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCurrentIdx(0);
    fetchData();
  }, [programId]);

  const cancle = () => {
    setProgramSubId(null);
    setProgramId(null);
    setProgram(null);
  };

  const getYoutubeId = (url: string) => {
    var videoid = url.match(
      /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/,
    );
    if (videoid != null) {
      return videoid[1];
    } else {
      return '';
    }
  };

  const ActivityProgramModal = () => {
    return (
      <s.ModalContainer>
        <s.SmallTitleArea>
          <s.TitleLeft>
            {program.field && program.field.menuCd ? (
              <s.SmallTitleLeftIcon src={`/static/icons/${program.field.menuCd}.png`} />
            ) : null}
          </s.TitleLeft>
          <s.TitleRight>
            <s.TitleRightTop>
              {program.age ? (
                <s.TitleRightTopText>
                  만 {program.age.comnCdNm} {program.holder?.comnCdNm}
                </s.TitleRightTopText>
              ) : (
                <s.TitleRightTopText>{program.holder?.comnCdNm}</s.TitleRightTopText>
              )}
              {program.field ? (
                <s.TitleRightTopIcon>{program.field?.menuNm}</s.TitleRightTopIcon>
              ) : null}
            </s.TitleRightTop>
            <s.TitleRightBottom>{program.title}</s.TitleRightBottom>
          </s.TitleRight>
        </s.SmallTitleArea>
        <Slider
          sildeList={program.programImages.map((item: any, index: number) => (
            <s.SlideThumbnailArea
              current={index === currentIdx}
              onClick={() => setCurrentIdx(index)}
            >
              <s.SlideThumbnail src={item.file.fileUrl} />
            </s.SlideThumbnailArea>
          ))}
          slideSize={10}
          currentIdx={currentIdx}
          nextBtn={<s.SlideBtn src="/static/img/Rshaft.png" />}
          prevBtn={<s.SlideBtn src="/static/img/Lshaft.png" />}
        />
        <s.MultContentArea>
          <s.ContentArea>
            <s.ContentImage src={program.programImages[currentIdx].file.fileUrl} />
          </s.ContentArea>
        </s.MultContentArea>
        <s.ButtonArea>
          <s.WhiteButton
            onClick={() => {
              if (userInfo.userId && userInfo.userTypeCd !== 'NAIVUSER') {
                for (let i = 0; i < program.programImages.length; i++) {
                  setTimeout(() => {
                    saveAs(
                      program.programImages[i].file.fileUrl,
                      program.programImages[i].file.originalFileName,
                    );
                  }, 500 * i);
                }
              }
            }}
          >
            <s.DownloadBtnIcon src="/static/img/download_button.png" />
            {program.subMenu.menuCd === 'MN0401' ? '활동지' : '지침서'} 다운로드
          </s.WhiteButton>
          <s.BlueButton onClick={cancle}>닫기</s.BlueButton>
        </s.ButtonArea>
      </s.ModalContainer>
    );
  };

  const DocumentProgramModal = () => {
    return (
      <s.ModalContainer>
        <s.SmallTitleArea>
          <s.TitleLeft>
            {program.field && program.field.menuCd ? (
              <s.SmallTitleLeftIcon src={`/static/icons/${program.field.menuCd}.png`} />
            ) : null}
          </s.TitleLeft>
          <s.TitleRight>
            <s.TitleRightTop>
              {program.age ? (
                <s.TitleRightTopText>
                  만 {program.age.comnCdNm} {program.holder.comnCdNm}
                </s.TitleRightTopText>
              ) : (
                <s.TitleRightTopText>{program.holder.comnCdNm}</s.TitleRightTopText>
              )}
              {program.field ? (
                <s.TitleRightTopIcon>{program.field?.menuNm}</s.TitleRightTopIcon>
              ) : null}
            </s.TitleRightTop>
            <s.TitleRightBottom>{program.title}</s.TitleRightBottom>
          </s.TitleRight>
        </s.SmallTitleArea>
        <Slider
          sildeList={program.programDocuments.map((item: any, index: number) => (
            <s.SlideThumbnailArea
              current={index === currentIdx}
              onClick={() => setCurrentIdx(index)}
            >
              <s.SlideThumbnail src={item.file.fileUrl} />
            </s.SlideThumbnailArea>
          ))}
          slideSize={10}
          currentIdx={currentIdx}
          nextBtn={<s.SlideBtn src="/static/img/Rshaft.png" />}
          prevBtn={<s.SlideBtn src="/static/img/Lshaft.png" />}
        />
        <s.MultContentArea>
          <s.ContentArea>
            <s.ContentImage src={program.programDocuments[currentIdx].file.fileUrl} />
          </s.ContentArea>
        </s.MultContentArea>
        <s.ButtonArea>
          <s.WhiteButton
            onClick={() =>
              userInfo.userId && userInfo.userTypeCd !== 'NAIVUSER'
                ? saveAs(
                    program.programDocuments[0].file.fileUrl,
                    program.programDocuments[0].file.originalFileName,
                  )
                : console.log('')
            }
          >
            <s.DownloadBtnIcon src="/static/img/download_button.png" />
            문서 다운로드
          </s.WhiteButton>
          <s.BlueButton onClick={cancle}>닫기</s.BlueButton>
        </s.ButtonArea>
      </s.ModalContainer>
    );
  };

  const SoundProgramModal = () => {
    return (
      <s.AudioModalContainer>
        <s.SmallTitleArea>
          <s.TitleLeft>
            {program.field && program.field.menuCd ? (
              <s.SmallTitleLeftIcon src={`/static/icons/${program.field.menuCd}.png`} />
            ) : null}
          </s.TitleLeft>
          <s.TitleRight>
            <s.TitleRightTop>
              {program.age ? (
                <s.TitleRightTopText>
                  만 {program.age.comnCdNm} {program.holder.comnCdNm}
                </s.TitleRightTopText>
              ) : (
                <s.TitleRightTopText>{program.holder.comnCdNm}</s.TitleRightTopText>
              )}
              {program.field ? (
                <s.TitleRightTopIcon>{program.field.menuNm}</s.TitleRightTopIcon>
              ) : null}
            </s.TitleRightTop>
            <s.TitleRightBottom>{program.title}</s.TitleRightBottom>
          </s.TitleRight>
        </s.SmallTitleArea>
        <s.SubTitleArea>
          {!program.programSounds[0].tune ? (
            <>
              <s.SubTitleText>{program.programSounds[0].composer}</s.SubTitleText>
              <s.SubTitleSeparator />
              <s.SubTitleText>{program.programSounds[0].lyricist}</s.SubTitleText>
            </>
          ) : (
            <s.SubTitleText>{program.programSounds[0].tune}</s.SubTitleText>
          )}
        </s.SubTitleArea>
        <s.AudioImageArea>
          {program.programSounds[0].soundLyrics ? (
            <s.AudioImage src={program.programSounds[0].soundLyrics.fileUrl} />
          ) : (
            <>
              <s.AudioImage src={'/static/img/audio_default.png'} />
              <s.AudioDefaultDataArea>
                <s.AudioDefaultIcon src={'/static/img/audio_icon.png'} />
                <s.AudioInfoArea>
                  <s.AudioInfoTitle>{program.title}</s.AudioInfoTitle>
                  <s.AudioInfoDetails>
                    {program.programSounds[0].composer}
                    {' | '}
                    {program.programSounds[0].lyricist}
                  </s.AudioInfoDetails>
                </s.AudioInfoArea>
              </s.AudioDefaultDataArea>
            </>
          )}
        </s.AudioImageArea>
        <s.AudioArea>
          <s.VideoAreaType>{program.programSounds[0].type}</s.VideoAreaType>
          <s.VideoAreaTitle>{program.programSounds[0].title}</s.VideoAreaTitle>
          <ReactPlayer
            // Disable download button
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            // Disable right click
            onContextMenu={(e: { preventDefault: () => any }) => e.preventDefault()}
            width={'100%'}
            height={'auto'}
            url={program.programSounds[0].soundSoundtrack?.fileUrl}
            controls
          />
        </s.AudioArea>
        <s.ButtonArea>
          {program.programSounds[0].soundLyrics ? (
            <s.BigWhiteButton
              onClick={() =>
                userInfo.userId && userInfo.userTypeCd !== 'NAIVUSER'
                  ? saveAs(
                      program.programSounds[0].soundLyrics.fileUrl,
                      program.programSounds[0].soundLyrics.originalFileName,
                    )
                  : console.log('')
              }
            >
              <s.DownloadBtnIconBigButton src="/static/img/download_button.png" />
              가사 다운로드
            </s.BigWhiteButton>
          ) : null}
          {program.programSounds[0].soundSheet ? (
            <s.BigWhiteButton
              onClick={() =>
                userInfo.userId && userInfo.userTypeCd !== 'NAIVUSER'
                  ? saveAs(
                      program.programSounds[0].soundSheet.fileUrl,
                      program.programSounds[0].soundSheet.originalFileName,
                    )
                  : console.log('')
              }
            >
              <s.DownloadBtnIconBigButton src="/static/img/download_button.png" />
              악보 다운로드
            </s.BigWhiteButton>
          ) : null}
          <s.BigBlueButton onClick={cancle}>닫기</s.BigBlueButton>
        </s.ButtonArea>
      </s.AudioModalContainer>
    );
  };

  const MediaProgramModal = () => {
    return (
      <s.ModalContainer>
        <s.SmallTitleArea>
          <s.TitleLeft>
            {program.field && program.field.menuCd ? (
              <s.SmallTitleLeftIcon src={`/static/icons/${program.field.menuCd}.png`} />
            ) : null}
          </s.TitleLeft>
          <s.TitleRight>
            <s.TitleRightTop>
              {program.age ? (
                <s.TitleRightTopText>
                  만 {program.age.comnCdNm} {program.holder?.comnCdNm}
                </s.TitleRightTopText>
              ) : (
                <s.TitleRightTopText>{program.holder?.comnCdNm}</s.TitleRightTopText>
              )}
              {program.field ? (
                <s.TitleRightTopIcon>{program.field.menuNm}</s.TitleRightTopIcon>
              ) : null}
            </s.TitleRightTop>
            <s.TitleRightBottom>{program.title}</s.TitleRightBottom>
          </s.TitleRight>
        </s.SmallTitleArea>
        <Slider
          sildeList={program.programMedias.map((item: any, index: number) => {
            console.log(item);
            return (
              <s.SlideThumbnailArea
                current={index === currentIdx}
                onClick={() => setCurrentIdx(index)}
              >
                <s.SlideThumbnail src={item.thumbnail?.fileUrl} />
              </s.SlideThumbnailArea>
            );
          })}
          slideSize={10}
          currentIdx={currentIdx}
          nextBtn={<s.SlideBtn src="/static/img/Rshaft.png" />}
          prevBtn={<s.SlideBtn src="/static/img/Lshaft.png" />}
        />
        <s.VideoContentArea>
          {program.programMedias.length !== 0 ? (
            program.programMedias[currentIdx].url ? (
              <>
                <YouTube
                  videoId={getYoutubeId(program.programMedias[currentIdx].url)}
                  opts={{
                    height: '500',
                    width: '940',
                    playerVars: {
                      autoplay: 0,
                    },
                  }}
                />
                <s.VideoAreaDescription>
                  {program.programMedias[currentIdx].title}
                </s.VideoAreaDescription>
              </>
            ) : (
              <>
                <ReactPlayer
                  // Disable download button
                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  // Disable right click
                  onContextMenu={(e: { preventDefault: () => any }) => e.preventDefault()}
                  width={'940px'}
                  height={'auto'}
                  url={program.programMedias[currentIdx].file.fileUrl}
                  controls
                />
                <s.VideoAreaDescription>
                  {program.programMedias[currentIdx].title}
                </s.VideoAreaDescription>
              </>
            )
          ) : null}
        </s.VideoContentArea>
        <s.ButtonArea>
          <s.BlueButton onClick={cancle}>닫기</s.BlueButton>
        </s.ButtonArea>
      </s.ModalContainer>
    );
  };

  const SwitchModal = () => {
    switch (program.topMenu.menuCd) {
      case 'MN04':
        switch (program.subMenu.menuCd) {
          case 'MN0401':
            return <ActivityProgramModal />;
          case 'MN0402':
            return <SoundProgramModal />;
          case 'MN0403':
            return <DocumentProgramModal />;
          case 'MN0404':
            return <DocumentProgramModal />;
          case 'MN0405':
            return <DocumentProgramModal />;
          case 'MN0406':
            return <ActivityProgramModal />;
          case 'MN0407':
            return <DocumentProgramModal />;
          case 'MN0408':
            return <MediaProgramModal />;
          default:
            return null;
        }
      case 'MN05':
        return <MediaProgramModal />;
      default:
        cancle();
        return null;
    }
  };
  return programId !== null && program !== null ? (
    <s.Container>
      <SwitchModal />
    </s.Container>
  ) : null;
};

HeaderPresenter.defaultProps = {};

export default HeaderPresenter;

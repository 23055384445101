import styled from 'styled-components';

export const Container = styled.div<{ imgsrc: string }>`
  width: 100%;
  min-width: 1180px;
  min-height: 650px;
  position: relative;
  margin: 0 auto -30px;
  background-image: ${(props) => `url(${props.imgsrc})`};
  background-size: 100% 100%;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const RegisterContentArea = styled.div`
  display: block;
  width: 460px;
  margin: 0 auto;
  padding-top: 90px;
  text-align: center;
`;

export const LogoImg = styled.img`
  display: block;
  width: 40%;
  height: auto;
  object-fit: contain;
  cursor: pointer;
  margin: 0 auto 30px;
`;

export const RegisterTextInput = styled.input`
  display: block;
  position: relative;
  width: 100%;
  height: 50px;
  border: 0;
  border-radius: 20px;
  padding-left: 20px;
  font-size: 15px;
  color: #c9c9c9;
  box-sizing: border-box;
  background-color: #f0f0f0;
  cursor: pointer;
`;

export const RegisterButton = styled.img`
  width: 20%;
  height: 20%;
  margin-top: 20px;
`;

export const RegisterContent = styled.div`
  display: block;
`;

export const RegisterRow = styled.div`
  display: block;
`;

export const RegisterTitle = styled.h3`
  margin: 19px 0 8px;
  font-size: 14px;
  font-weight: 700;
`;

export const RegisterRadioContent = styled.div`
  display: inline-flex;
  width: 350px;
  height: 70px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  padding: 20px 15px;
  // box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
`;

export const RegisterRadioLabel1 = styled.label<{ userType: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 20px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgray;
  transition: all 0.3s ease;
  background-color: ${(props) => (props.userType === 'ADMIN' ? '#0087ed' : '#fff')};
`;

export const RegisterRadioLabel2 = styled.label<{ userType: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 20px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgray;
  transition: all 0.3s ease;
  background-color: ${(props) => (props.userType === 'USER' ? '#0087ed' : '#fff')};
`;

export const RegisterRadioDot1 = styled.div<{ userType: string }>`
  height: 15px;
  width: 15px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
  background-color: ${(props) => (props.userType === 'ADMIN' ? '#fff' : '#d9d9d9')};

  ::before {
    position: absolute;
    content: '';
    top: 3px;
    left: 3px;
    width: 9px;
    height: 9px;
    background: #0087ed;
    border-radius: 50%;
    opacity: 0;
    opacity: ${(props) => (props.userType === 'ADMIN' ? 1 : 0)};
    transition: all 0.3s ease;
  }
`;

export const RegisterRadioDot2 = styled.div<{ userType: string }>`
  height: 15px;
  width: 15px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
  background-color: ${(props) => (props.userType === 'USER' ? '#fff' : '#d9d9d9')};

  ::before {
    position: absolute;
    content: '';
    top: 3px;
    left: 3px;
    width: 9px;
    height: 9px;
    background: #0087ed;
    border-radius: 50%;
    opacity: 0;
    opacity: ${(props) => (props.userType === 'USER' ? 1 : 0)};
    transition: all 0.3s ease;
  }
`;

export const RegisterRadioText1 = styled.span<{ userType: string }>`
  font-size: 15px;
  color: ${(props) => (props.userType === 'ADMIN' ? '#fff' : '#000')};
`;

export const RegisterRadioText2 = styled.span<{ userType: string }>`
  font-size: 15px;
  color: ${(props) => (props.userType === 'USER' ? '#fff' : '#000')};
`;

export const RegisterRadioInput = styled.input`
  display: none;
`;

export const RegisterCompanyContent = styled.div`
  display: inline-flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space;
  border-radius: 5px;
  cursor: pointer;
`;

export const RegisterAgencyInput = styled.input`
  width: 70%;
  height: 50px;
  border: 0;
  border-radius: 20px;
  padding-left: 20px;
  font-size: 15px;
  color: #c9c9c9;
  box-sizing: border-box;
  background-color: #f0f0f0;
`;

export const RegisterAgencyButtonArea = styled.div`
  display: block;
  width: 30%;
`;

export const RegisterAgencyButton = styled.button`
  display: block;
  width: 70%;
  height: 40px;
  margin: 5% 15%;
  color: white;
  font-size: 15px;
  font-weight: bold;
  background-color: #0087ed;
  border: 2px solid #0087ed;
`;

export const RegisterSubmit = styled.div`
  display: block;
  width: 80%;
  margin: 40px auto;
  text-align: center;
`;

export const RegisterConfirmButton = styled.button`
  width: 100%;
  height: 40px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  border-radius: 15px;
  background-color: #0087ed;
  border: 2px solid #0087ed;
`;

export const ModalBackGround = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(77, 77, 77, 0.5);
  z-index: 1999;
`;

export const ModalForm = styled.div`
  position: relative;
  width: 640px;
  height: 82.5%; /* */
  z-index: 2999;
  opacity: 1;
  margin: 5% 25% 0;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.25) 0px 8px 24px, rgba(17, 17, 26, 0.25) 0px 16px 56px,
    rgba(17, 17, 26, 0.25) 0px 24px 80px;
`;

export const ModalHeader = styled.div`
  display: inline-block;
  width: 100%;
  height: 60px;
  background-color: #fff;
  border-top: 4px solid #0087ed;
`;

export const ModalHeaderText = styled.span`
  display: inline-block;
  width: 25%;
  line-height: 60px;
  text-align: center;
  font-size: 20px;
`;

export const ModalExitArea = styled.div`
  position: absolute;
  top: 17px;
  right: 17px;
  width: 26px;
  height: 26px;
  float: right;
`;

export const ModalExitImg = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const ModalNavBar = styled.div`
  display: inline-block;
  width: 92%;
  line-height: 50px;
  margin: 0 4%;
  align-items: center;
  justify-content: center;
`;

export const ModalNavSearchArea = styled.div`
  width: 350px;
  height: 40px;
  display: inline-block;
`;

export const ModalNavRefresh = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  vertical-align: middle;
  cursor: pointer;
`;

export const ModalNavSearchImg = styled.img`
  width: 30px;
  height: 30px;
  vertical-align: baseline;
  margin: 2px;
`;

export const ModalNavSearchTextArea = styled.div`
  display: inline-block;
  line-height: 35px;
  width: 220px;
  border: 1.5px solid #0087ed;
`;

export const ModalNavSearchText = styled.input`
  width: 180px;
  height: 35px;
  padding-left: 10px;
  border: none;
`;

export const ModalNavSearchButton = styled.div`
  display: inline-block;
  width: 35px;
  height: 35px;
  vertical-align: middle;
  cursor: pointer;
`;

export const ModalAddButtonArea = styled.div`
  width: 75px;
  height: 100%;
  vertical-align: middle;
  margin-right: 30px;
  float: right;
`;

export const ModalAddCompanyButton = styled.button`
  width: 90px;
  height: 40px;
  color: white;
  font-size: 13.5px;
  background-color: #0087ed;
  border: 1px solid #0087ed;
`;

export const ModalListArea = styled.div`
  width: auto;
  height: auto;
  margin: 20px 15px 0;
  font-size: 15px;
`;

export const ModalColumnArea = styled.div`
  display: flex;
  width: 100%;
  line-height: 25px;
  padding: 5px 0;
  background-color: rgba(0, 135, 237, 0.15);
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`;

export const ModalContentsList = styled.ul`
  display: block;
  width: auto;
  height: auto;
`;

export const ModalListItem = styled.li`
  display: flex;
  width: 100%;
  line-height: 25px;
  padding: 5px 0;
  border-bottom: 1px solid black;

  &:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: rgba(240, 240, 240, 0.5);
  }
`;

export const ModalListItemName = styled.div`
  flex: 3;
  padding-left: 10px;
`;

export const ModalListItemAddr = styled.div`
  flex: 6;
  padding-left: 10px;
`;

export const ModalListItemContact = styled.div`
  flex: 3;
  padding-left: 10px;
`;

export const ModalButtonArea = styled.div`
  display: flex;
  width: 40%;
  height: 70px;
  float: right;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`;

export const ModalExitButton = styled.button`
  width: 80px;
  height: 40px;
  margin: 0 20px;
  border-radius: 20px;
  color: #fff;
  text-decoration: none;
  background-color: #e74c3c;
`;

export const ModalConfirmButton = styled.button`
  width: 80px;
  height: 40px;
  margin: 0 20px;
  border-radius: 20px;
  color: #fff;
  background-color: rgb(0, 135, 237);
`;

export const ModalPagingNav = styled.div`
  width: 100%;
  height: 26px;
  padding: 3px;
  text-align: center;
`;

export const ModalPagingLink = styled.div`
  display: inline-block;
  width: 12px;
  height: 100%;
  margin: 0 3px;

  &:hover {
    cursor: pointer;
  }
`;

export const ModalPagingLinkImg = styled.img``;

export const ModalPagingNumArea = styled.ul`
  display: inline-block;
  height: 100%;
  margin: 0 15px;
  text-align: center;
`;

export const ModalPagingNum = styled.li<{ current: boolean }>`
  display: inline-block;
  margin: 0 3px;
  font-weight: bold;
  color: ${(props) => (props.current ? 'blue' : 'default')};
  padding: 2px 6px;
  border: 1px solid #f0f0f0;

  &:hover {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
`;

export const ModalAddCompanyRow = styled.div`
  display: block;
  width: 50%;
  margin: 30px auto;
`;

export const ModalAddCompanyTitle = styled.h3`
  margin: 19px 0 8px;
  font-size: 14px;
  font-weight: 700;
`;

export const ModalAddCompanyTextInput = styled.input`
  display: block;
  position: relative;
  width: 100%;
  height: 50px;
  border: 0;
  border-radius: 20px;
  padding-left: 20px;
  font-size: 15px;
  color: #c9c9c9;
  box-sizing: border-box;
  background-color: #f0f0f0;
`;

export const ModalAddCompanyCfBtnArea = styled.div`
  display: block;
  width: 45%;
  height: 50px;
  margin: 40px auto;
`;

export const ModalAddCompanyConfirmBtn = styled.button`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 17px;
  font-weight: bold;
  color: white;
  background-color: #0087ed;
  border: 1px solid #0087ed;
  border-radius: 20px;
`;

export const RegisterAuthContent = styled.div`
  display: inline-flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space;
  border-radius: 5px;
  cursor: pointer;
`;

export const AuthListArea = styled.div`
  width: auto;
  height: auto;
  margin: 20px 15px 0;
  font-size: 15px;
`;

export const AuthColumnArea = styled.div`
  display: flex;
  width: 100%;
  line-height: 25px;
  padding: 5px 0;
  background-color: rgba(0, 135, 237, 0.15);
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`;

export const AuthContentsList = styled.ul`
  display: block;
  width: auto;
  height: auto;
`;

export const AuthListItem = styled.li`
  display: flex;
  width: 100%;
  height: 25px;
  margin: 5px 0;
  align-items: center;
  vertical-align: middle;
  border-bottom: 1px solid black;
`;

export const AuthListItemDetailArea = styled.div`
  flex: 1.5;
  text-align: center;
`;

export const AuthListItemSizeArea = styled.div`
  flex: 2.5;
  text-align: center;
`;

export const AuthEmptyArea = styled.div`
  flex: 2;
`;

export const AuthCheckInput = styled.input`
  display: none;
`;

export const AuthCheckLabel = styled.label`
  flex: 1.5;
  height: 18px;
`;

export const AuthCheckBox = styled.div<{ checked: boolean }>`
  width: 40%;
  height: 100%;
  margin: 0 auto;
  border: 1px solid #0087ed;

  background-color: ${(props) => (props.checked ? '#0087ed' : '#fff')};

  &:hover {
    cursor: pointer;
  }
`;

export const AuthSizeInputLabel = styled.label`
  flex: 2.5;
  text-align: center;
`;

export const AuthSizeInput = styled.input`
  width: 50%;
  height: auto;
  margin: 0 auto;
  border: 1px solid #0087ed;

  &:hover {
    cursor: pointer;
  }
`;

export const AuthListButtonLabel = styled.label`
  flex: 1;
  text-align: center;
  vertical-align: middle;
  font-size: 17px;
  color: white;
`;

export const AuthListAddButton = styled.div`
  width: 70%;
  height: 18px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #0087ed;

  &:hover {
    cursor: pointer;
  }
`;

export const AuthListDeleteButton = styled.div`
  width: 70%;
  height: 18px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #ed5500;

  &:hover {
    cursor: pointer;
  }
`;

export const ResultContentArea = styled.div`
  padding: 20px 10px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
`;

export const ResultContent = styled.div`
  border: 1px solid #7d7d7d;
  border-radius: 10px;
  padding: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ResultContentItem = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  align-items: center;
`;

export const ResultContentItemId = styled.div``;

export const ResultContentItemPw = styled.div``;

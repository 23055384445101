import React, { useRef } from 'react';

import * as s from './PlanModalStyled';
import { SetterOrUpdater } from 'recoil';
import ReactToPrint from 'react-to-print';

interface PlanModalProps {
  plan: any;
  planId: number | null;
  programId: number | null;
  setProgramId: SetterOrUpdater<number | null>;
  cancel: () => void;
  onClickProgram: (material: any) => void;
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}

const PlanModalPresenter = (props: PlanModalProps) => {
  const componentRef = useRef<any>();
  return props.programId === null && props.planId !== null && props.plan !== null ? (
    <s.Container>
      <s.ModalContainer ref={componentRef}>
        <s.ModalHeaderArea>
          <s.ModalHeaderMonth>교육계획안</s.ModalHeaderMonth>

          <ReactToPrint
            trigger={() => <s.ModalHeaderPrintButton>인쇄하기</s.ModalHeaderPrintButton>}
            content={() => componentRef.current}
          />
        </s.ModalHeaderArea>
        <s.ModalTypeArea>
          {props.plan.materialList.map((material: any) => {
            return (
              <s.ModalType onClick={() => props.onClickProgram(material)}>
                <s.ModalTypeImg src={`/static/img/${material.icon.comnCd}.png`} />
                <s.ModalTypeText>{material.icon.comnCdNm}</s.ModalTypeText>
              </s.ModalType>
            );
          })}
        </s.ModalTypeArea>
        <s.ModalMaterialArea>
          <s.ModalMaterialTitleArea>
            <s.ModalMaterialTitleImg src="/static/img/papers.png" />
            <s.ModalMaterialTitleTextArea>
              <s.ModalMaterialTitleText>놀이자료</s.ModalMaterialTitleText>
              <s.ModalMaterialTitleSubText>
                총 {props.plan.materialList.length}개
              </s.ModalMaterialTitleSubText>
            </s.ModalMaterialTitleTextArea>
            <s.ModalMaterialElementArea>
              {props.plan.materialList &&
                props.plan.materialList.length > 0 &&
                props.plan.materialList.map((material: any) => {
                  if (material.program != null) {
                    return (
                      <s.ModalMaterialElement onClick={() => props.onClickProgram(material)}>
                        {material.program &&
                        material.program.thumbnail &&
                        material.program.thumbnail.fileUrl ? (
                          <s.ModalMaterialElementImg
                            src={
                              material.program.thumbnail.fileUrl
                                ? material.program.thumbnail.fileUrl
                                : '/static/img/background.png'
                            }
                            onError={(e) => {
                              e.currentTarget.src = '/static/img/background.png';
                            }}
                          />
                        ) : (
                          <s.ModalMaterialElementImg src={'/static/img/background.png'} />
                        )}
                        {/* <s.ModalMaterialElementText>
                          {material.program.title}
                        </s.ModalMaterialElementText> */}
                      </s.ModalMaterialElement>
                    );
                  }
                  return (
                    <s.ModalMaterialElement onClick={() => props.onClickProgram(material)}>
                      <s.ModalMaterialElementImg
                        src={
                          material.file?.fileUrl
                            ? material.file?.fileUrl
                            : '/static/img/background.png'
                        }
                        onError={(e) => {
                          e.currentTarget.src = '/static/img/background.png';
                        }}
                      />
                      <s.ModalMaterialElementText>
                        {material.file?.originalFileName}
                      </s.ModalMaterialElementText>
                    </s.ModalMaterialElement>
                  );
                })}
            </s.ModalMaterialElementArea>
          </s.ModalMaterialTitleArea>
        </s.ModalMaterialArea>
        <s.ModalContextTable>
          <s.ModalContextTr>
            <s.ModalContextTh>활동명</s.ModalContextTh>
            <s.ModalContextTd colSpan={3}>{props.plan.activityNm}</s.ModalContextTd>
          </s.ModalContextTr>
          <s.ModalContextTr>
            <s.ModalContextTh>영역</s.ModalContextTh>
            <s.ModalContextTd width={'45%'}>{props.plan.domain.comnCdNm}</s.ModalContextTd>
            <s.ModalContextTh>활동자료</s.ModalContextTh>
            <s.ModalContextTd></s.ModalContextTd>
          </s.ModalContextTr>
          <s.ModalContextTr>
            <s.ModalContextTh>주제</s.ModalContextTh>
            <s.ModalContextTd width={'45%'}>{props.plan.topic}</s.ModalContextTd>
            <s.ModalContextTh>소주제</s.ModalContextTh>
            <s.ModalContextTd>{props.plan.subTopic}</s.ModalContextTd>
          </s.ModalContextTr>
          <s.ModalContextTr>
            <s.ModalContextTh>교사의기대</s.ModalContextTh>
            <s.ModalContextTd colSpan={3}>
              {props.plan.expectationList.map((item: any, index: number) => (
                <s.ModalContextTdText>
                  {'• '}
                  {item.content}
                </s.ModalContextTdText>
              ))}
            </s.ModalContextTd>
          </s.ModalContextTr>
          <s.ModalContextTr>
            <s.ModalContextTh>표준보육과정내용범주</s.ModalContextTh>
            <s.ModalContextTd colSpan={3}>
              {props.plan.factorList.map((item: any, index: number) => (
                <s.ModalContextTdText>
                  {'• '}
                  {item.content}
                </s.ModalContextTdText>
              ))}
            </s.ModalContextTd>
          </s.ModalContextTr>
          <s.ModalContextTr>
            <s.ModalContextTh>활동방법</s.ModalContextTh>
            <s.ModalContextTd width={'45%'}>
              {props.plan.methodList.map((item: any, index: number) => (
                <>
                  <s.ModalContextTdText>{item.content}</s.ModalContextTdText>
                  {item.subMethodList &&
                    item.subMethodList.map((subItem: any, index2: number) => (
                      <s.ModalContextTdText>{'- ' + subItem.content}</s.ModalContextTdText>
                    ))}
                </>
              ))}
            </s.ModalContextTd>
            <s.ModalContextTh>유의점</s.ModalContextTh>
            <s.ModalContextTd>{props.plan.note}</s.ModalContextTd>
          </s.ModalContextTr>
          <s.ModalContextTr>
            <s.ModalContextTh>확장활동</s.ModalContextTh>
            <s.ModalContextTd colSpan={3}>
              <s.TdTextArea>{props.plan.extension}</s.TdTextArea>
            </s.ModalContextTd>
          </s.ModalContextTr>
          <s.ModalContextTr style={{ borderBottom: 0 }}>
            <s.ModalContextTh>놀이평가</s.ModalContextTh>
            <s.ModalContextTd colSpan={3}>
              <s.TdTextArea>{props.plan.playEvaluation}</s.TdTextArea>
            </s.ModalContextTd>
          </s.ModalContextTr>
        </s.ModalContextTable>

        {/* <s.ModalFeatureArea>
          <s.ModalFeatureAreaRow>
            <s.ModalFeatureTitle>활동명</s.ModalFeatureTitle>
            <s.ModalFeatureContent>{props.plan.activityNm}</s.ModalFeatureContent>
          </s.ModalFeatureAreaRow>
          <s.ModalFeatureAreaRow>
            <s.ModalFeatureTitle>영역</s.ModalFeatureTitle>
            <s.ModalFeatureContent>{props.plan.domain.comnCdNm}</s.ModalFeatureContent>
          </s.ModalFeatureAreaRow>
          <s.ModalFeatureAreaRow>
            <s.ModalFeatureTitle>활동자료</s.ModalFeatureTitle>
            <s.ModalFeatureContent>활동자료를 입력해주세요.</s.ModalFeatureContent>
          </s.ModalFeatureAreaRow>
          <s.ModalFeatureAreaRow>
            <s.ModalFeatureTitle>주제</s.ModalFeatureTitle>
            <s.ModalFeatureContent>{props.plan.topic}</s.ModalFeatureContent>
          </s.ModalFeatureAreaRow>
          <s.ModalFeatureAreaRowLast>
            <s.ModalFeatureTitle>소주제</s.ModalFeatureTitle>
            <s.ModalFeatureContent>{props.plan.subTopic}</s.ModalFeatureContent>
          </s.ModalFeatureAreaRowLast>
        </s.ModalFeatureArea>
        <s.ModalTabArea
          color={
            props.currentTab === 'expectation'
              ? '#03DDA0'
              : props.currentTab === 'factor'
              ? '#A78FFD'
              : props.currentTab === 'method'
              ? '#FF7816'
              : props.currentTab === 'note'
              ? '#FF6643'
              : props.currentTab === 'extension'
              ? '#FE8278'
              : ''
          }
        >
          <s.ModalTab
            onClick={() => props.setCurrentTab('expectation')}
            current={props.currentTab === 'expectation'}
            width={22}
            color={'#03DDA0'}
          >
            교사의 기대
          </s.ModalTab>
          <s.ModalTab
            onClick={() => props.setCurrentTab('factor')}
            current={props.currentTab === 'factor'}
            width={28}
            color={'#A78FFD'}
          >
            누리과정 관련요소
          </s.ModalTab>
          <s.ModalTab
            onClick={() => props.setCurrentTab('method')}
            current={props.currentTab === 'method'}
            width={19}
            color={'#FF7816'}
          >
            활동 방법
          </s.ModalTab>
          <s.ModalTab
            onClick={() => props.setCurrentTab('note')}
            current={props.currentTab === 'note'}
            width={15}
            color={'#FF6643'}
          >
            유의점
          </s.ModalTab>
          <s.ModalTab
            onClick={() => props.setCurrentTab('extension')}
            current={props.currentTab === 'extension'}
            width={19}
            color={'#FE8278'}
          >
            확장활동
          </s.ModalTab>
        </s.ModalTabArea>
        {props.currentTab === 'expectation' ? (
          <s.ModalContenArea color={'#03DDA0'}>
            {props.plan.expectationList.map((item: any, index: number) => (
              <s.ModalExpectationContentArea>
                <s.ModalDecorationDot />
                <s.ModalExpectationContent>{item.content}</s.ModalExpectationContent>
              </s.ModalExpectationContentArea>
            ))}
          </s.ModalContenArea>
        ) : props.currentTab === 'factor' ? (
          <s.ModalContenArea color={'#A78FFD'}>
            {props.plan.factorList.map((item: any, index: number) => (
              <s.ModalFactorContentArea>
                <s.ModalDecorationDot />
                <s.ModalFactorContent>{item.content}</s.ModalFactorContent>
              </s.ModalFactorContentArea>
            ))}
          </s.ModalContenArea>
        ) : props.currentTab === 'method' ? (
          <s.ModalContenArea color={'#FE8278'}>
            {props.plan.methodList.map((item: any, index: number) => (
              <s.ModalMethodContentArea>
                <s.ModalDecorationDot />
                <s.ModalMethodContent>
                  {item.content}
                  {item.subMethodList.map((subItem: any, index2: number) => (
                    <s.ModalMethodSubContent>{'- ' + subItem.content}</s.ModalMethodSubContent>
                  ))}
                </s.ModalMethodContent>
              </s.ModalMethodContentArea>
            ))}
          </s.ModalContenArea>
        ) : props.currentTab === 'note' ? (
          <s.ModalContenArea color={'#FF6643'}>
            <s.ModalEtcContent>{props.plan.note}</s.ModalEtcContent>
          </s.ModalContenArea>
        ) : props.currentTab === 'extension' ? (
          <s.ModalContenArea color={'#FF6643'}>
            <s.ModalEtcContent>{props.plan.extension}</s.ModalEtcContent>
          </s.ModalContenArea>
        ) : null}
        <s.ModalPlayEvaluationArea>
          <s.ModalPlayEvaluationTitleArea>
            <s.ModalPlayEvaluationTitle>놀이평가</s.ModalPlayEvaluationTitle>
          </s.ModalPlayEvaluationTitleArea>
          <s.ModalPlayEvaluationContentArea>
            <s.ModalPlayEvaluationContent>{props.plan.playEvaluation}</s.ModalPlayEvaluationContent>
          </s.ModalPlayEvaluationContentArea>
        </s.ModalPlayEvaluationArea> */}

        <s.ModalEtcRow>
          <s.ModalButton onClick={props.cancel}>X</s.ModalButton>
        </s.ModalEtcRow>
      </s.ModalContainer>
    </s.Container>
  ) : null;
};

export default PlanModalPresenter;

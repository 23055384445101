import React, { Dispatch, SetStateAction } from 'react';

import * as util from '../../../util/util';
import * as s from './CreateProductStyled';
import NotFound from '../../../components/notFound';
import { Link } from 'react-router-dom';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { FaBan } from 'react-icons/fa';

interface Props {
  getThumbnailRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getThumbnailInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  thumbnailOpen: () => void;
  thumbnailAcceptedFiles: File[];
  thumbnailIsDragActive: boolean;

  getDetailPageRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getDetailPageInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  detailPageOpen: () => void;
  detailPageAcceptedFiles: File[];
  detailPageIsDragActive: boolean;

  thumbnailFileList: any[];
  thumbnailList: any[];
  setThumbnailList: Dispatch<SetStateAction<any>>;

  detailPageFileList: any[];
  detailPageList: any[];
  setDetailPageList: Dispatch<SetStateAction<any>>;

  submit: () => void;

  productNm: string;
  setProductNm: Dispatch<SetStateAction<string>>;
  productDv: string;
  setProductDv: Dispatch<SetStateAction<string>>;
  price: number;
  setPrice: Dispatch<SetStateAction<number>>;
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  material: string;
  setMaterial: Dispatch<SetStateAction<string>>;
  size: string;
  setSize: Dispatch<SetStateAction<string>>;
  credNumber: string;
  setCredNumber: Dispatch<SetStateAction<string>>;
  openYear: string;
  setOpenYear: Dispatch<SetStateAction<string>>;
  madeIn: string;
  setMadeIn: Dispatch<SetStateAction<string>>;
  bestYn: string;
  setBestYn: Dispatch<SetStateAction<string>>;
  productDetailDv: string;
  setProductDetailDv: Dispatch<SetStateAction<string>>;
  month: string;
  setMonth: Dispatch<SetStateAction<string>>;
  useYn: string;
  setUseYn: Dispatch<SetStateAction<string>>;

  removeThumb: (item: any) => void;
  removeDetailPage: (item: any) => void;
}

const CreateProductPresenter = (props: Props) => {
  return (
    <s.Container>
      <s.Title>제품 기본정보</s.Title>
      <s.InputTable>
        <s.InputTbody>
          <s.InputTr>
            <s.InputTh>썸네일</s.InputTh>
            <s.InputTd colSpan={3}>
              <s.UploadProgramAreaFileUploadArea
                isDrag={props.thumbnailIsDragActive}
                {...props.getThumbnailRootProps({ className: 'dropzone' })}
              >
                <s.UploadProgramAreaFileUploadAreaInput {...props.getThumbnailInputProps()} />
                {props.thumbnailFileList.length > 0 ? (
                  <s.UploadProgramAreaFileUploadAreaButtonIcon
                    onClick={props.thumbnailOpen}
                    src="/static/img/pdf_icon_1.png"
                  />
                ) : null}
                <s.UploadProgramAreaFileUploadAreaText>
                  {props.thumbnailFileList.length > 0
                    ? props.thumbnailFileList.length === 1
                      ? props.thumbnailFileList[0].name
                      : props.thumbnailFileList[0].name +
                        ' 외 ' +
                        (props.thumbnailFileList.length - 1) +
                        '개'
                    : '파일을 선택해주세요.'}
                </s.UploadProgramAreaFileUploadAreaText>
                <s.UploadProgramAreaFileUploadAreaButtonIcon
                  onClick={props.thumbnailOpen}
                  src={
                    props.thumbnailFileList.length > 0
                      ? '/static/img/file_exist.png'
                      : '/static/img/selectFile.png'
                  }
                />
              </s.UploadProgramAreaFileUploadArea>
              {props.thumbnailList.map(
                (item: { fileName: string; fileIndex: number; text: string }, index: number) => (
                  <s.UploadProgramAreaFooterTextArea>
                    <s.TextAreaInfo>
                      <s.TextAreaIntoFileName>{item.fileName}</s.TextAreaIntoFileName>
                      <s.TextAreaIntoText>순서</s.TextAreaIntoText>
                    </s.TextAreaInfo>
                    <s.TextAreaTextInput
                      placeholder="순서를 입력해주세요"
                      value={item.text}
                      pattern="[0-9]*"
                      onChange={(e) => {
                        if (e.target.validity.valid) {
                          let tempList = [...props.thumbnailList];
                          tempList[index].text = e.target.value;
                          props.setThumbnailList(tempList);
                        }
                      }}
                    />
                    <s.RemoveFileButton onClick={() => props.removeThumb(item)}>
                      <FaBan size={'20px'} color={'#FE7878'} />
                    </s.RemoveFileButton>
                  </s.UploadProgramAreaFooterTextArea>
                ),
              )}
            </s.InputTd>
          </s.InputTr>
          <s.InputTr>
            <s.InputTh>상세페이지</s.InputTh>
            <s.InputTd colSpan={3}>
              <s.UploadProgramAreaFileUploadArea
                isDrag={props.detailPageIsDragActive}
                {...props.getDetailPageRootProps({ className: 'dropzone' })}
              >
                <s.UploadProgramAreaFileUploadAreaInput {...props.getDetailPageInputProps()} />
                {props.detailPageFileList.length > 0 ? (
                  <s.UploadProgramAreaFileUploadAreaButtonIcon
                    onClick={props.detailPageOpen}
                    src="/static/img/pdf_icon_1.png"
                  />
                ) : null}
                <s.UploadProgramAreaFileUploadAreaText>
                  {props.detailPageFileList.length > 0
                    ? props.detailPageFileList.length === 1
                      ? props.detailPageFileList[0].name
                      : props.detailPageFileList[0].name +
                        ' 외 ' +
                        (props.detailPageFileList.length - 1) +
                        '개'
                    : '파일을 선택해주세요.'}
                </s.UploadProgramAreaFileUploadAreaText>
                <s.UploadProgramAreaFileUploadAreaButtonIcon
                  onClick={props.detailPageOpen}
                  src={
                    props.detailPageFileList.length > 0
                      ? '/static/img/file_exist.png'
                      : '/static/img/selectFile.png'
                  }
                />
              </s.UploadProgramAreaFileUploadArea>
              {props.detailPageList.map(
                (item: { fileName: string; fileIndex: number; text: string }, index: number) => (
                  <s.UploadProgramAreaFooterTextArea>
                    <s.TextAreaInfo>
                      <s.TextAreaIntoFileName>{item.fileName}</s.TextAreaIntoFileName>
                      <s.TextAreaIntoText>순서</s.TextAreaIntoText>
                    </s.TextAreaInfo>
                    <s.TextAreaTextInput
                      placeholder="순서를 입력해주세요"
                      value={item.text}
                      pattern="[0-9]*"
                      onChange={(e) => {
                        if (e.target.validity.valid) {
                          let tempList = [...props.detailPageList];
                          tempList[index].text = e.target.value;
                          props.setDetailPageList(tempList);
                        }
                      }}
                    />
                    <s.RemoveFileButton onClick={() => props.removeDetailPage(item)}>
                      <FaBan size={'20px'} color={'#FE7878'} />
                    </s.RemoveFileButton>
                  </s.UploadProgramAreaFooterTextArea>
                ),
              )}
            </s.InputTd>
          </s.InputTr>
          <s.InputTr>
            <s.InputTh>제품명</s.InputTh>
            <s.InputTd colSpan={3}>
              <s.InputInput
                placeholder="ex) 스몽 2호"
                type="text"
                style={{ width: '32%' }}
                value={props.productNm}
                onChange={(e) => {
                  props.setProductNm(e.target.value);
                }}
              ></s.InputInput>
            </s.InputTd>
          </s.InputTr>
          <s.InputTr>
            <s.InputTh>제품구분</s.InputTh>
            <s.InputTd>
              <s.InputSelect
                value={props.productDv}
                onChange={(e) => {
                  props.setProductDv(e.target.value);
                }}
              >
                <option value="0">선택</option>
                <option value="1">영역별교구</option>
                <option value="2">만들기</option>
                <option value="3">환경구성</option>
                <option value="4">시설교구/사무기기</option>
                <option value="5">교재/도서</option>
              </s.InputSelect>
            </s.InputTd>
            <s.InputTh>제품상세구분</s.InputTh>
            <s.InputTd>
              <s.InputSelect
                value={props.productDetailDv}
                onChange={(e) => {
                  props.setProductDetailDv(e.target.value);
                }}
              >
                <option value="0">선택</option>
                {props.productDv === '1' ? (
                  <>
                    <option value="1">신체놀이</option>
                    <option value="2">쌓기/블록놀이</option>
                    <option value="3">역할놀이</option>
                    <option value="4">언어놀이</option>
                    <option value="5">과학놀이</option>
                    <option value="6">수/조작놀이</option>
                    <option value="7">미술놀이</option>
                    <option value="8">음률놀이</option>
                  </>
                ) : props.productDv === '2' ? (
                  <>
                    <option value="1">우리원/입학/졸업</option>
                    <option value="2">봄/여름</option>
                    <option value="3">가족/어버이날/스승의날</option>
                    <option value="4">우리동네/직업/교통기관</option>
                    <option value="5">우리나라/환경과생활</option>
                    <option value="6">가을/생활도구</option>
                    <option value="7">우리나라/세계여러나라</option>
                    <option value="8">겨울/성탄절</option>
                  </>
                ) : props.productDv === '3' ? (
                  <>
                    <option value="1">환경판/날짜판</option>
                    <option value="2">출석판/투약함</option>
                    <option value="3">오늘의 활동</option>
                    <option value="4">위생/안전</option>
                    <option value="5">스티커</option>
                    <option value="6">역할놀이/현수막</option>
                    <option value="7">기타</option>
                  </>
                ) : props.productDv === '4' ? (
                  <>
                    <option value="1">교구장/사물함</option>
                    <option value="2">정리함</option>
                    <option value="3">책상/의자</option>
                    <option value="4">주방놀이/소꿉놀이</option>
                    <option value="5">칼라보드/칠판</option>
                    <option value="6">환경판/게시판</option>
                    <option value="7">사무기기</option>
                    <option value="8">생활가전/생활용품</option>
                  </>
                ) : props.productDv === '5' ? (
                  <>
                    <option value="1">엄지교육</option>
                    <option value="2">도서</option>
                    <option value="3">독서/수학교재</option>
                    <option value="4">블럭교재</option>
                    <option value="5">미술교재</option>
                    <option value="6">과학교재</option>
                  </>
                ) : null}
              </s.InputSelect>
            </s.InputTd>
          </s.InputTr>
          <s.InputTr>
            <s.InputTh>가격</s.InputTh>
            <s.InputTd>
              <s.InputInput
                value={util.priceToString(props.price)}
                onChange={(e) => {
                  e.target.validity.valid
                    ? props.setPrice(Number(e.target.value.replaceAll(',', '')))
                    : console.log();
                }}
                type="text"
                pattern="[0-9,]*"
                placeholder="ex) 30,000"
              ></s.InputInput>{' '}
              원
            </s.InputTd>
            <s.InputTh>월별특색여부</s.InputTh>
            <s.InputTd>
              <s.InputSelect
                value={props.month}
                onChange={(e) => {
                  props.setMonth(e.target.value);
                }}
              >
                <option value="0">선택</option>
                <option value="3">3월</option>
                <option value="4">4월</option>
                <option value="5">5월</option>
                <option value="6">6월</option>
                <option value="7">7월</option>
                <option value="8">8월</option>
                <option value="9">9월</option>
                <option value="10">10월</option>
                <option value="11">11월</option>
                <option value="12">12월</option>
                <option value="1">1월</option>
                <option value="2">2월</option>
              </s.InputSelect>
            </s.InputTd>
          </s.InputTr>
          <s.InputTr>
            <s.InputTh>베스트상품여부</s.InputTh>
            <s.InputTd>
              <s.InputSelect
                value={props.bestYn}
                onChange={(e) => {
                  props.setBestYn(e.target.value);
                }}
              >
                <option value="N">아니오</option>
                <option value="Y">예</option>
              </s.InputSelect>
            </s.InputTd>
            <s.InputTh>품절여부</s.InputTh>
            <s.InputTd>
              <s.InputSelect
                value={props.useYn}
                onChange={(e) => {
                  props.setUseYn(e.target.value);
                }}
              >
                <option value="N">예</option>
                <option value="Y">아니오</option>
              </s.InputSelect>
            </s.InputTd>
          </s.InputTr>
        </s.InputTbody>
      </s.InputTable>

      <s.Title>제품 상세정보</s.Title>
      <s.InputTable>
        <s.InputTbody>
          <s.InputTr>
            <s.InputTh>제품유형</s.InputTh>
            <s.InputTd>
              <s.InputInput
                value={props.type}
                onChange={(e) => {
                  props.setType(e.target.value);
                }}
                type="text"
                placeholder="ex) 장난감"
              ></s.InputInput>
            </s.InputTd>
            <s.InputTh>재질</s.InputTh>
            <s.InputTd>
              <s.InputInput
                value={props.material}
                onChange={(e) => {
                  props.setMaterial(e.target.value);
                }}
                type="text"
                placeholder="ex) 원목"
              ></s.InputInput>
            </s.InputTd>
          </s.InputTr>
          <s.InputTr>
            <s.InputTh>크기</s.InputTh>
            <s.InputTd>
              <s.InputInput
                value={props.size}
                onChange={(e) => {
                  props.setSize(e.target.value);
                }}
                type="text"
                placeholder="ex) 350mm * 500mm * 600mm"
              ></s.InputInput>
            </s.InputTd>
            <s.InputTh>인증번호</s.InputTh>
            <s.InputTd>
              <s.InputInput
                value={props.credNumber}
                onChange={(e) => {
                  props.setCredNumber(e.target.value);
                }}
                type="text"
                placeholder="ex) CB061R699-6002A"
              ></s.InputInput>
            </s.InputTd>
          </s.InputTr>
          <s.InputTr>
            <s.InputTh>생산연월</s.InputTh>
            <s.InputTd>
              <s.InputInput
                value={props.openYear}
                onChange={(e) => {
                  props.setOpenYear(e.target.value);
                }}
                type="text"
                placeholder="ex) 2022년 06월"
              ></s.InputInput>
            </s.InputTd>
            <s.InputTh>제조국가</s.InputTh>
            <s.InputTd>
              <s.InputInput
                value={props.madeIn}
                onChange={(e) => {
                  props.setMadeIn(e.target.value);
                }}
                type="text"
                placeholder="ex) 중국"
              ></s.InputInput>
            </s.InputTd>
          </s.InputTr>
        </s.InputTbody>
      </s.InputTable>
      <s.CreateButton onClick={props.submit}>등록하기</s.CreateButton>
    </s.Container>
  );
};

export default CreateProductPresenter;

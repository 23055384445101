import { domain } from 'process';
import React, { useState } from 'react';

import * as s from './DomainModalStyled';
import * as util from './../../../../util/util';

interface DomainModalPresenterProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  domain: string;
  setDomain: React.Dispatch<React.SetStateAction<string>>;
  domainNm: string;
  setDomainNm: React.Dispatch<React.SetStateAction<string>>;
  domainList: any;
}

const DomainModalPresenter = (props: DomainModalPresenterProps) => {
  const [selectedDomain, setSelectedDomain] = useState('');

  const confirmDomainSelect = () => {
    if (selectedDomain === '') {
      util.makeMsg('영역을 선택해주세요.', 'error');
    } else {
      props.setDomain(selectedDomain);
      props.domainList.map(function (item: any, index: number) {
        if (item.comnCd === selectedDomain) {
          props.setDomainNm(item.comnCdNm);
          return true;
        }

        props.setIsModalOpen(false);
        return true;
      });
    }
  };

  const cancelDomainSelect = () => {
    setSelectedDomain('');
    props.setIsModalOpen(false);
  };

  return (
    <s.Container>
      <s.TitleWrapper>도메인 입력</s.TitleWrapper>
      <s.ContentWrapper>
        <s.EachContentWrapper></s.EachContentWrapper>
        <s.ResultWrapper>
          {props.domainList.map((item: any) => {
            return (
              <s.ResultTopicWrapper
                key={item.comnCd}
                onClick={() => setSelectedDomain(item.comnCd)}
                isSelect={selectedDomain === item.comnCd}
              >
                <s.ResultItemInfoNm>{item.comnCdNm}</s.ResultItemInfoNm>
              </s.ResultTopicWrapper>
            );
          })}
        </s.ResultWrapper>
        <s.BigButtonWrapper>
          <s.BigButton isCloseButton={false} onClick={confirmDomainSelect}>
            <s.BigButtonText>확인</s.BigButtonText>
          </s.BigButton>
          <s.BigButton isCloseButton={true} onClick={cancelDomainSelect}>
            <s.BigButtonText>닫기</s.BigButtonText>
          </s.BigButton>
        </s.BigButtonWrapper>
      </s.ContentWrapper>
    </s.Container>
  );
};

export default DomainModalPresenter;

import React, { useState, useEffect } from 'react';
import * as s from './HeaderStyled';
import { FaAngleDown, FaSearch } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as util from '../../util/util';
import * as recoilItem from '../../util/recoilitem';
import { useRecoilState } from 'recoil';

import { menuApi } from '../../api/api-menu';
import { SSL_OP_ALL } from 'constants';

interface HeaderProps extends RouteComponentProps {}

const HeaderPresenter = (props: HeaderProps) => {
  const [contentVisible, setContentVisible] = useState(false);

  // const [content1Visible, setContent1Visible] = useState(false);
  // const [content2Visible, setContent2Visible] = useState(false);
  // const [content3Visible, setContent3Visible] = useState(false);

  const [menus, setMenus] = useState<any[]>([]);

  const [token, setToken] = useRecoilState(recoilItem.token);
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);

  const notSupport = () => {
    Swal.fire('추후 지원할 예정입니다.');
  };

  const logout = () => {
    setToken({
      accessToken: '',
      refreshToken: '',
    });
    setUserInfo({});
    util.makeMsg('로그아웃 되었습니다.', 'etc');
    props.history.push('/');
  };

  const register = () => {
    props.history.push('/registerSelect');
  };

  const toMyPage = () => {
    props.history.push('/mypage');
  };

  const fetchData = async () => {
    try {
      await menuApi.getCd('MN').then(async (res) => {
        if (res.data.rsltCd === '00') {
          let menuList: any[] = [];
          await Promise.all(
            res.data.ComnCd.subMenuList.map(async (item: any) => {
              let subRes = await menuApi.getCd(item.menuCd);
              if (subRes.data.rsltCd === '00') {
                if (item.menuCd === 'MN07') {
                  let tempList = subRes.data.ComnCd.subMenuList.filter(
                    (item: any) =>
                    item.menuCd !== 'MN0702'
                  );
                  subRes.data.ComnCd.subMenuList = tempList;
                }
                if (item.menuCd === 'MN02') {
                  let tempList = subRes.data.ComnCd.subMenuList.filter(
                    (item: any) =>
                      item.menuCd !== 'MN0209' &&
                      item.menuCd !== 'MN0201' &&
                      item.menuCd !== 'MN0202' &&
                      item.menuCd !== 'MN0213' &&
                      item.menuCd !== 'MN0214',
                  );
                  subRes.data.ComnCd.subMenuList = tempList;
                }
                if (item.menuCd === 'MN03') {
                  let tempList = [
                    {
                      menuCd:
                        subRes.data.ComnCd.subMenuList[0].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[1].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[2].menuCd,
                      menuNm:
                        subRes.data.ComnCd.subMenuList[0].menuNm.slice(0, -2) +
                        ' / ' +
                        subRes.data.ComnCd.subMenuList[1].menuNm.slice(0, -2) +
                        ' / ' +
                        subRes.data.ComnCd.subMenuList[2].menuNm.slice(0, -2),
                      viewOrder: 1,
                      link:
                        '/activity?field=' +
                        subRes.data.ComnCd.subMenuList[0].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[1].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[2].menuCd,
                    },
                    {
                      menuCd:
                        subRes.data.ComnCd.subMenuList[3].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[4].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[5].menuCd,
                      menuNm:
                        subRes.data.ComnCd.subMenuList[3].menuNm.slice(0, -2) +
                        ' / ' +
                        subRes.data.ComnCd.subMenuList[4].menuNm.slice(0, -2) +
                        ' / ' +
                        subRes.data.ComnCd.subMenuList[5].menuNm.slice(0, -2),
                      viewOrder: 2,
                      link:
                        '/activity?field=' +
                        subRes.data.ComnCd.subMenuList[3].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[4].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[5].menuCd,
                    },
                    {
                      menuCd:
                        subRes.data.ComnCd.subMenuList[6].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[7].menuCd,
                      menuNm:
                        subRes.data.ComnCd.subMenuList[6].menuNm.slice(0, -2) +
                        ' / ' +
                        subRes.data.ComnCd.subMenuList[7].menuNm.slice(0, -2),
                      viewOrder: 3,
                      link:
                        '/activity?field=' +
                        subRes.data.ComnCd.subMenuList[6].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[7].menuCd,
                    },
                    {
                      menuCd: subRes.data.ComnCd.subMenuList[8].menuCd,
                      menuNm: subRes.data.ComnCd.subMenuList[8].menuNm,
                      viewOrder: 4,
                      link: subRes.data.ComnCd.subMenuList[8].link,
                    },
                    {
                      menuCd: subRes.data.ComnCd.subMenuList[9].menuCd,
                      menuNm: subRes.data.ComnCd.subMenuList[9].menuNm,
                      viewOrder: 5,
                      link: subRes.data.ComnCd.subMenuList[9].link,
                    },
                    {
                      menuCd: subRes.data.ComnCd.subMenuList[10].menuCd,
                      menuNm: subRes.data.ComnCd.subMenuList[10].menuNm,
                      viewOrder: 6,
                      link: subRes.data.ComnCd.subMenuList[10].link,
                    },
                    {
                      menuCd:
                        subRes.data.ComnCd.subMenuList[11].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[12].menuCd,
                      menuNm:
                        subRes.data.ComnCd.subMenuList[11].menuNm +
                        ' / ' +
                        subRes.data.ComnCd.subMenuList[12].menuNm.slice(0, -2),
                      viewOrder: 7,
                      link:
                        '/activity?field=' +
                        subRes.data.ComnCd.subMenuList[11].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[12].menuCd,
                    },
                    {
                      menuCd:
                        subRes.data.ComnCd.subMenuList[13].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[14].menuCd,
                      menuNm:
                        subRes.data.ComnCd.subMenuList[13].menuNm +
                        ' / ' +
                        subRes.data.ComnCd.subMenuList[14].menuNm,
                      viewOrder: 8,
                      link:
                        '/activity?field=' +
                        subRes.data.ComnCd.subMenuList[13].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[14].menuCd,
                    },
                    {
                      menuCd: subRes.data.ComnCd.subMenuList[15].menuCd,
                      menuNm: subRes.data.ComnCd.subMenuList[15].menuNm,
                      viewOrder: 9,
                      link: subRes.data.ComnCd.subMenuList[15].link,
                    },
                    {
                      menuCd: subRes.data.ComnCd.subMenuList[17].menuCd,
                      menuNm: subRes.data.ComnCd.subMenuList[17].menuNm,
                      viewOrder: 10,
                      link: subRes.data.ComnCd.subMenuList[17].link,
                    },
                    {
                      menuCd: subRes.data.ComnCd.subMenuList[16].menuCd,
                      menuNm: subRes.data.ComnCd.subMenuList[16].menuNm,
                      viewOrder: 11,
                      link: subRes.data.ComnCd.subMenuList[16].link,
                    },
                    {
                      menuCd: subRes.data.ComnCd.subMenuList[23].menuCd,
                      menuNm: subRes.data.ComnCd.subMenuList[23].menuNm,
                      viewOrder: 12,
                      link: subRes.data.ComnCd.subMenuList[23].link,
                    },
                    {
                      menuCd: subRes.data.ComnCd.subMenuList[18].menuCd,
                      menuNm: subRes.data.ComnCd.subMenuList[18].menuNm,
                      viewOrder: 13,
                      link: subRes.data.ComnCd.subMenuList[18].link,
                    },
                    // {
                    //   menuCd: subRes.data.ComnCd.subMenuList[19].menuCd,
                    //   menuNm: subRes.data.ComnCd.subMenuList[19].menuNm + 'asdfasdf',
                    //   viewOrder: 13,
                    //   link: subRes.data.ComnCd.subMenuList[19].link,
                    // },
                    {
                      menuCd:
                        subRes.data.ComnCd.subMenuList[20].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[21].menuCd,
                      menuNm:
                        subRes.data.ComnCd.subMenuList[20].menuNm.slice(0, -2) +
                        ' / ' +
                        subRes.data.ComnCd.subMenuList[21].menuNm.slice(0, -2),
                      viewOrder: 14,
                      link:
                        '/activity?field=' +
                        subRes.data.ComnCd.subMenuList[20].menuCd +
                        ',' +
                        subRes.data.ComnCd.subMenuList[21].menuCd,
                    },
                  ];
                  subRes.data.ComnCd.subMenuList = tempList;
                }
                menuList.push(subRes.data.ComnCd);
              }
            }),
          );
          setMenus(menuList.sort((a, b) => a.viewOrder - b.viewOrder));
        } else {
          util.makeMsg(res.data.rsltMsg, 'error');
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <s.Container>
      <s.HeaderContentArea>
        <s.SectionHigh>
          <s.HeaderTopBtnArea>
            {util.tokenCheck(token) && userInfo.userTypeCd === 'MASTER' ? (
              <>
                <s.HeaderTopBtn onClick={() => logout()}>로그아웃</s.HeaderTopBtn>
                <s.HeaderTopBtn style={{ cursor: 'default', fontSize: '15px' }}>|</s.HeaderTopBtn>
                <s.HeaderTopBtn onClick={() => register()}>관리자</s.HeaderTopBtn>
              </>
            ) : util.tokenCheck(token) &&
              (userInfo.userTypeCd === 'ADMIN' ||
                userInfo.userTypeCd === 'USER' ||
                userInfo.userTypeCd === 'NAIVUSER') ? (
              <>
                <s.HeaderTopBtn onClick={() => logout()}>로그아웃</s.HeaderTopBtn>
                <s.HeaderTopBtn style={{ cursor: 'default', fontSize: '15px' }}>|</s.HeaderTopBtn>
                <s.HeaderTopBtn onClick={() => toMyPage()}>마이페이지</s.HeaderTopBtn>
              </>
            ) : (
              <>
                <Link to="/login">
                  <s.HeaderTopBtn>로그인</s.HeaderTopBtn>
                </Link>
                <div>
                  <s.HeaderTopBtn style={{ cursor: 'default', fontSize: '15px' }}>|</s.HeaderTopBtn>
                </div>
                <Link to="/signUp">
                  <s.HeaderTopBtn>회원가입</s.HeaderTopBtn>
                </Link>
              </>
            )}
          </s.HeaderTopBtnArea>
        </s.SectionHigh>
        <s.SectionLow>
          <Link to="/">
            <s.LogoImg alt="Logo" src="/static/img/logo.png" />
          </Link>
          <s.SearchBar>
            <s.SearchSelect onClick={notSupport}>통합검색</s.SearchSelect>
            <s.SearchSelectIcon onClick={notSupport}>
              <FaAngleDown size={20} />
            </s.SearchSelectIcon>
            <s.SearchTextArea>
              <s.SearchTextInput placeholder={'검색어를 입력하세요.'} />
            </s.SearchTextArea>
            <s.SearchIcon onClick={notSupport}>
              <FaSearch color={'#FFF'} size={20} />
            </s.SearchIcon>
          </s.SearchBar>
          <s.SectionLowTextSection>
            <s.SectionLowTextSectionHigh>인기 검색어</s.SectionLowTextSectionHigh>
            <s.SectionLowTextSectionLow>
              <Link to="/">
                <s.SectionLowTextSectionLowLinks>안전교육</s.SectionLowTextSectionLowLinks>
              </Link>
              <s.SectionLowTextSectionLowLinks>|</s.SectionLowTextSectionLowLinks>
              <Link to="/">
                <s.SectionLowTextSectionLowLinks>겨울</s.SectionLowTextSectionLowLinks>
              </Link>
              <s.SectionLowTextSectionLowLinks>|</s.SectionLowTextSectionLowLinks>
              <Link to="/">
                <s.SectionLowTextSectionLowLinks>동물</s.SectionLowTextSectionLowLinks>
              </Link>
              <s.SectionLowTextSectionLowLinks>|</s.SectionLowTextSectionLowLinks>
              <Link to="/">
                <s.SectionLowTextSectionLowLinks>크리스마스</s.SectionLowTextSectionLowLinks>
              </Link>
            </s.SectionLowTextSectionLow>
          </s.SectionLowTextSection>
        </s.SectionLow>
      </s.HeaderContentArea>
      <s.HeaderNavArea
        onMouseEnter={() => setContentVisible(true)}
        onMouseLeave={() => setContentVisible(false)}
      >
        <s.HeaderNavBar>
          {menus && menus.length > 0
            ? menus.map((item, idx) => (
                <s.HeaderNavBarItem length={menus.length} onClick={() => setContentVisible(false)}>
                  <s.HeaderNavBarItemText
                    onClick={() => (item.link ? props.history.push(item.link) : notSupport())}
                  >
                    {item.menuNm}
                  </s.HeaderNavBarItemText>

                  <s.HeaderHoverContentArea visible={contentVisible}>
                    <s.HeaderHoverContent>
                      <s.HeaderHoverContentRow key={'sub' + item.menuCd}>
                        {item && item.subMenuList && item.subMenuList.length > 0
                          ? item.subMenuList.map((subMenu: any, index: number) => (
                              <Link key={subMenu.menuCd} to={subMenu.link ? subMenu.link : ''}>
                                <s.HeaderHoverContentCol
                                  onClick={() =>
                                    subMenu.link ? setContentVisible(false) : notSupport()
                                  }
                                >
                                  {subMenu.menuNm}
                                </s.HeaderHoverContentCol>
                              </Link>
                            ))
                          : null}
                      </s.HeaderHoverContentRow>
                    </s.HeaderHoverContent>
                  </s.HeaderHoverContentArea>
                </s.HeaderNavBarItem>
              ))
            : null}
        </s.HeaderNavBar>
        {/* <s.HeaderHoverContentArea visible={contentVisible}>
          <s.HeaderHoverContent>
            {menus && menus.length > 0
              ? menus.map((menu: any, idx: number) => (
                  <s.HeaderHoverContentRow key={'sub' + menu.menuCd}>
                    {menu && menu.subMenuList && menu.subMenuList.length > 0
                      ? menu.subMenuList.map((subMenu: any, index: number) => (
                          <Link key={subMenu.menuCd} to={subMenu.link ? subMenu.link : ''}>
                            <s.HeaderHoverContentCol
                              onClick={() =>
                                subMenu.link ? setContentVisible(false) : notSupport()
                              }
                            >
                              {subMenu.menuNm}
                            </s.HeaderHoverContentCol>
                          </Link>
                        ))
                      : null}
                  </s.HeaderHoverContentRow>
                ))
              : null}
          </s.HeaderHoverContent>
        </s.HeaderHoverContentArea> */}
      </s.HeaderNavArea>
    </s.Container>
  );
};

HeaderPresenter.defaultProps = {};

export default HeaderPresenter;

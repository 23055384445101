import React from 'react';
import * as s from './YearlyPlanStyled';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/pagination/pagination.scss';
import * as util from '../../../util/util';
import NotFound from '../../../components/notFound';

import { saveAs } from 'file-saver';
// install Swiper modules

type YearlyPlanProps = {
  notSupport: () => void;
  yearList: number[];
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  ageAuths: any;
  yearlyPlan: any;
  createPlanFile: (planId: string) => Promise<void>;
  createMonthlyPlanFile: (month: string) => Promise<void>;
  createWeeklyPlanFile: (month: string, week: string) => Promise<void>;
  getMonthlyPlanFile: (month: number) => Promise<boolean>;
  getWeeklyPlanFile: (month: number, week: number) => Promise<boolean>;
};

const YearlyPlanPresenter = (props: YearlyPlanProps) => {
  if (!props.yearlyPlan) {
    return (
      <s.Container>
        <NotFound />
      </s.Container>
    );
  }
  return (
    <s.Container>
      <s.PlanningTitleArea>
        <s.PlanningTitle>
          <s.PlanningTitleSpan>{props.option.year}년</s.PlanningTitleSpan> 만{' '}
          <s.PlanningTitleSpan>
            {props.option.age ? props.ageAuths[props.option.age].comnCdNm : null}
          </s.PlanningTitleSpan>{' '}
          연간 교육계획안
        </s.PlanningTitle>
        <s.PlanningAllDownloadBtn
          onClick={() => {
            if (!props.yearlyPlan.planId) {
              util.makeMsg('권한이 부족합니다.', 'error');
            } else {
              if (props.yearlyPlan.file) {
                saveAs(props.yearlyPlan.file.fileUrl, props.yearlyPlan.file.originalFileName);
              } else {
                props.createPlanFile(props.yearlyPlan.planId);
              }
            }
          }}
        >
          <s.DownloadBtnIcon src="/static/img/download_button.png" />
          <s.PlanningAllDownloadText>연간 교육계획안 묶음 다운로드</s.PlanningAllDownloadText>
        </s.PlanningAllDownloadBtn>
      </s.PlanningTitleArea>
      <s.YearlyPlanTable>
        <s.YearlyPlanTableHeader>
          <s.YearlyPlanTableHeaderMonth>월</s.YearlyPlanTableHeaderMonth>
          <s.YearlyPlanTableHeaderSubject>생활주제</s.YearlyPlanTableHeaderSubject>
          <s.YearlyPlanTableHeaderWeek>주차</s.YearlyPlanTableHeaderWeek>
          <s.YearlyPlanTableHeaderDetailSubject>주제</s.YearlyPlanTableHeaderDetailSubject>
          <s.YearlyPlanTableHeaderDownload></s.YearlyPlanTableHeaderDownload>
        </s.YearlyPlanTableHeader>
        {props.yearlyPlan.months.map((monthlyItem: any, index: number) => (
          <>
            <s.YearlyPlanMonthContent key={'monthlyItem' + index}>
              <s.MonthContentMonthArea>
                <s.MonthContentMonth>{monthlyItem.month + '월'}</s.MonthContentMonth>
                <s.MonthContentHolder>{'(' + monthlyItem.num + '호)'}</s.MonthContentHolder>
                <s.MonthContentDownloadLink
                      onClick={() => {
                        if (!props.yearlyPlan.planId) {
                          util.makeMsg('권한이 부족합니다.', 'error');
                        } else {
                          if (!props.getMonthlyPlanFile(monthlyItem.month)) {
                            props.createMonthlyPlanFile(monthlyItem.month);
                          } else {
                            util.makeMsg('존재하지않는 파일입니다.', 'error');
                          }
                        }
                      }}
                    >
                      <s.MonthContentDownloadImg src={'/static/img/download_button.png'} />
                      <s.MonthContentDownloadText>월간 교육 계획안</s.MonthContentDownloadText>
                    </s.MonthContentDownloadLink>
              </s.MonthContentMonthArea>
              <s.MonthContentSubjectArea>
                {monthlyItem.subject.split(',').map((subject: string, index: number) => (
                    <s.MonthContentSubject><s.TextBullet/>{subject}</s.MonthContentSubject>
                ))}
              </s.MonthContentSubjectArea>
              <s.MonthContentWeekArea>
                {monthlyItem.weeks.map((weeklyItem: any, index: number) => (
                  <s.MonthContentWeekContent key={'weeklyItem' + index}>
                    <s.MonthContentWeek>{weeklyItem.week + '주'}</s.MonthContentWeek>
                    <s.MonthContentDetailSubject>
                      {weeklyItem.subject.split('/').map((item: string) => (
                        <div>{item}</div>
                      ))}
                    </s.MonthContentDetailSubject>
                    <s.MonthContentDownloadArea
                      onClick={() => {
                        if (!props.yearlyPlan.planId) {
                          util.makeMsg('권한이 부족합니다.', 'error');
                        } else {
                          if (!props.getWeeklyPlanFile(monthlyItem.month, weeklyItem.week)) {
                            props.createWeeklyPlanFile(monthlyItem.month, weeklyItem.week);
                          } else {
                            util.makeMsg('존재하지않는 파일입니다.', 'error');
                          }
                        }
                      }}
                    >
                      <s.MonthContentDownloadImg src={'/static/img/download_button.png'} />
                      <s.MonthContentDownloadText>주간 교육 계획안</s.MonthContentDownloadText>
                    </s.MonthContentDownloadArea>
                  </s.MonthContentWeekContent>
                ))}
              </s.MonthContentWeekArea>
            </s.YearlyPlanMonthContent>
          </>
        ))}
      </s.YearlyPlanTable>
    </s.Container>
  );
};

YearlyPlanPresenter.defaultProps = {};

export default YearlyPlanPresenter;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1075px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const SignUpHeader = styled.div`
  width: 1091px;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 25px 0 10px;
  vertical-align: middle;
`;

export const SignUpHeaderTitleArea = styled.div`
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SignUpHeaderTitle = styled.div`
  float: left;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const SignUpHeaderTitleDivide = styled.div`
  float: left;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  padding-top: 3px;
  margin: 0 10px;
  color: #e2e2e2;
`;

export const SignUpHeaderRightArea = styled.div`
  float: right;
  padding: 10px 0;
  text-align: center;
  vertical-align: middle;
`;

export const SignUpHeaderRightText = styled.div`
  font-weight: 400;
  color: #4d4d4d;
  vertical-align: middle;
`;

export const SignUpHeaderRightIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 5px;
  vertical-align: middle;
`;

export const ContentsArea = styled.div`
  width: 1091px;
  margin: 20px auto 0px;
`;

export const StepArea = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #707070;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 180px;
`;

export const StepNumberText = styled.div`
  font-size: 28px;
  color: #707070;
  font-family: 'Gosanja';
  margin-right: 10px;
`;

export const StepText = styled.div`
  font-size: 20px;
  color: #707070;
`;

export const SignInFormTable = styled.table`
  border-top: 2px solid #BCBCBC;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const SignInFormTr = styled.tr<{border: number}>`
  border-bottom: ${(props) => props.border}px solid #C8C8C8
`;

export const SignInFormTh = styled.th`
  width: 260px;
  padding: 10px 0;
  text-align: center;
  font-size: 18px;
  color: #4D4D4D;
  background-color: #F1F1F1;
`;

export const SignInFormTd = styled.td`
  width: 831px;
  padding: 10px 10px;
`;

export const SignInFormHorizontalDiv = styled.div`
  display: flex;
  flex-direction: row;
`

export const SignInFormVerticalDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const SignInInputLong = styled.input`
  border: 1px solid #C8C8C8;
  width: 260px;
  padding: 5px;
  font-size: 16px;
  color: #4D4D4D;
  ::-webkit-inner-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
  }
  ::-webkit-outer-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
  }    

`;

export const SignInInputShort = styled.input`
  border: 1px solid #C8C8C8;
  width: 122px;
  padding: 5px;
  font-size: 16px;
  color: #4D4D4D;
`;

export const SignInCheckButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0087ED;
  padding: 5px 25px;
  font-size: 16px;
  color: #0087ED;
  background-color: #FFFFFF;
  margin-left: 15px;
`;

export const SignInToolTip = styled.div<{validType: string}>`
  font-size: 14px;
  margin-top: 5px;
  color: ${(props) => props.validType  === 'Y' ? '#0087ED' : '#DF3131'};
`;

export const SignInputSelect = styled.select`
  width: 122px;
  padding: 5px;
  font-size: 16px;
  color: #4D4D4D;
  border: 1px solid #C8C8C8;
  
`;

export const SignInputSelectOption = styled.option`

`;

export const SignInputText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #4D4D4D;
  margin-right: 10px;
  margin-left: 10px;
`;

export const SignInInputRadioArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const SignInputRadio = styled.input`
  width: 15px;
  padding: 5px;
`;

export const SignInputRadioLabel = styled.div`
  width: 80x;
  padding: 5px;
  font-size: 16px;
  color: #4D4D4D;
`;

export const AgreementArea = styled.div`
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const AgreementCheckbox = styled.input`
  width: 10px;
  height: 10px;
  margin-right: 10px;
`;

export const AgreementText = styled.div`
  font-size: 16px;
  color: #707070;
`;

export const AgreementTextLink = styled(Link)`
  font-size: 16px;
  color: #0087ED;
  text-decoration: none;
`;

export const RegisterButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #FFFFFF;
  background-color: #0087ED;
  width: 122px;
  height: 45px;
  margin: 30px auto 50px;
`;


export const ButtonSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: auto;
`; 

export const ColorButton = styled.div<{color: string, idx: number}>`
  width: 352px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Gosanja';
  font-size: 20px;
  color: #FFFFFF;
  background-color: ${(props) => props.color};
  margin-left: ${(props) => props.idx === 0 ? '0px' : '10px'};
  cursor: pointer;
`;
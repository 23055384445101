import moment from 'moment';
import React, { Dispatch, Props, SetStateAction } from 'react';
import { FaEquals, FaInfoCircle, FaPlus, FaUserCircle } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router-dom';
import { makeMsg, priceToString } from '../../../util/util';
import * as s from './CartStyled';

interface CartProps extends RouteComponentProps {
  userInfo: {
    userKey: any;
    userId: any;
    userNm: any;
    userTypeCd: any;
    useYn: any;
    regDtm: any;
    userEmail: any;
    userMobno: any;
  };
  cartItems: any;
  setCartItems: Dispatch<SetStateAction<any>>;
  toggleAllItems: () => void;
  toggleItem: (id: string) => void;

  updateCount: (productId: any, count: number) => void;
  totalPrices: number;
  deleteSelectedItem: () => void;
  orderSelected: () => void;
  orderAll: () => void;
}

const CartPresenter = (props: CartProps) => {
  return (
    <s.Container>
      <s.TopTableArea>
        <s.TopTable>
          <s.TopTbody>
            <s.TopTr>
              <s.TopTh style={{ width: '5%' }}>
                <s.TopCheckbox
                  type="checkbox"
                  id={'cartAll'}
                  onClick={() => props.toggleAllItems()}
                />
              </s.TopTh>
              <s.TopTh style={{ width: '35%' }}>상품</s.TopTh>
              <s.TopTh style={{ width: '20%' }}>가격</s.TopTh>
              <s.TopTh style={{ width: '20%' }}>수량</s.TopTh>
              <s.TopTh style={{ width: '20%' }}>합계금액</s.TopTh>
            </s.TopTr>
            {props.cartItems.length > 0 ? (
              props.cartItems.map((item: any, index: number) => {
                return (
                  <s.TopTr>
                    <s.TopTd>
                      <s.TopCheckbox
                        onClick={() => props.toggleItem(`cart${index}`)}
                        type="checkbox"
                        name="cartItem"
                        value={item.productId}
                        id={`cart${index}`}
                      />
                    </s.TopTd>
                    <s.TopTd>{item.productNm}</s.TopTd>
                    <s.TopTd>
                      <s.TopTdSpan>{priceToString(item.price)}</s.TopTdSpan>&nbsp;원
                    </s.TopTd>
                    <s.TopTd>
                      <s.CountComponentWrapper>
                        <s.CountComponent>
                          <s.CountDownButton
                            onClick={() => {
                              if (item.count > 1) {
                                props.updateCount(item.productId, item.count - 1);
                              } else {
                                makeMsg('최소 수량은 1개 입니다', 'error');
                              }
                            }}
                          >
                            －
                          </s.CountDownButton>
                          <s.CountItem>{item.count}</s.CountItem>
                          <s.CountUpButton
                            onClick={() => props.updateCount(item.productId, item.count + 1)}
                          >
                            +
                          </s.CountUpButton>
                        </s.CountComponent>
                      </s.CountComponentWrapper>
                    </s.TopTd>
                    <s.TopTd>
                      <s.TopTdSpan style={{ color: '#0091FF' }}>
                        {priceToString(item.totalPrice)}
                      </s.TopTdSpan>
                      &nbsp;원
                    </s.TopTd>
                  </s.TopTr>
                );
              })
            ) : (
              <s.TopTr>
                <s.TopTd colSpan={5}>상품이 없습니다.</s.TopTd>
              </s.TopTr>
            )}
          </s.TopTbody>
        </s.TopTable>
        <s.ButtonWrapper>
          <s.DeleteButton onClick={() => props.deleteSelectedItem()}>선택상품 삭제</s.DeleteButton>
          <s.GoToShoppingButton onClick={() => props.history.push('/shopping/open')}>
            쇼핑 계속하기
          </s.GoToShoppingButton>
        </s.ButtonWrapper>
      </s.TopTableArea>
      <s.TotalPriceBox>
        <s.TotalPriceProduct>
          <s.BoxTitle>상품금액</s.BoxTitle>
          <s.BoxPrice>{priceToString(props.totalPrices)}원</s.BoxPrice>
        </s.TotalPriceProduct>
        <s.TotalPriceDelivery>
          <s.BoxTitle>배송비</s.BoxTitle>
          <s.BoxPrice>{props.totalPrices > 50000 ? 0 : priceToString(3000)}원</s.BoxPrice>
        </s.TotalPriceDelivery>
        <s.TotalPriceSummary>
          <s.BoxTitle>최종 주문 금액</s.BoxTitle>
          <s.BoxPrice style={{ color: '#0091FF' }}>
            {props.totalPrices > 50000
              ? priceToString(props.totalPrices)
              : priceToString(props.totalPrices + 3000)}
            원
          </s.BoxPrice>
        </s.TotalPriceSummary>
        <s.SignCircle style={{ top: '70px', left: '340px' }}>
          <FaPlus size={'50%'} color={'#0091FF'} />
        </s.SignCircle>
        <s.SignCircle style={{ top: '70px', right: '340px' }}>
          <FaEquals size={'50%'} color={'#0091FF'} />
        </s.SignCircle>
      </s.TotalPriceBox>
      <s.OrderButtonWrapper>
        <s.OrderSelected onClick={() => props.orderSelected()}>선택 상품 주문하기</s.OrderSelected>
        <s.OrderAll onClick={() => props.orderAll()}>전체 상품 주문하기</s.OrderAll>
      </s.OrderButtonWrapper>
      <s.InfoArea>
        <s.InfoTitle>
          <FaInfoCircle color={'#4d4d4d'} size={'30px'} /> 장바구니 안내사항
        </s.InfoTitle>
        <br />- 장바구니에 담긴 상품은 담은 시점으로 부터 30일간 보관됩니다.
        <br /> - 장바구니에는 최대 100개까지 보관이 가능하며, 100개가 넘는 경우 오래된 상품부터
        순차적으로 자동 삭제됩니다.
        <br /> - 장바구니에 담긴 상품의 금액 및 옵션 등의 구매정보가 변동될 수 있습니다.
        <br /> - 제주/도서산간 지역은 주문 시 추가 배송비가 발생합니다.
      </s.InfoArea>
    </s.Container>
  );
};

export default CartPresenter;

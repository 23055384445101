import React, { Dispatch, Props, SetStateAction } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as s from './MainStyled';
// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
// import Swiper core and required modules
import SwiperCore, { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/core';

import Slider from '../../components/slider/Slider';

import YouTube from 'react-youtube';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss'; // *
import 'swiper/components/scrollbar/scrollbar.scss'; // *
import moment from 'moment';

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade, Pagination, Navigation]);

interface MainProps extends RouteComponentProps {
  userInfo: {
    userKey: any;
    userId: any;
    userNm: any;
    userTypeCd: any;
    useYn: any;
  };
  cityList: { en: string; kr: string }[];
  city: number;
  weather: { main: string };
  changeCity: (type: number) => void;
  weatherText: (weatherText: string) => string;
  notSupport: () => void;
  categoryList: any[];
  programList: any[];
  bannerList: any[];
  selectedCategoryList: {
    menuCd: string;
    menuNm: string;
  }[];
  setSelectedCategoryList: React.Dispatch<
    React.SetStateAction<
      {
        menuCd: string;
        menuNm: string;
      }[]
    >
  >;
  onChangeCategoryList: (item: any) => void;
  contentCategoryList: {
    menuCd: string;
    menuNm: string;
  }[];
  selectedContentCategoryList: {
    menuCd: string;
    menuNm: string;
  }[];
  setSelectedContentCategoryList: React.Dispatch<
    React.SetStateAction<
      {
        menuCd: string;
        menuNm: string;
      }[]
    >
  >;
  onChangeContentCategoryList: (item: any) => void;
  multimediaList: any[];
  token: string;
  onChangeId: React.ChangeEventHandler<HTMLInputElement>;
  onChangePw: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress: React.KeyboardEventHandler<HTMLInputElement>;
  onSubmit: React.MouseEventHandler<HTMLDivElement>;
  checkLogin: () => any;
  logout: () => void;
  dataPackageList: any[];
  actSheetList: any[];
  activeBoard: number;
  setActiveBoard: Dispatch<SetStateAction<number>>;
  boardItems: any[];
  goTo: (path: string) => void;
}

const MainPresenter = (props: MainProps) => {
  return (
    <s.Container>
      <Swiper
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        spaceBetween={20}
        effect={'fade'}
        pagination={{
          clickable: true,
        }}
        style={{ height: '400px' }}
      >
        {props.bannerList.map((item) => (
          <SwiperSlide>
            <s.Banner src={item} />
          </SwiperSlide>
        ))}
      </Swiper>

      <s.MainContentArea>
        {/* from here */}
        <s.NewUpdateArea>
          <s.NewUpdateTitle>New Update</s.NewUpdateTitle>
          <s.NewUpdateText>3월의 교육계획안이 업데이트되었습니다.</s.NewUpdateText>
        </s.NewUpdateArea>

        <s.MainContentHorizontalSection1>
          <s.MainContentDiv1>
            <s.MainContentTitle>이달의 교육계획안</s.MainContentTitle>
            <s.MainContentDiv1ListContainer>
              <s.MainContentDiv1ListRow>
                <s.MainContentDiv1ListItemType1 style={{ backgroundColor: '#FFE1BE' }}>
                  <s.MainContentDiv1ListItemTextSmall
                    style={{ fontWeight: 'bold', color: '#F3B759' }}
                  >
                    전체
                    <br />
                    연령
                  </s.MainContentDiv1ListItemTextSmall>
                </s.MainContentDiv1ListItemType1>
                <s.MainContentDiv1ListItemType2
                  style={{ backgroundColor: '#FFEED9' }}
                  onClick={() => props.history.push('/plan?type=monthly&age=AUTH0101')}
                >
                  <s.MainContentDiv1ListItemTextBig style={{ color: '#EDAF54' }}>
                    1
                  </s.MainContentDiv1ListItemTextBig>
                  <s.MainContentDiv1ListItemTextSmall style={{ color: '#EDAF54' }}>
                    세
                  </s.MainContentDiv1ListItemTextSmall>
                </s.MainContentDiv1ListItemType2>
              </s.MainContentDiv1ListRow>
              <s.MainContentDiv1ListRow>
                <s.MainContentDiv1ListItemType2
                  style={{ backgroundColor: '#FEDEDD' }}
                  onClick={() => props.history.push('/plan?type=monthly&age=AUTH0102')}
                >
                  <s.MainContentDiv1ListItemTextBig style={{ color: '#ED8794' }}>
                    2
                  </s.MainContentDiv1ListItemTextBig>
                  <s.MainContentDiv1ListItemTextSmall style={{ color: '#ED8794' }}>
                    세
                  </s.MainContentDiv1ListItemTextSmall>
                </s.MainContentDiv1ListItemType2>
                <s.MainContentDiv1ListItemType1
                  style={{ backgroundColor: '#FFD8DA' }}
                  onClick={() => props.history.push('/plan?type=monthly&age=AUTH0103')}
                >
                  <s.MainContentDiv1ListItemTextBig style={{ color: '#EA8792' }}>
                    3
                  </s.MainContentDiv1ListItemTextBig>
                  <s.MainContentDiv1ListItemTextSmall style={{ color: '#EA8792' }}>
                    세
                  </s.MainContentDiv1ListItemTextSmall>
                </s.MainContentDiv1ListItemType1>
              </s.MainContentDiv1ListRow>
              <s.MainContentDiv1ListRow>
                <s.MainContentDiv1ListItemType1
                  style={{ backgroundColor: '#DBE7FF' }}
                  onClick={() => props.history.push('/plan?type=monthly&age=AUTH0104')}
                >
                  <s.MainContentDiv1ListItemTextBig style={{ color: '#61A1CE' }}>
                    4
                  </s.MainContentDiv1ListItemTextBig>
                  <s.MainContentDiv1ListItemTextSmall style={{ color: '#61A1CE' }}>
                    세
                  </s.MainContentDiv1ListItemTextSmall>
                </s.MainContentDiv1ListItemType1>
                <s.MainContentDiv1ListItemType2
                  style={{ backgroundColor: '#D6EDFE' }}
                  onClick={() => props.history.push('/plan?type=monthly&age=AUTH0105')}
                >
                  <s.MainContentDiv1ListItemTextBig style={{ color: '#5B9BCD' }}>
                    5
                  </s.MainContentDiv1ListItemTextBig>
                  <s.MainContentDiv1ListItemTextSmall style={{ color: '#5B9BCD' }}>
                    세
                  </s.MainContentDiv1ListItemTextSmall>
                </s.MainContentDiv1ListItemType2>
              </s.MainContentDiv1ListRow>
            </s.MainContentDiv1ListContainer>
            <s.MainContentDiv1AdBox>
              <s.AdImage src="/static/img/ad_left.jpg" />
            </s.MainContentDiv1AdBox>
            <s.MainContentDiv1BannerBox to="/edu?title=MN0207">
              <s.AdImage src="/static/img/main_div1_banner.png" />
            </s.MainContentDiv1BannerBox>
          </s.MainContentDiv1>

          <s.MainContentDiv2>
            <s.MainContentTitle>추천 콘텐츠</s.MainContentTitle>
            {props.multimediaList.length > 1 ? (
              <s.MainContentDiv2Row>
                <s.MainContentDiv2RowBox onClick={() => props.goTo('/multi')}>
                  <s.MainContentDiv2RowBoxImg
                    alt="Content"
                    src={
                      props.multimediaList[0].thumbnail !== null
                        ? props.multimediaList[0].thumbnail.fileUrl
                        : null
                    }
                  />
                  <s.MainContentDiv2RowBoxTextArea>
                    <s.MainContentDiv2RowBoxTextCategory
                      color={
                        props.multimediaList[0].subMenu.meunCd === 'MN0401'
                          ? '#7f70b1'
                          : props.multimediaList[0].subMenu.meunCd === 'MN0402'
                          ? '#01c76f'
                          : props.multimediaList[0].subMenu.meunCd === 'MN0403'
                          ? '#7f70b1'
                          : props.multimediaList[0].subMenu.meunCd === 'MN0404'
                          ? '#01c76f'
                          : '#ee8198'
                      }
                    >
                      {props.multimediaList[0].subMenu.menuNm.slice(0, 2)}
                    </s.MainContentDiv2RowBoxTextCategory>
                    <s.MainContentDiv2RowBoxTextContent>
                      {props.multimediaList[0].title}
                    </s.MainContentDiv2RowBoxTextContent>
                  </s.MainContentDiv2RowBoxTextArea>
                </s.MainContentDiv2RowBox>
                <s.MainContentDiv2RowBox onClick={() => props.goTo('/multi')}>
                  <s.MainContentDiv2RowBoxImg
                    alt="Content"
                    src={
                      props.multimediaList[1].thumbnail !== null
                        ? props.multimediaList[1].thumbnail.fileUrl
                        : null
                    }
                  />
                  <s.MainContentDiv2RowBoxTextArea>
                    <s.MainContentDiv2RowBoxTextCategory
                      color={
                        props.multimediaList[1].subMenu.meunCd === 'MN0401'
                          ? '#7f70b1'
                          : props.multimediaList[1].subMenu.meunCd === 'MN0402'
                          ? '#01c76f'
                          : props.multimediaList[1].subMenu.meunCd === 'MN0403'
                          ? '#7f70b1'
                          : props.multimediaList[1].subMenu.meunCd === 'MN0404'
                          ? '#01c76f'
                          : '#ee8198'
                      }
                    >
                      {props.multimediaList[1].subMenu.menuNm.slice(0, 2)}
                    </s.MainContentDiv2RowBoxTextCategory>
                    <s.MainContentDiv2RowBoxTextContent>
                      {props.multimediaList[1].title}
                    </s.MainContentDiv2RowBoxTextContent>
                  </s.MainContentDiv2RowBoxTextArea>
                </s.MainContentDiv2RowBox>
              </s.MainContentDiv2Row>
            ) : props.multimediaList.length === 1 ? (
              <s.MainContentDiv2Row>
                <s.MainContentDiv2RowBox
                  style={{ marginRight: '10px' }}
                  onClick={() => props.goTo('/multi')}
                >
                  <s.MainContentDiv2RowBoxImg
                    alt="Content"
                    src={
                      props.multimediaList[0].thumbnail !== null
                        ? props.multimediaList[0].thumbnail.fileUrl
                        : null
                    }
                  />
                  <s.MainContentDiv2RowBoxTextArea>
                    <s.MainContentDiv2RowBoxTextCategory
                      color={
                        props.multimediaList[0].subMenu.meunCd === 'MN0401'
                          ? '#7f70b1'
                          : props.multimediaList[0].subMenu.meunCd === 'MN0402'
                          ? '#01c76f'
                          : props.multimediaList[0].subMenu.meunCd === 'MN0403'
                          ? '#7f70b1'
                          : props.multimediaList[0].subMenu.meunCd === 'MN0404'
                          ? '#01c76f'
                          : '#ee8198'
                      }
                    >
                      {props.multimediaList[0].subMenu.menuNm.slice(0, 2)}
                    </s.MainContentDiv2RowBoxTextCategory>
                    <s.MainContentDiv2RowBoxTextContent>
                      {props.multimediaList[0].title}
                    </s.MainContentDiv2RowBoxTextContent>
                  </s.MainContentDiv2RowBoxTextArea>
                </s.MainContentDiv2RowBox>
                <s.MainContentDiv2RowBox>
                  <s.MainContentDiv2NoItemsPlaceHolder>
                    더 조회된 컨텐츠가 없습니다.
                  </s.MainContentDiv2NoItemsPlaceHolder>
                </s.MainContentDiv2RowBox>
              </s.MainContentDiv2Row>
            ) : (
              <s.MainContentDiv2NoItemsPlaceHolder style={{ marginTop: '10px' }}>
                컨텐츠가 없습니다.
              </s.MainContentDiv2NoItemsPlaceHolder>
            )}

            <s.MainContentDiv2BannerWrapper to="/shopping">
              <s.MainContentDiv2Banner src="/static/img/main_middle_banner.jpg" />
            </s.MainContentDiv2BannerWrapper>

            <s.MainContentTitle>추천 프로그램</s.MainContentTitle>
            {props.programList.length > 3 ? (
              <s.MainContentDiv2Row>
                <s.MainContentDiv2RowBoxSmall
                  style={{ marginRight: '10px' }}
                  onClick={() => props.goTo('/activity')}
                >
                  <s.MainContentDiv2RowBoxImg
                    alt="Content"
                    src={
                      props.programList[0].thumbnail !== null
                        ? props.programList[0].thumbnail.fileUrl
                        : null
                    }
                  />
                  <s.MainContentDiv2RowBoxTextAreaSmall>
                    <s.MainContentDiv2RowBoxTextCategory
                      color={
                        props.programList[0].subMenu.meunCd === 'MN0501'
                          ? '#7f70b1'
                          : props.programList[0].subMenu.meunCd === 'MN0502'
                          ? '#01c76f'
                          : props.programList[0].subMenu.meunCd === 'MN0503'
                          ? '#7f70b1'
                          : props.programList[0].subMenu.meunCd === 'MN0504'
                          ? '#01c76f'
                          : '#ee8198'
                      }
                    >
                      {props.programList[0].subMenu.menuNm.slice(0, 2)}
                    </s.MainContentDiv2RowBoxTextCategory>
                    <s.MainContentDiv2RowBoxTextContent>
                      {props.programList[0].title}
                    </s.MainContentDiv2RowBoxTextContent>
                  </s.MainContentDiv2RowBoxTextAreaSmall>
                </s.MainContentDiv2RowBoxSmall>
                <s.MainContentDiv2RowBoxSmall
                  style={{ marginRight: '10px' }}
                  onClick={() => props.goTo('/activity')}
                >
                  <s.MainContentDiv2RowBoxImg
                    alt="Content"
                    src={
                      props.programList[1].thumbnail !== null
                        ? props.programList[1].thumbnail.fileUrl
                        : null
                    }
                  />
                  <s.MainContentDiv2RowBoxTextAreaSmall>
                    <s.MainContentDiv2RowBoxTextCategory
                      color={
                        props.programList[1].subMenu.meunCd === 'MN0501'
                          ? '#7f70b1'
                          : props.programList[1].subMenu.meunCd === 'MN0502'
                          ? '#01c76f'
                          : props.programList[1].subMenu.meunCd === 'MN0503'
                          ? '#7f70b1'
                          : props.programList[1].subMenu.meunCd === 'MN0504'
                          ? '#01c76f'
                          : '#ee8198'
                      }
                    >
                      {props.programList[1].subMenu.menuNm.slice(0, 2)}
                    </s.MainContentDiv2RowBoxTextCategory>
                    <s.MainContentDiv2RowBoxTextContent>
                      {props.programList[1].title}
                    </s.MainContentDiv2RowBoxTextContent>
                  </s.MainContentDiv2RowBoxTextAreaSmall>
                </s.MainContentDiv2RowBoxSmall>
                <s.MainContentDiv2RowBoxSmall
                  style={{ marginRight: '10px' }}
                  onClick={() => props.goTo('/activity')}
                >
                  <s.MainContentDiv2RowBoxImg
                    alt="Content"
                    src={
                      props.programList[2].thumbnail !== null
                        ? props.programList[2].thumbnail.fileUrl
                        : null
                    }
                  />
                  <s.MainContentDiv2RowBoxTextAreaSmall>
                    <s.MainContentDiv2RowBoxTextCategory
                      color={
                        props.programList[2].subMenu.meunCd === 'MN0501'
                          ? '#7f70b1'
                          : props.programList[2].subMenu.meunCd === 'MN0502'
                          ? '#01c76f'
                          : props.programList[2].subMenu.meunCd === 'MN0503'
                          ? '#7f70b1'
                          : props.programList[2].subMenu.meunCd === 'MN0504'
                          ? '#01c76f'
                          : '#ee8198'
                      }
                    >
                      {props.programList[2].subMenu.menuNm.slice(0, 2)}
                    </s.MainContentDiv2RowBoxTextCategory>
                    <s.MainContentDiv2RowBoxTextContent>
                      {props.programList[2].title}
                    </s.MainContentDiv2RowBoxTextContent>
                  </s.MainContentDiv2RowBoxTextAreaSmall>
                </s.MainContentDiv2RowBoxSmall>

                <s.MainContentDiv2RowBoxSmall
                  style={{ marginRight: '10px' }}
                  onClick={() => props.goTo('/activity')}
                >
                  <s.MainContentDiv2RowBoxImg
                    alt="Content"
                    src={
                      props.programList[3].thumbnail !== null
                        ? props.programList[3].thumbnail.fileUrl
                        : null
                    }
                  />
                  <s.MainContentDiv2RowBoxTextAreaSmall>
                    <s.MainContentDiv2RowBoxTextCategory
                      color={
                        props.programList[3].subMenu.meunCd === 'MN0501'
                          ? '#7f70b1'
                          : props.programList[3].subMenu.meunCd === 'MN0502'
                          ? '#01c76f'
                          : props.programList[3].subMenu.meunCd === 'MN0503'
                          ? '#7f70b1'
                          : props.programList[3].subMenu.meunCd === 'MN0504'
                          ? '#01c76f'
                          : '#ee8198'
                      }
                    >
                      {props.programList[3].subMenu.menuNm.slice(0, 2)}
                    </s.MainContentDiv2RowBoxTextCategory>
                    <s.MainContentDiv2RowBoxTextContent>
                      {props.programList[3].title}
                    </s.MainContentDiv2RowBoxTextContent>
                  </s.MainContentDiv2RowBoxTextAreaSmall>
                </s.MainContentDiv2RowBoxSmall>
              </s.MainContentDiv2Row>
            ) : props.programList.length === 3 ? (
              <s.MainContentDiv2Row>
                <s.MainContentDiv2RowBoxSmall
                  style={{ marginRight: '10px' }}
                  onClick={() => props.goTo('/activity')}
                >
                  <s.MainContentDiv2RowBoxImg
                    alt="Content"
                    src={
                      props.programList[0].thumbnail !== null
                        ? props.programList[0].thumbnail.fileUrl
                        : null
                    }
                  />
                  <s.MainContentDiv2RowBoxTextAreaSmall>
                    <s.MainContentDiv2RowBoxTextCategory
                      color={
                        props.programList[0].subMenu.meunCd === 'MN0501'
                          ? '#598793'
                          : props.programList[0].subMenu.meunCd === 'MN0502'
                          ? '#01c76f'
                          : props.programList[0].subMenu.meunCd === 'MN0503'
                          ? '#7f70b1'
                          : props.programList[0].subMenu.meunCd === 'MN0504'
                          ? '#01c76f'
                          : '#ee8198'
                      }
                    >
                      {props.programList[0].subMenu.menuNm.slice(0, 2)}
                    </s.MainContentDiv2RowBoxTextCategory>
                    <s.MainContentDiv2RowBoxTextContent>
                      {props.programList[0].title}
                    </s.MainContentDiv2RowBoxTextContent>
                  </s.MainContentDiv2RowBoxTextAreaSmall>
                </s.MainContentDiv2RowBoxSmall>

                <s.MainContentDiv2RowBoxSmall
                  style={{ marginRight: '10px' }}
                  onClick={() => props.goTo('/activity')}
                >
                  <s.MainContentDiv2RowBoxImg
                    alt="Content"
                    src={
                      props.programList[1].thumbnail !== null
                        ? props.programList[1].thumbnail.fileUrl
                        : null
                    }
                  />
                  <s.MainContentDiv2RowBoxTextAreaSmall>
                    <s.MainContentDiv2RowBoxTextCategory
                      color={
                        props.programList[1].subMenu.meunCd === 'MN0501'
                          ? '#7f70b1'
                          : props.programList[1].subMenu.meunCd === 'MN0502'
                          ? '#01c76f'
                          : props.programList[1].subMenu.meunCd === 'MN0503'
                          ? '#7f70b1'
                          : props.programList[1].subMenu.meunCd === 'MN0504'
                          ? '#01c76f'
                          : '#ee8198'
                      }
                    >
                      {props.programList[1].subMenu.menuNm.slice(0, 2)}
                    </s.MainContentDiv2RowBoxTextCategory>
                    <s.MainContentDiv2RowBoxTextContent>
                      {props.programList[1].title}
                    </s.MainContentDiv2RowBoxTextContent>
                  </s.MainContentDiv2RowBoxTextAreaSmall>
                </s.MainContentDiv2RowBoxSmall>
                <s.MainContentDiv2RowBoxSmall
                  style={{ marginRight: '10px' }}
                  onClick={() => props.goTo('/activity')}
                >
                  <s.MainContentDiv2RowBoxImg
                    alt="Content"
                    src={
                      props.programList[2].thumbnail !== null
                        ? props.programList[2].thumbnail.fileUrl
                        : null
                    }
                  />
                  <s.MainContentDiv2RowBoxTextAreaSmall>
                    <s.MainContentDiv2RowBoxTextCategory
                      color={
                        props.programList[2].subMenu.meunCd === 'MN0501'
                          ? '#7f70b1'
                          : props.programList[2].subMenu.meunCd === 'MN0502'
                          ? '#01c76f'
                          : props.programList[2].subMenu.meunCd === 'MN0503'
                          ? '#7f70b1'
                          : props.programList[2].subMenu.meunCd === 'MN0504'
                          ? '#01c76f'
                          : '#ee8198'
                      }
                    >
                      {props.programList[2].subMenu.menuNm.slice(0, 2)}
                    </s.MainContentDiv2RowBoxTextCategory>
                    <s.MainContentDiv2RowBoxTextContent>
                      {props.programList[2].title}
                    </s.MainContentDiv2RowBoxTextContent>
                  </s.MainContentDiv2RowBoxTextAreaSmall>
                </s.MainContentDiv2RowBoxSmall>

                <s.MainContentDiv2RowBoxSmall>
                  <s.MainContentDiv2NoItemsPlaceHolder>
                    더 조회된 컨텐츠가 없습니다.
                  </s.MainContentDiv2NoItemsPlaceHolder>
                </s.MainContentDiv2RowBoxSmall>
              </s.MainContentDiv2Row>
            ) : props.programList.length === 2 ? (
              <s.MainContentDiv2Row>
                <s.MainContentDiv2RowBoxSmall
                  style={{ marginRight: '10px' }}
                  onClick={() => props.goTo('/activity')}
                >
                  <s.MainContentDiv2RowBoxImg
                    alt="Content"
                    src={
                      props.programList[0].thumbnail !== null
                        ? props.programList[0].thumbnail.fileUrl
                        : null
                    }
                  />
                  <s.MainContentDiv2RowBoxTextAreaSmall>
                    <s.MainContentDiv2RowBoxTextCategory
                      color={
                        props.programList[0].subMenu.meunCd === 'MN0501'
                          ? '#598793'
                          : props.programList[0].subMenu.meunCd === 'MN0502'
                          ? '#01c76f'
                          : props.programList[0].subMenu.meunCd === 'MN0503'
                          ? '#7f70b1'
                          : props.programList[0].subMenu.meunCd === 'MN0504'
                          ? '#01c76f'
                          : '#ee8198'
                      }
                    >
                      {props.programList[0].subMenu.menuNm.slice(0, 2)}
                    </s.MainContentDiv2RowBoxTextCategory>
                    <s.MainContentDiv2RowBoxTextContent>
                      {props.programList[0].title}
                    </s.MainContentDiv2RowBoxTextContent>
                  </s.MainContentDiv2RowBoxTextAreaSmall>
                </s.MainContentDiv2RowBoxSmall>

                <s.MainContentDiv2RowBoxSmall
                  style={{ marginRight: '10px' }}
                  onClick={() => props.goTo('/activity')}
                >
                  <s.MainContentDiv2RowBoxImg
                    alt="Content"
                    src={
                      props.programList[1].thumbnail !== null
                        ? props.programList[1].thumbnail.fileUrl
                        : null
                    }
                  />
                  <s.MainContentDiv2RowBoxTextAreaSmall>
                    <s.MainContentDiv2RowBoxTextCategory
                      color={
                        props.programList[1].subMenu.meunCd === 'MN0501'
                          ? '#7f70b1'
                          : props.programList[1].subMenu.meunCd === 'MN0502'
                          ? '#01c76f'
                          : props.programList[1].subMenu.meunCd === 'MN0503'
                          ? '#7f70b1'
                          : props.programList[1].subMenu.meunCd === 'MN0504'
                          ? '#01c76f'
                          : '#ee8198'
                      }
                    >
                      {props.programList[1].subMenu.menuNm.slice(0, 2)}
                    </s.MainContentDiv2RowBoxTextCategory>
                    <s.MainContentDiv2RowBoxTextContent>
                      {props.programList[1].title}
                    </s.MainContentDiv2RowBoxTextContent>
                  </s.MainContentDiv2RowBoxTextAreaSmall>
                </s.MainContentDiv2RowBoxSmall>

                <s.MainContentDiv2RowBoxSmall style={{ width: '344px', border: '0px ' }}>
                  <s.MainContentDiv2NoItemsPlaceHolder style={{ border: '0px' }}>
                    더 조회된 컨텐츠가 없습니다.
                  </s.MainContentDiv2NoItemsPlaceHolder>
                </s.MainContentDiv2RowBoxSmall>
              </s.MainContentDiv2Row>
            ) : props.programList.length === 1 ? (
              <s.MainContentDiv2Row>
                <s.MainContentDiv2RowBoxSmall
                  style={{ marginRight: '10px' }}
                  onClick={() => props.goTo('/activity')}
                >
                  <s.MainContentDiv2RowBoxImg
                    alt="Content"
                    src={
                      props.programList[0].thumbnail !== null
                        ? props.programList[0].thumbnail.fileUrl
                        : null
                    }
                  />
                  <s.MainContentDiv2RowBoxTextAreaSmall>
                    <s.MainContentDiv2RowBoxTextCategory
                      color={
                        props.programList[0].subMenu.meunCd === 'MN0501'
                          ? '#598793'
                          : props.programList[0].subMenu.meunCd === 'MN0502'
                          ? '#01c76f'
                          : props.programList[0].subMenu.meunCd === 'MN0503'
                          ? '#7f70b1'
                          : props.programList[0].subMenu.meunCd === 'MN0504'
                          ? '#01c76f'
                          : '#ee8198'
                      }
                    >
                      {props.programList[0].subMenu.menuNm.slice(0, 2)}
                    </s.MainContentDiv2RowBoxTextCategory>
                    <s.MainContentDiv2RowBoxTextContent>
                      {props.programList[0].title}
                    </s.MainContentDiv2RowBoxTextContent>
                  </s.MainContentDiv2RowBoxTextAreaSmall>
                </s.MainContentDiv2RowBoxSmall>
                <s.MainContentDiv2RowBoxSmall style={{ width: '431px', border: '0px' }}>
                  <s.MainContentDiv2NoItemsPlaceHolder style={{ border: '0px' }}>
                    더 조회된 컨텐츠가 없습니다.
                  </s.MainContentDiv2NoItemsPlaceHolder>
                </s.MainContentDiv2RowBoxSmall>
              </s.MainContentDiv2Row>
            ) : (
              <s.MainContentDiv2NoItemsPlaceHolder style={{ marginTop: '10px' }}>
                컨텐츠가 없습니다.
              </s.MainContentDiv2NoItemsPlaceHolder>
            )}
          </s.MainContentDiv2>

          <s.MainContentDiv4>
            <s.MainContentDiv4LoginButtonSection>
              {props.checkLogin() ? null : (
                <>
                  <s.MainContentDiv4LoginSaveCheckBox type="checkbox" />
                  <s.MainContentDiv4LoginSaveText
                    onChange={() => {
                      console.log('SAVE ID');
                    }}
                  >
                    아이디저장
                  </s.MainContentDiv4LoginSaveText>
                </>
              )}
            </s.MainContentDiv4LoginButtonSection>

            <s.MainContentDiv4LoginSection
              style={
                props.checkLogin()
                  ? { border: '1px', borderStyle: 'solid', borderColor: '#DFDFDF' }
                  : {}
              }
            >
              {props.checkLogin() ? (
                <>
                  <s.MainContentDiv4AfterLoginTopDiv>
                    <s.MainContentDiv4UserInfoSection>
                      <s.MainContentDiv4AfterLoginTopName>
                        {props.userInfo.userId}
                      </s.MainContentDiv4AfterLoginTopName>
                      <s.MainContentDiv4AfterLoginTopType>
                        {props.userInfo.userTypeCd === 'ADMIN'
                          ? '관리자'
                          : props.userInfo.userTypeCd === 'MASTER'
                          ? '마스터'
                          : props.userInfo.userTypeCd === 'user'
                          ? '정규사용자'
                          : '일반사용자'}
                      </s.MainContentDiv4AfterLoginTopType>
                    </s.MainContentDiv4UserInfoSection>
                    <s.MainContentDiv4AfterLoginLogoutText
                      onClick={() => {
                        props.logout();
                      }}
                    >
                      로그아웃
                    </s.MainContentDiv4AfterLoginLogoutText>
                  </s.MainContentDiv4AfterLoginTopDiv>
                  <s.MainContentDiv4AfterLoginButtonSection>
                    <s.MainContentDiv4AfterLoginButtonArea>
                      <s.MainContentDiv4AfterLoginButtonTextArea>
                        <s.MainContentDiv4AfterLoginButtonTextLabel>
                          정회원
                        </s.MainContentDiv4AfterLoginButtonTextLabel>
                        <s.MainContentDiv4AfterLoginButtonTextData>
                          미가입
                        </s.MainContentDiv4AfterLoginButtonTextData>
                      </s.MainContentDiv4AfterLoginButtonTextArea>
                      <s.MainContentDiv4AfterLoginMyPageButton
                        onClick={() => {
                          props.history.push('/mypage');
                        }}
                      >
                        MY PAGE
                      </s.MainContentDiv4AfterLoginMyPageButton>
                    </s.MainContentDiv4AfterLoginButtonArea>
                    <s.MainContentDiv4AfterLoginButtonArea>
                      <s.MainContentDiv4AfterLoginButtonTextArea>
                        <s.MainContentDiv4AfterLoginButtonTextLabel>
                          유료회원
                        </s.MainContentDiv4AfterLoginButtonTextLabel>
                        <s.MainContentDiv4AfterLoginButtonTextData>
                          미가입
                        </s.MainContentDiv4AfterLoginButtonTextData>
                      </s.MainContentDiv4AfterLoginButtonTextArea>
                      <s.MainContentDiv4AfterLoginMyShoppingButton
                        onClick={() => {
                          props.history.push('/mypage/cart');
                        }}
                      >
                        MY 쇼핑
                      </s.MainContentDiv4AfterLoginMyShoppingButton>
                    </s.MainContentDiv4AfterLoginButtonArea>
                  </s.MainContentDiv4AfterLoginButtonSection>
                  <s.MainContentDiv4AfterLoginDataSection>
                    <s.MainContentDiv4AfterLoginDataTextLabel
                      style={{ width: '33%', textAlign: 'left', paddingLeft: '10px' }}
                    >
                      포인트
                    </s.MainContentDiv4AfterLoginDataTextLabel>
                    <s.MainContentDiv4AfterLoginDataTextData
                      style={{ width: '14%', textAlign: 'right', paddingRight: '15px' }}
                    >
                      1,060p
                    </s.MainContentDiv4AfterLoginDataTextData>
                    <s.MainContentDiv4AfterLoginDataTextLabel
                      style={{ width: '6%', textAlign: 'center' }}
                    >
                      |
                    </s.MainContentDiv4AfterLoginDataTextLabel>
                    <s.MainContentDiv4AfterLoginDataTextLabel
                      style={{ width: '33%', textAlign: 'left', paddingLeft: '10px' }}
                    >
                      쪽지
                    </s.MainContentDiv4AfterLoginDataTextLabel>
                    <s.MainContentDiv4AfterLoginDataTextData
                      style={{ width: '14%', textAlign: 'right', paddingRight: '10px' }}
                    >
                      0 통
                    </s.MainContentDiv4AfterLoginDataTextData>
                  </s.MainContentDiv4AfterLoginDataSection>
                </>
              ) : (
                <>
                  <s.MainContentDiv4LoginMainSection>
                    <s.MainContentDiv4LoginInputSection>
                      <s.MainContentDiv4LoginInput
                        placeholder={'아이디'}
                        type="text"
                        name="id"
                        onChange={props.onChangeId}
                        onKeyPress={props.onKeyPress}
                      />
                      <s.MainContentDiv4LoginInput
                        placeholder={'비밀번호'}
                        type="password"
                        style={{ marginTop: '2px' }}
                        name="password"
                        onChange={props.onChangePw}
                        onKeyPress={props.onKeyPress}
                      />
                    </s.MainContentDiv4LoginInputSection>
                    <s.MainContentDiv4LoginButton onClick={props.onSubmit}>
                      로그인
                    </s.MainContentDiv4LoginButton>
                  </s.MainContentDiv4LoginMainSection>

                  <s.MainContentDiv4LoginSmallItemSection>
                    <s.MainContentDiv4LoginSmallItem
                      onClick={() => {
                        console.log('TO REGISTER');
                      }}
                    >
                      회원가입
                    </s.MainContentDiv4LoginSmallItem>
                    <s.MainContentDiv4LoginSmallItem>|</s.MainContentDiv4LoginSmallItem>
                    <s.MainContentDiv4LoginSmallItem
                      onClick={() => {
                        console.log('TO ID/PW FIND');
                      }}
                    >
                      아이디/비밀번호 찾기
                    </s.MainContentDiv4LoginSmallItem>
                  </s.MainContentDiv4LoginSmallItemSection>

                  <s.MainContentDiv4LoginBigItemSection>
                    <s.MainContentDiv4LoginBigItem>
                      <s.MainContentDiv4LoginBigItemImg src="/static/img/info.png" />
                      회원정보
                    </s.MainContentDiv4LoginBigItem>
                    <s.MainContentDiv4LoginBigItem>
                      <s.MainContentDiv4LoginBigItemImg src="/static/img/scrap.png" />
                      스크랩
                    </s.MainContentDiv4LoginBigItem>
                    <s.MainContentDiv4LoginBigItem>
                      <s.MainContentDiv4LoginBigItemImg src="/static/img/setting.png" />
                      기타
                    </s.MainContentDiv4LoginBigItem>
                  </s.MainContentDiv4LoginBigItemSection>
                </>
              )}
            </s.MainContentDiv4LoginSection>
            <s.MainContentDiv4Link to="/">
              <s.MainContentDiv2Banner src="/static/img/main_div4_banner.jpg" />
            </s.MainContentDiv4Link>

            <s.MainContentDiv4ArticleWrapper>
              <s.MainContentDiv4TabAreaWrapper>
                <s.MainContentDiv4TabAreaItem
                  active={props.activeBoard === 0}
                  onClick={() => props.setActiveBoard(0)}
                >
                  공지사항
                </s.MainContentDiv4TabAreaItem>
                <s.MainContentDiv4TabAreaItem
                  active={props.activeBoard === 1}
                  onClick={() => props.setActiveBoard(1)}
                >
                  활동지
                </s.MainContentDiv4TabAreaItem>
              </s.MainContentDiv4TabAreaWrapper>
              <s.MainContentDiv4ArticleList>
                {props.boardItems.length > 0 ? (
                  props.boardItems.map((item: any) => {
                    return (
                      <s.MainContentDiv4ArticleItem to={item.linkTo}>
                        {item.title.length > 20 ? item.title.substring(0, 20) + '...' : item.title}
                        <s.MainContentDiv4ArtivleItemDate>
                          {moment(item.regDtm).format('YY.MM')}
                        </s.MainContentDiv4ArtivleItemDate>
                      </s.MainContentDiv4ArticleItem>
                    );
                  })
                ) : (
                  <s.MainContentDiv4ArtivleNoItem>
                    게시글이 없습니다.
                  </s.MainContentDiv4ArtivleNoItem>
                )}
              </s.MainContentDiv4ArticleList>
            </s.MainContentDiv4ArticleWrapper>

            <s.MainContentDiv4CustomerWrapper>
              <s.MainContentDiv4CustomerTitleBig>
                하비캐슬
                <s.MainContentDiv4CustomerTitleSmall>고객센터</s.MainContentDiv4CustomerTitleSmall>
              </s.MainContentDiv4CustomerTitleBig>
              <s.MainContentDiv4CustomerPhone>080-080-9781</s.MainContentDiv4CustomerPhone>
              <s.MainContentDiv4CustomerExplanation>
                평일 09:00 ~ 18:00 (주말/공휴일 휴무)
              </s.MainContentDiv4CustomerExplanation>

              <s.MainContentDiv4CustomerIconList>
                <s.MainContentDiv4CustomerIconWrapper>
                  <s.MainContentDiv4CustomerIcon src={'/static/icons/main_qna.png'} />
                  <s.MainContentDiv4CustomerIconText>
                    자주 묻는 질문
                  </s.MainContentDiv4CustomerIconText>
                </s.MainContentDiv4CustomerIconWrapper>
                <s.MainContentDiv4CustomerIconWrapper>
                  <s.MainContentDiv4CustomerIcon src={'/static/icons/main_talk.png'} />
                  <s.MainContentDiv4CustomerIconText>
                    카카오톡 문의
                  </s.MainContentDiv4CustomerIconText>
                </s.MainContentDiv4CustomerIconWrapper>
                <s.MainContentDiv4CustomerIconWrapper>
                  <s.MainContentDiv4CustomerIcon src={'/static/icons/main_mail.png'} />
                  <s.MainContentDiv4CustomerIconText>메일 문의</s.MainContentDiv4CustomerIconText>
                </s.MainContentDiv4CustomerIconWrapper>
              </s.MainContentDiv4CustomerIconList>
            </s.MainContentDiv4CustomerWrapper>
          </s.MainContentDiv4>
        </s.MainContentHorizontalSection1>
      </s.MainContentArea>
    </s.Container>
  );
};

MainPresenter.defaultProps = {
  mark: '',
  name: '',
};

export default MainPresenter;

import * as s from '../UpdateEduStyled';

interface SelectHolderProps {
  option: {
    holder: string;
    edu: string;
    type: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
  holderList: any;
}

const SelectHolder = (props: SelectHolderProps) => {
  return (
    <s.SelectArea>
      <s.SelectLabel>월(호) 선택</s.SelectLabel>
      <s.SelectDiv></s.SelectDiv>
      <s.SelectForm selectType={'holder'}>
        {props.holderList &&
          Object.entries(props.holderList).map(([key, value]) => {
            return (
              <s.SelectItem
                key={'holder' + key}
                active={props.holderList[key].comnCd === props.option.holder}
                selectType={'holder'}
                onClick={() => props.onChangeOption('holder', props.holderList[key].comnCd)}
              >
                {props.holderList[key].comnCdNm}
              </s.SelectItem>
            );
          })}
      </s.SelectForm>
    </s.SelectArea>
  );
};

export default SelectHolder;

import Axios from 'axios';

const baseApi = Axios.create({
  baseURL: `https://api.openweathermap.org/data/2.5/weather`,
});

const WeatherApi = {
  getWeather: (city: string) =>
    baseApi.get('', {
      params: {
        q: city,
        appid: '8a667b92a8b073b049d9a0bc15c8f1bd',
        lang: 'kr',
      },
    }),
};

export default WeatherApi;

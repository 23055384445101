import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1075px;
  position: relative;
  z-index: 1;
  margin: 0 auto 50px;
  text-align: center;
  padding-bottom: 30px;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;


export const OrderRegisterHeader = styled.div`
  width: 1075px;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 35px 0 10px;
  vertical-align: middle;
`;

export const OrderRegisterHeaderTitleArea = styled.div`
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const OrderRegisterHeaderTitle = styled.div`
  float: left;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const OrderRegisterHeaderTitleDivide = styled.div`
  float: left;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  padding-top: 3px;
  margin: 0 10px;
  color: #e2e2e2;
`;

export const OrderRegisterHeaderSubTitle = styled.div`
  float: left;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  padding-top: 14px;
  margin-left: 20px;
  color: #e2e2e2;
`;

export const OrderRegisterHeaderRightArea = styled.div`
  float: right;
  text-align: center;
  vertical-align: middle;
`;
export const OrderRegisterHeaderRightIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 5px;
  vertical-align: middle;
`;

export const OrderRegisterHeaderRightText = styled.div`
  font-weight: 400;
  color: #4d4d4d;
  vertical-align: middle;
`;


export const ProgramListArea = styled.div`
  width: 1091px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
  padding-bottom: 50px;
  position: relative;
`;

export const ProgramForm = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  font-size: 20px;
  color: #5f5f5f;
  font-weight: 500;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #aaa;
`;


export const SelectDoneButton = styled(Link)`
  position: absolute;
  margin: 0 auto;
  z-index: 9999;
  right: 0%;  
  bottom: 0%;
  display: flex;
  width: 86px;
  height: 37px;
  font-size: 16px;
  color: #0087ED;
  border: 1px solid #0087ED;
  border-radius: 50px;
  background-color: #FFFFFF;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;


export const ProgramListCenterCol = styled.div`
  flex: 1;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;


export const ProgramList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 1091px;
  margin: 0px auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
`;

export const ProgramListHeader = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  font-size: 18px;
  color: #4D4D4D;
  font-weight: 500;
  align-items: center;
  justify-content: flex-start;
  background-color: #eee;
  border-bottom: 1px solid #aaa;
`;


export const AddressTable = styled.table`
  width: 1091px;
  margin: 0 auto;
  border-collapse: collapse;
  border-spacing: 0px;
  border-top: 2px solid #ABABAB;
`;

export const AddressTr = styled.tr`
  border-bottom: 1px solid #ABABAB;
`;

export const AddressTh = styled.th`
  background-color: #DEDEDE;
  padding: 10px;
`;

export const AddressTd = styled.td`
  padding: 16px;
`;

export const AddressTdContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const AddressTdInputButton = styled.div`
  width: 122px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0087ED;
  background-color: #FFFFFF;
  color: #0087ED;
  font-size: 16px;
  cursor: pointer;
`;

export const AddressTdRow = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const AddressTdInput = styled.input`
  width: 250px;
  height: 35px;
  border: 1px solid #ABABAB;
  padding: 0 10px;
`;

export const AddressTdInputArea = styled.textarea`
  width: 250px;
  height: 35px;
  border: 1px solid #ABABAB;
  padding: 10px;
  resize: none;
`;

export const OrderButton = styled.div`
  width: 122px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 16px;
  background-color: #0087ED;
  cursor: pointer;
  margin: 30px auto 100px;
`;

export const PostCodeStyle = styled.div`
  display: block,
  position: relative,
  top: 0%;
  width: 400px;
  height: 400px;
  padding: 7px;
`;
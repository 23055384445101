import React, { Dispatch, SetStateAction } from 'react';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { RouteComponentProps } from 'react-router-dom';
import Media from './component/Media';

import BeatLoader from 'react-spinners/BeatLoader';
import * as s from './RegisterVideosStyled';
interface RegisterVideosProps extends RouteComponentProps {
  option: {
    age: string | null;
    holder: string;
    topMenu: string;
    subMenu: string;
    type: string;
    field: string;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      holder: string;
      topMenu: string;
      subMenu: string;
      type: string;
      field: string;
    }>
  >;
  ageAuths: any;
  holderList: any;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  open: () => void;
  acceptedFiles: File[];
  isDragActive: boolean;
  isModifying: boolean;
  setIsModifying: React.Dispatch<React.SetStateAction<boolean>>;
  submitFileList: File[];
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  submit: () => Promise<void>;
  programMedias: {
    thumbnail?: File;
    file?: File;
    description: string;
  }[];
  onChangeProgramMedia: (index: number, name: string, value: string | File) => void;
  addProgramMidea: () => void;
  subMenuList: {
    menuCd: string;
    menuNm: string;
    viewOrder: number;
  }[];
  fieldList: {
    menuCd: string;
    menuNm: string;
    viewOrder: number;
  }[];
}

const RegisterVideosPresenter = (props: RegisterVideosProps) => {
  return (
    <s.Container>
      <s.TitleArea>
        <s.Title>
          교수동영상
        </s.Title>
      </s.TitleArea>
      <s.UploadArea>
        <s.UploadAreaHeader>
          <s.UploadAreaHeaderLeft>업로드</s.UploadAreaHeaderLeft>
          <s.UploadAreaHeaderRight>
            <s.UploadAreaHeaderRightIcon src="/static/img/warning.png" />
            <s.UploadAreaHeaderRightText>ZIP파일은 업로드 불가입니다.</s.UploadAreaHeaderRightText>
          </s.UploadAreaHeaderRight>
        </s.UploadAreaHeader>
        <s.UploadContentArea>
          <s.UploadProgramArea>
            <s.UploadProgramAreaHeader>
              <s.UploadProgramAreaInputArea>
                <s.UploadProgramAreaHeaderLabel>제목</s.UploadProgramAreaHeaderLabel>
                <s.UploadProgramAreaHeaderInput
                  value={props.title}
                  onChange={(e) => props.setTitle(e.target.value)}
                  placeholder={'텍스트를 입력해주세요.'}
                  disabled={!props.isModifying}
                />
              </s.UploadProgramAreaInputArea>
            </s.UploadProgramAreaHeader>

          </s.UploadProgramArea>
          {props.programMedias.map(
            (
              item: {
                thumbnail?: File;
                file?: File;
                description: string;
              },
              index: number,
            ) => (
              <Media
                programMedia={item}
                onChangeProgramMedia={(name: string, value: File | string) =>
                  props.onChangeProgramMedia(index, name, value)
                }
              />
            ),
          )}
          <s.ButtonArea>
            <s.SubmitButton onClick={props.submit}>
              <s.SubmitButtonText>업로드</s.SubmitButtonText>
            </s.SubmitButton>
          </s.ButtonArea>
        </s.UploadContentArea>
      </s.UploadArea>
    </s.Container>
  );
};

export default RegisterVideosPresenter;

import styled from 'styled-components';

export const Container = styled.div`
  width: 1180px;
  margin: 0 auto;
  text-align: center;
`;

export const PlanningTitleArea = styled.div`
  width: 80%;
  margin: 30px auto 0;
  height: auto;
  border-bottom: 1px solid #0087ed;
  padding: 10px 0 5px;
  display: inline-block;
  text-align: left;
  vertical-align: baseline;
`;

export const PlanningTitle = styled.div`
  font-size: 20px;
  display: inline-block;
  line-height: 32px;
  font-weight: 800;
`;

export const PlanningTitleSpan = styled.span`
  color: #0087ed;
`;

export const YearlyPlanFileUploadArea = styled.div`
  width: 80%;
  margin: 30px auto 0;
  background-color: #f8f8f8;
  border-radius: 5px;
`;

export const YearlyPlanFileUploadAreaHeader = styled.div`
  width: 100%;
  padding: 12px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #0087ed;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const YearlyPlanFileUploadAreaHeaderLeft = styled.div`
  font-size: 18px;
  color: #fff;
  font-weight: 600;
`;

export const YearlyPlanFileUploadAreaHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const YearlyPlanFileUploadAreaHeaderRightIcon = styled.img`
  width: 30px;
  height: auto;
  margin-right: 10px;
`;

export const YearlyPlanFileUploadAreaHeaderRightText = styled.div`
  font-size: 16px;
  color: #fff;
  font-weight: 500;
`;

export const YearlyPlanFileUploadInputArea = styled.div<{ isDrag?: boolean }>`
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  opacity: ${(props) => (props.isDrag ? 0.5 : 1)};
`;

export const YearlyPlanFileUploadInput = styled.input``;

export const YearlyPlanFileUploadInputButton = styled.div`
  padding: 10px 20px;
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const YearlyPlanFileUploadInputButtonIcon = styled.img`
  width: auto;
  height: auto;
  cursor: pointer;
`;
export const YearlyPlanFileUploadInputButtonText = styled.div`
  font-size: 18px;
  color: #9c9c9c;
  font-weight: 600;
`;

export const YearlyPlanFileUploadStatusArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 50px;
  background-color: #fff;
  border: 1px solid #ececec;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const YearlyPlanFileUploadStatusName = styled.div`
  font-size: 16px;
  color: #6f6f6f;
  font-weight: 600;
`;

export const YearlyPlanFileUploadStatus = styled.div<{ status?: string }>`
  padding: 8px 35px;
  border-radius: 30px;
  background-color: #fff;
  border: 1.5px solid
    ${(props) =>
      props.status === 'loading'
        ? '#acacac'
        : props.status === 'complete'
        ? '#00AF00'
        : props.status === 'exist'
        ? '#0788ED'
        : '#acacac'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: ${(props) => (props.status === 'exist' ? 'pointer' : 'default')};
  font-size: 14px;
  color: ${(props) =>
    props.status === 'loading'
      ? '#acacac'
      : props.status === 'complete'
      ? '#00AF00'
      : props.status === 'exist'
      ? '#0788ED'
      : '#acacac'};
  font-weight: 600;
`;

export const YearlyPlanFileUploadStatusCancle = styled.div`
  padding: 8px 35px;
  border-radius: 30px;
  background-color: #fff;
  border: 1.5px solid #ff7a5b;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #ff7a5b;
  font-size: 14px;
  font-weight: 600;
`;

export const YearlyPlanUploadArea = styled.div`
  width: 80%;
  margin: 30px auto 0;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding-bottom: 20px;
`;

export const YearlyPlanUploadAreaHeader = styled.div`
  width: 100%;
  padding: 12px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #0087ed;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const YearlyPlanUploadAreaHeaderLeft = styled.div`
  font-size: 18px;
  color: #fff;
  font-weight: 600;
`;

export const YearlyPlanUploadAreaHeaderRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const YearlyPlanUploadAreaHeaderRightIcon = styled.img`
  width: 30px;
  height: auto;
  margin-right: 10px;
`;

export const YearlyPlanUploadAreaHeaderRightText = styled.div`
  font-size: 16px;
  color: #fff;
  font-weight: 500;
`;

export const YealyPlanUploadContent = styled.div``;

export const YealyPlanUploadContentArea = styled.div`
  padding: 20px;
`;

export const YealyPlanUploadContentAreaHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const YealyPlanUploadContentAreaHeaderLeft = styled.div`
  padding: 10px 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const YealyPlanUploadContentAreaHeaderRight = styled.div`
  padding: 10px 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const YealyPlanUploadContentAreaHeaderIcon = styled.img`
  width: 15px;
  height: auto;
  margin: 5px;
  cursor: pointer;
`;

export const YealyPlanUploadContentAreaHeaderLeftText = styled.div<{ isColor: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.isColor ? '#7d7d7d' : '#F56466')};
  cursor: pointer;
`;

export const YealyPlanUploadContentAreaHeaderRightText = styled.div<{ isColor: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.isColor ? '#7d7d7d' : '#4CBC41')};
  cursor: pointer;
`;

export const YealyPlanUploadMonthArea = styled.div`
  border: 1px solid #acacac;
  border-radius: 5px;
`;

export const YealyPlanUploadMonthAreaHeader = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const YealyPlanUploadMonthAreaInput = styled.input`
  width: 40px;
  height: 30px;
  border: 1px solid #c6c6c6;
  border-radius: 2px;
  text-align: center;
`;
export const YealyPlanUploadMonthAreaHeaderText = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: #7d7d7d;
  margin-right: 20px;
  cursor: pointer;
`;
export const YealyPlanUploadMonthAreaSelectWeek = styled.input`
  display: none;
`;
export const YealyPlanUploadMonthAreaSelectWeekLabel = styled.label`
  border: 1px solid #dfdfdf;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const YealyPlanUploadMonthAreaSelectWeekLabelIcon = styled.img`
  width: 60%;
  height: 60%;
`;

export const YealyPlanUploadMonthAreaFileUploadArea = styled.div<{ isDrag: boolean }>`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  opacity: ${(props) => (props.isDrag ? 0.5 : 1)};
`;
export const YealyPlanUploadMonthAreaFileUploadAreaText = styled.div`
  font-size: 18px;
  color: #9c9c9c;
  font-weight: 600;
`;
export const YealyPlanUploadMonthAreaFileUploadAreaInput = styled.input``;
export const YealyPlanUploadMonthAreaFileUploadAreaButton = styled.div`
  padding: 10px 20px;
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
export const YealyPlanUploadMonthAreaFileUploadAreaButtonIcon = styled.img`
  width: auto;
  height: auto;
  cursor: pointer;
`;
export const YealyPlanUploadMonthAreaFileUploadAreaButtonText = styled.div``;

export const YealyPlanUploadMonthAreaStatusArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 50px;
  border: 1px solid #ececec;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const YealyPlanUploadMonthAreaStatusName = styled.div`
  font-size: 16px;
  color: #6f6f6f;
  font-weight: 600;
`;

export const YealyPlanUploadMonthAreaStatus = styled.div`
  padding: 8px 35px;
  border-radius: 30px;
  background-color: #fff;
  border: 1.5px solid #acacac;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #acacac;
  font-weight: 600;
`;

export const YealyPlanUploadMonthAreaStatusCancle = styled.div`
  padding: 8px 35px;
  border-radius: 30px;
  background-color: #fff;
  border: 1.5px solid #ff7a5b;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #ff7a5b;
  font-size: 14px;
  font-weight: 600;
`;

export const YealyPlanUploadMonthAreaSubjectArea = styled.div`
  background-color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;
export const YealyPlanUploadMonthAreaSubjectAreaLabel = styled.label`
  color: #4c4c4c;
  margin-right: 10px;
`;
export const YealyPlanUploadMonthAreaSubjectAreaInput = styled.input`
  height: 30px;
  width: 400px;
  padding: 10px;
  border: 1px solid #c6c6c6;
  border-radius: 2px;
`;
export const YealyPlanUploadMonthAreaSubjectAreaText = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #7d7d7d;
  margin-right: 20px;
  cursor: pointer;
`;

export const YealyPlanUploadWeekArea = styled.div`
  margin-top: 20px;
  border: 1px solid #acacac;
  border-radius: 5px;
`;

export const YealyPlanUploadWeekAreaHeader = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 30px;
  gap: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;
export const YealyPlanUploadWeekAreaHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #7d7d7d;
  margin-right: 19%;
`;
export const YealyPlanUploadWeekAreaHeaderLabel = styled.label`
  color: #4c4c4c;
`;
export const YealyPlanUploadWeekAreaHeaderInput = styled.input`
  height: 30px;
  width: 400px;
  padding: 10px;
  border: 1px solid #c6c6c6;
  border-radius: 2px;
`;
export const YealyPlanUploadWeekAreaHeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
export const YealyPlanUploadWeekAreaHeaderText = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #7d7d7d;
  margin-right: 20px;
  cursor: pointer;
`;

export const YealyPlanUploadWeekAreaFileUploadArea = styled.div<{ isDrag: boolean }>`
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  opacity: ${(props) => (props.isDrag ? 0.5 : 1)};
`;
export const YealyPlanUploadWeekAreaFileUploadAreaText = styled.div`
  font-size: 18px;
  color: #9c9c9c;
  font-weight: 600;
`;
export const YealyPlanUploadWeekAreaFileUploadAreaInput = styled.input``;
export const YealyPlanUploadWeekAreaFileUploadAreaButton = styled.div``;
export const YealyPlanUploadWeekAreaFileUploadAreaButtonIcon = styled.img`
  width: auto;
  height: auto;
  cursor: pointer;
`;
export const YealyPlanUploadWeekAreaFileUploadAreaButtonText = styled.div``;

export const YealyPlanUploadWeekAreaStatusArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 50px;
  border: 1px solid #ececec;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const YealyPlanUploadWeekAreaStatusName = styled.div`
  font-size: 16px;
  color: #6f6f6f;
  font-weight: 600;
`;

export const YealyPlanUploadWeekAreaStatus = styled.div`
  padding: 8px 35px;
  border-radius: 30px;
  background-color: #fff;
  border: 1.5px solid #acacac;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #acacac;
  font-weight: 600;
`;

export const YealyPlanUploadWeekAreaStatusCancle = styled.div`
  padding: 8px 35px;
  border-radius: 30px;
  background-color: #fff;
  border: 1.5px solid #ff7a5b;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #ff7a5b;
  font-size: 14px;
  font-weight: 600;
`;

export const MonthAddButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const MonthAddButton = styled.div`
  background-color: #0087ed;
  padding: 10px 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border-radius: 30px;
  cursor: pointer;
`;
export const MonthAddButtonIcon = styled.img``;
export const MonthAddButtonText = styled.div`
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
`;

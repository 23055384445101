import moment from 'moment';
import React, { Dispatch, Props, SetStateAction } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router-dom';
import { priceToString } from '../../../util/util';
import * as s from './OrderStyled';

interface OrderProps extends RouteComponentProps {
  userInfo: {
    userKey: any;
    userId: any;
    userNm: any;
    userTypeCd: any;
    useYn: any;
    regDtm: any;
    userEmail: any;
    userMobno: any;
  };
  orderItems: any;
  totalPrice: number;
  onChangeOpenPost: () => void;
  address: string;
  addressDetail: string;
  setAddressDetail: Dispatch<SetStateAction<string>>;

  receiverNm: string;
  setReceiverNm: Dispatch<SetStateAction<string>>;
  receiverTelno: string;
  setReceiverTelno: Dispatch<SetStateAction<string>>;
  memo: string;
  setMemo: Dispatch<SetStateAction<string>>;

  payment: number;
  setPayment: Dispatch<SetStateAction<number>>;
  doPay: () => void;
}

const OrderPresenter = (props: OrderProps) => {
  return (
    <s.Container>
      <s.TopTableArea>
        <s.TopTitle>주문/결제</s.TopTitle>
        <s.TopTable>
          <s.TopTbody>
            <s.TopTr>
              <s.TopTh style={{ width: '40%' }}>상품</s.TopTh>
              <s.TopTh style={{ width: '20%' }}>가격</s.TopTh>
              <s.TopTh style={{ width: '20%' }}>수량</s.TopTh>
              <s.TopTh style={{ width: '20%', borderRight: 0 }}>합계금액</s.TopTh>
            </s.TopTr>
            {props.orderItems.map((item: any, index: number) => {
              return (
                <s.TopTr>
                  <s.TopTd style={{ textAlign: 'left' }}>{item.productNm}</s.TopTd>
                  <s.TopTd style={{ textAlign: 'right' }}>
                    <s.TopTdSpan>{priceToString(item.price)}</s.TopTdSpan>&nbsp;원
                  </s.TopTd>
                  <s.TopTd style={{ textAlign: 'right' }}>{priceToString(item.count)}</s.TopTd>
                  <s.TopTd style={{ textAlign: 'right', borderRight: 0 }}>
                    <s.TopTdSpan>{priceToString(item.totalPrice)}</s.TopTdSpan>
                    &nbsp;원
                  </s.TopTd>
                </s.TopTr>
              );
            })}
            {props.totalPrice < 50000 ? (
              <s.TopTr>
                <s.TopTd style={{ textAlign: 'left' }}>배송비</s.TopTd>
                <s.TopTd style={{ textAlign: 'right' }}>
                  <s.TopTdSpan>{priceToString(3000)}</s.TopTdSpan>&nbsp;원
                </s.TopTd>
                <s.TopTd style={{ textAlign: 'right' }}></s.TopTd>
                <s.TopTd style={{ textAlign: 'right', borderRight: 0 }}>
                  <s.TopTdSpan>{priceToString(3000)}</s.TopTdSpan>
                  &nbsp;원
                </s.TopTd>
              </s.TopTr>
            ) : (
              <s.TopTr>
                <s.TopTd style={{ textAlign: 'left' }}>배송비</s.TopTd>
                <s.TopTd style={{ textAlign: 'right' }}>
                  <s.TopTdSpan>{priceToString(0)}</s.TopTdSpan>&nbsp;원
                </s.TopTd>
                <s.TopTd style={{ textAlign: 'right' }}></s.TopTd>
                <s.TopTd style={{ textAlign: 'right', borderRight: 0 }}>
                  <s.TopTdSpan>{priceToString(0)}</s.TopTdSpan>
                  &nbsp;원
                </s.TopTd>
              </s.TopTr>
            )}
            <s.TopTr>
              <s.TopTh
                colSpan={4}
                style={{ borderTop: '1px solid #cecece', textAlign: 'right', padding: '15px 30px' }}
              >
                <s.TopThSpan>{priceToString(props.totalPrice)}&nbsp;원</s.TopThSpan>
              </s.TopTh>
            </s.TopTr>
          </s.TopTbody>
        </s.TopTable>
      </s.TopTableArea>

      <s.OrderInfoArea>
        <s.TopTitle>배송지 정보</s.TopTitle>
        <s.OrderTable>
          <s.OrderTbody>
            <s.OrderTr>
              <s.OrderTh>이름</s.OrderTh>
              <s.OrderTd>
                <s.OrderTdInput
                  type="text"
                  placeholder="수령자"
                  value={props.receiverNm}
                  onChange={(e) => props.setReceiverNm(e.target.value)}
                />
              </s.OrderTd>
            </s.OrderTr>
            <s.OrderTr>
              <s.OrderTh>연락처</s.OrderTh>
              <s.OrderTd>
                <s.OrderTdInput
                  type="text"
                  pattern="[0-9]*"
                  placeholder="수령자 연락처"
                  value={props.receiverTelno}
                  onChange={(e) =>
                    e.target.validity.valid ? props.setReceiverTelno(e.target.value) : console.log()
                  }
                />
              </s.OrderTd>
            </s.OrderTr>
            <s.OrderTr>
              <s.OrderTh>주소</s.OrderTh>
              <s.OrderTd>
                <s.AddressTdContent>
                  <s.AddressTdInputButton onClick={props.onChangeOpenPost}>
                    우편번호찾기
                  </s.AddressTdInputButton>
                  <s.AddressTdRow>
                    <s.AddressTdInput
                      disabled={true}
                      style={{ width: '122px', backgroundColor: '#DEDEDE' }}
                      value={props.address}
                    ></s.AddressTdInput>
                    <s.AddressTdInput
                      style={{ marginLeft: '16px', width: '400px' }}
                      value={props.addressDetail}
                      onChange={(e) => {
                        props.setAddressDetail(e.target.value);
                      }}
                    />
                  </s.AddressTdRow>
                </s.AddressTdContent>
              </s.OrderTd>
            </s.OrderTr>
            <s.OrderTr>
              <s.OrderTh>배송메모</s.OrderTh>
              <s.OrderTd>
                <s.OrderTdTextArea
                  placeholder="빠르게 배송해주세요."
                  value={props.memo}
                  onChange={(e) => props.setMemo(e.target.value)}
                />
              </s.OrderTd>
            </s.OrderTr>
          </s.OrderTbody>
        </s.OrderTable>
      </s.OrderInfoArea>

      <s.PaymentInfoArea>
        <s.TopTitle>결제 수단 선택</s.TopTitle>
        <s.OrderTable>
          <s.OrderTbody>
            <s.OrderTr>
              <s.OrderTh style={{ width: '100%', padding: '20px' }}>
                <s.PaymentSelectWrapper>
                  <s.PaymentSelectRadio
                    type="radio"
                    onChange={() => props.setPayment(0)}
                    checked={props.payment === 0}
                  />
                  <s.PaymentSelectRadioLabel>신용카드</s.PaymentSelectRadioLabel>
                  <s.PaymentSelectRadio
                    type="radio"
                    onChange={() => props.setPayment(1)}
                    checked={props.payment === 1}
                  />
                  <s.PaymentSelectRadioLabel>가상계좌</s.PaymentSelectRadioLabel>
                </s.PaymentSelectWrapper>
              </s.OrderTh>
            </s.OrderTr>
          </s.OrderTbody>
        </s.OrderTable>
      </s.PaymentInfoArea>
      <s.PaymentButton onClick={props.doPay}>결제하기</s.PaymentButton>
    </s.Container>
  );
};

export default OrderPresenter;

import React, { Dispatch, SetStateAction } from 'react';

import * as util from '../../../util/util';
import * as s from './ProductDetailStyled';
import NotFound from '../../../components/notFound';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  FaArrowRight,
  FaCaretRight,
  FaCartPlus,
  FaChevronRight,
  FaHeart,
  FaInfoCircle,
  FaPen,
  FaShoppingBag,
  FaShoppingBasket,
  FaShoppingCart,
  FaUser,
} from 'react-icons/fa';

interface Props {
  userInfo: any;
  itemInfo: any;
  selectedThumb: string;
  setSelectedThumb: Dispatch<SetStateAction<string>>;
  itemCount: number;
  setItemCount: Dispatch<SetStateAction<number>>;
  activeFilter: number;
  setActiveFilter: Dispatch<SetStateAction<number>>;
  addProductToCart: () => void;
  orderProduct: () => void;
  goToEdit: () => void;
}

const ProductDetailPresenter = (props: Props) => {
  return (
    <s.Container>
      <s.ItemTopArea>
        <s.ItemThumbnailArea>
          <s.ItemThumbnailView src={props.selectedThumb} />
          <s.ItemThumbnailList>
            {props.itemInfo?.thumbnailList.map((thumb: any) => (
              <s.ItemThumbnailSmall
                src={thumb.fileUrl}
                onClick={() => props.setSelectedThumb(thumb.fileUrl)}
              />
            ))}
            {props.itemInfo?.useYn === 'N' ? <s.SoldoutThumbCover>품절</s.SoldoutThumbCover> : null}
          </s.ItemThumbnailList>
        </s.ItemThumbnailArea>
        <s.ItemInfoArea>
          <s.ItemNm>{props.itemInfo?.productNm}</s.ItemNm>
          <s.priceArea>
            <s.priceRow>
              판매가<s.priceNumber>{util.priceToString(props.itemInfo?.price)} 원</s.priceNumber>
            </s.priceRow>
            <s.priceRow>
              배송비
              <s.explainSpan>50,000원 이상 구매시 무료배송</s.explainSpan>
              <s.priceNumber>
                {Number(props.itemInfo?.price) >= 50000 ? 0 : util.priceToString(3000)} 원
              </s.priceNumber>
            </s.priceRow>
            <s.priceRow>
              배송기일
              <s.explainSpan>(결제완료기준)</s.explainSpan>
              <s.priceNumber>3일이내</s.priceNumber>
            </s.priceRow>
            <s.priceRow>
              상품공급업체
              <s.priceNumber>엄지교육</s.priceNumber>
            </s.priceRow>
          </s.priceArea>
          <s.ItemCountWrapper>
            <s.priceRow>
              수량
              <s.CountComponent>
                <s.CountDownButton
                  onClick={() =>
                    props.itemCount > 1
                      ? props.setItemCount(props.itemCount - 1)
                      : util.makeMsg('최소 수량은 1개 입니다', 'error')
                  }
                >
                  －
                </s.CountDownButton>
                <s.CountItem>{props.itemCount}</s.CountItem>
                <s.CountUpButton onClick={() => props.setItemCount(props.itemCount + 1)}>
                  +
                </s.CountUpButton>
              </s.CountComponent>
            </s.priceRow>
          </s.ItemCountWrapper>
          <s.TotalPriceArea>
            <s.TotalPriceLabel>총 금액(수량)</s.TotalPriceLabel>
            <s.TotalPrice>
              {util.priceToString(props.itemCount * props.itemInfo?.price)}&nbsp;원
              <s.TotalPricePostfix>({props.itemCount}&nbsp;개)</s.TotalPricePostfix>
            </s.TotalPrice>
          </s.TotalPriceArea>
          <s.ButtonArea>
            {props.userInfo.userTypeCd === 'MASTER' ? (
              <>
                <s.BuyButton onClick={() => props.goToEdit()}>
                  <FaPen size={'20px'} color={'#ffffff'} />
                  상품수정
                </s.BuyButton>
              </>
            ) : props.itemInfo?.useYn === 'N' ? (
              <s.SoldOutPanel>품절</s.SoldOutPanel>
            ) : (
              <>
                <s.BuyButton onClick={() => props.orderProduct()}>
                  <FaShoppingCart size={'20px'} color={'#ffffff'} />
                  구매하기
                </s.BuyButton>
                <s.CartButton onClick={() => props.addProductToCart()}>
                  <FaShoppingBasket size={'20px'} color={'#ffffff'} />
                  장바구니
                </s.CartButton>
              </>
            )}
          </s.ButtonArea>
        </s.ItemInfoArea>
      </s.ItemTopArea>

      <s.FilterArea>
        <s.FilterItem active={props.activeFilter === 1} onClick={() => props.setActiveFilter(1)}>
          상품 상세정보
        </s.FilterItem>
        <s.FilterItem active={props.activeFilter === 2} onClick={() => props.setActiveFilter(2)}>
          상품 구매안내
        </s.FilterItem>
        <s.FilterItem active={props.activeFilter === 3} onClick={() => props.setActiveFilter(3)}>
          배송안내
        </s.FilterItem>
        <s.FilterItem
          active={props.activeFilter === 4}
          isLast={true}
          onClick={() => props.setActiveFilter(4)}
        >
          주문취소/교환/반품안내
        </s.FilterItem>
      </s.FilterArea>

      {props.activeFilter === 1 ? (
        <s.DetailPageList>
          {props.itemInfo?.detailList.map((detail: any) => (
            <s.DetailPage src={detail.fileUrl} />
          ))}
        </s.DetailPageList>
      ) : props.activeFilter === 2 ? (
        <s.InfoTable>
          <s.InfoTbody>
            <s.InfoTr>
              <s.InfoTh>품명 및 모델명</s.InfoTh>
              <s.InfoTd colSpan={3}>{props.itemInfo?.productNm}</s.InfoTd>
            </s.InfoTr>
            <s.InfoTr>
              <s.InfoTh>자율안전신고필증번호</s.InfoTh>
              <s.InfoTd colSpan={3}>{props.itemInfo?.credNumber}</s.InfoTd>
            </s.InfoTr>
            <s.InfoTr>
              <s.InfoTh>크기,중량</s.InfoTh>
              <s.InfoTd colSpan={3}>{props.itemInfo?.size}</s.InfoTd>
            </s.InfoTr>
            <s.InfoTr>
              <s.InfoTh>타입</s.InfoTh>
              <s.InfoTd colSpan={3}>{props.itemInfo?.type}</s.InfoTd>
            </s.InfoTr>
            <s.InfoTr>
              <s.InfoTh>재질</s.InfoTh>
              <s.InfoTd colSpan={3}>{props.itemInfo?.material}</s.InfoTd>
            </s.InfoTr>
            <s.InfoTr>
              <s.InfoTh>출시년월</s.InfoTh>
              <s.InfoTd colSpan={3}>{props.itemInfo?.openYear}</s.InfoTd>
            </s.InfoTr>
            <s.InfoTr>
              <s.InfoTh>제조국</s.InfoTh>
              <s.InfoTd colSpan={3}>{props.itemInfo?.madeIn}</s.InfoTd>
            </s.InfoTr>
            <s.InfoTr>
              <s.InfoTh>취급방법 및 주의사항</s.InfoTh>
              <s.InfoTd colSpan={3}>
                1. 화기 및 뜨거운곳에 주의하세요.
                <br />
                <br />
                2. 입에 넣거나 빨지 마세요.
                <br />
                <br />
                3. 용도 외 사용하지 마세요.
              </s.InfoTd>
            </s.InfoTr>
            <s.InfoTr>
              <s.InfoTh>품질보증기준</s.InfoTh>
              <s.InfoTd colSpan={3}>소비자 분쟁해결 기준을 따름</s.InfoTd>
            </s.InfoTr>
            <s.InfoTr>
              <s.InfoTh>AS책임자 전화번호</s.InfoTh>
              <s.InfoTd colSpan={3}>080-080-9781</s.InfoTd>
            </s.InfoTr>
          </s.InfoTbody>
        </s.InfoTable>
      ) : props.activeFilter === 3 ? (
        <s.InfoTable>
          <s.InfoTbody>
            <s.InfoTr>
              <s.InfoTh>구분</s.InfoTh>
              <s.InfoTh style={{ borderLeft: '1px solid #cecece' }} colSpan={3}>
                배송안내
              </s.InfoTh>
            </s.InfoTr>
            <s.InfoTr>
              <s.InfoTh>배송기간</s.InfoTh>
              <s.InfoTd colSpan={3}>
                <s.InfoTdBold>물류센터 배송</s.InfoTdBold>
                - 배송기간은 별제 완료 후 평균 3~5일이 소요
                <br />
                - 주문제작 상품(단체티, 도장, 가구) 등의 경우 4~5일 소요 (토 / 일 / 공휴일 제외)
                <br />
                - 도서, 산간 지역은 1~2일 정도 더 추가되며, 특별히 배송이 지연되는 경우 미리 연락을
                드리고 있습니다.
                <br />
                <br />
                <s.InfoTdBold>합배송</s.InfoTdBold>
                - 물류센터 재고 부족 시, 재고 있는 매장에서 이동 받아 배송되며, 5~7일이 소요될 수
                있습니다. (토 / 일 / 공휴일 제외)
                <br />- 오프라인 매장과 동시 판매되고 있으므로, 결제 완료 후에도 발송지연 또는
                품절이 될 수 있습니다.
              </s.InfoTd>
            </s.InfoTr>
            <s.InfoTr>
              <s.InfoTh>배송비용</s.InfoTh>
              <s.InfoTd colSpan={3}>
                - 50,000원 이상 구매 시 배송비 무료입니다.
                <br />- 도서, 산간 지역 추가 운임료 발생할 수 있습니다.
              </s.InfoTd>
            </s.InfoTr>
            <s.InfoTr>
              <s.InfoTh>배송조회</s.InfoTh>
              <s.InfoTd colSpan={3} style={{ verticalAlign: 'middle' }}>
                <s.InfoTdBold>배송조회 방법</s.InfoTdBold>
                <s.InfoTdList>
                  <s.InfoTdListItem>
                    <FaArrowRight size={'16px'} color={'#0091ff'} />
                    로그인
                  </s.InfoTdListItem>{' '}
                  <FaChevronRight size={'18px'} color={'#ABABAB'} />
                  <s.InfoTdListItem>
                    <FaUser size={'16px'} color={'#0091ff'} />
                    마이페이지
                  </s.InfoTdListItem>{' '}
                  <FaChevronRight size={'18px'} color={'#ABABAB'} />
                  <s.InfoTdListItem>
                    <FaInfoCircle size={'16px'} color={'#0091ff'} />
                    결제내역조회
                  </s.InfoTdListItem>{' '}
                  <FaChevronRight size={'18px'} color={'#ABABAB'} />
                  <s.InfoTdListItem>운송장번호 확인</s.InfoTdListItem>
                </s.InfoTdList>
              </s.InfoTd>
            </s.InfoTr>
          </s.InfoTbody>
        </s.InfoTable>
      ) : (
        <s.InfoTable>
          <s.InfoTbody>
            <s.InfoTr>
              <s.InfoTh rowSpan={2}>주문취소</s.InfoTh>
              <s.InfoTd>
                - 직접 취소 신청은 주문접수, 결제 완료 상태일 때만 가능합니다.
                <br />
                (상품준비중 또는 배송중 상태에서는 취소 불가)
                <br />
                - 주문제작 상품은 제작 일정으로 인해 상품 준비중일 경우에는 취소 신청이 어렵습니다.
                <br />- 부득이한 취소를 원하실 경우 고객센터 (080-080-9781) 에 문의하여 취소 가능
                여부를 꼭 확인하세요.
              </s.InfoTd>
            </s.InfoTr>
            <s.InfoTr>
              <s.InfoTd colSpan={3} style={{ verticalAlign: 'middle' }}>
                <s.InfoTdBold>주문취소 방법</s.InfoTdBold>
                <s.InfoTdList>
                  <s.InfoTdListItem>
                    <FaArrowRight size={'16px'} color={'#0091ff'} />
                    로그인
                  </s.InfoTdListItem>{' '}
                  <FaChevronRight size={'18px'} color={'#ABABAB'} />
                  <s.InfoTdListItem>
                    <FaUser size={'16px'} color={'#0091ff'} />
                    마이페이지
                  </s.InfoTdListItem>{' '}
                  <FaChevronRight size={'18px'} color={'#ABABAB'} />
                  <s.InfoTdListItem>
                    <FaInfoCircle size={'16px'} color={'#0091ff'} />
                    결제내역조회
                  </s.InfoTdListItem>{' '}
                  <FaChevronRight size={'18px'} color={'#ABABAB'} />
                  <s.InfoTdListItem>취소요청</s.InfoTdListItem>
                </s.InfoTdList>
              </s.InfoTd>
            </s.InfoTr>
          </s.InfoTbody>
        </s.InfoTable>
      )}
    </s.Container>
  );
};

export default ProductDetailPresenter;

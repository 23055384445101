import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as recoilItem from '../../../util/recoilitem';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useDropzone } from 'react-dropzone';

import RegisterSoundPresenter from './RegisterSoundPresenter';
import { programApi } from '../../../api/api-program';
import { menuApi } from '../../../api/api-menu';
import { makeMsg } from '../../../util/util';

interface RegisterSoundProps extends RouteComponentProps {
  option: {
    age: string | null;
    holder: string;
    topMenu: string;
    subMenu: string;
    type: string;
    field: string;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      holder: string;
      topMenu: string;
      subMenu: string;
      type: string;
      field: string;
    }>
  >;
  ageAuths: any;
  holderList: any;
}

const RegisterSoundContainer = (props: RegisterSoundProps) => {
  const token = useRecoilValue(recoilItem.token);
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files) => {
      setSubmitFileList(files);
    },
  });
  const [isModifying, setIsModifying] = useState<boolean>(true);
  const [hasVideo, setHasVideo] = useState<boolean>(false);
  const [isTune, setIsTune] = useState<boolean>(false);

  const [fieldList, setFieldList] = useState<
    {
      menuCd: string;
      menuNm: string;
      viewOrder: number;
    }[]
  >([]);
  const [submitFileList, setSubmitFileList] = useState<File[]>([]);
  const [submitVideoList, setSubmitVideoList] = useState<
    {
      files: File[];
      url: string;
      type: string;
      description: string;
    }[]
  >([
    {
      files: [],
      url: '',
      type: '',
      description: '',
    },
  ]);
  const [submitLyricsList, setSubmitLyricsList] = useState<File[]>([]);
  const [submitSheetList, setSubmitSheetList] = useState<File[]>([]);
  const [title, setTitle] = useState<string>('');
  const [submitForm, setSubmitForm] = useState<{
    lyricist: string;
    composer: string;
    tune: string;
    time: string;
  }>({
    lyricist: '',
    composer: '',
    tune: '',
    time: '',
  });

  const getField = async () => {
    try {
      setIsLoading(true);
      await menuApi.getCd('MN03').then((res) => {
        if (res.data.rsltCd === '00') {
          setFieldList(
            res.data.ComnCd.subMenuList.map((item: any) => ({
              menuCd: item.menuCd,
              menuNm: item.menuNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    getField();
  }, []);

  const submit = async () => {
    try {
      setIsLoading(true);
      let formData: FormData = new FormData();
      if (!props.option.age) {
        makeMsg('연령을 선택해주세요.', 'error');
        return;
      }
      if (!title) {
        makeMsg('제목을 입력해주세요.', 'error');
        return;
      }
      if (!props.option.holder) {
        makeMsg('월(호)를 선택해주세요.', 'error');
        return;
      }
      if (!props.option.field) {
        makeMsg('놀이를 선택해주세요.', 'error');
        return;
      }
      if (!submitForm.time) {
        makeMsg('시간을 입력해주세요.', 'error');
        return;
      }
      if (submitFileList.length === 0) {
        makeMsg('음원파일을 선택해주세요.', 'error');
        return;
      }
      if (hasVideo && submitVideoList.length === 0) {
        makeMsg('영상을 선택해주세요.', 'error');
        return;
      }

      formData.append('age', props.option.age);
      formData.append('topMenu', props.option.topMenu);
      formData.append('subMenu', props.option.subMenu);
      formData.append('holder', props.option.holder);
      formData.append('field', props.option.field);
      formData.append('title', title);

      formData.append('programSounds[0].time', submitForm.time);
      if (isTune) {
        if (!submitForm.tune) {
          makeMsg('연주곡을 입력해주세요.', 'error');
          return;
        }
        formData.append('programSounds[0].tune', submitForm.tune);
      } else {
        if (!submitForm.lyricist) {
          makeMsg('작사가를 입력해주세요.', 'error');
          return;
        }
        if (!submitForm.composer) {
          makeMsg('작곡가를 입력해주세요.', 'error');
          return;
        }
        formData.append('programSounds[0].lyricist', submitForm.lyricist);
        formData.append('programSounds[0].composer', submitForm.composer);
      }
      formData.append('programSounds[0].soundtrack.file', submitFileList[0]);
      if (hasVideo) {
        submitVideoList.forEach((video: any, videoIndex: number) => {
          formData.append(`programSounds[0].videos[${videoIndex}].title`, title);
          formData.append(`programSounds[0].videos[${videoIndex}].description`, video.description);
          formData.append(`programSounds[0].videos[${videoIndex}].type`, video.type);
          formData.append(`programSounds[0].videos[${videoIndex}].url`, video.url);
          formData.append(`programSounds[0].videos[${videoIndex}].file.file`, video.files[0]);
        });
      }
      if (submitLyricsList.length > 0) {
        formData.append('programSounds[0].lyrics.file', submitLyricsList[0]);
      }
      if (submitSheetList.length > 0) {
        formData.append('programSounds[0].sheet.file', submitSheetList[0]);
      }
      formData.append('isEduTool', 'N');

      await programApi.createProgram(token, formData).then((res) => {
        if (res.data.rsltCd === '00') {
          makeMsg('업로드에 성공하였습니다.', 'success');
          setIsLoading(false);
          props.history.push('/registerSelect');
        } else {
          makeMsg('업로드에 실패하였습니다.', 'error');
          setIsLoading(false);
        }
      });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const addVideo = () => {
    let temps = [...submitVideoList];
    temps.push({
      files: [],
      url: '',
      type: '',
      description: '',
    });
    setSubmitVideoList(temps);
  };
  const deleteVideo = (index: number) => {
    let temps = [...submitVideoList];
    temps.splice(index, 1);
    setSubmitVideoList(temps);
  };
  return (
    <RegisterSoundPresenter
      {...props}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      open={open}
      acceptedFiles={acceptedFiles}
      isDragActive={isDragActive}
      isModifying={isModifying}
      setIsModifying={setIsModifying}
      submitFileList={submitFileList}
      fieldList={fieldList}
      submit={submit}
      title={title}
      setTitle={setTitle}
      submitForm={submitForm}
      setSubmitForm={setSubmitForm}
      hasVideo={hasVideo}
      setHasVideo={setHasVideo}
      isTune={isTune}
      setIsTune={setIsTune}
      submitVideoList={submitVideoList}
      setSubmitVideoList={setSubmitVideoList}
      submitLyricsList={submitLyricsList}
      setSubmitLyricsList={setSubmitLyricsList}
      submitSheetList={submitSheetList}
      setSubmitSheetList={setSubmitSheetList}
      addVideo={addVideo}
      deleteVideo={deleteVideo}
    />
  );
};

export default RegisterSoundContainer;

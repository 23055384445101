import styled from 'styled-components';

export const Container = styled.div`
  max-width: 768px;
  min-width: 460px;
  position: relative;
  display: block;
  margin: 0 auto;
  padding-bottom: 100px;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const TemporaryContentArea = styled.div`
  display: block;
  width: 460px;
  margin: 0 auto;
`;

export const TemporaryContent = styled.div`
  display: block;
`;

export const TemporaryRow = styled.div`
  display: block;
`;

export const TemporaryTitle = styled.h3`
  margin: 19px 0 8px;
  font-size: 14px;
  font-weight: 700;
`;

export const TemporaryTextInput = styled.input`
  display: block;
  position: relative;
  width: 100%;
  height: 50px;
  border: 0;
  border-radius: 20px;
  padding-left: 20px;
  font-size: 15px;
  color: #c9c9c9;
  box-sizing: border-box;
  background-color: #f0f0f0;
  cursor: pointer;
`;

export const ModalBackGround = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(77, 77, 77, 0.5);
  z-index: 1999;
`;

export const ModalForm = styled.div`
  position: relative;
  width: 640px;
  height: 82.5%; /* */
  z-index: 2999;
  opacity: 1;
  margin: 5% 25% 0;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.25) 0px 8px 24px,
    rgba(17, 17, 26, 0.25) 0px 16px 56px, rgba(17, 17, 26, 0.25) 0px 24px 80px;
`;

export const ModalHeader = styled.div`
  display: inline-block;
  width: 100%;
  height: 60px;
  background-color: #fff;
  border-top: 4px solid #0087ed;
`;

export const ModalHeaderText = styled.span`
  display: inline-block;
  width: 25%;
  line-height: 60px;
  text-align: center;
  font-size: 20px;
`;

export const ModalExitArea = styled.div`
  position: absolute;
  top: 17px;
  right: 17px;
  width: 26px;
  height: 26px;
  float: right;
`;

export const ModalExitImg = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const ModalNavBar = styled.div`
  display: inline-block;
  width: 92%;
  line-height: 50px;
  margin: 0 4%;
  align-items: center;
  justify-content: center;
`;

export const ModalNavSearchArea = styled.div`
  width: 350px;
  height: 40px;
  display: inline-block;
`;

export const ModalNavRefresh = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  vertical-align: middle;
  cursor: pointer;
`;

export const ModalNavSearchImg = styled.img`
  width: 30px;
  height: 30px;
  vertical-align: baseline;
  margin: 2px;
`;

export const ModalNavSearchTextArea = styled.div`
  display: inline-block;
  line-height: 35px;
  width: 220px;
  border: 1.5px solid #0087ed;
`;

export const ModalNavSearchText = styled.input`
  width: 180px;
  height: 35px;
  padding-left: 10px;
  border: none;
`;

export const ModalNavSearchButton = styled.div`
  display: inline-block;
  width: 35px;
  height: 35px;
  vertical-align: middle;
  cursor: pointer;
`;

export const ModalAddButtonArea = styled.div`
  width: 75px;
  height: 100%;
  vertical-align: middle;
  margin-right: 30px;
  float: right;
`;

export const ModalAddAgencyButton = styled.button`
  width: 90px;
  height: 40px;
  color: white;
  font-size: 13.5px;
  background-color: #0087ed;
  border: 1px solid #0087ed;
`;

export const ModalListArea = styled.div`
  width: auto;
  height: auto;
  margin: 20px 15px 0;
  font-size: 15px;
`;

export const ModalColumnArea = styled.div`
  display: flex;
  width: 100%;
  line-height: 25px;
  padding: 5px 0;
  background-color: rgba(0, 135, 237, 0.15);
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`;

export const ModalContentsList = styled.ul`
  display: block;
  width: auto;
  height: auto;
`;

export const ModalListItem = styled.li`
  display: flex;
  width: 100%;
  line-height: 25px;
  padding: 5px 0;
  border-bottom: 1px solid black;

  &:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: rgba(240, 240, 240, 0.5);
  }
`;

export const ModalListItemName = styled.div`
  flex: 3;
  padding-left: 10px;
`;

export const ModalListItemAddr = styled.div`
  flex: 6;
  padding-left: 10px;
`;

export const ModalListItemContact = styled.div`
  flex: 3;
  padding-left: 10px;
`;

export const ModalButtonArea = styled.div`
  display: flex;
  width: 40%;
  height: 70px;
  float: right;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`;

export const ModalExitButton = styled.button`
  width: 80px;
  height: 40px;
  margin: 0 20px;
  border-radius: 20px;
  color: #fff;
  text-decoration: none;
  background-color: #e74c3c;
`;

export const ModalConfirmButton = styled.button`
  width: 80px;
  height: 40px;
  margin: 0 20px;
  border-radius: 20px;
  color: #fff;
  background-color: rgb(0, 135, 237);
`;

export const ModalPagingNav = styled.div`
  width: 100%;
  height: 26px;
  padding: 3px;
  text-align: center;
`;

export const ModalPagingLink = styled.div`
  display: inline-block;
  width: 12px;
  height: 100%;
  margin: 0 3px;

  &:hover {
    cursor: pointer;
  }
`;

export const ModalPagingLinkImg = styled.img``;

export const ModalPagingNumArea = styled.ul`
  display: inline-block;
  height: 100%;
  margin: 0 15px;
  text-align: center;
`;

export const ModalPagingNum = styled.li<{ current: boolean }>`
  display: inline-block;
  margin: 0 3px;
  font-weight: bold;
  color: ${(props) => (props.current ? 'blue' : 'default')};
  padding: 2px 6px;
  border: 1px solid #f0f0f0;

  &:hover {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
`;

export const ModalAddAgencyRow = styled.div`
  display: block;
  width: 50%;
  margin: 30px auto;
`;

export const ModalAddAgencyTitle = styled.h3`
  margin: 19px 0 8px;
  font-size: 14px;
  font-weight: 700;
`;

export const ModalAddAgencyTextInput = styled.input`
  display: block;
  position: relative;
  width: 100%;
  height: 50px;
  border: 0;
  border-radius: 20px;
  padding-left: 20px;
  font-size: 15px;
  color: #c9c9c9;
  box-sizing: border-box;
  background-color: #f0f0f0;
`;

export const ModalAddAgencyCfBtnArea = styled.div`
  display: block;
  width: 45%;
  height: 50px;
  margin: 40px auto;
`;

export const ModalAddAgencyConfirmBtn = styled.button`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 17px;
  font-weight: bold;
  color: white;
  background-color: #0087ed;
  border: 1px solid #0087ed;
  border-radius: 20px;
`;

export const TemporaryAgencyContent = styled.div`
  display: inline-flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space;
  border-radius: 5px;
  cursor: pointer;
`;

export const TemporarySubmit = styled.div`
  display: block;
  width: 80%;
  margin: 40px auto;
  text-align: center;
`;

export const TemporaryConfirmButton = styled.button`
  width: 100%;
  height: 40px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  border-radius: 15px;
  background-color: #0087ed;
  border: 2px solid #0087ed;
`;

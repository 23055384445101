import * as s from '../UpdateEduStyled';

interface SelectTypeProps {
  option: {
    holder: string;
    edu: string;
    type: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
  typeList: any;
}

const SelectType = (props: SelectTypeProps) => {
  return (
    <s.SelectArea>
      <s.SelectLabel>프로그램 유형 선택</s.SelectLabel>
      <s.SelectDiv></s.SelectDiv>
      <s.SelectForm selectType={'type'}>
        {props.typeList &&
          Object.entries(props.typeList).map(([key, value]) => {
            return (
              <s.SelectItem
                key={'type' + key}
                active={props.typeList[key].comnCd === props.option.type}
                selectType={'type'}
                onClick={() => props.onChangeOption('type', props.typeList[key].comnCd)}
              >
                {props.typeList[key].comnCdNm}
              </s.SelectItem>
            );
          })}
      </s.SelectForm>
    </s.SelectArea>
  );
};

export default SelectType;

import React, { Dispatch, SetStateAction } from 'react';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { RouteComponentProps } from 'react-router-dom';

import BeatLoader from 'react-spinners/BeatLoader';
import Media from './component/Media';
import * as s from './RegisterMultiStyled';

interface RegisterMultiProps extends RouteComponentProps {
  option: {
    holder: string;
    subMenu: string;
    type: string;
    field: string;
    visible: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
  setIsModifying: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  programMedias: {
    thumbnail?: File;
    file?: File;
    description: string;
  }[];
  onChangeProgramMedia: (index: number, name: string, value: string | File) => void;
  addProgramMidea: () => void;
  deleteProgramMidea: (index: number) => void;
}

const RegisterMultiPresenter = (props: RegisterMultiProps) => {
  return (
    <>
      <s.UploadProgramAreaHeader>
        <s.UploadProgramAreaInputArea>
          <s.UploadProgramAreaHeaderLabel>제목</s.UploadProgramAreaHeaderLabel>
          <s.UploadProgramAreaHeaderInput
            value={props.title}
            onChange={(e) => props.setTitle(e.target.value)}
            placeholder={'텍스트를 입력해주세요.'}
            disabled={!props.isModifying}
          />
        </s.UploadProgramAreaInputArea>
      </s.UploadProgramAreaHeader>
      {props.programMedias.map(
        (
          item: {
            thumbnail?: File;
            file?: File;
            description: string;
          },
          index: number,
        ) => (
          <Media
            programMedia={item}
            onChangeProgramMedia={(name: string, value: File | string) =>
              props.onChangeProgramMedia(index, name, value)
            }
            deleteProgramMedia={() => props.deleteProgramMidea(index)}
          />
        ),
      )}
      <s.ButtonArea>
        <s.SubmitButton onClick={props.addProgramMidea}>
          <s.SubmitButtonText>영상추가</s.SubmitButtonText>
        </s.SubmitButton>
      </s.ButtonArea>
    </>
  );
};

export default RegisterMultiPresenter;

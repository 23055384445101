import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as recoilItem from '../../../util/recoilitem';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useDropzone } from 'react-dropzone';

import RegisterActsheetPresenter from './RegisterActsheetPresenter';
import { programApi } from '../../../api/api-program';
import { menuApi } from '../../../api/api-menu';
import { makeMsg } from '../../../util/util';

interface RegisterActsheetProps extends RouteComponentProps {
  option: {
    age: string | null;
    holder: string;
    topMenu: string;
    subMenu: string;
    type: string;
    field: string;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      holder: string;
      topMenu: string;
      subMenu: string;
      type: string;
      field: string;
    }>
  >;
  ageAuths: any;
  holderList: any;
}

const RegisterActsheetContainer = (props: RegisterActsheetProps) => {
  const token = useRecoilValue(recoilItem.token);
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: (files) => {
      setDescriptionList(
        files.map((file: File, index: number) => ({
          fileName: file.name,
          fileIndex: index,
          text: '',
        })),
      );
      setSubmitFileList(files);
    },
  });
  const [isModifying, setIsModifying] = useState(true);
  const [descriptionList, setDescriptionList] = useState<
    { fileName: string; fileIndex: number; text: string }[]
  >([]);

  const [fieldList, setFieldList] = useState<
    {
      menuCd: string;
      menuNm: string;
      viewOrder: number;
    }[]
  >([]);
  const [submitFileList, setSubmitFileList] = useState<File[]>([]);
  const [title, setTitle] = useState<string>('');
  const [isEduTool, setIsEduTool] = useState<boolean>(false);

  const getField = async () => {
    try {
      setIsLoading(true);
      await menuApi.getCd('MN03').then((res) => {
        if (res.data.rsltCd === '00') {
          setFieldList(
            res.data.ComnCd.subMenuList.map((item: any) => ({
              menuCd: item.menuCd,
              menuNm: item.menuNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    getField();
  }, []);

  const submit = async () => {
    try {
      setIsLoading(true);
      let formData: FormData = new FormData();
      if (!props.option.age) {
        makeMsg('연령을 선택해주세요.', 'error');
        return;
      }
      if (!props.option.subMenu) {
        makeMsg('분야를 선택해주세요.', 'error');
        return;
      }
      if (!props.option.field) {
        makeMsg('놀이를 선택해주세요.', 'error');
        return;
      }
      if (!props.option.holder) {
        makeMsg('월(호)를 선택해주세요.', 'error');
        return;
      }
      if (!title) {
        makeMsg('제목을 입력해주세요.', 'error');
        return;
      }
      formData.append('age', props.option.age);
      formData.append('topMenu', props.option.topMenu);
      formData.append('subMenu', props.option.subMenu);
      formData.append('field', props.option.field);
      formData.append('holder', props.option.holder);
      formData.append('title', title);
      formData.append('isEduTool', isEduTool ? 'Y' : 'N');

      if (descriptionList.length === 0) {
        makeMsg('내용을 입력해주세요.', 'error');
        return;
      }

      formData.append('thumbnail.file', submitFileList[0]);

      descriptionList.forEach((item, index) => {
        formData.append(`programImages[${index}].title`, item.text);
        formData.append(`programImages[${index}].file.file`, submitFileList[index]);
      });

      await programApi.createProgram(token, formData).then((res) => {
        console.log(res);
        if (res.data.rsltCd === '00') {
          makeMsg('업로드에 성공하였습니다.', 'success');
          setIsLoading(false);
          props.history.push('/registerSelect');
        } else {
          makeMsg('업로드에 실패하였습니다.', 'error');
          setIsLoading(false);
        }
      });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <RegisterActsheetPresenter
      {...props}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      open={open}
      acceptedFiles={acceptedFiles}
      isDragActive={isDragActive}
      isModifying={isModifying}
      setIsModifying={setIsModifying}
      submitFileList={submitFileList}
      fieldList={fieldList}
      descriptionList={descriptionList}
      setDescriptionList={setDescriptionList}
      submit={submit}
      title={title}
      setTitle={setTitle}
      isEduTool={isEduTool}
      setIsEduTool={setIsEduTool}
    />
  );
};

export default RegisterActsheetContainer;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1091px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const SoundHeader = styled.div`
  width: 1091px;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 25px 0 10px;
  vertical-align: middle;
`;

export const SoundHeaderTitleArea = styled.div`
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SoundHeaderTitle = styled.div`
  float: left;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #4d4d4d;
`;
export const SoundHeaderTitleDivide = styled.div`
  float: left;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  padding-top: 3px;
  margin: 0 10px;
  color: #e2e2e2;
`;

export const SoundHeaderSubTitle = styled.div`
  float: left;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  padding-top: 14px;
  margin-left: 20px;
  color: #e2e2e2;
`;

export const SoundHeaderRightArea = styled.div`
  float: right;
  padding: 10px 0;
  text-align: center;
  vertical-align: middle;
`;
export const SoundHeaderRightIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 5px;
  vertical-align: middle;
`;

export const SoundHeaderRightText = styled.div`
  font-weight: 400;
  color: #4d4d4d;
  vertical-align: middle;
`;

export const SoundTabArea = styled.ul`
  width: auto;
  height: auto;
  margin: 30px auto 0;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SoundTab = styled.li<{ active: boolean }>`
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  width: 240px;
  height: 69px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: ${(props) => (props.active ? 800 : 400)};
  cursor: pointer;
  background-color: ${(props) => (props.active ? '#0087ed' : '#fff')};
  color: ${(props) => (props.active ? '#fff' : '#4d4d4d')};
  border: 1px solid #cecece;
  border-left: 0px;
  &:first-of-type {
    border: 1px solid #cecece;
  }
  &:after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-width: 0;
    border-top-color: ${(props) => (props.active ? '#0087ed' : 'transparent')};
    top: 67px;
  }
  &:hover {
    background-color: #0087ed;
    color: #fff;
    font-weight: 800;
  }
`;

export const SelectArea = styled.div`
  width: 1091px;
  margin: 50px auto 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SelectDiv = styled.div`
  width: 2.5%;
  margin: 20px auto;
  text-align: center;
  border-bottom: 1px solid;
`;

export const SelectLabel = styled.div`
  margin: 0px auto;
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const SelectForm = styled.ul`
  display: flex;
  width: 90%;
  margin: 20px auto 0;
  text-align: center;
  justify-content: center;
  flex-flow: wrap;
  white-space: nowrap;
`;

export const SelectItem = styled.li<{ active: boolean; selectType: string }>`
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Gosanja';
  font-size: 20px;
  width: ${(props) => (props.selectType === 'holder' ? '122px' : '140px')};
  color: ${(props) => (props.active ? '#fff' : '#9d9d9d')};
  border: 1px solid ${(props) => (props.active ? '#3A96CF' : '#ccc')};
  border-radius: 30px;
  margin: ${(props) => (props.selectType === 'holder' ? '10px 10px' : '10px 5px')};
  background: ${(props) =>
    props.active ? 'linear-gradient(to bottom,#59A9DA 95%, #59A9DA 5px,  #3B89C9 5%)' : '#fff'};
  cursor: pointer;
`;

export const ProgramListArea = styled.div`
  width: 1091px;
  margin: 0 auto;
  text-align: center;
`;

export const ProgramTypeTitleArea = styled.div`
  width: 100%;
  margin: 10px auto 0;
  height: auto;
  border-bottom: 1px solid #0087ed;
  padding: 10px 0 10px;
  vertical-align: baseline;
  display: flex;
  justify-content: space-between;
`;

export const ProgramTypeTitleFixedPart = styled.div`
  font-size: 18px;
  display: inline-block;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 5px 3px;
  color: #0087ed;
`;

export const ProgramTypeTitlePart = styled.div`
  font-size: 24px;
  display: inline-block;
  line-height: 32px;
  font-weight: 800;
  margin: 0 3px;
  color: #0087ed;
`;

export const ProgramSearchTextArea = styled.div`
  line-height: 35px;
  width: 220px;
  border: 1.5px solid #0087ed;
  float: right;
`;

export const ProgramSearchText = styled.input`
  width: 180px;
  height: 35px;
  padding-left: 10px;
  border: none;
`;

export const ProgramSearchButton = styled.div`
  display: inline-block;
  width: 35px;
  height: 35px;
  vertical-align: middle;
  cursor: pointer;
`;

export const ProgramSearchImg = styled.img`
  width: 30px;
  height: 30px;
  vertical-align: baseline;
  margin: 2px;
`;

export const ProgramList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 1091px;
  margin: 0px auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
`;

export const ProgramListHeader = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  font-size: 18px;
  color: #4D4D4D;
  font-weight: 500;
  align-items: center;
  justify-content: flex-start;
  background-color: #eee;
  border-bottom: 1px solid #aaa;
`;

export const ProgramListSelect = styled.div`
  width: 5%;
  padding: 0 5px;
`;

export const ProgramListCenterCol = styled.div`
  flex: 1;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

export const ProgramListStartCol = styled.div`
  margin-left: 114px;
  flex: 4;
  padding: 5px;
  text-align: start;
`;

export const ProgramCheckBox = styled.input``;

export const ProgramForm = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  font-size: 20px;
  color: #5f5f5f;
  font-weight: 500;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #aaa;
`;

export const ProgramTitle = styled.div`
  width: 100%;
  font-size: 18px;
  color: #4d4d4d;
  cursor: pointer;
`;

export const ProgramInfoAraa = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
`;
export const ProgramInfoText = styled.div`
  width: auto;
  text-align: center;
  color: #A4A4A4;
  font-size: 18px;
`;
export const ProgramInfoSeparate = styled.div`
  width: 1px;
  height: 10px;
  background-color: #A4A4A4;
  margin: 0 10px;
`;
export const ProgramListSmallIcon = styled.img<{ hoverImgUrl?: string | undefined }>`
  width: 34px;
  height: 25px;
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    content: ${(props) => (props.hoverImgUrl ? `url(${props.hoverImgUrl})` : '')};
  }
`;
export const ProgramListIcon = styled.img<{ hoverImgUrl?: string | undefined }>`
  width: 30px;
  aspect-ratio: 1/1;
  cursor: pointer;
  &:hover {
    content: ${(props) => (props.hoverImgUrl ? `url(${props.hoverImgUrl})` : '')};
  }
`;

export const PagingNav = styled.div`
  width: 100%;
  height: 40px;
  padding: 3px;
  margin-top: 30px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 50px;
`;

export const PagingLink = styled.div`
  display: inline-block;
  width: 12px;
  height: 100%;
  margin: 0 3px;

  &:hover {
    cursor: pointer;
  }
`;

export const PagingLinkImg = styled.img``;

export const PagingNumArea = styled.ul`
  display: inline-block;
  height: 100%;
  margin: auto 15px;
  font-size: 17px;
  color: #7e7e7e;
  text-align: center;
  vertical-align: middle;
`;

export const PagingNum = styled.li<{ current: boolean }>`
  display: inline-block;
  margin: 5.5px 2px;
  font-weight: ${(props) => (props.current ? '800' : '600')};
  color: ${(props) => (props.current ? '#4d4d4d' : 'default')};
  padding: 2px 6px;

  &:hover {
    cursor: pointer;
    color: #4d4d4d;
    text-decoration: underline;
    text-decoration-color: #4d4d4d;
  }
`;

export const ModalBackContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 9990;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(100, 100, 100, 0.5);
`;

export const ModalContainer = styled.div`
  width: 550px;
  min-height: 300px;
  margin: 0 auto;
  z-index: 9991;
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(45, 45, 45, 0.7);
  border-radius: 20px;
  padding: 20px 50px;
`;

export const TitleArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 40px;
  margin: 0 10px;
`;
export const TitleFull = styled.div`
  flex: 1;
  background-color: #fff;
  font-family: 'Gosanja';
  font-size: 24px;
  white-space: normal;
  padding: 10px;
  border-radius: 10px;
`;
export const ContentArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  min-height: 100px;
  margin-top: 20px;
`;

export const ContentImage = styled.img`
  width: 90%;
  height: auto;
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-height: 50px;
  margin-top: 20px;
  flex-wrap: wrap;
`;

export const WhiteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 42px;
  border-radius: 40px;
  border: 1px solid #0087ed;
  color: #2c2c2c;
  background-color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const BlueButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 42px;
  border-radius: 40px;
  border: 1px solid #0087ed;
  color: #fff;
  background-color: #0087ed;
  font-size: 15px;
  font-weight: 800;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const DownloadBtnIcon = styled.img`
  width: 18px;
  height: 16px;
  margin: 0px 5px;
`;

export const ProgramScrapLink = styled.div`
  display: flex;
  width: 128px;
  height: 44px;
  border-radius: 25px;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  border: 1px solid #6db3df;
`;




export const SearchConditionArea = styled.div`
  width: 1091px;
  height: auto;
  margin: 20px auto 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
`;

export const searchConditionHeader = styled.div`
  width: 150px;
  display: flex;
  background-color: #F3F3F3;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #4D4D4D;
`;

export const SearchConditionBody = styled.div`
  width: calc(100% - 150px);
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
`;

export const SearchConditionItem = styled.div<{selected: boolean}>`
  margin: 5px;
  padding: 0px 10px 0px 10px;
  font-size: 16px;
  color: ${(props) => props.selected ? '#0087ED' : '#4D4D4D'};
  border-radius: 20px;
  border: ${(props) => props.selected ? '1px solid #0087ED' : '0px solid #0087ED'};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  cursor: pointer;
`;

export const SearchConditionAgeArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const SearchConditionMonthArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 0.5px solid #AAAAAA;
`;

export const SearchConditionMediaArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 0.5px solid #AAAAAA;
`;

import React, { Dispatch, SetStateAction } from 'react';
import { SetterOrUpdater } from 'recoil';
import { MdClose } from 'react-icons/md';

import * as s from './LoadModalStyled';

const check = '/static/img/check.png';
const lens = '/static/img/search-icon.png';
const burgerMenu = '/static/img/burgerMenu.png';
const leftArrow = '/static/img/L_arrow.png';
const rightArrow = '/static/img/R_arrow.png';

interface LoadModalProps {
  ageAuths: any;
  setIsOpenLoadModal: (arg0: boolean) => void;
  age: any;
  setAge: Dispatch<SetStateAction<any>>;
  year: any;
  setYear: Dispatch<SetStateAction<any>>;
  month: any;
  setMonth: Dispatch<SetStateAction<any>>;
  day: any;
  setDay: Dispatch<SetStateAction<any>>;

  ageList: any[];
  monthList: any[];
  yearList: any[];
  dayList: any[];
  onSave: () => void;
}

const LoadModalPresenter = (props: LoadModalProps) => {
  return (
    <s.Container>
      <s.ModalContainer>
        <s.CloseButton onClick={() => props.setIsOpenLoadModal(false)}>
          <MdClose size={'100%'} color={'#4d4d4d'} />
        </s.CloseButton>
        <s.SelectTable>
          <s.SelectTbody>
            <s.SelectTr>
              <s.SelectTh>연령</s.SelectTh>
              <s.SelectTd>
                <s.SelectTableSelect
                  value={props.age}
                  onChange={(e) => props.setAge(e.target.value)}
                >
                  <option value="">선택</option>
                  {props.ageList.map((age: any) => (
                    <option value={age.cd}>{age.nm}</option>
                  ))}
                </s.SelectTableSelect>
              </s.SelectTd>
            </s.SelectTr>
            <s.SelectTr>
              <s.SelectTh>연도</s.SelectTh>
              <s.SelectTd>
                <s.SelectTableSelect
                  value={props.year}
                  onChange={(e) => props.setYear(Number(e.target.value))}
                >
                  <option value="">선택</option>
                  {props.yearList.map((year: any) => (
                    <option value={year}>{year}</option>
                  ))}
                </s.SelectTableSelect>
              </s.SelectTd>
            </s.SelectTr>
            <s.SelectTr>
              <s.SelectTh>월</s.SelectTh>
              <s.SelectTd>
                <s.SelectTableSelect
                  value={props.month}
                  onChange={(e) => props.setMonth(Number(e.target.value))}
                >
                  <option value="">선택</option>
                  {props.monthList.map((month: any) => (
                    <option value={month}>{month}</option>
                  ))}
                </s.SelectTableSelect>
              </s.SelectTd>
            </s.SelectTr>
            <s.SelectTr>
              <s.SelectTh>일</s.SelectTh>
              <s.SelectTd>
                <s.SelectTableSelect
                  value={props.day}
                  onChange={(e) => props.setDay(e.target.value)}
                >
                  <option value="">선택</option>
                  {props.dayList.map((day: any) => (
                    <option value={day}>{day}</option>
                  ))}
                </s.SelectTableSelect>
              </s.SelectTd>
            </s.SelectTr>
          </s.SelectTbody>
        </s.SelectTable>
        <s.ContentConfirmButtonArea>
          <s.ContentConfirmButton onClick={() => props.setIsOpenLoadModal(false)}>
            닫기
          </s.ContentConfirmButton>
          <s.ContentConfirmButton onClick={() => props.onSave()}>저장하기</s.ContentConfirmButton>
        </s.ContentConfirmButtonArea>
      </s.ModalContainer>
    </s.Container>
  );
};

export default LoadModalPresenter;

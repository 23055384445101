import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as recoilItem from '../../util/recoilitem';
import { useRecoilValue } from 'recoil';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const isLoading = useRecoilValue(recoilItem.isLoading);
  const [loadingImageSrc, setLoadingImageSrc] = useState('/static/img/loading0.gif');

  useEffect(() => {
    makeRandomLoadingImage();
  }, []);

  const makeRandomLoadingImage = () => {
    let index = Math.random()%6; // 0~5
    setLoadingImageSrc(`/static/img/loading${index}.gif`);
  }

  return !isLoading ? null : (
    <Container>
      <LoaderContainer>
        {
          ((new Date()).getSeconds()) % 6 === 0 ?
          <LoaderGif src={'/static/img/loading0.gif'} />
          : ((new Date()).getSeconds()) % 6 === 1 ?
          <LoaderGif src={'/static/img/loading1.gif'} />
          : ((new Date()).getSeconds()) % 6 === 2 ?
          <LoaderGif src={'/static/img/loading2.gif'} />
          : ((new Date()).getSeconds()) % 6 === 3 ?
          <LoaderGif src={'/static/img/loading3.gif'} />
          : ((new Date()).getSeconds()) % 6 === 4 ?
          <LoaderGif src={'/static/img/loading4.gif'} />
          :
          <LoaderGif src={'/static/img/loading5.gif'} />
        }
        
      </LoaderContainer>
    </Container>
  );
};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 9999;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(100, 100, 100, 0.5);
`;

export const LoaderContainer = styled.div`
  width: auto;
  height: auto;
  margin: 0 auto;
  z-index: 10001;
  text-align: center;
  padding: 20px 0;
  overflow: auto;
  transform: skew(-0.1deg);
`;

export const LoaderGif = styled.img`
  width: 140px;
  height: auto;
`;

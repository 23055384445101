import styled from 'styled-components';

export const Container = styled.div`
  width: 1091px;
  margin: 0 auto;
  text-align: center;
`;
export const PlanningDiv = styled.div``;
export const PlanNotFoundText = styled.div<{color: string}>`
  display: flex;
  margin: 100px auto;
  color: ${props => props.color};
  font-size: 20px;
  display: inline-block;
  line-height: 32px;
  font-weight: 800;
`;
export const PlanningTitleArea = styled.div<{color: string}>`
  width: 100%;
  margin: 20px auto 0;
  height: auto;
  border-bottom: 1px solid ${props => props.color};
  padding: 0 0 10px;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: space-between;
`;

export const PlanningTitle = styled.div`
  font-size: 18px;
  display: inline-block;
  line-height: 32px;
  font-weight: 500;
`;

export const PlanningTitleSpan = styled.span<{color: string}>`
  color: ${props => props.color};
`;

export const TopContentsArea = styled.div<{color: string}>`
  width: 100%;
  height: auto;
  border-bottom: 1px solid ${props => props.color};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TopContentsLeft = styled.div`
  height: auto;
  padding: 20px;
  display: inline-block;
  margin-right: 50px;
`;

export const TopContentsLeftIcon = styled.img`
  width: 222px;
  height: 222px;
  margin: 0 auto 20px;
`;

export const PlanningDownloadBtn = styled.div<{color: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: end;
  border-radius: 5px;
  border: 1px solid ${props => props.color};
  width: 191px;
  height: 27px;
  cursor: pointer;
`;

export const DownloadBtnIcon = styled.img`
  width: 16px;
  height: 14px;
  display: inline-block;
  margin: 0px 3px;
`;

export const PlanningDownloadText = styled.div`
  display: inline-block;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: #4d4d4d;
  vertical-align: bottom;
`;
export const TopContentsRight = styled.div`
  height: auto;
  padding: 30px 0 0;
  display: inline-block;
  text-align: left;
  vertical-align: top;
`;

export const TopContentsRow = styled.div`
  display: flex;
  border-top: 1px solid #DEDEDE;
  width: 100%;
  padding-bottom: 15px;
`

export const TopContentHorizontalSection = styled.div`
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 15px;
`

export const TopContentHeaderBox = styled.div`
  height: 24px;
  padding-left: 15px;
  padding-right: 15px;
  min-width: 83px;
  flex-basis: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #4D4D4D;
  background-color: #EEEEEE;
  border-radius: 5px;
`
export const TopContentTextArea = styled.div`
  font-size: 14px;
  color: #4D4D4D;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 24px;
  max-width: 930px;
  justify-content: center;
  align-items: center;
  text-align: left;
  line-height: 24px;
`;


export const TopRightTitle = styled.div`
  font-family: 'Gosanja';
  font-size: 20px;
  background: #ff8f42;
  border-radius: 5px;
  color: #fff;
  padding: 5px 20px;
  margin-right: 20px;
  white-space: nowrap;
`;

export const TopRightText = styled.div`
  color: #4d4d4d;
  font-size: 26px;
  vertical-align: middle;
  word-break: break-all;
  font-weight: 500;
`;

export const TopRightLineHeightText = styled.div`
  color: #4d4d4d;
  font-size: 26px;
  vertical-align: middle;
  word-break: break-all;
  font-weight: 500;
  line-height: 39px;
`;

export const TopRightSmallText = styled.div`
  color: #4d4d4d;
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  word-break: keep-all;
  font-weight: 400;
  line-height: 1.6;
`;

export const TopRightMultipleText = styled.pre`
  font-family: 'Noto Sans KR';
  color: #4d4d4d;
  font-size: 20px;
  display: inline-block;
  word-break: break-all;
  font-weight: 400;
  line-height: 1.6;
  overflow: auto;
  margin: 0;
`;
export const TableIconInfoArea = styled.div`
  width: 100%;
  padding: 15px;
  text-align: left;
`;

export const TableIconInfoImg = styled.img`
  width: 22px;
  height: 22px;
  display: inline-block;
`;

export const TableIconInfoText = styled.div`
  vertical-align: middle;
  font-size: 12px;
  font-weight: 400;
  color: #4d4d4d;
  padding: 0px 25px 0px 5px;
  display: inline-block;
`;

export const TableTextArea = styled.div<{ col: number }>`
  width: auto;
  display: inline-block;
  text-align: left;
  font-size: ${(props) => (props.col >= 2 ? '15px' : '14px')};
`;

export const WeeklyPlanTable = styled.div`
  margin: 10px auto 0 auto;
  width: 100%;
  border: none;
  *border-collapse: collapse; /* IE7 and lower */
  border-spacing: 0;
`;

export const WeeklyPlanTableImg = styled.img<{ marginLeft?: number }>`
  width: 26px;
  height: 26px;
  display: inline-block;
  margin: ${(props) => (props.marginLeft ? `0 10px 5px ${props.marginLeft}px` : '0 10px 5px 0')};
`;

export const WeeklyPlanTableHead = styled.div``;

export const WeeklyPlanTableHeadRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const WeeklyPlanTableHeadHeader = styled.div<{color: string}>`
  flex: 1;
  height: 82px;
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.color};
  border-right: 1px solid #ddd;

  &:first-of-type {
    border-top-left-radius: 15px;
  }
  &:last-of-type {
    border-top-right-radius: 15px;
  }
`;

export const GridLayoutWrapper = styled.div`
  width: 100%;
  display: inline-block;
  background: transparent;
  border: none;
  *border-collapse: collapse; /* IE7 and lower */
  border-spacing: 0;
`;

export const GridTable = styled.table`
  width: 100%;
  border-spacing: 0px;
  margin-bottom: 50px;
`;

export const GridTr = styled.tr`
  width: 100%;
  border: 0;
  cellspacing: 0;
  cellpadding: 100%;
`;

export const GridTdHeader = styled.td<{color: string}>`
  width: 91px;
  height: 44px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background-color: ${props => props.color};
  border-right: 1px solid #ddd;
  text-align: center;
`;

export const GridTd = styled.td<{colorDiv: string, isCenterAlign: boolean}>`
  width: 91px;
  min-height: 43px;
  border-bottom: 1px solid #ddd;
  border-top: transparent;
  border-right: 1px solid #ddd;
  border-spacing: 0px;
  padding: 15px 5px 15px 5px;
  vertical-align: middle;
  margin: 0 ${(props) => (props.isCenterAlign ? '4px' : '10px')};
  background: ${(props => (props.colorDiv === 'G' ? '#EFEFEF' : props.colorDiv === 'B' ? '#BADFFF' : props.colorDiv === 'LB' ? '#E4F2FF' : props.colorDiv === 'Y' ? '#FFE4AA' : props.colorDiv === 'LY' ? '#FEEAC5' : 'transparent'))};
  cursor: unset;
`;

export const GridTdTextDot = styled.div`
  width: 3px;
  height: 3px;
  background-color: #4D4D4D;
  display: inline-block;
  margin-right: 3px;
  border-radius: 10px;
  vertical-align: middle;
`

export const GridTdText = styled.div<{
  isBold: boolean;
  color: string | null;
  isTextCenter: boolean;
  disabled: boolean;
}>`
  font-family: 'NanumSquareRound';
  font-weight: ${(props) => (props.isBold ? 'bold' : '600')};;
  font-size: ${(props) => (props.isBold ? '14px' : '14px')};
  color: ${(props) => (props.isBold ? '#4D4D4D' : props.color ? props.color : '#ABABAB')};
  text-align: center;
  display: inline-block;
  margin: 2px;
  

  ${(props) => (props.disabled ?
    ``
  : `
    &:hover {
      opacity: 0.5;
    }
    cursor: pointer;
  `)}
`;




export const GridLayoutDiv = styled.div<{ isFirst: boolean; isLast: boolean }>`
  border: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ddd;
  border-top: transparent;
  border-left: ${(props) =>
    props.isFirst ? `10px solid ${props.color}` : '1px solid transparent'};
  border-right: 1px solid #ddd;
`;

export const GridButton = styled.button<{ isCenterAlign: boolean }>`
  border: 1px solid transparent;
  display: flex;
  flex-direction: ${(props) => (props.isCenterAlign ? 'row' : 'column')};
  align-items: ${(props) => (props.isCenterAlign ? 'center' : 'flex-start')};
  align-self: ${(props) => (props.isCenterAlign ? 'center' : 'flex-start')};
  margin: 0 ${(props) => (props.isCenterAlign ? '4px' : '10px')};
  background: transparent;

  transition: 0.25s all;

  cursor: unset;

  :not(:disabled) {
    &:hover {
      opacity: 0.5;
    }
    cursor: pointer;
  }
`;

export const GridTextHeader = styled.div<{ color: string | null }>`
  font-family: 'Gosanja';
  font-size: 20px;
  color: ${(props) => (props.color ? props.color : '#4d4d4d')};
  align-self: center;
  margin-bottom: 2px;
`;

export const GridTextStyle = styled.div<{
  isBold: boolean;
  color: string | null;
  isTextCenter: boolean;
  col?: number;
}>`
  font-family: 'NanumSquareRound';
  font-weight: 700;
  font-size: ${(props) => (props.isBold ? '26px' : '20px')};
  max-width: ${(props) => (props.col ? `${props.col * 180}px` : '100%')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${(props) => (props.col ? 'nowrap' : 'normal')};
  color: ${(props) => (props.isBold ? props.color : '#4d4d4d')};
  text-align: ${(props) => (props.isBold || props.isTextCenter ? 'center' : 'left')};
`;

export const GridBigTextStyle = styled.div`
  font-family: 'Gosanja';
  font-size: 26px;
  color: #fd0101;
  text-align: 'center';
`;

export const GridImageWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;

export const GridImage = styled.img`
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 1px 2px;
`;

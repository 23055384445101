import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as recoilItem from '../../../util/recoilitem';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useDropzone } from 'react-dropzone';

import RegisterHomeletterPresenter from './RegisterHomeletterPresenter';
import { programApi } from '../../../api/api-program';
import { menuApi } from '../../../api/api-menu';
import { makeMsg } from '../../../util/util';

interface RegisterHomeletterProps extends RouteComponentProps {
  option: {
    age: string | null;
    holder: string;
    topMenu: string;
    subMenu: string;
    type: string;
    field: string;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      holder: string;
      topMenu: string;
      subMenu: string;
      type: string;
      field: string;
    }>
  >;
  ageAuths: any;
  holderList: any;
}

const RegisterHomeletterContainer = (props: RegisterHomeletterProps) => {
  const token = useRecoilValue(recoilItem.token);
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files) => {
      setSubmitFileList(files);
    },
  });
  const [isModifying, setIsModifying] = useState(true);

  const [subMenuList, setSubMenuList] = useState<
    {
      menuCd: string;
      menuNm: string;
      viewOrder: number;
    }[]
  >([]);
  const [submitFileList, setSubmitFileList] = useState<File[]>([]);
  const [title, setTitle] = useState<string>('');
  const [isUrlUpload, setIsUrlUpload] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>('');

  const getSubMenu = async () => {
    try {
      await menuApi.getCd('MN04').then((res) => {
        if (res.data.rsltCd === '00') {
          setSubMenuList(
            res.data.ComnCd.subMenuList
              .filter(
                (item: any) =>
                  item.menuCd === 'MN0404' || item.menuCd === 'MN0405' || item.menuCd === 'MN0407',
              )
              .map((item: any) => ({
                menuCd: item.menuCd,
                menuNm: item.menuNm,
                viewOrder: item.viewOrder,
              })),
          );
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getSubMenu();
  }, []);

  const submit = async () => {
    try {
      setIsLoading(true);
      let formData: FormData = new FormData();
      if (!props.option.age) {
        makeMsg('연령을 선택해주세요.', 'error');
        return;
      }
      if (!props.option.subMenu) {
        makeMsg('분야를 선택해주세요.', 'error');
        return;
      }
      if (!props.option.holder) {
        makeMsg('월(호)를 선택해주세요.', 'error');
        return;
      }
      if (!title) {
        makeMsg('제목을 입력해주세요.', 'error');
        return;
      }
      formData.append('age', props.option.age);
      formData.append('topMenu', props.option.topMenu);
      formData.append('subMenu', props.option.subMenu);
      formData.append('holder', props.option.holder);
      formData.append('title', title);
      formData.append('isEduTool', 'N');

      if (isUrlUpload) {
        if (!fileUrl) {
          makeMsg('URL을 입력하세요.', 'error');
          setIsLoading(false);
          return;
        } else {
          formData.append('programDocuments[0].documentUrl', fileUrl);
          formData.append('thumbnail.file', new Blob());
        }
      } else {
        formData.append('programDocuments[0].title', title);
        formData.append('programDocuments[0].file.file', submitFileList[0]);
        formData.append('thumbnail.file', submitFileList[0]);
      }

      await programApi.createProgram(token, formData).then((res) => {
        if (res.data.rsltCd === '00') {
          makeMsg('업로드에 성공하였습니다.', 'success');
          setIsLoading(false);
          props.history.push('/registerSelect');
        } else {
          makeMsg('업로드에 실패하였습니다.', 'error');
          setIsLoading(false);
        }
      });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <RegisterHomeletterPresenter
      {...props}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      open={open}
      acceptedFiles={acceptedFiles}
      isDragActive={isDragActive}
      isModifying={isModifying}
      setIsModifying={setIsModifying}
      submitFileList={submitFileList}
      subMenuList={subMenuList}
      submit={submit}
      title={title}
      setTitle={setTitle}
      isUrlUpload={isUrlUpload}
      setIsUrlUpload={setIsUrlUpload}
      fileUrl={fileUrl}
      setFileUrl={setFileUrl}
    />
  );
};

export default RegisterHomeletterContainer;

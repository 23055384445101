import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import RegisterMultiPresenter from './RegisterMultiPresenter';

interface RegisterMultiProps extends RouteComponentProps {
  option: {
    holder: string;
    subMenu: string;
    type: string;
    field: string;
    visible: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
  setIsModifying: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  programMedias: {
    thumbnail?: File;
    file?: File;
    description: string;
  }[];
  onChangeProgramMedia: (index: number, name: string, value: string | File) => void;
  addProgramMidea: () => void;
  deleteProgramMidea: (index: number) => void;
}

const RegisterMultiContainer = (props: RegisterMultiProps) => {
  return <RegisterMultiPresenter {...props} />;
};

export default RegisterMultiContainer;

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';

import GuideBookPresenter from './GuideBookPresenter';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { comnApi } from '../../api/api-comn';
import { menuApi } from '../../api/api-menu';
import { programApi } from '../../api/api-program';
import * as recoilItem from '../../util/recoilitem';
import * as util from '../../util/util';

interface GuideBookProps extends RouteComponentProps {}

const GuideBookContainer = (props: GuideBookProps) => {
  const token = useRecoilValue(recoilItem.token);
  const [programId, setProgramId] = useRecoilState(recoilItem.programId);

  const blockPageNum: string = '5';
  const pagePostCount: string = '15';

  const [ageList, setAgeList] = useState([]);
  const [holderList, setHolderList] = useState([]);

  const [selectedAge, setSelectedAge] = useState<{ comnCd: string; comnCdNm: string }>({
    comnCd: 'AUTH0101',
    comnCdNm: '1세',
  });

  const [selectedHolderList, setSelectedHolderList] = useState<
    { comnCd: string; comnCdNm: string }[]
  >([]);

  const [programList, setProgramList] = useState(null);

  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [pageNum, setPageNum] = useState(1);
  const [pageList, setPageList] = useState([]);
  const [isFirst, setIsFirst] = useState(false);
  const [lastPageNum, setLastPageNum] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [totalItemLength, setTotalItemLength] = useState(0);

  const onChangeAge = (item: any) => {
    setSelectedAge({ comnCd: item.comnCd, comnCdNm: item.comnCdNm });
    setPageNum(1);
  };

  const onChangeHolderList = (item: any) => {
    if (!item) {
      setSelectedHolderList([]);
      return;
    }
    const holder = { comnCd: item.comnCd, comnCdNm: item.comnCdNm };

    // let tempHolderList = [...selectedHolderList];

    // if (tempHolderList.map((item: any) => item.comnCd).includes(holder.comnCd)) {
    //   const idx: number = tempHolderList.map((item: any) => item.comnCd).indexOf(holder.comnCd);
    //   tempHolderList.splice(idx, 1);
    // } else {
    //   tempHolderList.push(holder);
    // }

    // setSelectedHolderList(tempHolderList);
    setSelectedHolderList([holder]);
    setPageNum(1);
  };

  const firstFetchData = async () => {
    setIsLoading(true);

    let res1: any = null;
    let res2: any = null;
    let res3: any = null;
    let res4: any = null;

    let formData: FormData = new FormData();

    formData.append('topMenu', 'MN04');
    formData.append('subMenu', 'MN0406');
    formData.append('age', selectedAge.comnCd);
    formData.append('page', pageNum.toString());
    formData.append('blockPageNum', blockPageNum);
    formData.append('pagePostCount', pagePostCount);

    try {
      res1 = await comnApi.getCd('AUTH01');
      res3 = await comnApi.getCd('HOL01');
      res4 = await programApi.filter(token, formData);

      console.log(res4);

      if (res1.data.rsltCd === '00') {
        setAgeList(res1.data.ComnCd.subCdList);
      }
      if (res3.data.rsltCd === '00') {
        setHolderList(res3.data.ComnCd.subCdList);
      }
      if (res4.data.rsltCd === '00') {
        let resultList = res4.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res4.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setProgramList(resultList);
        setIsFirst(res4.data.programList.first);
        setIsLast(res4.data.programList.last);
        setLastPageNum(res4.data.programList.totalPages);
        setPageList(res4.data.pageList);
        setTotalItemLength(res4.data.programList.totalElements);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const unableDownload = () => {
    util.makeMsg('해당하는 파일이 없습니다.', 'error');
  };

  const fetchData = async () => {
    setIsLoading(true);

    let res: any = null;

    let formData: FormData = new FormData();

    formData.append('topMenu', 'MN04');
    formData.append('subMenu', 'MN0406');
    formData.append('age', selectedAge.comnCd);
    formData.append('page', pageNum.toString());
    formData.append('blockPageNum', blockPageNum);
    formData.append('pagePostCount', pagePostCount);
    if (selectedHolderList.length > 0) {
      formData.append('holder', selectedHolderList.map((item: any) => item.comnCd).join(','));
    }

    try {
      res = await programApi.filter(token, formData);
      if (res.data.rsltCd === '00') {
        console.log(res);
        let resultList = res.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setProgramList(resultList);
        setIsFirst(res.data.programList.first);
        setIsLast(res.data.programList.last);
        setLastPageNum(res.data.programList.totalPages);
        setPageList(res.data.pageList);
        setTotalItemLength(res.data.programList.totalElements);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    firstFetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedAge, selectedHolderList, pageNum]);

  return (
    <>
      <Header {...props} />
      <GuideBookPresenter
        ageList={ageList}
        holderList={holderList}
        selectedAge={selectedAge}
        setSelectedAge={setSelectedAge}
        selectedHolderList={selectedHolderList}
        onChangeAge={onChangeAge}
        onChangeHolderList={onChangeHolderList}
        programList={programList}
        setProgramId={setProgramId}
        unableDownload={unableDownload}
        pageNum={pageNum}
        setPageNum={setPageNum}
        pageList={pageList}
        lastPageNum={lastPageNum}
        isFirst={isFirst}
        isLast={isLast}
      ></GuideBookPresenter>
      <Footer {...props} />
    </>
  );
};

export default GuideBookContainer;

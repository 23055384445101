import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import * as s from './SoundStyled';
import * as util from '../../util/util';
import NotFound from '../../components/notFound';

interface SoundProps {
  headerList: null | {
    ageList: never[];
    holderList: never[];
  };
  selectedAge: {
    comnCd: string;
    comnCdNm: string;
  };
  setSelectedAge: React.Dispatch<
    React.SetStateAction<{
      comnCd: string;
      comnCdNm: string;
    }>
  >;
  selectedHolderList: {
    comnCd: string;
    comnCdNm: string;
  }[];
  onChangeHolderList: (item: any) => void;
  onChangeAge: React.MouseEventHandler<HTMLLIElement>;
  programList: any;
  setProgramId: any;
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  pageList: never[];
  lastPageNum: number;
  isFirst: boolean;
  isLast: boolean;
  selectedIdxList: string[];
  setSelectedIdxList: React.Dispatch<React.SetStateAction<string[]>>;
  audioRef: any;
  audioUrl: string | undefined;
  setAudioUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
  audioPlaying: string | undefined;
  setAudioPlaying: React.Dispatch<React.SetStateAction<string | undefined>>;
  openModal:
    | {
        idx: number;
        type: string;
      }
    | undefined;
  setOpenModal: React.Dispatch<
    React.SetStateAction<
      | {
          idx: number;
          type: string;
        }
      | undefined
    >
  >;
}

const SoundPresenter = (props: SoundProps) => {
  const LyricsModal = () => {
    return (
      <s.ModalContainer>
        <s.TitleArea>
          <s.TitleFull>
            {props.programList[props.openModal ? props.openModal.idx : 0].title}
          </s.TitleFull>
        </s.TitleArea>
        <s.ContentArea>
          <s.ContentImage
            src={
              props.programList[props.openModal ? props.openModal.idx : 0].programSounds[0]
                .soundLyrics?.fileUrl
            }
          />
        </s.ContentArea>
        <s.ButtonArea>
          <s.BlueButton onClick={() => props.setOpenModal(undefined)}>닫기</s.BlueButton>
        </s.ButtonArea>
      </s.ModalContainer>
    );
  };
  const SheetModal = () => {
    return (
      <s.ModalContainer>
        <s.TitleArea>
          <s.TitleFull>
            {props.programList[props.openModal ? props.openModal.idx : 0].title}
          </s.TitleFull>
        </s.TitleArea>
        <s.ContentArea>
          <s.ContentImage
            src={
              props.programList[props.openModal ? props.openModal.idx : 0].programSounds[0]
                .soundSheet?.fileUrl
            }
          />
        </s.ContentArea>
        <s.ButtonArea>
          <s.BlueButton onClick={() => props.setOpenModal(undefined)}>닫기</s.BlueButton>
        </s.ButtonArea>
      </s.ModalContainer>
    );
  };

  return (
    <s.Container>
      {props.openModal ? (props.openModal.type === 'sheet' ? SheetModal() : LyricsModal()) : null}
      <ReactAudioPlayer style={{ display: 'none' }} ref={props.audioRef} src={props.audioUrl} />
      <s.SoundHeader>
        <s.SoundHeaderTitleArea>
          <s.SoundHeaderTitle>교수자료실</s.SoundHeaderTitle>
          <s.SoundHeaderTitleDivide>|</s.SoundHeaderTitleDivide>
          <s.SoundHeaderTitle>동요·음원</s.SoundHeaderTitle>
        </s.SoundHeaderTitleArea>
        <s.SoundHeaderRightArea>
          <s.SoundHeaderRightText>
            <s.SoundHeaderRightIcon src="/static/img/home_icon.jpg" />
            {'HOME > 교수자료실 > 동요·음원'}
          </s.SoundHeaderRightText>
        </s.SoundHeaderRightArea>
      </s.SoundHeader>

      <s.SearchConditionArea>
        <s.SearchConditionAgeArea>
          <s.searchConditionHeader>연령 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            {
              props.headerList?.ageList.map((item: any, index: number) => (
                <s.SearchConditionItem 
                  selected={item.comnCd === props.selectedAge.comnCd}
                  onClick={() => props.onChangeAge(item)}
                  >{'만 ' + item.comnCdNm}</s.SearchConditionItem>
              ))
            }
          </s.SearchConditionBody>
        </s.SearchConditionAgeArea>
        <s.SearchConditionMonthArea>
          <s.searchConditionHeader>월 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            <s.SearchConditionItem
              selected={props.selectedHolderList.length === 0}
              onClick={() => props.onChangeHolderList(null)}
            >
              전체
            </s.SearchConditionItem>
            {
              props.headerList?.holderList.map((item: any, index: number) => (
                <s.SearchConditionItem 
                  selected={props.selectedHolderList
                    .map((item: any) => item.comnCd)
                    .includes(item.comnCd)}
                  onClick={() => props.onChangeHolderList(item)}
                  >{item.comnCdNm}</s.SearchConditionItem>
              ))
            }
          </s.SearchConditionBody>
        </s.SearchConditionMonthArea>
      </s.SearchConditionArea>



      {/* <s.SoundTabArea>
        {props.headerList?.ageList.map((item: any, index: number) => (
          <s.SoundTab
            key={'age' + index}
            active={item.comnCd === props.selectedAge.comnCd}
            onClick={() => props.onChangeAge(item)}
          >
            {'만 ' + item.comnCdNm}
          </s.SoundTab>
        ))}
      </s.SoundTabArea>
      <s.SelectArea>
        <s.SelectLabel>월(호) 선택</s.SelectLabel>
        <s.SelectForm>
          <s.SelectItem
            key={'holder99'}
            active={props.selectedHolderList.length === 0}
            selectType={'holder'}
            onClick={() => props.onChangeHolderList(null)}
          >
            전체
          </s.SelectItem>
          {props.headerList?.holderList.map((item: any, index: number) => (
            <s.SelectItem
              key={'holder' + index}
              active={props.selectedHolderList
                .map((item: any) => item.comnCd)
                .includes(item.comnCd)}
              selectType={'holder'}
              onClick={() => props.onChangeHolderList(item)}
            >
              {item.comnCdNm}
            </s.SelectItem>
          ))}
        </s.SelectForm>
      </s.SelectArea> */}



      <s.ProgramListArea>
        <s.ProgramTypeTitleArea>
          <s.ProgramTypeTitleFixedPart>
            선택 ({props.selectedIdxList.length})
          </s.ProgramTypeTitleFixedPart>
        </s.ProgramTypeTitleArea>
        <s.ProgramList>
          <s.ProgramListHeader>
            <s.ProgramListSelect>
              <s.ProgramCheckBox
                type={'checkbox'}
                checked={
                  props.programList &&
                  props.programList.length > 0 &&
                  props.selectedIdxList.length === props.programList.length
                }
                onClick={(e) => {
                  if (
                    props.programList &&
                    props.programList.length > 0 &&
                    props.selectedIdxList.length === props.programList.length
                  ) {
                    props.setSelectedIdxList([]);
                  } else {
                    let tempList = props.programList.map((item: any) => item.programId);
                    props.setSelectedIdxList(tempList);
                  }
                }}
              />
            </s.ProgramListSelect>
            <s.ProgramListStartCol style={{textAlign: 'center'}}>곡정보</s.ProgramListStartCol>
            <s.ProgramListCenterCol>음원</s.ProgramListCenterCol>
            <s.ProgramListCenterCol>가사</s.ProgramListCenterCol>
            <s.ProgramListCenterCol>악보</s.ProgramListCenterCol>
          </s.ProgramListHeader>
          {!props.programList || props.programList.length === 0 ? (
            <NotFound />
          ) : (
            props.programList.map((item: any, index: number) => (
              <s.ProgramForm key={index}>
                <s.ProgramListSelect>
                  <s.ProgramCheckBox
                    type={'checkbox'}
                    checked={props.selectedIdxList.includes(item.programId)}
                    onClick={(e) => {
                      if (!item.programId) {
                        util.makeMsg('권한이 부족합니다.', 'error');
                      } else if (props.selectedIdxList.includes(item.programId)) {
                        let tempList = [...props.selectedIdxList];
                        tempList = tempList.filter((element) => element !== item.programId);
                        props.setSelectedIdxList(tempList);
                      } else {
                        let tempList = [...props.selectedIdxList];
                        tempList.push(item.programId);
                        props.setSelectedIdxList(tempList);
                      }
                    }}
                  />
                </s.ProgramListSelect>
                <s.ProgramListStartCol>
                  <s.ProgramTitle
                    onClick={(e) =>
                      !item.programId
                        ? util.makeMsg('권한이 부족합니다.', 'error')
                        : props.setProgramId(item.programId)
                    }
                  >
                    {item.title}
                    {item.programSounds &&
                    item.programSounds[0].soundVideos &&
                    item.programSounds[0].soundVideos.length > 0 ? (
                      <s.ProgramListSmallIcon
                        style={{ margin: '0 10px', alignSelf: 'center' }}
                        src={'/static/img/media.png'}
                      />
                    ) : null}
                  </s.ProgramTitle>
                  <s.ProgramInfoAraa>
                    {item.programSounds[0].tune &&
                    item.programSounds[0].tune !== null &&
                    item.programSounds[0].tune !== '' ? (
                      <s.ProgramInfoText>{item.programSounds[0].tune}</s.ProgramInfoText>
                    ) : (
                      <>
                        <s.ProgramInfoText>{item.programSounds[0].lyricist}</s.ProgramInfoText>
                        <s.ProgramInfoSeparate />
                        <s.ProgramInfoText>{item.programSounds[0].composer}</s.ProgramInfoText>
                      </>
                    )}
                    <s.ProgramInfoSeparate />
                    <s.ProgramInfoText>{item.programSounds[0].time}</s.ProgramInfoText>
                  </s.ProgramInfoAraa>
                </s.ProgramListStartCol>
                <s.ProgramListCenterCol>
                  <s.ProgramListIcon
                    onClick={() => {
                      if (!item.programId) {
                        util.makeMsg('권한이 부족합니다.', 'error');
                      } else if (props.audioRef.current.audioEl.current.paused) {
                        props.audioRef.current.audioEl.current.play();
                        props.setAudioPlaying(item.programId);
                      } else {
                        props.audioRef.current.audioEl.current.pause();
                        props.setAudioPlaying(undefined);
                      }
                    }}
                    onMouseEnter={() =>
                      !item.programId
                        ? null
                        : props.setAudioUrl(item.programSounds[0].soundSoundtrack?.fileUrl)
                    }
                    src={
                      props.audioPlaying === item.programId
                        ? 'https://storage.googleapis.com/habicastle_storage/static/icon/video_2.png'
                        : 'https://storage.googleapis.com/habicastle_storage/static/icon/video_1.png'
                    }
                  />
                </s.ProgramListCenterCol>
                <s.ProgramListCenterCol>
                  <s.ProgramListIcon
                    onClick={() =>
                      !item.programId
                        ? util.makeMsg('권한이 부족합니다.', 'error')
                        : props.programList[index].programSounds[0].soundLyrics?.fileUrl ?
                          props.setOpenModal({ idx: index, type: 'lyics' })
                        : util.makeMsg('파일이 없습니다.', 'error')
                    }
                    src={ props.programList[index].programSounds[0].soundLyrics?.fileUrl ? '/static/img/papers_2.png' : `/static/img/papers_1.png`}
                  />
                </s.ProgramListCenterCol>
                <s.ProgramListCenterCol>
                  <s.ProgramListIcon
                    onClick={() =>
                      !item.programId
                        ? util.makeMsg('권한이 부족합니다.', 'error')
                        : props.programList[index].programSounds[0].soundSheet?.fileUrl ?
                        props.setOpenModal({ idx: index, type: 'sheet' })
                        : util.makeMsg('파일이 없습니다.', 'error')
                    }
                    src={ props.programList[index].programSounds[0].soundLyrics?.fileUrl ? 'https://storage.googleapis.com/habicastle_storage/static/icon/note_2.png' : `https://storage.googleapis.com/habicastle_storage/static/icon/note_1.png`}
                  />
                </s.ProgramListCenterCol>
              </s.ProgramForm>
            ))
          )}
        </s.ProgramList>
      </s.ProgramListArea>
      <s.PagingNav>
        {props.isFirst ? null : (
          <s.PagingLink onClick={() => props.setPageNum(props.pageNum - 1)}>
            <s.PagingLinkImg src="/static/img/l_shaft.png" />
          </s.PagingLink>
        )}

        <s.PagingNumArea>
          {props.pageList.map((page: number, index: number) => (
            <s.PagingNum
              key={index}
              onClick={() => props.setPageNum(page)}
              current={props.pageNum === page}
            >
              {page}
            </s.PagingNum>
          ))}
        </s.PagingNumArea>
        {props.isLast ? null : (
          <s.PagingLink onClick={() => props.setPageNum(props.pageNum + 1)}>
            <s.PagingLinkImg src="/static/img/r_shaft.png" />
          </s.PagingLink>
        )}
      </s.PagingNav>
    </s.Container>
  );
};

export default SoundPresenter;

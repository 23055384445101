import React from 'react';
import DomainModalPresenter from './DomainModalPresenter';

interface DomainModalContainerProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  domain: string;
  setDomain: React.Dispatch<React.SetStateAction<string>>;
  domainNm: string;
  setDomainNm: React.Dispatch<React.SetStateAction<string>>;
  domainList: any;
}

const DomainModalContainer = (props: DomainModalContainerProps) => {
  return (
    <DomainModalPresenter
      isModalOpen={props.isModalOpen}
      setIsModalOpen={props.setIsModalOpen}
      domain={props.domain}
      setDomain={props.setDomain}
      domainNm={props.domainNm}
      setDomainNm={props.setDomainNm}
      domainList={props.domainList}
    />
  );
};
export default DomainModalContainer;

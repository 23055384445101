import React, { useState, useEffect } from 'react';
import * as s from './FloatingStyled';
import { FaAngleDown, FaSearch } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as util from '../../util/util';
import * as recoilItem from '../../util/recoilitem';
import { useRecoilState } from 'recoil';

import { menuApi } from '../../api/api-menu';
import { SSL_OP_ALL } from 'constants';
import WeatherApi from '../../api/WeatherApi';

interface FloatingProps extends RouteComponentProps {}

const FloatingPresenter = (props: FloatingProps) => {
  const weatherText = (weatherText: string) => {
    switch (weatherText) {
      case 'Clouds':
        return '흐림';
      case 'Clear':
        return '맑음';
      case 'Rain':
        return '비';
      default:
        return '';
    }
  };

  const [city, setCity] = useState(0);
  const [weather, setWeather] = useState({
    main: '',
  });
  const cityList = [
    { en: 'Seoul', kr: '서울' },
    { en: 'Suwan', kr: '수원' },
    { en: 'Daejeon', kr: '대전' },
    { en: 'Daegu', kr: '대구' },
    { en: 'Busan', kr: '부산' },
    { en: 'Gwangju', kr: '광주' },
    { en: 'Incheon', kr: '인천' },
    { en: 'Ulsan', kr: '울산' },
    { en: 'Jeju', kr: '제주' },
  ];

  const changeCity = (type: number) => {
    if (type === -1) {
      if (city === 0) {
        setCity(cityList.length - 1);
      } else {
        setCity(city - 1);
      }
    } else if (type === 1) {
      if (city === cityList.length - 1) {
        setCity(0);
      } else {
        setCity(city + 1);
      }
    }
  };

  const getWeather = async () => {
    console.log('GET WEATHER');
    try {
      await WeatherApi.getWeather(cityList[city].en).then((res) => {
        console.log(res.data.weather[0].main);
        setWeather({ main: res.data.weather[0].main });
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getWeather();
  }, []);

  useEffect(() => {
    getWeather();
  }, [city]);

  return (
    <s.Conatiner>
      <s.AdvArea>
        <s.AdImage
          src="/static/img/ad_puto.png"
          onClick={() => props.history.push('/edu?title=MN0203')}
        />
      </s.AdvArea>
      <s.PlanArea>
        <s.AdvArea>
          <s.AdImage
            src="/static/img/ad_fixed.png"
            onClick={() => props.history.push('/plan?type=yearly')}
          />
        </s.AdvArea>
        {/* <s.PlanTitle>교육계획안</s.PlanTitle>
        <s.PlanItem onClick={() => props.history.push('/plan?type=yearly')}>연간계획안</s.PlanItem>
        <s.PlanItem onClick={() => props.history.push('/plan?type=monthly')}>월간계획안</s.PlanItem>
        <s.PlanItem onClick={() => props.history.push('/plan?type=weekly')}>주간계획안</s.PlanItem>
        <s.PlanItem style={{borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}} onClick={() => props.history.push('/plan?type=daily')}>일일계획안</s.PlanItem> */}
      </s.PlanArea>
      <s.WeatherArea>
        <s.SupportWeatherCityArea>
          <s.SupportWeatherCityBtn onClick={() => changeCity(-1)}>
            <s.SupportWeatherCityBtnImg src="/static/img/slide_1.png" />
          </s.SupportWeatherCityBtn>
          <s.SupportCityTitle>{cityList[city].kr}</s.SupportCityTitle>
          <s.SupportWeatherCityBtn onClick={() => changeCity(1)}>
            <s.SupportWeatherCityBtnImg src="/static/img/slide_2.png" />
          </s.SupportWeatherCityBtn>
        </s.SupportWeatherCityArea>
        <s.WeatherTitle style={{ marginTop: '0px' }}>오늘의 날씨</s.WeatherTitle>
        <s.WeatherIconType1
          src={
            weather.main === 'Clouds'
              ? '/static/img/weather_cloudy.png'
              : weather.main === 'Clear'
              ? '/static/img/weather_sunny.png'
              : weather.main === 'rain'
              ? '/static/img/weather_rainy.png'
              : '/static/img/weather_sunny.png'
          }
        />
        <s.WeatherText>{weatherText(weather.main)}</s.WeatherText>

        <s.WeatherTitle>미세먼지</s.WeatherTitle>
        <s.WeatherIconType1 src="/static/img/face_normal.png" />
        <s.WeatherText>보통</s.WeatherText>

        <s.WeatherTitle>오존</s.WeatherTitle>
        <s.WeatherIconType1 src="/static/img/face_good.png" />
        <s.WeatherText>좋음</s.WeatherText>
      </s.WeatherArea>
    </s.Conatiner>
  );
};

FloatingPresenter.defaultProps = {};

export default FloatingPresenter;

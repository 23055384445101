import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import * as s from './RegisterEduStyled';

import BeatLoader from 'react-spinners/BeatLoader';

import 'react-day-picker/lib/style.css';
import queryString from 'query-string';

import Header from '../../components/header';
import Footer from '../../components/footer';
import { comnApi } from '../../api/api-comn';
import { menuApi } from '../../api/api-menu';
import { useRecoilValue, useRecoilState } from 'recoil';
import * as recoilItem from '../../util/recoilitem';
import { makeMsg } from '../../util/util';

import SelectEdu from './component/SelectEdu';
import SelectField from './component/SelectField';
import SelectHolder from './component/SelectHolder';
import SelectType from './component/SelectType';
import SelectAge from './component/SelectAge';
import SelectMediaSubMenu from './component/SelectMediaSubMenu';
import SelectVisible from './component/SelectVisible';

import Actsheet from './actsheet';
import Sound from './sound';
import Multi from './multi';
import { eduApi } from '../../api/api-edu';
import SelectSub from './component/SelectSub';

interface RegisterEduProps extends RouteComponentProps {}

const RegisterEduContainer = (props: RegisterEduProps) => {
  const token = useRecoilValue(recoilItem.token);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const [eduList, setEduList] = useState<any>([]);
  const [holderList, setHolderList] = useState<any>([]);
  const [ageList, setAgeList] = useState<any>([]);
  const [mediaSubMenuList, setMediaSubMenuList] = useState<any>([]);
  const [option, setOption] = useState<{
    holder: string;
    subMenu: string;
    type: string;
    field: string;
    visible: string;
    age: string;
    mediaSubMenu: string;
    subTab: string;
    stepLength: number;
    currentStep: number;
  }>({
    holder: 'HOL0101',
    subMenu: '',
    type: '',
    field: '',
    visible: 'none',
    age: '',
    mediaSubMenu: '',
    subTab: '',
    stepLength: 0,
    currentStep: 0,
  });

  useEffect(() => {
    if (queryString.parse(props.location.search).type) {
      switch (String(queryString.parse(props.location.search).type)) {
        default:
          break;
      }
    }
    setProgramImages([]);
    setProgramMedias([
      {
        description: '',
      },
    ]);
    setProgramSounds({});
    setTitle('');
    setDescription('');
    setThumbnail(null);
    setOption({
      holder: 'HOL0101',
      subMenu: '',
      type: '',
      field: '',
      visible: 'none',
      age: '',
      mediaSubMenu: '',
      subTab: '',
      stepLength: 0,
      currentStep: 0,
    });
  }, [props.location]);

  const onChangeOption = (optionNm: string, optionValue: string) => {
    if (optionNm === 'stepLength' || optionNm === 'currentStep') {
      setOption({
        ...option,
        [optionNm]: optionValue === '' ? 0 : Number(optionValue),
      });
    } else {
      setOption({
        ...option,
        [optionNm]: optionValue,
      });
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      await comnApi.getCd('HOL01').then((res) => {
        if (res.data.rsltCd === '00') {
          let holderList: any = {};
          res.data.ComnCd.subCdList.map((item: any) => {
            holderList[item.comnCd] = {
              comnCd: item.comnCd,
              comnCdNm: item.comnCdNm,
              viewOrder: item.viewOrder,
            };
          });
          setHolderList(holderList);
        }
      });
      await comnApi.getCd('AUTH01').then((res) => {
        if (res.data.rsltCd === '00') {
          setAgeList(
            res.data.ComnCd.subCdList.map((item: any) => ({
              comnCd: item.comnCd,
              comnCdNm: item.comnCdNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
      });
      await menuApi.getCd('MN02').then((res) => {
        if (res.data.rsltCd === '00') {
          let eduList: any = {};

          res.data.ComnCd.subMenuList.map((item: any) => {
            if (item.menuCd !== 'MN0201' && item.menuCd !== 'MN0202')
              eduList[item.menuCd] = {
                menuCd: item.menuCd,
                menuNm: item.menuNm,
                viewOrder: item.viewOrder,
              };
          });
          setEduList(eduList);
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const [isModifying, setIsModifying] = useState(true);
  const [fieldList, setFieldList] = useState<
    {
      menuCd: string;
      menuNm: string;
      viewOrder: number;
    }[]
  >([]);
  const [typeList, setTypeList] = useState<
    {
      comnCd: string;
      comnCdNm: string;
      viewOrder: number;
    }[]
  >([]);
  const [thumbnail, setThumbnail] = useState<any>(null);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [programImages, setProgramImages] = useState<any>([]);
  const [programMedias, setProgramMedias] = useState<
    {
      thumbnail?: File;
      file?: File;
      description: string;
    }[]
  >([
    {
      description: '',
    },
  ]);

  const addProgramMidea = () => {
    setProgramMedias([...programMedias, { description: '' }]);
  };

  const deleteProgramMidea = (index: number) => {
    const temps = [...programMedias];
    temps.splice(index, 1);
    setProgramMedias(temps);
  };

  const onChangeProgramMedia = (index: number, name: string, value: string | File) => {
    const temps = [...programMedias];
    temps[index] = {
      ...temps[index],
      [name]: value,
    };
    setProgramMedias(temps);
  };

  const [programSounds, setProgramSounds] = useState<any>();

  const getField = async () => {
    try {
      setIsLoading(true);
      await menuApi.getCd('MN03').then((res) => {
        if (res.data.rsltCd === '00') {
          setFieldList(
            res.data.ComnCd.subMenuList.map((item: any) => ({
              menuCd: item.menuCd,
              menuNm: item.menuNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const getType = async () => {
    try {
      setIsLoading(true);
      await comnApi.getCd('TYPE12').then((res) => {
        if (res.data.rsltCd === '00') {
          setTypeList(
            res.data.ComnCd.subCdList.map((item: any) => ({
              comnCd: item.comnCd,
              comnCdNm: item.comnCdNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const getMediaSubMenu = async () => {
    try {
      setIsLoading(true);
      await menuApi.getCd('MN05').then((res) => {
        if (res.data.rsltCd === '00') {
          setMediaSubMenuList(
            res.data.ComnCd.subMenuList.map((item: any) => ({
              menuCd: item.menuCd,
              menuNm: item.menuNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const submit = async () => {
    try {
      setIsLoading(true);
      let formData: FormData = new FormData();
      if (!option.subMenu) {
        makeMsg('교육프로그램을 선택해주세요.', 'error');
        setIsLoading(false);
        return;
      }
      if (!option.holder) {
        makeMsg('월(호)를 선택해주세요.', 'error');
        setIsLoading(false);
        return;
      }
      if (!title) {
        makeMsg('제목을 입력해주세요.', 'error');
        setIsLoading(false);
        return;
      }

      if (option.subMenu === 'MN0205' && !option.subTab) {
        makeMsg('구분을 선택하세요 (쁘레놀이, 엔젤쁘레)', 'error');
        setIsLoading(false);
        return;
      }

      formData.append('subMenu', option.subMenu);
      formData.append('type', option.type);
      formData.append('holder', option.holder);

      formData.append('title', title);
      formData.append('description', description);

      formData.append('programVisible', option.visible);
      if (thumbnail) {
        formData.append('thumbnail.file', thumbnail);
      }
      if (option.subTab !== '') {
        formData.append('subTab', option.subTab);
      }
      if (option.stepLength !== 0) {
        formData.append('stepLength', String(option.stepLength));
        formData.append('currentStep', String(option.currentStep));
      }

      if (option.type === 'TYPE1201') {
        formData.append('programs[0].topMenu', 'MN04');
        formData.append('programs[0].subMenu', 'MN0406');
        if (option.field !== '') {
          formData.append('programs[0].field', option.field);
        }
        if (option.age !== '') {
          formData.append('programs[0].age', option.age);
        }
        formData.append('programs[0].holder', option.holder);
        formData.append('programs[0].title', title);
        formData.append('programs[0].description', description);
        formData.append('programs[0].visibleType', option.visible);
        if (thumbnail) {
          formData.append('programs[0].thumbnail.file', thumbnail);
        }

        programImages.forEach((item: any, index: number) => {
          formData.append(`programs[0].programImages[${index}].title`, title);
          formData.append(`programs[0].programImages[${index}].file.file`, item);
        });
      } else if (option.type === 'TYPE1202') {
        formData.append('programs[0].topMenu', 'MN04');
        formData.append('programs[0].subMenu', 'MN0401');
        if (option.field !== '') {
          formData.append('programs[0].field', option.field);
        }
        if (option.age !== '') {
          formData.append('programs[0].age', option.age);
        }
        formData.append('programs[0].holder', option.holder);
        formData.append('programs[0].title', title);
        formData.append('programs[0].description', description);
        formData.append('programs[0].visibleType', option.visible);
        if (thumbnail) {
          formData.append('programs[0].thumbnail.file', thumbnail);
        }

        programImages.forEach((item: any, index: number) => {
          formData.append(`programs[0].programImages[${index}].title`, title);
          formData.append(`programs[0].programImages[${index}].file.file`, item);
        });
      } else if (option.type === 'TYPE1203') {
        formData.append('programs[0].topMenu', 'MN04');
        formData.append('programs[0].subMenu', 'MN0402');
        if (option.field !== '') {
          formData.append('programs[0].field', option.field);
        }
        if (option.age !== '') {
          formData.append('programs[0].age', option.age);
        }
        formData.append('programs[0].holder', option.holder);
        formData.append('programs[0].title', title);
        formData.append('programs[0].description', description);
        formData.append('programs[0].visibleType', option.visible);
        if (thumbnail) {
          formData.append('programs[0].thumbnail.file', thumbnail);
        }

        formData.append('programs[0].programSounds[0].time', programSounds.time);
        if (programSounds.tune && programSounds.tune !== '') {
          formData.append('programs[0].programSounds[0].tune', programSounds.tune);
        }
        if (programSounds.lyricist && programSounds.lyricist !== '') {
          formData.append('programs[0].programSounds[0].lyricist', programSounds.lyricist);
        }
        if (programSounds.composer && programSounds.composer !== '') {
          formData.append('programs[0].programSounds[0].composer', programSounds.composer);
        }
        formData.append('programs[0].programSounds[0].soundtrack.file', programSounds.file);
        if (programSounds.lyrics) {
          formData.append('programs[0].programSounds[0].lyrics.file', programSounds.lyrics);
        }
        if (programSounds.sheet) {
          formData.append('programs[0].programSounds[0].sheet.file', programSounds.sheet);
        }
        if (programSounds.videos > 0) {
          programSounds.videos.forEach((video: any, videoIndex: number) => {
            formData.append(`programs[0].programSounds[0].videos[${videoIndex}].title`, title);
            formData.append(
              `programs[0].programSounds[0].videos[${videoIndex}].description`,
              video.description,
            );
            formData.append(`programs[0].programSounds[0].videos[${videoIndex}].type`, video.type);
            formData.append(`programs[0].programSounds[0].videos[${videoIndex}].url`, video.url);
            formData.append(
              `programs[0].programSounds[0].videos[${videoIndex}].file.file`,
              video.files[0],
            );
          });
        }
      } else if (option.type === 'TYPE1204') {
        formData.append('programs[0].topMenu', 'MN05');
        if (option.mediaSubMenu !== '') {
          formData.append('programs[0].subMenu', option.mediaSubMenu);
        }
        if (option.field !== '') {
          formData.append('programs[0].field', option.field);
        }
        if (option.age !== '') {
          formData.append('programs[0].age', option.age);
        }
        formData.append('programs[0].holder', option.holder);
        formData.append('programs[0].title', title);
        formData.append('programs[0].description', description);
        formData.append('programs[0].visibleType', option.visible);

        programMedias.map(
          (
            item: {
              thumbnail?: File | undefined;
              file?: File | undefined;
              description: string;
            },
            index: number,
          ) => {
            if (index === 0) {
              item.thumbnail && formData.append('programs[0].thumbnail.file', item.thumbnail);
            }
            item.description &&
              formData.append(`programs[0].programMedias[${index}].title`, item.description);
            item.file &&
              formData.append(`programs[0].programMedias[${index}].file.file`, item.file);
            item.thumbnail &&
              formData.append(`programs[0].programMedias[${index}].thumbnail.file`, item.thumbnail);
          },
        );
      }

      await eduApi.createEdu(token, formData).then((res) => {
        if (res.data.rsltCd === '00') {
          makeMsg('업로드에 성공하였습니다.', 'success');
          setIsLoading(false);
          props.history.push('/registerSelect');
        } else {
          makeMsg('업로드에 실패하였습니다.', 'error');
          setIsLoading(false);
        }
      });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(option);
  }, [option]);

  useEffect(() => {
    fetchData();
    getField();
    getType();
    getMediaSubMenu();
  }, []);

  return (
    <>
      <Header {...props} />
      <s.Container>
        <s.EduHeader>
          <s.EduHeaderTitle>교육프로그램</s.EduHeaderTitle>
          <s.EduHeaderRightArea>
            <s.EduHeaderRightText>
              <s.EduHeaderRightIcon src="/static/img/home_icon.jpg" />
              {'HOME > 마이페이지 > 교육프로그램작성'}
            </s.EduHeaderRightText>
          </s.EduHeaderRightArea>
        </s.EduHeader>
        <SelectEdu
          eduList={eduList}
          onChangeOption={onChangeOption}
          option={option}
          isModifying={isModifying}
        />
        <SelectHolder
          holderList={holderList}
          onChangeOption={onChangeOption}
          option={option}
          isModifying={isModifying}
        />
        <s.UploadFullArea>
          <s.TitleArea>
            <s.Title>
              <s.TitleSpan>
                {holderList || holderList.length === 0 ? null : holderList[option.holder].comnCdNm}
              </s.TitleSpan>{' '}
              활동지
            </s.Title>
          </s.TitleArea>

          <s.UploadArea>
            <s.UploadAreaHeader>
              <s.UploadAreaHeaderLeft>업로드</s.UploadAreaHeaderLeft>
              <s.UploadAreaHeaderRight>
                <s.UploadAreaHeaderRightIcon src="/static/img/warning.png" />
                <s.UploadAreaHeaderRightText>
                  ZIP파일은 업로드 불가입니다.
                </s.UploadAreaHeaderRightText>
              </s.UploadAreaHeaderRight>
            </s.UploadAreaHeader>
            <s.UploadContentArea>
              <s.UploadContentAreaHeader>
                <s.UploadContentAreaHeaderLeft>
                  <s.UploadContentAreaHeaderIcon
                    src={!isModifying ? '/static/img/pen.png' : '/static/img/red_pen.png'}
                  />
                  <s.UploadContentAreaHeaderLeftText
                    onClick={() => (!isModifying ? setIsModifying(true) : null)}
                    isColor={!isModifying}
                  >
                    수정
                  </s.UploadContentAreaHeaderLeftText>
                </s.UploadContentAreaHeaderLeft>
                <s.UploadContentAreaHeaderRight>
                  {false ? (
                    <BeatLoader size={8} color={'#acacac'} />
                  ) : (
                    <>
                      <s.UploadContentAreaHeaderIcon
                        src={isModifying ? '/static/img/check.png' : '/static/img/green_check.png'}
                      />
                      <s.UploadContentAreaHeaderRightText
                        onClick={() => (isModifying ? setIsModifying(false) : null)}
                        isColor={isModifying}
                      >
                        완료
                      </s.UploadContentAreaHeaderRightText>
                    </>
                  )}
                </s.UploadContentAreaHeaderRight>
              </s.UploadContentAreaHeader>
              <s.UploadProgramArea>
                <s.UploadProgramAreaHeader>
                  <SelectSub
                    option={option}
                    onChangeOption={onChangeOption}
                    isModifying={isModifying}
                  />
                  <SelectType
                    option={option}
                    onChangeOption={onChangeOption}
                    isModifying={isModifying}
                    typeList={typeList}
                  />
                  {option.type === 'TYPE1203' || option.type === 'TYPE1204' ? null : (
                    <>
                      <SelectVisible
                        option={option}
                        onChangeOption={onChangeOption}
                        isModifying={isModifying}
                      />
                      {option.visible === 'visible' ? (
                        <>
                          <SelectField
                            option={option}
                            onChangeOption={onChangeOption}
                            isModifying={isModifying}
                            fieldList={fieldList}
                          />
                          <SelectAge
                            option={option}
                            onChangeOption={onChangeOption}
                            isModifying={isModifying}
                            ageList={ageList}
                          />
                          {option.type === 'TYPE1204' ? (
                            <SelectMediaSubMenu
                              option={option}
                              onChangeOption={onChangeOption}
                              isModifying={isModifying}
                              mediaSubMenuList={mediaSubMenuList}
                            />
                          ) : null}
                        </>
                      ) : null}
                    </>
                  )}
                </s.UploadProgramAreaHeader>
                {option.type === 'TYPE1201' || option.type === 'TYPE1202' ? (
                  <Actsheet
                    {...props}
                    option={option}
                    onChangeOption={onChangeOption}
                    isModifying={isModifying}
                    setIsModifying={setIsModifying}
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    programImages={programImages}
                    setProgramImages={setProgramImages}
                    thumbnail={thumbnail}
                    setThumbnail={setThumbnail}
                  />
                ) : option.type === 'TYPE1203' ? (
                  <Sound
                    {...props}
                    option={option}
                    onChangeOption={onChangeOption}
                    isModifying={isModifying}
                    setIsModifying={setIsModifying}
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    programSounds={programSounds}
                    setProgramSounds={setProgramSounds}
                    thumbnail={thumbnail}
                    setThumbnail={setThumbnail}
                  />
                ) : option.type === 'TYPE1204' ? (
                  <Multi
                    {...props}
                    option={option}
                    onChangeOption={onChangeOption}
                    isModifying={isModifying}
                    setIsModifying={setIsModifying}
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    programMedias={programMedias}
                    onChangeProgramMedia={onChangeProgramMedia}
                    addProgramMidea={addProgramMidea}
                    deleteProgramMidea={deleteProgramMidea}
                  />
                ) : null}
              </s.UploadProgramArea>
              <s.ButtonArea>
                <s.SubmitButton onClick={submit}>
                  <s.SubmitButtonText>업로드</s.SubmitButtonText>
                </s.SubmitButton>
              </s.ButtonArea>
            </s.UploadContentArea>
          </s.UploadArea>
        </s.UploadFullArea>
      </s.Container>
      <Footer {...props} />
    </>
  );
};

RegisterEduContainer.defaultProps = {};

export default RegisterEduContainer;

import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as recoilItem from '../../../util/recoilitem';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useDropzone } from 'react-dropzone';

import RegisterVideosPresenter from './RegisterVideosPresenter';
import { programApi } from '../../../api/api-program';
import { menuApi } from '../../../api/api-menu';
import { makeMsg } from '../../../util/util';

interface RegisterVideosProps extends RouteComponentProps {
  option: {
    age: string | null;
    holder: string;
    topMenu: string;
    subMenu: string;
    type: string;
    field: string;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      holder: string;
      topMenu: string;
      subMenu: string;
      type: string;
      field: string;
    }>
  >;
  ageAuths: any;
  holderList: any;
}

const RegisterVideosContainer = (props: RegisterVideosProps) => {
  const token = useRecoilValue(recoilItem.token);
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files) => {
      setSubmitFileList(files);
    },
  });
  const [isModifying, setIsModifying] = useState<boolean>(true);
  const [submitFileList, setSubmitFileList] = useState<File[]>([]);
  const [fieldList, setFieldList] = useState<
    {
      menuCd: string;
      menuNm: string;
      viewOrder: number;
    }[]
  >([]);
  const [subMenuList, setSubMenuList] = useState<
    {
      menuCd: string;
      menuNm: string;
      viewOrder: number;
    }[]
  >([]);

  const [title, setTitle] = useState<string>('');
  const [programMedias, setProgramMedias] = useState<
    {
      thumbnail?: File;
      file?: File;
      description: string;
    }[]
  >([
    {
      description: '',
    },
  ]);

  const onChangeProgramMedia = (index: number, name: string, value: string | File) => {
    const temps = [...programMedias];
    temps[index] = {
      ...temps[index],
      [name]: value,
    };
    setProgramMedias(temps);
  };

  const getField = async () => {
    try {
      setIsLoading(true);
      await menuApi.getCd('MN03').then((res) => {
        if (res.data.rsltCd === '00') {
          setFieldList(
            res.data.ComnCd.subMenuList.map((item: any) => ({
              menuCd: item.menuCd,
              menuNm: item.menuNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const getSubMenu = async () => {
    try {
      setIsLoading(true);
      await menuApi.getCd('MN04').then((res) => {
        if (res.data.rsltCd === '00') {
          setSubMenuList(
            res.data.ComnCd.subMenuList.map((item: any) => ({
              menuCd: item.menuCd,
              menuNm: item.menuNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getSubMenu();
    getField();
  }, []);

  const addProgramMidea = () => {
    setProgramMedias([...programMedias, { description: '' }]);
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      if (!title) {
        makeMsg('제목을 입력해주세요', 'error');
      }
      if (!props.option.age) {
        makeMsg('연령을 선택해주세요.', 'error');
        return;
      }

      let formData: FormData = new FormData();

      formData.append('age', props.option.age);
      formData.append('topMenu', props.option.topMenu);
      formData.append('subMenu', props.option.subMenu);
      formData.append('title', title);
      formData.append('isEduTool', 'N');

      programMedias.map(
        (
          item: {
            thumbnail?: File | undefined;
            file?: File | undefined;
            description: string;
          },
          index: number,
        ) => {
          if (index === 0) {
            item.thumbnail && formData.append('thumbnail.file', item.thumbnail);
          }
          item.description && formData.append(`programMedias[${index}].title`, item.description);
          item.file && formData.append(`programMedias[${index}].file.file`, item.file);
          item.thumbnail &&
            formData.append(`programMedias[${index}].thumbnail.file`, item.thumbnail);
        },
      );

      await programApi.createProgram(token, formData).then((res) => {
        if (res.data.rsltCd === '00') {
          makeMsg('업로드에 성공하였습니다.', 'success');
          setIsLoading(false);
          props.history.push('/registerSelect');
        } else {
          makeMsg('업로드에 실패하였습니다.', 'error');
          setIsLoading(false);
        }
      });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <RegisterVideosPresenter
      {...props}
      subMenuList={subMenuList}
      fieldList={fieldList}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      open={open}
      acceptedFiles={acceptedFiles}
      isDragActive={isDragActive}
      isModifying={isModifying}
      setIsModifying={setIsModifying}
      submitFileList={submitFileList}
      title={title}
      setTitle={setTitle}
      submit={submit}
      programMedias={programMedias}
      onChangeProgramMedia={onChangeProgramMedia}
      addProgramMidea={addProgramMidea}
    />
  );
};

export default RegisterVideosContainer;

import React, { useState, useEffect } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import MyPagePresenter from './MyPagePresenter';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Floating from '../../components/floating';
import { useRecoilValue, useRecoilState } from 'recoil';

import * as recoilItem from '../../util/recoilitem';
import * as util from '../../util/util';
import { shoppingApi } from '../../api/api-shopping';

interface MyPageProps extends RouteComponentProps {}

const MyPageContainer = (props: MyPageProps) => {
  const [token, setToken] = useRecoilState(recoilItem.token);
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);
  const [cartItemSize, setCartItemSize] = useState<number>(0);
  const [orderList, setOrderList] = useState<any>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let myCartParams: FormData = new FormData();

    let cartRes = await shoppingApi.getCartList(token, myCartParams);
    if (cartRes.data.rsltCd === '00') {
      setCartItemSize(cartRes.data.cartItems.length);
    }

    let orderParams: FormData = new FormData();
    orderParams.append('userId', userInfo.userKey);
    let orderRes = await shoppingApi.getOpenOrderList(token, orderParams);
    console.log(orderRes);
    if (orderRes.data.rsltCd === '00') {
      let newList = orderRes.data.orderList
        .map((item: any) => ({
          ...item,
          totalPrice: item.totalPrice > 50000 ? item.totalPrice : item.totalPrice + 3000,
          orderSeq: item.orderDt + '-' + item.orderId,
        }))
        .sort((a: any, b: any) => b.orderId - a.orderId);
      setOrderList(newList);
    } else {
      setOrderList([]);
    }
  };

  return (
    <>
      <Header {...props} />
      <Floating {...props} />
      <MyPagePresenter
        {...props}
        userInfo={userInfo}
        cartItemSize={cartItemSize}
        orderList={orderList}
      />
      <Footer {...props} />
    </>
  );
};

export default MyPageContainer;

import styled from 'styled-components';

export const Container = styled.div<{ imgsrc: string }>`
  width: 100%;
  min-width: 1180px;
  min-height: 650px;
  position: relative;
  margin: 0 auto -30px;
  background-image: ${(props) => `url(${props.imgsrc})`};
  background-size: 100% 100%;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const FilterArea = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  position: relative;
`;

export const FilterTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
`;

export const FilterTbody = styled.tbody``;

export const FilterTr = styled.tr``;

export const FilterTh = styled.th`
  border: 1px solid #cecece;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  font-size: 16px;
  width: 20%;
`;

export const FilterTd = styled.td`
  border: 1px solid #cecece;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  font-size: 16px;
  width: 80%;
`;

export const FilterSelect = styled.select`
  padding: 5px;
  font-size: 16px;
`;

export const FilterInput = styled.input`
  padding: 5px;
  font-size: 16px;
  placeholder-color: #cecece;
`;

export const SearchButton = styled.div`
  padding: 5px 10px;
  background-color: #0091ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

export const UserListArea = styled.div`
  width: 1180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  position: relative;
`;

export const UserListTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`;

export const UserListTbody = styled.tbody``;

export const UserListTr = styled.tr``;

export const UserListTh = styled.th`
  border: 1px solid #cecece;
  background-color: #0091ff;
  padding: 5px 10px;
  color: #ffffff;
  font-size: 14px;
`;

export const UserListTd = styled.td`
  border: 1px solid #cecece;
  color: #4d4d4d;
  font-size: 14px;
  text-align: center;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.8);
`;

export const UpdateButton = styled.div`
  padding: 5px 10px;
  background-color: #0091ff;
  cursor: pointer;
  color: #ffffff;
`;

import React from 'react';

import * as s from './HomeletterStyled';
import * as util from '../../util/util';
import NotFound from '../../components/notFound';

import { saveAs } from 'file-saver';

interface HomeletterProps {
  ageList: any;
  holderList: any;
  selectedAge: {
    comnCd: string;
    comnCdNm: string;
  };
  setSelectedAge: React.Dispatch<
    React.SetStateAction<{
      comnCd: string;
      comnCdNm: string;
    }>
  >;
  selectedHolderList: {
    comnCd: string;
    comnCdNm: string;
  }[];
  onChangeHolderList: (item: any) => void;
  onChangeAge: React.MouseEventHandler<HTMLLIElement>;
  homeLetterList: any;
  eduDataList: any;
  setProgramId: any;
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  pageList: number[];
  lastPageNum: number;
  isFirst: boolean;
  isLast: boolean;

  pageNum2: number;
  setPageNum2: React.Dispatch<React.SetStateAction<number>>;
  pageList2: number[];
  lastPageNum2: number;
  isFirst2: boolean;
  isLast2: boolean;
  currentSubMenu: string;
  unableDownload: React.MouseEventHandler<HTMLDivElement>;
}

const HomeletterPresenter = (props: HomeletterProps) => {
  const HomeLetter = () => {
    return (
      <>
        <s.ProgramListArea>
          <s.ProgramTypeTitleArea>
            <s.ProgramTypeTitleFixedPart>만</s.ProgramTypeTitleFixedPart>
            <s.ProgramTypeTitlePart>{props.selectedAge.comnCdNm}</s.ProgramTypeTitlePart>
            {props.selectedHolderList.map((item: any, index: number) => (
              <s.ProgramTypeTitlePart>{item.comnCdNm}</s.ProgramTypeTitlePart>
            ))}
            {/* {props.selectedTypeList.map((item: any, index: number) => (
            <s.ProgramTypeTitlePart>{item}</s.ProgramTypeTitlePart>
          ))} */}
            <s.ProgramTypeTitleFixedPart>가정통신문</s.ProgramTypeTitleFixedPart>
            <s.ProgramSearchTextArea>
              <s.ProgramSearchText type="text" name="keyword" />
              <s.ProgramSearchButton onClick={props.unableDownload}>
                <s.ProgramSearchImg src="/static/img/lens_2.png" />
              </s.ProgramSearchButton>
            </s.ProgramSearchTextArea>
          </s.ProgramTypeTitleArea>
          {/* <s.ProgramTypeTitleArea>
          <s.ProgramTypeTitleFixedPartArea programType={'homeletter'}>
            <s.ProgramTypeTitleFixedPart>가정통신문</s.ProgramTypeTitleFixedPart>
          </s.ProgramTypeTitleFixedPartArea>
          <s.ProgramTypeTitlePartArea programType={'homeletter'}>
            <s.ProgramTypeTitlePart>{'만' + props.selectedAge.comnCdNm}</s.ProgramTypeTitlePart>
            {props.selectedHolderList.map((item: any, index: number) => (
              <s.ProgramTypeTitlePart>{item.comnCdNm}</s.ProgramTypeTitlePart>
            ))}
            <s.ProgramTypeUnderLine programType={'homeletter'} />
          </s.ProgramTypeTitlePartArea>
        </s.ProgramTypeTitleArea> */}
          {!props.homeLetterList || props.homeLetterList.length === 0 ? (
            <NotFound />
          ) : (
            <s.ProgramList>
              {!props.homeLetterList || props.homeLetterList.length === 0 ? (
                <NotFound />
              ) : (
                props.homeLetterList.map((item: any, index: number) => (
                  <s.ProgramForm key={index} idx={index}>
                    <s.ProgramThumbnailArea
                      onClick={() =>
                        !item.programId
                          ? util.makeMsg('권한이 부족합니다.', 'error')
                          : props.setProgramId(item.programId)
                      }
                    >
                      {item.thumbnail !== null ? (
                        <s.ProgramThumbnail imgsrc={item.thumbnail.fileUrl}>
                          <s.ProgramDarkness>
                            <s.ProgramLensImg src={'/static/img/lens.png'} />
                          </s.ProgramDarkness>
                        </s.ProgramThumbnail>
                      ) : null}
                    </s.ProgramThumbnailArea>
                    <s.ProgramTitle>{item.title}</s.ProgramTitle>
                    <s.ProgramLinkArea>
                      <s.ProgramScrapLinkArea
                        onClick={() => util.makeMsg('준비중인 기능입니다.', 'info')}
                      >
                        <s.ProgramScrapLink>스크랩</s.ProgramScrapLink>
                      </s.ProgramScrapLinkArea>
                      <s.ProgramDownloadLinkArea>
                        {!item.programImages ? (
                          <s.ProgramDownloadLink
                            onClick={() => util.makeMsg('권한이 부족합니다.', 'error')}
                          >
                            다운로드
                          </s.ProgramDownloadLink>
                        ) : item.programImages.length !== 0 ? (
                          <s.ProgramDownloadLink
                            onClick={() =>
                              saveAs(
                                item.programImages[0].file.fileUrl,
                                item.programImages[0].file.originalFileName,
                              )
                            }
                          >
                            다운로드
                          </s.ProgramDownloadLink>
                        ) : (
                          <s.ProgramDownloadLink onClick={props.unableDownload}>
                            다운로드
                          </s.ProgramDownloadLink>
                        )}
                      </s.ProgramDownloadLinkArea>
                    </s.ProgramLinkArea>
                  </s.ProgramForm>
                ))
              )}
            </s.ProgramList>
          )}
        </s.ProgramListArea>
        <s.PagingNav>
          {props.isFirst ? null : (
            <s.PagingLink onClick={() => props.setPageNum(props.pageNum - 1)}>
              <s.PagingLinkImg src="/static/img/l_shaft.png" />
            </s.PagingLink>
          )}

          <s.PagingNumArea>
            {props.pageList.map((page: number, index: number) => (
              <s.PagingNum
                key={index}
                onClick={() => props.setPageNum(page)}
                current={props.pageNum === page}
              >
                {page}
              </s.PagingNum>
            ))}
          </s.PagingNumArea>
          {props.isLast ? null : (
            <s.PagingLink onClick={() => props.setPageNum(props.pageNum + 1)}>
              <s.PagingLinkImg src="/static/img/r_shaft.png" />
            </s.PagingLink>
          )}
        </s.PagingNav>
      </>
    );
  };

  const ParrentEducation = () => {
    return (
      <>
        <s.ProgramListArea>
          <s.ProgramTypeTitleArea>
            <s.ProgramTypeTitleFixedPart>만</s.ProgramTypeTitleFixedPart>
            <s.ProgramTypeTitlePart>{props.selectedAge.comnCdNm}</s.ProgramTypeTitlePart>
            {props.selectedHolderList.map((item: any, index: number) => (
              <s.ProgramTypeTitlePart>{item.comnCdNm}</s.ProgramTypeTitlePart>
            ))}
            {/* {props.selectedTypeList.map((item: any, index: number) => (
            <s.ProgramTypeTitlePart>{item}</s.ProgramTypeTitlePart>
          ))} */}
            <s.ProgramTypeTitleFixedPart>부모교육</s.ProgramTypeTitleFixedPart>
            <s.ProgramSearchTextArea>
              <s.ProgramSearchText type="text" name="keyword" />
              <s.ProgramSearchButton onClick={props.unableDownload}>
                <s.ProgramSearchImg src="/static/img/lens_2.png" />
              </s.ProgramSearchButton>
            </s.ProgramSearchTextArea>
          </s.ProgramTypeTitleArea>
          {/* <s.ProgramTypeTitleArea>
          <s.ProgramTypeTitle>
            <s.ProgramTypeTitleFixedPartArea programType={'edudata'}>
              <s.ProgramTypeTitleFixedPart>부모교육자료</s.ProgramTypeTitleFixedPart>
            </s.ProgramTypeTitleFixedPartArea>
            <s.ProgramTypeTitlePartArea programType={'edudata'}>
              <s.ProgramTypeTitlePart>{'만' + props.selectedAge.comnCdNm}</s.ProgramTypeTitlePart>
              {props.selectedHolderList.map((item: any, index: number) => (
                <s.ProgramTypeTitlePart>{item.comnCdNm}</s.ProgramTypeTitlePart>
              ))}
            </s.ProgramTypeTitlePartArea>
            <s.ProgramTypeUnderLine programType={'edudata'} />
          </s.ProgramTypeTitle>
        </s.ProgramTypeTitleArea> */}
          {!props.eduDataList || props.eduDataList.length === 0 ? (
            <NotFound />
          ) : (
            <s.ProgramList>
              {!props.eduDataList || props.eduDataList.length === 0 ? (
                <NotFound />
              ) : (
                props.eduDataList.map((item: any, index: number) => (
                  <s.ProgramForm key={index} idx={index}>
                    <s.ProgramThumbnailArea
                      onClick={() =>
                        !item.programId
                          ? util.makeMsg('권한이 부족합니다.', 'error')
                          : props.setProgramId(item.programId)
                      }
                    >
                      {item.thumbnail !== null ? (
                        <s.ProgramThumbnail imgsrc={item.thumbnail.fileUrl}>
                          <s.ProgramDarkness>
                            <s.ProgramLensImg src={'/static/img/lens.png'} />
                          </s.ProgramDarkness>
                        </s.ProgramThumbnail>
                      ) : null}
                    </s.ProgramThumbnailArea>
                    <s.ProgramTitle>{item.title}</s.ProgramTitle>
                    <s.ProgramLinkArea>
                      <s.ProgramScrapLinkArea
                        onClick={() => util.makeMsg('준비중인 기능입니다.', 'info')}
                      >
                        <s.ProgramScrapLink>스크랩</s.ProgramScrapLink>
                      </s.ProgramScrapLinkArea>
                      <s.ProgramDownloadLinkArea>
                        {!item.programImages ? (
                          <s.ProgramDownloadLink
                            onClick={() => util.makeMsg('권한이 부족합니다.', 'error')}
                          >
                            다운로드
                          </s.ProgramDownloadLink>
                        ) : item.programImages.length !== 0 ? (
                          <s.ProgramDownloadLink
                            onClick={() =>
                              saveAs(
                                item.programImages[0].file.fileUrl,
                                item.programImages[0].file.originalFileName,
                              )
                            }
                          >
                            다운로드
                          </s.ProgramDownloadLink>
                        ) : (
                          <s.ProgramDownloadLink onClick={props.unableDownload}>
                            다운로드
                          </s.ProgramDownloadLink>
                        )}
                      </s.ProgramDownloadLinkArea>
                    </s.ProgramLinkArea>
                  </s.ProgramForm>
                ))
              )}
            </s.ProgramList>
            // <s.ProgramList>
            //   {props.eduDataList.map((item: any, index: number) => (
            //     <s.ProgramForm key={index}  idx={index}>
            //       <s.ProgramThumbnailArea
            //         onClick={() =>
            //           !item.programId
            //             ? util.makeMsg('권한이 부족합니다.', 'error')
            //             : props.setProgramId(item.programId)
            //         }
            //       >
            //         {item.thumbnail !== null ? (
            //           <s.ProgramThumbnail imgsrc={item.thumbnail.fileUrl}>
            //             <s.ProgramDarkness>
            //               <s.ProgramLensImg src={'/static/img/lens.png'} />
            //             </s.ProgramDarkness>
            //           </s.ProgramThumbnail>
            //         ) : null}
            //       </s.ProgramThumbnailArea>
            //       <s.ProgramTitle>{item.title}</s.ProgramTitle>
            //     </s.ProgramForm>
            //   ))}
            // </s.ProgramList>
          )}
        </s.ProgramListArea>
        <s.PagingNav>
          {props.isFirst2 ? null : (
            <s.PagingLink onClick={() => props.setPageNum2(props.pageNum2 - 1)}>
              <s.PagingLinkImg src="/static/img/l_shaft.png" />
            </s.PagingLink>
          )}

          <s.PagingNumArea>
            {props.pageList2.map((page: number, index: number) => (
              <s.PagingNum
                key={index}
                onClick={() => props.setPageNum2(page)}
                current={props.pageNum2 === page}
              >
                {page}
              </s.PagingNum>
            ))}
          </s.PagingNumArea>
          {props.isLast2 ? null : (
            <s.PagingLink onClick={() => props.setPageNum2(props.pageNum2 + 1)}>
              <s.PagingLinkImg src="/static/img/r_shaft.png" />
            </s.PagingLink>
          )}
        </s.PagingNav>
      </>
    );
  };

  return (
    <s.Container>
      <s.HomeletterHeader>
        <s.HomeletterHeaderTitleArea>
          <s.HomeletterHeaderTitle>교수자료실</s.HomeletterHeaderTitle>
          <s.HomeletterHeaderTitleDivide>|</s.HomeletterHeaderTitleDivide>
          <s.HomeletterHeaderTitle>
            {props.currentSubMenu === 'MN0404' ? '가정통신문' : '부모교육'}
          </s.HomeletterHeaderTitle>
        </s.HomeletterHeaderTitleArea>
        <s.HomeletterHeaderRightArea>
          <s.HomeletterHeaderRightText>
            <s.HomeletterHeaderRightIcon src="/static/img/home_icon.jpg" />
            {'HOME > 교수자료실 > 가정통신문'}
          </s.HomeletterHeaderRightText>
        </s.HomeletterHeaderRightArea>
      </s.HomeletterHeader>

      <s.SearchConditionArea>
        <s.SearchConditionAgeArea>
          <s.searchConditionHeader>연령 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            {props.ageList.map((item: any, index: number) => (
              <s.SearchConditionItem
                selected={item.comnCd === props.selectedAge.comnCd}
                onClick={() => props.onChangeAge(item)}
              >
                {'만 ' + item.comnCdNm}
              </s.SearchConditionItem>
            ))}
          </s.SearchConditionBody>
        </s.SearchConditionAgeArea>
        <s.SearchConditionMonthArea>
          <s.searchConditionHeader>월 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            <s.SearchConditionItem
              selected={props.selectedHolderList.length === 0}
              onClick={() => props.onChangeHolderList(null)}
            >
              전체
            </s.SearchConditionItem>
            {props.holderList.map((item: any, index: number) => (
              <s.SearchConditionItem
                selected={props.selectedHolderList
                  .map((item: any) => item.comnCd)
                  .includes(item.comnCd)}
                onClick={() => props.onChangeHolderList(item)}
              >
                {item.comnCdNm}
              </s.SearchConditionItem>
            ))}
          </s.SearchConditionBody>
        </s.SearchConditionMonthArea>
      </s.SearchConditionArea>

      {/* <s.HomeletterTabArea>
        {props.ageList.map((item: any, index: number) => (
          <s.HomeletterTab
            key={'age' + index}
            active={item.comnCd === props.selectedAge.comnCd}
            onClick={() => props.onChangeAge(item)}
          >
            {'만 ' + item.comnCdNm}
          </s.HomeletterTab>
        ))}
      </s.HomeletterTabArea>
      <s.SelectArea>
        <s.SelectLabel>월(호) 선택</s.SelectLabel>
        <s.SelectDiv></s.SelectDiv>
        <s.HolderSelectForm>
          <s.SelectItem
            onClick={() => props.onChangeHolderList(null)}
            active={props.selectedHolderList.length === 0}
          >
            전체
          </s.SelectItem>
          {props.holderList.map((item: any, index: number) => (
            <s.HolderTypeItem
              key={index}
              active={props.selectedHolderList
                .map((item: any) => item.comnCd)
                .includes(item.comnCd)}
              onClick={() => props.onChangeHolderList(item)}
            >
              {item.comnCdNm}
            </s.HolderTypeItem>
          ))}
        </s.HolderSelectForm>
      </s.SelectArea> */}
      {props.currentSubMenu === 'MN0404' ? (
        <>
          <HomeLetter />
        </>
      ) : props.currentSubMenu === 'MN0405' ? (
        <>
          <ParrentEducation />
        </>
      ) : (
        <>
          <HomeLetter />
          <ParrentEducation />
        </>
      )}
    </s.Container>
  );
};

export default HomeletterPresenter;

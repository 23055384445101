import React from 'react';
import * as s from './LoginStyled';
import { Link } from 'react-router-dom';

interface LoginProps {
  onChangeId: React.ChangeEventHandler<HTMLInputElement>;
  onChangePw: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress: React.KeyboardEventHandler<HTMLInputElement>;
  onSubmit: React.MouseEventHandler<HTMLDivElement>;
  onSignUp: () => void;
}

const LoginPresenter = (props: LoginProps) => {
  return (
    <s.Container imgsrc={'/static/img/login_back.png'}>
      <s.LoginContentArea>
        <Link to="/">
          <s.LogoImg alt="Logo" src="/static/img/logo.png" />
        </Link>
        <s.LoginTextInput
          type="text"
          placeholder="아이디 입력"
          name="id"
          onChange={props.onChangeId}
          onKeyPress={props.onKeyPress}
        />
        <s.LoginTextInput
          type="password"
          placeholder="패스워드 입력"
          name="password"
          onChange={props.onChangePw}
          onKeyPress={props.onKeyPress}
        />
        <s.LoginSubmit>
          <s.SignInButton onClick={props.onSignUp}>회원가입</s.SignInButton>
          <s.LoginButton onClick={props.onSubmit}>로그인</s.LoginButton>
        </s.LoginSubmit>
      </s.LoginContentArea>
    </s.Container>
  );
};

export default LoginPresenter;

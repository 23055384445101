import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import * as s from './VideosStyled';
import * as util from '../../util/util';
import NotFound from '../../components/notFound';

interface VideosProps {
  ageList: any;
  selectedAge: {
    comnCd: string;
    comnCdNm: string;
  };
  setSelectedAge: React.Dispatch<
    React.SetStateAction<{
      comnCd: string;
      comnCdNm: string;
    }>
  >;
  onChangeAge: React.MouseEventHandler<HTMLLIElement>;
  programList: any;
  setProgramId: any;
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  pageList: never[];
  lastPageNum: number;
  isFirst: boolean;
  isLast: boolean;
}

const VideosPresenter = (props: VideosProps) => {

  return (
    <s.Container>
      <s.MultiHeader>
        <s.MultiHeaderTitle>교수동영상</s.MultiHeaderTitle>
        <s.MultiHeaderRightArea>
          <s.MultiHeaderRightText>
            <s.MultiHeaderRightIcon src="/static/img/home_icon.jpg" />
            {'HOME > 교수동영상'}
          </s.MultiHeaderRightText>
        </s.MultiHeaderRightArea>
      </s.MultiHeader>
      <s.SearchConditionArea>
        <s.SearchConditionAgeArea>
          <s.searchConditionHeader>연령 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            {
              props.ageList.map((item: any, index: number) => (
                <s.SearchConditionItem 
                  selected={item.comnCd === props.selectedAge.comnCd}
                  onClick={() => props.onChangeAge(item)}
                  >{'만 ' + item.comnCdNm}</s.SearchConditionItem>
              ))
            }
          </s.SearchConditionBody>
        </s.SearchConditionAgeArea>
      </s.SearchConditionArea>


      <s.ProgramListArea>
        <s.ProgramTypeTitleArea>
          <s.ProgramTypeTitleFixedPart>교수동영상 만</s.ProgramTypeTitleFixedPart>
          <s.ProgramTypeTitlePart>{props.selectedAge.comnCdNm}</s.ProgramTypeTitlePart>
          <s.ProgramSearchTextArea>
            <s.ProgramSearchText type="text" name="keyword" />
            <s.ProgramSearchButton>
              <s.ProgramSearchImg src="/static/img/lens_2.png" />
            </s.ProgramSearchButton>
          </s.ProgramSearchTextArea>
        </s.ProgramTypeTitleArea>
        {
          <s.ProgramList>
            {!props.programList || props.programList.length === 0 ? (
              <NotFound />
            ) : (
              props.programList.map((item: any, index: number) => {
                console.log(item.title, index);
                return(
                <s.ProgramForm key={index} idx={index}>
                  <s.ProgramThumbnailArea
                    onClick={() =>
                      !item.programId
                        ? util.makeMsg('권한이 부족합니다.', 'error')
                        : props.setProgramId(item.programId)
                    }
                  >
                    {item.thumbnail !== null ? (
                      <s.ProgramThumbnail imgsrc={item.thumbnail.fileUrl}>
                        <s.ProgramDarkness>
                          <s.ProgramLensImg src={'/static/img/lens.png'} />
                        </s.ProgramDarkness>
                      </s.ProgramThumbnail>
                    ) : null}
                  </s.ProgramThumbnailArea>
                  <s.ProgramTitle>{item.title}</s.ProgramTitle>
                  <s.ProgramLinkArea>
                    <s.ProgramScrapLinkArea
                      onClick={() => util.makeMsg('준비중인 기능입니다.', 'info')}
                    >
                      <s.ProgramScrapLink>스크랩</s.ProgramScrapLink>
                    </s.ProgramScrapLinkArea>
                    <s.ProgramDownloadLinkArea>
                      <s.ProgramDownloadLink>활동안</s.ProgramDownloadLink>
                    </s.ProgramDownloadLinkArea>
                  </s.ProgramLinkArea>
                </s.ProgramForm>
              )})
            )}
          </s.ProgramList>
        }
      </s.ProgramListArea>
      <s.PagingNav>
        {props.isFirst ? null : (
          <s.PagingLink onClick={() => props.setPageNum(props.pageNum - 1)}>
            <s.PagingLinkImg src="/static/img/l_shaft.png" />
          </s.PagingLink>
        )}

        <s.PagingNumArea>
          {props.pageList.map((page: number, index: number) => (
            <s.PagingNum
              key={index}
              onClick={() => props.setPageNum(page)}
              current={props.pageNum === page}
            >
              {page}
            </s.PagingNum>
          ))}
        </s.PagingNumArea>
        {props.isLast ? null : (
          <s.PagingLink onClick={() => props.setPageNum(props.pageNum + 1)}>
            <s.PagingLinkImg src="/static/img/r_shaft.png" />
          </s.PagingLink>
        )}
      </s.PagingNav>
    </s.Container>
  );
};

export default VideosPresenter;

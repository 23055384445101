import React from 'react';
import styled from 'styled-components';

interface NotFoundProps {
  size?: number;
}

const NotFoundContainer = (props: NotFoundProps) => (
  <Container>
    <Img size={!props.size ? 60 : props.size} src={'/static/img/update_page.png'} />
  </Container>
);

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 100px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img<{ size: number }>`
  width: ${(props) => props.size};
  height: auto;
`;
export default NotFoundContainer;

import React, { Dispatch, SetStateAction } from 'react';

import { useDropzone } from 'react-dropzone';
import * as s from '../RegisterMultiStyled';
import Thumbnail from './Thumbnail';

interface MediaProps {
  programMedia: {
    thumbnail?: File;
    file?: File;
    description: string;
    mediaUrl?: string;
  };
  onChangeProgramMedia: (name: string, value: File | string) => void;
  deleteProgramMedia: () => void;
}

const Media = (props: MediaProps) => {
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files: File[]) => {
      props.onChangeProgramMedia('file', files[0]);
    },
  });
  return (
    <s.UploadProgramArea>
      <s.UploadProgramAreaTextHeader>
        미디어 업로드
        <s.ContentListButton onClick={props.deleteProgramMedia}>
          <s.ContentListButtonImg src={'/static/img/button_minus.png'} />
        </s.ContentListButton>
      </s.UploadProgramAreaTextHeader>
      <s.UploadProgramAreaFileUploadArea
        isDrag={isDragActive}
        {...getRootProps({ className: 'dropzone' })}
      >
        {/* <s.UploadProgramAreaFileUploadAreaInput {...getInputProps()} />
        {props.programMedia.file ? (
          <s.UploadProgramAreaFileUploadAreaButtonIcon
            onClick={open}
            src="/static/img/pdf_icon_1.png"
          />
        ) : null}
        <s.UploadProgramAreaFileUploadAreaText>
          {props.programMedia.file ? props.programMedia.file.name : '영상 파일을 선택해주세요.'}
        </s.UploadProgramAreaFileUploadAreaText>
        <s.UploadProgramAreaFileUploadAreaButtonIcon
          onClick={open}
          src={
            props.programMedia.file ? '/static/img/file_exist.png' : '/static/img/selectFile.png'
          }
        /> */}
        <s.UploadMediaUrlInput
          type="text"
          placeholder="영상URL을 입력하세요."
          value={props.programMedia.mediaUrl}
          onChange={(e) => {
            props.onChangeProgramMedia('mediaUrl', e.target.value);
          }}
        />
      </s.UploadProgramAreaFileUploadArea>
      <Thumbnail
        programMedia={props.programMedia}
        onChangeProgramMedia={props.onChangeProgramMedia}
      />
      <s.UploadProgramAreaFooter>
        <s.UploadProgramAreaFooterTextArea>
          <s.TextAreaInfo>
            <s.TextAreaIntoText>영상 소개 내용</s.TextAreaIntoText>
            <s.TextAreaInfoNotice>(최대 0000글자만 작성 가능)</s.TextAreaInfoNotice>
          </s.TextAreaInfo>
          <s.TextAreaTextInputWrapper>
            <s.TextAreaTextInput
              placeholder="텍스트를 입력해주세요."
              value={props.programMedia.description}
              onChange={(e) => props.onChangeProgramMedia('description', e.target.value)}
            />
          </s.TextAreaTextInputWrapper>
        </s.UploadProgramAreaFooterTextArea>
      </s.UploadProgramAreaFooter>
    </s.UploadProgramArea>
  );
};

export default Media;

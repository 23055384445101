import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1180px;
  z-index: 99;
  position: relative;
  background: #FEFEFE;
  border-top: 1.5px solid #9E9E9E;
  height: 130px;
  justify-content: center;
  align-item: center;
  display: flex;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const ContentArea = styled.div`
  width: 1180px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  justify-content: center;
`;

export const FooterLogo = styled.div`
  width: 160px;
  height: 105px;
  width: 105px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
`;

export const FooterLogoImg = styled.img`
  width: 100%;
  height: 100%;
  display: inline-block;
  object-fit: contain;
`;

export const FooterTextArea = styled.div`
  width: 75%;
  height: 90px;
  margin-top: 50px;
  margin-left: 50px;
  padding-left: 50px;
  margin-bottom: 50px;
  border-left: 1px solid #e2e2e2;
  text-align: left;
  display: flex;
  flex-direction: column;
`;

export const FooterBtnArea = styled.div`
  text-align: left;
  vertical-align: middle;
  margin-top: 10px;
  width: 100%;
`;

export const FooterBtn = styled.div`
  font-size: 13px;
  display: inline-block;
  color: #9E9E9E;
  letter-spacing: 1.2px;
  cursor: pointer;
  font-weight: 500;
`;

export const FooterText = styled.div`
  margin-top: 10px;
  text-align: left;
  font-size: 13px;
  display: inline-block;
  color: #9E9E9E;
`;

import React, { useState, useEffect } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import MainPresenter from './MainPresenter';
import WeatherApi from '../../api/WeatherApi';
import Swal from 'sweetalert2';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Floating from '../../components/floating';
import { useRecoilValue, useRecoilState } from 'recoil';
import * as Yup from 'yup';

import { menuApi } from '../../api/api-menu';
import { programApi } from './../../api/api-program';
import * as recoilItem from '../../util/recoilitem';
import * as util from '../../util/util';
import { userApi } from '../../api/api-user';

import { comnApi } from '../../api/api-comn';
import { API_SERVER } from '../../config/config';

interface MainProps extends RouteComponentProps {}

const holderList = [
  'HOL0111',
  'HOL0112',
  'HOL0101',
  'HOL0102',
  'HOL0103',
  'HOL0104',
  'HOL0105',
  'HOL0106',
  'HOL0107',
  'HOL0108',
  'HOL0109',
  'HOL0110',
];

const contentCategoryList = [
  { menuCd: 'MN0501', menuNm: '누리과정동화' },
  { menuCd: 'MN0502', menuNm: '인성동화' },
  { menuCd: 'MN0503', menuNm: '수학동화' },
  // { menuCd: 'MN0504', menuNm: '과학동화' },
  { menuCd: 'MN0505', menuNm: '놀이동화' },
  { menuCd: 'MN0506', menuNm: '독서동화' },
];

const MainContainer = (props: MainProps) => {
  const [weather, setWeather] = useState({
    main: '',
  });
  const [city, setCity] = useState(0);
  const cityList = [
    { en: 'Seoul', kr: '서울' },
    { en: 'Suwan', kr: '수원' },
    { en: 'Daejeon', kr: '대전' },
    { en: 'Daegu', kr: '대구' },
    { en: 'Busan', kr: '부산' },
    { en: 'Gwangju', kr: '광주' },
    { en: 'Incheon', kr: '인천' },
    { en: 'Ulsan', kr: '울산' },
    { en: 'Jeju', kr: '제주' },
  ];

  const [selectedContentCategoryList, setSelectedContentCategoryList] = useState<
    { menuCd: string; menuNm: string }[]
  >([]);
  const onChangeContentCategoryList = (item: any) => {
    if (!item) {
      setSelectedContentCategoryList([]);
      return;
    }
    const contentCategory = { menuCd: item.menuCd, menuNm: item.menuNm };

    let tempContentCategoryList = [...selectedContentCategoryList];

    if (tempContentCategoryList.map((item: any) => item.menuCd).includes(contentCategory.menuCd)) {
      const idx: number = tempContentCategoryList
        .map((item: any) => item.menuCd)
        .indexOf(contentCategory.menuCd);
      tempContentCategoryList.splice(idx, 1);
    } else {
      tempContentCategoryList.push(contentCategory);
    }
    setSelectedContentCategoryList(tempContentCategoryList);
  };
  const [multimediaList, setMultimediaList] = useState<any[]>([]);

  const [categoryList, setCactegoryList] = useState([]);

  const [selectedCategoryList, setSelectedCategoryList] = useState<
    { menuCd: string; menuNm: string }[]
  >([]);
  const onChangeCategoryList = (item: any) => {
    if (!item) {
      setSelectedCategoryList([]);
      return;
    }
    const category = { menuCd: item.menuCd, menuNm: item.menuNm };

    let tempCategoryList = [...selectedCategoryList];

    if (tempCategoryList.map((item: any) => item.menuCd).includes(category.menuCd)) {
      const idx: number = tempCategoryList.map((item: any) => item.menuCd).indexOf(category.menuCd);
      tempCategoryList.splice(idx, 1);
    } else {
      tempCategoryList.push(category);
    }

    setSelectedCategoryList(tempCategoryList);
  };
  const [programList, setProgramList] = useState<any[]>([]);
  const [bannerList, setBannerList] = useState<any[]>([
    'https://storage.googleapis.com/habicastle_storage/static/main/banner01.jpg',
    'https://storage.googleapis.com/habicastle_storage/static/main/banner02.jpg',
    'https://storage.googleapis.com/habicastle_storage/static/main/banner03.jpg',
    'https://storage.googleapis.com/habicastle_storage/static/main/banner04.jpg',
  ]);

  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [token, setToken] = useRecoilState(recoilItem.token);
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  const [dataPackageList, setDataPackageList] = useState<any[]>([]);
  const [actSheetList, setActSheetList] = useState<any[]>([]);

  const formSchema = Yup.object().shape({
    id: Yup.string().required(),
    password: Yup.string().required(),
  });

  const [activeBoard, setActiveBoard] = useState<number>(1);
  const [boardItems, setBoardItems] = useState<any[]>([]);

  const logout = () => {
    setToken({
      accessToken: '',
      refreshToken: '',
    });
    setUserInfo({});
    util.makeMsg('로그아웃 되었습니다.', 'etc');
    props.history.push('/');
  };

  const register = () => {
    props.history.push('/registerSelect');
  };

  const onChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setId(e.target.value);
  };

  const onChangePw = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const onSubmit = () => {
    let res: any = null;

    formSchema
      .validate({ id: id, password: password })
      .then(async function () {
        setIsLoading(true);
        let loginForm: FormData = new FormData();

        loginForm.append('userId', id);
        loginForm.append('password', password);

        try {
          res = await userApi.login(loginForm);
          if (res && res.data.rsltCd === '00') {
            setToken(res.data.sessionToken);
            setUserInfo({
              userKey: res.data.userKey,
              userId: res.data.userId,
              userNm: res.data.userNm,
              userTypeCd: res.data.userTypeCd,
              useYn: res.data.useYn,
              regDtm: res.data.regDtm,
              userEmail: res.data.userEmail,
              userMobno: res.data.userMobno,
            });
            console.log(res.data);
            setIsLoading(false);
            if (res.data.useYn === 'Y') {
              util.makeMsg('로그인 되었습니다.', 'success');
              props.history.push('/');
            } else if (res.data.useYn === 'W') {
              util.makeMsg('임시페이지로 이동합니다.', 'success');
              props.history.push('/temporary');
            }
          } else {
            util.makeMsg('로그인에 실패하였습니다.', 'error');
          }
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      })
      .catch(function () {
        util.makeMsg('값을 모두 입력해주세요.', 'error');
      });
  };

  const changeCity = (type: number) => {
    if (type === -1) {
      if (city === 0) {
        setCity(cityList.length - 1);
      } else {
        setCity(city - 1);
      }
    } else if (type === 1) {
      if (city === cityList.length - 1) {
        setCity(0);
      } else {
        setCity(city + 1);
      }
    }
  };

  const getCategory = async () => {
    try {
      await menuApi.getCd('MN04').then(async (res) => {
        if (res.data.rsltCd === '00') {
          setCactegoryList(
            res.data.ComnCd.subMenuList.sort((a: any, b: any) => a.viewOrder - b.viewOrder),
          );
        } else {
          util.makeMsg(res.data.rsltMsg, 'error');
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getMultimedia = async () => {
    console.log(API_SERVER);
    console.log(process.env.NODE_ENV);

    try {
      let formData: FormData = new FormData();

      formData.append('topMenu', 'MN05');
      let today = new Date();
      // formData.append('holder', String(holderList[today.getMonth()]));
      // if (selectedContentCategoryList.length > 0) {
      //   formData.append(
      //     'subMenu',
      //     selectedContentCategoryList.map((item: any) => item.menuCd).join(','),
      //   );
      // }
      await programApi.filterbylist(token, formData).then(async (res) => {
        if (res.data.rsltCd === '00') {
          let tempMultimediaList = await res.data.programList
            .map((item: any, idx: number) => ({ ...item, idx: idx }))
            .sort((a: any, b: any) =>
              a.regDtm === b.regDtm ? b.idx - a.idx : a.regDtm > b.regDtm ? -1 : 1,
            );
          console.log('CHECK');
          console.log(res.data.programList);
          console.log(tempMultimediaList);
          setMultimediaList(tempMultimediaList);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getProgram = async () => {
    try {
      let formData: FormData = new FormData();

      formData.append('topMenu', 'MN04');
      let today = new Date();
      formData.append('holder', String(holderList[today.getMonth()]));
      if (selectedCategoryList.length > 0) {
        formData.append('subMenu', selectedCategoryList.map((item: any) => item.menuCd).join(','));
      }

      await programApi.filterbylist(token, formData).then((res) => {
        if (res.data.rsltCd === '00') {
          setProgramList(res.data.programList.sort((a: any, b: any) => a.regDtm < b.regDtm));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getWeather = async () => {
    try {
      await WeatherApi.getWeather(cityList[city].en).then((res) => {
        console.log(res.data.weather[0].main);
        setWeather({ main: res.data.weather[0].main });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const weatherText = (weatherText: string) => {
    switch (weatherText) {
      case 'Clouds':
        return '흐림';
      case 'Clear':
        return '맑음';
      case 'Rain':
        return '비';
      default:
        return '';
    }
  };

  const getBanner = async () => {
    try {
    } catch (error) {}
  };

  const firstFetchData = async () => {
    setIsLoading(true);
    getWeather();
    getBanner();
    getCategory();
    getMultimedia();
    getProgram();
    setIsLoading(false);
  };

  const notSupport = () => {
    Swal.fire('추후 지원할 예정입니다.');
  };

  useEffect(() => {
    firstFetchData();
  }, []);

  useEffect(() => {
    getWeather();
  }, [city]);

  useEffect(() => {
    getProgram();
  }, [selectedCategoryList]);

  useEffect(() => {
    getMultimedia();
  }, [selectedContentCategoryList]);

  useEffect(() => {
    getBoardItems();
  }, [activeBoard]);

  const checkLogin = (): any => {
    return util.tokenCheck(token);
  };

  const getBoardItems = async () => {
    if (activeBoard === 0) {
      setBoardItems([]);
    } else {
      let formData: FormData = new FormData();

      formData.append('topMenu', 'MN04');
      formData.append('subMenu', 'MN0401');
      formData.append('page', '1');
      formData.append('blockPageNum', '5');
      formData.append('pagePostCount', '6');

      let res = await programApi.filter(token, formData);
      if (res.data.rsltCd === '00') {
        let resultList = res.data.programList.content;
        resultList = resultList.map((item: any) => {
          return {
            title: item.title,
            regDtm: item.regDtm,
            linkTo: '/actsheet',
          };
        });
        setBoardItems(resultList);
      }
    }
  };

  const fetchDataPackage = async () => {
    let res1: any = null;
    let res2: any = null;
    let res3: any = null;

    let formData: FormData = new FormData();
    formData.append('topMenu', 'MN04');
    formData.append('subMenu', 'MN0407');
    formData.append('page', '1');
    formData.append('blockPageNum', '5');
    formData.append('pagePostCount', '10');
    try {
      res1 = await comnApi.getCd('AUTH01');
      res2 = await comnApi.getCd('HOL01');
      res3 = await programApi.filter(token, formData);
      console.log(res3);
      if (res1.data.rsltCd === '00' && res2.data.rsltCd === '00') {
      }
      if (res3.data.rsltCd === '00') {
        let resultList = res3.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (1 - 1) * res3.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setDataPackageList(resultList);
        console.log(resultList);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const fetchActSheet = async () => {
    let res1: any = null;
    let res2: any = null;
    let res3: any = null;
    let res4: any = null;

    let formData: FormData = new FormData();

    formData.append('topMenu', 'MN04');
    formData.append('subMenu', 'MN0401');
    formData.append('page', '1');
    formData.append('blockPageNum', '5');
    formData.append('pagePostCount', '10');

    try {
      res1 = await comnApi.getCd('AUTH01');
      res3 = await comnApi.getCd('HOL01');
      res4 = await programApi.filter(token, formData);

      if (res4.data.rsltCd === '00') {
        let resultList = res4.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (1 - 1) * res4.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setActSheetList(resultList);
        console.log(resultList[0]);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataPackage();
    fetchActSheet();
  }, []);

  const goTo = (path: string) => {
    props.history.push(path);
  };

  return (
    <>
      <Header {...props} />
      <Floating {...props} />
      <MainPresenter
        {...props}
        token={token}
        cityList={cityList}
        city={city}
        weather={weather}
        changeCity={changeCity}
        weatherText={weatherText}
        notSupport={notSupport}
        categoryList={categoryList}
        programList={programList}
        bannerList={bannerList}
        selectedCategoryList={selectedCategoryList}
        setSelectedCategoryList={setSelectedCategoryList}
        onChangeCategoryList={onChangeCategoryList}
        contentCategoryList={contentCategoryList}
        selectedContentCategoryList={selectedContentCategoryList}
        setSelectedContentCategoryList={setSelectedContentCategoryList}
        onChangeContentCategoryList={onChangeContentCategoryList}
        multimediaList={multimediaList}
        logout={logout}
        onChangeId={onChangeId}
        onChangePw={onChangePw}
        onKeyPress={onKeyPress}
        onSubmit={onSubmit}
        checkLogin={checkLogin}
        userInfo={userInfo}
        dataPackageList={dataPackageList}
        actSheetList={actSheetList}
        activeBoard={activeBoard}
        setActiveBoard={setActiveBoard}
        boardItems={boardItems}
        goTo={goTo}
      />
      <Footer {...props} />
    </>
  );
};

MainContainer.defaultProps = {};

export default MainContainer;

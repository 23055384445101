import { loginedApi } from './api-base';

export const compApi = {
  list: (token: any, keyWord: string, page: Number) =>
    loginedApi.get('/comp/list', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
      params: {
        keyWord: keyWord,
        page: page,
      },
    }),

  Add: (token: any, Data: FormData) =>
    loginedApi.post('/comp/compcrud', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
    }),
};

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import * as recoilUtil from '../../../../util/recoilitem';

import { useDropzone } from 'react-dropzone';

import Swal from 'sweetalert2';
import MonthlyPlanPresenter from './MonthlyPlanPresenter';

import { comnApi } from '../../../../api/api-comn';
import { planApi } from '../../../../api/api-plan';
import { makeLayout, clear, findItemById, addLayout } from '../../../../util/makeLayout';

type MonthlyPlanProps = {
  ageAuths: any;
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  yearList: number[];
};

const MonthlyPlanContainer = (props: MonthlyPlanProps) => {
  const token = useRecoilValue(recoilUtil.token);
  const [isLoading, setIsLoading] = useRecoilState(recoilUtil.isLoading);

  const { getRootProps, getInputProps, open, isDragAccept } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (file) => {
      setSubmitForm({
        ...submitForm,
        file,
      });
    },
  });

  const [weekLength, setWeekLength] = useState<number>(4);
  const [weekLengthEditable, setWeekLengthEditable] = useState<boolean>(true);
  const [submitForm, setSubmitForm] = useState<any>({
    planId: null,
    description: '',
    subject: '',
    expectations: '',
    file: null,
  });

  const [isEditing, setIsEditing] = useState<boolean>(true);

  const [popupOn, setPopupOn] = useState<any>({
    x: null,
    y: null,
    activityList: [],
    description: '',
  });
  const [layout, setLayout] = useState<any>([]);

  const [topicList, setTopicList] = useState<any>({});
  const [subTopicList, setSubTopicList] = useState<any>({});
  const [colorList, setColorList] = useState<any>([]);

  const [prevPlanModalOn, setPrevPlanModalOn] = useState<boolean>(false);
  const [prevPlanData, setPrevPlanData] = useState<any>({
    age: props.option.age,
    month: props.option.month,
    year: props.option.year,
  });

  const initializeLayout = () => {
    setLayout([]);
    if (props.option.age) {
      setLayout(makeLayout('monthly', weekLength, props.option.age));
    } else {
      setLayout(makeLayout('monthly', weekLength));
    }
  };

  const onInitializeLayout = () => {
    if (props.option.age) {
      setLayout(makeLayout('monthly', weekLength, props.option.age));
    } else {
      setLayout(makeLayout('monthly', weekLength));
    }
  };

  const addLayoutData = () => {
    setLayout([...addLayout(layout, 'monthly')]);
  };

  const onClear = () => {
    setLayout(clear(layout, weekLength, 'monthly'));
  };

  const onLayoutChange = async (changedLayout: any) => {
    setLayout(
      changedLayout.map((item: any) => {
        const findItem = findItemById(layout, item);
        return {
          ...item,
          activityList: findItem ? findItem.activityList : [],
          dataId: findItem ? findItem.dataId : null,
          description: findItem ? findItem.description : '',
        };
      }),
    );
  };

  const onPushActivityInLayout = async (
    activityList: any[],
    { x, y }: any,
    description?: string,
  ) => {
    console.log(activityList, x, y);
    const _layout = layout.map((item: any) => {
      return item.x === x && item.y === y
        ? {
            ...item,
            activityList: [...activityList],
            description: description,
          }
        : item;
    });
    setLayout([..._layout]);
  };

  const fetchData = async (planId?: any) => {
    //대주제 가져옴
    try {
      setIsLoading(true);
      //대주제 가져옴
      await comnApi.getCd('TYPE10').then((res) => {
        if (res.data.rsltCd === '00') {
          let subjectList: any = {};
          res.data.ComnCd.subCdList.map(
            (item: { comnCd: string; comnCdNm: string; inggCd: string; viewOrder: number }) => {
              subjectList[item.comnCd] = item;
            },
          );
          setTopicList(subjectList);
        }
      });
      //소주제 가져옴
      await comnApi.getCd('TYPE0401').then((res) => {
        if (res.data.rsltCd === '00') {
          let subjectList: any = {};
          res.data.ComnCd.subCdList.map(
            (item: { comnCd: string; comnCdNm: string; inggCd: string; viewOrder: number }) => {
              subjectList[item.comnCd] = item;
            },
          );
          setSubTopicList(subjectList);
        }
      });
      //색 가져옴
      await comnApi.getCd('COL').then((res) => {
        if (res.data.rsltCd === '00') {
          let color: any = {};
          res.data.ComnCd.subCdList.map(
            (item: { comnCd: string; comnCdNm: string; inggCd: string; viewOrder: number }) => {
              color[item.comnCd] = item;
            },
          );
          setColorList(color);
        }
      });

      //이전 데이터 가져옴
      await planApi
        .getPlanMonth(
          token,
          prevPlanData.age ? prevPlanData.age : '',
          prevPlanData.month,
          prevPlanData.year,
        )
        .then((res) => {
          if (res.data.rsltCd === '00') {
            console.log('RESCHCEK ::', res);
            setWeekLengthEditable(false);
            setSubmitForm({
              planId:
                prevPlanData.age === props.option.age &&
                prevPlanData.month === props.option.month &&
                prevPlanData.year === props.option.year
                  ? res.data.monthlyPlan.planId
                  : null,
              description: res.data.monthlyPlan.description,
              expectations: res.data.monthlyPlan.expectations,
              subject: res.data.monthlyPlan.subject,
              file: res.data.monthlyPlan.file
                ? [{ name: res.data.monthlyPlan.file.originalFileName }]
                : null,
            });
            setWeekLength(res.data.monthlyPlan.week);
            setLayout(
              res.data.monthlyPlan.dataList.map((data: any) => {
                return {
                  ...data,
                  activityList: data.activityList.map((activity: any) => {
                    return {
                      color: activity.color ? activity.color : null,
                      typeCd: activity.type ? activity.type.comnCd : null,
                      typeCdNm: activity.type ? activity.type.comnCdNm : null,
                      planActivityId: activity.planActivity
                        ? activity.planActivity.activityId
                        : null,
                      monthlyActivityId: activity.monthlyActivityId
                        ? activity.monthlyActivityId
                        : null,
                    };
                  }),
                  i: String(data.dataId),
                };
              }),
            );
            setIsLoading(false);
          } else {
            setWeekLengthEditable(true);
            setSubmitForm({
              planId: null,
              description: '',
              expectations: '',
              subject: '',
              file: null,
            });
            setWeekLength(4);
            if (props.option.age) {
              setLayout(makeLayout('monthly', 4, props.option.age));
            } else {
              setLayout(makeLayout('monthly', 4));
            }
            setIsLoading(false);
          }
        });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const uploadFile = () => {
    open();
  };

  const onChangeAge = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubmitForm({
      ...submitForm,
      age: e.target.value,
    });
  };
  const onChageWeekLength = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWeekLength(parseInt(e.target.value));
    if (props.option.age) {
      setLayout(makeLayout('monthly', parseInt(e.target.value), props.option.age));
    } else {
      setLayout(makeLayout('monthly', parseInt(e.target.value)));
    }
  };

  const onChangeSubject = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubmitForm({
      ...submitForm,
      subject: e.target.value,
    });
  };

  const onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubmitForm({
      ...submitForm,
      description: e.target.value,
    });
  };

  const onChangeExpectations = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubmitForm({
      ...submitForm,
      expectations: e.target.value,
    });
  };

  const onGetLastData = async () => {
    setPrevPlanModalOn(!prevPlanModalOn);
  };

  const onSubmit = async () => {
    const { year, age, month } = props.option;
    console.log('CHECKING ::', submitForm.planId);
    let Data: FormData = new FormData();

    Data.append('year', String(year));
    Data.append('age', String(age));
    Data.append('month', String(month));
    Data.append('week', String(weekLength));
    console.log(weekLength);

    if (submitForm.planId) {
      Data.append('planId', String(submitForm.planId));
    }

    Data.append('description', submitForm.description);
    Data.append('subject', submitForm.subject);
    Data.append('expectations', submitForm.expectations);
    if (submitForm.file && submitForm.file[0] && submitForm.file[0] instanceof File) {
      Data.append('file.file', submitForm.file[0]);
    }

    layout.forEach((data: any, indexFirst: number) => {
      if (data.dataId) {
        Data.append(`dataList[${indexFirst}].dataId`, data.dataId);
      }
      Data.append(`dataList[${indexFirst}].x`, data.x);
      Data.append(`dataList[${indexFirst}].y`, data.y);
      Data.append(`dataList[${indexFirst}].w`, data.w);
      Data.append(`dataList[${indexFirst}].h`, data.h);
      data.description && Data.append(`dataList[${indexFirst}].description`, data.description);
      data.activityList.forEach((activity: any, indexSecond: number) => {
        if (activity.monthlyActivityId) {
          Data.append(
            `dataList[${indexFirst}].activityList[${indexSecond}].monthlyActivityId`,
            activity.monthlyActivityId,
          );
        }
        if (activity.color) {
          Data.append(
            `dataList[${indexFirst}].activityList[${indexSecond}].colorCd`,
            activity.color.comnCd,
          );
        }
        if (activity.typeCd) {
          Data.append(
            `dataList[${indexFirst}].activityList[${indexSecond}].typeCd`,
            activity.typeCd,
          );
        }
        if (activity.planActivityId) {
          Data.append(
            `dataList[${indexFirst}].activityList[${indexSecond}].planActivityId`,
            activity.planActivityId,
          );
        }
      });
    });

    try {
      if (isEditing) {
        Swal.fire('먼저 완료 버튼을 눌러주세요.');
        return;
      } else {
        setIsLoading(true);
        const result = await planApi.createPlanMonthly(token, Data);
        setIsLoading(false);
        if (result.data.rsltCd === '00') {
          Swal.fire('월간 계획이 생성되었습니다.');
          setPrevPlanData({
            age: props.option.age,
            month: props.option.month,
            year: props.option.year,
          });
          setWeekLength(4);
          return;
        }
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      Swal.fire('월간 계획 생성을 실패했습니다.');
    }
  };

  useEffect(() => {
    fetchData(submitForm.planId);
  }, [prevPlanData]);

  useEffect(() => {
    setPrevPlanData({
      age: props.option.age,
      month: props.option.month,
      year: props.option.year,
    });
    setWeekLength(4);
  }, [props.option]);

  return (
    <MonthlyPlanPresenter
      option={props.option}
      ageAuths={props.ageAuths}
      weekLength={weekLength}
      weekLengthEditable={weekLengthEditable}
      uploadFile={uploadFile}
      submitForm={submitForm}
      onChangeAge={onChangeAge}
      onChageWeekLength={onChageWeekLength}
      onChangeSubject={onChangeSubject}
      onChangeDescription={onChangeDescription}
      onChangeExpectations={onChangeExpectations}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      layout={layout}
      onLayoutChange={onLayoutChange}
      onInitializeLayout={onInitializeLayout}
      onClear={onClear}
      addLayoutData={addLayoutData}
      onGetLastData={onGetLastData}
      onSubmit={onSubmit}
      popupOn={popupOn}
      setPopupOn={setPopupOn}
      topicList={topicList}
      subTopicList={subTopicList}
      colorList={colorList}
      onPushActivityInLayout={onPushActivityInLayout}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      isDragActive={isDragAccept}
      yearList={props.yearList}
      prevPlanData={prevPlanData}
      setPrevPlanData={setPrevPlanData}
      prevPlanModalOn={prevPlanModalOn}
      setPrevPlanModalOn={setPrevPlanModalOn}
      initializeLayout={initializeLayout}
    />
  );
};

export default MonthlyPlanContainer;

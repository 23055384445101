import styled from 'styled-components';

export const Container = styled.div<{ currentTimeIndex: number; lastIndex: number }>`
  width: 1180px;
  margin: 0 auto;
  text-align: center;
  border: none;
  transform: skew(-0.1deg);
`;

export const PlanCreateHeaderWrapper = styled.div`
  width: 80%;
  border: none;
  border-bottom: 1px solid #0087ed;
  display: inline-block;
  flex-direction: row;
  margin-top: 10px;
`;

export const PlanCreateHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
`;

export const PlanCreateHeaderText = styled.div<{ color: string }>`
  font-size: 18px;
  color: ${(props) => props.color};
  font-weight: 600;
`;

export const PlanFileUploadWrapper = styled.div`
  width: 80%;
  display: inline-block;
  flex-direction: column;
  margin-top: 20px;
`;

export const UploadHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0087ed;
  width: 100%;

  padding: 10px 50px;

  &:first-of-type {
    border-top-left-radius: 3px;
  }
  &:last-of-type {
    border-top-right-radius: 3px;
  }
`;

export const UploadHeadTitle = styled.div`
  flex: 1;
  font-size: 15px;
  font-weight: 800;
  color: #fff;
  text-align: left;
  letter-spacing: 0.7px;
`;

export const UploadHeadInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const UploadHeadInfoImg = styled.img`
  width: 26px;
  height: 26px;
  margin-right: 5px;
  border: none;
  display: inline-block;
  object-fit: contain;
`;

export const UploadHeadInfoText = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #fff;
`;

export const UploadBody = styled.div<{ isDragActive: boolean }>`
  width: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: ${(props) => (props.isDragActive ? '1px solid #0087ed' : '1px solid transparent')};
  opacity: ${(props) => (props.isDragActive ? '.5' : '1')};
`;

export const UploadButton = styled.button`
  margin: 40px 0;
  border: none;
  background-color: transparent;

  transition: 0.25s all;

  &:hover {
    opacity: 0.5;
  }
`;

export const UploadButtonImg = styled.img`
  height: 30px;
  width: 100px;
`;

export const UploadFooter = styled.div`
  width: 100%;
  backgrond-color: #fff;
  border: 1px solid #a2a2a2;
  padding: 10px 0;
`;

export const PlanWriteWrapper = styled.div`
  width: 80%;
  display: inline-block;
  flex-direction: column;
  margin-top: 20px;
`;

export const WriteButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
`;

export const WriteEditButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
  background-color: transparent;

  transition: 0.25s all;

  &:hover {
    opacity: 0.5;
  }
`;

export const WriteEditButtonImg = styled.img<{ choose: boolean }>`
  height: 15px;
  width: 15px;
  display: inline-block;
  margin-right: 3px;
`;

export const WriteEditButtonText = styled.div<{ choose: boolean }>`
  font-weight: ${(props) => (props.choose ? 800 : 400)};
  font-size: ${(props) => (props.choose ? '16px' : '14px')};
  color: #4d4d4d;
`;

export const WritingBoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  border: 1px solid #a2a2a2;
  border-bottom: none;

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  width: 95%;

  margin-bottom: 15px;
`;

export const WritingBoardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  border-bottom: 1px solid #a2a2a2;
  width: 100%;

  padding: 10px 0;
`;

export const WritingBoardInfo = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  height: 150px;
`;

export const WritingBoardIntoText = styled.div`
  text-align: right;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const WritingBoardInfoNotice = styled.div`
  text-align: right;
  letter-spacing: 1px;
  color: #b0b0b0;
  margin-top: 5px;
`;

export const WritingBoardTextInputWrapper = styled.div`
  flex: 7;
  display: flex;
  align-items: flex-start;
  border: none;
`;

export const WritingBoardTextInput = styled.textarea`
  resize: none;
  width: 80%;
  padding: 10px;
  height: 150px;
  border: 1px solid #a2a2a2;
  border-radius: 3px;
  ::placeholder {
    color: #e2e2e2;
  }
`;

export const DailyPlanArea = styled.div`
  width: 95%;
  border: 1px solid #b0b0b0;
  border-bottom: 0px;
  border-radius: 5px 5px 0 0;
  background-color: #fff;
`;

export const DailyPlanForm = styled.div`
  width: 100%;
  border-bottom: 1px solid #b0b0b0;
`;

export const DailyPlanFormHeaderArea = styled.div<{ color?: string }>`
  display: flex;
  width: 100%;
  height: 50px;
  position: relative;
  border-bottom: 2px solid;
  border-bottom-color: ${(props) => (props.color ? props.color : '#0087ed')};
  justify-content: center;
  align-items: center;
`;

export const DailyPlanFormHeader = styled.div<{ typeCd: string }>`
  display: flex;
  width: 50%;
  height: 65%;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  color: ${(props) =>
    props.typeCd === 'TYPE0501' || props.typeCd === 'TYPE0502' || props.typeCd === 'TYPE0506'
      ? '#d89a15'
      : props.typeCd === 'TYPE0503' || props.typeCd === 'TYPE0504'
      ? '#367c93'
      : props.typeCd === 'TYPE0505'
      ? '#31ab45'
      : '#0087ed'};
  font-size: 18px;
  font-weight: 800;
  justify-content: center;
  align-items: center;
`;

export const DailyPlanFormContentArea = styled.div`
  display: flex;
  width: 100%;
  font-size: 15px;
  color: #4d4d4d;
`;

export const ContentTimeArea = styled.div`
  width: 20%;
`;

export const ContentTimeColumn = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
`;

export const ContentTimeInput = styled.input<{ timeType: string }>`
  width: ${(props) =>
    props.timeType === 'start' ? '90px' : props.timeType === 'end' ? '50px' : '0px'};
  height: 30px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  margin-left: 8px;
  padding-left: 5px;
  font-size: 12px;

  ::placeholder {
    color: #e2e2e2;
  }
`;

export const ContentTimeDisabledInput = styled.div`
  width: 50px;
  height: 30px;
  border-radius: 5px;
  margin-left: 8px;
  background-color: #e2e2e2;
`;

export const ContentTimeCheck = styled.input`
  display: none;
`;

export const ContentTimeLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ContentTimeCheckBox = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  border: 1px solid #a2a2a2;
  border-radius: 3px;
  margin: 0 4px;
  justify-content: center;
  align-items: center;
`;

export const ContentTimeCheckImg = styled.img<{ active: boolean }>`
  width: 70%;
  height: 70%;
  opacity: ${(props) => (props.active ? '1' : '0')};
  transition: all 0.2s;
`;

export const ContentInfoArea = styled.div`
  width: 80%;
`;

export const ContentInfoForm = styled.div`
  display: flex;
  width: 100%;
  min-height: 50px;
  margin: 10px 0;
  align-items: center;
`;

export const ContentInfoFormTitle = styled.div`
  width: 10%;
  font-size: 13px;
`;

export const ContentInfoFormTextarea = styled.textarea`
  width: 55%;
  height: 120px;
  overflow: auto;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ContentInfoFormInput = styled.input`
  width: 55%;
  height: 30px;
  padding-left: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  ::placeholder {
    color: #e4e4e4;
  }
`;

export const ContentEvaluationInput = styled.input`
  width: 100%;
  height: 30px;
  padding: 5px 10px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  font-size: 14px;
  ::placeholder {
    color: #e4e4e4;
  }
`;

export const ContentInfoFormDisabledInput = styled.div`
  width: 55%;
  height: 30px;
  border-radius: 5px;
  background-color: #e4e4e4;
`;

export const ContentInfoFormCheckArea = styled.div`
  width: auto;
`;

export const ContentInfoFormCheck = styled.input`
  display: none;
`;

export const ContentInfoFormLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ContentInfoFormCheckBox = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  margin: 0 6px;
  justify-content: center;
  align-items: center;
`;

export const ContentInfoFormCheckImg = styled.img<{ active: boolean }>`
  width: 70%;
  height: 70%;
  opacity: ${(props) => (props.active ? '1' : '0')};
  transition: all 0.2s;
`;

export const ContentInfoFormButtonArea = styled.div`
  width: 20%;
  margin-left: 10px;
`;

export const ContentInfoFormButton = styled.div`
  display: flex;
  width: 80%;
  height: 30px;
  justify-content: center;
  align-items: center;
  color: #a2a2a2;
  font-weight: 600;
  border: 1px solid #a2a2a2;
  border-radius: 15px;
  cursor: pointer;
`;

export const ContentAddColButtonArea = styled.div`
  display: flex;
  width: 100%;
  margin: 15px 0 20px;
`;

export const ContentAddColButton = styled.div`
  margin-left: 40px;
  cursor: pointer;
  color: #0087ed;
  font-size: 16px;
  font-weight: 600;
`;

export const ContentAddColButtonImg = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 5px;
`;

export const ContentConfirmButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;

export const ContentConfirmButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 40px;
  margin: 20px 0;
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background-color: #0087ed;
  cursor: pointer;
`;

export const SelectWeekLabelText = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: #7d7d7d;
  margin-right: 20px;
  cursor: pointer;
`;
export const SelectWeek = styled.input`
  display: none;
`;
export const SelectWeekLabel = styled.label`
  border: 1px solid #dfdfdf;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
`;
export const SelectWeekLabelIcon = styled.img`
  width: 60%;
  height: 60%;
`;

export const UploadContentAreaHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const UploadContentAreaHeaderLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UploadContentAreaHeaderRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UploadContentAreaHeaderIcon = styled.img`
  width: 15px;
  height: auto;
  margin: 5px;
  cursor: pointer;
`;

export const UploadContentAreaHeaderLeftText = styled.div<{ isColor: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.isColor ? '#7d7d7d' : '#F56466')};
  cursor: pointer;
`;

export const UploadContentAreaHeaderRightText = styled.div<{ isColor: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.isColor ? '#7d7d7d' : '#4CBC41')};
  cursor: pointer;
`;

export const FileUploadArea = styled.div<{ isDragActive: boolean }>`
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: #f2f2f2;
  opacity: ${(props) => (props.isDragActive ? 0.5 : 1)};
`;
export const FileUploadAreaText = styled.div`
  font-size: 18px;
  color: #9c9c9c;
  font-weight: 600;
`;
export const FileUploadAreaInput = styled.input``;
export const FileUploadAreaButton = styled.div``;
export const FileUploadAreaButtonIcon = styled.img`
  width: auto;
  height: auto;
  cursor: pointer;
`;

export const ContentListButton = styled.div`
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  right: 20px;
`;

export const ContentListButtonImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

export const ColorListArea = styled.div`
  position: absolute;
  left: 20px;
`;

export const ContentTimeTileFormInput = styled.input`
  width: 40%;
  height: 30px;
  padding-left: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  ::placeholder {
    color: #e4e4e4;
  }
`;
export const ColorButton = styled.button<{ color: string; isChoose: boolean }>`
  border: ${(props) => (props.isChoose ? '2px solid #0087ed' : '2px solid transparent')};
  height: 12px;
  width: 12px;
  border-radius: 100px;

  background-color: ${(props) => props.color};

  margin: 0 0 0 10px;
`;

export const HolidayCheckArea = styled.div`
  width: 15%;
`;

export const HolidayCheck = styled.input`
  display: none;
`;

export const HolidayLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #e32727;
  font-weight: 600;
  font-size: 16px;
`;

export const HolidayCheckBox = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  border: 2px solid #e32727;
  background-color: #fff;
  border-radius: 3px;
  margin: 0 6px;
  justify-content: center;
  align-items: center;
`;

export const HolidayCheckImg = styled.img<{ active: boolean }>`
  width: 70%;
  height: 70%;
  opacity: ${(props) => (props.active ? '1' : '0')};
  transition: all 0.2s;
`;

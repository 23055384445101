import styled from 'styled-components';

export const Container = styled.div<{ imgsrc: string }>`
  width: 100%;
  height: auto;
  position: relative;
  margin: 0 auto -30px;
  text-align: center;
  background-image: ${(props) => `url(${props.imgsrc})`};
  background-size: 100% 100%;
  background-repeat: no-repeat;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const LoginContentArea = styled.div`
  width: 1180px;
  height: 600px;
  padding-top: 70px;
  margin: 0 auto;

  @media screen and (min-width: 1300px) and (max-width: 1400px) {
    padding-top: 80px;
    height: 630px;
  }
  @media screen and (min-width: 1400px) and (max-width: 1500px) {
    padding-top: 100px;
    height: 670px;
  }
  @media screen and (min-width: 1500px) and (max-width: 1600px) {
    padding-top: 115px;
    height: 710px;
  }
  @media screen and (min-width: 1600px) and (max-width: 1700px) {
    padding-top: 130px;
    height: 760px;
  }
  @media screen and (min-width: 1700px) and (max-width: 1800px) {
    padding-top: 150px;
    height: 800px;
  }
  @media screen and (min-width: 1800px) and (max-width: 1920px) {
    padding-top: 165px;
    height: 850px;
  }
`;

export const LogoImg = styled.img`
  display: inline-block;
  width: 163px;
  height: 99px;
  object-fit: contain;
  cursor: pointer;
  margin-bottom: 30px;
`;

export const LoginTextInput = styled.input`
  display: block;
  width: 270px;
  height: 53px;
  border: 0;
  border-radius: 20px;
  padding-left: 15px;
  font-size: 15px;
  color: #000;
  border: 1px solid #e2e2e2;
  margin: 16px auto;

  &::placeholder {
    color: #e2e2e2;
  }
`;

export const LoginButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 127px;
  height: 53px;
  margin-top: 20px;
  cursor: pointer;
  margin-left: 16px;
  border-radius: 10px;
  background-color: #0087ED;
  color: #FFFFFF;
  font-size: 16px;
`;

export const SignInButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 127px;
  height: 53px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #0087ED;
  background-color: #FFFFFF;
  color: #0087ED;
  font-size: 16px;
`;

export const LoginSubmit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

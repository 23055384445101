import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import DailyPlanPresenter from './DailyPlanPresenter';

import { comnApi } from '../../../../api/api-comn';
import { planApi } from '../../../../api/api-plan';
import * as util from '../../../../util/util';
import * as recoilItem from '../../../../util/recoilitem';

interface DailyPlanProps {
  option: {
    age: any;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  ageAuths: any;
}

const DailyPlanContainer = (props: DailyPlanProps) => {
  const token = useRecoilValue(recoilItem.token);
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files) => {
      setSelectedFile(files[0]);
    },
  });

  const [selectedFile, setSelectedFile] = useState<any>();
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState<boolean>(false);
  const [isOpenCautionModal, setIsOpenCautionModal] = useState<boolean>(false);
  const [isOpenDefaultEducationModal, setIsOpenDefaultEducationModal] = useState<boolean>(false);
  const [isOpenLoadModal, setIsOpenLoadModal] = useState<boolean>(false);
  const [isHoliday, setIsHoliday] = useState<boolean>(false);
  const [currentTimeIndex, setCurrentTimeIndex] = useState<number>(-1);
  const [modalPosition, setModalPosition] = useState<{
    topIndex: number;
    preLen: number;
    subLength: number;
  }>({
    topIndex: 0,
    preLen: 0,
    subLength: 0,
  });

  const checkIsHoliday = () => {
    setIsHoliday(!isHoliday);
  };

  const pushActivityList = (avtivities: any[], timeIndex: number) => {
    let tempTimes = [...timeList];
    tempTimes[timeIndex].activityList = avtivities;
    setTimeList(timeList);
  };

  const [planId, setPlanId] = useState<number | null>(null);
  const [subject, setSubject] = useState<string>('');
  const [lifeSubject, setLifeSubject] = useState<string>('');
  const [expectations, setExpectations] = useState<string>('');
  const [totalEvalutaion, setTotalEvaluation] = useState<string>('');
  const [caution, setCaution] = useState<any>(null);
  const [defaultEducation, setDefaultEducation] = useState<any>(null);
  const [timeList, setTimeList] = useState<
    {
      timeId?: number;
      startTime: string;
      isEndTime: boolean;
      endTime: string;
      mainTitle: string;
      color: string;
      subTitle: string;
      isSubTitle: boolean;
      isProgram: boolean;
      activityList: {
        text: string;
        activityId?: string;
        typeCd?: string;
        evaluation?: string;
      }[];
    }[]
  >([]);

  const onChangeTimeList = (index: number, optionNm: string, optionValue: string) => {
    const times = [...timeList];
    times[index] = {
      ...times[index],
      [optionNm]: optionValue,
    };

    setTimeList(times);
  };
  const [topicList, setTopicList] = useState<any>({});
  const [subTopicList, setSubTopicList] = useState<any>({});
  const [colorList, setColorList] = useState<any>([]);

  const onChangeSubject = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubject(e.target.value);
  };

  const onChangeLifeSubject = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLifeSubject(e.target.value);
  };

  const onChangeExpectations = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setExpectations(e.target.value);
  };

  const fetchData = async () => {
    try {
      //대주제 가져옴
      await comnApi.getCd('TYPE10').then((res) => {
        if (res.data.rsltCd === '00') {
          let subjectList: any = {};
          res.data.ComnCd.subCdList.map(
            (item: { comnCd: string; comnCdNm: string; inggCd: string; viewOrder: number }) => {
              subjectList[item.comnCd] = item;
            },
          );
          setTopicList(subjectList);
        }
      });
      //소주제 가져옴
      await comnApi.getCd('TYPE0401').then((res) => {
        if (res.data.rsltCd === '00') {
          let subjectList: any = {};
          res.data.ComnCd.subCdList.map(
            (item: { comnCd: string; comnCdNm: string; inggCd: string; viewOrder: number }) => {
              subjectList[item.comnCd] = item;
            },
          );
          setSubTopicList(subjectList);
        }
      });
      //색 가져옴
      await comnApi.getCd('COL').then((res) => {
        if (res.data.rsltCd === '00') {
          let color: any = {};
          res.data.ComnCd.subCdList.map(
            (item: { comnCd: string; comnCdNm: string; inggCd: string; viewOrder: number }) => {
              color[item.comnCd] = item;
            },
          );
          setColorList(color);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const addTime = () => {
    const times = [...timeList];
    times.push({
      startTime: '',
      isEndTime: false,
      endTime: '',
      mainTitle: '',
      color: '',
      isSubTitle: false,
      subTitle: '',
      activityList: [
        {
          text: '',
        },
      ],
      isProgram: false,
    });
    setTimeList(times);
  };

  const deleteTime = async (index: number) => {
    let times = [...timeList];
    let item: any = times.splice(index, 1);
    console.log(item);

    let formData = new FormData();
    console.log(item[0].timeId);

    if (item[0].timeId) {
      formData.append('timeId', item[0].timeId);

      let res = await planApi.deleteDailyPlanTime(token, formData);
      if (res && res.data.rsltCd === '00') {
        util.makeMsg('삭제되었습니다', 'success');
      } else {
        util.makeMsg('삭제에 실패하였습니다.', 'error');
      }
    }

    setTimeList(times);
  };

  const fetchPlan = async () => {
    setIsLoading(true);
    setIsEditing(true);
    setSelectedFile(undefined);
    setLifeSubject('');
    setSubject('');
    setExpectations('');
    setTimeList([]);
    setIsHoliday(false);
    setPlanId(null);
    setTotalEvaluation('');
    setCaution(null);
    setDefaultEducation(null);
    try {
      // await comnApi.getCd('TYPE05').then((res) => {
      //   if (res.data.rsltCd === '00') {
      //     let times: any = [];
      //     res.data.ComnCd.subCdList.forEach((item: any) => {
      //       times.push({
      //         startTime: '',
      //         isEndTime: false,
      //         endTime: '',
      //         title: item,
      //         isSubTitle: true,
      //         subTitle: '',
      //         activityList: [
      //           {
      //             activityId: null,
      //             text: '',
      //           },
      //         ],
      //         isProgram: false,
      //       });
      //     });
      //     setTimeList(times);
      //   }
      // });
      await planApi
        .getPlanDaily(
          token,
          props.option.age,
          props.option.day,
          props.option.week,
          props.option.month,
          props.option.year,
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.rsltCd === '00') {
            setIsEditing(false);
            setPlanId(res.data.dailyPlan.planId);
            setSelectedFile(res.data.dailyPlan.file);
            setLifeSubject(res.data.dailyPlan.lifeSubject);
            setSubject(res.data.dailyPlan.subject);
            setExpectations(res.data.dailyPlan.expectations);
            setIsHoliday(res.data.dailyPlan.isHoliday === 'T' ? true : false);
            setTotalEvaluation(res.data.dailyPlan.totalEvaluation);
            setCaution(res.data.dailyPlan.caution);
            setDefaultEducation(res.data.dailyPlan.defaultEducation);
            setTimeList(
              res.data.dailyPlan.timeList.map((item: any) => {
                if (
                  item.activityList &&
                  item.activityList.length > 0 &&
                  item.activityList[0].type.comnCd === 'TYPE0602'
                ) {
                  return {
                    ...item,
                    color: item.color.comnCd,
                    isEndTime: !item.endTime ? false : true,
                    isSubTitle: !item.subTitle ? false : true,
                    isMainString: !item.mainTitle ? false : true,
                    isProgram: true,
                    activityList: item.activityList.map((activity: any) => ({
                      dailyActivityId: activity.dailyActivityId,
                      type: activity.type.comnCd,
                      ...activity.activity,
                    })),
                  };
                } else {
                  return {
                    ...item,
                    color: item.color.comnCd,
                    isEndTime: !item.endTime ? false : true,
                    isSubTitle: !item.subTitle ? false : true,
                    isMainString: !item.mainTitle ? false : true,
                    isProgram: false,
                    activityList: item.activityList.map((activity: any) => ({
                      dailyActivityId: activity.dailyActivityId,
                      type: activity.type.comnCd,
                      text: activity.description,
                    })),
                  };
                }
              }),
            );
          }
        });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const onSubmit = async () => {
    setIsLoading(true);
    let res: any = null;

    let formData: FormData = new FormData();
    if (planId !== null) {
      formData.append('planId', String(planId));
    }
    formData.append('year', props.option.year.toString());
    formData.append('month', props.option.month.toString());
    formData.append('week', props.option.week.toString());
    formData.append('day', props.option.day.toString());
    formData.append('age', props.option.age);
    formData.append('isHoliday', isHoliday ? 'T' : 'F');
    formData.append('totalEvaluation', totalEvalutaion);
    if (caution) {
      formData.append('caution', caution.activityId);
    }
    if (defaultEducation) {
      formData.append('defaultEducation', defaultEducation.activityId);
    }
    if (!isHoliday) {
      formData.append('subject', subject);
      formData.append('lifeSubject', lifeSubject);
      formData.append('expectations', expectations);

      console.log('TIMELIST :: ', timeList);

      timeList.map(function (item: any, index: number) {
        if (item.timeId && item.timeId !== null) {
          formData.append(`timeList[${index}].timeId`, String(item.timeId));
        }
        formData.append(`timeList[${index}].mainTitle`, item.mainTitle);
        if (item.isSubTitle) {
          formData.append(`timeList[${index}].subTitle`, item.subTitle);
        }
        formData.append(`timeList[${index}].startTime`, item.startTime);
        if (item.isEndTime) {
          formData.append(`timeList[${index}].endTime`, item.endTime);
        }

        formData.append(`timeList[${index}].color`, item.color ? item.color : 'COL04');

        item.activityList.map(function (subItem: any, subIndex: number) {
          if (subItem.dailyActivityId && subItem.dailyActivityId !== null) {
            formData.append(
              `timeList[${index}].activityList[${subIndex}].dailyActivityId`,
              String(subItem.dailyActivityId),
            );
          }
          if (item.isProgram) {
            formData.append(`timeList[${index}].activityList[${subIndex}].typeCd`, 'TYPE0602');
            formData.append(
              `timeList[${index}].activityList[${subIndex}].planActivityId`,
              subItem.activityId,
            );
            formData.append(
              `timeList[${index}].activityList[${subIndex}].evaluation`,
              subItem.evaluation,
            );
          } else {
            formData.append(`timeList[${index}].activityList[${subIndex}].typeCd`, 'TYPE0601');
            formData.append(
              `timeList[${index}].activityList[${subIndex}].description`,
              subItem.text,
            );
            formData.append(
              `timeList[${index}].activityList[${subIndex}].evaluation`,
              subItem.evaluation,
            );
          }

          return true;
        });

        return true;
      });

      if (acceptedFiles.length > 0) {
        formData.append('file.file', acceptedFiles[0]);
      }
    } else {
      formData.append('subject', subject);
    }
    try {
      res = await planApi.createPlanDaily(token, formData);
      if (res.data.rsltCd === '00') {
        setIsEditing(false);
        setIsLoading(false);
        util.makeMsg('계획안 작성이 완료되었습니다.', 'success');
        fetchPlan();
      } else {
        setIsLoading(false);
        util.makeMsg('계획안 작성에 실패하였습니다.', 'error');
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const deleteActivity = (index: number, subIndex: number) => {
    let item = timeList[index];

    if (item.activityList.length === 1) {
      util.makeMsg('최소 1개 이상의 시간이 존재해야 합니다!', 'error');
      return;
    }

    let tempActivityList = [];
    for (let i = 0; i < item.activityList.length; i++) {
      if (i !== subIndex) {
        tempActivityList.push(item.activityList[i]);
      }
    }

    item.activityList = tempActivityList;

    let tempTimeList = timeList;
    tempTimeList[index] = item;
    setTimeList(tempTimeList);
  };

  const loadDailyPlan = async (age: any, year: any, month: any, week: any, day: any) => {
    setIsLoading(true);
    setIsEditing(true);
    setSelectedFile(undefined);
    setLifeSubject('');
    setSubject('');
    setExpectations('');
    setTimeList([]);
    setIsHoliday(false);
    try {
      await planApi.getPlanDaily(token, age, day, week, month, year).then((res) => {
        console.log(res);
        if (res.data.rsltCd === '00') {
          setIsEditing(false);
          setSelectedFile(res.data.dailyPlan.file);
          setLifeSubject(res.data.dailyPlan.lifeSubject);
          setSubject(res.data.dailyPlan.subject);
          setExpectations(res.data.dailyPlan.expectations);
          setIsHoliday(res.data.dailyPlan.isHoliday === 'T' ? true : false);
          setTimeList(
            res.data.dailyPlan.timeList.map((item: any) => {
              if (
                item.activityList &&
                item.activityList.length > 0 &&
                item.activityList[0].type.comnCd === 'TYPE0602'
              ) {
                return {
                  ...item,
                  timeId: null,
                  color: item.color.comnCd,
                  isEndTime: !item.endTime ? false : true,
                  isSubTitle: !item.subTitle ? false : true,
                  isMainString: !item.mainTitle ? false : true,
                  isProgram: true,
                  activityList: item.activityList.map((activity: any) => ({
                    dailyActivityId: null,
                    type: activity.type.comnCd,
                    ...activity.activity,
                  })),
                };
              } else {
                return {
                  ...item,
                  timeId: null,
                  color: item.color.comnCd,
                  isEndTime: !item.endTime ? false : true,
                  isSubTitle: !item.subTitle ? false : true,
                  isMainString: !item.mainTitle ? false : true,
                  isProgram: false,
                  activityList: item.activityList.map((activity: any) => ({
                    dailyActivityId: null,
                    type: activity.type.comnCd,
                    text: activity.description,
                  })),
                };
              }
            }),
          );
        }
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPlan();
  }, []);

  useEffect(() => {
    fetchPlan();
  }, [props.option]);

  useEffect(() => {
    console.log(defaultEducation);
    console.log(caution);
  }, [defaultEducation, caution]);

  return (
    <DailyPlanPresenter
      option={props.option}
      ageAuths={props.ageAuths}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      open={open}
      acceptedFiles={acceptedFiles}
      isDragActive={isDragActive}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      timeList={timeList}
      setTimeList={setTimeList}
      subject={subject}
      lifeSubject={lifeSubject}
      expectations={expectations}
      onChangeSubject={onChangeSubject}
      onChangeLifeSubject={onChangeLifeSubject}
      onChangeExpectations={onChangeExpectations}
      onSubmit={onSubmit}
      isOpenSearchModal={isOpenSearchModal}
      setIsOpenSearchModal={setIsOpenSearchModal}
      currentTimeIndex={currentTimeIndex}
      setCurrentTimeIndex={setCurrentTimeIndex}
      pushActivityList={pushActivityList}
      topicList={topicList}
      subTopicList={subTopicList}
      colorList={colorList}
      selectedFile={selectedFile}
      modalPosition={modalPosition}
      setModalPosition={setModalPosition}
      addTime={addTime}
      onChangeTimeList={onChangeTimeList}
      deleteTime={deleteTime}
      isHoliday={isHoliday}
      checkIsHoliday={checkIsHoliday}
      deleteActivity={deleteActivity}
      isOpenLoadModal={isOpenLoadModal}
      setIsOpenLoadModal={setIsOpenLoadModal}
      loadDailyPlan={loadDailyPlan}
      totalEvaluation={totalEvalutaion}
      setTotalEvaluation={setTotalEvaluation}
      caution={caution}
      setCaution={setCaution}
      defaultEducation={defaultEducation}
      setDefaultEducation={setDefaultEducation}
      isOpenCautionModal={isOpenCautionModal}
      setIsOpenCautionModal={setIsOpenCautionModal}
      isOpenDefaultEducationModal={isOpenDefaultEducationModal}
      setIsOpenDefaultEducationModal={setIsOpenDefaultEducationModal}
    ></DailyPlanPresenter>
  );
};

export default DailyPlanContainer;

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as Yup from 'yup';

import Header from '../../components/header';
import Footer from '../../components/footer';
import TemporaryPresenter from './TemporaryPresenter';
import * as recoilItem from '../../util/recoilitem';
import * as util from '../../util/util';

import { agenApi } from '../../api/api-agen';
import { userApi } from '../../api/api-user';

interface TemporaryProps extends RouteComponentProps {}

const TemporaryContainer = (props: TemporaryProps) => {
  const [password, setPassword] = useState('');
  const [passwordConf, setPasswordConf] = useState('');

  const [agenId, setAgenId] = useState('');
  const [agenNm, setAgenNm] = useState('');
  const [agenAddr, setAgenAddr] = useState('');
  const [agenContact, setAgenContact] = useState('');

  const [keyWord, setKeyWord] = useState('');
  const [keyWordForSearch, setKeyWordForSearch] = useState('');

  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [pageNum, setPageNum] = useState(1);
  const [pageList, setPageList] = useState([]);
  const [isFirst, setIsFirst] = useState(false);
  const [lastPageNum, setLastPageNum] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [totalItemLength, setTotalItemLength] = useState(0);
  const [agenList, setAgenList] = useState(null);

  const token = useRecoilValue(recoilItem.token);
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);

  const addAgencyFormSchema = Yup.object().shape({
    agenNm: Yup.string().required(),
    agenAddr: Yup.string().required(),
    agenContact: Yup.string().required(),
  });

  const submitFormSchema = Yup.object().shape({
    password: Yup.string().required(),
    passwordConf: Yup.string().required(),
    agenId: Yup.string().required(),
  });

  const onChangePw = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onChangePwConf = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConf(e.target.value);
  };

  const onChangeAgenNm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgenNm(e.target.value);
  };
  const onChangeAgenAddr = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgenAddr(e.target.value);
  };
  const onChangeAgenContact = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgenContact(e.target.value);
  };

  const selectAgency = (agenId: string, agenNm: string) => {
    setAgenId(agenId);
    setAgenNm(agenNm);
    setIsSearchModalOpen(false);
  };

  const openSearchModal = () => {
    refreshAgencyList();
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const switchModal = () => {
    if (isSearchModalOpen) {
      setIsSearchModalOpen(false);
      setIsAddModalOpen(true);
    } else if (isAddModalOpen) {
      setIsAddModalOpen(false);
      setIsSearchModalOpen(true);
    }
  };

  const refreshAgencyList = () => {
    setKeyWordForSearch('');
    setPageNum(1);
    fetchData();
  };

  const onChangeKeyWord = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyWord(e.target.value);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const onSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearchSubmit();
    }
  };

  const onAddKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onAddSubmit();
    }
  };

  const onSearchSubmit = () => {
    setKeyWordForSearch(keyWord);
    setPageNum(1);
  };

  const onSubmit = () => {
    let res: any = null;

    submitFormSchema
      .validate({
        password: password,
        passwordConf: passwordConf,
        agenId: agenId,
      })
      .then(async function () {
        if (password === passwordConf) {
          setIsLoading(true);
          let formData: FormData = new FormData();

          formData.append('newPassword', password);
          formData.append('agenId', agenId);

          try {
            res = await userApi.assignAgency(token, formData);
            if (res.data.rsltCd === '00') {
              util.makeMsg('정보 수정이 완료되었습니다.', 'success');
              props.history.push('/');
            }
            setIsLoading(false);
          } catch (e) {
            console.log(e);
            setIsLoading(false);
          }
        } else {
          util.makeMsg('비밀번호와 비밀번호 확인이 다릅니다.', 'error');
        }
      })
      .catch(function () {
        util.makeMsg('값을 모두 입력해주세요.', 'error');
      });
  };

  const onAddSubmit = () => {
    let res: any = null;

    addAgencyFormSchema
      .validate({
        agenNm: agenNm,
        agenAddr: agenAddr,
        agenContact: agenContact,
      })
      .then(async function () {
        setIsLoading(true);
        let addAgencyForm: FormData = new FormData();

        addAgencyForm.append('agenNm', agenNm);
        addAgencyForm.append('agenAddr', agenAddr);
        addAgencyForm.append('agenCont', agenContact);

        try {
          res = await agenApi.Add(token, addAgencyForm);
          if (res.data.rsltCd === '00') {
            util.makeMsg('기관이 추가되었습니다.', 'success');
            setAgenId(res.data.agenId);
            setAgenNm(res.data.agenNm);
            setIsAddModalOpen(false);
          }
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      })
      .catch(function () {
        util.makeMsg('값을 모두 입력해주세요.', 'error');
      });
  };
  const fetchData = async () => {
    let res: any = null;
    setIsLoading(true);

    try {
      res = await agenApi.list(token, keyWordForSearch, pageNum);
      if (res.data.rsltCd === '00') {
        let resultList = res.data.agencyList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res.data.agencyList.pageable.pageSize + index + 1;
          return item;
        });
        setAgenList(resultList);
        setIsFirst(res.data.agencyList.first);
        setIsLast(res.data.agencyList.last);
        setLastPageNum(res.data.agencyList.totalPages);
        setPageList(res.data.pageList);
        setTotalItemLength(res.data.agencyList.totalElements);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const test = () => {};

  useEffect(() => {
    fetchData();
  }, [pageNum, keyWordForSearch]);

  return (
    <>
      <Header {...props} />
      <TemporaryPresenter
        onChangePw={onChangePw}
        onChangePwConf={onChangePwConf}
        agenNm={agenNm}
        onChangeAgenNm={onChangeAgenNm}
        onChangeAgenAddr={onChangeAgenAddr}
        onChangeAgenContact={onChangeAgenContact}
        setAgenId={setAgenId}
        selectAgency={selectAgency}
        onChangeKeyWord={onChangeKeyWord}
        onKeyPress={onKeyPress}
        onSearchKeyPress={onSearchKeyPress}
        onAddKeyPress={onAddKeyPress}
        onSubmit={onSubmit}
        onSearchSubmit={onSearchSubmit}
        onAddSubmit={onAddSubmit}
        openSearchModal={openSearchModal}
        closeSearchModal={closeSearchModal}
        openAddModal={openAddModal}
        closeAddModal={closeAddModal}
        switchModal={switchModal}
        refreshAgencyList={refreshAgencyList}
        isSearchModalOpen={isSearchModalOpen}
        isAddModalOpen={isAddModalOpen}
        setKeyWordForSearch={setKeyWordForSearch}
        agenList={agenList}
        pageNum={pageNum}
        setPageNum={setPageNum}
        pageList={pageList}
        isFirst={isFirst}
        isLast={isLast}
        lastPageNum={lastPageNum}
        totalItemLength={totalItemLength}
        test={test}
      ></TemporaryPresenter>
      <Footer {...props} />
    </>
  );
};

export default TemporaryContainer;

import * as s from '../RegisterEduStyled';

interface SelectSubProps {
  option: {
    holder: string;
    subMenu: string;
    type: string;
    field: string;
    visible: string;
    subTab: string;
    stepLength: number;
    currentStep: number;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
}

const SelectSub = (props: SelectSubProps) => {
  return (
    <>
      {props.option.subMenu === 'MN0205' ? (
        <s.UploadProgramAreaHeaderSelect>
          <>
            <s.UploadProgramAreaSelectType
              id={'sub-MN0206'}
              value={'MN0206'}
              defaultChecked={props.option.subTab === 'MN0205'}
              onChange={(e: any) =>
                props.option.subTab === e.target.value
                  ? props.onChangeOption('subTab', '')
                  : props.onChangeOption('subTab', e.target.value)
              }
              type={'checkbox'}
              disabled={!props.isModifying}
            />
            <s.UploadProgramAreaSelectTypeLabel htmlFor={'sub-MN0206'}>
              {props.option.subTab === 'MN0206' ? (
                <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
              ) : null}
            </s.UploadProgramAreaSelectTypeLabel>
            <s.UploadProgramAreaSelectText htmlFor={'sub-MN0206'}>
              {'엔젤쁘레'}
            </s.UploadProgramAreaSelectText>
          </>
          <>
            <s.UploadProgramAreaSelectType
              id={'sub-MN0208'}
              value={'MN0208'}
              defaultChecked={props.option.subTab === 'MN0208'}
              onChange={(e: any) =>
                props.option.type === e.target.value
                  ? props.onChangeOption('subTab', '')
                  : props.onChangeOption('subTab', e.target.value)
              }
              type={'checkbox'}
              disabled={!props.isModifying}
            />
            <s.UploadProgramAreaSelectTypeLabel htmlFor={'sub-MN0208'}>
              {props.option.subTab === 'MN0208' ? (
                <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
              ) : null}
            </s.UploadProgramAreaSelectTypeLabel>
            <s.UploadProgramAreaSelectText htmlFor={'sub-MN0208'}>
              {'슈필매스'}
            </s.UploadProgramAreaSelectText>
          </>
        </s.UploadProgramAreaHeaderSelect>
      ) : null}
      <s.UploadProgramAreaSelectHeader>총단계</s.UploadProgramAreaSelectHeader>
      <s.UploadProgramAreaSelectDv />
      <s.UploadProgramAreaHeaderSelect>
        <>
          <s.UploadProgramAreaSelectType
            id={'step-none'}
            value={0}
            defaultChecked={props.option.stepLength === 0}
            onChange={(e: any) =>
              props.option.stepLength === e.target.value
                ? props.onChangeOption('stepLength', '')
                : props.onChangeOption('stepLength', e.target.value)
            }
            type={'checkbox'}
            disabled={!props.isModifying}
          />
          <s.UploadProgramAreaSelectTypeLabel htmlFor={'step-none'}>
            {props.option.stepLength === 0 ? (
              <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
            ) : null}
          </s.UploadProgramAreaSelectTypeLabel>
          <s.UploadProgramAreaSelectText htmlFor={'step-none'}>
            {'단계없음'}
          </s.UploadProgramAreaSelectText>
        </>
        <>
          <s.UploadProgramAreaSelectType
            id={'step-2'}
            value={2}
            defaultChecked={props.option.stepLength === 2}
            onChange={(e: any) =>
              props.option.stepLength === e.target.value
                ? props.onChangeOption('stepLength', '')
                : props.onChangeOption('stepLength', e.target.value)
            }
            type={'checkbox'}
            disabled={!props.isModifying}
          />
          <s.UploadProgramAreaSelectTypeLabel htmlFor={'step-2'}>
            {props.option.stepLength === 2 ? (
              <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
            ) : null}
          </s.UploadProgramAreaSelectTypeLabel>
          <s.UploadProgramAreaSelectText htmlFor={'step-2'}>
            {'2단계'}
          </s.UploadProgramAreaSelectText>
        </>
        <></>
        <>
          <s.UploadProgramAreaSelectType
            id={'step-3'}
            value={3}
            defaultChecked={props.option.stepLength === 3}
            onChange={(e: any) =>
              props.option.stepLength === e.target.value
                ? props.onChangeOption('stepLength', '')
                : props.onChangeOption('stepLength', e.target.value)
            }
            type={'checkbox'}
            disabled={!props.isModifying}
          />
          <s.UploadProgramAreaSelectTypeLabel htmlFor={'step-3'}>
            {props.option.stepLength === 3 ? (
              <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
            ) : null}
          </s.UploadProgramAreaSelectTypeLabel>
          <s.UploadProgramAreaSelectText htmlFor={'step-3'}>
            {'3단계'}
          </s.UploadProgramAreaSelectText>
        </>
        <>
          <s.UploadProgramAreaSelectType
            id={'step-4'}
            value={4}
            defaultChecked={props.option.stepLength === 4}
            onChange={(e: any) =>
              props.option.stepLength === e.target.value
                ? props.onChangeOption('stepLength', '')
                : props.onChangeOption('stepLength', e.target.value)
            }
            type={'checkbox'}
            disabled={!props.isModifying}
          />
          <s.UploadProgramAreaSelectTypeLabel htmlFor={'step-4'}>
            {props.option.stepLength === 4 ? (
              <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
            ) : null}
          </s.UploadProgramAreaSelectTypeLabel>
          <s.UploadProgramAreaSelectText htmlFor={'step-4'}>
            {'4단계'}
          </s.UploadProgramAreaSelectText>
        </>
      </s.UploadProgramAreaHeaderSelect>
      {props.option.stepLength !== 0 ? (
        <>
          <s.UploadProgramAreaSelectHeader>현재단계</s.UploadProgramAreaSelectHeader>
          <s.UploadProgramAreaSelectDv />
          <s.UploadProgramAreaHeaderSelect>
            {props.option.stepLength === 4 ? (
              <>
                <s.UploadProgramAreaSelectType
                  id={'curstep-0'}
                  value={0}
                  defaultChecked={props.option.currentStep === 0}
                  onChange={(e: any) =>
                    props.option.currentStep === e.target.value
                      ? props.onChangeOption('currentStep', '')
                      : props.onChangeOption('currentStep', e.target.value)
                  }
                  type={'checkbox'}
                  disabled={!props.isModifying}
                />
                <s.UploadProgramAreaSelectTypeLabel htmlFor={'curstep-0'}>
                  {props.option.currentStep === 0 ? (
                    <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                  ) : null}
                </s.UploadProgramAreaSelectTypeLabel>
                <s.UploadProgramAreaSelectText htmlFor={'curstep-0'}>
                  {'0단계'}
                </s.UploadProgramAreaSelectText>
              </>
            ) : null}

            <>
              <s.UploadProgramAreaSelectType
                id={'curstep-1'}
                value={1}
                defaultChecked={props.option.currentStep === 1}
                onChange={(e: any) =>
                  props.option.currentStep === e.target.value
                    ? props.onChangeOption('currentStep', '')
                    : props.onChangeOption('currentStep', e.target.value)
                }
                type={'checkbox'}
                disabled={!props.isModifying}
              />
              <s.UploadProgramAreaSelectTypeLabel htmlFor={'curstep-1'}>
                {props.option.currentStep === 1 ? (
                  <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                ) : null}
              </s.UploadProgramAreaSelectTypeLabel>
              <s.UploadProgramAreaSelectText htmlFor={'curstep-1'}>
                {'1단계'}
              </s.UploadProgramAreaSelectText>
            </>
            <>
              <s.UploadProgramAreaSelectType
                id={'curstep-2'}
                value={2}
                defaultChecked={props.option.currentStep === 2}
                onChange={(e: any) =>
                  props.option.currentStep === e.target.value
                    ? props.onChangeOption('currentStep', '')
                    : props.onChangeOption('currentStep', e.target.value)
                }
                type={'checkbox'}
                disabled={!props.isModifying}
              />
              <s.UploadProgramAreaSelectTypeLabel htmlFor={'curstep-2'}>
                {props.option.currentStep === 2 ? (
                  <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                ) : null}
              </s.UploadProgramAreaSelectTypeLabel>
              <s.UploadProgramAreaSelectText htmlFor={'curstep-2'}>
                {'2단계'}
              </s.UploadProgramAreaSelectText>
            </>
            {props.option.stepLength >= 3 ? (
              <>
                <s.UploadProgramAreaSelectType
                  id={'curstep-3'}
                  value={3}
                  defaultChecked={props.option.currentStep === 3}
                  onChange={(e: any) =>
                    props.option.currentStep === e.target.value
                      ? props.onChangeOption('currentStep', '')
                      : props.onChangeOption('currentStep', e.target.value)
                  }
                  type={'checkbox'}
                  disabled={!props.isModifying}
                />
                <s.UploadProgramAreaSelectTypeLabel htmlFor={'curstep-3'}>
                  {props.option.currentStep === 3 ? (
                    <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                  ) : null}
                </s.UploadProgramAreaSelectTypeLabel>
                <s.UploadProgramAreaSelectText htmlFor={'curstep-3'}>
                  {'3단계'}
                </s.UploadProgramAreaSelectText>
              </>
            ) : null}
          </s.UploadProgramAreaHeaderSelect>
        </>
      ) : null}
    </>
  );
};

export default SelectSub;

import { loginedApi } from './api-base';

export const agenApi = {
  list: (token: any, keyWord: string, page: Number) =>
    loginedApi.get('/agen/list', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
      params: {
        keyWord: keyWord,
        page: page,
      },
    }),

  Add: (token: any, Data: FormData) =>
    loginedApi.post('/agen/agencrud', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
    }),
};

import React, { useEffect, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as recoilUtil from '../../../../../util/recoilitem';

import SearchModalPresenter from './SearchModalPresenter';
import { planApi } from '../../../../../api/api-plan';

interface SearchModalProps {
  ageAuths: any;
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };

  topicList: any;
  subTopicList: any;
  colorList: any;

  timeList: any[];
  currentTimeIndex: number;
  setIsOpenSearchModal: (arg0: boolean) => void;
  onPushActivity: (arg0: any[]) => void;

  modalPosition: {
    topIndex: number;
    preLen: number;
    subLength: number;
  };
  setModalPosition: React.Dispatch<
    React.SetStateAction<{
      topIndex: number;
      preLen: number;
      subLength: number;
    }>
  >;
}

const SearchModalContainer = (props: SearchModalProps) => {
  const token: any = useRecoilValue(recoilUtil.token);
  const setPlanId = useSetRecoilState(recoilUtil.planId);
  const setLoading = useSetRecoilState(recoilUtil.isLoading);

  const [topicList, setTopicList] = useState<any>(
    new Array(...Object.entries(props.topicList).map((item) => item[1])),
  );
  const [subTopicList, setSubTopicList] = useState<any>(
    new Array(...Object.entries(props.subTopicList).map((item) => item[1])),
  );
  const [colorList, setColorList] = useState<any>(
    new Array(...Object.entries(props.colorList).map((item) => item[1])),
  );

  const [activityList, setActivityList] = useState<any>([]);
  const [keyword, setKeyword] = useState<string>('');

  const [addActivityList, setAddActivityList] = useState<any>(
    props.timeList.length > 0 &&
      props.timeList[props.currentTimeIndex].activityList &&
      props.timeList[props.currentTimeIndex].activityList.length
      ? props.timeList[props.currentTimeIndex].activityList.filter((item: any) => item.activityId)
      : [],
  );

  const [chooseSubTopic, setChooseSubTopic] = useState<any>({});

  const [chooseColor, setChooseColor] = useState<any>(
    props.timeList.length > 0 &&
      props.timeList[props.currentTimeIndex].activityList &&
      props.timeList[props.currentTimeIndex].activityList.length
      ? props.timeList[props.currentTimeIndex].activityList[0].color
      : null,
  );

  const [page, setPage] = useState<number>(1);
  const [nowPage, setNowPage] = useState<number>(0);
  const [pageList, setPageList] = useState<any>([]);
  const pagePostCount: number = 5; //한 페이지에 표시되는 아이템 수
  const blockPageNum: number = 5; //5개 단위의 페이지로 나눔

  const onSetKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };
  const onIsInActivityList = (activity: any) => {
    return addActivityList.map((item: any) => item.activityId).includes(activity.activityId);
  };

  //체크가 되어있으면(=이미 등록되어있으면) 해제, 체크가 안되어 있다면 등록
  const onCheckAddActivityList = (activity: any) => {
    onIsInActivityList(activity)
      ? onDeleteAddActivityItem(activity)
      : onSetAddActivityList(activity);
  };

  const onSetAddActivityList = (activity: any) => {
    setAddActivityList([
      ...addActivityList,
      {
        ...activity,
        planActivityId: activity.activityId,
        typeCd: activity.domain ? activity.domain.comnCd : null,
        color: chooseColor,
      },
    ]);
  };

  const onDeleteAddActivityItem = (activity: any) => {
    const index = addActivityList.map((item: any) => item.activityId).indexOf(activity.activityId);
    setAddActivityList([...addActivityList.slice(0, index), ...addActivityList.slice(index + 1)]);
  };

  const onSearch = async () => {
    let Data: FormData = new FormData();

    Data.append('activityNm', keyword);
    Data.append('domain', chooseSubTopic && chooseSubTopic.comnCd ? chooseSubTopic.comnCd : '');
    Data.append('page', String(page));
    Data.append('blockPageNum', String(blockPageNum));
    Data.append('pagePostCount', String(pagePostCount));

    try {
      setLoading(true);
      const res = await planApi.searchPlanActivity(token, Data);
      if (res.data.rsltCd === '00') {
        setActivityList(res.data.activityList.content);
        setPageList(
          new Array(res.data.activityList.totalPages)
            .fill(0)
            .map((item: any, index: number) => index + 1),
        );
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const onNextPage = () => {
    setPage((Math.floor((page - 1) / pagePostCount) + 1) * pagePostCount + 1);
    setNowPage(nowPage + 1);
  };

  const onPrevPage = () => {
    setPage((Math.floor((page - 1) / pagePostCount) - 1) * pagePostCount + 1);
    setNowPage(nowPage - 1);
  };

  const onClose = async () => {
    props.setIsOpenSearchModal(false);
    setAddActivityList([]);
  };

  const onConfirm = async () => {
    props.onPushActivity(addActivityList);
    onClose();
  };

  useEffect(() => {
    onSearch();
  }, [page, chooseSubTopic]);

  return (
    <SearchModalPresenter
      ageAuths={props.ageAuths}
      option={props.option}
      topicList={topicList}
      subTopicList={subTopicList}
      colorList={colorList}
      setPlanId={setPlanId}
      keyword={keyword}
      onSetKeyword={onSetKeyword}
      onKeyPress={onKeyPress}
      activityList={activityList}
      onSearch={onSearch}
      page={page}
      setPage={setPage}
      onNextPage={onNextPage}
      onPrevPage={onPrevPage}
      nowPage={nowPage}
      pagePostCount={pagePostCount}
      pageList={pageList}
      addActivityList={addActivityList}
      onIsInActivityList={onIsInActivityList}
      onSetAddActivityList={onSetAddActivityList}
      onCheckAddActivityList={onCheckAddActivityList}
      chooseSubTopic={chooseSubTopic}
      setChooseSubTopic={setChooseSubTopic}
      chooseColor={chooseColor}
      setChooseColor={setChooseColor}
      onConfirm={onConfirm}
      onClose={onClose}
      modalPosition={props.modalPosition}
      setModalPosition={props.setModalPosition}
    />
  );
};

export default SearchModalContainer;

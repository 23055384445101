import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  min-width: 1180px;
  min-height: 700px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const ButtonArea = styled.div`
  margin: 50px auto;
  width: 1080px;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 3%;
`;

export const Button = styled(Link)`
  border: 1px solid #acacac;
  border-radius: 30px;
  width: 30%;
  height: 140px;
  font-family: 'Gosanja';
  font-size: 20px;
  word-wrap: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 20px;

  &:hover {
    background-color: #0087ed;
    color: #fff;
  }
`;

export const SelectArea = styled.div`
  width: 1075px;
  height: 700px;
  display: flex;
  flex-direciton: row;
  margin: 0 auto;
`;

export const Level1Area = styled.div`
  width: 200px;
  height: 700px;
  background-color: #0087ED;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 0px;
`;

export const Level2Area = styled.div`
  width: 1000px;
  height: 700px;
  background-color: #44BAFF;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 0px;
`

export const LevelItem = styled.div`
  width: 200px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #FFFFFF;
  font-weight: 500;
`;

export const LevelLinkWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const LevelLink = styled(Link)`
  width: 200px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 500;
  cursor: pointer;
  padding: 0px 20px 0px 20px;
`
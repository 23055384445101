import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';

import HomeletterPresenter from './HomeletterPresenter';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { comnApi } from './../../api/api-comn';
import { programApi } from './../../api/api-program';
import * as recoilItem from '../../util/recoilitem';
import queryString from 'query-string';
import * as util from '../../util/util';

interface HomeletterProps extends RouteComponentProps {}

const HomeletterContainer = (props: HomeletterProps) => {
  const token = useRecoilValue(recoilItem.token);
  const [programId, setProgramId] = useRecoilState(recoilItem.programId);

  const blockPageNum: string = '1';
  const pagePostCount: string = '10';

  const [ageList, setAgeList] = useState([]);
  const [holderList, setHolderList] = useState([]);

  const [selectedAge, setSelectedAge] = useState<{ comnCd: string; comnCdNm: string }>({
    comnCd: 'AUTH0101',
    comnCdNm: '1세',
  });

  const [selectedHolderList, setSelectedHolderList] = useState<
    { comnCd: string; comnCdNm: string }[]
  >([]);

  const [currentSubMenu, setCurrentSubMenu] = useState('MN0404');

  const [homeLetterList, setHomeLetterList] = useState(null);
  const [eduDataList, setEduDataList] = useState(null);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [pageNum, setPageNum] = useState(1);
  const [pageList, setPageList] = useState([]);
  const [isFirst, setIsFirst] = useState(false);
  const [lastPageNum, setLastPageNum] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [totalItemLength, setTotalItemLength] = useState(0);

  const [pageNum2, setPageNum2] = useState(1);
  const [pageList2, setPageList2] = useState([]);
  const [isFirst2, setIsFirst2] = useState(false);
  const [lastPageNum2, setLastPageNum2] = useState(1);
  const [isLast2, setIsLast2] = useState(false);
  const [totalItemLength2, setTotalItemLength2] = useState(0);

  const onChangeAge = (item: any) => {
    setSelectedAge({ comnCd: item.comnCd, comnCdNm: item.comnCdNm });
    setPageNum(1);
  };

  const onChangeHolderList = (item: any) => {
    if (!item) {
      setSelectedHolderList([]);
      return;
    }
    const holder = { comnCd: item.comnCd, comnCdNm: item.comnCdNm };

    // let tempHolderList = [...selectedHolderList];

    // if (tempHolderList.map((item: any) => item.comnCd).includes(holder.comnCd)) {
    //   const idx: number = tempHolderList.map((item: any) => item.comnCd).indexOf(holder.comnCd);
    //   tempHolderList.splice(idx, 1);
    // } else {
    //   tempHolderList.push(holder);
    // }

    // setSelectedHolderList(tempHolderList);
    setSelectedHolderList([holder]);
    setPageNum(1);
  };
  const firstFetchData = async () => {
    setIsLoading(true);

    let res1: any = null;
    let res2: any = null;
    let res3: any = null;
    let res4: any = null;

    // 가정통신문 api 호출용 FormData
    let formData1: FormData = new FormData();

    formData1.append('topMenu', 'MN04');
    formData1.append('subMenu', 'MN0404');
    formData1.append('age', selectedAge.comnCd);
    formData1.append('page', pageNum.toString());
    formData1.append('blockPageNum', blockPageNum);
    formData1.append('pagePostCount', pagePostCount);

    // 부모교육자료 api 호출용 FormData
    let formData2: FormData = new FormData();

    formData2.append('topMenu', 'MN04');
    formData2.append('subMenu', 'MN0405');
    formData2.append('age', selectedAge.comnCd);
    formData2.append('page', pageNum2.toString());
    formData2.append('blockPageNum', blockPageNum);
    formData2.append('pagePostCount', pagePostCount);

    try {
      res1 = await comnApi.getCd('AUTH01');
      res2 = await comnApi.getCd('HOL01');
      res3 = await programApi.filter(token, formData1);
      res4 = await programApi.filter(token, formData2);
      if (res1.data.rsltCd === '00') {
        setAgeList(res1.data.ComnCd.subCdList);
      }
      if (res2.data.rsltCd === '00') {
        setHolderList(res2.data.ComnCd.subCdList);
      }
      if (res3.data.rsltCd === '00') {
        let resultList = res3.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res3.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setHomeLetterList(resultList);
        setIsFirst(res3.data.programList.first);
        setIsLast(res3.data.programList.last);
        setLastPageNum(res3.data.programList.totalPages);
        setPageList(res3.data.pageList);
        setTotalItemLength(res3.data.programList.totalElements);
      }
      if (res4.data.rsltCd === '00') {
        let resultList = res4.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res4.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setEduDataList(resultList);
        setIsFirst2(res4.data.programList.first);
        setIsLast2(res4.data.programList.last);
        setLastPageNum2(res4.data.programList.totalPages);
        setPageList2(res4.data.pageList);
        setTotalItemLength2(res4.data.programList.totalElements);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    let res1: any = null;
    let res2: any = null;

    // 가정통신문 api 호출용 FormData
    let formData1: FormData = new FormData();

    formData1.append('topMenu', 'MN04');
    formData1.append('subMenu', 'MN0404');
    formData1.append('age', selectedAge.comnCd);
    if (selectedHolderList.length > 0) {
      formData1.append('holder', selectedHolderList.map((item: any) => item.comnCd).join(','));
    }
    formData1.append('page', pageNum.toString());
    formData1.append('blockPageNum', blockPageNum);
    formData1.append('pagePostCount', pagePostCount);

    // 부모교육자료 api 호출용 FormData
    let formData2: FormData = new FormData();

    formData2.append('topMenu', 'MN04');
    formData2.append('subMenu', 'MN0405');
    formData2.append('age', selectedAge.comnCd);
    if (selectedHolderList.length > 0) {
      formData2.append('holder', selectedHolderList.map((item: any) => item.comnCd).join(','));
    }
    formData2.append('page', pageNum2.toString());
    formData2.append('blockPageNum', blockPageNum);
    formData2.append('pagePostCount', pagePostCount);

    try {
      res1 = await programApi.filter(token, formData1);
      res2 = await programApi.filter(token, formData2);
      if (res1.data.rsltCd === '00') {
        let resultList = res1.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res1.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setHomeLetterList(resultList);
        setIsFirst(res1.data.programList.first);
        setIsLast(res1.data.programList.last);
        setLastPageNum(res1.data.programList.totalPages);
        setPageList(res1.data.pageList);
        setTotalItemLength(res1.data.programList.totalElements);
        console.log(res1);
      }
      if (res2.data.rsltCd === '00') {
        let resultList = res2.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res2.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setEduDataList(resultList);
        setIsFirst2(res2.data.programList.first);
        setIsLast2(res2.data.programList.last);
        setLastPageNum2(res2.data.programList.totalPages);
        setPageList2(res2.data.pageList);
        setTotalItemLength2(res2.data.programList.totalElements);
        console.log(res2);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const unableDownload = () => {
    util.makeMsg('해당하는 파일이 없습니다.', 'error');
  };

  useEffect(() => {
    firstFetchData();
    setCurrentSubMenu(String(queryString.parse(props.location.search).menu));
  }, [props.location]);

  useEffect(() => {
    fetchData();
  }, [selectedAge, selectedHolderList, pageNum, pageNum2]);

  return (
    <>
      <Header {...props} />
      <HomeletterPresenter
        ageList={ageList}
        holderList={holderList}
        selectedAge={selectedAge}
        setSelectedAge={setSelectedAge}
        selectedHolderList={selectedHolderList}
        onChangeHolderList={onChangeHolderList}
        onChangeAge={onChangeAge}
        homeLetterList={homeLetterList}
        eduDataList={eduDataList}
        setProgramId={setProgramId}
        pageNum={pageNum}
        setPageNum={setPageNum}
        pageList={pageList}
        lastPageNum={lastPageNum}
        isFirst={isFirst}
        isLast={isLast}
        pageNum2={pageNum2}
        setPageNum2={setPageNum2}
        pageList2={pageList2}
        lastPageNum2={lastPageNum2}
        isFirst2={isFirst2}
        isLast2={isLast2}
        currentSubMenu={currentSubMenu}
        unableDownload={unableDownload}
      ></HomeletterPresenter>
      <Footer {...props} />
    </>
  );
};

export default HomeletterContainer;

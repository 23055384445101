import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';

import EduPresenter from './EduPresenter';
import Header from '../../components/header';
import Footer from '../../components/footer';
import * as recoilItem from '../../util/recoilitem';
import * as util from '../../util/util';
import { comnApi } from '../../api/api-comn';

import data from './eduinfo.json';
import queryString from 'query-string';
import { eduApi } from '../../api/api-edu';

interface EduProps extends RouteComponentProps {}

const EduContainer = (props: EduProps) => {
  const token = useRecoilValue(recoilItem.token);

  const [eduTitle, setEduTitle] = useState<string>('');
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [eduSearch, setEduSearch] = useRecoilState(recoilItem.eduSearch);
  const [holderList, setHolderList] = useState<any>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [currentStep, setCurrentStep] = useState<string>('');
  const [hspDv, setHspDv] = useState<string>('');
  const [tab, setTab] = useState<string>('intro');

  const [holder, setHolder] = useState<string>('HOL0101');

  const getImages = async () => {
    let formData: FormData = new FormData();
    formData.append('holder', holder);
    formData.append('subMenu', eduTitle);
    let result = await eduApi.getEduPictures(token, formData);
    console.log(result.data);

    if (result.data.rsltCd === '00') {
      setFileList(result.data.eduImageList.sort((a: any, b: any) => a.viewOrder - b.viewOrder));
    }
  };

  const getHolders = async () => {
    try {
      setIsLoading(true);
      await comnApi.getCd('HOL01').then((res) => {
        if (res.data.rsltCd === '00') {
          let holderList: any = {};
          res.data.ComnCd.subCdList.map((item: any) => {
            holderList[item.comnCd] = {
              comnCd: item.comnCd,
              comnCdNm: item.comnCdNm,
              viewOrder: item.viewOrder,
            };
          });
          setHolderList(holderList);
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setTab('intro');
    setEduTitle(
      queryString.parse(props.location.search).title
        ? String(queryString.parse(props.location.search).title)
        : '',
    );
    getHolders();
    setIsLoading(false);
  }, [props.location]);

  const getEdu = async (type: string) => {
    setEduSearch({
      subMenu: eduTitle,
      type: type,
      holder: holder,
    });
  };

  useEffect(() => {
    getImages();
  }, [eduTitle, holder]);

  switch (eduTitle) {
    case 'MN0206':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0206}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );
    case 'MN0207':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0207}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );
    case 'MN0213':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0213}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );
    case 'MN0211':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0211}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );
    case 'MN0210':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0210}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );
    case 'MN0203':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0203}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );
    case 'MN0201':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0201}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );
    case 'MN0214':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0214}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );
    case 'MN0205':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0205}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );
    case 'MN0204':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0204}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );
    case 'MN0202':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0202}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );
    case 'MN0208':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0208}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );
    case 'MN0209':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0209}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );
    case 'MN0212':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0212}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );
    case 'MN0215':
      return (
        <>
          <Header {...props} />
          <EduPresenter
            eduTitle={eduTitle}
            {...props}
            data={data.MN0215}
            tab={tab}
            setTab={setTab}
            holderList={holderList}
            holder={holder}
            setHolder={setHolder}
            getEdu={getEdu}
            fileList={fileList}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            hspDv={hspDv}
            setHspDv={setHspDv}
          ></EduPresenter>
          <Footer {...props} />
        </>
      );

    default:
      return (
        <>
          <Header {...props} />
          <Footer {...props} />
        </>
      );
  }
};

export default EduContainer;

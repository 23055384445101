import React, { Dispatch, SetStateAction } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useDropzone } from 'react-dropzone';
import * as s from '../RegisterDocuformStyled';

interface ThumbnailProps {
  thumbnail?: File[];
  onChangeThumbnail: Dispatch<SetStateAction<File[]>>;
}

const Thumbnail = (props: ThumbnailProps) => {
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files: File[]) => {
      props.onChangeThumbnail(files);
    },
  });
  return (
    <s.UploadProgramAreaFileUploadArea
      isDrag={isDragActive}
      {...getRootProps({ className: 'dropzone' })}
    >
      <s.UploadProgramAreaFileUploadAreaInput {...getInputProps()} />
      {props.thumbnail ? (
        <s.UploadProgramAreaFileUploadAreaButtonIcon
          onClick={open}
          src="/static/img/pdf_icon_1.png"
        />
      ) : null}
      <s.UploadProgramAreaFileUploadAreaText>
        {props.thumbnail && props.thumbnail.length > 0
          ? props.thumbnail[0]?.name
          : '썸네일 파일을 선택해주세요.'}
      </s.UploadProgramAreaFileUploadAreaText>
      <s.UploadProgramAreaFileUploadAreaButtonIcon
        onClick={open}
        src={
          props.thumbnail && props.thumbnail.length > 0
            ? '/static/img/file_exist.png'
            : '/static/img/selectFile.png'
        }
      />
    </s.UploadProgramAreaFileUploadArea>
  );
};

export default Thumbnail;

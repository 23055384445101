import moment from 'moment';
import React, { Dispatch, Props, SetStateAction } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router-dom';
import { priceToString } from '../../util/util';
import * as s from './MyPageStyled';

interface MyPageProps extends RouteComponentProps {
  userInfo: {
    userKey: any;
    userId: any;
    userNm: any;
    userTypeCd: any;
    useYn: any;
    regDtm: any;
    userEmail: any;
    userMobno: any;
  };
  cartItemSize: number;
  orderList: any;
}

const MyPagePresenter = (props: MyPageProps) => {
  return (
    <s.Container>
      <s.UserInfoArea>
        <s.UserInfoLeft>
          <s.UserIdText>
            <FaUserCircle size={'30px'} color={'#4d4d4d'} />
            {props.userInfo.userId}
          </s.UserIdText>
          <s.UserNmArea>
            <s.UserNmSpan>{props.userInfo.userNm}</s.UserNmSpan>님
          </s.UserNmArea>
        </s.UserInfoLeft>
        <s.UserInfoCenter>
          <s.DetailRow>
            <s.DetailLabel>회원가입일</s.DetailLabel>
            <s.DetailInfo>{moment(props.userInfo.regDtm).format('YYYY-MM-DD')}</s.DetailInfo>
          </s.DetailRow>
          <s.DetailRow>
            <s.DetailLabel>이메일</s.DetailLabel>
            <s.DetailInfo>{props.userInfo.userEmail}</s.DetailInfo>
          </s.DetailRow>
          <s.DetailRow>
            <s.DetailLabel>연락처</s.DetailLabel>
            <s.DetailInfo>{props.userInfo.userMobno}</s.DetailInfo>
          </s.DetailRow>
        </s.UserInfoCenter>
        <s.UserInfoRight>
          <s.DetailRow>
            <s.DetailLabel>장바구니</s.DetailLabel>
            <s.DetailInfo>
              <s.DetailInfoSpan onClick={() => props.history.push('/mypage/cart')}>
                {props.cartItemSize}
              </s.DetailInfoSpan>
              개
            </s.DetailInfo>
          </s.DetailRow>
        </s.UserInfoRight>
      </s.UserInfoArea>

      <s.OrderListArea>
        <s.TopTitle>최근 주문내역</s.TopTitle>
        <s.OrderListTable>
          <s.OrderListTbody>
            <s.OrderListTr>
              <s.OrderListTh>주문서번호</s.OrderListTh>
              <s.OrderListTh>주문일시</s.OrderListTh>
              <s.OrderListTh>주문상품</s.OrderListTh>
              <s.OrderListTh>주문금액</s.OrderListTh>
              <s.OrderListTh>결제구분</s.OrderListTh>
              <s.OrderListTh>가상계좌</s.OrderListTh>
              <s.OrderListTh>배송회사</s.OrderListTh>
              <s.OrderListTh>운송장번호</s.OrderListTh>
              <s.OrderListTh>상태</s.OrderListTh>
            </s.OrderListTr>
            {props.orderList.length > 0 ? (
              props.orderList.map((item: any, index: number) => (
                <s.OrderListTr>
                  <s.OrderListTd>{item.orderSeq}</s.OrderListTd>
                  <s.OrderListTd>{item.orderDt}</s.OrderListTd>
                  <s.OrderListTd>{item.orderTitle}</s.OrderListTd>
                  <s.OrderListTd>{priceToString(item.totalPrice)}&nbsp;원</s.OrderListTd>
                  <s.OrderListTd>{item.paymentDv === 'C' ? '카드결제' : '가상계좌'}</s.OrderListTd>
                  <s.OrderListTd>
                    {item.paymentDv === 'C'
                      ? ''
                      : item.vbankName +
                        ' ' +
                        item.vbankHolder.replaceAll(' ', '') +
                        '(' +
                        item.vbankNum +
                        ')'}
                  </s.OrderListTd>
                  <s.OrderListTd>{item.sender}</s.OrderListTd>
                  <s.OrderListTd>{item.senderNum}</s.OrderListTd>
                  <s.OrderListTd>
                    {item.status === 'ORDERED'
                      ? '배송준비중'
                      : item.status === 'DELIVERING'
                      ? '배송중'
                      : item.status === 'DELIVERED'
                      ? '배송완료'
                      : item.status === 'DONE'
                      ? '확정완료'
                      : item.status === 'CANCELED'
                      ? '주문취소'
                      : ''}
                  </s.OrderListTd>
                </s.OrderListTr>
              ))
            ) : (
              <s.OrderListTr>
                <s.OrderListTd colSpan={7}>주문이 없습니다.</s.OrderListTd>
              </s.OrderListTr>
            )}
          </s.OrderListTbody>
        </s.OrderListTable>
      </s.OrderListArea>
    </s.Container>
  );
};

export default MyPagePresenter;

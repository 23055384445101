import styled from 'styled-components';
import { StringMappingType } from 'typescript';

export const Container = styled.div`
  width: 100%;
  min-width: 1180px;
  min-height: 700px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const EduHeader = styled.div`
  width: 1091px;
  z-index: 1;
  margin: 25px auto;
  text-align: center;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const EduHeaderTitleArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const EduHeaderTitle = styled.div`
  float: left;
  font-size: 28px;
  font-weight: 600;
  color: #4d4d4d;
  word-wrap: normal;
`;

export const EduHeaderTitleDivide = styled.div`
  float: left;
  font-size: 20px;
  font-weight: 600;
  padding-top: 3px;
  margin: 0 10px;
  color: #e2e2e2;
  word-wrap: normal;
`;

export const EduHeaderSubTitle = styled.div`
  float: left;
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
  color: #c9c9c9;
`;

export const EduHeaderRightArea = styled.div`
  float: right;
  padding: 10px 0;
  text-align: center;
  vertical-align: middle;
`;
export const EduHeaderRightIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 5px;
  vertical-align: middle;
`;

export const EduHeaderRightText = styled.div`
  font-weight: 400;
  color: #4d4d4d;
  vertical-align: middle;
`;

export const EduTabArea = styled.div`
  width: 1091px;
  height: auto;
  margin: 0 auto 0;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-self: flex-start;
`;

export const EduTab = styled.div<{ active: boolean; color: string }>`
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  width: 272px;
  height: 49px;
  padding: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 18px;
  cursor: pointer;
  color: #fff;
  background-color: ${(props) => (props.active ? props.color : '#EEEEEE')};
  color: ${(props) => (props.active ? '#fff' : '#4d4d4d')};
`;

export const BannerArea = styled.div`
  width: 100%;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BannerImg = styled.img`
  object-fit: contain;
  width: 1091px;
`;

export const MainTitleArea = styled.div`
  width: 1180px;
  margin: 50px auto 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const MainTitle = styled.div<{ color: string }>`
  margin: 0 auto;
  padding: 10px;
  background-color: ${(props) => props.color};
  border-radius: 50px;
  font-size: 50px;
  color: #fff;
  font-family: 'Gosanja';
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const MainTitleSpanLeft = styled.div<{ color: string }>`
  margin-left: 20px;
  margin-right: 40px;
  opacity: 0.7;
  border-radius: 50px;
  width: 42px;
  aspect-ratio: 1;
  border: 7px solid ${(props) => props.color};
`;

export const MainTitleSpanRight = styled.div<{ color: string }>`
  margin-left: 40px;
  margin-right: 20px;
  opacity: 0.7;
  border-radius: 50px;
  width: 42px;
  aspect-ratio: 1;
  border: 7px solid ${(props) => props.color};
`;

export const MainImgArea = styled.div`
  width: 1091px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px auto 50px;
  align-items: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid #e2e2e2;
`;

export const MainImg = styled.img`
  width: 1091px;
  object-fit: contain;
`;

export const InfoArea = styled.div`
  margin: 50px auto 0;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const InfoTitle = styled.div`
  color: #0081f0;
  font-size: 36px;
  font-family: 'Gosanja';
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;
export const InfoTitleSpan = styled.span``;
export const InfoTitleImg = styled.img`
  width: 40px;
  margin-right: 10px;
`;

export const InfoButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  gap: 30px;
  margin-bottom: 50px;
`;

export const InfoButton = styled.div`
  background-color: #0081f0;
  font-size: 20px;
  font-family: 'Gosanja';
  padding: 15px 30px;
  border-radius: 50px;
  color: #fff;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
`;

export const InfoButtonSpan = styled.span`
  font-size: 20px;
  font-family: 'Gosanja';
  margin-left: 10px;
  border-radius: 50px;
  color: #fff;
  width: 10px;
`;

export const InfoButtonImg = styled.img`
  width: 16px;
`;

export const ConfigArea = styled.div<{ color: string }>`
  width: 1091px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4%;
  flex-wrap: wrap;
`;

export const ConfigData = styled.div`
  width: 240px;
  height: 240px;
  margin-bottom: 80px;
  cursor: pointer;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const ConfigDataTitle = styled.div`
  font-family: 'Gosanja';
  padding: 5px 10px;
  font-size: 16px;
`;

export const ConfigVideoData = styled.div`
  width: 22%;
  height: 300px;
  margin-bottom: 50px;
  cursor: pointer;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
`;

export const ConfigDataImg = styled.img`
  width: 240px;
  height: 240px;
  object-fit: contain;
  margin-bottom: 10px;
`;

export const SelectArea = styled.div`
  width: 1091px;
  margin: 0px auto 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SelectDiv = styled.div`
  width: 2.5%;
  margin: 20px auto;
  text-align: center;
  border-bottom: 1px solid;
`;

export const SelectLabel = styled.div`
  margin: 0px auto;
  text-align: center;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const SelectForm = styled.ul<{ selectType: string }>`
  display: flex;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  flex-flow: wrap;
  white-space: nowrap;
  width: 82%;
`;

export const SelectItem = styled.li<{ active: boolean; selectType: string; color: string }>`
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Gosanja';
  font-size: 20px;
  width: ${(props) => (props.selectType === 'holder' ? '122px' : '150px')};
  color: ${(props) => (props.active ? '#fff' : '#9d9d9d')};
  border: 1px solid ${(props) => (props.active ? props.color : '#ccc')};
  border-radius: 30px;
  margin: ${(props) => (props.selectType === 'holder' ? '10px 10px' : '10px 5px')};
  background: ${(props) =>
    props.active
      ? `linear-gradient(to bottom,${props.color} 95%, ${props.color} 5px,  ${props.color} 5%)`
      : '#fff'};
  cursor: pointer;
`;
export const SelectItemSpan = styled.div`
  font-size: 12px;
`;

export const SearchConditionArea = styled.div`
  width: 1091px;
  height: auto;
  margin: 20px auto 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
`;

export const searchConditionHeader = styled.div`
  width: 150px;
  display: flex;
  background-color: #f3f3f3;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #4d4d4d;
`;

export const SearchConditionBody = styled.div`
  width: calc(100% - 150px);
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
`;

export const SearchConditionItem = styled.div<{ selected: boolean }>`
  margin: 5px;
  padding: 0px 10px 0px 10px;
  font-size: 16px;
  color: ${(props) => (props.selected ? '#0087ED' : '#4D4D4D')};
  border-radius: 20px;
  border: ${(props) => (props.selected ? '1px solid #0087ED' : '0px solid #0087ED')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  cursor: pointer;
`;

export const SearchConditionAgeArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

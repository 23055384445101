import * as s from '../UpdateProgramStyled';

interface SelectFieldProps {
  option: {
    holder: string;
    field: string;
    age: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  fieldList: any;
}

const SelectField = (props: SelectFieldProps) => {
  return (
    <s.UploadProgramAreaHeaderSelect>
      <s.UploadProgramAreaSelectHeader>놀이 선택</s.UploadProgramAreaSelectHeader>
      <s.UploadProgramAreaHeaderSelect>
        {props.fieldList &&
          Object.entries(props.fieldList).map(([key, value]) => {
            return (
              <>
                <s.UploadProgramAreaSelectType
                  id={props.fieldList[key].menuCd}
                  value={props.fieldList[key].menuCd}
                  defaultChecked={props.option.field === props.fieldList[key].menuCd}
                  onChange={(e: any) =>
                    props.option.field === e.target.value
                      ? props.onChangeOption('field', '')
                      : props.onChangeOption('field', e.target.value)
                  }
                  type={'checkbox'}
                />
                <s.UploadProgramAreaSelectTypeLabel htmlFor={props.fieldList[key].menuCd}>
                  {props.option.field === props.fieldList[key].menuCd ? (
                    <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                  ) : null}
                </s.UploadProgramAreaSelectTypeLabel>
                <s.UploadProgramAreaSelectText htmlFor={props.fieldList[key].menuCd}>
                  {props.fieldList[key].menuNm}
                </s.UploadProgramAreaSelectText>
              </>
            );
          })}
      </s.UploadProgramAreaHeaderSelect>
    </s.UploadProgramAreaHeaderSelect>
  );
};

export default SelectField;

import React from 'react';

import * as s from '../UpdateEduStyled';

interface SelectVislbleProps {
  option: {
    field: string;
    visible: string;
    age: string;
    mediaSubMenu: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
}

const SelectVislble = (props: SelectVislbleProps) => {
  return (
    <s.UploadProgramAreaHeaderSelect>
      <s.UploadProgramAreaSelectType
        id={'visible'}
        value={'visible'}
        defaultChecked={props.option.visible === 'visible'}
        onChange={(e: any) =>
          props.option.visible === e.target.value
            ? props.onChangeOption('visible', 'none')
            : props.onChangeOption('visible', e.target.value)
        }
        type={'checkbox'}
        disabled={!props.isModifying}
      />
      <s.UploadProgramAreaSelectTypeLabel htmlFor={'visible'}>
        {props.option.visible === 'visible' ? (
          <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
        ) : null}
      </s.UploadProgramAreaSelectTypeLabel>
      <s.UploadProgramAreaSelectText htmlFor={'visible'}>보이기</s.UploadProgramAreaSelectText>
    </s.UploadProgramAreaHeaderSelect>
  );
};

export default SelectVislble;

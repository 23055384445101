import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1075px;
  position: relative;
  z-index: 1;
  margin: 0 auto 50px;
  text-align: center;
  padding-bottom: 30px;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;


export const OrderHeader = styled.div`
  width: 1075px;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 35px 0 10px;
  vertical-align: middle;
`;

export const OrderHeaderTitleArea = styled.div`
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const OrderHeaderTitle = styled.div`
  float: left;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const OrderHeaderTitleDivide = styled.div`
  float: left;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  padding-top: 3px;
  margin: 0 10px;
  color: #e2e2e2;
`;

export const OrderHeaderSubTitle = styled.div`
  float: left;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  padding-top: 14px;
  margin-left: 20px;
  color: #e2e2e2;
`;

export const OrderHeaderRightArea = styled.div`
  float: right;
  text-align: center;
  vertical-align: middle;
`;
export const OrderHeaderRightIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 5px;
  vertical-align: middle;
`;

export const OrderHeaderRightText = styled.div`
  font-weight: 400;
  color: #4d4d4d;
  vertical-align: middle;
`;


export const SearchConditionArea = styled.div`
  width: 1075px;
  height: auto;
  margin: 20px auto 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
`;

export const searchConditionHeader = styled.div`
  width: 150px;
  display: flex;
  background-color: #F3F3F3;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #4D4D4D;
`;

export const SearchConditionBody = styled.div`
  width: calc(100% - 150px);
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
`;

export const SearchConditionItem = styled.div<{selected: boolean}>`
  margin: 5px;
  padding: 0px 10px 0px 10px;
  font-size: 16px;
  color: ${(props) => props.selected ? '#0087ED' : '#4D4D4D'};
  border-radius: 20px;
  border: ${(props) => props.selected ? '1px solid #0087ED' : '0px solid #0087ED'};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  cursor: pointer;
`;

export const SearchConditionAgeArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const SearchConditionMonthArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 0.5px solid #AAAAAA;
`;

export const SearchConditionMediaArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 0.5px solid #AAAAAA;
`;

export const ProgramTypeTitleArea = styled.div`
  width: 100%;
  margin: 10px auto 0;
  height: auto;
  border-bottom: 1px solid #0087ed;
  padding: 10px 0 10px;
  vertical-align: baseline;
  display: flex;
  justify-content: space-between;
`;

export const ProgramTypeTitleFixedPart = styled.div`
  font-size: 18px;
  display: inline-block;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 5px 3px;
  color: #0087ed;
`;

export const ProgramTypeTitlePart = styled.div`
  font-size: 24px;
  display: inline-block;
  line-height: 32px;
  font-weight: 800;
  margin: 0 3px;
  color: #0087ed;
`;

export const ProgramSearchTextArea = styled.div`
  line-height: 35px;
  width: 220px;
  border: 1.5px solid #0087ed;
  float: right;
`;

export const ProgramSearchText = styled.input`
  width: 180px;
  height: 35px;
  padding-left: 10px;
  border: none;
`;

export const ProgramSearchButton = styled.div`
  display: inline-block;
  width: 35px;
  height: 35px;
  vertical-align: middle;
  cursor: pointer;
`;

export const ProgramSearchImg = styled.img`
  width: 30px;
  height: 30px;
  vertical-align: baseline;
  margin: 2px;
`;

export const ProgramList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 1091px;
  margin: 0px auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
`;

export const ProgramListHeader = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  font-size: 18px;
  color: #4D4D4D;
  font-weight: 500;
  align-items: center;
  justify-content: flex-start;
  background-color: #eee;
  border-bottom: 1px solid #aaa;
`;

export const ProgramListSelect = styled.div`
  width: 5%;
  padding: 0 5px;
`;

export const ProgramListCenterCol = styled.div`
  flex: 1;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

export const ProgramListStartCol = styled.div`
  flex: 4;
  padding: 5px;
  text-align: start;
`;

export const ProgramCheckBox = styled.input``;

export const ProgramListArea = styled.div`
  width: 1091px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
`;

export const ProgramForm = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  font-size: 20px;
  color: #5f5f5f;
  font-weight: 500;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #aaa;
`;


export const SelectDoneButton = styled.div`
  position: absolute;
  margin: 0 auto;
  z-index: 9999;
  right: 15%;  
  bottom: 0%;
  display: flex;
  width: 86px;
  height: 37px;
  font-size: 16px;
  color: #0087ED;
  border: 1px solid #0087ED;
  border-radius: 50px;
  background-color: #FFFFFF;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;
import React, { Dispatch, SetStateAction } from 'react';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';

import * as s from './DailyPlanStyled';
import SearchModal from './searchModal';
import LoadModal from './loadModal';
import * as util from '../../../../util/util';

const warning = '/static/img/warning.png';
const pen = '/static/img/pen.png';
const check = '/static/img/check.png';
const selectFile = '/static/img/selectFile.png';
const plus = '/static/img/button_plus.png';
const minus = '/static/img/button_minus.png';

interface DailyPlanProps {
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  ageAuths: any;

  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  open: () => void;
  acceptedFiles: File[];
  isDragActive: boolean;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  timeList: any;
  setTimeList: any;
  subject: string;
  lifeSubject: string;
  expectations: string;
  onChangeSubject: React.ChangeEventHandler<HTMLTextAreaElement>;
  onChangeLifeSubject: React.ChangeEventHandler<HTMLTextAreaElement>;
  onChangeExpectations: React.ChangeEventHandler<HTMLTextAreaElement>;
  onSubmit: React.MouseEventHandler<HTMLDivElement> | undefined;

  isOpenSearchModal: boolean;
  setIsOpenSearchModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentTimeIndex: number;
  setCurrentTimeIndex: React.Dispatch<React.SetStateAction<number>>;
  pushActivityList: (
    avtivities: {
      text: string;
      planActivityId: string;
      typeCd: string;
    }[],
    timeIndex: number,
  ) => void;
  topicList: any;
  subTopicList: any;
  colorList: any;
  selectedFile: any;
  modalPosition: {
    topIndex: number;
    preLen: number;
    subLength: number;
  };
  setModalPosition: React.Dispatch<
    React.SetStateAction<{
      topIndex: number;
      preLen: number;
      subLength: number;
    }>
  >;
  addTime: () => void;
  onChangeTimeList: (index: number, optionNm: string, optionValue: string) => void;
  deleteTime: (index: number) => void;
  isHoliday: boolean;
  checkIsHoliday: () => void;
  deleteActivity: (index: number, subIndex: number) => void;
  isOpenLoadModal: boolean;
  setIsOpenLoadModal: Dispatch<SetStateAction<boolean>>;
  loadDailyPlan: (age: any, year: any, month: any, week: any, day: any) => void;

  totalEvaluation: string;
  setTotalEvaluation: Dispatch<SetStateAction<string>>;
  caution: any;
  setCaution: Dispatch<SetStateAction<any>>;
  defaultEducation: any;
  setDefaultEducation: Dispatch<SetStateAction<any>>;
  isOpenCautionModal: boolean;
  setIsOpenCautionModal: Dispatch<SetStateAction<boolean>>;
  isOpenDefaultEducationModal: boolean;
  setIsOpenDefaultEducationModal: Dispatch<SetStateAction<boolean>>;
}

const DailyPlanPresenter = (props: DailyPlanProps) => {
  return (
    <>
      {props.isOpenSearchModal ? (
        <SearchModal
          ageAuths={props.ageAuths}
          option={props.option}
          topicList={props.topicList}
          subTopicList={props.subTopicList}
          colorList={props.colorList}
          timeList={props.timeList}
          currentTimeIndex={props.currentTimeIndex}
          setIsOpenSearchModal={(arg0: boolean) => {
            props.setIsOpenSearchModal(arg0);
            if (!arg0) {
              props.setCurrentTimeIndex(-1);
            }
          }}
          onPushActivity={(arg0: any[]) => {
            let tempTimes = [...props.timeList];
            tempTimes[props.currentTimeIndex].activityList = arg0;
            tempTimes[props.currentTimeIndex].isProgram = true;
            props.setTimeList(tempTimes);
          }}
          modalPosition={props.modalPosition}
          setModalPosition={props.setModalPosition}
        />
      ) : null}
      {props.isOpenLoadModal ? (
        <LoadModal
          ageAuths={props.ageAuths}
          setIsOpenLoadModal={(arg0: boolean) => {
            props.setIsOpenLoadModal(arg0);
          }}
          loadDailyPlan={props.loadDailyPlan}
        />
      ) : null}
      <s.Container currentTimeIndex={props.currentTimeIndex} lastIndex={props.timeList.length - 1}>
        <s.PlanCreateHeaderWrapper>
          <s.PlanCreateHeader>
            <s.PlanCreateHeaderText color="#0087ed">{props.option.year}년 </s.PlanCreateHeaderText>
            <s.PlanCreateHeaderText color="#4d4d4d"> 만 </s.PlanCreateHeaderText>
            <s.PlanCreateHeaderText color="#0087ed">
              {props.option.age ? props.ageAuths[props.option.age].comnCdNm : ''}
            </s.PlanCreateHeaderText>
            <s.PlanCreateHeaderText color="#0087ed">
              {' '}
              {props.option.month}월{' '}
            </s.PlanCreateHeaderText>
            <s.PlanCreateHeaderText color="#0087ed">
              {' '}
              {props.option.week}주차{' '}
            </s.PlanCreateHeaderText>
            <s.PlanCreateHeaderText color="#0087ed"> {props.option.day}일 </s.PlanCreateHeaderText>
            <s.PlanCreateHeaderText color="#4d4d4d">일일 교육계획안 </s.PlanCreateHeaderText>
          </s.PlanCreateHeader>
        </s.PlanCreateHeaderWrapper>
        <s.PlanWriteWrapper>
          <s.UploadHead>
            <s.UploadHeadTitle>일일교육계획안 주차 작성</s.UploadHeadTitle>
            <s.UploadHeadInfo>
              <s.UploadHeadInfoImg src={warning} />
              <s.UploadHeadInfoText>ZIP파일 · 단일 jpg는 업로드 불가입니다.</s.UploadHeadInfoText>
            </s.UploadHeadInfo>
          </s.UploadHead>
          <s.UploadBody isDragActive={false}>
            <s.WriteButtonWrapper>
              <s.UploadContentAreaHeader>
                <s.UploadContentAreaHeaderLeft>
                  <s.UploadContentAreaHeaderIcon
                    src={!props.isEditing ? '/static/img/pen.png' : '/static/img/red_pen.png'}
                  />
                  <s.UploadContentAreaHeaderLeftText
                    onClick={() => (!props.isEditing ? props.setIsEditing(true) : null)}
                    isColor={!props.isEditing}
                  >
                    수정
                  </s.UploadContentAreaHeaderLeftText>
                </s.UploadContentAreaHeaderLeft>
                <s.HolidayCheckArea>
                  <s.HolidayCheck type="checkbox" id={`Holiday_chk`} />
                  <s.HolidayLabel htmlFor={`Holiday_chk`} onClick={props.checkIsHoliday}>
                    <s.HolidayCheckBox>
                      <s.HolidayCheckImg src={check} active={props.isHoliday} />
                    </s.HolidayCheckBox>
                    휴무
                  </s.HolidayLabel>
                </s.HolidayCheckArea>
                <s.UploadContentAreaHeaderRight>
                  <s.UploadContentAreaHeaderIcon
                    src={props.isEditing ? '/static/img/check.png' : '/static/img/green_check.png'}
                  />
                  <s.UploadContentAreaHeaderRightText
                    onClick={() => (props.isEditing ? props.setIsEditing(false) : null)}
                    isColor={props.isEditing}
                  >
                    완료
                  </s.UploadContentAreaHeaderRightText>
                </s.UploadContentAreaHeaderRight>
              </s.UploadContentAreaHeader>
            </s.WriteButtonWrapper>
            {props.isHoliday ? (
              <s.WritingBoardWrapper>
                <s.WritingBoardContent>
                  <s.WritingBoardInfo style={{ height: '50px', justifyContent: 'center' }}>
                    <s.WritingBoardIntoText>내용</s.WritingBoardIntoText>
                  </s.WritingBoardInfo>
                  <s.WritingBoardTextInputWrapper>
                    <s.WritingBoardTextInput
                      placeholder="텍스트를 입력해주세요."
                      style={{ height: '40px' }}
                      disabled={!props.isEditing}
                      value={props.subject}
                      onChange={props.onChangeSubject}
                    />
                  </s.WritingBoardTextInputWrapper>
                </s.WritingBoardContent>
              </s.WritingBoardWrapper>
            ) : (
              <>
                <s.WritingBoardWrapper>
                  <s.WritingBoardContent>
                    <s.WritingBoardInfo style={{ height: '50px', justifyContent: 'center' }}>
                      <s.WritingBoardIntoText>생활주제</s.WritingBoardIntoText>
                    </s.WritingBoardInfo>
                    <s.WritingBoardTextInputWrapper>
                      <s.WritingBoardTextInput
                        placeholder="텍스트를 입력해주세요."
                        style={{ height: '40px' }}
                        disabled={!props.isEditing}
                        value={props.lifeSubject}
                        onChange={props.onChangeLifeSubject}
                      />
                    </s.WritingBoardTextInputWrapper>
                  </s.WritingBoardContent>
                  <s.WritingBoardContent>
                    <s.WritingBoardInfo style={{ height: '50px', justifyContent: 'center' }}>
                      <s.WritingBoardIntoText>주제</s.WritingBoardIntoText>
                    </s.WritingBoardInfo>
                    <s.WritingBoardTextInputWrapper>
                      <s.WritingBoardTextInput
                        placeholder="텍스트를 입력해주세요."
                        style={{ height: '40px' }}
                        disabled={!props.isEditing}
                        value={props.subject}
                        onChange={props.onChangeSubject}
                      />
                    </s.WritingBoardTextInputWrapper>
                  </s.WritingBoardContent>
                  <s.WritingBoardContent>
                    <s.WritingBoardInfo>
                      <s.WritingBoardIntoText>교사의 기대</s.WritingBoardIntoText>
                      <s.WritingBoardInfoNotice>
                        (최대 0000글자만 작성 가능)
                      </s.WritingBoardInfoNotice>
                    </s.WritingBoardInfo>
                    <s.WritingBoardTextInputWrapper>
                      <s.WritingBoardTextInput
                        placeholder="텍스트를 입력해주세요."
                        disabled={!props.isEditing}
                        value={props.expectations}
                        onChange={props.onChangeExpectations}
                      />
                    </s.WritingBoardTextInputWrapper>
                  </s.WritingBoardContent>
                  <s.WritingBoardContent>
                    <s.WritingBoardInfo>
                      <s.WritingBoardIntoText>총평</s.WritingBoardIntoText>
                      <s.WritingBoardInfoNotice>
                        (최대 0000글자만 작성 가능)
                      </s.WritingBoardInfoNotice>
                    </s.WritingBoardInfo>
                    <s.WritingBoardTextInputWrapper>
                      <s.WritingBoardTextInput
                        placeholder="텍스트를 입력해주세요."
                        disabled={!props.isEditing}
                        value={props.totalEvaluation}
                        onChange={(e) => props.setTotalEvaluation(e.target.value)}
                      />
                    </s.WritingBoardTextInputWrapper>
                  </s.WritingBoardContent>
                  <s.WritingBoardContent>
                    <s.WritingBoardInfo style={{ height: '50px', justifyContent: 'center' }}>
                      <s.WritingBoardIntoText>안전교육</s.WritingBoardIntoText>
                    </s.WritingBoardInfo>
                    <s.WritingBoardTextInputWrapper>
                      {props.caution ? (
                        <div>
                          [{props.caution.domain.comnCdNm}] {props.caution.activityNm}
                        </div>
                      ) : null}
                      <s.ContentAddColButton onClick={() => props.setIsOpenCautionModal(true)}>
                        찾기
                      </s.ContentAddColButton>
                      {props.isOpenCautionModal ? (
                        <SearchModal
                          ageAuths={props.ageAuths}
                          option={props.option}
                          topicList={props.topicList}
                          subTopicList={props.subTopicList}
                          colorList={props.colorList}
                          timeList={[]}
                          currentTimeIndex={props.currentTimeIndex}
                          setIsOpenSearchModal={(arg0: boolean) => {
                            props.setIsOpenCautionModal(arg0);
                          }}
                          onPushActivity={(arg0: any[]) => {
                            props.setCaution(arg0[0]);
                          }}
                          modalPosition={props.modalPosition}
                          setModalPosition={props.setModalPosition}
                        />
                      ) : null}
                    </s.WritingBoardTextInputWrapper>
                  </s.WritingBoardContent>
                  <s.WritingBoardContent>
                    <s.WritingBoardInfo style={{ height: '50px', justifyContent: 'center' }}>
                      <s.WritingBoardIntoText>기본생활</s.WritingBoardIntoText>
                    </s.WritingBoardInfo>
                    <s.WritingBoardTextInputWrapper>
                      {props.defaultEducation ? (
                        <div>
                          [{props.defaultEducation.domain.comnCdNm}]{' '}
                          {props.defaultEducation.activityNm}
                        </div>
                      ) : null}
                      <s.ContentAddColButton
                        onClick={() => props.setIsOpenDefaultEducationModal(true)}
                      >
                        찾기
                      </s.ContentAddColButton>
                      {props.isOpenDefaultEducationModal ? (
                        <SearchModal
                          ageAuths={props.ageAuths}
                          option={props.option}
                          topicList={props.topicList}
                          subTopicList={props.subTopicList}
                          colorList={props.colorList}
                          timeList={[]}
                          currentTimeIndex={props.currentTimeIndex}
                          setIsOpenSearchModal={(arg0: boolean) => {
                            props.setIsOpenDefaultEducationModal(arg0);
                          }}
                          onPushActivity={(arg0: any[]) => {
                            props.setDefaultEducation(arg0[0]);
                          }}
                          modalPosition={props.modalPosition}
                          setModalPosition={props.setModalPosition}
                        />
                      ) : null}
                    </s.WritingBoardTextInputWrapper>
                  </s.WritingBoardContent>
                </s.WritingBoardWrapper>

                <s.DailyPlanArea>
                  {props.timeList.map((item: any, index: number) => (
                    <s.DailyPlanForm>
                      <s.DailyPlanFormHeaderArea
                        color={
                          props.colorList.length > 0 && item.color
                            ? props.colorList[item.color].comnCdNm.split('/')[0]
                            : null
                        }
                      >
                        <s.ColorListArea>
                          {Object.keys(props.colorList).map((key: any) => {
                            return (
                              <s.ColorButton
                                isChoose={item.color === key}
                                color={props.colorList[key].comnCdNm.split('/')[0]}
                                onClick={() =>
                                  props.onChangeTimeList(
                                    index,
                                    'color',
                                    props.colorList[key].comnCd,
                                  )
                                }
                              />
                            );
                          })}
                        </s.ColorListArea>
                        <s.ContentTimeTileFormInput
                          placeholder="텍스트를 입력해주세요."
                          type="text"
                          value={item.mainTitle}
                          onChange={(e) =>
                            props.onChangeTimeList(index, 'mainTitle', e.target.value)
                          }
                        />
                        <s.ContentListButton onClick={() => props.deleteTime(index)}>
                          <s.ContentListButtonImg src={'/static/img/button_minus.png'} />
                        </s.ContentListButton>
                      </s.DailyPlanFormHeaderArea>
                      <s.DailyPlanFormContentArea>
                        <s.ContentTimeArea>
                          <s.ContentTimeColumn>
                            시간
                            <s.ContentTimeInput
                              timeType="start"
                              placeholder="입력"
                              type="text"
                              name={`startTime ${index}`}
                              value={item.startTime}
                              onChange={(e) => {
                                item.startTime = e.target.value;
                                let temps = [...props.timeList];
                                temps[index].startTime = item.startTime;
                                props.setTimeList(temps);
                              }}
                            />
                          </s.ContentTimeColumn>
                        </s.ContentTimeArea>
                        <s.ContentInfoArea>
                          <s.ContentInfoForm>
                            <s.ContentInfoFormTitle>주제</s.ContentInfoFormTitle>
                            {item.isSubTitle ? (
                              <s.ContentInfoFormInput
                                placeholder="텍스트를 입력해주세요."
                                type="text"
                                name={`subTitle ${index}`}
                                value={item.subTitle}
                                onChange={(e) => {
                                  item.subTitle = e.target.value;
                                  let temps = [...props.timeList];
                                  temps[index].subTitle = item.subTitle;
                                  props.setTimeList(temps);
                                }}
                              />
                            ) : (
                              <s.ContentInfoFormDisabledInput />
                            )}
                            <s.ContentInfoFormCheckArea>
                              <s.ContentInfoFormCheck
                                type="checkbox"
                                id={`subTitle_chk ${index}`}
                              />
                              <s.ContentInfoFormLabel
                                htmlFor={`subTitle_chk ${index}`}
                                onClick={() => {
                                  item.isSubTitle = !item.isSubTitle;
                                  let temps = [...props.timeList];
                                  temps[index].isSubTitle = item.isSubTitle;
                                  props.setTimeList(temps);
                                }}
                              >
                                <s.ContentInfoFormCheckBox>
                                  <s.ContentInfoFormCheckImg
                                    src={check}
                                    active={!item.isSubTitle}
                                  />
                                </s.ContentInfoFormCheckBox>
                                주제 없음
                              </s.ContentInfoFormLabel>
                            </s.ContentInfoFormCheckArea>
                          </s.ContentInfoForm>
                          {item.activityList.map((subItem: any, subIndex: number) => (
                            <s.ContentInfoForm>
                              <s.ContentInfoFormTitle />
                              {item.isProgram ? (
                                <s.InputWrapper>
                                  <s.ContentInfoFormInput
                                    placeholder="프로그램을 선택해주세요."
                                    disabled
                                    style={{ width: '100%' }}
                                    value={!subItem.activityNm ? '' : subItem.activityNm}
                                  />
                                  <s.ContentEvaluationInput
                                    type="text"
                                    placeholder="강의평을 입력해주세요."
                                    value={subItem.evaluation}
                                    onChange={(e) => {
                                      let subTemps = [...item.activityList];
                                      subItem.evaluation = e.target.value;
                                      subTemps[subIndex].evaluation = subItem.evaluation;
                                      let temps = [...props.timeList];
                                      temps[index].activityList = item.activityList;
                                      props.setTimeList(temps);
                                    }}
                                  />
                                </s.InputWrapper>
                              ) : (
                                <s.InputWrapper>
                                  <s.ContentInfoFormTextarea
                                    placeholder="텍스트를 입력해주세요."
                                    name={`text ${subIndex} of ${index}`}
                                    value={subItem.text}
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                      let subTemps = [...item.activityList];
                                      subItem.text = e.target.value;
                                      subTemps[subIndex].text = subItem.text;
                                      let temps = [...props.timeList];
                                      temps[index].activityList = item.activityList;
                                      props.setTimeList(temps);
                                    }}
                                  />
                                  <s.ContentEvaluationInput
                                    type="text"
                                    placeholder="강의평을 입력해주세요."
                                    value={subItem.evaluation}
                                    onChange={(e) => {
                                      let subTemps = [...item.activityList];
                                      subItem.evaluation = e.target.value;
                                      subTemps[subIndex].evaluation = subItem.evaluation;
                                      let temps = [...props.timeList];
                                      temps[index].activityList = item.activityList;
                                      props.setTimeList(temps);
                                    }}
                                  />
                                </s.InputWrapper>
                              )}

                              <s.ContentInfoFormCheckArea>
                                <s.ContentInfoFormCheck
                                  type="checkbox"
                                  id={`activity_chk ${subIndex} of ${index}`}
                                  defaultChecked={!item.isProgram}
                                  onChange={(e) => {
                                    let temps = [...props.timeList];
                                    temps[index].isProgram = !temps[index].isProgram;
                                    props.setTimeList(temps);
                                  }}
                                />
                                <s.ContentInfoFormLabel
                                  htmlFor={`activity_chk ${subIndex} of ${index}`}
                                >
                                  <s.ContentInfoFormCheckBox>
                                    <s.ContentInfoFormCheckImg
                                      src={check}
                                      active={!item.isProgram}
                                    />
                                  </s.ContentInfoFormCheckBox>
                                  내용작성
                                  <s.ContentAddColButton
                                    style={{ color: '#DE7575' }}
                                    onClick={() => {
                                      props.deleteActivity(index, subIndex);
                                    }}
                                  >
                                    <s.ContentAddColButtonImg src={minus} />
                                  </s.ContentAddColButton>
                                </s.ContentInfoFormLabel>
                              </s.ContentInfoFormCheckArea>
                              {item.activityList.length - 1 === subIndex && item.isProgram ? (
                                <s.ContentInfoFormButtonArea>
                                  <s.ContentInfoFormButton
                                    onClick={() => {
                                      let preLen = 0;
                                      props.timeList.forEach((timeTemp: any, tempIndex: number) => {
                                        if (tempIndex < index) {
                                          preLen +=
                                            timeTemp.activityList.length <= 2
                                              ? 0
                                              : timeTemp.activityList.length - 2;
                                        } else {
                                          return;
                                        }
                                      });
                                      props.setModalPosition({
                                        topIndex: index,
                                        preLen: preLen,
                                        subLength: subIndex,
                                      });
                                      props.setCurrentTimeIndex(index);
                                      props.setIsOpenSearchModal(true);
                                    }}
                                  >
                                    자료 가져오기
                                  </s.ContentInfoFormButton>
                                </s.ContentInfoFormButtonArea>
                              ) : null}
                            </s.ContentInfoForm>
                          ))}
                        </s.ContentInfoArea>
                      </s.DailyPlanFormContentArea>

                      <s.ContentAddColButtonArea>
                        <s.ContentAddColButton
                          onClick={() => {
                            let newItem: any = {
                              activityId: null,
                              text: '',
                            };
                            let subTemps = [...item.activityList, newItem];
                            item.activityList = subTemps;
                            let temps = [...props.timeList];
                            temps.splice(index, 1, item);
                            props.setTimeList(temps);
                          }}
                        >
                          <s.ContentAddColButtonImg src={plus} /> 행 추가 하기
                        </s.ContentAddColButton>
                      </s.ContentAddColButtonArea>
                    </s.DailyPlanForm>
                  ))}
                </s.DailyPlanArea>
              </>
            )}
            <s.ContentConfirmButtonArea>
              {props.isHoliday ? null : (
                <s.ContentConfirmButton onClick={props.addTime}>
                  시간 추가하기
                </s.ContentConfirmButton>
              )}
              <s.ContentConfirmButton onClick={() => props.setIsOpenLoadModal(true)}>
                불러오기
              </s.ContentConfirmButton>
              <s.ContentConfirmButton onClick={props.onSubmit}>저장하기</s.ContentConfirmButton>
            </s.ContentConfirmButtonArea>
          </s.UploadBody>
        </s.PlanWriteWrapper>
      </s.Container>
    </>
  );
};

export default DailyPlanPresenter;

import React from 'react';

import * as s from './PrevPlanModalStyled';

const xButton = '/static/img/xButton.png';
const leftArrow = '/static/img/L_arrow.png';
const rightArrow = '/static/img/R_arrow.png';

interface PrevPlanModalProps {
  type: string;
  setPrevPlanModalOn: (arg0: boolean) => void;

  ageAuths: any;
  yearList: number[];

  age: string | null;
  onSetAge: (arg0: string) => void;
  month: number | null;
  onSetMonth: (arg0: number) => void;
  year: number | null;
  onSetYear: (arg0: number) => void;

  monthList: number[];

  page: number;
  nowPage: number;
  pagePostCount: number;
  setPage: (arg0: number) => void;
  onNextPage: () => void;
  onPrevPage: () => void;

  prevPlan: any;
  prevPlanList: any[];
  pageList: any[];

  onSetPrevData: (arg0: any) => void;
  onApplyPrevData: () => void;
}

const PrevPlanModalPresenter = (props: PrevPlanModalProps) => {
  return (
    <s.Container>
      <s.ModalOffButtonWrapper>
        <s.ModalOffButtonWrapperDiv />
        <s.Title>이전 데이터 불러오기</s.Title>
        <s.ModalOffButtonWrapperDiv>
          <s.ModalOffButton onClick={() => props.setPrevPlanModalOn(false)}>
            <s.ModalOffButtonImg src={xButton} />
          </s.ModalOffButton>
        </s.ModalOffButtonWrapperDiv>
      </s.ModalOffButtonWrapper>
      <s.YearListWrapper>
        {props.yearList.map((year: number) => {
          return (
            <s.EachYearButton onClick={() => props.onSetYear(year)} isSelect={props.year === year}>
              <s.EachYearButtonText>{year}년</s.EachYearButtonText>
            </s.EachYearButton>
          );
        })}
      </s.YearListWrapper>
      {props.type === 'monthly' ? null : (
        <s.MonthListWrapper>
          {props.monthList.map((month: number) => {
            return (
              <s.EachMonthButton
                onClick={() => props.onSetMonth(month)}
                isSelect={props.month === month}
              >
                <s.EachMonthButtonText>{month}월</s.EachMonthButtonText>
              </s.EachMonthButton>
            );
          })}
        </s.MonthListWrapper>
      )}
      <s.AgeListWrapper>
        {Object.entries(props.ageAuths).map(([key, value]: [string, any]) => {
          return (
            <s.EachAgeButton onClick={() => props.onSetAge(key)} isSelect={key === props.age}>
              <s.EachAgeButtonText>{value.comnCdNm}</s.EachAgeButtonText>
            </s.EachAgeButton>
          );
        })}
      </s.AgeListWrapper>
      <s.PrevPlanWrapper>
        {props.prevPlanList.map((prevPlan: any) => {
          return (
            <s.EachPrevPlan
              onClick={() => props.onSetPrevData(prevPlan)}
              isSelect={
                props.prevPlan && props.prevPlan.planId && props.prevPlan.planId === prevPlan.planId
              }
            >
              <s.EachPrevPlanInfo>{prevPlan.year}년</s.EachPrevPlanInfo>
              <s.EachPrevPlanInfo>{prevPlan.month}월</s.EachPrevPlanInfo>
              <s.EachPrevPlanInfo>{prevPlan.week}주</s.EachPrevPlanInfo>
              <s.EachPrevPlanInfo>{prevPlan.age.comnCdNm}</s.EachPrevPlanInfo>
              <s.EachPrevPlanDetail>{prevPlan.subject}</s.EachPrevPlanDetail>
            </s.EachPrevPlan>
          );
        })}
      </s.PrevPlanWrapper>
      <s.PageListWrapper>
        {props.page > props.pagePostCount ? (
          <s.PageArrowButton onClick={props.onPrevPage}>
            <s.PageArrowButtonImg src={leftArrow} />
          </s.PageArrowButton>
        ) : null}
        {props.pageList
          .slice(props.nowPage * props.pagePostCount, (props.nowPage + 1) * props.pagePostCount)
          .map((page: number) => {
            return (
              <s.EachPage isSelect={page === props.page} onClick={() => props.setPage(page)}>
                {page}
              </s.EachPage>
            );
          })}
        {props.pageList.length > (props.nowPage + 1) * props.pagePostCount ? (
          <s.PageArrowButton onClick={props.onNextPage}>
            <s.PageArrowButtonImg src={rightArrow} />
          </s.PageArrowButton>
        ) : null}
      </s.PageListWrapper>
      <s.ButtonWrapper>
        <s.Button onClick={props.onApplyPrevData}>확인</s.Button>
        <s.Button onClick={() => props.setPrevPlanModalOn(false)}>닫기</s.Button>
      </s.ButtonWrapper>
    </s.Container>
  );
};

export default PrevPlanModalPresenter;

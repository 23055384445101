import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { userApi } from '../../../api/api-user';
import { programApi } from '../../../api/api-program';
import * as recoilItem from '../../../util/recoilitem';
import queryString from 'query-string';
import * as util from '../../../util/util';
import UpdateProductPresenter from './UpdateProductPresenter';

import { useDropzone } from 'react-dropzone';
import { shoppingApi } from '../../../api/api-shopping';

interface Props extends RouteComponentProps {}

const UpdateProductContainer = (props: Props) => {
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const token = useRecoilValue(recoilItem.token);
  const setUserInfo = useSetRecoilState(recoilItem.userInfo);

  const [productId, setProductId] = useState<any>(null);

  const [orgThumbnailList, setOrgThumbnailList] = useState<any>([]);
  const [deletedThumbnailList, setDeletedThumbnailList] = useState<any>([]);
  const [orgDetailPageList, setOrgDetailPageList] = useState<any>([]);
  const [deletedDetailPageList, setDeletedDetailPageList] = useState<any>([]);

  const [thumbnailList, setThumbnailList] = useState<any>([]);
  const [thumbnailFileList, setThumbnailFileList] = useState<any>([]);

  const [detailPageList, setDetailPageList] = useState<any>([]);
  const [detailPageFileList, setDetailPageFileList] = useState<any>([]);

  const [productNm, setProductNm] = useState<string>('');
  const [productDv, setProductDv] = useState<string>('0');
  const [productDetailDv, setProductDetailDv] = useState<string>('0');
  const [month, setMonth] = useState<string>('0');
  const [price, setPrice] = useState<number>(0);

  const [type, setType] = useState<string>('');
  const [material, setMaterial] = useState<string>('');
  const [size, setSize] = useState<string>('');
  const [credNumber, setCredNumber] = useState<string>('');
  const [openYear, setOpenYear] = useState<string>('');
  const [madeIn, setMadeIn] = useState<string>('');
  const [bestYn, setBestYn] = useState<string>('N');
  const [useYn, setUseYn] = useState<string>('Y');

  useEffect(() => {
    let state: any = props.history.location.state;
    if (!state.item) {
      util.makeMsg('상품 조회에 실패했습니다', 'error');
      props.history.push('/registerSelect');
    } else {
      let item = state.item;
      console.log(item);
      setProductId(item.productId);
      setProductNm(item.productNm);
      setProductDv(item.productDv);
      setProductDetailDv(item.productDetailDv);
      setMonth(item.month);
      setPrice(item.price);
      setType(item.type);
      setMaterial(item.material);
      setSize(item.size);
      setCredNumber(item.credNumber);
      setOpenYear(item.openYear);
      setMadeIn(item.madeIn);
      setBestYn(item.bestYn);
      setUseYn(item.useYn);
      setOrgThumbnailList(item.thumbnailList);
      setOrgDetailPageList(item.detailList);
    }
  }, []);

  const {
    getRootProps: getThumbnailRootProps,
    getInputProps: getThumbnailInputProps,
    open: thumbnailOpen,
    acceptedFiles: thumbnailAcceptedFiles,
    isDragActive: thumbnailIsDragActive,
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: (files) => {
      let newFiles = files.map((file: File, index: number) => ({
        fileName: file.name,
        fileIndex: index,
        text: '',
      }));
      setThumbnailList([...thumbnailList, ...newFiles]);
      setThumbnailFileList([...thumbnailFileList, ...files]);
    },
  });

  const {
    getRootProps: getDetailPageRootProps,
    getInputProps: getDetailPageInputProps,
    open: detailPageOpen,
    acceptedFiles: detailPageAcceptedFiles,
    isDragActive: detailPageIsDragActive,
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: (files) => {
      let newFiles = files.map((file: File, index: number) => ({
        fileName: file.name,
        fileIndex: index,
        text: '',
      }));
      setDetailPageList([...detailPageList, ...newFiles]);
      setDetailPageFileList([...detailPageFileList, ...files]);
    },
  });

  useEffect(() => {
    console.log(orgThumbnailList);
    console.log(deletedThumbnailList);
  }, [orgThumbnailList, deletedThumbnailList]);

  const submit = async () => {
    try {
      setIsLoading(true);

      let formData: FormData = new FormData();

      if (thumbnailList.length < 1 && orgThumbnailList.length < 1) {
        util.makeMsg('썸네일은 필수입니다.', 'error');
        setIsLoading(false);
        return;
      }

      if (detailPageList.length < 1 && orgDetailPageList.length < 1) {
        util.makeMsg('상세페이지는 필수입니다.', 'error');
        setIsLoading(false);
        return;
      }

      if (!productNm.trim()) {
        util.makeMsg('상품명은 필수입니다.', 'error');
        setIsLoading(false);
        return;
      }

      if (!productDv || productDv === '0' || !productDetailDv || productDetailDv === '0') {
        util.makeMsg('상품구분은 필수입니다.', 'error');
        setIsLoading(false);
        return;
      }

      if (!price || price === 0) {
        util.makeMsg('가격은 필수입니다.', 'error');
        setIsLoading(false);
        return;
      }

      let thumbnailViewOrderOk = true;
      let detailPageViewOrderOk = true;

      for (let i = 0; i < thumbnailList.length; i++) {
        let item = thumbnailList[i];
        if (!item.text) {
          thumbnailViewOrderOk = false;
        }
      }

      for (let i = 0; i < orgThumbnailList.length; i++) {
        let item = orgThumbnailList[i];
        if (!item.viewOrder) {
          thumbnailViewOrderOk = false;
        }
      }

      if (!thumbnailViewOrderOk) {
        util.makeMsg('썸네일 출력순서는 필수입니다.', 'error');
        setIsLoading(false);
        return;
      }

      for (let i = 0; i < detailPageList.length; i++) {
        let item = detailPageList[i];
        if (!item.text) {
          detailPageViewOrderOk = false;
        }
      }

      for (let i = 0; i < orgDetailPageList.length; i++) {
        let item = orgDetailPageList[i];
        if (!item.viewOrder) {
          detailPageViewOrderOk = false;
        }
      }

      if (!detailPageViewOrderOk) {
        util.makeMsg('상세페이지 출력순서는 필수입니다.', 'error');
        setIsLoading(false);
        return;
      }

      formData.append('productId', productId);
      formData.append('productNm', productNm);
      formData.append('productDv', String(productDv));
      formData.append('productDetailDv', String(productDetailDv));
      formData.append('price', String(price));
      formData.append('type', type);
      formData.append('material', material);
      formData.append('size', size);
      formData.append('credNumber', credNumber);
      formData.append('openYear', openYear);
      formData.append('madeIn', madeIn);
      formData.append('bestYn', bestYn);
      formData.append('useYn', useYn);

      if (month && month !== '0') {
        formData.append('month', month);
      }

      thumbnailList.forEach((item: any, index: number) => {
        formData.append(`newThumbnailList[${index}].file`, thumbnailFileList[index]);
        formData.append(`newThumbnailList[${index}].viewOrder`, item.text);
      });

      orgThumbnailList.forEach((item: any, index: number) => {
        formData.append(`updateThumbnailList[${index}].viewOrder`, item.viewOrder);
        formData.append(`updateThumbnailList[${index}].thumbnailId`, item.thumbnailId);
      });

      deletedThumbnailList.forEach((item: any, index: number) => {
        formData.append(`deleteThumbnailList[${index}].thumbnailId`, item.thumbnailId);
      });

      detailPageList.forEach((item: any, index: number) => {
        formData.append(`newDetailList[${index}].file`, detailPageFileList[index]);
        formData.append(`newDetailList[${index}].viewOrder`, item.text);
      });

      orgDetailPageList.forEach((item: any, index: number) => {
        formData.append(`updateDetailList[${index}].viewOrder`, item.viewOrder);
        formData.append(`updateDetailList[${index}].detailId`, item.detailId);
      });

      deletedDetailPageList.forEach((item: any, index: number) => {
        formData.append(`deleteDetailList[${index}].detailId`, item.detailId);
      });

      let res = await shoppingApi.updateOpenProduct(token, formData);

      if (res.data.rsltCd === '00') {
        util.makeMsg('상품이 수정되었습니다.', 'success');
        setIsLoading(false);
        props.history.push('/shopping/open');
      } else {
        util.makeMsg('상품수정에 실패하였습니다.', 'error');
        console.log(res);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      util.makeMsg('오류가 발생했습니다.', 'error');
      console.log(e);
    }
  };

  const removeThumb = async (item: any) => {
    if (item.thumbnailId) {
      let curList = orgThumbnailList;
      let delIdx = curList.findIndex((thumb: any) => thumb.thumbnailId === item.thumbnailId);
      if (delIdx > -1) {
        let delTarget = curList.splice(delIdx, 1);
        let curDeleted = deletedThumbnailList;
        curDeleted.push(delTarget[0]);
        setDeletedThumbnailList([...curDeleted]);
        setOrgThumbnailList([...curList]);
      }
    } else {
      let curList = thumbnailList;
      let targetItemIdx = thumbnailList.findIndex(
        (i: any) =>
          i.fileName === item.fileName && i.fileIndex === item.fileIndex && i.text === item.text,
      );
      if (targetItemIdx > -1) {
        let targetItem = thumbnailList[targetItemIdx];
        let curFileList = thumbnailFileList;
        curFileList.splice(targetItem.fileIndex, 1);
        curList.splice(targetItemIdx, 1);
        setThumbnailFileList([...curFileList]);
        setThumbnailList([...curList]);
      }
    }
  };

  const removeDetailPage = async (item: any) => {
    if (item.detailId) {
      let curList = orgDetailPageList;
      let delIdx = curList.findIndex((detail: any) => detail.detailId === item.detailId);
      if (delIdx > -1) {
        let delTarget = curList.splice(delIdx, 1);
        let curDeleted = deletedDetailPageList;
        curDeleted.push(delTarget[0]);
        setDeletedDetailPageList([...curDeleted]);
        setOrgDetailPageList([...curList]);
      }
    } else {
      let curList = detailPageList;
      let targetItemIdx = detailPageList.findIndex(
        (i: any) =>
          i.fileName === item.fileName && i.fileIndex === item.fileIndex && i.text === item.text,
      );
      if (targetItemIdx > -1) {
        let targetItem = detailPageList[targetItemIdx];
        let curFileList = detailPageFileList;
        curFileList.splice(targetItem.fileIndex, 1);
        curList.splice(targetItemIdx, 1);
        setDetailPageFileList([...curFileList]);
        setDetailPageList([...curList]);
      }
    }
  };

  return (
    <>
      <Header {...props} />
      <UpdateProductPresenter
        {...props}
        getThumbnailRootProps={getThumbnailRootProps}
        getThumbnailInputProps={getThumbnailInputProps}
        thumbnailOpen={thumbnailOpen}
        thumbnailAcceptedFiles={thumbnailAcceptedFiles}
        thumbnailIsDragActive={thumbnailIsDragActive}
        getDetailPageRootProps={getDetailPageRootProps}
        getDetailPageInputProps={getDetailPageInputProps}
        detailPageOpen={detailPageOpen}
        detailPageAcceptedFiles={detailPageAcceptedFiles}
        detailPageIsDragActive={detailPageIsDragActive}
        thumbnailFileList={thumbnailFileList}
        thumbnailList={thumbnailList}
        setThumbnailList={setThumbnailList}
        detailPageFileList={detailPageFileList}
        detailPageList={detailPageList}
        setDetailPageList={setDetailPageList}
        submit={submit}
        productNm={productNm}
        setProductNm={setProductNm}
        productDv={productDv}
        setProductDv={setProductDv}
        price={price}
        setPrice={setPrice}
        type={type}
        setType={setType}
        material={material}
        setMaterial={setMaterial}
        size={size}
        setSize={setSize}
        credNumber={credNumber}
        setCredNumber={setCredNumber}
        openYear={openYear}
        setOpenYear={setOpenYear}
        madeIn={madeIn}
        setMadeIn={setMadeIn}
        bestYn={bestYn}
        setBestYn={setBestYn}
        useYn={useYn}
        setUseYn={setUseYn}
        productDetailDv={productDetailDv}
        setProductDetailDv={setProductDetailDv}
        month={month}
        setMonth={setMonth}
        orgThumbnailList={orgThumbnailList}
        setOrgThumbnailList={setOrgThumbnailList}
        orgDetailPageList={orgDetailPageList}
        setOrgDetailPageList={setOrgDetailPageList}
        removeThumb={removeThumb}
        removeDetailPage={removeDetailPage}
      ></UpdateProductPresenter>
      <Footer {...props} />
    </>
  );
};

export default UpdateProductContainer;

import { loginedApi, notLoginedApi } from './api-base';

export const planApi = {
  getPlanYear: (token: any, age: string, year: number) =>
    notLoginedApi.get('/plan/yearly', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
      params: {
        age: age,
        year: year,
      },
    }),
  getPlanMonth: (token: any, age: string, month: number, year: number) =>
    notLoginedApi.get('/plan/monthly', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
      params: {
        age,
        month,
        year,
      },
    }),
  getPlanMonthFile: (token: any, age: string, month: number, year: number) =>
    loginedApi.get('/plan/monthly/file', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
      params: {
        age,
        month,
        year,
      },
    }),
  getPrevPlanMonth: (token: any, age: string | null, year: number | null, page: number) =>
    loginedApi.get('/plan/monthly/list', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
      params: {
        age,
        year,
        page,
      },
    }),
  getPrevPlanWeekly: (token: any, age: string | null, month: number | null, year: number | null, page: number) =>
    loginedApi.get('/plan/weekly/list', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
      params: {
        age,
        year,
        month,
        page,
      },
    }),
  getPlanWeekly: (token: any, age: string, week: number, month: number, year: number) =>
    notLoginedApi.get('/plan/weekly', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
      params: {
        age,
        week,
        month,
        year,
      },
    }),
  getPlanWeeklyFile: (token: any, age: string, week: number, month: number, year: number) =>
    loginedApi.get('/plan/weekly/file', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
      params: {
        age,
        week,
        month,
        year,
      },
    }),
  getPlanDaily: (token: any, age: string, day: number, week: number, month: number, year: number) =>
    notLoginedApi.get('/plan/daily', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
      params: {
        age,
        day,
        week,
        month,
        year,
      },
    }),
  createPlanYearly: (token: any, Data: FormData) =>
    loginedApi.post('/plan/yearly', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
  deletePlanYearlyMonth: (token: any, Data: FormData) => 
    loginedApi.post('/plan/yearly/deletePlanMonth', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      }
    })
    ,
  createPlanYearlyFile: (token: any, Data: FormData) =>
    loginedApi.post('/plan/yearly/planfile', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
  updatePlanYearly: (token: any, Data: FormData) =>
    loginedApi.post('/plan/yearly/year', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
    }),
  createPlanMonthly: (token: any, Data: FormData) =>
    loginedApi.post('/plan/monthly', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
  createPlanMonthlyFile: (token: any, Data: FormData) =>
    loginedApi.post('/plan/monthly/planfile', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
  createPlanWeekly: (token: any, Data: FormData) =>
    loginedApi.post('/plan/weekly', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
  createPlanWeeklyFile: (token: any, Data: FormData) =>
    loginedApi.post('/plan/weekly/planfile', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
  createPlanDaily: (token: any, Data: FormData) =>
    loginedApi.post('/plan/daily', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
  deleteDailyPlanTime: (token: any, Data: FormData) =>
    loginedApi.post('/plan/daily/deleteTime', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      }
    }),
  createPlanDailyFile: (token: any, Data: FormData) =>
    loginedApi.post('/plan/daily/planfile', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
  getActivityPlan: (token: any, activityId: number) =>
    notLoginedApi.get(`/plan/origin/activity/detail/${activityId}`, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
  deleteActivityPlan: (token: any, activityId: number) =>
    loginedApi.delete(`/plan/origin/activity/${activityId}`, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
  createActivityPlan: (token: any, Data: FormData) =>
    loginedApi.post('/plan/origin/activity', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
  updateActivityPlan: (token: any, Data: FormData) =>
    loginedApi.put('/plan/origin/activity', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
  searchPlanActivity: (token: any, Data: FormData) =>
    loginedApi.post('/plan/origin/activity/list', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
    }),
  searchPlanActivtyByKeyword: (token: any, keyword: string) =>
    loginedApi.get('/plan/origin/activity/search', {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
      params: {
        keyword
      },
    }),
};

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1091px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;


export const DummyArea = styled.div`
  width: 100%;
  height: 1500px;

`

export const MultiHeader = styled.div`
  width: 1075px;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  padding: 25px 0 0;
  vertical-align: middle;
`;

export const MultiHeaderTitle = styled.div`
  float: left;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const MultiHeaderRightArea = styled.div`
  float: right;
  text-align: center;
  vertical-align: middle;
`;
export const MultiHeaderRightIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 5px;
  vertical-align: middle;
`;

export const MultiHeaderRightText = styled.div`
  font-weight: 400;
  color: #4d4d4d;
  vertical-align: middle;
`;


export const SearchConditionArea = styled.div`
  width: 1075px;
  height: auto;
  margin: 20px auto 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
`;

export const searchConditionHeader = styled.div`
  width: 150px;
  display: flex;
  background-color: #F3F3F3;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #4D4D4D;
`;

export const SearchConditionBody = styled.div`
  width: calc(100% - 150px);
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
`;

export const SearchConditionItem = styled.div<{selected: boolean}>`
  margin: 5px;
  padding: 0px 10px 0px 10px;
  font-size: 16px;
  color: ${(props) => props.selected ? '#0087ED' : '#4D4D4D'};
  border-radius: 20px;
  border: ${(props) => props.selected ? '1px solid #0087ED' : '0px solid #0087ED'};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  cursor: pointer;
`;

export const SearchConditionAgeArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ProgramListArea = styled.div`
  width: 1075px;
  margin: 0 auto;
  text-align: center;
`;

export const ProgramTypeTitleArea = styled.div`
  width: 100%;
  margin: 20px auto 0;
  height: auto;
  border-bottom: 1px solid #e2e2e2;
  padding: 0px 0 5px;
  display: inline-block;
  color: #4d4d4d;
  text-align: left;
  vertical-align: baseline;
`;

export const ProgramTypeTitleFixedPart = styled.div`
  font-size: 20px;
  display: inline-block;
  line-height: 26px;
  font-weight: 600;
  margin: 0 3px;
`;

export const ProgramTypeTitlePart = styled.div`
  font-size: 20px;
  display: inline-block;
  line-height: 26px;
  font-weight: 600;
  margin: 0 3px;
  color: #0087ed;
`;

export const ProgramSearchTextArea = styled.div`
  line-height: 26px;
  width: 200px;
  border-radius: 20px;
  border: 1.5px solid #e2e2e2;
  float: right;
  position: relative;
`;

export const ProgramSearchText = styled.input`
  width: 80%;
  height: 20px;
  margin: 0 10px 0 20px;
  border: none;
  color: #C8C8C8;
`;

export const ProgramSearchButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 17%;
  height: 26px;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  border-radius: 0 15px 15px 0;
  background-color: #e2e2e2;
`;

export const ProgramSearchImg = styled.img`
  width: 18px;
  height: 18px;
  vertical-align: middle;
`;

export const ProgramList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 1220px;
  margin: 20px auto 0px;
  height: auto;
  justify-content: flex-start;
  align-items: center;
`;

export const ProgramForm = styled.li<{ idx: number }>`
  width: 175px;
  margin: ${(props) => props.idx%5 === 0 ? '0px 25px 30px 0px' : props.idx % 5 === 4 ? '0px 0px 30px 25px' : '0px 25px 30px 25px'};
  display: flex;
  flex-direction: column;
`;

export const ProgramThumbnailArea = styled.div`
  width: 175px;
  height: 248px;
  cursor: pointer;
  margin-bottom: 10px;
  justify-self: flex-start;
`;

export const ProgramThumbnail = styled.div<{ imgsrc: string }>`
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url(${props.imgsrc})`};
  background-size: cover;
  background-position: top;
`;

export const ProgramDarkness = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.3s linear;
  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  &:hover img {
    opacity: 1;
  }
`;

export const ProgramLensImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: none;
  opacity: 0;
`;

export const ProgramTitle = styled.div`
  display: flex;
  width: 100%;
  margin: 10px auto 30px auto;
  height: 10px;
  font-size: 16px;
  color: #4d4d4d;
  align-items: center;
  justify-content: flex-start;
`;

export const ProgramLinkArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const ProgramTypeArea = styled.div`
  display: flex;
  width: 57px;
  height: 34px;
  align-items: center;
`;

export const ProgramType = styled.div<{ type: string }>`
  display: flex;
  width: 57px;
  height: 34px;
  border-radius: 25px;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${(props) =>
    props.type === 'MN0301'
      ? '#ffcf16'
      : props.type === 'MN0302'
      ? '#f2a066'
      : props.type === 'MN0303'
      ? '#a788c6'
      : props.type === 'MN0304'
      ? '#40c7ff'
      : props.type === 'MN0305'
      ? '#ff73a4'
      : props.type === 'MN0306'
      ? '#ff8c11'
      : props.type === 'MN0307'
      ? '#54c635'
      : props.type === 'MN0308'
      ? '#5c60f8'
      : props.type === 'MN0309'
      ? '#10ae36'
      : props.type === 'MN0310'
      ? '#ffa800'
      : props.type === 'MN0311'
      ? '#00cdbc'
      : props.type === 'MN0312'
      ? '#f87ec0'
      : props.type === 'MN0313'
      ? '#7976f9'
      : props.type === 'MN0314'
      ? '#1fa0a2'
      : props.type === 'MN0315'
      ? '#1fa0a2'
      : props.type === 'MN0316'
      ? '#00dda0'
      : props.type === 'MN0317'
      ? '#ff8278'
      : props.type === 'MN0318'
      ? '#ee6868'
      : props.type === 'MN0319'
      ? '#2a9c17'
      : props.type === 'MN0320'
      ? '#f5a054'
      : props.type === 'MN0321'
      ? '#5fda76'
      : props.type === 'MN0322'
      ? '#ff7800'
      : '#720000'};
`;

export const ProgramScrapLinkArea = styled.div`
  display: flex;
  width: 99px;
  height: 34px;
  align-items: center;
  cursor: pointer;
`;

export const ProgramScrapLink = styled.div`
  display: flex;
  width: 85px;
  height: 30px;
  border-radius: 25px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  border: 1px solid #6db3df;
  color: #6db3df;
`;

export const ProgramDownloadLinkArea = styled.div`
  display: flex;
  width: 100px;
  height: 34px;
  align-items: center;
  cursor: pointer;
`;

export const ProgramDownloadLink = styled.div`
  display: flex;
  width: 85px;
  height: 30px;
  font-size: 12px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #6db3df;
  cursor: pointer;
`;

export const PagingNav = styled.div`
  width: 100%;
  height: 40px;
  padding: 3px;
  margin-bottom: 50px;
  text-align: center;
  vertical-align: middle;
`;

export const PagingLink = styled.div`
  display: inline-block;
  width: 12px;
  height: 100%;
  margin: 0 3px;

  &:hover {
    cursor: pointer;
  }
`;

export const PagingLinkImg = styled.img``;

export const PagingNumArea = styled.ul`
  display: inline-block;
  height: 100%;
  margin: auto 15px;
  font-size: 17px;
  color: #7e7e7e;
  text-align: center;
  vertical-align: middle;
`;

export const PagingNum = styled.li<{ current: boolean }>`
  display: inline-block;
  margin: 5.5px 2px;
  font-weight: ${(props) => (props.current ? '800' : '600')};
  color: ${(props) => (props.current ? '#4d4d4d' : 'default')};
  padding: 2px 6px;

  &:hover {
    cursor: pointer;
    color: #4d4d4d;
    text-decoration: underline;
    text-decoration-color: #4d4d4d;
  }
`;

export const SearchConditionMonthArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 0.5px solid #AAAAAA;
`;

export const SearchConditionMediaArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 0.5px solid #AAAAAA;
`;


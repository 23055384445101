const data: { [key: string]: { [key: string]: string } } = {
  MN0206: {
    1: '#7D9BFF',
    2: '#77A4FF',
    3: '#4C87FF',
    title: '엔젤쁘레',
  },
  MN0205: {
    0: '#607AFF',
    1: '#B0BDFF',
    2: '#8B9EFF',
    3: '#7A90FF',
    4: '#93A5FF',
    title: '쁘레놀이',
  },
  MN0207: {
    0: '#253877',
    1: '#314A9C',
    2: '#3255C9',
    3: '#6083F9',
    title: '아트몽',
  },
  MN0213: {
    0: '#10AC7B',
    1: '#37DCA8',
    2: '#0DB681',
    3: '#129C71',
    title: '하비랑',
  },
  MN0210: {
    0: '#10AC7b',
    1: '#37DCA8',
    2: '#0DB681',
    3: '#129C71',
    title: '헬로프로젝트',
  },
  MN0208: {
    0: '#099BEF',
    1: '#35A4E4',
    2: '#47BCFF',
    3: '#2AB1FF',
    title: '슈필매스',
  },
  MN0209: {
    1: '#FFA126',
    2: '#FFBF26',
    3: '#FFA126',
    title: '누리교과서',
  },
  MN0214: {
    1: '#1730B4',
    2: '#1780D4',
    3: '#1730B4',
    title: '말랑말랑과학',
  },
  MN0215: {
    1: '#EA9303',
    2: '#FCB505',
    title: '뉴하비쥬에',
  },
};

export default data;

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import * as s from './RegisterProgramStyled';

import 'moment/locale/ko';

import 'react-day-picker/lib/style.css';
import queryString from 'query-string';

import Header from '../../components/header';
import Footer from '../../components/footer';
import { comnApi } from '../../api/api-comn';
import { useRecoilValue, useRecoilState } from 'recoil';
import * as recoilItem from '../../util/recoilitem';

import Activity from './activity';
import ActSheet from './actsheet';
import Docuform from './docuform';
import Homeletter from './homeletter';
import GuideBook from './guidebook';
import Multi from './multi';
import Sound from './sound';
import Videos from './videos';

interface RegisterProgramProps extends RouteComponentProps {}

const RegisterProgramContainer = (props: RegisterProgramProps) => {
  const token = useRecoilValue(recoilItem.token);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const [ageAuths, setAgeAuths] = useState<any>([]);
  const [holderList, setHolderList] = useState<any>([]);
  const [option, setOption] = useState<{
    age: string | null;
    holder: string;
    topMenu: string;
    subMenu: string;
    type: string;
    field: string;
  }>({
    age: 'AUTH0101',
    holder: 'HOL0101',
    topMenu: 'MN03',
    subMenu: '',
    type: '',
    field: '',
  });

  useEffect(() => {
    let topMenu = 'MN03';
    let subMenu = '';
    if (queryString.parse(props.location.search).type) {
      switch (String(queryString.parse(props.location.search).type)) {
        case 'MN03':
          setOption({
            ...option,
            topMenu: 'MN03',
          });
          topMenu = 'MN03';
          break;
        case 'MN0401':
          setOption({
            ...option,
            topMenu: 'MN04',
            subMenu: 'MN0401',
          });
          topMenu = 'MN04';
          subMenu = 'MN0401';
          break;
        case 'MN0402':
          setOption({
            ...option,
            topMenu: 'MN04',
            subMenu: 'MN0402',
          });
          topMenu = 'MN04';
          subMenu = 'MN0402';
          break;
        case 'MN0403':
          setOption({
            ...option,
            topMenu: 'MN04',
            subMenu: 'MN0403',
          });
          topMenu = 'MN04';
          subMenu = 'MN0403';
          break;
        case 'MN0404':
          setOption({
            ...option,
            topMenu: 'MN04',
            subMenu: 'MN0404',
          });
          topMenu = 'MN04';
          subMenu = 'MN0404';
          break;
        case 'MN0406':
          setOption({
            ...option,
            topMenu: 'MN04',
            subMenu: 'MN0406',
          });
          topMenu = 'MN04';
          subMenu = 'MN0406';
          break;
        case 'MN0407':
          setOption({
            ...option,
            topMenu: 'MN04',
            subMenu: 'MN0407',
          })
          topMenu = 'MN04';
          subMenu = 'MN0407';
          break;
        case 'MN0408':
          setOption({
            ...option,
            topMenu: 'MN04',
            subMenu: 'MN0408',
          })
          topMenu = 'MN04';
          subMenu = 'MN0408';
          break;
        case 'MN05':
          setOption({
            ...option,
            topMenu: 'MN05',
          });
          topMenu = 'MN05';
          break;
        default:
          break;
      }
    }
    setOption({
      age: 'AUTH0101',
      holder: 'HOL0101',
      topMenu: topMenu,
      subMenu: subMenu,
      type: '',
      field: '',
    });
  }, [props.location]);

  const setAge = (age: string) => {
    setOption({
      ...option,
      age: age,
    });
  };

  const setHolder = (holder: string) => {
    setOption({
      ...option,
      holder: holder,
    });
  };

  const setTopMenu = (topMenu: string) => {
    setOption({
      ...option,
      topMenu: topMenu,
    });
  };
  const setSubMenu = (subMenu: string) => {
    setOption({
      ...option,
      subMenu: subMenu,
    });
  };
  const setType = (type: string) => {
    setOption({
      ...option,
      type: type,
    });
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      await comnApi.getCd('AUTH01').then((res) => {
        if (res.data.rsltCd === '00') {
          let ageAuthList: any = {};
          res.data.ComnCd.subCdList.map(
            (item: { comnCd: string; comnCdNm: string; inggCd: string; viewOrder: number }) => {
              ageAuthList[item.comnCd] = item;
            },
          );
          setAgeAuths(ageAuthList);
        }
      });
      await comnApi.getCd('HOL01').then((res) => {
        if (res.data.rsltCd === '00') {
          let holderList: any = {};
          res.data.ComnCd.subCdList.map((item: any) => {
            holderList[item.comnCd] = {
              comnCd: item.comnCd,
              comnCdNm: item.comnCdNm,
              viewOrder: item.viewOrder,
            };
          });
          setHolderList(holderList);
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header {...props} />
      <s.Container>
        <s.ProgramHeader>
          <s.ProgramHeaderTitle>
            {option.topMenu === 'MN03'
              ? '활동프로그램'
              : option.topMenu === 'MN04'
              ? '교수자료실'
              : option.topMenu === 'MN05'
              ? '멀티미디어'
              : ''}
          </s.ProgramHeaderTitle>
          <s.ProgramHeaderRightArea>
            <s.ProgramHeaderRightText>
              <s.ProgramHeaderRightIcon src="/static/img/home_icon.jpg" />
              {'HOME > 마이페이지 > 글작성'}
            </s.ProgramHeaderRightText>
          </s.ProgramHeaderRightArea>
        </s.ProgramHeader>
        <s.FullSelectArea>
          <s.FullSelectLabel>연령 선택</s.FullSelectLabel>
          <s.FullSelectDiv></s.FullSelectDiv>
          <s.FullSelectForm100>
            {ageAuths &&
              Object.entries(ageAuths).map(([key, value]) => {
                return (
                  <s.AgeItem
                    key={'age' + value}
                    active={key === option.age}
                    onClick={() => setAge(key)}
                  >
                    만 {ageAuths[key].comnCdNm}
                  </s.AgeItem>
                );
              })}
          </s.FullSelectForm100>
          {option.topMenu === 'MN03' ? null : (
            <>
              <s.FullSelectLabel>월(호) 선택</s.FullSelectLabel>
              <s.FullSelectDiv></s.FullSelectDiv>
              <s.FullSelectForm100>
                {holderList &&
                  Object.entries(holderList).map(([key, value]) => {
                    return (
                      <s.HolderItem
                        key={holderList[key].comnCd}
                        active={holderList[key].comnCd === option.holder}
                        onClick={() => setHolder(holderList[key].comnCd)}
                      >
                        {holderList[key].comnCdNm}
                      </s.HolderItem>
                    );
                  })}
              </s.FullSelectForm100>
            </>
          )}
        </s.FullSelectArea>
        {option.topMenu === 'MN03' ? (
          <Activity
            {...props}
            option={option}
            setOption={setOption}
            ageAuths={ageAuths}
            holderList={holderList}
          />
        ) : option.topMenu === 'MN05' ? (
          <Multi
            {...props}
            option={option}
            setOption={setOption}
            ageAuths={ageAuths}
            holderList={holderList}
          />
        ) : option.topMenu === 'MN04' ? (
          option.subMenu === 'MN0401' ? (
            <ActSheet
              {...props}
              option={option}
              setOption={setOption}
              ageAuths={ageAuths}
              holderList={holderList}
            />
          ) : option.subMenu === 'MN0402' ? (
            <Sound
              {...props}
              option={option}
              setOption={setOption}
              ageAuths={ageAuths}
              holderList={holderList}
            />
          ) : option.subMenu === 'MN0403' ? (
            <Docuform
              {...props}
              option={option}
              setOption={setOption}
              ageAuths={ageAuths}
              holderList={holderList}
            />
          ) : option.subMenu === 'MN0406' ? (
            <GuideBook
              {...props}
              option={option}
              setOption={setOption}
              ageAuths={ageAuths}
              holderList={holderList}
            />
          ) : option.subMenu === 'MN0408' ? (
            <Videos
              {...props}
              option={option}
              setOption={setOption}
              ageAuths={ageAuths}
              holderList={holderList}
            />
          ) 
            : (
            <Homeletter
              {...props}
              option={option}
              setOption={setOption}
              ageAuths={ageAuths}
              holderList={holderList}
            />
          )
        ) : null}
      </s.Container>
      <Footer {...props} />
    </>
  );
};

RegisterProgramContainer.defaultProps = {};

export default RegisterProgramContainer;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1091px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const DataPackageHeader = styled.div`
  width: 1091px;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 25px 0 10px;
  vertical-align: middle;
`;

export const DataPackageHeaderTitleArea = styled.div`
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const DataPackageHeaderTitle = styled.div`
  float: left;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const DataPackageHeaderTitleDivide = styled.div`
  float: left;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  padding-top: 3px;
  margin: 0 10px;
  color: #e2e2e2;
`;

export const DataPackageHeaderRightArea = styled.div`
  float: right;
  padding: 10px 0;
  text-align: center;
  vertical-align: middle;
`;

export const DataPackageHeaderRightText = styled.div`
  font-weight: 400;
  color: #4d4d4d;
  vertical-align: middle;
`;

export const DataPackageHeaderRightIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 5px;
  vertical-align: middle;
`;




export const ProgramListArea = styled.div`
  width: 1091px;
  margin: 30px auto 0px;
  text-align: center;
`;

export const ProgramTypeTitleArea = styled.div`
  width: 100%;
  margin: 10px auto 0;
  height: auto;
  border-bottom: 1px solid #0087ed;
  padding: 10px 0 10px;
  vertical-align: baseline;
  display: flex;
  justify-content: space-between;
`;

export const ProgramTypeTitleFixedPart = styled.div`
  font-size: 18px;
  display: inline-block;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 5px 3px;
  color: #0087ed;
`;

export const ProgramTypeTitlePart = styled.div`
  font-size: 24px;
  display: inline-block;
  line-height: 32px;
  font-weight: 800;
  margin: 0 3px;
  color: #0087ed;
`;

export const ProgramSearchTextArea = styled.div`
  line-height: 35px;
  width: 220px;
  border: 1.5px solid #0087ed;
  float: right;
`;

export const ProgramSearchText = styled.input`
  width: 180px;
  height: 35px;
  padding-left: 10px;
  border: none;
`;

export const ProgramSearchButton = styled.div`
  display: inline-block;
  width: 35px;
  height: 35px;
  vertical-align: middle;
  cursor: pointer;
`;

export const ProgramSearchImg = styled.img`
  width: 30px;
  height: 30px;
  vertical-align: baseline;
  margin: 2px;
`;

export const ProgramList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 1091px;
  margin: 0px auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;

  border-top: 2px solid #7E7E7E;
`;

export const ProgramListHeader = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  font-size: 18px;
  color: #4D4D4D;
  font-weight: 500;
  align-items: center;
  justify-content: flex-start;
  background-color: #eee;
  border-bottom: 1px solid #aaa;
`;

export const ProgramListSelect = styled.div`
  width: 5%;
  padding: 0 5px;
`;

export const ProgramListLastCol = styled.div`
  flex: 6;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  color: #4D4D4D;
`;

export const ProgramListStartCol = styled.div`
  flex: 2;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  color: #4D4D4D;
`;

export const ProgramListCenterCol = styled.div`
  flex: 23;
  padding: 15px;
  text-align: start;
  font-size: 16px;
  color: #4D4D4D;
`

export const ProgramCheckBox = styled.input``;

export const ProgramForm = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #aaa;
`;

export const ProgramTitle = styled.div`
  width: 100%;
  font-size: 18px;
  color: #4d4d4d;
  cursor: pointer;
`;

export const ProgramInfoAraa = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
`;
export const ProgramInfoText = styled.div`
  width: auto;
  text-align: center;
  color: #A4A4A4;
  font-size: 18px;
`;
export const ProgramInfoSeparate = styled.div`
  width: 1px;
  height: 10px;
  background-color: #A4A4A4;
  margin: 0 10px;
`;
export const ProgramListSmallIcon = styled.img<{ hoverImgUrl?: string | undefined }>`
  width: 34px;
  height: 25px;
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    content: ${(props) => (props.hoverImgUrl ? `url(${props.hoverImgUrl})` : '')};
  }
`;
export const ProgramListIcon = styled.img<{ hoverImgUrl?: string | undefined }>`
  width: 30px;
  aspect-ratio: 1/1;
  cursor: pointer;
  &:hover {
    content: ${(props) => (props.hoverImgUrl ? `url(${props.hoverImgUrl})` : '')};
  }
`;

export const PagingNav = styled.div`
  width: 100%;
  height: 40px;
  padding: 3px;
  margin-top: 30px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 50px;
`;

export const PagingLink = styled.div`
  display: inline-block;
  width: 12px;
  height: 100%;
  margin: 0 3px;

  &:hover {
    cursor: pointer;
  }
`;

export const PagingLinkImg = styled.img``;

export const PagingNumArea = styled.ul`
  display: inline-block;
  height: 100%;
  margin: auto 15px;
  font-size: 17px;
  color: #7e7e7e;
  text-align: center;
  vertical-align: middle;
`;

export const PagingNum = styled.li<{ current: boolean }>`
  display: inline-block;
  margin: 5.5px 2px;
  font-weight: ${(props) => (props.current ? '800' : '600')};
  color: ${(props) => (props.current ? '#4d4d4d' : 'default')};
  padding: 2px 6px;

  &:hover {
    cursor: pointer;
    color: #4d4d4d;
    text-decoration: underline;
    text-decoration-color: #4d4d4d;
  }
`;

export const ScrapButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #6DB3DF;
  border: 1px solid #6DB3DF;
  border-radius: 10px;
  width: 90px;
  height: 38px;
  margin-right: 5px;
`;

export const DownloadButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #FFFFFF;
  background-color: #6DB3DF;
  border: 1px solid #6DB3DF;
  border-radius: 10px;
  width: 90px;
  height: 38px;
`;
import React, { Dispatch, SetStateAction } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import BeatLoader from 'react-spinners/BeatLoader';
import * as s from './RegisterMultiStyled';
import Media from './component/Media';

interface RegisterMultiProps extends RouteComponentProps {
  option: {
    age: string | null;
    holder: string;
    topMenu: string;
    subMenu: string;
    type: string;
    field: string;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      holder: string;
      topMenu: string;
      subMenu: string;
      type: string;
      field: string;
    }>
  >;
  ageAuths: any;
  holderList: any;
  isModifying: boolean;
  setIsModifying: React.Dispatch<React.SetStateAction<boolean>>;
  subMenuList: {
    menuCd: string;
    menuNm: string;
    viewOrder: number;
  }[];
  fieldList: {
    menuCd: string;
    menuNm: string;
    viewOrder: number;
  }[];
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  submit: () => Promise<void>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  programMedias: {
    thumbnail?: File;
    file?: File;
    description: string;
    mediaUrl?: string;
  }[];
  onChangeProgramMedia: (index: number, name: string, value: string | File) => void;
  addProgramMidea: () => void;
  deleteProgramMidea: (index: number) => void;
}

const RegisterMultiPresenter = (props: RegisterMultiProps) => {
  return (
    <s.Container>
      <s.TitleArea>
        <s.Title>
          만{' '}
          <s.TitleSpan>
            {!props.ageAuths || props.ageAuths.length === 0
              ? null
              : props.option.age
              ? props.ageAuths[props.option.age].comnCdNm
              : null}
          </s.TitleSpan>{' '}
          <s.TitleSpan>
            {!props.holderList || props.holderList.length === 0
              ? null
              : props.holderList[props.option.holder].comnCdNm}
          </s.TitleSpan>{' '}
          멀티 프로그램
        </s.Title>
      </s.TitleArea>

      <s.UploadArea>
        <s.UploadAreaHeader>
          <s.UploadAreaHeaderLeft>업로드</s.UploadAreaHeaderLeft>
          <s.UploadAreaHeaderRight>
            <s.UploadAreaHeaderRightIcon src="/static/img/warning.png" />
            <s.UploadAreaHeaderRightText>ZIP파일은 업로드 불가입니다.</s.UploadAreaHeaderRightText>
          </s.UploadAreaHeaderRight>
        </s.UploadAreaHeader>
        <s.UploadContentArea>
          <s.UploadContentAreaHeader>
            <s.UploadContentAreaHeaderLeft>
              <s.UploadContentAreaHeaderIcon
                src={!props.isModifying ? '/static/img/pen.png' : '/static/img/red_pen.png'}
              />
              <s.UploadContentAreaHeaderLeftText
                onClick={() => (!props.isModifying ? props.setIsModifying(true) : null)}
                isColor={!props.isModifying}
              >
                수정
              </s.UploadContentAreaHeaderLeftText>
            </s.UploadContentAreaHeaderLeft>
            <s.UploadContentAreaHeaderRight>
              {false ? (
                <BeatLoader size={8} color={'#acacac'} />
              ) : (
                <>
                  <s.UploadContentAreaHeaderIcon
                    src={
                      props.isModifying ? '/static/img/check.png' : '/static/img/green_check.png'
                    }
                  />
                  <s.UploadContentAreaHeaderRightText
                    onClick={() => (props.isModifying ? props.setIsModifying(false) : null)}
                    isColor={props.isModifying}
                  >
                    완료
                  </s.UploadContentAreaHeaderRightText>
                </>
              )}
            </s.UploadContentAreaHeaderRight>
          </s.UploadContentAreaHeader>
          <s.UploadProgramArea>
            <s.UploadProgramAreaHeader>
              <s.UploadProgramAreaSelectHeader>놀이 선택</s.UploadProgramAreaSelectHeader>
              <s.UploadProgramAreaSelectDv />
              <s.UploadProgramAreaHeaderSelect>
                {props.fieldList.map((type: any) => (
                  <s.UploadProgramAreaSelectWrapper>
                    <s.UploadProgramAreaSelectType
                      id={type.menuCd}
                      value={type.menuCd}
                      defaultChecked={props.option.field === type.menuCd}
                      onChange={(e) =>
                        props.option.field === e.target.value
                          ? props.setOption({ ...props.option, field: '' })
                          : props.setOption({ ...props.option, field: e.target.value })
                      }
                      type={'checkbox'}
                      disabled={!props.isModifying}
                    />
                    <s.UploadProgramAreaSelectTypeLabel htmlFor={type.menuCd}>
                      {props.option.field === type.menuCd ? (
                        <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                      ) : null}
                    </s.UploadProgramAreaSelectTypeLabel>
                    <s.UploadProgramAreaSelectText htmlFor={type.menuCd}>
                      {type.menuNm}
                    </s.UploadProgramAreaSelectText>
                  </s.UploadProgramAreaSelectWrapper>
                ))}
              </s.UploadProgramAreaHeaderSelect>
              <s.UploadProgramAreaSelectHeader>분야 선택</s.UploadProgramAreaSelectHeader>
              <s.UploadProgramAreaSelectDv />
              <s.UploadProgramAreaHeaderSelect>
                {props.subMenuList.map((subMenu: any) => (
                  <s.UploadProgramAreaHeaderSelectDiv>
                    <s.UploadProgramAreaSelectType
                      id={subMenu.menuCd}
                      value={subMenu.menuCd}
                      defaultChecked={props.option.subMenu === subMenu.menuCd}
                      onChange={(e) =>
                        props.option.subMenu === e.target.value
                          ? props.setOption({ ...props.option, subMenu: '' })
                          : props.setOption({ ...props.option, subMenu: e.target.value })
                      }
                      type={'checkbox'}
                      disabled={!props.isModifying}
                    />
                    <s.UploadProgramAreaSelectTypeLabel htmlFor={subMenu.menuCd}>
                      {props.option.subMenu === subMenu.menuCd ? (
                        <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                      ) : null}
                    </s.UploadProgramAreaSelectTypeLabel>
                    <s.UploadProgramAreaSelectText htmlFor={subMenu.menuCd}>
                      {subMenu.menuNm}
                    </s.UploadProgramAreaSelectText>
                  </s.UploadProgramAreaHeaderSelectDiv>
                ))}
              </s.UploadProgramAreaHeaderSelect>
              <s.UploadProgramAreaInputArea>
                <s.UploadProgramAreaHeaderLabel>제목</s.UploadProgramAreaHeaderLabel>
                <s.UploadProgramAreaHeaderInput
                  value={props.title}
                  onChange={(e) => props.setTitle(e.target.value)}
                  placeholder={'텍스트를 입력해주세요.'}
                  disabled={!props.isModifying}
                />
              </s.UploadProgramAreaInputArea>
            </s.UploadProgramAreaHeader>
          </s.UploadProgramArea>
          {props.programMedias.map(
            (
              item: {
                thumbnail?: File;
                file?: File;
                description: string;
                mediaUrl?: string;
              },
              index: number,
            ) => (
              <Media
                programMedia={item}
                onChangeProgramMedia={(name: string, value: File | string) =>
                  props.onChangeProgramMedia(index, name, value)
                }
                deleteProgramMedia={() => props.deleteProgramMidea(index)}
              />
            ),
          )}
          <s.ButtonArea>
            <s.SubmitButton onClick={props.addProgramMidea}>
              <s.SubmitButtonText>영상추가</s.SubmitButtonText>
            </s.SubmitButton>
            <s.SubmitButton onClick={props.submit}>
              <s.SubmitButtonText>업로드</s.SubmitButtonText>
            </s.SubmitButton>
          </s.ButtonArea>
        </s.UploadContentArea>
      </s.UploadArea>
    </s.Container>
  );
};

export default RegisterMultiPresenter;

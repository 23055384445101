import React, { useState, useRef, useLayoutEffect, ReactElement } from 'react';
import Slide from './Slide';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Button = styled.div`
  z-index: 10;
  width: 5%;
  margin: 0 auto;
`;

const SliderArea = styled.div`
  width: 90%;
  height: 100%;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
`;

const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 6;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

type SliderProps = {
  sildeList: ReactElement[];
  prevBtn?: ReactElement;
  nextBtn?: ReactElement;
  slideSize: number;
  currentIdx?: number;
};

const Slider = (props: SliderProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideRef = useRef<HTMLDivElement>(null);
  const nextSlide = () => {
    if (currentSlide >= props.sildeList.length - 1) {
      // 더 이상 넘어갈 슬라이드가 없으면 슬라이드를 초기화합니다.
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 10);
    }
  };
  const prevSlide = () => {
    if (currentSlide === 0) {
      setCurrentSlide(props.sildeList.length - 1);
    } else {
      setCurrentSlide(currentSlide - 10);
    }
  };
  useLayoutEffect(() => {
    if (slideRef.current) {
      console.log(currentSlide);
      slideRef.current.style.transition = 'all 0.5s ease-in-out';
      slideRef.current.style.transform = `translateX(-${currentSlide * props.slideSize}%)`; // 백틱을 사용하여 슬라이드로 이동하는 애니메이션을 만듭니다.
    }
  }, [currentSlide, props.slideSize]);
  useLayoutEffect(() => {
    if (props.currentIdx) {
      setCurrentSlide(Math.floor(props.currentIdx / 9) * 10);
    }
  }, [props.currentIdx]);
  return (
    <Container>
      <Button onClick={prevSlide}>{props.prevBtn}</Button>
      <SliderArea>
        <SliderContainer ref={slideRef}>
          {props.sildeList.map((item, index) => (
            <Slide key={'Slide' + index} silde={item} />
          ))}
        </SliderContainer>
      </SliderArea>
      <Button onClick={nextSlide}>{props.nextBtn}</Button>
    </Container>
  );
};

Slider.defaultProps = {
  sildeList: [],
  prevBtn: null,
  nextBtn: null,
  slideSize: 25,
};
export default Slider;

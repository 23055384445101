import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useDropzone } from 'react-dropzone';
import * as s from '../RegisterMultiStyled';

interface ThumbnailProps {
  programMedia: {
    thumbnail?: File;
  };
  onChangeProgramMedia: (name: string, value: File | string) => void;
}

const Thumbnail = (props: ThumbnailProps) => {
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files: File[]) => {
      props.onChangeProgramMedia('thumbnail', files[0]);
    },
  });
  return (
    <s.UploadProgramAreaFileUploadArea
      isDrag={isDragActive}
      {...getRootProps({ className: 'dropzone' })}
    >
      <s.UploadProgramAreaFileUploadAreaInput {...getInputProps()} />
      {props.programMedia.thumbnail ? (
        <s.UploadProgramAreaFileUploadAreaButtonIcon
          onClick={open}
          src="/static/img/pdf_icon_1.png"
        />
      ) : null}
      <s.UploadProgramAreaFileUploadAreaText>
        {props.programMedia.thumbnail
          ? props.programMedia.thumbnail.name
          : '썸네일 파일을 선택해주세요.'}
      </s.UploadProgramAreaFileUploadAreaText>
      <s.UploadProgramAreaFileUploadAreaButtonIcon
        onClick={open}
        src={
          props.programMedia.thumbnail ? '/static/img/file_exist.png' : '/static/img/selectFile.png'
        }
      />
    </s.UploadProgramAreaFileUploadArea>
  );
};

export default Thumbnail;

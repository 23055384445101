import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import RegisterActsheetPresenter from './RegisterActsheetPresenter';

interface RegisterActsheetProps extends RouteComponentProps {
  option: {
    holder: string;
    subMenu: string;
    type: string;
    field: string;
    visible: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
  setIsModifying: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  programImages: any[];
  setProgramImages: React.Dispatch<React.SetStateAction<any[]>>;
  thumbnail: any;
  setThumbnail: React.Dispatch<React.SetStateAction<any>>;
}

const RegisterActsheetContainer = (props: RegisterActsheetProps) => {
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: (files) => {
      props.setProgramImages(files);
      props.setThumbnail(files[0]);
    },
  });

  return (
    <RegisterActsheetPresenter
      {...props}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      open={open}
      acceptedFiles={acceptedFiles}
      isDragActive={isDragActive}
    />
  );
};

export default RegisterActsheetContainer;

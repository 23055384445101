import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1180px;
  min-height: 700px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const PlanningHeader = styled.div`
  width: 1180px;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  padding: 35px 100px 10px;
  vertical-align: middle;
`;

export const PlanningHeaderTitle = styled.div`
  float: left;
  font-size: 27px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const PlanningHeaderRightArea = styled.div`
  float: right;
  padding: 10px 0;
  text-align: center;
  vertical-align: middle;
`;
export const PlanningHeaderRightIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 5px;
  vertical-align: middle;
`;

export const PlanningHeaderRightText = styled.div`
  font-weight: 400;
  color: #4d4d4d;
  vertical-align: middle;
`;

export const PlanningTabArea = styled.ul`
  width: 1040px;
  height: auto;
  margin: 0 auto;
  align-items: center;
  border-bottom: 1px solid #1c7ae9;
  display: inline-block;
`;

export const PlanningTab = styled.li<{ active: boolean }>`
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-top: 15px;
  padding: 10px 45px;
  text-align: center;
  display: inline-block;
  font-size: 18px;
  font-weight: ${(props) => (props.active ? 800 : 400)};
  cursor: pointer;
  border-top-left-radius: 10px;
  background-color: ${(props) => (props.active ? '#0087ed' : '#fff')};
  color: ${(props) => (props.active ? '#fff' : '#4d4d4d')};

  &:after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-width: 0;
    border-top-color: ${(props) => (props.active ? '#0087ed' : 'transparent')};
    top: 140px;
    margin: 0 -65px;
  }
  &:hover {
    background-color: #0087ed;
    color: #fff;
  }
`;

export const FullSelectArea = styled.div`
  width: 1040px;
  margin: 0 auto;
  text-align: center;
`;

export const FullSelectDiv = styled.div`
  width: 2.5%;
  margin: 20px auto;
  text-align: center;
  border-bottom: 1px solid;
`;

export const FullSelectLabel = styled.div`
  margin: 40px auto 5px;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const FullSelectForm = styled.div`
  width: 70%;
  margin: 5px auto;
  text-align: center;
  padding: 10px 0;
`;

export const FullSelectForm100 = styled.div`
  width: 100%;
  margin: 5px auto;
  text-align: center;
  padding: 10px 0;
`;

export const SliderArrowIcon = styled.div`
  display: inline-block;
  width: 10px;
  height: 20px;
  cursor: pointer;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
`;

export const SliderArrowIconImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const YearItem = styled.div<{ active: boolean }>`
  padding: 10px 20px;
  font-family: 'Gosanja';
  font-size: 18px;
  color: ${(props) => (props.active ? '#fff' : '#9d9d9d')};
  border: 1px solid ${(props) => (props.active ? '#3A96CF' : '#ccc')};
  border-radius: 30px;
  margin: 0 12px;
  background: ${(props) =>
    props.active ? 'linear-gradient(to bottom,#59A9DA 95%, #59A9DA 5px,  #3B89C9 5%)' : '#fff'};
  cursor: pointer;
`;

export const AgeItem = styled.div<{ active: boolean }>`
  padding: 10px 20px;
  font-family: 'Gosanja';
  font-size: 16px;
  width: 115px;
  color: ${(props) => (props.active ? '#fff' : '#9d9d9d')};
  border: 1px solid ${(props) => (props.active ? '#3A96CF' : '#ccc')};
  border-radius: 30px;
  margin: 0 12px;
  background: ${(props) =>
    props.active ? 'linear-gradient(to bottom,#59A9DA 95%, #59A9DA 5px,  #3B89C9 5%)' : '#fff'};
  cursor: pointer;
`;

export const HalfSelectLeftArea = styled.div`
  margin-left: 5%;
  width: 40%;
  display: inline-block;
  height: 500px;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
`;

export const HalfSelectRightArea = styled.div`
  margin-right: 5%;
  width: 40%;
  display: inline-block;
  height: 500px;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
`;

export const MonthItem = styled.div<{ active: boolean }>`
  display: inline-block;
  font-family: 'Gosanja';
  padding: 10px 20px;
  font-size: 16px;
  width: 120px;
  color: ${(props) => (props.active ? '#fff' : '#9d9d9d')};
  border: 1px solid ${(props) => (props.active ? '#3A96CF' : '#ccc')};
  border-radius: 30px;
  margin: 0 5px 10px;
  background: ${(props) =>
    props.active ? 'linear-gradient(to bottom,#59A9DA 95%, #59A9DA 5px,  #3B89C9 5%)' : '#fff'};
  cursor: pointer;
`;

export const SelectLine = styled.div``;

export const WeekItem = styled.div<{ active: boolean }>`
  display: inline-block;
  font-family: 'Gosanja';
  padding: 10px 20px;
  font-size: 16px;
  width: 120px;
  color: ${(props) => (props.active ? '#fff' : '#9d9d9d')};
  border: 1px solid ${(props) => (props.active ? '#3A96CF' : '#ccc')};
  border-radius: 30px;
  margin: 0 5px 10px;
  background: ${(props) =>
    props.active ? 'linear-gradient(to bottom,#59A9DA 95%, #59A9DA 5px,  #3B89C9 5%)' : '#fff'};
  cursor: pointer;
`;

export const DayPickerCaption = styled.div`
  position: absolute;
  text-align: center;
  top: 0;
  left: 46%;
  font-size: 24px;
  font-family: 'BRBA_B';
  color: #5d5d5d;
`;

export const DayPickerWeekday = styled.div`
  font-size: 20px;
  color: #dcdcdc;
`;

export const PlanNotFoundText = styled.div`
  display: flex;
  margin: 100px auto;
  color: #0087ed;
  font-size: 20px;
  display: inline-block;
  line-height: 32px;
  font-weight: 800;
`;

export const SelectArea = styled.div`
  width: 1091px;
  text-align: center;
  margin: 10px auto 0;
`;

export const SelectTableHead = styled.div`
  width: 1091px;
  height: 74px;
  display: flex;
  flex-direction: row;
`;

export const SelectTableHeadItem = styled.div`
  height: 100%;
  display: flex;
  font-size: 15px;
  color: #4d4d4d;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
`;

export const SelectTableBody = styled.div`
  width: 1091px;
  height: 260px;
  display: flex;
  flex-direction: row;
`;

export const SelectTableBodyColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectTableBodyColumnItem = styled.div<{ active: boolean }>`
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
  height: 52px;
  color: ${(props) => (props.active ? '#fff' : '#4d4d4d')};
  background: ${(props) => (props.active ? '#0087ED' : '#FFFFFF')};

  &:hover {
    background-color: #0087ed;
    color: #fff;
  }
`;

export const SelectTableDateTable = styled.div`
  width: 484px;
  height: 260px;
  display: grid;
  grid-template-columns: 69px 69px 69px 69px 69px 69px 69px;
  grid-template-rows: 52px 52px 52px 52px 52px;
`;

export const SelectTableDateCell = styled.div<{
  active: boolean;
  hoverable: boolean;
  inWeek: boolean;
  saturday: boolean;
  sunday: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 69px;
  height: 52px;
  padding: 0px;
  margin: 0px;
  border-left: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  cursor: ${(props) => (props.hoverable ? 'pointer' : 'default')};
  color: ${(props) =>
    props.active
      ? '#fff'
      : props.inWeek
      ? '#FFF'
      : props.saturday
      ? '#0087ED'
      : props.sunday
      ? '#ED4545'
      : '#4d4d4d'};
  background: ${(props) => (props.active ? '#0087ED' : props.inWeek ? '#0087ED' : '#FFFFFF')};

  &:hover {
    background-color: ${(props) => (props.hoverable ? '#0087ed' : '#FFFFFF')};
    color: ${(props) => (props.hoverable ? '#FFFFFF' : '#4D4D4D')};
  }
`;

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Main from './main';
import Plan from './plan';
import Login from './login';
import SignUp from './signup';
import Register from './register';
import UserList from './register/userList';
import Temporary from './temporary';
import Activity from './activity';
import Multi from './multi';
import Actsheet from './actsheet';
import Docuform from './docuform';
import Sound from './sound';
import Videos from './videos';
import DataPackage from './datapackage';
import Homeletter from './homeletter';
import GuideBook from './guidebook';
import RegActivityPlan from './plan/registerPlan/activityPlan';
import RegPlan from './plan/registerPlan';
import RegisterProgram from './registerProgram';
import RegisterSelect from './registerSelect';
import RegisterEduImage from './registerEduImage';
import RegisterEdu from './registerEdu';
import RegisterBulkProgram from './registerBulk/registerProgram';
import RegisterBulkEdu from './registerBulk/registerEdu';
import UpdateVisibleEdu from './updateVisibleEdu';
import UpdateActivityPlan from './updateActivityPlan';
import UpdateProgram from './updateProgram';
import Partners from './partners';
import Order from './partners/order';
import OrderRegister from './partners/orderRegister';
import OrderList from './partners/orderList';
import Banner from './banner';
import MyPage from './myPage';
import Cart from './myPage/cart';
import MyOrder from './myPage/order';

import Shopping from './shopping';
import CreateProduct from './shopping/createProduct';
import UpdateProduct from './shopping/updateProduct';
import ProductDetail from './shopping/productDetail';
import AdminOrders from './shopping/adminOrders';

import Edu from './fingeredu';

import Page404 from '../components/404';

import ProgramModal from '../components/programModal';
import PlanModal from '../components/planModal';
import EduModal from '../components/eduModal';
import Loader from '../components/loader';

const Router = () => {
  return (
    <BrowserRouter>
      <Loader />
      <ProgramModal />
      <PlanModal />
      <EduModal />
      <Switch>
        <Route exact={true} path="/" component={Main} />
        <Route exact={true} path="/404" component={Page404} />
        <Route exact={true} path="/login" component={Login} />
        <Route exact={true} path="/signUp" component={SignUp} />
        <Route exact={true} path="/register" component={Register} />
        <Route exact={true} path="/userList" component={UserList} />
        <Route exact={true} path="/temporary" component={Temporary} />
        <Route exact={true} path="/plan" component={Plan} />
        <Route exact={true} path="/activity" component={Activity} />
        <Route exact={true} path="/multi" component={Multi} />
        <Route exact={true} path="/actsheet" component={Actsheet} />
        <Route exact={true} path="/docuform" component={Docuform} />
        <Route exact={true} path="/sound" component={Sound} />
        <Route exact={true} path="/videos" component={Videos} />
        <Route exact={true} path="/datapackage" component={DataPackage} />
        <Route exact={true} path="/homeletter" component={Homeletter} />
        <Route exact={true} path="/guidebook" component={GuideBook} />
        <Route exact={true} path="/regactivityplan" component={RegActivityPlan} />
        <Route exact={true} path="/regplan" component={RegPlan} />
        <Route exact={true} path="/registerProgram" component={RegisterProgram} />
        <Route exact={true} path="/registerSelect" component={RegisterSelect} />
        <Route exact={true} path="/registerEdu" component={RegisterEdu} />
        <Route exact={true} path="/registerBulkProgram" component={RegisterBulkProgram} />
        <Route exact={true} path="/registerBulkEdu" component={RegisterBulkEdu} />
        <Route exact={true} path="/updateVisibleEdu" component={UpdateVisibleEdu} />
        <Route exact={true} path="/registerEduImage" component={RegisterEduImage} />
        <Route exact={true} path="/updateProgram" component={UpdateProgram} />
        <Route exact={true} path="/updateActivityPlan" component={UpdateActivityPlan} />
        <Route exact={true} path="/edu" component={Edu} />
        <Route exact={true} path="/shopping" component={Partners} />
        <Route exact={true} path="/shopping/order" component={Order} />
        <Route exact={true} path="/shopping/order/register" component={OrderRegister} />
        <Route exact={true} path="/shopping/order/list" component={OrderList} />
        <Route exact={true} path="/banner" component={Banner} />
        <Route exact={true} path="/shopping/open" component={Shopping} />
        <Route exact={true} path="/shopping/open/create" component={CreateProduct} />
        <Route exact={true} path="/shopping/open/update" component={UpdateProduct} />
        <Route exact={true} path="/shopping/open/detail" component={ProductDetail} />
        <Route exact={true} path="/shopping/admin/orders" component={AdminOrders} />

        <Route exact={true} path="/mypage" component={MyPage} />
        <Route exact={true} path="/mypage/cart" component={Cart} />
        <Route exact={true} path="/mypage/order" component={MyOrder} />
        {/* Not Found */}
        <Route component={() => <Redirect to="/404" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;

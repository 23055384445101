import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { boolean } from 'yup';

export const Container = styled.div`
  width: 100%;
  min-width: 1075px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const ItemTopArea = styled.div`
  width: 1075px;
  display: flex;
  gap: 30px;
  height: 577px;
  margin: 30px auto 100px;
`;

export const ItemThumbnailArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 516px;
  height: 100%;
  gap: 10px;
  position: relative;
`;

export const SoldoutThumbCover = styled.div`
  width: 516px;
  height: 445px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fea0a0;
  font-size: 80px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemThumbnailView = styled.img`
  width: 516px;
  height: 445px;
  object-fit: contain;
  border: 1px solid #e2e2e2;
`;

export const ItemThumbnailList = styled.div`
  width: 100%;
  height: 122px;
  gap: 10px;
  display: flex;
  overflow: auto;
`;

export const ItemThumbnailSmall = styled.img`
  border: 1px solid #e2e2e2;
  width: 121px;
  height: 122px;
  object-fit: contain;
  cursor: pointer;
`;

export const ItemInfoArea = styled.div`
  width: 529px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ItemNm = styled.div`
  font-size: 30px;
  height: 30px;
  font-weight: bold;
  color: #4d4d4d;
  display: flex;
  margin-bottom: 20px;
`;

export const priceArea = styled.div`
  width: 100%;
  height: 222px;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  gap: 30px;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  margin-bottom: 30px;
`;

export const priceRow = styled.div`
  width: 100%;
  height: 23px;
  display: flex;
  font-size: 18px;
  color: #7d7d7d;
  position: relative;
  align-items: center;
`;

export const explainSpan = styled.span`
  font-size: 13px;
  color: #aeaeae;
  margin-left: 10px;
`;

export const priceNumber = styled.span`
  position: absolute;
  right: 0;
  color: #4d4d4d;
`;

export const ItemCountWrapper = styled.div`
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
  display: flex;
`;

export const CountComponent = styled.div`
  width: 120px;
  height: 30px;
  display: flex;
  position: absolute;
  right: 0;
`;

export const CountDownButton = styled.div`
  width: 35px;
  height: 30px;
  border-top: 1px solid #cecece;
  border-left: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CountUpButton = styled.div`
  width: 35px;
  height: 30px;
  border-top: 1px solid #cecece;
  border-right: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CountItem = styled.div`
  width: 50px;
  height: 30px;
  border: 1px solid #cecece;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TotalPriceArea = styled.div`
  width: 100%;
  height: 123px;
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  background-color: #dfdfdf;
  display: flex;
  position: relative;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
`;

export const TotalPriceLabel = styled.div`
  font-size: 18px;
  color: #4d4d4d;
  position: absolute;
  left: 30%;
`;

export const TotalPrice = styled.div`
  font-size: 30px;
  color: #4d4d4d;
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  right: 10px;
`;

export const TotalPricePostfix = styled.span`
  font-size: 20px;
  font-weight: 400;
`;

export const ButtonArea = styled.div`
  width: 100%;
  gap: 10px;
  height: 80px;
  display: flex;
  align-items: center;
`;

export const SoldOutPanel = styled.div`
  width: 100%;
  height: 100%;
  background-color: #efefef;
  border: 1px solid #cecece;
  color: #fea0a0;
  font-size: 26px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BuyButton = styled.div`
  width: 329px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #0091ff;
  font-size: 20px;
  gap: 10px;
  cursor: pointer;
`;

export const CartButton = styled.div`
  width: 190px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #ababab;
  font-size: 20px;
  gap: 10px;
  cursor: pointer;
`;

export const FilterArea = styled.div`
  width: 1075px;
  height: 50px;
  margin: 30px auto;
  display: flex;
`;

export const FilterItem = styled.div<{ active: boolean; isLast?: boolean }>`
  width: 268px;
  height: 100%;
  background-color: ${(props) => (props.active ? '#5d5d5d' : '#FFFFFF')};
  border-top: 1px solid #5d5d5d;
  border-bottom: 1px solid #5d5d5d;
  border-left: 1px solid #5d5d5d;
  ${(props) => (props.isLast ? 'border-right: 1px solid #5d5d5d;' : null)}
  color: ${(props) => (props.active ? '#ffffff' : '#4d4d4d')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
`;

export const InfoTable = styled.table`
  width: 800px;
  margin: 0 auto 50px;
  border-spacing: 0px;
  border-collapse: collapse;
`;

export const InfoTbody = styled.tbody``;

export const InfoTr = styled.tr``;

export const InfoTh = styled.th`
  background-color: #eaeaea;
  color: #4d4d4d;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  width: 15%;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  font-weight: 400;
  padding: 15px 15px;
`;

export const InfoTd = styled.td`
  padding: 15px 15px;
  color: #4d4d4d;
  font-size: 14px;
  width: 35%;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  border-left: 1px solid #cecece;
  text-align: left;
  line-height: 18px;
`;

export const DetailPageList = styled.div`
  width: 900px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const DetailPage = styled.img`
  width: 100%;
`;

export const InfoTdBold = styled.div`
  font-weight: bold;
  line-height: 18px;
`;

export const InfoTdList = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const InfoTdListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 15px 0;
`;

import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoginPresenter from './LoginPresenter';
import * as Yup from 'yup';
import * as util from '../../util/util';
import { userApi } from '../../api/api-user';
import { useSetRecoilState, useRecoilState } from 'recoil';
import * as recoilItem from '../../util/recoilitem';
import Header from '../../components/header';
import Footer from '../../components/footer';

interface LoginProps extends RouteComponentProps {}

const LoginContainer = (props: LoginProps) => {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  const setToken = useSetRecoilState(recoilItem.token);
  const setUserInfo = useSetRecoilState(recoilItem.userInfo);

  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const formSchema = Yup.object().shape({
    id: Yup.string().required(),
    password: Yup.string().required(),
  });

  const onChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setId(e.target.value);
  };

  const onChangePw = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };
  const onSubmit = () => {
    let res: any = null;

    formSchema
      .validate({ id: id, password: password })
      .then(async function () {
        setIsLoading(true);
        let loginForm: FormData = new FormData();

        loginForm.append('userId', id);
        loginForm.append('password', password);

        try {
          res = await userApi.login(loginForm);
          console.log(res);
          if (res && res.data.rsltCd === '00') {
            setToken(res.data.sessionToken);
            setUserInfo({
              userKey: res.data.userKey,
              userId: res.data.userId,
              userNm: res.data.userNm,
              userTypeCd: res.data.userTypeCd,
              useYn: res.data.useYn,
              regDtm: res.data.regDtm,
              userEmail: res.data.userEmail,
              userMobno: res.data.userMobno,
            });
            setIsLoading(false);
            if (res.data.useYn === 'Y') {
              util.makeMsg('로그인 되었습니다.', 'success');
              props.history.push('/');
            } else if (res.data.useYn === 'W') {
              util.makeMsg('임시페이지로 이동합니다.', 'success');
              props.history.push('/temporary');
            }
          } else {
            util.makeMsg('로그인에 실패하였습니다.', 'error');
          }
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      })
      .catch(function () {
        util.makeMsg('값을 모두 입력해주세요.', 'error');
      });
  };

  const onSignUp = () => {
    props.history.push('/signUp');
  };

  return (
    <>
      <Header {...props} />
      <LoginPresenter
        onChangeId={onChangeId}
        onChangePw={onChangePw}
        onKeyPress={onKeyPress}
        onSubmit={onSubmit}
        onSignUp={onSignUp}
      ></LoginPresenter>
      <Footer {...props} />
    </>
  );
};

export default LoginContainer;

import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const token = atom({
  key: 'token',
  default: {
    accessToken: '',
    refreshToken: '',
  },
  effects_UNSTABLE: [persistAtom],
});

export const isLoading = atom<boolean>({
  key: 'isLoading',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const programId = atom<null | number>({
  key: 'currentProgram',
  default: null,
  effects_UNSTABLE: [persistAtom],
});


export const programSubId = atom<null | number>({
  key: 'currentProgramSub',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const planId = atom<null | number>({
  key: 'currentPlan',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const eduSearch = atom<null | any>({
  key: 'eduSearch',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const userInfo = atom({
  key: 'userInfo',
  default: {
    userKey: '',
    userId: '',
    userNm: '',
    userTypeCd: '',
    useYn: '',
  },
  effects_UNSTABLE: [persistAtom],
});

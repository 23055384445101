import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1091px;
  min-height: 700px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const PlanningHeader = styled.div`
  width: 1091px;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  padding: 50px 0 0px;
  vertical-align: middle;
`;

export const PlanningHeaderTitle = styled.div`
  float: left;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const PlanningHeaderRightArea = styled.div`
  float: right;
  padding: 10px 0;
  text-align: center;
  vertical-align: middle;
`;
export const PlanningHeaderRightIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 5px;
  vertical-align: middle;
`;

export const PlanningHeaderRightText = styled.div`
  font-weight: 400;
  color: #707070;
  font-size: 10px;
  vertical-align: middle;
`;

export const PlanningTabArea = styled.ul`
  width: 1091px;
  height: auto;
  margin: 0 auto;
  align-items: center;
  border-bottom: 1px solid #DEDEDE;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const PlanningTab = styled.li<{ active: boolean, color: string }>`
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-top: 30px;
  width: 273px;
  height: 49px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 18px;
  padding: 0px;
  margin: 0px;
  font-weight: ${(props) => (props.active ? 500 : 400)};
  cursor: pointer;
  background-color: ${(props) => (props.active ? props.color : '#EEEEEE')};
  color: ${(props) => (props.active ? '#fff' : '#4d4d4d')};

  &:hover {
    background-color: ${(props) => props.color};
    color: #fff;
  }
`;

export const SelectTableHead = styled.div`
  width: 1091px;
  height: 74px;
  display: flex;
  flex-direction: row;
`;

export const SelectTableHeadItem = styled.div`
  height: 100%;
  display: flex;
  font-size: 15px;
  color: #4D4D4D;
  align-items: center;
  justify-content: center;
  background-color: #F3F3F3;
`;

export const SelectTableBody = styled.div`
  width: 1091px;
  height: 260px;
  display: flex;
  flex-direction: row;
`;

export const SelectTableBodyColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectTableBodyColumnItem = styled.div<{ active: boolean, color: string }>`
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #DEDEDE;
  border-bottom: 1px solid #DEDEDE;
  cursor: pointer;
  height: 52px;
  color: ${(props) => (props.active ? '#fff' : '#4d4d4d')};
  background: ${(props) =>
    props.active ? props.color : '#FFFFFF'};

  &:hover {
    background-color: ${props => props.color};
    color: #fff;
  }
`;

export const SelectTableDateTable = styled.div`
  width: 484px;
  height: 260px;
  display: grid;
  grid-template-columns: 69px 69px 69px 69px 69px 69px 69px;
  grid-template-rows: 52px 52px 52px 52px 52px;
`;

export const SelectTableDateCell = styled.div<{ active: boolean, hoverable: boolean, inWeek: boolean, saturday: boolean, sunday: boolean, color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 69px;
  height: 52px;
  padding: 0px;
  margin: 0px;
  border-left: 1px solid #DEDEDE;
  border-bottom: 1px solid #DEDEDE;
  cursor: ${(props) => props.hoverable ? 'pointer' : 'default'};
  color: ${(props) => (props.active ? '#fff' : 
                        props.inWeek ? '#FFF' :
                        props.saturday ? '#0087ED' :
                        props.sunday ? '#ED4545' :
                        '#4d4d4d')};
  background: ${(props) =>
    props.active ? props.color 
    : props.inWeek ? props.color
    : '#FFFFFF'};

  &:hover {
    background-color: ${(props) => (props.hoverable ? props.color : '#FFFFFF')};
    color: ${(props) => props.hoverable ? '#FFFFFF' : '#4D4D4D'}
  }
`;



export const FullSelectArea = styled.div`
  width: 1130px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const SelectArea = styled.div`
  width: 1091px;
  text-align: center;
  margin: 10px auto 0;
`;
export const FullSelectDiv = styled.div`
  width: 2.5%;
  margin: 20px auto;
  text-align: center;
  border-bottom: 1px solid;
`;

export const FullSelectLabel = styled.div`
  margin: 30px auto 0;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const MonthSelectLabel = styled.div`
  margin: 50px auto 0;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const HalfSelectLabel = styled.div`
  margin: 30px auto 30px;
  text-align: center;
  font-size: 26px;
  font-weight: 800;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const FullSelectForm = styled.div`
  width: 1000px;
  margin: 0px auto;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const FullSelectFormAge = styled.div`
  width: 900px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const FullSelectForMonth = styled.div`
  margin-top: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;
export const FullSelectForWeek = styled.div`
  width: 100%;
  margin: 20px auto 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const SliderArrowIcon = styled.div`
  display: inline-block;
  width: 15px;
  height: 25px;
  cursor: pointer;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
`;

export const SliderArrowIconImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const YearItem = styled.div<{ active: boolean }>`
  width: 141px;
  height: 59px;
  font-family: 'Gosanja';
  font-size: 20px;
  color: ${(props) => (props.active ? '#fff' : '#9d9d9d')};
  border: 1px solid ${(props) => (props.active ? '#3A96CF' : '#ccc')};
  border-radius: 30px;
  margin: 0 20px;
  background: ${(props) =>
    props.active ? 'linear-gradient(to bottom,#59A9DA 95%, #59A9DA 5px,  #3B89C9 5%)' : '#fff'};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AgeItem = styled.div<{ active: boolean }>`
  width: 133px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Gosanja';
  font-size: 20px;
  color: ${(props) => (props.active ? '#fff' : '#9d9d9d')};
  border: 1px solid ${(props) => (props.active ? '#3A96CF' : '#ccc')};
  border-radius: 30px;
  margin: 0 10px;
  background: ${(props) =>
    props.active ? 'linear-gradient(to bottom,#59A9DA 95%, #59A9DA 5px,  #3B89C9 5%)' : '#fff'};
  cursor: pointer;
`;

export const HalfSelectLeftArea = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
`;

export const HalfSelectRightArea = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
`;

export const MonthItem = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Gosanja';
  width: 160px;
  height: 53px;
  font-size: 20px;
  color: ${(props) => (props.active ? '#fff' : '#9d9d9d')};
  border: 1px solid ${(props) => (props.active ? '#3A96CF' : '#ccc')};
  border-radius: 30px;
  margin: 0 10px 20px;
  background: ${(props) =>
    props.active ? 'linear-gradient(to bottom,#59A9DA 95%, #59A9DA 5px,  #3B89C9 5%)' : '#fff'};
  cursor: pointer;
`;

export const SelectLine = styled.div``;

export const WeekItem = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Gosanja';
  width: 160px;
  height: 53px;
  font-size: 20px;
  color: ${(props) => (props.active ? '#fff' : '#9d9d9d')};
  border: 1px solid ${(props) => (props.active ? '#3A96CF' : '#ccc')};
  border-radius: 30px;
  margin: 0 10px;
  background: ${(props) =>
    props.active ? 'linear-gradient(to bottom,#59A9DA 95%, #59A9DA 5px,  #3B89C9 5%)' : '#fff'};
  cursor: pointer;
`;

export const DayPickerCaption = styled.div`
  position: absolute;
  text-align: center;
  top: 0;
  left: 46%;
  font-size: 26px;
  font-family: 'BRBA_B';
  color: #5d5d5d;
`;

export const DayPickerWeekday = styled.div`
  font-size: 20px;
  color: #4D4D4D;
`;

export const PlanNotFoundText = styled.div<{color: string}>`
  display: flex;
  margin: 100px auto;
  color: ${props => props.color}
  font-size: 20px;
  display: inline-block;
  line-height: 32px;
  font-weight: 800;
`;

import React from 'react';
import { SetterOrUpdater } from 'recoil';

import * as s from './SearchModalStyled';

const check = '/static/img/check.png';
const lens = '/static/img/search-icon.png';
const burgerMenu = '/static/img/burgerMenu.png';
const leftArrow = '/static/img/L_arrow.png';
const rightArrow = '/static/img/R_arrow.png';

interface SearchModalProps {
  type: string;
  ageAuths: any;
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };

  topicList: any[];
  subTopicList: any[];
  colorList: any[];

  popupOn: any;
  setPopupOn: (arg0: any) => void;
  setPlanId: SetterOrUpdater<number | null>;

  keyword: string;
  onSetKeyword: React.ChangeEventHandler<HTMLInputElement>;
  activityList: any[];
  onSearch: () => void;

  page: number;
  setPage: (arg0: number) => void;
  onNextPage: () => void;
  onPrevPage: () => void;
  nowPage: number;
  pagePostCount: number;
  pageList: number[];

  addActivityList: any[];
  onIsInActivityList: (arg0: any) => boolean;
  onSetAddActivityList: (arg0: any) => void;
  onCheckAddActivityList: (arg0: any) => void;

  chooseSubTopic: any;
  setChooseSubTopic: any;

  chooseColor: any;
  setChooseColor: any;

  onConfirm: () => void;
  onClose: () => void;
  getType: string;
  setGetType: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;

  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const SearchModalPresenter = (props: SearchModalProps) => {
  return (
    <s.Container>
      <s.TitleWrapper>
        <s.TitleLeftWrapper>
          <s.Title isActive={props.getType === 'search'} onClick={() => props.setGetType('search')}>
            자료 가져오기
          </s.Title>
          <s.Title isActive={props.getType === 'input'} onClick={() => props.setGetType('input')}>
            내용 입력하기
          </s.Title>
        </s.TitleLeftWrapper>
        {props.getType === 'search' ? (
          <s.EachContentSearchWrapper>
            <s.EachContentSearchBar
              value={props.keyword}
              onChange={props.onSetKeyword}
              onKeyPress={props.onKeyPress}
              disabled={false}
            />
            <s.EachContentSearchButton onClick={props.onSearch} disabled={false}>
              <s.EachContentSearchButtonImg src={lens} />
            </s.EachContentSearchButton>
          </s.EachContentSearchWrapper>
        ) : null}
      </s.TitleWrapper>
      {props.getType === 'search' ? (
        <s.ContentWrapper>
          {props.popupOn.x >= 2 ? (
            <>
              <s.TopicWrapper>
                <s.TopicIcon src={burgerMenu} />
                {props.subTopicList.map((subTopic: any) => {
                  return (
                    <s.Topic
                      isSelect={
                        props.chooseSubTopic && subTopic.comnCd === props.chooseSubTopic.comnCd
                      }
                      onClick={() => props.setChooseSubTopic(subTopic)}
                    >
                      {subTopic.comnCdNm}
                    </s.Topic>
                  );
                })}
              </s.TopicWrapper>
              <s.ResultContentWrapper>
                {props.activityList && props.activityList.length ? (
                  props.activityList.map((activity) => {
                    return (
                      <s.ResultEachItemWrapper key={activity.activityId}>
                        <s.ResultItemInfoTopic>{activity.domain.comnCdNm}</s.ResultItemInfoTopic>
                        <s.ResultItemInfoNm onClick={() => props.setPlanId(activity.activityId)}>
                          {activity.activityNm}
                          {activity.materialList && activity.materialList.length
                            ? activity.materialList.map((icon: any) => (
                                <s.ResultItemInfoNmImg
                                  src={`/static/img/${icon.icon.comnCd}.jpg`}
                                />
                              ))
                            : null}
                        </s.ResultItemInfoNm>
                        <s.ResultItemInfoAge>
                          {props.option.age
                            ? `만 ${props.ageAuths[props.option.age].comnCdNm}`
                            : null}
                        </s.ResultItemInfoAge>
                        <s.ResultItemButton
                          onClick={() => props.onCheckAddActivityList(activity)}
                          disabled={false}
                        >
                          {props.onIsInActivityList(activity) ? (
                            <s.ResultItemButtonCheckImg src={check} />
                          ) : (
                            <s.ResultItemButtomEmptyImg />
                          )}
                        </s.ResultItemButton>
                      </s.ResultEachItemWrapper>
                    );
                  })
                ) : (
                  <s.NoResult>검색 결과가 없습니다.</s.NoResult>
                )}
              </s.ResultContentWrapper>
              <s.PageWrapper>
                {props.page > props.pagePostCount ? (
                  <s.PageButton onClick={props.onPrevPage}>
                    <s.PagePrev src={leftArrow} />
                  </s.PageButton>
                ) : null}
                {props.pageList
                  .slice(
                    props.nowPage * props.pagePostCount,
                    (props.nowPage + 1) * props.pagePostCount,
                  )
                  .map((page: number) => (
                    <s.EachPage onClick={() => props.setPage(page)} isSelect={props.page === page}>
                      {page}
                    </s.EachPage>
                  ))}
                {props.pageList.length > (props.nowPage + 1) * props.pagePostCount ? (
                  <s.PageButton onClick={props.onNextPage}>
                    <s.PageNext src={rightArrow} />
                  </s.PageButton>
                ) : null}
              </s.PageWrapper>
            </>
          ) : (
            <>
              <s.TopicWrapper>
                <s.TopicIcon src={burgerMenu} />
                {props.colorList.map((color: any) => {
                  return (
                    <s.ColorButton
                      isChoose={props.chooseColor && props.chooseColor.comnCd === color.comnCd}
                      color={color.comnCdNm.split('/')[0]}
                      onClick={() => props.setChooseColor(color)}
                    />
                  );
                })}
              </s.TopicWrapper>
              <s.ResultTopicContentWrapper>
                {props.popupOn.x ? (
                  props.subTopicList.length ? (
                    props.subTopicList.map((subTopic: any) => (
                      <s.ResultTopicWrapper
                        isSelect={
                          props.addActivityList.length &&
                          props.addActivityList[0].typeCd === subTopic.comnCd
                            ? true
                            : false
                        }
                        onClick={() => props.onSetAddActivityList(subTopic)}
                      >
                        {subTopic.comnCdNm}
                      </s.ResultTopicWrapper>
                    ))
                  ) : (
                    <s.NoResult>검색 결과가 없습니다.</s.NoResult>
                  )
                ) : props.topicList.length ? (
                  props.topicList.map((topic: any) => (
                    <s.ResultTopicWrapper
                      isSelect={
                        props.addActivityList.length &&
                        props.addActivityList[0].typeCd === topic.comnCd
                          ? true
                          : false
                      }
                      onClick={() => props.onSetAddActivityList(topic)}
                    >
                      {topic.comnCdNm}
                    </s.ResultTopicWrapper>
                  ))
                ) : (
                  <s.NoResult>검색 결과가 없습니다.</s.NoResult>
                )}
              </s.ResultTopicContentWrapper>
            </>
          )}
          <s.BigButtonWrapper>
            <s.BigButton isCloseButton={false} onClick={props.onConfirm}>
              <s.BigButtonText>가져오기</s.BigButtonText>
            </s.BigButton>
            <s.BigButton isCloseButton={true} onClick={props.onClose}>
              <s.BigButtonText>닫기</s.BigButtonText>
            </s.BigButton>
          </s.BigButtonWrapper>
        </s.ContentWrapper>
      ) : (
        <s.ContentWrapper>
          <s.ContentInputWrapper>
            <s.ContentInputLabel>내용 입력 : </s.ContentInputLabel>
            <s.ContentInputInput
              value={props.description}
              onChange={(e) => props.setDescription(e.target.value)}
            />
          </s.ContentInputWrapper>
          <s.BigButtonWrapper>
            <s.BigButton isCloseButton={false} onClick={props.onConfirm}>
              <s.BigButtonText>가져오기</s.BigButtonText>
            </s.BigButton>
            <s.BigButton isCloseButton={true} onClick={props.onClose}>
              <s.BigButtonText>닫기</s.BigButtonText>
            </s.BigButton>
          </s.BigButtonWrapper>
        </s.ContentWrapper>
      )}
    </s.Container>
  );
};

export default SearchModalPresenter;

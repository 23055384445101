import styled from 'styled-components';

export const Container = styled.div`
  /* width: 100%;
  height: 100%; */
  margin: 0 auto;
  text-align: center;
  z-index: 9980;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
`;

export const ModalContainer = styled.div<{ topIndex: number; subLength: number; preLen: number }>`
  width: 50%;
  height: 550px;
  margin: 0 auto;
  z-index: 9981;
  text-align: center;
  /* top: ${(props) =>
    `${650 + 250 * props.topIndex + 60 * props.preLen + 65 * props.subLength}px`}; */
  top: 15%;
  left: 0;
  right: 0;
  position: absolute;
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #0087ed;
`;
// export const Container = styled.div`
//   width: 50%;
//   height: 520px;
//   z-index: 99;
//   position: fixed;

//   margin: 0 auto;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   align-self: center;
//   background-color: #fff;

//   border: 1px solid #0087ed;
//   border-radius: 5px;
// `;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 15px 30px;

  border: none;
  border-bottom: 1px solid #ddd;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 800;

  color: #0087ed;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const TopicWrapper = styled.div`
  overflow: scroll;
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;

  padding: 10px 0;

  border: none;
  border-bottom: 1px solid #ddd;

  ::-webkit-scrollbar {
    height: 3px;
    margin: 0;
    width: 0px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #0087ed;
    margin: 1px;
  }
`;

export const TopicIcon = styled.img`
  height: 20px;
  width: 20px;
  margin: 0 8px 0 16px;
`;

export const Topic = styled.button<{ isSelect: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 30px;
  min-width: 80px;

  border: ${(props) => (props.isSelect ? '1px solid #0087ed' : '1px solid #a0a0a0')};
  background-color: ${(props) => (props.isSelect ? '#0087ed' : 'transparent')};
  color: ${(props) => (props.isSelect ? '#fff' : '#a0a0a0')};

  border-radius: 30px;
  margin: 0 8px;

  font-size: 11px;
  font-weight: 800;

  transition: 0.25s all;

  &:hover {
    background-color: #0087ed;
    color: #fff;
    border: 1px solid #0087ed;
  }
`;

export const ResultContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0px 0 0 0;

  height: 300px;
`;

export const ResultTopicContentWrapper = styled.div`
  overflow: scroll;

  height: 300px;

  &::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0087ed;
  }
`;

export const EachContentButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  margin: 0 0 10px 0;
`;

export const EachContentButton = styled.button<{ isEditing: boolean }>`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  border: none;
  background-color: transparent;

  color: ${(props) => (props.isEditing ? '#0087ed' : '#343434')};

  transition: 0.25s all;

  padding: 5px;

  &:hover {
    color: #0087ed;
  }
`;

export const EachContentButtonImg = styled.img`
  height: 20px;
  width: 20px;

  margin: 0 5px 0 0;
`;

export const EachContentButtonText = styled.div`
  font-weight: 700;
`;

export const ColorChooseWrapper = styled.div`
  display: flex;
  flex-direction: row;

  padding: 3px 0;
  border: none;
`;

export const ColorChooseMsg = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: #a0a0a0;
`;

export const ColorButton = styled.button<{ color: string; isChoose: boolean }>`
  border: ${(props) => (props.isChoose ? '2px solid #0087ed' : '2px solid transparent')};
  height: 12px;
  width: 12px;
  border-radius: 100px;

  background-color: ${(props) => props.color};

  margin: 0 0 0 10px;
`;

export const EachContentSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #a0a0a0;
  border-radius: 30px;
  padding: 3px 4px;

  justify-content: space-between;
`;

export const EachContentSearchBar = styled.input`
  border: none;
  width: 100%;
  padding: 0px 10px;
  border-radius: 30px;
`;

export const EachContentSearchButton = styled.button`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 5px;

  border: none;
  background-color: transparent;

  transition: 0.25s all;

  &:hover {
    color: #0087ed;
  }
`;

export const EachContentSearchButtonImg = styled.img`
  height: 18px;
  width: 18px;

  margin: 0 5px 0 0;
`;

export const EachContentSearchButtonText = styled.div`
  font-size: 13px;
`;

export const SelectActivityWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin: 20px 0 0 0;

  height: 40px;
  padding: 1px 0px;

  align-items: center;
`;

export const SelectActivityInfoText = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
`;

export const SelectActivityListWrapper = styled.div`
  overflow-x: auto;

  height: 100%;
  flex: 4.5;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  color: #a0a0a0;
  font-size: 10px;

  &::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0087ed;
  }
`;

export const AcitivityWrapper = styled.div`
  background-color: #f2f2f2;
  border: none;
  border-radius: 10px;

  min-width: 110px;

  margin: 0 0 0 5px;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  padding: 3px 10px;
`;

export const ActivityTitle = styled.div`
  color: #a0a0a0;
  font-weight: 700;
`;

export const ActivityDeleteButton = styled.button`
  display: flex;

  justify-content: center;
  align-items: center;
  pading: 1px;

  border: none;
  background-color: transparent;
  margin: 0 0 0 3px;
`;

export const ActivityDeleteButtonImg = styled.img`
  height: 10px;
  width: 10px;
`;

export const NoResult = styled.div`
  flex: 1;

  display: flex;

  justify-content: center;
  align-items: center;

  color: #a0a0a0;
`;

export const ResultEachItemWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  border: none;
  border-bottom: 1px solid #ddd;
  background-color: transparent;

  height: 20%;
  width: 100%;

  &:last-of-type {
    border-right: none;
  }
`;

export const ResultSeperatorLine = styled.div`
  height: 30px;
  border: none;
  border-right: 1px solid #ddd;
`;

export const ResultItemInfoTopic = styled.div`
  flex: 3;
  font-size: 14px;
  font-weight: 800;
  color: #343434;

  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
`;

export const ResultItemInfoNm = styled.button`
  flex: 4;
  margin: 0px 15px;

  font-size: 12px;
  font-weight: 500;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;

  background-color: transparent;
  border: none;

  transition: 0.25s all;

  &:hover {
    color: #0087ed;
  }
`;

export const ResultItemInfoNmImg = styled.img`
  margin: 0 0 0 5px;
  height: 12px;
  width: 12px;
`;

export const ResultItemInfoAge = styled.div`
  flex: 2;
  font-size: 12px;
  font-weight: 500;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;

  border: none;
`;

export const ResultItemButton = styled.button`
  flex: 1;

  border: none;
  background-color: transparent;
  margin: 0 4px;

  font-size: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;

  transition: 0.25s all;

  &:hover {
    opacity: 0.5;
  }
`;

export const ResultItemButtonCheckImg = styled.img`
  height: auto;
  width: 30%;
`;

export const ResultItemButtomEmptyImg = styled.div`
  height: 20px;
  width: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: transparent;
`;

export const ResultTopicWrapper = styled.button<{ isSelect: boolean }>`
  margin: 0 0 0 3px;
  display: flex;
  flex-direction: row;

  align-items: center;

  border: none;
  border-bottom: 1px solid #ddd;
  background-color: ${(props) => (props.isSelect ? '#0087ed' : 'transparent')};
  color: ${(props) => (props.isSelect ? '#fff' : '#000')};

  height: 40px;
  width: 100%;

  transition: 0.25s all;

  justify-content: center;
  align-items: center;

  padding: 10px 0;

  &:hover {
    color: #fff;
    background-color: #0087ed;
  }

  &:last-of-type {
    border-right: none;
  }
`;

export const PageWrapper = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border: none;
`;

export const PageButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: transparent;

  transition: 0.25s all;

  &:hover {
    opacity: 0.5;
  }
`;

export const PagePrev = styled.img`
  height: 10px;
  width: 10px;
`;

export const PageNext = styled.img`
  height: 10px;
  width: 10px;
`;

export const EachPage = styled.button<{ isSelect: boolean }>`
  font-size: 12px;
  font-weight: 500;

  border: none;
  background-color: transparent;

  transition: 0.25s all;

  color: ${(props) => (props.isSelect ? '#0087ed' : '#000')};

  &:hover {
    color: #0087ed;
  }
`;

export const BigButtonWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: row;

  width: 70%;

  justify-content: space-around;
  align-items: center;
  align-self: center;
  z-index: 9999999999;
`;

export const BigButton = styled.button<{ isCloseButton: boolean }>`
  height: 30px;
  width: 160px;

  border-radius: 30px;

  border: 1px solid ${(props) => (props.isCloseButton ? '#0087ed' : 'transparent')};
  background-color: ${(props) => (props.isCloseButton ? 'transparent' : '#0087ed')};
  color: ${(props) => (props.isCloseButton ? '#0087ed' : '#fff')};

  margin: 0 5px;

  transition: 0.25s all;
`;

export const BigButtonText = styled.div`
  font-weight: 700;
  font-size: 13px;
`;

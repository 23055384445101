import React, { Dispatch, SetStateAction } from 'react';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { RouteComponentProps } from 'react-router-dom';

import BeatLoader from 'react-spinners/BeatLoader';
import * as s from './RegisterSoundStyled';
import LyricsUpload from './component/Lyrics';
import SheetUpload from './component/Sheet';
import VideoUpload from './component/Video';

interface RegisterSoundProps extends RouteComponentProps {
  option: {
    holder: string;
    subMenu: string;
    type: string;
    field: string;
    visible: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
  setIsModifying: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  programSounds: any;
  setProgramSounds: React.Dispatch<React.SetStateAction<any>>;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  open: () => void;
  isDragActive: boolean;
  hasVideo: boolean;
  setHasVideo: React.Dispatch<React.SetStateAction<boolean>>;
  isTune: boolean;
  setIsTune: React.Dispatch<React.SetStateAction<boolean>>;
  addVideo: () => void;
  deleteVideo: (index: number) => void;
  thumbnail: any;
  setThumbnail: React.Dispatch<React.SetStateAction<any>>;
}

const RegisterSoundPresenter = (props: RegisterSoundProps) => {
  return (
    <>
      <s.UploadProgramAreaHeader>
        <s.UploadProgramAreaHeaderSelect>
          <s.UploadProgramAreaHeaderLabel>제목</s.UploadProgramAreaHeaderLabel>
          <s.UploadProgramAreaHeaderInput
            value={props.title}
            onChange={(e) => props.setTitle(e.target.value)}
            placeholder={'텍스트를 입력해주세요.'}
            disabled={!props.isModifying}
          />
          <s.UploadProgramAreaHeaderLabel>시간</s.UploadProgramAreaHeaderLabel>
          <s.UploadProgramAreaSmallInput
            value={props.programSounds && props.programSounds.time}
            onChange={(e) =>
              props.setProgramSounds({ ...props.programSounds, time: e.target.value })
            }
            placeholder={'시간'}
            disabled={!props.isModifying}
          />
          <s.UploadProgramAreaSelectType
            id={'hasVideo'}
            defaultChecked={props.hasVideo}
            onChange={(e) => props.setHasVideo(e.target.checked)}
            type={'checkbox'}
            disabled={!props.isModifying}
          />
          <s.UploadProgramAreaSelectTypeLabel htmlFor={'hasVideo'}>
            {props.hasVideo ? (
              <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
            ) : null}
          </s.UploadProgramAreaSelectTypeLabel>
          <s.UploadProgramAreaSelectText htmlFor={'hasVideo'}>
            영상포함
          </s.UploadProgramAreaSelectText>
        </s.UploadProgramAreaHeaderSelect>
      </s.UploadProgramAreaHeader>
      <s.UploadProgramAreaFileUploadArea
        isDrag={props.isDragActive}
        {...props.getRootProps({ className: 'dropzone' })}
      >
        <s.UploadProgramAreaFileUploadAreaInput {...props.getInputProps()} />
        {props.programSounds && props.programSounds.file && props.programSounds.file.length > 0 ? (
          <s.UploadProgramAreaFileUploadAreaButtonIcon
            onClick={props.open}
            src="/static/img/pdf_icon_1.png"
          />
        ) : null}
        <s.UploadProgramAreaFileUploadAreaText>
          {props.programSounds && props.programSounds.file
            ? props.programSounds.file.name
            : '파일을 선택해주세요.'}
        </s.UploadProgramAreaFileUploadAreaText>
        <s.UploadProgramAreaFileUploadAreaButtonIcon
          onClick={props.open}
          src={
            props.programSounds && props.programSounds.file
              ? '/static/img/file_exist.png'
              : '/static/img/selectFile.png'
          }
        />
      </s.UploadProgramAreaFileUploadArea>
      <s.UploadProgramAreaFooter>
        <s.UploadProgramAreaFooterInputArea>
          {!props.isTune ? (
            <>
              <s.UploadProgramAreaFooterLabel>작사</s.UploadProgramAreaFooterLabel>
              <s.UploadProgramAreaFooterInput
                value={props.programSounds && props.programSounds.lyricist}
                onChange={(e) =>
                  props.setProgramSounds({ ...props.programSounds, lyricist: e.target.value })
                }
                placeholder={'텍스트를 입력해주세요.'}
                disabled={!props.isModifying}
              />
              <s.UploadProgramAreaFooterLabel>작곡</s.UploadProgramAreaFooterLabel>
              <s.UploadProgramAreaFooterInput
                value={props.programSounds && props.programSounds.composer}
                onChange={(e) =>
                  props.setProgramSounds({ ...props.programSounds, composer: e.target.value })
                }
                placeholder={'텍스트를 입력해주세요.'}
                disabled={!props.isModifying}
              />
            </>
          ) : (
            <>
              <s.UploadProgramAreaFooterLabel>연주곡</s.UploadProgramAreaFooterLabel>
              <s.UploadProgramAreaFooterLongInput
                value={props.programSounds && props.programSounds.tune}
                onChange={(e) =>
                  props.setProgramSounds({ ...props.programSounds, tune: e.target.value })
                }
                placeholder={'텍스트를 입력해주세요.'}
                disabled={!props.isModifying}
              />
            </>
          )}
          <s.UploadProgramAreaSelectType
            id={'isTune'}
            defaultChecked={props.isTune}
            onChange={(e) => props.setIsTune(e.target.checked)}
            type={'checkbox'}
            disabled={!props.isModifying}
          />
          <s.UploadProgramAreaSelectTypeLabel htmlFor={'isTune'}>
            {props.isTune ? (
              <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
            ) : null}
          </s.UploadProgramAreaSelectTypeLabel>
          <s.UploadProgramAreaSelectText htmlFor={'isTune'}>연주곡</s.UploadProgramAreaSelectText>
        </s.UploadProgramAreaFooterInputArea>
      </s.UploadProgramAreaFooter>
      <LyricsUpload
        isModifying={props.isModifying}
        submitFile={props.programSounds && props.programSounds.lyrics}
        setSubmitFile={(file: File) =>
          props.setProgramSounds({
            ...props.programSounds,
            lyrics: file,
          })
        }
      />
      <SheetUpload
        isModifying={props.isModifying}
        submitFile={props.programSounds && props.programSounds.sheet}
        setSubmitFile={(file: File) =>
          props.setProgramSounds({
            ...props.programSounds,
            sheet: file,
          })
        }
      />
      {props.hasVideo ? (
        <s.UploadProgramAreaFooterButtonArea>
          <s.SubmitButton onClick={props.addVideo}>
            <s.SubmitButtonText>영상 추가</s.SubmitButtonText>
          </s.SubmitButton>
        </s.UploadProgramAreaFooterButtonArea>
      ) : null}
      {props.hasVideo
        ? props.programSounds.videos &&
          props.programSounds.videos.map(
            (
              item: {
                file: File;
                url: string;
                type: string;
                description: string;
              },
              index: number,
            ) => (
              <VideoUpload
                isModifying={props.isModifying}
                submitVideo={props.programSounds && props.programSounds.videos[index]}
                setSubmitVideo={(arg1: {
                  file: File;
                  url: string;
                  type: string;
                  description: string;
                }) => {
                  let tempList = [...props.programSounds.videos];
                  tempList.push(arg1);
                  props.setProgramSounds({
                    ...props.programSounds,
                    videos: tempList,
                  });
                }}
                deleteVideo={() => props.deleteVideo(index)}
              />
            ),
          )
        : null}
    </>
  );
};

export default RegisterSoundPresenter;

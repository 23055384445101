import * as s from '../UpdateEduStyled';

interface SelectEduProps {
  option: {
    holder: string;
    edu: string;
    type: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
  eduList: any;
}

const SelectEdu = (props: SelectEduProps) => {
  return (
    <s.SelectArea>
      <s.SelectLabel>교육프로그램 선택</s.SelectLabel>
      <s.SelectDiv></s.SelectDiv>
      <s.SelectForm selectType={'edu'}>
        {props.eduList &&
          Object.entries(props.eduList).map(([key, value]) => {
            return (
              <s.SelectItem
                key={'edu' + key}
                active={props.eduList[key].menuCd === props.option.edu}
                selectType={'edu'}
                onClick={() => props.onChangeOption('edu', props.eduList[key].menuCd)}
              >
                {props.eduList[key].menuNm.split('(')[0]}
                {props.eduList[key].menuNm.split('(')[1] ? (
                  <s.SelectItemSpan>({props.eduList[key].menuNm.split('(')[1]}</s.SelectItemSpan>
                ) : null}
              </s.SelectItem>
            );
          })}
      </s.SelectForm>
    </s.SelectArea>
  );
};

export default SelectEdu;

import * as s from './FooterStyled';
import Swal from 'sweetalert2';

type FooterProps = {};

const FooterPresenter = (props: FooterProps) => {
  const notSupport = () => {
    Swal.fire('추후 지원할 예정입니다.');
  };

  return (
    <s.Container>
      <s.ContentArea>
        <s.FooterLogo>
          <s.FooterLogoImg src="/static/img/logo_black.png" />
        </s.FooterLogo>
        <s.FooterTextArea>
          <s.FooterBtnArea>
            <s.FooterBtn onClick={notSupport}>이용약관</s.FooterBtn>
            <s.FooterBtn style={{ cursor: 'default', marginLeft: '10px', marginRight: '10px' }}>
              |
            </s.FooterBtn>
            <s.FooterBtn onClick={notSupport}>개인정보 처리방침</s.FooterBtn>
            <s.FooterBtn style={{ cursor: 'default', marginLeft: '10px', marginRight: '10px' }}>
              |
            </s.FooterBtn>
            <s.FooterBtn onClick={notSupport}>고객센터</s.FooterBtn>
          </s.FooterBtnArea>
          <s.FooterText>
            업체명 : (주)엄지교육&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 대표자 :
            최말경&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 대표전화 :
            080-080-9781&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 사업자등록번호 :
            615-81-88622&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 통신판매업신고 : 2021-경남김해-0043
          </s.FooterText>
          <s.FooterText style={{ marginTop: '5px' }}>
            주소 : 경상남도 김해시 진례면 테크노밸리길 188-8
          </s.FooterText>
        </s.FooterTextArea>
      </s.ContentArea>
    </s.Container>
  );
};

FooterPresenter.defaultProps = {};

export default FooterPresenter;

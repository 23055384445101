import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import * as recoilUtil from '../../../../util/recoilitem';

import { useDropzone } from 'react-dropzone';

import Swal from 'sweetalert2';
import WeeklyPlanPresenter from './WeeklyPlanPresenter';

import { comnApi } from '../../../../api/api-comn';
import { planApi } from '../../../../api/api-plan';
import { makeLayout, clear, findItemById, addLayout } from '../../../../util/makeLayout';

// interface WeeklyPlanProps extends RouteComponentProps {};
type WeeklyPlanProps = {
  ageAuths: any;
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  yearList: number[];
};

const WeeklyPlanContainer = (props: WeeklyPlanProps) => {
  const token = useRecoilValue(recoilUtil.token);
  const [isLoading, setIsLoading] = useRecoilState(recoilUtil.isLoading);

  const { getRootProps, getInputProps, open, isDragAccept } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (file) => {
      setSubmitForm({
        ...submitForm,
        file,
      });
    },
  });

  const [submitForm, setSubmitForm] = useState<any>({
    planId: null,
    description: '',
    subject: '',
    goal: '',
    file: null,
  });

  const [isEditing, setIsEditing] = useState<boolean>(true);

  const [popupOn, setPopupOn] = useState<any>({
    x: null,
    y: null,
    activityList: [],
    description: '',
  });
  const [layout, setLayout] = useState<any>([]);

  const [topicList, setTopicList] = useState<any>({});
  const [subTopicList, setSubTopicList] = useState<any>({});
  const [colorList, setColorList] = useState<any>({});

  const [prevPlanModalOn, setPrevPlanModalOn] = useState<boolean>(false);
  const [prevPlanData, setPrevPlanData] = useState<any>({
    age: props.option.age,
    week: props.option.week,
    month: props.option.month,
    year: props.option.year,
  });

  const initializeLayout = () => {
    setLayout([]);
    if (props.option.age) {
      setLayout(makeLayout('weekly', props.option.week, props.option.age));
    } else {
      setLayout(makeLayout('weekly', props.option.week));
    }
  };

  const onInitializeLayout = () => {
    if (props.option.age) {
      setLayout(makeLayout('weekly', props.option.week, props.option.age));
    } else {
      setLayout(makeLayout('weekly', props.option.week));
    }
  };

  const addLayoutData = () => {
    setLayout([...addLayout(layout, 'monthly')]);
  };

  const onClear = () => {
    setLayout(clear(layout, 4, 'weekly'));
  };

  const onLayoutChange = async (changedLayout: any) => {
    setLayout(
      changedLayout.map((item: any) => {
        const findItem = findItemById(layout, item);
        return {
          ...item,
          activityList: findItem ? findItem.activityList : [],
          dataId: findItem ? findItem.dataId : null,
          description: findItem ? findItem.description : '',
        };
      }),
    );
  };

  const onPushActivityInLayout = async (
    activityList: any[],
    { x, y }: any,
    description?: string,
  ) => {
    const _layout = layout.map((item: any) => {
      return item.x === x && item.y === y
        ? {
            ...item,
            activityList: [...activityList],
            description: description,
          }
        : item;
    });
    setLayout(_layout);
  };

  const fetchData = async (planId?: any, isLoad?: boolean) => {
    try {
      //대주제 가져옴
      setIsLoading(true);
      await comnApi.getCd('TYPE10').then((res) => {
        if (res.data.rsltCd === '00') {
          let subjectList: any = {};
          res.data.ComnCd.subCdList.map(
            (item: { comnCd: string; comnCdNm: string; inggCd: string; viewOrder: number }) => {
              subjectList[item.comnCd] = item;
            },
          );
          setTopicList(subjectList);
        }
      });
      //소주제 가져옴
      await comnApi.getCd('TYPE0401').then((res) => {
        if (res.data.rsltCd === '00') {
          let subjectList: any = {};
          res.data.ComnCd.subCdList.map(
            (item: { comnCd: string; comnCdNm: string; inggCd: string; viewOrder: number }) => {
              subjectList[item.comnCd] = item;
            },
          );
          setSubTopicList(subjectList);
        }
      });
      //색 가져옴
      await comnApi.getCd('COL').then((res) => {
        if (res.data.rsltCd === '00') {
          let color: any = {};
          res.data.ComnCd.subCdList.map(
            (item: { comnCd: string; comnCdNm: string; inggCd: string; viewOrder: number }) => {
              color[item.comnCd] = item;
            },
          );
          setColorList(color);
        }
      });

      //이전 데이터 가져옴
      await planApi
        .getPlanWeekly(
          token,
          prevPlanData.age ? prevPlanData.age : '',
          prevPlanData.week,
          prevPlanData.month,
          prevPlanData.year,
        )
        .then((res) => {
          if (res.data.rsltCd === '00') {
            setSubmitForm(
              isLoad
                ? {
                    ...submitForm,
                    description: res.data.weeklyPlan.description,
                    goal: res.data.weeklyPlan.goal,
                    subject: res.data.weeklyPlan.subject,
                    file: res.data.weeklyPlan.file
                      ? [{ name: res.data.weeklyPlan.file.originalFileName }]
                      : null,
                  }
                : {
                    planId: res.data.weeklyPlan.planId,
                    description: res.data.weeklyPlan.description,
                    goal: res.data.weeklyPlan.goal,
                    subject: res.data.weeklyPlan.subject,
                    file: res.data.weeklyPlan.file
                      ? [{ name: res.data.weeklyPlan.file.originalFileName }]
                      : null,
                  },
            );
            setLayout(
              res.data.weeklyPlan.dataList.map((data: any) => {
                return {
                  ...data,
                  activityList: data.activityList.map((activity: any) => {
                    return {
                      color: activity.color ? activity.color : null,
                      typeCd: activity.type ? activity.type.comnCd : null,
                      typeCdNm: activity.type ? activity.type.comnCdNm : null,
                      planActivityId: activity.planActivity
                        ? activity.planActivity.activityId
                        : null,
                      weeklyActivityId: activity.weeklyActivityId
                        ? activity.weeklyActivityId
                        : null,
                    };
                  }),
                  i: String(data.dataId),
                  maxH: 23,
                };
              }),
            );
            setIsLoading(false);
          } else {
            setSubmitForm({
              planId: null,
              description: '',
              goal: '',
              subject: '',
              file: null,
            });
            if (props.option.age) {
              setLayout(makeLayout('weekly', 5, props.option.age));
            } else {
              setLayout(makeLayout('weekly', 5));
            }
            setIsLoading(false);
          }
        });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const uploadFile = () => {
    open();
  };

  const onChangeAge = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubmitForm({
      ...submitForm,
      age: e.target.value,
    });
  };

  const onChangeSubject = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubmitForm({
      ...submitForm,
      subject: e.target.value,
    });
  };

  const onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubmitForm({
      ...submitForm,
      description: e.target.value,
    });
  };

  const onChangeGoal = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubmitForm({
      ...submitForm,
      goal: e.target.value,
    });
  };

  const onGetLastData = async () => {
    setPrevPlanModalOn(!prevPlanModalOn);
  };

  const onSubmit = async () => {
    const { year, age, month, week } = props.option;
    let Data: FormData = new FormData();
    console.log(submitForm.planId);

    Data.append('year', String(year));
    Data.append('age', String(age));
    Data.append('month', String(month));
    Data.append('week', String(week));
    Data.append('weekLength', '7');

    if (submitForm.planId) {
      Data.append('planId', String(submitForm.planId));
    }

    Data.append('description', submitForm.description);
    Data.append('subject', submitForm.subject);
    Data.append('goal', submitForm.goal);
    if (submitForm.file && submitForm.file[0] && submitForm.file[0] instanceof File) {
      Data.append('file.file', submitForm.file[0]);
    }

    layout.forEach((data: any, indexFirst: number) => {
      Data.append(`dataList[${indexFirst}].x`, data.x);
      Data.append(`dataList[${indexFirst}].y`, data.y);
      Data.append(`dataList[${indexFirst}].w`, data.w);
      Data.append(`dataList[${indexFirst}].h`, data.h);
      data.description && Data.append(`dataList[${indexFirst}].description`, data.description);
      data.activityList.forEach((activity: any, indexSecond: number) => {
        if (activity.weeklyActivityId) {
          Data.append(
            `dataList[${indexFirst}].activityList[${indexSecond}].weeklyActivityId`,
            activity.weeklyActivityId,
          );
        }
        if (activity.color) {
          Data.append(
            `dataList[${indexFirst}].activityList[${indexSecond}].colorCd`,
            activity.color.comnCd,
          );
        }
        if (activity.typeCd) {
          Data.append(
            `dataList[${indexFirst}].activityList[${indexSecond}].typeCd`,
            activity.typeCd,
          );
        }
        if (activity.planActivityId) {
          Data.append(
            `dataList[${indexFirst}].activityList[${indexSecond}].planActivityId`,
            activity.planActivityId,
          );
        }
      });
    });
    try {
      if (isEditing) {
        Swal.fire('먼저 완료 버튼을 눌러주세요.');
        return;
      } else {
        setIsLoading(true);
        const result = await planApi.createPlanWeekly(token, Data);
        setIsLoading(false);
        if (result.data.rsltCd === '00') {
          Swal.fire('주간 계획이 생성되었습니다.');
          setPrevPlanData({
            age: props.option.age,
            week: props.option.week,
            month: props.option.month,
            year: props.option.year,
          });
          return;
        }
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      Swal.fire('주간 계획 생성을 실패했습니다.');
    }
  };

  useEffect(() => {
    console.log(submitForm.planId);
    if (prevPlanData.isLoad) {
      fetchData(submitForm.planId, true);
    } else {
      fetchData(submitForm.planId);
    }
    
  }, [prevPlanData]);

  useEffect(() => {
    setPrevPlanData({
      age: props.option.age,
      week: props.option.week,
      month: props.option.month,
      year: props.option.year,
      isLoad: false
    });
  }, [props.option]);

  return (
    <WeeklyPlanPresenter
      option={props.option}
      ageAuths={props.ageAuths}
      uploadFile={uploadFile}
      submitForm={submitForm}
      onChangeAge={onChangeAge}
      onChangeSubject={onChangeSubject}
      onChangeDescription={onChangeDescription}
      onChangeGoal={onChangeGoal}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      layout={layout}
      onLayoutChange={onLayoutChange}
      onInitializeLayout={onInitializeLayout}
      onClear={onClear}
      addLayoutData={addLayoutData}
      onGetLastData={onGetLastData}
      onSubmit={onSubmit}
      popupOn={popupOn}
      setPopupOn={setPopupOn}
      topicList={topicList}
      subTopicList={subTopicList}
      colorList={colorList}
      onPushActivityInLayout={onPushActivityInLayout}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      isDragActive={isDragAccept}
      yearList={props.yearList}
      prevPlanData={prevPlanData}
      setPrevPlanData={setPrevPlanData}
      prevPlanModalOn={prevPlanModalOn}
      setPrevPlanModalOn={setPrevPlanModalOn}
      initializeLayout={initializeLayout}
    />
  );
};

export default WeeklyPlanContainer;

import React from 'react';
import * as s from './DailyPlanStyled';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/pagination/pagination.scss';
import * as util from '../../../../util/util';
import NotFound from '../../../../components/notFound';

import { saveAs } from 'file-saver';
// install Swiper modules

type DailyPlanProps = {
  notSupport: () => void;
  option: {
    age: any;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  ageAuths: any;
  icons: any;
  dailyPlan: any;
  setPlanId: any;
  createPlanFile: (planId: string) => Promise<void>;
  color: string;
  eduTitle: string;
};

const DailyPlanPresenter = (props: DailyPlanProps) => {
  if (!props.dailyPlan) {
    return (
      <s.Container>
        <NotFound />
      </s.Container>
    );
  }
  return (
    <s.Container>
      <s.PlanningTitleArea color={props.color}>
        <s.PlanningTitle>
          <s.PlanningTitleSpan color={props.color}>{props.option.year}년</s.PlanningTitleSpan> 만{' '}
          <s.PlanningTitleSpan color={props.color}>{props.ageAuths[props.option.age].comnCdNm}</s.PlanningTitleSpan>{' '}
          <s.PlanningTitleSpan color={props.color}>
            {props.option.month + '월 ' + props.option.week + '주차 ' + props.option.day + '일'}
          </s.PlanningTitleSpan>{' '}
          일일 교육계획안
        </s.PlanningTitle>
        {props.dailyPlan.isHoliday === 'T' ? null : (
          <s.PlanningDailyDownloadBtn color={props.color}
            onClick={() => {
              if (!props.dailyPlan.planId) {
                util.makeMsg('권한이 부족합니다.', 'error');
              } else {
                if (props.dailyPlan.file) {
                  saveAs(props.dailyPlan.file.fileUrl, props.dailyPlan.file.originalFileName);
                } else {
                  props.createPlanFile(props.dailyPlan.planId);
                }
              }
            }}
          >
            <s.DailyDownloadBtnIcon src="/static/img/download_button.png" />
            <s.PlanningDailyDownloadText>일일 교육계획안 다운로드</s.PlanningDailyDownloadText>
          </s.PlanningDailyDownloadBtn>
        )}
      </s.PlanningTitleArea>
      {props.dailyPlan.isHoliday === 'T' ? (
        <s.Holiday>{props.dailyPlan.subject}</s.Holiday>
      ) : (
        <>
          <s.TopContentsArea color={props.color}>

            <s.TopContentsRow style={{borderTop: '0px'}}>
              <s.TopContentHorizontalSection style={{width: '50%'}}>
                <s.TopContentHeaderBox>연령</s.TopContentHeaderBox>
                <s.TopContentTextArea>만 {props.ageAuths[props.option.age].comnCdNm}</s.TopContentTextArea>
              </s.TopContentHorizontalSection>
              <s.TopContentHorizontalSection style={{width: '50%'}}>
                <s.TopContentHeaderBox>기간</s.TopContentHeaderBox>
                <s.TopContentTextArea>{props.option.year}년{' '}
                  {props.option.month}월 {props.option.week}주 {props.option.day} 일</s.TopContentTextArea>
              </s.TopContentHorizontalSection>
            </s.TopContentsRow>
            <s.TopContentsRow>
              <s.TopContentHorizontalSection style={{width: '50%'}}>
                <s.TopContentHeaderBox>생활주제</s.TopContentHeaderBox>
                <s.TopContentTextArea>{props.dailyPlan.lifeSubject}</s.TopContentTextArea>
              </s.TopContentHorizontalSection>
              <s.TopContentHorizontalSection style={{width: '50%'}}>
                <s.TopContentHeaderBox>주제</s.TopContentHeaderBox>
                <s.TopContentTextArea>{props.dailyPlan.subject}</s.TopContentTextArea>
              </s.TopContentHorizontalSection>
            </s.TopContentsRow>
            <s.TopContentsRow>
              <s.TopContentHorizontalSection>
                <s.TopContentHeaderBox>교사의 기대</s.TopContentHeaderBox>
                <s.TopContentTextArea>
                {
                  props.dailyPlan.expectations ?
                  props.dailyPlan.expectations.split('\n').map((row: any) => {
                    return <s.TopContentTextArea>{row}</s.TopContentTextArea>
                  })
                  : null
                }
                </s.TopContentTextArea>
              </s.TopContentHorizontalSection>
          </s.TopContentsRow>
          </s.TopContentsArea>
          <s.TableIconInfoArea>
            {Object.keys(props.icons).map((key: string) => (
              <s.PlanningDiv key={'icon' + key}>
                <s.TableIconInfoImg src={`/static/img/${key}.png`} />
                <s.TableIconInfoText>{props.icons[key].comnCdNm}</s.TableIconInfoText>
              </s.PlanningDiv>
            ))}
          </s.TableIconInfoArea>
          <s.PlanAreaWrapper>
          {props.dailyPlan.timeList
            .filter((item: any) => item.endTime || item.startTime)
            .map((item: any, index: number) => (
              <s.PlanArea key={'time' + index} colorCd={item.color.comnCdNm.split('/')[0]}>
                <s.PlanTime>
                  {item.startTime}
                  {item.endTime !== null ? (
                    <s.PlanTimeSub>{'~ ' + item.endTime}</s.PlanTimeSub>
                  ) : null}
                </s.PlanTime>
                <s.DivIcon></s.DivIcon>
                <s.PlanTitle colorCd={item.color.comnCdNm.split('/')[0]}>
                  {item.title ? item.title.comnCdNm : item.mainTitle}
                  {item.subTitle !== null ? <s.PlanSubTitle>{item.subTitle}</s.PlanSubTitle> : null}
                </s.PlanTitle>
                <s.DivIcon></s.DivIcon>
                {item.activityList.length !== 0 &&
                item.activityList[0].type.comnCd === 'TYPE0601' ? (
                  <s.PlanTextArea>
                    {item.activityList.map((actItem: any, index2: number) => (
                      <s.PlanText key={'actvity' + index2}>{actItem.description}</s.PlanText>
                    ))}
                  </s.PlanTextArea>
                ) : item.activityList.length !== 0 &&
                  item.activityList[0].type.comnCd === 'TYPE0602' ? (
                  <s.PlanTextArea>
                    {item.activityList.map((actItem: any, index2: number) => 
                    { 
                      console.log(actItem);
                      let containing = false;

                      if (actItem.activity && actItem.activity.materialList) {
                        actItem.activity.materialList.map((item: any, idx: number) => {
                          if (item.program && item.program.eduProgram) {
                            item.program.eduProgram.map((eduItem: any, eduIdx: number) => {
                              if (eduItem.subMenu.menuCd === props.eduTitle) {
                                containing = true
                              }
                            })
                          }
                        });
                      }
                      return (
                      <s.PlanData
                        key={'activityData' + index2}
                        onClick={() => {
                            if (containing) {
                              props.setPlanId(actItem.activity.activityId)
                            }
                          }
                        }
                        color={containing ? props.color : '#ABABAB'}
                        cursor={containing ? 'pointer' : 'default'}
                      >
                        <s.PlanDataTop>
                          <s.PlanDataTopText>{actItem.activity.domain.comnCdNm}</s.PlanDataTopText>
                          {actItem.activity.materialList.map(
                            (materialItem: any, index3: number) => {
                              console.log(materialItem);
                              if (materialItem.program && 
                                materialItem.program.eduProgram && materialItem.program.eduProgram.length > 0 && 
                                materialItem.program.eduProgram[0].subMenu.menuCd === props.eduTitle) { 
                                return(
                              <s.PlanDataTopImg
                                key={'material' + index3}
                                src={`/static/img/${materialItem.icon.comnCd}.jpg`}
                              />
                            )}},
                          )}
                        </s.PlanDataTop>
                        <s.PlanDataBottom>{actItem.activity.activityNm}</s.PlanDataBottom>
                      </s.PlanData>
                    )})}
                  </s.PlanTextArea>
                ) : null}
              </s.PlanArea>
            ))}

          </s.PlanAreaWrapper>
        </>
      )}
    </s.Container>
  );
};

DailyPlanPresenter.defaultProps = {};

export default DailyPlanPresenter;

import React, { Dispatch, SetStateAction } from 'react';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { RouteComponentProps } from 'react-router-dom';

import BeatLoader from 'react-spinners/BeatLoader';
import * as s from './RegisterGuideBookStyled';

interface RegisterGuideBookProps extends RouteComponentProps {
  option: {
    age: string | null;
    holder: string;
    topMenu: string;
    subMenu: string;
    type: string;
    field: string;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      holder: string;
      topMenu: string;
      subMenu: string;
      type: string;
      field: string;
    }>
  >;
  ageAuths: any;
  holderList: any;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  open: () => void;
  acceptedFiles: File[];
  isDragActive: boolean;
  isModifying: boolean;
  setIsModifying: React.Dispatch<React.SetStateAction<boolean>>;
  submitFileList: File[];
  fieldList: {
    menuCd: string;
    menuNm: string;
    viewOrder: number;
  }[];
  descriptionList: { fileName: string; fileIndex: number; text: string }[];
  setDescriptionList: React.Dispatch<
    React.SetStateAction<{ fileName: string; fileIndex: number; text: string }[]>
  >;
  submit: () => Promise<void>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
}

const RegisterGuideBookPresenter = (props: RegisterGuideBookProps) => {
  return (
    <s.Container>
      <s.TitleArea>
        <s.Title>
          만{' '}
          <s.TitleSpan>
            {!props.ageAuths || props.ageAuths.length === 0
              ? null
              : props.option.age
              ? props.ageAuths[props.option.age].comnCdNm
              : null}
          </s.TitleSpan>{' '}
          <s.TitleSpan>
            {!props.holderList || props.holderList.length === 0
              ? null
              : props.holderList[props.option.holder].comnCdNm}
          </s.TitleSpan>{' '}
          지침서
        </s.Title>
      </s.TitleArea>

      <s.UploadArea>
        <s.UploadAreaHeader>
          <s.UploadAreaHeaderLeft>업로드</s.UploadAreaHeaderLeft>
          <s.UploadAreaHeaderRight>
            <s.UploadAreaHeaderRightIcon src="/static/img/warning.png" />
            <s.UploadAreaHeaderRightText>ZIP파일은 업로드 불가입니다.</s.UploadAreaHeaderRightText>
          </s.UploadAreaHeaderRight>
        </s.UploadAreaHeader>
        <s.UploadContentArea>
          <s.UploadContentAreaHeader>
            <s.UploadContentAreaHeaderLeft>
              <s.UploadContentAreaHeaderIcon
                src={!props.isModifying ? '/static/img/pen.png' : '/static/img/red_pen.png'}
              />
              <s.UploadContentAreaHeaderLeftText
                onClick={() => (!props.isModifying ? props.setIsModifying(true) : null)}
                isColor={!props.isModifying}
              >
                수정
              </s.UploadContentAreaHeaderLeftText>
            </s.UploadContentAreaHeaderLeft>
            <s.UploadContentAreaHeaderRight>
              {false ? (
                <BeatLoader size={8} color={'#acacac'} />
              ) : (
                <>
                  <s.UploadContentAreaHeaderIcon
                    src={
                      props.isModifying ? '/static/img/check.png' : '/static/img/green_check.png'
                    }
                  />
                  <s.UploadContentAreaHeaderRightText
                    onClick={() => (props.isModifying ? props.setIsModifying(false) : null)}
                    isColor={props.isModifying}
                  >
                    완료
                  </s.UploadContentAreaHeaderRightText>
                </>
              )}
            </s.UploadContentAreaHeaderRight>
          </s.UploadContentAreaHeader>
          <s.UploadProgramArea>
            <s.UploadProgramAreaHeader>
              <s.UploadProgramAreaSelectHeader>놀이 선택</s.UploadProgramAreaSelectHeader>
              <s.UploadProgramAreaSelectDv />
              <s.UploadProgramAreaHeaderSelect>
                {props.fieldList.map((type: any) => (
                  <>
                    <s.UploadProgramAreaSelectType
                      id={type.menuCd}
                      value={type.menuCd}
                      defaultChecked={props.option.field === type.menuCd}
                      onChange={(e) =>
                        props.option.field === e.target.value
                          ? props.setOption({ ...props.option, field: '' })
                          : props.setOption({ ...props.option, field: e.target.value })
                      }
                      type={'checkbox'}
                      disabled={!props.isModifying}
                    />
                    <s.UploadProgramAreaSelectTypeLabel htmlFor={type.menuCd}>
                      {props.option.field === type.menuCd ? (
                        <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                      ) : null}
                    </s.UploadProgramAreaSelectTypeLabel>
                    <s.UploadProgramAreaSelectText htmlFor={type.menuCd}>
                      {type.menuNm}
                    </s.UploadProgramAreaSelectText>
                  </>
                ))}
              </s.UploadProgramAreaHeaderSelect>
              <s.UploadProgramAreaHeaderSelect>
                <s.UploadProgramAreaHeaderLabel>제목</s.UploadProgramAreaHeaderLabel>
                <s.UploadProgramAreaHeaderInput
                  value={props.title}
                  onChange={(e) => props.setTitle(e.target.value)}
                  placeholder={'텍스트를 입력해주세요.'}
                  disabled={!props.isModifying}
                />
              </s.UploadProgramAreaHeaderSelect>
            </s.UploadProgramAreaHeader>
            <s.UploadProgramAreaFileUploadArea
              isDrag={props.isDragActive}
              {...props.getRootProps({ className: 'dropzone' })}
            >
              <s.UploadProgramAreaFileUploadAreaInput {...props.getInputProps()} />
              {props.submitFileList.length > 0 ? (
                <s.UploadProgramAreaFileUploadAreaButtonIcon
                  onClick={props.open}
                  src="/static/img/pdf_icon_1.png"
                />
              ) : null}
              <s.UploadProgramAreaFileUploadAreaText>
                {props.submitFileList.length > 0
                  ? props.submitFileList.length === 1
                    ? props.submitFileList[0].name
                    : props.submitFileList[0].name +
                      ' 외 ' +
                      (props.submitFileList.length - 1) +
                      '개'
                  : '파일을 선택해주세요.'}
              </s.UploadProgramAreaFileUploadAreaText>
              <s.UploadProgramAreaFileUploadAreaButtonIcon
                onClick={props.open}
                src={
                  props.submitFileList.length > 0
                    ? '/static/img/file_exist.png'
                    : '/static/img/selectFile.png'
                }
              />
            </s.UploadProgramAreaFileUploadArea>
            <s.UploadProgramAreaFooter>
              {props.descriptionList.map(
                (item: { fileName: string; fileIndex: number; text: string }, index: number) => (
                  <s.UploadProgramAreaFooterTextArea>
                    <s.TextAreaInfo>
                      <s.TextAreaIntoFileName>{item.fileName}</s.TextAreaIntoFileName>
                      <s.TextAreaIntoText>프로그램 소개 내용</s.TextAreaIntoText>
                      <s.TextAreaInfoNotice>(최대 0000글자만 작성 가능)</s.TextAreaInfoNotice>
                    </s.TextAreaInfo>
                    <s.TextAreaTextInputWrapper>
                      <s.TextAreaTextInput
                        placeholder="텍스트를 입력해주세요."
                        value={item.text}
                        onChange={(e) => {
                          let tempList = [...props.descriptionList];
                          tempList[index].text = e.target.value;
                          props.setDescriptionList(tempList);
                        }}
                        disabled={!props.isModifying}
                      />
                    </s.TextAreaTextInputWrapper>
                  </s.UploadProgramAreaFooterTextArea>
                ),
              )}
            </s.UploadProgramAreaFooter>
          </s.UploadProgramArea>
          <s.ButtonArea>
            <s.SubmitButton onClick={props.submit}>
              <s.SubmitButtonText>업로드</s.SubmitButtonText>
            </s.SubmitButton>
          </s.ButtonArea>
        </s.UploadContentArea>
      </s.UploadArea>
    </s.Container>
  );
};

export default RegisterGuideBookPresenter;

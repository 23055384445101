import React, { useState, useEffect } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import OrderPresenter from './OrderPresenter';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Floating from '../../../components/floating';
import { useRecoilValue, useRecoilState } from 'recoil';

import * as recoilItem from '../../../util/recoilitem';
import * as util from '../../../util/util';
import DaumPostcode from 'react-daum-postcode';
import { iamport_merchant_uid } from '../../../config/config';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { shoppingApi } from '../../../api/api-shopping';

declare const window: Window &
  typeof globalThis & {
    IMP: any;
  };

interface OrderProps extends RouteComponentProps {}

const OrderContainer = (props: OrderProps) => {
  const [token, setToken] = useRecoilState(recoilItem.token);
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);
  const [orderItems, setOrderItems] = useState<any>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const [address, setAddress] = useState(''); // 주소
  const [addressDetail, setAddressDetail] = useState(''); // 상세주소
  const [isOpenPost, setIsOpenPost] = useState(false);
  const [receiverNm, setReceiverNm] = useState<string>('');
  const [receiverTelno, setReceiverTelno] = useState<string>('');
  const [memo, setMemo] = useState<string>('');
  const [payment, setPayment] = useState<number>(0);

  useEffect(() => {
    console.log(userInfo);
    let state: any = props.history.location.state;
    setOrderItems(state.passItems);
    let totalPrice = 0;
    state.passItems.forEach((item: any) => {
      totalPrice += item.totalPrice;
    });

    setTotalPrice(totalPrice);
  }, []);

  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };

  const onCompletePost = (data: any) => {
    let fullAddr = data.address;
    let extraAddr = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddr += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
    }

    setAddress(data.zonecode);
    setAddressDetail(fullAddr);
    setIsOpenPost(false);
  };

  const validateForm = async () => {
    let result = true;

    if (!receiverNm || receiverNm === '') {
      util.makeMsg('수령인 이름은 필수입니다.', 'error');
      result = false;
      return result;
    }

    if (!receiverTelno || receiverTelno === '') {
      util.makeMsg('수령인 연락처는 필수입니다.', 'error');
      result = false;
      return result;
    }

    if (!address || address === '' || !addressDetail || addressDetail === '') {
      util.makeMsg('수령주소는 필수입니다.', 'error');
      result = false;
      return result;
    }

    return result;
  };

  const doPay = async () => {
    try {
      if (!validateForm()) {
        return;
      }

      if (!receiverNm) {
        util.makeMsg('수령인을 적어주세요', 'error');
        setIsLoading(false);
        return;
      }

      if (!receiverTelno) {
        util.makeMsg('수령인 전화번호를 적어주세요', 'error');
        setIsLoading(false);
        return;
      }

      if (!address || !addressDetail) {
        util.makeMsg('주소를 적어주세요.', 'error');
        setIsLoading(false);
        return;
      }

      console.log(payment);
      setIsLoading(true);
      let merchant_uid = uuid();

      const IMP = window.IMP;
      IMP.init(iamport_merchant_uid);
      IMP.request_pay(
        {
          // pg: 'html5_inicis.MOIshina96', // Live 반영 시 발급받아 수정
          pg: 'html5_inicis',
          pay_method: payment === 0 ? 'card' : 'vbank',
          merchant_uid: merchant_uid,
          name:
            '[' +
            moment().format('YYYY-MM-DD') +
            ']' +
            orderItems[0].productNm +
            '외 ' +
            (orderItems.length - 1) +
            '건',
          amount: totalPrice < 50000 ? totalPrice + 3000 : totalPrice,
          buyer_email: userInfo.userEmail,
          buyer_name: userInfo.userNm,
          buyer_tel: userInfo.userMobno,
        },
        async (rsp: any) => {
          console.log(rsp);
          if (rsp.success) {
            let param: FormData = new FormData();
            param.append('userId', userInfo.userKey);
            param.append('receiverNm', receiverNm);
            param.append('receiverTelno', receiverTelno);
            param.append('receiverZipcode', address);
            param.append('receiverAddress', addressDetail);
            param.append('memo', memo);
            param.append('paymentDv', payment === 0 ? 'C' : 'V');
            if (payment === 1) {
              param.append('vbankHolder', rsp.vbank_holder);
              param.append('vbankName', rsp.vbank_name);
              param.append('vbankNum', rsp.vbank_num);
            }
            for (let i = 0; i < orderItems.length; i++) {
              let item = orderItems[i];
              param.append(`productList[${i}].productId`, item.productId);
              param.append(`productList[${i}].count`, item.count);
            }

            let res = await shoppingApi.orderProducts(token, param);

            if (res.data.rsltCd === '00') {
              util.makeMsg(
                payment === 0
                  ? '주문이 완료되었습니다.'
                  : `주문이 완료되었습니다.\n아래 계좌로 입금해주세요.\n\n예금주:${rsp.vbank_holder}\n은행:${rsp.vbank_name}\n계좌번호:${rsp.vbank_num}`,
                'success',
              );
              setIsLoading(false);
              props.history.push('/mypage');
            } else {
              util.makeMsg('주문에 실패하였습니다.\n관리자에게 문의해주세요.', 'error');
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
            util.makeMsg(rsp.error_msg, 'error');
          }
        },
      );
    } catch (e) {
      console.log(e);
      util.makeMsg('데이터 처리에 오류가 발생하였습니다.', 'error');
      setIsLoading(false);
    }
  };

  return (
    <>
      {isOpenPost ? (
        <DaumPostcode
          style={{ position: 'absolute', width: '100vw', height: '100vh', zIndex: '99999' }}
          autoClose
          onComplete={onCompletePost}
        />
      ) : null}
      <Header {...props} />
      <Floating {...props} />
      <OrderPresenter
        {...props}
        userInfo={userInfo}
        orderItems={orderItems}
        totalPrice={totalPrice}
        address={address}
        addressDetail={addressDetail}
        setAddressDetail={setAddressDetail}
        onChangeOpenPost={onChangeOpenPost}
        receiverNm={receiverNm}
        setReceiverNm={setReceiverNm}
        receiverTelno={receiverTelno}
        setReceiverTelno={setReceiverTelno}
        memo={memo}
        setMemo={setMemo}
        payment={payment}
        setPayment={setPayment}
        doPay={doPay}
      />
      <Footer {...props} />
    </>
  );
};

export default OrderContainer;

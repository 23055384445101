import React, { useState, useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import OrderListPresenter from './OrderListPresenter';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import * as recoilItem from '../../../util/recoilitem';
import queryString from 'query-string';
import * as util from '../../../util/util';
import { comnApi } from '../../../api/api-comn';
import { shoppingApi } from '../../../api/api-shopping';
import DaumPostcode from 'react-daum-postcode';

interface OrderListProps extends RouteComponentProps {}

const OrderListContainer = (props: OrderListProps) => {
  const token = useRecoilValue(recoilItem.token);
  const [orderList, setOrderList] = useState<any[]>([]);
  const [openIdx, setOpenIdx] = useState<number>(-1);

  useEffect(() => {
    getOrderList();
  }, []);

  const getOrderList = async () => {
    let res = await shoppingApi.getOrderList(token, new FormData());
    console.log(res);
    if (res.data.rsltCd === '00') {
      // console.log(res.data.orderList);
      setOrderList(res.data.orderList);
    }
  };

  return (
    <>
      <Header {...props} />
      <OrderListPresenter
        orderList={orderList}
        openIdx={openIdx}
        setOpenIdx={setOpenIdx}
      ></OrderListPresenter>
      <Footer {...props} />
    </>
  );
};

export default OrderListContainer;

import React from 'react';

import RGL from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import * as s from './MonthlyPlanStyled';
import SearchModal from '../searchModal';
import PrevPlanModal from '../prevPlanModal';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';

const warning = '/static/img/warning.png';
const pen = '/static/img/pen.png';
const check = '/static/img/check.png';
const selectFile = '/static/img/selectFile.png';

const GridLayout = RGL.WidthProvider(RGL);

interface MonthlyPlanProps {
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  ageAuths: any;
  weekLength: number;
  weekLengthEditable: boolean;

  uploadFile: () => void;

  submitForm: {
    planId: number | null;
    description: string;
    subject: string;
    expectations: string;
    file: File | any;
  };
  onChangeAge: React.ChangeEventHandler<HTMLTextAreaElement>;
  onChageWeekLength: React.ChangeEventHandler<HTMLInputElement>;
  onChangeSubject: React.ChangeEventHandler<HTMLTextAreaElement>;
  onChangeDescription: React.ChangeEventHandler<HTMLTextAreaElement>;
  onChangeExpectations: React.ChangeEventHandler<HTMLTextAreaElement>;

  isEditing: boolean;
  setIsEditing: (arg0: any) => void;

  layout: [];
  onLayoutChange: (arg0: any) => void;
  onInitializeLayout: () => void;
  addLayoutData: () => void;
  onClear: () => void;
  onGetLastData: () => void;
  onSubmit: () => void;
  popupOn: any;
  setPopupOn: (arg0: any) => void;

  topicList: any;
  subTopicList: any;
  colorList: any;
  onPushActivityInLayout: (arg0: any[], arg1: any) => void;

  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  isDragActive: boolean;

  yearList: number[];
  prevPlanData: any;
  setPrevPlanData: (arg0: any) => void;
  prevPlanModalOn: boolean;
  setPrevPlanModalOn: (arg0: boolean) => void;
  initializeLayout: () => void;
}

const MonthlyPlanPresenter = (props: MonthlyPlanProps) => {
  return (
    <>
      <s.Container>
        <s.PlanCreateHeaderWrapper>
          <s.PlanCreateHeader>
            <s.PlanCreateHeaderText color="#0087ed">{props.option.year}년 </s.PlanCreateHeaderText>
            <s.PlanCreateHeaderText color="#4d4d4d"> 만 </s.PlanCreateHeaderText>
            <s.PlanCreateHeaderText color="#0087ed">
              {props.option.age ? props.ageAuths[props.option.age].comnCdNm : ''}
            </s.PlanCreateHeaderText>
            <s.PlanCreateHeaderText color="#0087ed">
              {' '}
              {props.option.month}월{' '}
            </s.PlanCreateHeaderText>
            <s.PlanCreateHeaderText color="#4d4d4d">월간 교육 계획안 </s.PlanCreateHeaderText>
          </s.PlanCreateHeader>
        </s.PlanCreateHeaderWrapper>
        {/* <s.PlanFileUploadWrapper>
          <s.UploadHead>
            <s.UploadHeadTitle>월간교육계획안 업로드</s.UploadHeadTitle>
            <s.UploadHeadInfo>
              <s.UploadHeadInfoImg src={warning} />
              <s.UploadHeadInfoText>ZIP파일 · 단일 jpg는 업로드 불가입니다.</s.UploadHeadInfoText>
            </s.UploadHeadInfo>
          </s.UploadHead>
          <s.FileUploadArea {...props.getRootProps()} isDragActive={props.isDragActive}>
            <s.FileUploadAreaInput {...props.getInputProps()} />
            {props.submitForm.file ? (
              <s.FileUploadAreaButtonIcon
                onClick={props.uploadFile}
                src="/static/img/pdf_icon_1.png"
              />
            ) : null}
            <s.FileUploadAreaText>
              {props.submitForm.file ? props.submitForm.file[0].name : '파일을 업로드 해주세요.'}
            </s.FileUploadAreaText>
            <s.FileUploadAreaButtonIcon
              onClick={props.uploadFile}
              src={
                props.submitForm.file ? '/static/img/file_exist.png' : '/static/img/selectFile.png'
              }
            />
          </s.FileUploadArea>
        </s.PlanFileUploadWrapper> */}
        <s.PlanWriteWrapper>
          <s.UploadHead>
            <s.UploadHeadTitle>월간교육계획안 작성</s.UploadHeadTitle>
            <s.UploadHeadInfo>
              <s.UploadHeadInfoImg src={warning} />
              <s.UploadHeadInfoText>ZIP파일 · 단일 jpg는 업로드 불가입니다.</s.UploadHeadInfoText>
            </s.UploadHeadInfo>
          </s.UploadHead>
          <s.UploadBody isDragActive={false}>
            <s.WriteButtonWrapper>
              <s.UploadContentAreaHeader>
                <s.UploadContentAreaHeaderLeft>
                  <s.UploadContentAreaHeaderIcon
                    src={!props.isEditing ? '/static/img/pen.png' : '/static/img/red_pen.png'}
                  />
                  <s.UploadContentAreaHeaderLeftText
                    onClick={() => (!props.isEditing ? props.setIsEditing(true) : null)}
                    isColor={!props.isEditing}
                  >
                    수정
                  </s.UploadContentAreaHeaderLeftText>
                </s.UploadContentAreaHeaderLeft>
                <s.UploadContentAreaHeaderRight>
                  <s.UploadContentAreaHeaderIcon
                    src={props.isEditing ? '/static/img/check.png' : '/static/img/green_check.png'}
                  />
                  <s.UploadContentAreaHeaderRightText
                    onClick={() => (props.isEditing ? props.setIsEditing(false) : null)}
                    isColor={props.isEditing}
                  >
                    완료
                  </s.UploadContentAreaHeaderRightText>
                </s.UploadContentAreaHeaderRight>
              </s.UploadContentAreaHeader>
            </s.WriteButtonWrapper>
            <s.WritingBoardWrapper>
              <s.WritingBoardContent>
                <s.WritingBoardCheckboxBody style={{ flex: 3, justifyContent: 'flex-end' }}>
                  <s.WritingBoardCheckboxWrapper>
                    <s.WritingBoardCheckboxText style={{ fontWeight: 800, fontSize: '15' }}>
                      주차 선택
                    </s.WritingBoardCheckboxText>
                  </s.WritingBoardCheckboxWrapper>
                </s.WritingBoardCheckboxBody>
                <s.WritingBoardCheckboxBody>
                  <s.WritingBoardCheckboxWrapper>
                    <s.SelectWeek
                      disabled={!props.isEditing || !props.weekLengthEditable}
                      id={`weekselect-1`}
                      value={'3'}
                      defaultChecked={props.weekLength === 3}
                      onChange={props.onChageWeekLength}
                      type={'checkbox'}
                    />
                    <s.SelectWeekLabel htmlFor={`weekselect-1`}>
                      {props.weekLength === 3 ? (
                        <s.SelectWeekLabelIcon src="/static/img/check.png" />
                      ) : null}
                    </s.SelectWeekLabel>
                    <s.SelectWeekLabelText htmlFor={`weekselect-1`}>1~3주</s.SelectWeekLabelText>
                    <s.SelectWeek
                      disabled={!props.isEditing || !props.weekLengthEditable}
                      id={`weekselect-2`}
                      value={4}
                      defaultChecked={props.weekLength === 4}
                      onChange={props.onChageWeekLength}
                      type={'checkbox'}
                    />
                    <s.SelectWeekLabel htmlFor={`weekselect-2`}>
                      {props.weekLength === 4 ? (
                        <s.SelectWeekLabelIcon src="/static/img/check.png" />
                      ) : null}
                    </s.SelectWeekLabel>
                    <s.SelectWeekLabelText htmlFor={`weekselect-2`}>1~4주</s.SelectWeekLabelText>
                    <s.SelectWeek
                      disabled={!props.isEditing || !props.weekLengthEditable}
                      id={`weekselect-3`}
                      value={5}
                      defaultChecked={props.weekLength === 5}
                      onChange={props.onChageWeekLength}
                      type={'checkbox'}
                    />
                    <s.SelectWeekLabel htmlFor={`weekselect-3`}>
                      {props.weekLength === 5 ? (
                        <s.SelectWeekLabelIcon src="/static/img/check.png" />
                      ) : null}
                    </s.SelectWeekLabel>
                    <s.SelectWeekLabelText htmlFor={`weekselect-3`}>1~5주</s.SelectWeekLabelText>
                  </s.WritingBoardCheckboxWrapper>
                </s.WritingBoardCheckboxBody>
              </s.WritingBoardContent>
              <s.WritingBoardContent>
                <s.WritingBoardInfo style={{ height: '50px', justifyContent: 'center' }}>
                  <s.WritingBoardIntoText>생활주제</s.WritingBoardIntoText>
                </s.WritingBoardInfo>
                <s.WritingBoardTextInputWrapper>
                  <s.WritingBoardTextInput
                    placeholder="텍스트를 입력해주세요."
                    style={{ height: '40px' }}
                    disabled={!props.isEditing}
                    value={props.submitForm.subject}
                    onChange={props.onChangeSubject}
                  />
                </s.WritingBoardTextInputWrapper>
              </s.WritingBoardContent>
              <s.WritingBoardContent>
                <s.WritingBoardInfo>
                  <s.WritingBoardIntoText>주제 선정이유</s.WritingBoardIntoText>
                  <s.WritingBoardInfoNotice>(최대 0000글자만 작성 가능)</s.WritingBoardInfoNotice>
                </s.WritingBoardInfo>
                <s.WritingBoardTextInputWrapper>
                  <s.WritingBoardTextInput
                    placeholder="텍스트를 입력해주세요."
                    disabled={!props.isEditing}
                    value={props.submitForm.description}
                    onChange={props.onChangeDescription}
                  />
                </s.WritingBoardTextInputWrapper>
              </s.WritingBoardContent>
              <s.WritingBoardContent>
                <s.WritingBoardInfo>
                  <s.WritingBoardIntoText>교사의 기대</s.WritingBoardIntoText>
                  <s.WritingBoardInfoNotice>(최대 0000글자만 작성 가능)</s.WritingBoardInfoNotice>
                </s.WritingBoardInfo>
                <s.WritingBoardTextInputWrapper>
                  <s.WritingBoardTextInput
                    placeholder="텍스트를 입력해주세요."
                    disabled={!props.isEditing}
                    value={props.submitForm.expectations}
                    onChange={props.onChangeExpectations}
                  />
                </s.WritingBoardTextInputWrapper>
              </s.WritingBoardContent>
            </s.WritingBoardWrapper>
          </s.UploadBody>
        </s.PlanWriteWrapper>
        <s.PlanWriteSpecifyWrapper>
          <s.CleanUpTableButton onClick={props.initializeLayout}>초기화</s.CleanUpTableButton>
          <s.UploadHead>
            <s.UploadHeadTitle>월간교육계획안 주차 계획표 작성</s.UploadHeadTitle>
            <s.UploadHeadInfo>
              <s.UploadHeadInfoImg src={warning} />
              <s.UploadHeadInfoText>ZIP파일 · 단일 jpg는 업로드 불가입니다.</s.UploadHeadInfoText>
            </s.UploadHeadInfo>
          </s.UploadHead>
          <s.MonthlyPlanTable>
            <s.MonthlyPlanTableHeadRow>
              <s.MonthlyPlanTableHeadHeader>주차</s.MonthlyPlanTableHeadHeader>
              <s.MonthlyPlanTableHeadHeader>1주</s.MonthlyPlanTableHeadHeader>
              <s.MonthlyPlanTableHeadHeader>2주</s.MonthlyPlanTableHeadHeader>
              <s.MonthlyPlanTableHeadHeader>3주</s.MonthlyPlanTableHeadHeader>
              {props.weekLength >= 4 ? (
                <s.MonthlyPlanTableHeadHeader>4주</s.MonthlyPlanTableHeadHeader>
              ) : null}
              {props.weekLength >= 5 ? (
                <s.MonthlyPlanTableHeadHeader>5주</s.MonthlyPlanTableHeadHeader>
              ) : null}
            </s.MonthlyPlanTableHeadRow>
            <s.MonthlyPlanTableBodyWrapper>
              {props.prevPlanModalOn ? (
                <PrevPlanModal
                  type="monthly"
                  yearList={props.yearList}
                  ageAuths={props.ageAuths}
                  prevPlanData={props.prevPlanData}
                  setPrevPlanData={props.setPrevPlanData}
                  setPrevPlanModalOn={props.setPrevPlanModalOn}
                />
              ) : null}
              <s.GridLayoutWrapper>
                <GridLayout
                  rowHeight={60}
                  cols={(props.weekLength + 1) * 2}
                  layout={props.layout}
                  onLayoutChange={props.onLayoutChange}
                  margin={[-1, 0]}
                >
                  {props.layout.map((item: any) => {
                    return item.x < 2 ? (
                      <s.GridLayoutEach key={item.i}>
                        {item.activityList[0] || item.description ? (
                          <s.GridLayoutTopicButton
                            onClick={() =>
                              props.setPopupOn({
                                x: item.x,
                                y: item.y,
                                activityList: item.activityList,
                              })
                            }
                            style={{
                              border: '1px solid transparent',
                              fontSize: '15px',
                              color: '#0087eb',
                            }}
                          >
                            {item.activityList.length > 0
                              ? item.activityList[0].typeCdNm
                              : item.description}
                          </s.GridLayoutTopicButton>
                        ) : (
                          <s.GridLayoutTopicButton
                            onClick={() =>
                              props.setPopupOn({
                                x: item.x,
                                y: item.y,
                                activityList: item.activityList,
                              })
                            }
                          >
                            {item.x === 0 ? '대주제' : '소주제'}
                          </s.GridLayoutTopicButton>
                        )}
                      </s.GridLayoutEach>
                    ) : (
                      <s.GridLayoutEach key={item.i}>
                        {(item.activityList && item.activityList.length) || item.description ? (
                          <s.GridLayoutEachButton
                            onClick={() =>
                              props.setPopupOn({
                                x: item.x,
                                y: item.y,
                                activityList: item.activityList,
                                description: item.description,
                              })
                            }
                            style={{
                              backgroundColor: '#0087ed',
                              color: '#fff',
                              border: '1px solid transparent',
                            }}
                          >
                            완료
                          </s.GridLayoutEachButton>
                        ) : (
                          <s.GridLayoutEachButton
                            onClick={() =>
                              props.setPopupOn({
                                x: item.x,
                                y: item.y,
                                activityList: item.activityList,
                                description: item.description,
                              })
                            }
                          >
                            입력
                          </s.GridLayoutEachButton>
                        )}
                      </s.GridLayoutEach>
                    );
                  })}
                </GridLayout>
              </s.GridLayoutWrapper>
            </s.MonthlyPlanTableBodyWrapper>
          </s.MonthlyPlanTable>
          <s.FooterButtonWrapper>
            <s.GridLayoutSettingButtonWrapper>
              <s.GridLayoutSettingButton onClick={props.addLayoutData}>
                데이터 추가
              </s.GridLayoutSettingButton>
              <s.GridLayoutSettingButton onClick={props.onGetLastData}>
                이전 데이터 불러오기
              </s.GridLayoutSettingButton>
              <s.GridLayoutSettingButton onClick={props.onInitializeLayout}>
                초기화
              </s.GridLayoutSettingButton>
              <s.GridLayoutSettingButton onClick={props.onClear}>정리</s.GridLayoutSettingButton>
            </s.GridLayoutSettingButtonWrapper>
            <s.FooterSubmitButton onClick={props.onSubmit}>데이터 저장</s.FooterSubmitButton>
          </s.FooterButtonWrapper>
        </s.PlanWriteSpecifyWrapper>
        {props.popupOn.x !== null && props.popupOn.y !== null ? (
          <SearchModal
            type="monthly"
            ageAuths={props.ageAuths}
            option={props.option}
            topicList={props.topicList}
            subTopicList={props.subTopicList}
            colorList={props.colorList}
            layout={props.layout}
            popupOn={props.popupOn}
            setPopupOn={props.setPopupOn}
            onPushActivityInLayout={props.onPushActivityInLayout}
          />
        ) : null}
      </s.Container>
    </>
  );
};

export default MonthlyPlanPresenter;

import React from 'react';
import * as s from './DailyPlanStyled';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/pagination/pagination.scss';
import * as util from '../../../util/util';
import NotFound from '../../../components/notFound';

import { saveAs } from 'file-saver';
// install Swiper modules

type DailyPlanProps = {
  notSupport: () => void;
  option: {
    age: any;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  ageAuths: any;
  icons: any;
  dailyPlan: any;
  setPlanId: any;
  createPlanFile: (planId: string) => Promise<void>;
};

const DailyPlanPresenter = (props: DailyPlanProps) => {
  if (!props.dailyPlan) {
    return (
      <s.Container>
        <NotFound />
      </s.Container>
    );
  }
  return (
    <s.Container>
      <s.PlanningTitleArea>
        <s.PlanningTitle>
          <s.PlanningTitleSpan>{props.option.year}년</s.PlanningTitleSpan> 만{' '}
          <s.PlanningTitleSpan>{props.ageAuths[props.option.age].comnCdNm}</s.PlanningTitleSpan>{' '}
          <s.PlanningTitleSpan>
            {props.option.month + '월 ' + props.option.week + '주차 ' + props.option.day + '일'}
          </s.PlanningTitleSpan>{' '}
          일일 교육계획안
        </s.PlanningTitle>
        {props.dailyPlan.isHoliday === 'T' ? null : (
          <s.PlanningDailyDownloadBtn
            onClick={() => {
              if (!props.dailyPlan.planId) {
                util.makeMsg('권한이 부족합니다.', 'error');
              } else {
                if (props.dailyPlan.file) {
                  saveAs(props.dailyPlan.file.fileUrl, props.dailyPlan.file.originalFileName);
                } else {
                  props.createPlanFile(props.dailyPlan.planId);
                }
              }
            }}
          >
            <s.DailyDownloadBtnIcon src="/static/img/download_button.png" />
            <s.PlanningDailyDownloadText>일일 교육계획안 다운로드</s.PlanningDailyDownloadText>
          </s.PlanningDailyDownloadBtn>
        )}
      </s.PlanningTitleArea>
      {props.dailyPlan.isHoliday === 'T' ? (
        <s.Holiday>{props.dailyPlan.subject}</s.Holiday>
      ) : (
        <>
          <s.TopContentsArea>
            <s.TopContentsRow style={{ borderTop: '0px' }}>
              <s.TopContentHorizontalSection style={{ width: '50%' }}>
                <s.TopContentHeaderBox>연령</s.TopContentHeaderBox>
                <s.TopContentTextArea>
                  만 {props.ageAuths[props.option.age].comnCdNm}
                </s.TopContentTextArea>
              </s.TopContentHorizontalSection>
              <s.TopContentHorizontalSection style={{ width: '50%' }}>
                <s.TopContentHeaderBox>기간</s.TopContentHeaderBox>
                <s.TopContentTextArea>
                  {props.option.year}년 {props.option.month}월 {props.option.week}주{' '}
                  {props.option.day} 일
                </s.TopContentTextArea>
              </s.TopContentHorizontalSection>
            </s.TopContentsRow>
            <s.TopContentsRow>
              <s.TopContentHorizontalSection style={{ width: '50%' }}>
                <s.TopContentHeaderBox>생활주제</s.TopContentHeaderBox>
                <s.TopContentTextArea>{props.dailyPlan.lifeSubject}</s.TopContentTextArea>
              </s.TopContentHorizontalSection>
              <s.TopContentHorizontalSection style={{ width: '50%' }}>
                <s.TopContentHeaderBox>주제</s.TopContentHeaderBox>
                <s.TopContentTextArea>
                  <s.TopContentTextAreaMultiline>
                    {props.dailyPlan.subject}
                  </s.TopContentTextAreaMultiline>
                </s.TopContentTextArea>
              </s.TopContentHorizontalSection>
            </s.TopContentsRow>
            <s.TopContentsRow>
              <s.TopContentHorizontalSection>
                <s.TopContentHeaderBox>교사의 기대</s.TopContentHeaderBox>
                <s.TopContentTextArea style={{ width: '900px' }}>
                  {/* {
                  props.dailyPlan.expectations ?
                  props.dailyPlan.expectations.split('\n').map((row: any) => {
                    return <s.TopContentTextArea>{row}</s.TopContentTextArea>
                  })
                  : null
                } */}
                  <s.TopContentTextAreaMultiline>
                    {props.dailyPlan.expectations}
                  </s.TopContentTextAreaMultiline>
                </s.TopContentTextArea>
              </s.TopContentHorizontalSection>
            </s.TopContentsRow>
            <s.TopContentsRow>
              <s.TopContentHorizontalSection>
                <s.TopContentHeaderBox>안전/기본생활</s.TopContentHeaderBox>
                <s.TopContentTextArea style={{ width: '900px', justifyContent: 'flex-start' }}>
                  {props.dailyPlan.caution ? (
                    <s.PlanData
                      key={'caution'}
                      onClick={() =>
                        props.dailyPlan.caution.activityId
                          ? props.setPlanId(props.dailyPlan.caution.activityId)
                          : util.makeMsg('권한이 부족합니다.', 'error')
                      }
                    >
                      <s.PlanDataTop>
                        <s.PlanDataTopText>
                          안전 : {props.dailyPlan.caution.domain.comnCdNm}
                        </s.PlanDataTopText>
                        {props.dailyPlan.caution.materialList.map(
                          (materialItem: any, index3: number) => (
                            <s.PlanDataTopImg
                              key={'material' + index3}
                              src={`/static/img/${materialItem.icon.comnCd}.jpg`}
                            />
                          ),
                        )}
                      </s.PlanDataTop>
                      <s.PlanDataBottom>{props.dailyPlan.caution.activityNm}</s.PlanDataBottom>
                    </s.PlanData>
                  ) : null}
                  {props.dailyPlan.defaultEducation ? (
                    <s.PlanData
                      key={'defaultEducation'}
                      onClick={() =>
                        props.dailyPlan.defaultEducation.activityId
                          ? props.setPlanId(props.dailyPlan.defaultEducation.activityId)
                          : util.makeMsg('권한이 부족합니다.', 'error')
                      }
                    >
                      <s.PlanDataTop>
                        <s.PlanDataTopText>
                          기본생활 : {props.dailyPlan.defaultEducation.domain.comnCdNm}
                        </s.PlanDataTopText>
                        {props.dailyPlan.defaultEducation.materialList.map(
                          (materialItem: any, index3: number) => (
                            <s.PlanDataTopImg
                              key={'material' + index3}
                              src={`/static/img/${materialItem.icon.comnCd}.jpg`}
                            />
                          ),
                        )}
                      </s.PlanDataTop>
                      <s.PlanDataBottom>
                        {props.dailyPlan.defaultEducation.activityNm}
                      </s.PlanDataBottom>
                    </s.PlanData>
                  ) : null}
                </s.TopContentTextArea>
              </s.TopContentHorizontalSection>
            </s.TopContentsRow>
          </s.TopContentsArea>
          <s.TableIconInfoArea>
            {Object.keys(props.icons).map((key: string) => (
              <s.PlanningDiv key={'icon' + key}>
                <s.TableIconInfoImg src={`/static/img/${key}.png`} />
                <s.TableIconInfoText>{props.icons[key].comnCdNm}</s.TableIconInfoText>
              </s.PlanningDiv>
            ))}
          </s.TableIconInfoArea>
          <s.PlanAreaWrapper>
            {props.dailyPlan.timeList
              .filter((item: any) => item.endTime || item.startTime)
              .sort((a: any, b: any) =>
                a.startTime < b.startTime ? -1 : a.timeId < b.timeId ? -1 : 1,
              )
              .map((item: any, index: number) => {
                return (
                  <s.PlanArea key={'time' + index} colorCd={item.color.comnCdNm.split('/')[0]}>
                    <s.PlanTime>{item.startTime}</s.PlanTime>
                    <s.DivIcon></s.DivIcon>
                    <s.PlanTitle colorCd={item.color.comnCdNm.split('/')[0]}>
                      {item.title ? item.title.comnCdNm : item.mainTitle}
                      {item.subTitle !== null ? (
                        <s.PlanSubTitle>{item.subTitle}</s.PlanSubTitle>
                      ) : null}
                    </s.PlanTitle>
                    <s.DivIcon></s.DivIcon>
                    {item.activityList.length !== 0 &&
                    item.activityList[0].type.comnCd === 'TYPE0601' ? (
                      <s.PlanTextArea>
                        {item.activityList.map((actItem: any, index2: number) => {
                          let descriptionLength = actItem.description.split('\r\n');
                          return (
                            <s.PlanText
                              rows={descriptionLength.length}
                              height={26 * descriptionLength.length}
                              readOnly
                              key={'actvity' + index2}
                            >
                              {actItem.description}
                            </s.PlanText>
                          );
                        })}
                      </s.PlanTextArea>
                    ) : item.activityList.length !== 0 &&
                      item.activityList[0].type.comnCd === 'TYPE0602' ? (
                      <s.PlanTextArea>
                        {item.activityList.map((actItem: any, index2: number) => (
                          <s.PlanData
                            key={'activityData' + index2}
                            onClick={() =>
                              actItem.activity.activityId
                                ? props.setPlanId(actItem.activity.activityId)
                                : util.makeMsg('권한이 부족합니다.', 'error')
                            }
                          >
                            <s.PlanDataTop>
                              <s.PlanDataTopText>
                                {actItem.activity.domain.comnCdNm}
                              </s.PlanDataTopText>
                              {actItem.activity.materialList.map(
                                (materialItem: any, index3: number) => (
                                  <s.PlanDataTopImg
                                    key={'material' + index3}
                                    src={`/static/img/${materialItem.icon.comnCd}.jpg`}
                                  />
                                ),
                              )}
                            </s.PlanDataTop>
                            <s.PlanDataBottom>{actItem.activity.activityNm}</s.PlanDataBottom>
                          </s.PlanData>
                        ))}
                      </s.PlanTextArea>
                    ) : null}
                  </s.PlanArea>
                );
              })}
          </s.PlanAreaWrapper>
        </>
      )}
    </s.Container>
  );
};

DailyPlanPresenter.defaultProps = {};

export default DailyPlanPresenter;

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import SignUpPresenter from './SignUpPresenter';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { userApi } from '../../api/api-user';
import { programApi } from '../../api/api-program';
import * as recoilItem from '../../util/recoilitem';
import queryString from 'query-string';
import * as util from '../../util/util';

interface SignUpProps extends RouteComponentProps {}

const SignUpContainer = (props: SignUpProps) => {
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const setToken = useSetRecoilState(recoilItem.token);
  const setUserInfo = useSetRecoilState(recoilItem.userInfo);

  const [currentStep, setCurrentStep] = useState(1);
  const [id, setId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConf, setPasswordConf] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [sex, setSex] = useState<string>('F');
  const [birthYear, setBirthYear] = useState<any>(null);
  const [birthMonth, setBirthMonth] = useState<any>(null);
  const [birthDay, setBirthDay] = useState<any>(null);
  const [emailId, setEmailId] = useState<string>('');
  const [emailDomain, setEmailDomain] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [regMethod, setRegMethod] = useState(1);
  const [regNumber, setRegNumber] = useState<string>('');

  const [idValid, setIdValid] = useState<number>(1); // 1 -> initial, 2 -> valid, 3 -> invalid
  const [passwordValid, setPasswordValid] = useState<number>(2); // 2 -> OK, 2 -> Invalid
  const [sent, setSent] = useState<number>(1); // 1 -> Not Sent, 2 -> sent
  const [regNumChecked, setRegNumChecked] = useState<number>(1); // 1 -> Not Registered, 2 -> Valid, 3 -> Invalid.
  const [agreed, setAgreed] = useState<boolean>(false);

  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  const mobileRegex = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/;

  const [yearOptions, setYearOptions] = useState<number[]>([]);
  const [monthOptions, setMonthOptions] = useState<number[]>([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  ]);
  const [dayOptions, setDayOptions] = useState<number[]>([]);

  const checkIdDup = async () => {
    let formData: FormData = new FormData();

    formData.append('userId', id);

    try {
      await userApi.idDupCheck(formData).then((res) => {
        if (res.data.rsltCd !== '00') {
          setIdValid(3);
        } else {
          setIdValid(2);
        }
      });
    } catch (e) {}
  };

  const chkPasswordValid = () => {
    if (password.length === 0) {
      setPasswordValid(2);
      return;
    } else {
      if (passwordRegex.test(password)) {
        setPasswordValid(2);
        return;
      } else {
        setPasswordValid(3);
        return;
      }
    }
  };

  const makeYearOptions = () => {
    let curYear = new Date().toISOString().substring(0, 4);
    let yearArr: number[] = [];

    for (let i = 0; i < 100; i++) {
      yearArr.push(Number(curYear) - i);
    }

    setYearOptions(yearArr);
  };

  const makeDayOptions = () => {
    let dayArr: number[] = [];
    if (!birthYear || !birthMonth) {
      setDayOptions(dayArr);
      return;
    }
    if (
      birthMonth === 1 ||
      birthMonth === 3 ||
      birthMonth === 5 ||
      birthMonth === 7 ||
      birthMonth === 8 ||
      birthMonth === 10 ||
      birthMonth == 12
    ) {
      for (let i = 0; i < 31; i++) {
        dayArr.push(i + 1);
      }
    } else if (birthMonth === 2) {
      if ((birthYear % 4 === 0 && birthYear % 100 !== 0) || birthYear % 400 === 0) {
        for (let i = 0; i < 29; i++) {
          dayArr.push(i + 1);
        }
      } else {
        for (let i = 0; i < 28; i++) {
          dayArr.push(i + 1);
        }
      }
    } else {
      for (let i = 0; i < 30; i++) {
        dayArr.push(i + 1);
      }
    }

    setDayOptions(dayArr);
  };

  const sendRegisterCode = async () => {
    if (regMethod === 1) {
      if (!emailId || !emailDomain) {
        util.makeMsg('이메일 주소를 정확히 입력해주세요', 'error');
        setSent(1);
        setRegNumChecked(1);
        return;
      } else {
        setSent(2);
        let formData: FormData = new FormData();
        formData.append('userEmail', emailId + '@' + emailDomain);
        await userApi.sendRegMail(formData).then((res) => {
          console.log(res);
        });
        setRegNumChecked(1);
      }
    } else if (regMethod === 2) {
      if (!mobile) {
        util.makeMsg('휴대전화번호를 입력해주세요', 'error');
        setSent(1);
        setRegNumChecked(1);
        return;
      } else {
        // Do Something with Mobile Register.
      }
    }
  };

  const validateRegisterCode = async () => {
    if (sent === 1) {
      util.makeMsg('인증번호 전송을 먼저 진행해주세요', 'error');
      setRegNumChecked(1);
      return;
    }

    let formData: FormData = new FormData();
    formData.append('userEmail', emailId + '@' + emailDomain);
    formData.append('regno', regNumber);

    await userApi.checkRegno(formData).then((res) => {
      console.log(res);
      console.log(res.data.rsltCd === '00');
      if (res.data.rsltCd === '00') {
        setRegNumChecked(2);
      } else {
        setRegNumChecked(3);
      }
    });
  };

  const register = async () => {
    if (!id) {
      util.makeMsg('아이디를 입력해주세요.', 'error');
      return;
    }
    if (idValid !== 2) {
      util.makeMsg('중복확인을 진행해주세요', 'error');
      return;
    }
    if (!password) {
      util.makeMsg('비밀번호를 입력해주세요.', 'error');
      return;
    }
    if (passwordValid !== 2) {
      util.makeMsg('비밀번호 규칙에 맞게 입력해주세요.', 'error');
      return;
    }
    if (!passwordConf) {
      util.makeMsg('비밀번호 확인을 입력해주세요.', 'error');
      return;
    }
    if (password !== passwordConf) {
      util.makeMsg('비밀번호 일치여부를 확인해주세요.', 'error');
      return;
    }
    if (!name) {
      util.makeMsg('이름을 입력해주세요.', 'error');
      return;
    }
    if (!birthYear || !birthMonth || !birthDay) {
      util.makeMsg('생년월일을 입력해주세요.', 'error');
      return;
    }
    if (!mobile) {
      util.makeMsg('전화번호를 입력해주세요.', 'error');
      return;
    }
    if (/^/) {
    }
    if (sent !== 2) {
      util.makeMsg('메일인증을 진행해주세요.', 'error');
      return;
    }
    if (!regNumber) {
      util.makeMsg('인증번호를 입력해주세요.', 'error');
      return;
    }
    if (regNumChecked !== 2) {
      util.makeMsg('인증번호 확인을 진행해주세요.', 'error');
      return;
    }
    if (!agreed) {
      util.makeMsg('이용약관에 동의해주세요', 'error');
      return;
    }

    let formData: FormData = new FormData();

    formData.append('userId', id);
    formData.append('password', password);
    formData.append('userName', name);
    formData.append('userMobile', mobile);
    formData.append('userEmail', emailId + '@' + emailDomain);
    formData.append('sex', sex);
    formData.append(
      'userBirth',
      new Date(birthYear, birthMonth, birthDay).toISOString().substring(0, 10).replaceAll('-', ''),
    );
    console.log(
      new Date(birthYear, birthMonth, birthDay).toISOString().substring(0, 10).replaceAll('-', ''),
    );
    await userApi.registerIndividual(formData).then((res) => {
      if (res && res.data.rsltCd === '00') {
        setToken(res.data.sessionToken);
        setUserInfo({
          userKey: res.data.userKey,
          userId: res.data.userId,
          userNm: res.data.userNm,
          userTypeCd: res.data.userTypeCd,
          useYn: res.data.useYn,
          regDtm: res.data.regDtm,
          userEmail: res.data.userEmail,
          userMobno: res.data.userMobno,
        });
        setIsLoading(false);
        // setCurrentStep(2);
        props.history.push('/');
      } else {
        setIsLoading(false);
        util.makeMsg('회원 등록에 실패했습니다.\n관리자에게 문의해주세요.', 'error');
      }
    });
  };

  useEffect(() => {
    makeYearOptions();
  }, []);

  useEffect(() => {
    setIdValid(1);
  }, [id]);

  useEffect(() => {
    chkPasswordValid();
  }, [password]);

  useEffect(() => {
    makeDayOptions();
  }, [birthYear, birthMonth]);

  useEffect(() => {
    setSent(1);
  }, [mobile]);

  return (
    <>
      <Header {...props} />
      <SignUpPresenter
        currentStep={currentStep}
        id={id}
        setId={setId}
        name={name}
        setName={setName}
        sex={sex}
        setSex={setSex}
        password={password}
        setPassword={setPassword}
        passwordConf={passwordConf}
        setPasswordConf={setPasswordConf}
        birthYear={birthYear}
        setBirthYear={setBirthYear}
        birthMonth={birthMonth}
        setBirthMonth={setBirthMonth}
        birthDay={birthDay}
        setBirthDay={setBirthDay}
        emailId={emailId}
        setEmailId={setEmailId}
        emailDomain={emailDomain}
        setEmailDomain={setEmailDomain}
        mobile={mobile}
        setMobile={setMobile}
        regMethod={regMethod}
        setRegMethod={setRegMethod}
        regNumber={regNumber}
        setRegNumber={setRegNumber}
        agreed={agreed}
        setAgreed={setAgreed}
        idValid={idValid}
        passwordValid={passwordValid}
        sent={sent}
        regNumChecked={regNumChecked}
        yearOptions={yearOptions}
        monthOptions={monthOptions}
        dayOptions={dayOptions}
        checkIdDup={checkIdDup}
        sendRegisterCode={sendRegisterCode}
        validateRegisterCode={validateRegisterCode}
        register={register}
      ></SignUpPresenter>
      <Footer {...props} />
    </>
  );
};

export default SignUpContainer;

import * as s from '../UpdateProgramStyled';

interface SelectAgeProps {
  option: {
    holder: string;
    field: string;
    age: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  ageList: any;
}

const SelectAge = (props: SelectAgeProps) => {
  return (
    <s.UploadProgramAreaHeaderSelect>
      <s.UploadProgramAreaSelectHeader>연령 선택</s.UploadProgramAreaSelectHeader>
      <s.UploadProgramAreaHeaderSelect>
        {props.ageList &&
          Object.entries(props.ageList).map(([key, value]) => {
            return (
              <>
                <s.UploadProgramAreaSelectType
                  id={props.ageList[key].comnCd}
                  value={props.ageList[key].comnCd}
                  defaultChecked={props.option.age === props.ageList[key].comnCd}
                  onChange={(e: any) =>
                    props.option.age === e.target.value
                      ? props.onChangeOption('age', '')
                      : props.onChangeOption('age', e.target.value)
                  }
                  type={'checkbox'}
                />
                <s.UploadProgramAreaSelectTypeLabel htmlFor={props.ageList[key].comnCd}>
                  {props.option.age === props.ageList[key].comnCd ? (
                    <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                  ) : null}
                </s.UploadProgramAreaSelectTypeLabel>
                <s.UploadProgramAreaSelectText htmlFor={props.ageList[key].comnCd}>
                  {props.ageList[key].comnCdNm}
                </s.UploadProgramAreaSelectText>
              </>
            );
          })}
      </s.UploadProgramAreaHeaderSelect>
    </s.UploadProgramAreaHeaderSelect>
  );
};

export default SelectAge;

import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

// 위에서 받은 `normalize`로 기본 css가 초기화 합니다.
const DayPickerStyle = createGlobalStyle`
  ${normalize}

.DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    padding-bottom: 1em;
    -webkit-user-select: none;
    user-select: none;
    width: 540px;
    margin-top: 3em !important;
}

.DayPicker-NavBar{
    display: none;
}

.DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.DayPicker-Month{
    flex: 1;
    display: table;
    margin: 0 1em;
    margin-top: 3em !important;
    border-spacing: 0;
    border-collapse: collapse;
    -webkit-user-select: none;
    user-select: none;
    background-color: #fbfbfb;
}

.DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: center;
}
.DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
    background-color: #fff;
    font-size: 20px;
}

.DayPicker-Body {
    display: table-row-group;
    background-color: #fbfbfb;
    border-radius: 15px;
}

.DayPicker-Day {
    display: table-cell;
    padding: .37em;
    border-radius: 20px;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
}
.DayPicker-Day--weeksstart {
    background-color: #B4FFC2;
    color: #4d4d4d;    
    border-radius: 0px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.DayPicker-Day--weeks {
    background-color: #B4FFC2;
    color: #4d4d4d;
    border-radius: 0px;

}
.DayPicker-Day--weeksend {
    background-color: #B4FFC2;
    color: #4d4d4d;
    border-radius: 0px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #C3FFCC;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: #5FC772;
    color: #F0F8FF;
}.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #5FC742;
}
/* .DayPicker {
    width: 416px !important;
    height: 100% !important;
}
// 오른쪽 구석의 화살표를 안보이게 한다.
.DayPickerKeyboardShortcuts_buttonReset {
    display: none;
}

.DayPicker_transitionContainer {
    width: 416px !important;
    height: 500px !important;
}

.CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    user-select: none;
    width: 100%;
    height: 100%;
}

.CalendarMonth_caption {
    color: #4d4d4d;
    font-size: 26px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    margin-bottom: 40px;
    width: 380px !important;
    font-family: 'BRBA_B';
}

.DayPicker_weekHeader {
    width: 406px !important;
    color: #757575;
    position: absolute;
    top: 82px;
    z-index: 2;
    text-align: left;
}
.DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
    width: 406px !important;
}
.DayPicker_weekHeader_li {
    font-size: 26px;
    width: 38px !important;
    display: inline-block;
    text-align: center;
    margin-right: 8px;
    margin-left: 8px;
    color: #ccc;
}

.CalendarMonth_table {
    margin-top: 10px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    overflow: visible;
    background: #fbfbfb;
    border-radius: 15px;
}

.DayPicker_focusRegion {
    width: 416px !important;

    outline: 0;
    height: 100%;
}

.CalendarDay {
    font-size: 20px;
    width: 36px !important;
    text-align: center;
    background: #fbfbfb;
    padding: 15px 0;
}

// 달력 각 칸의 기본 스타일.
.CalendarDay__default {
  border: none;
  border-radius: 50%;
  vertical-align: middle;
  outline: none; 
}

// 달력 각 칸에 호버가 되었을 때 스타일
.CalendarDay__default:hover {
  background: transparent;
  border: none;
  color: black;
  box-shadow: inset 0 0 0 1px black;
}

// 체크인 체크아웃이 선택되었을 때 그 사의 날짜들에 대한 스타일
.CalendarDay__selected_span {
  background-color: #f7f7f7;
  border: none;
  color: black;
}

// 체크인 체크아웃이 선택되었을 때 그 사의 날짜들에 호버 혹은 클릭했을 시 스타일
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  color: black;
  background-color: #f7f7f7;
}

// 선택된 체크인 체크아웃 날짜에 대한 스타일
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: black;
  border: none;
  color: white;
}

// 블록된 날짜에 대한 스타일링
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: white;
  border: none;
  color: #d2d2d2;
  box-shadow: none;
  text-decoration: line-through;
}

// 선택될 범위에 대한 스타일링
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  color: black;
  background-color: #f7f7f7;
  border: none;
}

.DayPickerNavigation_button {
    display: none;
} */
`;

export default DayPickerStyle;
import React from 'react';

import * as s from './TemporaryStyled';

interface TemporaryProps {
  onChangePw: React.ChangeEventHandler<HTMLInputElement>;
  onChangePwConf: React.ChangeEventHandler<HTMLInputElement>;
  agenNm: string;
  onChangeAgenNm: React.ChangeEventHandler<HTMLInputElement>;
  onChangeAgenAddr: React.ChangeEventHandler<HTMLInputElement>;
  onChangeAgenContact: React.ChangeEventHandler<HTMLInputElement>;
  setAgenId: any;
  selectAgency: any;
  onChangeKeyWord: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress: React.KeyboardEventHandler<HTMLInputElement>;
  onSearchKeyPress: React.KeyboardEventHandler<HTMLInputElement>;
  onAddKeyPress: React.KeyboardEventHandler<HTMLInputElement>;
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
  onSearchSubmit: React.MouseEventHandler<HTMLDivElement>;
  onAddSubmit: React.MouseEventHandler<HTMLButtonElement>;
  openSearchModal: any;
  closeSearchModal: React.MouseEventHandler<HTMLDivElement>;
  openAddModal: React.MouseEventHandler<HTMLButtonElement>;
  closeAddModal: React.MouseEventHandler<HTMLDivElement>;
  switchModal: React.MouseEventHandler<HTMLButtonElement>;
  refreshAgencyList: React.MouseEventHandler<HTMLDivElement>;
  isSearchModalOpen: boolean;
  isAddModalOpen: boolean;
  setKeyWordForSearch: any;
  agenList: any;
  pageNum: number;
  setPageNum: any;
  pageList: Array<number>;
  isFirst: boolean;
  isLast: boolean;
  lastPageNum: number;
  totalItemLength: number;
  test: any;
}

const TemporaryPresenter = (props: TemporaryProps) => {
  return (
    <s.Container>
      {props.isSearchModalOpen ? (
        <s.ModalBackGround>
          <s.ModalForm>
            <s.ModalHeader>
              <s.ModalHeaderText>기관 검색</s.ModalHeaderText>
              <s.ModalExitArea onClick={props.closeSearchModal}>
                <s.ModalExitImg src="/static/img/exit-icon.png"></s.ModalExitImg>
              </s.ModalExitArea>
            </s.ModalHeader>
            <s.ModalNavBar>
              <s.ModalNavSearchArea>
                <s.ModalNavRefresh onClick={props.refreshAgencyList}>
                  <s.ModalNavSearchImg src="/static/img/refresh-icon.png" />
                </s.ModalNavRefresh>
                <s.ModalNavSearchTextArea>
                  <s.ModalNavSearchText
                    type="text"
                    name="keyword"
                    placeholder="검색어를 입력하세요"
                    onChange={props.onChangeKeyWord}
                    onKeyPress={props.onSearchKeyPress}
                  />
                  <s.ModalNavSearchButton onClick={props.onSearchSubmit}>
                    <s.ModalNavSearchImg src="/static/img/search-icon.png" />
                  </s.ModalNavSearchButton>
                </s.ModalNavSearchTextArea>
              </s.ModalNavSearchArea>
              <s.ModalAddButtonArea>
                <s.ModalAddAgencyButton onClick={props.switchModal}>
                  기관 추가
                </s.ModalAddAgencyButton>
              </s.ModalAddButtonArea>
            </s.ModalNavBar>
            <s.ModalListArea>
              <s.ModalColumnArea>
                <s.ModalListItemName>이름</s.ModalListItemName>
                <s.ModalListItemAddr>주소</s.ModalListItemAddr>
                <s.ModalListItemContact>연락처</s.ModalListItemContact>
              </s.ModalColumnArea>
              <s.ModalContentsList>
                {props.agenList &&
                  props.agenList.map((item: any, index: any) => (
                    <s.ModalListItem
                      key={index}
                      onClick={() => props.selectAgency(item.agenId, item.agenNm)}
                    >
                      <s.ModalListItemName>{item.agenNm}</s.ModalListItemName>
                      <s.ModalListItemAddr>{item.agenAddr}</s.ModalListItemAddr>
                      <s.ModalListItemContact>{item.agenContact}</s.ModalListItemContact>
                    </s.ModalListItem>
                  ))}
              </s.ModalContentsList>
              <s.ModalPagingNav>
                <s.ModalPagingLink onClick={() => props.setPageNum(1)}>
                  <s.ModalPagingLinkImg src="/static/img/double_prev.png" />
                </s.ModalPagingLink>
                <s.ModalPagingLink onClick={() => props.setPageNum(props.pageNum - 1)}>
                  <s.ModalPagingLinkImg src="/static/img/single_prev.png" />
                </s.ModalPagingLink>
                <s.ModalPagingNumArea>
                  {props.pageList.map((page: number, index: number) => (
                    <s.ModalPagingNum
                      key={index}
                      onClick={() => props.setPageNum(page)}
                      current={props.pageNum === page}
                    >
                      {page}
                    </s.ModalPagingNum>
                  ))}
                </s.ModalPagingNumArea>
                <s.ModalPagingLink onClick={() => props.setPageNum(props.pageNum + 1)}>
                  <s.ModalPagingLinkImg src="/static/img/single_next.png" />
                </s.ModalPagingLink>
                <s.ModalPagingLink onClick={() => props.setPageNum(props.lastPageNum)}>
                  <s.ModalPagingLinkImg src="/static/img/double_next.png" />
                </s.ModalPagingLink>
              </s.ModalPagingNav>
            </s.ModalListArea>
          </s.ModalForm>
        </s.ModalBackGround>
      ) : props.isAddModalOpen ? (
        <s.ModalBackGround>
          <s.ModalForm>
            <s.ModalHeader>
              <s.ModalHeaderText>기관 추가</s.ModalHeaderText>
            </s.ModalHeader>
            <s.ModalExitArea onClick={props.closeAddModal}>
              <s.ModalExitImg src="/static/img/exit-icon.png"></s.ModalExitImg>
            </s.ModalExitArea>
            <s.ModalNavBar>
              <s.ModalAddButtonArea>
                <s.ModalAddAgencyButton onClick={props.switchModal}>
                  기관 검색
                </s.ModalAddAgencyButton>
              </s.ModalAddButtonArea>
            </s.ModalNavBar>
            <s.ModalListArea>
              <s.ModalAddAgencyRow>
                <s.ModalAddAgencyTitle>기관 이름</s.ModalAddAgencyTitle>
                <s.ModalAddAgencyTextInput
                  type="text"
                  name="agenNm"
                  onChange={props.onChangeAgenNm}
                  onKeyPress={props.onAddKeyPress}
                />
              </s.ModalAddAgencyRow>
              <s.ModalAddAgencyRow>
                <s.ModalAddAgencyTitle>기관 주소</s.ModalAddAgencyTitle>
                <s.ModalAddAgencyTextInput
                  type="text"
                  name="agenAddr"
                  onChange={props.onChangeAgenAddr}
                  onKeyPress={props.onAddKeyPress}
                />
              </s.ModalAddAgencyRow>
              <s.ModalAddAgencyRow>
                <s.ModalAddAgencyTitle>기관 연락처</s.ModalAddAgencyTitle>
                <s.ModalAddAgencyTextInput
                  type="text"
                  name="agenContact"
                  onChange={props.onChangeAgenContact}
                  onKeyPress={props.onAddKeyPress}
                />
              </s.ModalAddAgencyRow>
              <s.ModalAddAgencyCfBtnArea>
                <s.ModalAddAgencyConfirmBtn onClick={props.onAddSubmit}>
                  확 인
                </s.ModalAddAgencyConfirmBtn>
              </s.ModalAddAgencyCfBtnArea>
            </s.ModalListArea>
          </s.ModalForm>
        </s.ModalBackGround>
      ) : null}
      <s.TemporaryContentArea>
        <s.TemporaryContent>
          <s.TemporaryRow>
            <s.TemporaryTitle>-- 비밀번호 변경 --</s.TemporaryTitle>
          </s.TemporaryRow>
          <s.TemporaryRow>
            <s.TemporaryTitle>비밀번호</s.TemporaryTitle>
            <s.TemporaryTextInput
              type="password"
              placeholder="비밀번호 입력"
              name="password"
              onChange={props.onChangePw}
              onKeyPress={props.onKeyPress}
            />
          </s.TemporaryRow>
          <s.TemporaryRow>
            <s.TemporaryTitle>비밀번호 확인</s.TemporaryTitle>
            <s.TemporaryTextInput
              type="password"
              placeholder="비밀번호 확인 입력"
              name="passwordconf"
              onChange={props.onChangePwConf}
              onKeyPress={props.onKeyPress}
            />
          </s.TemporaryRow>
          <s.TemporaryRow>
            <s.TemporaryTitle>기관 선택</s.TemporaryTitle>
            <s.TemporaryAgencyContent onClick={props.openSearchModal}>
              <s.TemporaryTextInput
                type="text"
                name="agenNm"
                placeholder={props.agenNm}
                onKeyPress={props.onKeyPress}
                disabled
              />
            </s.TemporaryAgencyContent>
          </s.TemporaryRow>
          <s.TemporarySubmit>
            <s.TemporaryConfirmButton onClick={props.onSubmit}>정보 수정</s.TemporaryConfirmButton>
          </s.TemporarySubmit>
        </s.TemporaryContent>
      </s.TemporaryContentArea>
    </s.Container>
  );
};

export default TemporaryPresenter;

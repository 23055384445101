import React from 'react';

import * as util from '../../util/util';
import * as s from './SignUpStyled';
import NotFound from '../../components/notFound';
import { Link } from 'react-router-dom';

interface SignUpProps {
  currentStep: number,
  id: string;
  setId: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  sex: string;
  setSex: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  passwordConf: string;
  setPasswordConf: React.Dispatch<React.SetStateAction<string>>;
  birthYear: number;
  setBirthYear: React.Dispatch<React.SetStateAction<number>>;
  birthMonth: number;
  setBirthMonth: React.Dispatch<React.SetStateAction<number>>;
  birthDay: number;
  setBirthDay: React.Dispatch<React.SetStateAction<number>>;
  emailId: string;
  setEmailId: React.Dispatch<React.SetStateAction<string>>;
  emailDomain: string;
  setEmailDomain: React.Dispatch<React.SetStateAction<string>>;
  mobile: string;
  setMobile: React.Dispatch<React.SetStateAction<string>>;
  regMethod: number;
  setRegMethod: React.Dispatch<React.SetStateAction<number>>;
  regNumber: string;
  setRegNumber: React.Dispatch<React.SetStateAction<string>>;
  agreed: boolean;
  setAgreed: React.Dispatch<React.SetStateAction<boolean>>;

  idValid: number;
  passwordValid: number;
  sent: number;
  regNumChecked: number;

  yearOptions: number[];
  monthOptions: number[];
  dayOptions: number[];
  

  checkIdDup: () => void;
  sendRegisterCode: () => void;
  validateRegisterCode: () => void;
  register: () => void;
}

const SignUpPresenter = (props: SignUpProps) => {
  return (
    <s.Container>
      <s.SignUpHeader>
        <s.SignUpHeaderTitleArea>
          <s.SignUpHeaderTitle>회원가입</s.SignUpHeaderTitle>
        </s.SignUpHeaderTitleArea>
        <s.SignUpHeaderRightArea>
          <s.SignUpHeaderRightText>
            <s.SignUpHeaderRightIcon src="/static/img/home_icon.jpg" />
            {'HOME > 회원가입'}
          </s.SignUpHeaderRightText>
        </s.SignUpHeaderRightArea>
      </s.SignUpHeader>

      
      <s.ContentsArea>
        <s.StepArea>
          <s.StepNumberText>0{props.currentStep}</s.StepNumberText>
          <s.StepText>{props.currentStep === 1 ? '회원정보 입력' : '회원가입 완료'}</s.StepText>
        </s.StepArea>
        {
          props.currentStep === 1 ?
          <>
            <s.SignInFormTable>
              <s.SignInFormTr border={2}>
                <s.SignInFormTh>아이디</s.SignInFormTh>
                <s.SignInFormTd>
                  <s.SignInFormVerticalDiv>
                    <s.SignInFormHorizontalDiv>
                      <s.SignInInputLong type="text" placeholder='아이디' value={props.id} onChange={(e) => {props.setId(e.target.value)}}></s.SignInInputLong>
                      <s.SignInCheckButton onClick={props.checkIdDup}>중복확인</s.SignInCheckButton>
                    </s.SignInFormHorizontalDiv>
                    {
                      props.idValid === 2 ?
                      <s.SignInToolTip validType={'Y'}>사용 가능한 아이디입니다.</s.SignInToolTip>
                      : props.idValid === 3 ? 
                      <s.SignInToolTip validType={'N'}>이미 사용중인 아이디입니다.</s.SignInToolTip>
                      : null
                    }
                  </s.SignInFormVerticalDiv>
                </s.SignInFormTd>
              </s.SignInFormTr>
              <s.SignInFormTr border={1}>
                <s.SignInFormTh>비밀번호</s.SignInFormTh>
                <s.SignInFormTd>
                  <s.SignInFormVerticalDiv>
                    <s.SignInInputLong type="password" placeholder={'비밀번호'} value={props.password} onChange={(e) => props.setPassword(e.target.value)}></s.SignInInputLong>
                    {
                      props.passwordValid === 3 ?
                      <s.SignInToolTip validType={'N'}>비밀번호는 대문자, 소문자, 숫자, 특수문자를 포함해 최소 8자로 작성해야 합니다.</s.SignInToolTip>
                      : null
                    }
                  </s.SignInFormVerticalDiv>
                </s.SignInFormTd>
              </s.SignInFormTr>
              <s.SignInFormTr border={1}>
                <s.SignInFormTh>비밀번호 확인</s.SignInFormTh>
                <s.SignInFormTd>
                  <s.SignInFormVerticalDiv>
                    <s.SignInInputLong type="password" placeholder={'비밀번호확인'} value={props.passwordConf} onChange={(e) => props.setPasswordConf(e.target.value)}></s.SignInInputLong>
                    {
                      props.passwordConf.length > 0 && props.password !== props.passwordConf ?
                      <s.SignInToolTip validType={'N'}>비밀번호가 다릅니다.</s.SignInToolTip>
                      : null
                    }
                  </s.SignInFormVerticalDiv>
                </s.SignInFormTd>
              </s.SignInFormTr>
              <s.SignInFormTr border={1}>
                <s.SignInFormTh>이름</s.SignInFormTh>
                <s.SignInFormTd>
                  <s.SignInFormVerticalDiv>
                    <s.SignInInputLong type="text" placeholder={'이름'} value={props.name} onChange={(e) => props.setName(e.target.value)}></s.SignInInputLong>
                  </s.SignInFormVerticalDiv>
                </s.SignInFormTd>
              </s.SignInFormTr>
              <s.SignInFormTr border={1}>
                <s.SignInFormTh>성별</s.SignInFormTh>
                <s.SignInFormTd>
                  <s.SignInFormHorizontalDiv>
                    <s.SignInInputRadioArea>
                      <s.SignInputRadio type="radio" value={'F'} onChange={(e) => props.setSex(e.target.value)} checked={props.sex === 'F'}/>
                      <s.SignInputRadioLabel>여성</s.SignInputRadioLabel>
                    </s.SignInInputRadioArea>
                    <s.SignInInputRadioArea>
                      <s.SignInputRadio type="radio" value={'M'} onChange={(e) => props.setSex(e.target.value)} checked={props.sex === 'M'}/>
                      <s.SignInputRadioLabel>남성</s.SignInputRadioLabel>
                    </s.SignInInputRadioArea>
                  </s.SignInFormHorizontalDiv>
                </s.SignInFormTd>
              </s.SignInFormTr>
              <s.SignInFormTr border={1}>
                <s.SignInFormTh>생년월일</s.SignInFormTh>
                <s.SignInFormTd>
                  <s.SignInFormHorizontalDiv>
                    <s.SignInputSelect value={props.birthYear} onChange={(e) => props.setBirthYear(Number(e.target.value))}>
                      <s.SignInputSelectOption>선택</s.SignInputSelectOption>
                      {
                        props.yearOptions.map((item: number) => (
                          <s.SignInputSelectOption value={item}>{item}</s.SignInputSelectOption>
                        ))
                      }
                    </s.SignInputSelect>
                    <s.SignInputText>년</s.SignInputText>
                    <s.SignInputSelect value={props.birthMonth} onChange={(e) => props.setBirthMonth(Number(e.target.value))}>
                      <s.SignInputSelectOption>선택</s.SignInputSelectOption>
                      {
                        props.monthOptions.map((item: number) => (
                          <s.SignInputSelectOption value={item}>{item}</s.SignInputSelectOption>
                        ))
                      }
                    </s.SignInputSelect>
                    <s.SignInputText>월</s.SignInputText>
                    <s.SignInputSelect value={props.birthDay} onChange={(e) => props.setBirthDay(Number(e.target.value))}>
                      <s.SignInputSelectOption>선택</s.SignInputSelectOption>
                      {
                        props.dayOptions.map((item: number) => (
                          <s.SignInputSelectOption value={item}>{item}</s.SignInputSelectOption>
                        ))
                      }
                    </s.SignInputSelect>
                    <s.SignInputText>일</s.SignInputText>
                  </s.SignInFormHorizontalDiv>
                </s.SignInFormTd>
              </s.SignInFormTr>
              <s.SignInFormTr border={2}>
                <s.SignInFormTh>이메일</s.SignInFormTh>
                <s.SignInFormTd>
                  <s.SignInFormVerticalDiv>
                    <s.SignInFormHorizontalDiv>
                        <s.SignInInputShort type="text" placeholder='이메일' value={props.emailId} onChange={(e) => {props.setEmailId(e.target.value)}}></s.SignInInputShort>
                        <s.SignInputText>@</s.SignInputText>
                        <s.SignInInputLong type="text" placeholder='fingeredu.com' value={props.emailDomain} onChange={(e) => {props.setEmailDomain(e.target.value)}}></s.SignInInputLong>
                    </s.SignInFormHorizontalDiv>
                    <s.SignInToolTip validType={'Y'}>아이디 또는 비밀번호를 찾기 위해 사용됩니다. 정확한 정보로 기입하시기 바랍니다.</s.SignInToolTip>
                  </s.SignInFormVerticalDiv>
                </s.SignInFormTd>
              </s.SignInFormTr>
              <s.SignInFormTr border={1}>
                <s.SignInFormTh>휴대폰</s.SignInFormTh>
                <s.SignInFormTd>
                  <s.SignInFormVerticalDiv>
                    <s.SignInFormHorizontalDiv>
                        <s.SignInInputLong type="number" placeholder='-를 제외한 11자리' value={props.mobile} onChange={(e) => {props.setMobile(e.target.value)}}></s.SignInInputLong>
                    </s.SignInFormHorizontalDiv>
                    <s.SignInToolTip validType={'Y'}>아이디 또는 비밀번호를 찾기 위해 사용됩니다. 정확한 정보로 기입하시기 바랍니다.</s.SignInToolTip>
                  </s.SignInFormVerticalDiv>
                </s.SignInFormTd>
              </s.SignInFormTr>
              <s.SignInFormTr border={1}>
                <s.SignInFormTh>인증방법</s.SignInFormTh>
                <s.SignInFormTd>
                  <s.SignInFormVerticalDiv>
                    <s.SignInFormHorizontalDiv>
                      <s.SignInInputRadioArea>
                        <s.SignInputRadio type="radio" value={1} onChange={(e) => props.setRegMethod(Number(e.target.value))} checked={props.regMethod === 1}/>
                        <s.SignInputRadioLabel>이메일</s.SignInputRadioLabel>
                      </s.SignInInputRadioArea>
                      <s.SignInCheckButton onClick={props.sendRegisterCode}>인증</s.SignInCheckButton>
                    </s.SignInFormHorizontalDiv>
                    {
                      props.sent === 2 ?
                      <s.SignInToolTip validType={'Y'}>인증번호가 전송되었습니다.</s.SignInToolTip>
                      : null
                    }
                  </s.SignInFormVerticalDiv>
                </s.SignInFormTd>
              </s.SignInFormTr>
              <s.SignInFormTr border={1}>
                <s.SignInFormTh>인증번호</s.SignInFormTh>
                <s.SignInFormTd>
                  <s.SignInFormVerticalDiv>
                    <s.SignInFormHorizontalDiv>
                      <s.SignInInputShort type="text" placeholder='인증번호' value={props.regNumber} onChange={(e) => {props.setRegNumber(e.target.value)}}></s.SignInInputShort>
                      <s.SignInCheckButton onClick={props.validateRegisterCode}>인증</s.SignInCheckButton>
                    </s.SignInFormHorizontalDiv>
                    
                    {
                      props.regNumChecked === 2 ?
                      <s.SignInToolTip validType={'Y'}>인증완료</s.SignInToolTip>
                      : props.regNumChecked === 3 ?
                      <s.SignInToolTip validType={'N'}>인증번호가 다릅니다.</s.SignInToolTip>
                      : null
                    }
                  </s.SignInFormVerticalDiv>
                </s.SignInFormTd>
              </s.SignInFormTr>
            </s.SignInFormTable>
            <s.AgreementArea>
              <s.AgreementCheckbox type={'checkbox'} checked={props.agreed} onClick={() => {props.setAgreed(!props.agreed)}}/>
              <s.AgreementText>하비캐슬 <s.AgreementTextLink to="#">이용약관</s.AgreementTextLink>,<s.AgreementTextLink to="#">개인정보처리방침</s.AgreementTextLink> 및 <s.AgreementTextLink to="#">환불정책</s.AgreementTextLink>에 동의합니다.</s.AgreementText>
            </s.AgreementArea>
            <s.RegisterButton onClick={props.register}>확인</s.RegisterButton>
          </>
          :
          <>
            <s.ButtonSection>
              <Link to="/">
                <s.ColorButton color={'#68BEFF'} idx={0}>정기회원 신청하기</s.ColorButton>
              </Link>
              <Link to="/plan">
                <s.ColorButton color={'#86E93A'} idx={1}>교육계획안 보러가기</s.ColorButton>
              </Link>
              <Link to="/">
                <s.ColorButton color={'#FFAEAE'} idx={2}>쇼핑몰 구경하기</s.ColorButton>
              </Link>
            </s.ButtonSection>
            <Link to="/">
              <s.RegisterButton>메인화면</s.RegisterButton>
            </Link>
          </>
        }
      </s.ContentsArea>

    </s.Container>
  );
};

export default SignUpPresenter;

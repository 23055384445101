import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import UpdateProgramPresenter from './UpdateProgramPresenter';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { comnApi } from './../../api/api-comn';
import { menuApi } from './../../api/api-menu';
import { planApi } from '../../api/api-plan';
import { programApi } from '../../api/api-program';
import * as util from '../../util/util';
import * as recoilItem from '../../util/recoilitem';

import swal from 'sweetalert';
interface UpdateProgramProps extends RouteComponentProps {}

const UpdateProgramContainer = (props: UpdateProgramProps) => {
  const token = useRecoilValue(recoilItem.token);
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const setProgramId = useSetRecoilState(recoilItem.programId);

  const blockPageNum: string = '5';
  const pagePostCount: string = '5';

  const [holderList, setHolderList] = useState<any>([]);
  const [ageList, setAgeList] = useState<any>([]);
  const [isEduTool, setIsEduTool] = useState<boolean>(false);
  const [submitFileList, setSubmitFileList] = useState<File[]>([]);
  const [fieldList, setFieldList] = useState<
    {
      menuCd: string;
      menuNm: string;
      viewOrder: number;
    }[]
  >([]);

  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files) => {
      setSubmitFileList(files);
    },
  });

  const [option, setOption] = useState<{
    holder: string;
    field: string;
    age: string;
    title: string;
    description: string;
    files: any[];
    thumbnail: any;
  }>({
    holder: 'HOL0101',
    field: '',
    age: '',
    title: '',
    description: '',
    files: [],
    thumbnail: {},
  });

  const goModify = (program: any) => {
    setModifiedProgram(program);
    console.log(program);
    let progs: any[] = [];
    for (let i = 0; i < program.programImages.length; i++) {
      progs.push({
        name: program.programImages[i].file.originalFileName,
        type: 'Image',
      });
    }

    for (let i = 0; i < program.programDocuments.length; i++) {
      progs.push({
        name: program.programDocuments[i].file.originalFileName,
        type: 'Document',
      });
    }

    for (let i = 0; i < program.programMedias.length; i++) {
      progs.push({
        name: program.programMedias[i].file.originalFileName,
        type: 'Media',
      });
    }

    for (let i = 0; i < program.programSounds.length; i++) {
      progs.push({
        name: program.programSounds[i].file.originalFileName,
        type: 'Sound',
      });
    }

    setOption({
      holder: program.holder.comnCd,
      field: program.field?.menuCd,
      age: program.age.comnCd,
      title: program.title,
      description: program.description,
      files: progs,
      thumbnail: program.thumbnail,
    });

    console.log(program);

    if (program.isEduTool === 'Y') {
      setIsEduTool(true);
    } else {
      setIsEduTool(false);
    }
    setIsModifyPage(true);
  };

  const goSearch = () => {
    setModifiedProgram(null);
    setOption({
      holder: '',
      field: '',
      age: '',
      title: '',
      description: '',
      files: [],
      thumbnail: {},
    });
    setIsModifyPage(false);
  };

  const onChangeOption = (optionNm: string, optionValue: string) => {
    setOption({
      ...option,
      [optionNm]: optionValue,
    });
  };
  const getField = async () => {
    try {
      setIsLoading(true);
      await menuApi.getCd('MN03').then((res) => {
        if (res.data.rsltCd === '00') {
          setFieldList(
            res.data.ComnCd.subMenuList.map((item: any) => ({
              menuCd: item.menuCd,
              menuNm: item.menuNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageList, setPageList] = useState<number[]>([]);
  const [isFirst, setIsFirst] = useState<boolean>(false);
  const [lastPageNum, setLastPageNum] = useState<number>(1);
  const [isLast, setIsLast] = useState<boolean>(false);
  const [totalItemLength, setTotalItemLength] = useState<number>(0);

  const [selectedProgramList, setSelectedProgramList] = useState<any[]>([]);

  const [modalProgram, setModalProgram] = useState<any>(null);

  const [isModifyPage, setIsModifyPage] = useState<boolean>(false);
  const [modifiedProgram, setModifiedProgram] = useState<any>(null);

  const onChangeSelectedProgramList = (program: any, icon: string) => {
    let temps = [...selectedProgramList];
    let index = temps.map((item) => item.program.programId).indexOf(program.programId);
    if (index === -1) {
      temps.push({
        program,
        icon,
      });
    } else {
      if (temps[index].icon === icon) {
        temps.splice(index, 1);
      } else {
        temps.splice(index, 1);
        temps.push({
          program,
          icon,
        });
      }
    }
    setSelectedProgramList(temps);
  };
  const onChangeSelectedProgramPage = (program: any, page: string) => {
    let temps = [...selectedProgramList];
    let index = temps.map((item) => item.program.programId).indexOf(program.programId);
    let tempProgram = selectedProgramList[index];

    temps.splice(index, 1);
    temps.push({
      ...tempProgram,
      page,
    });

    setSelectedProgramList(temps);
    setModalProgram(null);
  };
  const [programList, setProgramList] = useState<any[]>([]);
  const [programSearch, setProgramSearch] = useState<{
    keyword?: string;
    field?: string;
  }>({});

  const getProgramList = async () => {
    try {
      setIsLoading(true);
      let formData: FormData = new FormData();
      programSearch.keyword && formData.append('title', programSearch.keyword);
      programSearch.field && formData.append('field', programSearch.field);
      formData.append('page', pageNum.toString());
      formData.append('blockPageNum', blockPageNum);
      formData.append('pagePostCount', pagePostCount);

      await programApi.filter(token, formData).then((res) => {
        if (res.data.rsltCd === '00') {
          console.log(res.data.programList.content);
          setProgramList(res.data.programList.content);
          setIsFirst(res.data.programList.first);
          setIsLast(res.data.programList.last);
          setLastPageNum(res.data.programList.totalPages);
          setPageList(res.data.pageList);
          setTotalItemLength(res.data.programList.totalElements);
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const deleteProgram = async (programId: number) => {
    await swal('정말로 삭제하시겠습니까?', {
      buttons: ['아니오', '예'],
    }).then(async (value) => {
      if (value) {
        try {
          setIsLoading(true);

          await programApi.deleteProgram(token, programId).then((res) => {
            if (res.data.rsltCd === '00') {
              util.makeMsg('삭제에 성공하였습니다.', 'success');
              getProgramList();
            } else {
              util.makeMsg('삭제에 실패하였습니다.', 'error');
            }
          });
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    });
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      await comnApi.getCd('HOL01').then((res) => {
        if (res.data.rsltCd === '00') {
          let holderList: any = {};
          res.data.ComnCd.subCdList.map((item: any) => {
            holderList[item.comnCd] = {
              comnCd: item.comnCd,
              comnCdNm: item.comnCdNm,
              viewOrder: item.viewOrder,
            };
          });
          setHolderList(holderList);
        }
      });
      await comnApi.getCd('AUTH01').then((res) => {
        if (res.data.rsltCd === '00') {
          setAgeList(
            res.data.ComnCd.subCdList.map((item: any) => ({
              comnCd: item.comnCd,
              comnCdNm: item.comnCdNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getField();
    fetchData();
  }, []);

  useEffect(() => {
    getProgramList();
  }, [pageNum]);

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getProgramList();
    }
  };

  const update = async () => {
    try {
      setIsLoading(true);
      let formData: FormData = new FormData();
      if (!option.age) {
        util.makeMsg('연령을 선택해주세요.', 'error');
        return;
      }
      if (!option.holder) {
        util.makeMsg('월(호)을 선택해주세요.', 'error');
        return;
      }
      // if (!option.field) {
      //   util.makeMsg('놀이를 선택해주세요.', 'error');
      //   return;
      // }
      if (!option.title) {
        util.makeMsg('제목을 입력해주세요.', 'error');
        return;
      }
      formData.append('programId', modifiedProgram.programId);
      formData.append('age', option.age);
      formData.append('holder', option.holder);
      formData.append('field', option.field);
      formData.append('title', option.title);
      formData.append('isEduTool', isEduTool ? 'Y' : 'N');
      if (option.description) {
        formData.append('description', option.description);
      }
      if (submitFileList.length > 0) {
        formData.append('thumbnail.file', submitFileList[0]);
      }

      await programApi.updateProgram(token, formData).then((res) => {
        console.log(res);
        if (res.data.rsltCd === '00') {
          util.makeMsg('수정에 성공하였습니다.', 'success');
          getProgramList();
          setIsLoading(false);
          goSearch();
        } else {
          util.makeMsg('수정에 실패하였습니다.', 'error');
          setIsLoading(false);
        }
      });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header {...props} />
      <UpdateProgramPresenter
        getProgramList={getProgramList}
        fieldList={fieldList}
        programSearch={programSearch}
        setProgramSearch={setProgramSearch}
        programList={programList}
        pageNum={pageNum}
        setPageNum={setPageNum}
        pageList={pageList}
        lastPageNum={lastPageNum}
        isFirst={isFirst}
        isLast={isLast}
        onChangeSelectedProgramList={onChangeSelectedProgramList}
        selectedProgramList={selectedProgramList}
        onKeyPress={onKeyPress}
        setProgramId={setProgramId}
        modalProgram={modalProgram}
        setModalProgram={setModalProgram}
        onChangeSelectedProgramPage={onChangeSelectedProgramPage}
        deleteProgram={deleteProgram}
        isModifyPage={isModifyPage}
        setIsModifyPage={setIsModifyPage}
        modifiedProgram={modifiedProgram}
        setModifiedProgram={setModifiedProgram}
        option={option}
        onChangeOption={onChangeOption}
        holderList={holderList}
        ageList={ageList}
        goModify={goModify}
        goSearch={goSearch}
        update={update}
        isEduTool={isEduTool}
        setIsEduTool={setIsEduTool}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        open={open}
        acceptedFiles={acceptedFiles}
        isDragActive={isDragActive}
        submitFileList={submitFileList}
      />
      <Footer {...props} />
    </>
  );
};

export default UpdateProgramContainer;

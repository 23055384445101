import React, { Dispatch, SetStateAction } from 'react';
import * as s from './UserListStyled';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import './Paging.css';

interface UserListProps {
  selectedUserType: string;
  setSelectedUserType: Dispatch<SetStateAction<string>>;
  userName: string;
  setUserName: Dispatch<SetStateAction<string>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  userTypeList: any[];
  fetchData: () => void;
  userList: any[];
  setUserList: Dispatch<SetStateAction<any[]>>;
  totalCount: number;
  updateUser: (user: any) => void;
  handlePageChange: (page: number) => void;
}

const UserListPresenter = (props: UserListProps) => {
  return (
    <s.Container imgsrc={'/static/img/login_back.png'}>
      <s.FilterArea>
        <s.FilterTable>
          <s.FilterTbody>
            <s.FilterTr>
              <s.FilterTh>사용자유형</s.FilterTh>
              <s.FilterTd>
                <s.FilterSelect
                  value={props.selectedUserType}
                  onChange={(e) => props.setSelectedUserType(e.target.value)}
                >
                  <option value="">전체</option>
                  {props.userTypeList.map((type: any) => (
                    <option value={type.cd}>{type.nm}</option>
                  ))}
                </s.FilterSelect>
              </s.FilterTd>
            </s.FilterTr>
            <s.FilterTr>
              <s.FilterTh>이름</s.FilterTh>
              <s.FilterTd>
                <s.FilterInput
                  value={props.userName}
                  onChange={(e) => props.setUserName(e.target.value)}
                  placeholder="사용자명"
                />
              </s.FilterTd>
            </s.FilterTr>
          </s.FilterTbody>
        </s.FilterTable>
        <s.SearchButton onClick={props.fetchData}>조회</s.SearchButton>
      </s.FilterArea>

      <s.UserListArea>
        <s.UserListTable>
          <s.UserListTbody>
            <s.UserListTr>
              <s.UserListTh>사용자 유형</s.UserListTh>
              <s.UserListTh>사용자명</s.UserListTh>
              <s.UserListTh>아이디</s.UserListTh>
              <s.UserListTh>가입일</s.UserListTh>
              <s.UserListTh>수정하기</s.UserListTh>
            </s.UserListTr>
            {props.userList.length > 0 ? (
              props.userList.map((user: any) => (
                <s.UserListTr>
                  <s.UserListTd>
                    <s.FilterSelect
                      value={user.userTypeCd}
                      onChange={(e) => {
                        let temp = props.userList;
                        temp[temp.findIndex((iu: any) => iu.userKey === user.userKey)].userTypeCd =
                          e.target.value;
                        props.setUserList([...temp]);
                      }}
                    >
                      {props.userTypeList.map((type: any) => (
                        <option value={type.cd}>{type.nm}</option>
                      ))}
                    </s.FilterSelect>
                  </s.UserListTd>
                  <s.UserListTd>{user.userName}</s.UserListTd>
                  <s.UserListTd>{user.userId}</s.UserListTd>
                  <s.UserListTd>{moment(user.regDtm).format('YYYY-MM-DD')}</s.UserListTd>
                  <s.UserListTd>
                    <s.UpdateButton onClick={() => props.updateUser(user)}>수정하기</s.UpdateButton>
                  </s.UserListTd>
                </s.UserListTr>
              ))
            ) : (
              <s.UserListTr>
                <s.UserListTd colSpan={5} style={{ height: '300px' }}>
                  조회된 사용자가 없습니다.
                </s.UserListTd>
              </s.UserListTr>
            )}
          </s.UserListTbody>
        </s.UserListTable>
        {props.userList.length > 0 ? (
          <Pagination
            activePage={props.page}
            itemsCountPerPage={10}
            totalItemsCount={props.totalCount}
            pageRangeDisplayed={5}
            prevPageText={'‹'}
            nextPageText={'›'}
            onChange={props.handlePageChange}
          />
        ) : null}
      </s.UserListArea>
    </s.Container>
  );
};

export default UserListPresenter;

import styled from 'styled-components';

export const Container = styled.div`
  width: 1091px;
  margin: 0 auto;
  text-align: center;
`;

export const PlanningDiv = styled.div``;

export const PlanningTitleArea = styled.div`
  width: 100%;
  margin: 20px auto 0;
  height: auto;
  border-bottom: 1px solid #0087ed;
  padding: 0 0 10px;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: space-between;
`;

export const PlanningTitle = styled.div`
  font-size: 18px;
  display: inline-block;
  line-height: 32px;
  font-weight: 500;
`;

export const PlanningTitleSpan = styled.span`
  color: #0087ed;
`;

export const PlanningDailyDownloadBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: end;
  border-radius: 5px;
  border: 1px solid #0087ed;
  width: 191px;
  height: 27px;
  cursor: pointer;
`;

export const DailyDownloadBtnIcon = styled.img`
  width: 16px;
  height: 14px;
  display: inline-block;
  margin: 0px 3px;
`;

export const PlanningDailyDownloadText = styled.div`
  display: inline-block;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: #4d4d4d;
  vertical-align: bottom;
`;

export const TopContentsArea = styled.div`
  width: 100%;
  height: auto;
  border-bottom: 1px solid #0087ed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TopContentHorizontalSection = styled.div`
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 15px;
`;

export const TopContentHeaderBox = styled.div`
  height: 24px;
  padding-left: 15px;
  padding-right: 15px;
  min-width: 83px;
  flex-basis: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #4d4d4d;
  background-color: #eeeeee;
  border-radius: 5px;
`;
export const TopContentTextArea = styled.div`
  font-size: 14px;
  color: #4d4d4d;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  width: auto;
  min-height: 24px;
  max-width: 930px;
  justify-content: center;
  align-items: center;
  text-align: left;
  line-height: 24px;
`;

export const TopContentTextAreaMultiline = styled.textarea`
  border: 0;
  resize: none;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #4d4d4d;
`;

export const TopContentsRight = styled.div`
  height: auto;
  display: flex;
  padding: 30px 0;
  align-items: center;
  gap: 30px;
  margin-right: 50px;
`;

export const TopContentsRightSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopContentsRightIcon = styled.img`
  width: 190px;
  height: 190px;
  margin: 0 auto 20px;
`;

export const PlanningDownloadBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: end;
  border-radius: 5px;
  border: 1px solid #0087ed;
  width: 191px;
  height: 27px;
  cursor: pointer;
`;

export const DownloadBtnIcon = styled.img`
  width: 16px;
  height: 14px;
  display: inline-block;
  margin: 0px 3px;
`;

export const PlanningDownloadText = styled.div`
  display: inline-block;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: #4d4d4d;
  vertical-align: bottom;
`;
export const TopContentsLeft = styled.div`
  padding: 30px 0;
  display: inline-block;
  text-align: left;
  vertical-align: top;
`;

export const TopContentsRow = styled.div`
  display: flex;
  border-top: 1px solid #dedede;
  width: 100%;
  padding-bottom: 15px;
`;

export const TopLeftTitle = styled.div`
  font-family: 'Gosanja';
  font-size: 20px;
  background: #59a9da;
  border-radius: 5px;
  color: #fff;
  padding: 5px 20px;
  margin-right: 10px;
  white-space: nowrap;
`;

export const TopLeftText = styled.div`
  color: #4d4d4d;
  font-size: 26px;
  vertical-align: middle;
  word-break: break-all;
  font-weight: 500;
`;

export const TopLeftLineHeightText = styled.div`
  color: #4d4d4d;
  font-size: 26px;
  vertical-align: middle;
  word-break: break-all;
  font-weight: 500;
  line-height: 39px;
`;

export const TableIconInfoArea = styled.div`
  width: 100%;
  padding: 15px 15px 0px 15px;
  text-align: left;
  display: flex;
  flex-direction: row;
`;

export const TableIconInfoImg = styled.img`
  width: 22px;
  height: 22px;
  display: inline-block;
`;

export const TableIconInfoText = styled.div`
  vertical-align: middle;
  font-size: 12px;
  font-weight: 400;
  color: #4d4d4d;
  padding: 0px 25px 0px 5px;
  display: inline-block;
`;

export const DivIcon = styled.div`
  height: 20px;
  border: 1px solid #ddd;
  vertical-align: middle;
  display: inline-block;
`;

export const PlanAreaWrapper = styled.div`
  margin-bottom: 50px;
`;

export const PlanArea = styled.div<{ colorCd: string }>`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ccc;
  border-left: ${(props) => `15px solid ${props.colorCd}`};
  padding: 15px 10px;
  margin: 10px auto;
  text-align: left;
  vertical-align: middle;
`;
export const PlanTime = styled.div`
  width: 10%;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
`;
export const PlanTimeSub = styled.div`
  font-size: 14px;
`;
export const PlanTitle = styled.div<{ colorCd: string }>`
  width: 30%;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  font-family: 'Gosanja';
  color: ${(props) => `${props.colorCd}`};
  vertical-align: middle;
`;
export const PlanSubTitle = styled.div`
  font-size: 14px;
  color: #4d4d4d;
  margin-top: 5px;
  font-family: 'Noto Sans KR', sans-serif;
  vertical-align: middle;
`;
export const PlanTextArea = styled.div`
  width: 55%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px;
`;
export const PlanText = styled.textarea<{ height: number }>`
  font-size: 14px;
  width: 100%;
  margin: 6px;
  font-weight: 400;
  resize: none;
  border: 0;
  height: ${(props) => props.height}px;
  overflow: hidden;
  line-height: 26px;
`;

export const PlanData = styled.div`
  display: inline-block;
  width: 50%;
  margin-bottom: 10px;
  cursor: pointer;
  vertical-align: top;
`;
export const PlanDataTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
export const PlanDataTopText = styled.div`
  border: 1px solid #ddd;
  border-radius: 15px;
  color: #44b2d6;
  font-size: 16px;
  font-family: 'Gosanja';
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 17.5px;
`;
export const PlanDataTopImg = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;
export const PlanDataBottom = styled.div`
  font-size: 14px;
  margin: 10px;
  font-weight: 400;
`;

export const PlanNotFoundText = styled.div`
  display: flex;
  margin: 100px auto;
  color: #0087ed;
  font-size: 20px;
  display: inline-block;
  line-height: 32px;
  font-weight: 800;
`;

export const Holiday = styled.div`
  border-radius: 10px;
  border: 1px solid #ff6661;
  border-left: 15px solid #ff6661;
  margin-top: 10px;
  width: 100%;
  height: 150px;
  color: #e8320e;
  font-size: 50px;
  font-family: 'Gosanja';
  display: flex;
  justify-content: center;
  align-items: center;
`;

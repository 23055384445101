import React, { Dispatch, SetStateAction, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import RegisterSoundPresenter from './RegisterSoundPresenter';

interface RegisterSoundProps extends RouteComponentProps {
  option: {
    holder: string;
    subMenu: string;
    type: string;
    field: string;
    visible: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
  setIsModifying: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  programSounds: any;
  setProgramSounds: React.Dispatch<React.SetStateAction<any>>;
  thumbnail: any;
  setThumbnail: React.Dispatch<React.SetStateAction<any>>;
}

const RegisterSoundContainer = (props: RegisterSoundProps) => {
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files) => {
      props.setProgramSounds({
        ...props.programSounds,
        file: files[0],
      });
    },
  });
  const [hasVideo, setHasVideo] = useState<boolean>(false);
  const [isTune, setIsTune] = useState<boolean>(false);

  const addVideo = () => {
    let temps = props.programSounds.videos ? [...props.programSounds.videos] : [];
    temps.push({
      file: null,
      url: '',
      type: '',
      description: '',
    });
    props.setProgramSounds({
      ...props.programSounds,
      videos: temps,
    });
  };
  const deleteVideo = (index: number) => {
    let temps = props.programSounds.videos ? [...props.programSounds.videos] : [];
    temps.splice(index, 1);
    props.setProgramSounds({
      ...props.programSounds,
      videos: temps,
    });
  };

  return (
    <RegisterSoundPresenter
      {...props}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      open={open}
      isDragActive={isDragActive}
      hasVideo={hasVideo}
      setHasVideo={setHasVideo}
      isTune={isTune}
      setIsTune={setIsTune}
      addVideo={addVideo}
      deleteVideo={deleteVideo}
    />
  );
};

export default RegisterSoundContainer;

import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import * as recoilItem from '../../../../util/recoilitem';

import PrevPlanModalPresenter from './PrevPlanModalPresenter';

import { planApi } from '../../../../api/api-plan';


interface PrevPlanModalProps {
    type : string,

    yearList : number[];
    ageAuths: any;

    prevPlanData : any;
    setPrevPlanData : (arg0 : any) => void;
    setPrevPlanModalOn : (arg0 : boolean) => void;
}

const PrevPlanModalContainer = (props : PrevPlanModalProps) => {

    const token = useRecoilValue(recoilItem.token);
    const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

    const [age, setAge] = useState<string | null>(null);
    const [month, setMonth] = useState<number | null>(null);
    const [year, setYear] = useState<number | null>(null);

    const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    const [prevPlanList, setPrevPlanList] = useState<any>([]);
    const [prevPlan, setPrevPlan] = useState<any>({});

    const pagePostCount = 5;
    const [page, setPage] = useState<number>(1);
    const [nowPage, setNowPage] = useState<number>(0);
    const [pageList, setPageList] = useState<any>([]);


 
    const fetchData = async() => {
        try {
            setIsLoading(true);
            const res = props.type === 'monthly' ?
                await planApi.getPrevPlanMonth(token, age, year, page)
                : props.type === 'weekly' ?
                await planApi.getPrevPlanWeekly(token, age, month, year, page) : null;
            if(res && res.data.rsltCd === '00') {
                setPrevPlanList(res.data.prevPlanList.content);
                setPageList(res.data.pageList);
                setIsLoading(false);
            }

            setIsLoading(false);

        } catch(e) {
            console.log(e);
            setIsLoading(false);
        }
    };


    const onSetAge = (arg0 : string) => {
        setAge(arg0 === age ? null : arg0);
    };

    const onSetMonth = (arg0 : number) => {
        setMonth(arg0 === month ? null : arg0);
    };

    const onSetYear = (arg0 : number) => {
        setYear(arg0 === year ? null : arg0);
    };

    const onNextPage = () => {
        setPage((Math.floor((page - 1) / pagePostCount) + 1) * pagePostCount + 1);
        setNowPage(nowPage + 1);
    };

    const onPrevPage = () => {
        setPage((Math.floor((page - 1) / pagePostCount) - 1) * pagePostCount + 1);
        setNowPage(nowPage - 1);
    };


    const onSetPrevData = (prevPlanData : any) => {
        setPrevPlan(prevPlanData);
    };

    const onApplyPrevData = () => {
        props.setPrevPlanData({
            age : prevPlan.age.comnCd,
            year : prevPlan.year,
            week : prevPlan.week,
            month : prevPlan.month,
            isLoad: true,
        });
        props.setPrevPlanModalOn(false);
    };

    useEffect(() => {
        fetchData();
    }, [age, month, year, page])



    return (
        <PrevPlanModalPresenter
            type = {props.type}
            setPrevPlanModalOn = {props.setPrevPlanModalOn}

            ageAuths = {props.ageAuths}
            yearList = {props.yearList}

            age = {age}
            onSetAge = {onSetAge}
            month = {month}
            onSetMonth = {onSetMonth}
            year = {year}
            onSetYear = {onSetYear}

            monthList = {monthList}

            page = {page}
            nowPage = {nowPage}
            pagePostCount = {pagePostCount}
            setPage = {setPage}
            onNextPage = {onNextPage}
            onPrevPage = {onPrevPage}

            prevPlan = {prevPlan}
            prevPlanList = {prevPlanList}
            pageList = {pageList}

            onSetPrevData = {onSetPrevData}
            onApplyPrevData = {onApplyPrevData}
        />
    )
};

export default PrevPlanModalContainer;


import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1075px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const InputTable = styled.table`
  width: 1075px;
  border-top: 2px solid #0091ff;
  border-bottom: 2px solid #0091ff;
  margin: 30px auto 50px;
  border-spacing: 0px;
`;

export const Title = styled.div`
  width: 1075px;
  margin: 50px auto 0;
  font-size: 24px;
  font-weight: bold;
  color: #4d4d4d;
`;

export const InputTbody = styled.tbody``;

export const InputTr = styled.tr``;

export const InputTh = styled.th`
  width: 15%;
  padding: 5px 10px;
  text-align: right;
  font-size: 14px;
  color: #4d4d4d;
  font-weight: bold;
  border-right: 1px solid #dedede;
  border-top: 1px solid #dedede;
`;

export const InputTd = styled.td`
  width: 35%;
  padding: 5px 10px;
  text-align: left;
  font-size: 14px;
  color: #4d4d4d;
  border-top: 1px solid #dedede;
`;

export const InputInput = styled.input`
  padding: 5px 10px;
  font-size: 14px;
  color: #4d4d4d;
  placeholder-color: #cecece;
  border: 1px solid #ababab;
  width: 80%;
`;

export const InputSelect = styled.select`
  padding: 5px 10px;
  font-size: 14px;
  color: #4d4d4d;
  border: 1px solid #ababab;
  width: 80%;
`;

export const CreateButton = styled.div`
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #0091ff;
  cursor: pointer;
  margin: 0 auto 50px;
`;

export const UploadProgramAreaFooterTextArea = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const TextAreaInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

export const TextAreaIntoFileName = styled.div`
  text-align: right;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  color: #7a7a7a;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const TextAreaIntoText = styled.div`
  text-align: right;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  color: #7d7d7d;
`;

export const TextAreaInfoNotice = styled.div`
  text-align: right;
  letter-spacing: 1px;
  color: #b0b0b0;
  margin-top: 5px;
`;

export const TextAreaTextInput = styled.input`
  width: 85%;
  padding: 10px;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  ::placeholder {
    color: #e2e2e2;
  }
`;

export const UploadProgramAreaFileUploadArea = styled.div<{ isDrag: boolean }>`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  opacity: ${(props) => (props.isDrag ? 0.5 : 1)};
`;
export const UploadProgramAreaFileUploadAreaText = styled.div`
  font-size: 18px;
  color: #9c9c9c;
  font-weight: 600;
`;
export const UploadProgramAreaFileUploadAreaInput = styled.input``;
export const UploadProgramAreaFileUploadAreaButton = styled.div`
  padding: 10px 20px;
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
export const UploadProgramAreaFileUploadAreaButtonIcon = styled.img`
  width: auto;
  height: auto;
  cursor: pointer;
`;
export const UploadProgramAreaFileUploadAreaButtonText = styled.div``;

export const RemoveFileButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import DataPackagePresenter from './DataPackagePresenter';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { comnApi } from '../../api/api-comn';
import { programApi } from '../../api/api-program';
import * as recoilItem from '../../util/recoilitem';

interface DataPackageProps extends RouteComponentProps {}

const DataPackageContainer = (props: DataPackageProps) => {
  const token = useRecoilValue(recoilItem.token);
  const setProgramId = useSetRecoilState(recoilItem.programId);
  const userInfo = useRecoilValue(recoilItem.userInfo);

  const blockPageNum: string = '5';
  const pagePostCount: string = '10';

  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const [pageNum, setPageNum] = useState(1);
  const [pageList, setPageList] = useState([]);
  const [isFirst, setIsFirst] = useState(false);
  const [lastPageNum, setLastPageNum] = useState(1);
  const [isLast, setIsLast] = useState(false);

  const [programList, setProgramList] = useState([]);

  const firstFetchData = async () => {
    setIsLoading(true);

    let res1: any = null;
    let res2: any = null;
    let res3: any = null;

    let formData: FormData = new FormData();
    formData.append('topMenu', 'MN04');
    formData.append('subMenu', 'MN0407');
    formData.append('page', pageNum.toString());
    formData.append('blockPageNum', blockPageNum);
    formData.append('pagePostCount', pagePostCount);
    try {
      res1 = await comnApi.getCd('AUTH01');
      res2 = await comnApi.getCd('HOL01');
      res3 = await programApi.filter(token, formData);
      console.log(res3);
      if (res1.data.rsltCd === '00' && res2.data.rsltCd === '00') {
      }
      if (res3.data.rsltCd === '00') {
        let resultList = res3.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res3.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setProgramList(resultList);
        setIsFirst(res3.data.programList.first);
        setIsLast(res3.data.programList.last);
        setLastPageNum(res3.data.programList.totalPages);
        setPageList(res3.data.pageList);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    let res: any = null;

    let formData: FormData = new FormData();

    formData.append('topMenu', 'MN04');
    formData.append('subMenu', 'MN0407');
    formData.append('page', pageNum.toString());
    formData.append('blockPageNum', blockPageNum);
    formData.append('pagePostCount', pagePostCount);

    try {
      res = await programApi.filter(token, formData);
      if (res.data.rsltCd === '00') {
        let resultList = res.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setProgramList(resultList);
        setIsFirst(res.data.programList.first);
        setIsLast(res.data.programList.last);
        setLastPageNum(res.data.programList.totalPages);
        setPageList(res.data.pageList);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    firstFetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [pageNum]);

  return (
    <>
      <Header {...props} />
      <DataPackagePresenter
        token={token}
        pageNum={pageNum}
        setPageNum={setPageNum}
        pageList={pageList}
        lastPageNum={lastPageNum}
        isFirst={isFirst}
        isLast={isLast}
        programList={programList}
        userInfo={userInfo}
      ></DataPackagePresenter>
      <Footer {...props} />
    </>
  );
};

export default DataPackageContainer;

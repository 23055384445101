import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { userApi } from '../../../api/api-user';
import { programApi } from '../../../api/api-program';
import * as recoilItem from '../../../util/recoilitem';
import queryString from 'query-string';
import * as util from '../../../util/util';
import ProductDetailPresenter from './ProductDetailPresenter';
import { shoppingApi } from '../../../api/api-shopping';

interface Props extends RouteComponentProps {}

const ProductDetailContainer = (props: Props) => {
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const token = useRecoilValue(recoilItem.token);
  const userInfo = useRecoilValue(recoilItem.userInfo);

  const [itemInfo, setItemInfo] = useState<any>(null);
  const [selectedThumb, setSelectedThumb] = useState<string>('');
  const [itemCount, setItemCount] = useState<number>(1);
  const [activeFilter, setActiveFilter] = useState<number>(1);

  useEffect(() => {
    window.scrollTo(0, 0);
    let item: any = props.history.location.state;
    console.log(item);
    if (!item.item) {
      util.makeMsg('상품조회에 실패했습니다', 'error');
      props.history.push('/shopping/open');
    } else {
      setItemInfo({
        ...item.item,
        thumbnailList: item.item.thumbnailList.sort((a: any, b: any) => a.viewOrder - b.viewOrder),
        detailList: item.item.detailList.sort((a: any, b: any) => a.viewOrder - b.viewOrder),
      });
      setSelectedThumb(
        item.item.thumbnailList.sort((a: any, b: any) => a.viewOrder - b.viewOrder)[0].fileUrl,
      );
    }
  }, []);

  const addProductToCart = async () => {
    let params: FormData = new FormData();

    params.append('productId', itemInfo.productId);
    params.append('count', String(itemCount));

    let res = await shoppingApi.addToCart(token, params);
    if (res.data.rsltCd === '00') {
      util.makeMsg('장바구니에 상품을 담았습니다.', 'success');
    } else {
      util.makeMsg('데이터 처리에 실패하였습니다.', 'error');
    }
  };

  const orderProduct = async () => {
    let passItems = [];
    passItems.push({ ...itemInfo, count: itemCount, totalPrice: itemInfo.price * itemCount });
    console.log(passItems);
    props.history.push('/mypage/order', { passItems: passItems });
  };

  const goToEdit = async () => {
    let item = itemInfo;
    props.history.push('/shopping/open/update', { item: item });
  };

  return (
    <>
      <Header {...props} />
      <ProductDetailPresenter
        itemInfo={itemInfo}
        selectedThumb={selectedThumb}
        setSelectedThumb={setSelectedThumb}
        itemCount={itemCount}
        setItemCount={setItemCount}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        addProductToCart={addProductToCart}
        orderProduct={orderProduct}
        userInfo={userInfo}
        goToEdit={goToEdit}
      ></ProductDetailPresenter>
      <Footer {...props} />
    </>
  );
};

export default ProductDetailContainer;

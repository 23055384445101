import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 9990;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(100, 100, 100, 0.5);
`;

export const ModalContainer = styled.div`
  width: 960px;
  height: auto;
  margin: 0 auto;
  z-index: 9991;
  text-align: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 20px 0;
  overflow: auto;
  position: relative;
`;

export const test = styled.div`
  width: 100%;
  height: 200px;
  margin: 20px 0;
  background-color: blue;
`;

export const ModalHeaderArea = styled.div`
  display: flex;
  position: relative;
  width: 920px;
  margin: 0 auto;
  justify-content: left;
  background-color: #ffffff;
  border-bottom: 1px solid #0087ed;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const ModalHeaderMonth = styled.div`
  display: flex;
  height: 50px;
  color: #0087ed;
  font-size: 24px;
  font-family: 'Gosanja';
  justify-content: center;
  align-items: center;
`;

export const ModalHeaderPrintButton = styled.div`
  display: flex;
  position: absolute;
  right: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #7ab9e9;
  width: 128px;
  height: 34px;
  align-self: center;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
`;

export const ModalHeaderWeek = styled.div`
  display: flex;
  width: 150px;
  height: 40px;
  margin-top: 10px;
  color: #fff;
  font-size: 20px;
  font-family: 'Gosanja';
  justify-content: center;
  align-items: center;
  border-radius: 0 10px 0 0;
  background-color: #007cd9;
`;
export const ModalTopArea = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ModalTypeArea = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 20px 300px 10px;
`;

export const ModalLeftDownloadArea = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 20%;
`;

export const ModalType = styled.div`
  display: flex;
  height: 25px;
  margin: 0 5px;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
`;

export const ModalTypeImg = styled.img`
  width: 22px;
  height: 22px;
  object-fit: contain;
`;

export const ModalTypeText = styled.div`
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 600;
  margin: 0 5px;
`;

export const ModalMaterialArea = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 150px 20px;
`;

export const ModalMaterialTitleArea = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalMaterialTitleImg = styled.img`
  width: 46px;
  height: 43px;
  object-fit: contain;
`;

export const ModalMaterialTitleTextArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
`;

export const ModalMaterialTitleText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #4d4d4d;
`;

export const ModalMaterialTitleSubText = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #9b9b9b;
`;

export const ModalMaterialElementArea = styled.div`
  display: flex;
  margin-left: 20px;
`;

export const ModalMaterialElement = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  align-items: center;
  cursor: pointer;
`;

export const ModalMaterialElementImg = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 7px;
  object-fit: cover;
`;

export const ModalMaterialElementText = styled.div`
  margin-top: 5px;
  color: #4d4d4d;
  font-weight: 600;
  text-overflow: ellipsis;
`;

export const ModalDividingLine = styled.div`
  width: 75px;
  height: 2px;
  margin: 25px auto;
  background-color: #0087ed;
`;

export const ModalFeatureAreaRow = styled.div`
  display: flex;
  margin: 0 2%;
  justify-content: center;
  align-items: flex-end;
  vertical-align: middle;
  border-bottom: 1px solid #eaeaea;
  padding: 1.5%;
`;

export const ModalFeatureAreaRowLast = styled.div`
  display: flex;
  margin: 0 2%;
  justify-content: center;
  align-items: flex-end;
  vertical-align: middle;
  padding: 1.5%;
`;

export const ModalFeatureArea = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  margin: 0 2.5%;
  border: 1px solid #eaeaea;
  border-radius: 5px;
`;

export const ModalFeature = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalFeatureTitle = styled.div`
  display: flex;
  flex: 2;
  font-size: 14px;
  color: #6d6d6d;
  font-family: 'Gosanja';
  align-items: center;
  justify-content: flex-start;
`;

export const ModalFeatureContent = styled.div`
  display: flex;
  flex: 10;
  font-size: 14px;
  margin: 0 auto;
  color: #4d4d4d;
  justify-content: flex-start;
`;

export const ModalFeatureDividingLine = styled.div`
  width: 2px;
  height: 40px;
  margin: auto 0;
  background-color: #e2e2e2;
`;

export const ModalExpectationArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalExpectationTitle = styled.div`
  display: flex;
  width: 120px;
  height: 30px;
  font-size: 16px;
  color: #fff;
  font-family: 'Gosanja';
  margin: 20px auto 15px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background-color: #00dda0;
`;

export const ModalDecorationDot = styled.div`
  width: 7px;
  height: 7px;
  margin: 4px 10px 0;
  border-radius: 100%;
  background-color: #0087ed;
`;

export const ModalExpectationContentArea = styled.div`
  display: flex;
  justify-content: left;
  margin: 5px 10px;
`;

export const ModalExpectationContent = styled.div`
  width: 400px;
  font-size: 15px;
  color: #4d4d4d;
  text-align: left;
`;

export const ModalFactorArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalFactorTitle = styled.div`
  display: flex;
  width: 165px;
  height: 30px;
  font-size: 16px;
  color: #fff;
  font-family: 'Gosanja';
  margin: 20px auto 15px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background-color: #00dda0;
`;

export const ModalFactorContentArea = styled.div`
  display: flex;
  justify-content: left;
  margin: 5px 10px;
`;

export const ModalFactorContent = styled.div`
  width: 400px;
  font-size: 15px;
  color: #4d4d4d;
  text-align: left;
`;

export const ModalMethodArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalMethodTitle = styled.div`
  display: flex;
  width: 120px;
  height: 30px;
  font-size: 16px;
  color: #fff;
  font-family: 'Gosanja';
  margin: 20px auto 15px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background-color: #ff7917;
`;

export const ModalMethodContentArea = styled.div`
  display: flex;
  justify-content: left;
  margin: 5px 10px;
`;

export const ModalMethodContent = styled.div`
  width: 380px;
  font-size: 15px;
  color: #4d4d4d;
  text-align: left;
`;

export const ModalMethodSubContent = styled.div`
  width: 350px;
  font-size: 14px;
  color: #4d4d4d;
  text-align: left;
  margin: 5px 0;
`;

export const ModalBigDividingLine = styled.div`
  width: 100%;
  height: 1.5px;
  margin: 20px 0;
  background-color: #e2e2e2;
`;

export const ModalEtcRow = styled.div`
  display: flex;
  position: absolute;
  justify-content: flex-end;
  align-items: flex-end;
  width: 950px;
  background-color: #dedede;
  top: 0px;
  left: 0px;
`;

export const ModalEtcArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
`;

export const ModalNoteTitle = styled.div`
  display: flex;
  width: 120px;
  height: 30px;
  font-size: 16px;
  color: #fff;
  font-family: 'Gosanja';
  margin: 0 auto 15px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background-color: #ff6643;
`;

export const ModalExtensionTitle = styled.div`
  display: flex;
  width: 120px;
  height: 30px;
  font-size: 16px;
  color: #fff;
  font-family: 'Gosanja';
  margin: 0 auto 15px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background-color: #ff7917;
`;

export const ModalEtcContent = styled.div`
  font-size: 15px;
  color: #4d4d4d;
  margin: 5px 10px;
`;

export const ModalEvaluationArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 650px;
  margin: 0 auto;
`;

export const ModalPlayEvaluationArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  border-bottom: 2px solid #ff9f3a;
  padding: 10px 20px 30px;
`;
export const ModalPlayEvaluationTitleArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  padding: 20px 0 10px;
`;
export const ModalPlayEvaluationContentArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const ModalPlayEvaluationTitle = styled.div`
  display: flex;
  width: 80px;
  height: 25px;
  font-size: 13px;
  color: #fff;
  font-family: 'Gosanja';
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background-color: #ff9f3a;
`;
export const ModalPlayEvaluationContent = styled.div`
  font-size: 14px;
  color: #4d4d4d;
  text-align: left;
  line-height: 20px;
`;

export const ModalEvaluationTitle = styled.div`
  display: flex;
  width: 120px;
  height: 30px;
  font-size: 16px;
  color: #fff;
  font-family: 'Gosanja';
  margin: 0 auto 15px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background-color: #0087ed;
`;

export const ModalButton = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  color: #4d4d4d;
  font-size: 20px;
  font-family: 'Gosanja';
  margin: 0px 5px 0px 0px;
  align-items: center;
  justify-content: center;
  padding: 2px;
  cursor: pointer;
`;

export const ModalTabArea = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 20px auto 0px;
  border-bottom: 1px solid ${(props) => props.color};
  align-items: flex-end;
`;

export const ModalTab = styled.div<{ color: string; width: number; current: boolean }>`
  background-color: ${(props) => props.color};
  width: ${(props) => (props.current ? props.width + 2 + '%' : props.width + '%')};
  height: auto;
  padding: ${(props) => (props.current ? '15px' : '10px')};
  color: #fff;
  font-family: 'Gosanja';
  font-size: ${(props) => (props.current ? '16px' : '14px')};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  letter-spacing: 1px;
  cursor: pointer;
`;

export const ModalContenArea = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: flex-start;
  margin: 0 auto;
  padding: 30px 0;
  border-bottom: 2px solid ${(props) => props.color};
`;

export const ModalContentText = styled.div`
  display: flex;
`;

export const ModalContextTable = styled.table`
  width: 920px;
  margin: 0px 20px;
  height: auto;
  border-top: 1px solid #0087ed;
  border-bottom: 1px solid #0087ed;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const ModalContextTr = styled.tr`
  width: 100%;
  height: auto;
  border-bottom: 1px solid #aac3f4;
  margin: 0px;
`;

export const ModalContextTh = styled.th`
  width: 111px;
  padding: 10px 15px;
  background-color: #d8eeff;
  font-size: 12px;
  color: #4d4d4d;
`;

export const ModalContextTd = styled.td`
  font-size: 14px;
  color: #4d4d4d;
  text-align: left;
  padding: 10px 15px;
`;

export const ModalContextTdText = styled.p`
  padding: 4px 0px;
`;

export const TdTextArea = styled.textarea`
  border: 0;
  resize: none;
  font-size: 14px;
  color: #4d4d4d;
  text-align: left;
  width: 100%;
`;

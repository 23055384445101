import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import OrderPresenter from './OrderPresenter';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import * as recoilItem from '../../../util/recoilitem';
import queryString from 'query-string';
import * as util from '../../../util/util';
import { comnApi } from '../../../api/api-comn';
import { shoppingApi } from '../../../api/api-shopping';

interface OrderProps extends RouteComponentProps {}

const OrderContainer = (props: OrderProps) => {
  const token = useRecoilValue(recoilItem.token);

  const [page, setPage] = useState<number>(1);
  const [ageList, setAgeList] = useState([]);
  const [holderList, setHolderList] = useState([]);
  const [productList, setProductList] = useState<any[]>([]);
  const [selectedIdxList, setSelectedIdxList] = useState<any[]>([]);

  const [selectedAge, setSelectedAge] = useState<{ comnCd: string; comnCdNm: string }>({
    comnCd: 'AUTH0101',
    comnCdNm: '1세',
  });

  const [selectedHolderList, setSelectedHolderList] = useState<
    { comnCd: string; comnCdNm: string }[]
  >([]);

  const onChangeAge = (item: any) => {
    setSelectedAge({ comnCd: item.comnCd, comnCdNm: item.comnCdNm });
    setPage(1);
  };

  const onChangeHolderList = (item: any) => {
    if (!item) {
      setSelectedHolderList([]);
      return;
    }
    const holder = { comnCd: item.comnCd, comnCdNm: item.comnCdNm };

    // let tempHolderList = [...selectedHolderList];

    // if (tempHolderList.map((item: any) => item.comnCd).includes(holder.comnCd)) {
    //   const idx: number = tempHolderList.map((item: any) => item.comnCd).indexOf(holder.comnCd);
    //   tempHolderList.splice(idx, 1);
    // } else {
    //   tempHolderList.push(holder);
    // }

    // setSelectedHolderList(tempHolderList);
    setSelectedHolderList([holder]);
    setPage(1);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let res1 = await comnApi.getCd('AUTH01');
    if (res1.data.rsltCd === '00') {
      setAgeList(res1.data.ComnCd.subCdList);
    }

    let searchForm = new FormData();

    let res2 = await shoppingApi.getList(token, searchForm);
    setProductList(res2.data.productList);
  };

  const checkOrder = async () => {
    let passProductList = [];
    for (let i = 0; i < productList.length; i++) {
      if (selectedIdxList.map((it: any) => it.productId).includes(productList[i].productId)) {
        passProductList.push({
          ...productList[i],
          count: selectedIdxList.find((item: any) => item.productId === productList[i].productId)
            .count,
        });
      }
    }

    props.history.push({
      pathname: '/shopping/order/register',
      state: { orderProductList: passProductList },
    });
  };

  const addToList = async (item: any, id: any) => {
    console.log('add To List');
    if (!item.productId || !$(id).val()) {
      util.makeMsg('수량은 필수항목입니다.', 'error');
    } else if (selectedIdxList.map((it: any) => it.productId).includes(item.productId)) {
      let tempList = [...selectedIdxList];
      tempList = tempList.filter((element) => element.productId !== item.productId);
      setSelectedIdxList(tempList);
    } else {
      let tempList = [...selectedIdxList];
      tempList.push({
        productId: item.productId,
        count: $(id).val(),
      });
      setSelectedIdxList(tempList);
    }
  };

  return (
    <>
      <Header {...props} />
      <OrderPresenter
        page={page}
        setPage={setPage}
        ageList={ageList}
        holderList={holderList}
        selectedAge={selectedAge}
        setSelectedAge={setSelectedAge}
        selectedHolderList={selectedHolderList}
        onChangeAge={onChangeAge}
        onChangeHolderList={onChangeHolderList}
        productList={productList}
        selectedIdxList={selectedIdxList}
        setSelectedIdxList={setSelectedIdxList}
        checkOrder={checkOrder}
        addToList={addToList}
      ></OrderPresenter>
      <Footer {...props} />
    </>
  );
};

export default OrderContainer;

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import Header from '../../components/header';
import Footer from '../../components/footer';
import { userApi } from '../../api/api-user';
import { programApi } from '../../api/api-program';
import * as recoilItem from '../../util/recoilitem';
import queryString from 'query-string';
import * as util from '../../util/util';
import { shoppingApi } from '../../api/api-shopping';
import PartnersPresenter from './PartnersPresenter';

interface PartnersProps extends RouteComponentProps {}

const PartnersContainer = (props: PartnersProps) => {
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const token = useRecoilValue(recoilItem.token);
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);

  const [mainItems, setMainItems] = useState<any>([]);

  useEffect(() => {
    let items = [];
    items.push({ name: '꿈바푸토', photo: '/static/img/shop_1.png', price: 30000, link: 'MN0203' });
    items.push({ name: '코몽스몽', photo: '/static/img/shop_2.png', price: 30000, link: 'MN0201' });
    items.push({ name: '아트몽', photo: '/static/img/shop_3.png', price: 30000, link: 'MN0207' });
    items.push({ name: '슈필매스', photo: '/static/img/shop_4.png', price: 30000, link: 'MN0208' });
    items.push({
      name: '말랑말랑과학',
      photo: '/static/img/shop_5.png',
      price: 30000,
      link: 'MN0214',
    });
    items.push({ name: '엔젤쁘레', photo: '/static/img/shop_6.png', price: 30000, link: 'MN0206' });
    items.push({ name: '쁘레놀이', photo: '/static/img/shop_7.png', price: 30000, link: 'MN0205' });
    items.push({
      name: '한글수학팡팡',
      photo: '/static/img/shop_8.png',
      price: 30000,
      link: 'MN0211',
    });
    items.push({ name: '틴토', photo: '/static/img/shop_9.png', price: 30000, link: 'MN0212' });
    items.push({
      name: '헬로프로젝트',
      photo: '/static/img/shop_10.png',
      price: 30000,
      link: 'MN0210',
    });
    setMainItems(items);
  }, []);

  useEffect(() => {
    if (userInfo.userTypeCd !== 'MASTER') {
      util.makeMsg('추후 오픈 예정입니다.', 'warning');
      props.history.push('/')
    }
  }, [])

  const linkTo = (to: string) => {
    props.history.push(to);
  };

  return (
    <>
      <Header {...props} />
      <PartnersPresenter mainItems={mainItems} linkTo={linkTo}></PartnersPresenter>
      <Footer {...props} />
    </>
  );
};

export default PartnersContainer;

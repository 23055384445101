import React from 'react';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { RouteComponentProps } from 'react-router-dom';

import * as s from './RegisterActsheetStyled';

interface RegisterActsheetProps extends RouteComponentProps {
  option: {
    holder: string;
    subMenu: string;
    type: string;
    field: string;
    visible: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  open: () => void;
  acceptedFiles: File[];
  isDragActive: boolean;
  isModifying: boolean;
  setIsModifying: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  programImages: any[];
  setProgramImages: React.Dispatch<React.SetStateAction<any[]>>;
  thumbnail: any;
  setThumbnail: React.Dispatch<React.SetStateAction<any>>;
}

const RegisterActsheetPresenter = (props: RegisterActsheetProps) => {
  return (
    <>
      <s.UploadProgramAreaHeader>
        <s.UploadProgramAreaHeaderSelect>
          <s.UploadProgramAreaHeaderLabel>제목</s.UploadProgramAreaHeaderLabel>
          <s.UploadProgramAreaHeaderInput
            value={props.title}
            onChange={(e) => props.setTitle(e.target.value)}
            placeholder={'텍스트를 입력해주세요.'}
            disabled={!props.isModifying}
          />
        </s.UploadProgramAreaHeaderSelect>
      </s.UploadProgramAreaHeader>
      <s.UploadProgramAreaFileUploadArea
        isDrag={props.isDragActive}
        {...props.getRootProps({ className: 'dropzone' })}
      >
        <s.UploadProgramAreaFileUploadAreaInput {...props.getInputProps()} />
        {props.programImages.length > 0 ? (
          <s.UploadProgramAreaFileUploadAreaButtonIcon
            onClick={props.open}
            src="/static/img/pdf_icon_1.png"
          />
        ) : null}
        <s.UploadProgramAreaFileUploadAreaText>
          {props.programImages.length > 0
            ? props.programImages.length === 1
              ? props.programImages[0].name
              : props.programImages[0].name + ' 외 ' + (props.programImages.length - 1) + '개'
            : '파일을 선택해주세요.'}
        </s.UploadProgramAreaFileUploadAreaText>
        <s.UploadProgramAreaFileUploadAreaButtonIcon
          onClick={props.open}
          src={
            props.programImages.length > 0
              ? '/static/img/file_exist.png'
              : '/static/img/selectFile.png'
          }
        />
      </s.UploadProgramAreaFileUploadArea>
      <s.UploadProgramAreaFooter>
        <s.UploadProgramAreaFooterTextArea>
          <s.TextAreaInfo>
            <s.TextAreaIntoText>프로그램 소개 내용</s.TextAreaIntoText>
            <s.TextAreaInfoNotice>(최대 0000글자만 작성 가능)</s.TextAreaInfoNotice>
          </s.TextAreaInfo>
          <s.TextAreaTextInputWrapper>
            <s.TextAreaTextInput
              placeholder="텍스트를 입력해주세요."
              value={props.description}
              onChange={(e) => props.setDescription(e.target.value)}
              disabled={!props.isModifying}
            />
          </s.TextAreaTextInputWrapper>
        </s.UploadProgramAreaFooterTextArea>
      </s.UploadProgramAreaFooter>
    </>
  );
};

export default RegisterActsheetPresenter;

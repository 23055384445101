import React, { useState, useEffect } from 'react';
import * as recoilItem from '../../util/recoilitem';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

import { planApi } from '../../api/api-plan';
import PlanModalPresenter from './PlanModalPresenter';
import { saveAs } from 'file-saver';

interface PlanModalProps {}

const PlanModalContainer = (props: PlanModalProps) => {
  const token = useRecoilValue(recoilItem.token);
  const [planId, setPlanId] = useRecoilState(recoilItem.planId);
  const [programId, setProgramId] = useRecoilState(recoilItem.programId);
  const setProgramSubId = useSetRecoilState(recoilItem.programSubId);

  const [plan, setPlan] = useState<any>(null);

  const [currentTab, setCurrentTab] = useState<string>('expectation');
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);

  const cancel = () => {
    setPlanId(null);
    setPlan(null);
  };

  const onClickProgram = (material: any) => {
    if (material.file != null) {
      saveAs(material.file.fileUrl, material.file.originalFileName);
    } else {
      setProgramSubId(material.subId);
      setProgramId(material.program.programId);
    }
  };

  const fetchData = async () => {
    try {
      if (planId !== null) {
        setIsLoading(true);
        await planApi.getActivityPlan(token, planId).then((res) => {
          console.log(res.data.activity);
          if (res.data.rsltCd === '00') {
            setPlan(res.data.activity);
          }
        });
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [planId]);

  return (
    <PlanModalPresenter
      plan={plan}
      planId={planId}
      programId={programId}
      setProgramId={setProgramId}
      cancel={cancel}
      onClickProgram={onClickProgram}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
    ></PlanModalPresenter>
  );
};

export default PlanModalContainer;

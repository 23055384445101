import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useDropzone } from 'react-dropzone';
import * as s from '../RegisterSoundStyled';

interface SheetProps {
  isModifying: boolean;
  submitFile: File;
  setSubmitFile: (file: File) => void;
}

const Sheet = (props: SheetProps) => {
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files: File[]) => {
      props.setSubmitFile(files[0]);
    },
  });
  return (
    <>
      <s.UploadProgramAreaTextHeader>악보 업로드</s.UploadProgramAreaTextHeader>
      <s.UploadProgramAreaFileUploadArea
        isDrag={isDragActive}
        {...getRootProps({ className: 'dropzone' })}
      >
        <s.UploadProgramAreaFileUploadAreaInput {...getInputProps()} />
        {props.submitFile ? (
          <s.UploadProgramAreaFileUploadAreaButtonIcon
            onClick={open}
            src="/static/img/pdf_icon_1.png"
          />
        ) : null}
        <s.UploadProgramAreaFileUploadAreaText>
          {props.submitFile ? props.submitFile.name : '파일을 선택해주세요.'}
        </s.UploadProgramAreaFileUploadAreaText>
        <s.UploadProgramAreaFileUploadAreaButtonIcon
          onClick={open}
          src={props.submitFile ? '/static/img/file_exist.png' : '/static/img/selectFile.png'}
        />
      </s.UploadProgramAreaFileUploadArea>
    </>
  );
};

export default Sheet;

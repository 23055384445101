export const API_SERVER =
  process.env.NODE_ENV === 'production'
    ? 'https://api.habicastle.com'
    : process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080'
    : 'http://localhost:8080';

// export const API_SERVER = 'https://api.habicastle.com';

export const iamport_merchant_uid = 'imp37033765';

export const iamport_rest_key = '0126124000035458';

export const iamport_api_secret =
  'crpM2LJ1C5o8iABdS6iFIJWsgJL04dqVRsGQaurx1DINQQwsKhgcX5r8Rq0Fq1DThPnT3saH0vuOUK3J';

import React, { Dispatch, SetStateAction } from 'react';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { RouteComponentProps } from 'react-router-dom';

import { FiPlusCircle } from 'react-icons/fi';
import BeatLoader from 'react-spinners/BeatLoader';
import * as s from './RegisterHomeletterStyled';

interface RegisterHomeletterProps extends RouteComponentProps {
  option: {
    age: string | null;
    holder: string;
    topMenu: string;
    subMenu: string;
    type: string;
    field: string;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      holder: string;
      topMenu: string;
      subMenu: string;
      type: string;
      field: string;
    }>
  >;
  ageAuths: any;
  holderList: any;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  open: () => void;
  acceptedFiles: File[];
  isDragActive: boolean;
  isModifying: boolean;
  setIsModifying: React.Dispatch<React.SetStateAction<boolean>>;
  submitFileList: File[];
  subMenuList: {
    menuCd: string;
    menuNm: string;
    viewOrder: number;
  }[];
  submit: () => Promise<void>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;

  isUrlUpload: boolean;
  setIsUrlUpload: Dispatch<SetStateAction<boolean>>;
  fileUrl: string;
  setFileUrl: Dispatch<SetStateAction<string>>;
}

const RegisterHomeletterPresenter = (props: RegisterHomeletterProps) => {
  return (
    <s.Container>
      <s.TitleArea>
        <s.Title>
          만{' '}
          <s.TitleSpan>
            {!props.ageAuths || props.ageAuths.length === 0
              ? null
              : props.option.age
              ? props.ageAuths[props.option.age].comnCdNm
              : null}
          </s.TitleSpan>{' '}
          <s.TitleSpan>
            {!props.holderList || props.holderList.length === 0
              ? null
              : props.holderList[props.option.holder].comnCdNm}
          </s.TitleSpan>{' '}
          가정통신문
        </s.Title>
      </s.TitleArea>

      <s.UploadArea>
        <s.UploadAreaHeader>
          <s.UploadAreaHeaderLeft>업로드</s.UploadAreaHeaderLeft>
          <s.UploadAreaHeaderRight>
            <s.UploadAreaHeaderRightIcon src="/static/img/warning.png" />
            <s.UploadAreaHeaderRightText>ZIP파일은 업로드 불가입니다.</s.UploadAreaHeaderRightText>
          </s.UploadAreaHeaderRight>
        </s.UploadAreaHeader>
        <s.UploadContentArea>
          <s.UploadContentAreaHeader>
            <s.UploadContentAreaHeaderLeft>
              <s.UploadContentAreaHeaderIcon
                src={!props.isModifying ? '/static/img/pen.png' : '/static/img/red_pen.png'}
              />
              <s.UploadContentAreaHeaderLeftText
                onClick={() => (!props.isModifying ? props.setIsModifying(true) : null)}
                isColor={!props.isModifying}
              >
                수정
              </s.UploadContentAreaHeaderLeftText>
            </s.UploadContentAreaHeaderLeft>
            <s.UploadContentAreaHeaderRight>
              {false ? (
                <BeatLoader size={8} color={'#acacac'} />
              ) : (
                <>
                  <s.UploadContentAreaHeaderIcon
                    src={
                      props.isModifying ? '/static/img/check.png' : '/static/img/green_check.png'
                    }
                  />
                  <s.UploadContentAreaHeaderRightText
                    onClick={() => (props.isModifying ? props.setIsModifying(false) : null)}
                    isColor={props.isModifying}
                  >
                    완료
                  </s.UploadContentAreaHeaderRightText>
                </>
              )}
            </s.UploadContentAreaHeaderRight>
          </s.UploadContentAreaHeader>
          <s.UploadProgramArea>
            <s.UploadProgramAreaHeader>
              <s.UploadProgramAreaHeaderSelect>
                <s.UploadProgramAreaHeaderLabel>제목</s.UploadProgramAreaHeaderLabel>
                <s.UploadProgramAreaHeaderInput
                  value={props.title}
                  onChange={(e) => props.setTitle(e.target.value)}
                  placeholder={'텍스트를 입력해주세요.'}
                  disabled={!props.isModifying}
                />
                <s.UploadProgramAreaSelectType
                  id={'isUrlUpload'}
                  defaultChecked={false}
                  onChange={(e) => {
                    props.setIsUrlUpload(e.target.checked);
                  }}
                  type={'checkbox'}
                />
                <s.UploadProgramAreaSelectTypeLabel htmlFor={'isUrlUpload'}>
                  {props.isUrlUpload ? (
                    <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                  ) : null}
                </s.UploadProgramAreaSelectTypeLabel>
                <s.UploadProgramAreaSelectText htmlFor={'isUrlUpload'}>
                  URL업로드
                </s.UploadProgramAreaSelectText>
              </s.UploadProgramAreaHeaderSelect>
            </s.UploadProgramAreaHeader>
            {!props.isUrlUpload ? (
              <>
                <s.UploadProgramAreaFileUploadArea
                  isDrag={props.isDragActive}
                  {...props.getRootProps({ className: 'dropzone' })}
                >
                  <s.UploadProgramAreaFileUploadAreaInput {...props.getInputProps()} />
                  {props.submitFileList.length > 0 ? (
                    <s.UploadProgramAreaFileUploadAreaButtonIcon
                      onClick={props.open}
                      src="/static/img/pdf_icon_1.png"
                    />
                  ) : null}
                  <s.UploadProgramAreaFileUploadAreaText>
                    {props.submitFileList.length > 0
                      ? props.submitFileList.length === 1
                        ? props.submitFileList[0].name
                        : props.submitFileList[0].name +
                          ' 외 ' +
                          (props.submitFileList.length - 1) +
                          '개'
                      : '파일을 선택해주세요.'}
                  </s.UploadProgramAreaFileUploadAreaText>
                  <s.UploadProgramAreaFileUploadAreaButtonIcon
                    onClick={props.open}
                    src={
                      props.submitFileList.length > 0
                        ? '/static/img/file_exist.png'
                        : '/static/img/selectFile.png'
                    }
                  />
                </s.UploadProgramAreaFileUploadArea>
              </>
            ) : (
              <>
                <s.UploadProgramAreaFileUploadArea
                  isDrag={false}
                  {...props.getRootProps({ className: 'dropzone' })}
                >
                  <s.UploadMediaUrlInput
                    type="text"
                    placeholder="URL을 입력하세요."
                    value={props.fileUrl}
                    onChange={(e: any) => {
                      props.setFileUrl(e.target.value);
                    }}
                  />
                </s.UploadProgramAreaFileUploadArea>
              </>
            )}

            <s.UploadProgramAreaFooter>
              <s.UploadProgramAreaHeaderSelect>
                {props.subMenuList.map((menu: any) => (
                  <>
                    <s.UploadProgramAreaSelectType
                      id={menu.menuCd}
                      value={menu.menuCd}
                      defaultChecked={props.option.subMenu === menu.menuCd}
                      onChange={(e) =>
                        props.option.subMenu === e.target.value
                          ? props.setOption({ ...props.option, subMenu: '' })
                          : props.setOption({ ...props.option, subMenu: e.target.value })
                      }
                      type={'checkbox'}
                      disabled={!props.isModifying}
                    />
                    <s.UploadProgramAreaSelectTypeLabel htmlFor={menu.menuCd}>
                      {props.option.subMenu === menu.menuCd ? (
                        <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                      ) : null}
                    </s.UploadProgramAreaSelectTypeLabel>
                    <s.UploadProgramAreaSelectText htmlFor={menu.menuCd}>
                      {menu.menuNm}
                    </s.UploadProgramAreaSelectText>
                  </>
                ))}
              </s.UploadProgramAreaHeaderSelect>
            </s.UploadProgramAreaFooter>
          </s.UploadProgramArea>
          <s.ButtonArea>
            <s.SubmitButton onClick={props.submit}>
              <s.SubmitButtonText>업로드</s.SubmitButtonText>
            </s.SubmitButton>
          </s.ButtonArea>
        </s.UploadContentArea>
      </s.UploadArea>
    </s.Container>
  );
};

export default RegisterHomeletterPresenter;

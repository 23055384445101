import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useDropzone } from 'react-dropzone';
import * as s from '../RegisterSoundStyled';

interface LyricsProps {
  isModifying: boolean;
  submitFileList: File[];
  setSubmitFileList: React.Dispatch<React.SetStateAction<File[]>>;
}

const Lyrics = (props: LyricsProps) => {
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files: File[]) => {
      props.setSubmitFileList(files);
    },
  });
  return (
    <s.UploadProgramArea>
      <s.UploadProgramAreaTextHeader>가사 업로드</s.UploadProgramAreaTextHeader>
      <s.UploadProgramAreaFileUploadArea
        isDrag={isDragActive}
        {...getRootProps({ className: 'dropzone' })}
      >
        <s.UploadProgramAreaFileUploadAreaInput {...getInputProps()} />
        {props.submitFileList.length > 0 ? (
          <s.UploadProgramAreaFileUploadAreaButtonIcon
            onClick={open}
            src="/static/img/pdf_icon_1.png"
          />
        ) : null}
        <s.UploadProgramAreaFileUploadAreaText>
          {props.submitFileList.length > 0
            ? props.submitFileList.length === 1
              ? props.submitFileList[0].name
              : props.submitFileList[0].name + ' 외 ' + (props.submitFileList.length - 1) + '개'
            : '파일을 선택해주세요.'}
        </s.UploadProgramAreaFileUploadAreaText>
        <s.UploadProgramAreaFileUploadAreaButtonIcon
          onClick={open}
          src={
            props.submitFileList.length > 0
              ? '/static/img/file_exist.png'
              : '/static/img/selectFile.png'
          }
        />
      </s.UploadProgramAreaFileUploadArea>
    </s.UploadProgramArea>
  );
};

export default Lyrics;

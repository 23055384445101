import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import RegisterPresenter from './RegisterPresenter';
import * as Yup from 'yup';
import * as util from '../../util/util';
import Header from '../../components/header';
import Footer from '../../components/footer';
import * as recoilItem from '../../util/recoilitem';

import { agenApi } from '../../api/api-agen';
import { compApi } from '../../api/api-comp';
import { userApi } from '../../api/api-user';
import { useRecoilValue, useRecoilState } from 'recoil';

interface RegisterProps extends RouteComponentProps {}

const RegisterContainer = (props: RegisterProps) => {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [passwordconf, setPasswordConf] = useState('');
  const [name, setName] = useState('');
  const [userType, setUserType] = useState('');
  const [agenId, setAgenId] = useState('');
  const [agenNm, setAgenNm] = useState('');
  const [agenAddr, setAgenAddr] = useState('');
  const [agenContact, setAgenContact] = useState('');
  const [compId, setCompId] = useState('');
  const [compCd, setCompCd] = useState('');
  const [compNm, setCompNm] = useState('');
  const [compAddr, setCompAddr] = useState('');

  const [userList, setUserList] = useState<string[]>([]);

  const [authList, setAuthList] = useState<
    {
      age1: boolean;
      age2: boolean;
      age3: boolean;
      age4: boolean;
      age5: boolean;
      userSize: number;
    }[]
  >([
    {
      age1: false,
      age2: false,
      age3: false,
      age4: false,
      age5: false,
      userSize: 0,
    },
  ]);

  const [keyWord, setKeyWord] = useState('');
  const [keyWordForSearch, setKeyWordForSearch] = useState('');

  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [pageNum, setPageNum] = useState(1);
  const [pageList, setPageList] = useState([]);
  const [isFirst, setIsFirst] = useState(false);
  const [lastPageNum, setLastPageNum] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [totalItemLength, setTotalItemLength] = useState(0);

  const [agenList, setAgenList] = useState(null);
  const [compList, setCompList] = useState(null);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const token = useRecoilValue(recoilItem.token);

  const registerAdminFormSchema = Yup.object().shape({
    compId: Yup.string().required(),
  });

  const addCompanyFormSchema = Yup.object().shape({
    compCd: Yup.string().required(),
    compNm: Yup.string().required(),
    compAddr: Yup.string().required(),
  });

  const onChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setId(e.target.value);
  };

  const onChangePw = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onChangePwConf = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConf(e.target.value);
  };

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onChangeKeyWord = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyWord(e.target.value);
  };

  const onChangeAgenNm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgenNm(e.target.value);
  };
  const onChangeAgenAddr = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgenAddr(e.target.value);
  };
  const onChangeAgenContact = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgenContact(e.target.value);
  };

  const onChangeCompCd = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompCd(e.target.value);
  };
  const onChangeCompNm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompNm(e.target.value);
  };
  const onChangeCompAddr = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompAddr(e.target.value);
  };

  const checkHandler = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let temps = [...authList];
    temps[index].userSize = parseInt(e.target.value);
    setAuthList(temps);
  };

  const addAuthList = (e: React.MouseEvent<HTMLDivElement>) => {
    let newItem = {
      age1: false,
      age2: false,
      age3: false,
      age4: false,
      age5: false,
      userSize: 0,
    };
    let temps = [...authList, newItem];
    setAuthList(temps);
  };

  const selectAgency = (agenId: string, agenNm: string) => {
    setAgenId(agenId);
    setAgenNm(agenNm);
    setIsSearchModalOpen(false);
  };

  const selectCompany = (compId: string, compNm: string) => {
    setCompId(compId);
    setCompNm(compNm);
    setIsSearchModalOpen(false);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const onSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearchSubmit();
    }
  };

  const onAddKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onAddSubmit();
    }
  };

  const onClickAdmin = () => {
    setUserType('ADMIN');
  };

  const onClickUser = () => {
    setUserType('USER');
  };

  const openSearchModal = () => {
    if (userType === '') {
      util.makeMsg('유저 타입을 선택해주세요.', 'error');
    } else {
      refreshCompanyList();
      setIsSearchModalOpen(true);
    }
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const switchModal = () => {
    if (isSearchModalOpen) {
      setIsSearchModalOpen(false);
      setIsAddModalOpen(true);
    } else if (isAddModalOpen) {
      setIsAddModalOpen(false);
      setIsSearchModalOpen(true);
    }
  };

  const refreshCompanyList = () => {
    setKeyWordForSearch('');
    setPageNum(1);
    fetchData();
  };

  const fetchData = async () => {
    let res: any = null;

    try {
      setIsLoading(true);
      res = await compApi.list(token, keyWordForSearch, pageNum);
      if (res.data.rsltCd === '00') {
        let resultList = res.data.companyList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res.data.companyList.pageable.pageSize + index + 1;
          return item;
        });
        setCompList(resultList);
        setIsFirst(res.data.companyList.first);
        setIsLast(res.data.companyList.last);
        setLastPageNum(res.data.companyList.totalPages);
        setPageList(res.data.pageList);
        setTotalItemLength(res.data.companyList.totalElements);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const popUpError = () => {
    util.makeMsg('생성할 유저의 수는 1부터 99까지 입력가능합니다.', 'error');
  };

  const onSubmit = () => {
    let res: any = null;

    if (userType === 'ADMIN') {
      registerAdminFormSchema
        .validate({
          compId: compId,
        })
        .then(async function () {
          setIsLoading(true);
          let formData: FormData = new FormData();

          formData.append('compId', compId);

          try {
            res = await userApi.registerAdmin(formData);
            if (res) {
              util.makeMsg('관리자 생성이 완료되었습니다.', 'success');
              props.history.push('/');
            }
            setIsLoading(false);
          } catch (e) {
            console.log(e);
            setIsLoading(false);
          }
        })
        .catch(function () {
          util.makeMsg('값을 모두 입력해주세요.', 'error');
        });
    } else if (userType === 'USER') {
      registerAdminFormSchema
        .validate({
          compId: compId,
        })
        .then(async function () {
          let formData: FormData = new FormData();
          let n: number = 0;

          formData.append('compId', compId);
          for (let item of authList) {
            if (item.userSize >= 1 && item.userSize < 100) {
              n = Number(n) + Number(item.userSize);
              for (let i = 0; i < item.userSize; i++) {
                if (item.age1) {
                  formData.append('ageAuth[]', '1');
                } else {
                  formData.append('ageAuth[]', '0');
                }
                if (item.age2) {
                  formData.append('ageAuth[]', '1');
                } else {
                  formData.append('ageAuth[]', '0');
                }
                if (item.age3) {
                  formData.append('ageAuth[]', '1');
                } else {
                  formData.append('ageAuth[]', '0');
                }
                if (item.age4) {
                  formData.append('ageAuth[]', '1');
                } else {
                  formData.append('ageAuth[]', '0');
                }
                if (item.age5) {
                  formData.append('ageAuth[]', '1');
                } else {
                  formData.append('ageAuth[]', '0');
                }
              }
            } else throw popUpError();
          }
          formData.append('n', String(n));

          try {
            res = await userApi.registerUser(formData);
          } catch (e) {
            console.log(e);
          }
          if (res.data.rsltCd === '00') {
            util.makeMsg('유저 생성이 완료되었습니다.', 'success');
            setUserList(res.data.userList);
            // props.history.push('/');
          }
        })
        .catch(function () {
          util.makeMsg('값을 모두 입력해주세요.', 'error');
        });
    } else {
      util.makeMsg('유저 타입을 선택해주세요.', 'error');
    }
  };

  const onSearchSubmit = () => {
    setKeyWordForSearch(keyWord);
    setPageNum(1);
  };

  const onAddSubmit = () => {
    let res: any = null;

    addCompanyFormSchema
      .validate({
        compCd: compCd,
        compNm: compNm,
        compAddr: compAddr,
      })
      .then(async function () {
        let addCompanyForm: FormData = new FormData();

        addCompanyForm.append('compCd', compCd);
        addCompanyForm.append('compNm', compNm);
        addCompanyForm.append('compAddr', compAddr);

        try {
          res = await compApi.Add(token, addCompanyForm);
        } catch (e) {
          util.makeMsg('이미 존재하는 지점입니다.', 'error');
        } finally {
          if (res) {
            util.makeMsg('지점이 추가되었습니다.', 'success');
            setCompId(res.data.compId);
            setCompNm(res.data.compNm);
            setIsAddModalOpen(false);
          }
        }
      })
      .catch(function () {
        util.makeMsg('값을 모두 입력해주세요.', 'error');
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageNum, keyWordForSearch]);

  return (
    <>
      <Header {...props} />
      <RegisterPresenter
        onChangeId={onChangeId}
        onChangePw={onChangePw}
        onChangePwConf={onChangePwConf}
        onChangeName={onChangeName}
        agenNm={agenNm}
        compNm={compNm}
        onChangeAgenNm={onChangeAgenNm}
        onChangeAgenAddr={onChangeAgenAddr}
        onChangeAgenContact={onChangeAgenContact}
        onChangeKeyWord={onChangeKeyWord}
        onChangeCompCd={onChangeCompCd}
        onChangeCompNm={onChangeCompNm}
        onChangeCompAddr={onChangeCompAddr}
        checkHandler={checkHandler}
        setAgenId={setAgenId}
        selectAgency={selectAgency}
        setCompId={setCompId}
        selectCompany={selectCompany}
        userType={userType}
        onClickAdmin={onClickAdmin}
        onClickUser={onClickUser}
        onKeyPress={onKeyPress}
        onSearchKeyPress={onSearchKeyPress}
        onAddKeyPress={onAddKeyPress}
        isSearchModalOpen={isSearchModalOpen}
        isAddModalOpen={isAddModalOpen}
        openSearchModal={openSearchModal}
        closeSearchModal={closeSearchModal}
        openAddModal={openAddModal}
        closeAddModal={closeAddModal}
        switchModal={switchModal}
        refreshCompanyList={refreshCompanyList}
        onSubmit={onSubmit}
        onSearchSubmit={onSearchSubmit}
        onAddSubmit={onAddSubmit}
        agenList={agenList}
        compList={compList}
        authList={authList}
        setAuthList={setAuthList}
        pageNum={pageNum}
        setPageNum={setPageNum}
        setKeyWordForSearch={setKeyWordForSearch}
        pageList={pageList}
        isFirst={isFirst}
        isLast={isLast}
        lastPageNum={lastPageNum}
        totalItemLength={totalItemLength}
        addAuthList={addAuthList}
        userList={userList}
      ></RegisterPresenter>
      <Footer {...props} />
    </>
  );
};

export default RegisterContainer;

//make Layout For grid-layout
import lodash from 'lodash';

const rowNumberBasic: number = 7; //행 개수
const columnNumberMonthly: number = 19; //열 개수
const columnNumberWeekly: number = 24;

export const makeLayout = (type: string, week: number, age?: string) => {
  let result: any = [];
  if (age) {
    if (type === 'weekly') {
      let countLayout: number = 0;
      let layout = [];

      // 1행
      layout.push({
        i: String(countLayout++),
        x: 0,
        y: 0,
        w: 2,
        h: 1,
        activityList: [],
      });
      layout.push({
        i: String(countLayout++),
        x: 2,
        y: 0,
        w: 2 * 5,
        h: 1,
        activityList: [],
      });

      // 2행
      layout.push({
        i: String(countLayout++),
        x: 0,
        y: 1,
        w: 2,
        h: 1,
        activityList: [],
      });
      layout.push({
        i: String(countLayout++),
        x: 2,
        y: 1,
        w: 2 * 5,
        h: 1,
        activityList: [],
      });

      //3행
      layout.push({
        i: String(countLayout++),
        x: 0,
        y: 2,
        w: 1,
        h: 3,
        activityList: [],
      });
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 2,
        w: 1,
        h: 1,
        activityList: [],
      });
      layout.push({
        i: String(countLayout++),
        x: 2,
        y: 2,
        w: 2 * 5,
        h: 1,
        activityList: [],
      });

      //4행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 3,
        w: 1,
        h: 1,
        activityList: [],
      });
      layout.push({
        i: String(countLayout++),
        x: 2,
        y: 3,
        w: 2 * 5,
        h: 1,
        activityList: [],
      });

      //5행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 4,
        w: 1,
        h: 1,
        activityList: [],
      });
      layout.push({
        i: String(countLayout++),
        x: 2,
        y: 4,
        w: 2 * 5,
        h: 1,
        activityList: [],
      });

      //6행
      layout.push({
        i: String(countLayout++),
        x: 0,
        y: 5,
        w: 1,
        h: 7,
        activityList: [],
      });
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 5,
        w: 1,
        h: 1,
        activityList: [],
      });

      for (let i = 0; i < 5; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 5,
          w: 2,
          h: 1,
          activityList: [],
        });
      }

      // 7행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 6,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < 5; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 6,
          w: 2,
          h: 1,
          activityList: [],
        });
      }
      // 8행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 7,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < 5; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 7,
          w: 2,
          h: 1,
          activityList: [],
        });
      }
      // 9행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 8,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < 5; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 8,
          w: 2,
          h: 1,
          activityList: [],
        });
      }
      // 10행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 9,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < 5; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 9,
          w: 2,
          h: 1,
          activityList: [],
        });
      }
      // 11행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 10,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < 5; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 10,
          w: 2,
          h: 1,
          activityList: [],
        });
      }
      // 12행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 11,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < 5; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 11,
          w: 2,
          h: 1,
          activityList: [],
        });
      }

      // 13행
      layout.push({
        i: String(countLayout++),
        x: 0,
        y: 12,
        w: 2,
        h: 1,
        activityList: [],
      });
      layout.push({
        i: String(countLayout++),
        x: 2,
        y: 12,
        w: 2 * 5,
        h: 1,
        activityList: [],
      });

      // 14행
      layout.push({
        i: String(countLayout++),
        x: 0,
        y: 13,
        w: 2,
        h: 1,
        activityList: [],
      });
      layout.push({
        i: String(countLayout++),
        x: 2,
        y: 13,
        w: 2 * 5,
        h: 1,
        activityList: [],
      });

      // 15행
      layout.push({
        i: String(countLayout++),
        x: 0,
        y: 14,
        w: 2,
        h: 1,
        activityList: [],
      });
      layout.push({
        i: String(countLayout++),
        x: 2,
        y: 14,
        w: 2 * 5,
        h: 1,
        activityList: [],
      });

      // 16행
      layout.push({
        i: String(countLayout++),
        x: 0,
        y: 15,
        w: 2,
        h: 1,
        activityList: [],
      });
      layout.push({
        i: String(countLayout++),
        x: 2,
        y: 15,
        w: 2 * 5,
        h: 1,
        activityList: [],
      });

      //17행
      layout.push({
        i: String(countLayout++),
        x: 0,
        y: 17,
        w: 1,
        h: 1,
        activityList: [],
      });
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 16,
        w: 1,
        h: 1,
        activityList: [],
      });

      for (let i = 0; i < 5; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 16,
          w: 2,
          h: 1,
          activityList: [],
        });
      }

      result = layout;
    } else if (type === 'monthly') {
      let countLayout: number = 0;
      let layout = [];
      // 1행
      for (let i = 0; i <= week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * i,
          y: 0,
          w: 2,
          h: 1,
          activityList: [],
        });
      }
      // 2행
      for (let i = 0; i <= week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * i,
          y: 1,
          w: 2,
          h: 1,
          activityList: [],
        });
      }

      //3행
      layout.push({
        i: String(countLayout++),
        x: 0,
        y: 2,
        w: 1,
        h: 3,
        activityList: [],
      });
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 2,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 2,
          w: 2,
          h: 1,
          activityList: [],
        });
      }
      //4행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 3,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 3,
          w: 2,
          h: 1,
          activityList: [],
        });
      }

      //5행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 4,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 4,
          w: 2,
          h: 1,
          activityList: [],
        });
      }

      //6행
      layout.push({
        i: String(countLayout++),
        x: 0,
        y: 5,
        w: 1,
        h: 7,
        activityList: [],
      });
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 5,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 5,
          w: 2,
          h: 1,
          activityList: [],
        });
      }

      // 7행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 6,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 6,
          w: 2,
          h: 1,
          activityList: [],
        });
      }
      // 8행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 7,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 7,
          w: 2,
          h: 1,
          activityList: [],
        });
      }
      // 9행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 8,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 8,
          w: 2,
          h: 1,
          activityList: [],
        });
      }
      // 10행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 9,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 9,
          w: 2,
          h: 1,
          activityList: [],
        });
      }
      // 11행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 10,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 10,
          w: 2,
          h: 1,
          activityList: [],
        });
      }

      // 12행
      layout.push({
        i: String(countLayout++),
        x: 1,
        y: 11,
        w: 1,
        h: 1,
        activityList: [],
      });
      for (let i = 0; i < week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * (i + 1),
          y: 11,
          w: 2,
          h: 1,
          activityList: [],
        });
      }

      // 13행
      for (let i = 0; i <= week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * i,
          y: 12,
          w: 2,
          h: 1,
          activityList: [],
        });
      }

      // 14행
      for (let i = 0; i <= week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * i,
          y: 13,
          w: 2,
          h: 1,
          activityList: [],
        });
      }

      // 15행
      for (let i = 0; i <= week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * i,
          y: 14,
          w: 2,
          h: 1,
          activityList: [],
        });
      }

      // 16행
      for (let i = 0; i <= week; i++) {
        layout.push({
          i: String(countLayout++),
          x: 2 * i,
          y: 15,
          w: 2,
          h: 1,
          activityList: [],
        });
      }

      return layout;
    }
  } else {
    let countLayout: number = 0;

    //월간 계획 : 열 개수 19개, 주가 계획 : 열 개수 24개
    const columnNumber =
      type === 'monthly' ? columnNumberMonthly : type === 'weekly' ? columnNumberWeekly : 0;

    //월간 계획에서 5주 이하면 열의 수 줄어듦 : 4주 = 6개, 3주 = 5개,
    const rowNumber = type === 'monthly' && week < 5 ? rowNumberBasic - (5 - week) : rowNumberBasic;

    const layout = lodash.map(lodash.range(columnNumber), (index: number) => {
      const row = lodash.map(lodash.range(rowNumber), (rowIndex: number) => {
        if (rowIndex >= 2) {
          return {
            i: String(countLayout++),
            x: 2 * (rowIndex - 1),
            y: index,
            w: 2,
            h: 1,
            activityList: [],
          };
        } else {
          return {
            i: String(countLayout++),
            x: rowIndex,
            y: index,
            w: 1,
            h: 1,
            activityList: [],
          };
        }
      });
      return row;
    });

    result = layout.reduce((prev: any, now: any) => {
      return prev.concat(now);
    });
  }
  return result;
};

export const addLayout = (layout: any[], type: string) => {
  let tempY = [...layout];
  let tempX = [...layout];
  let layoutRes = [...layout];
  tempY.sort((a: any, b: any) => {
    if (a.y === b.y) {
      return b.h - a.h;
    }
    return b.y - a.y;
  });
  tempX.sort((a: any, b: any) => {
    if (a.x === b.x) {
      return b.w - a.w;
    }
    return b.x - a.x;
  });

  let addData = [];
  addData.push({
    i: String(tempY.length++),
    x: 0,
    y: tempY[0].y + tempY[0].h,
    w: 1,
    h: 1,
    activityList: [],
  });
  addData.push({
    i: String(tempY.length++),
    x: 1,
    y: tempY[0].y + tempY[0].h,
    w: 1,
    h: 1,
    activityList: [],
  });
  for (let i = 2; i < tempX[0].x + tempX[0].w; i += 2) {
    addData.push({
      i: String(tempY.length++),
      x: i,
      y: tempY[0].y + tempY[0].h,
      w: 2,
      h: 1,
      activityList: [],
    });
  }

  // let addData = {
  //     i: String(tempY.length++),
  //     x: 0,
  //     y: tempY[0].y + tempY[0].h,
  //     w: 1,
  //     h: 1,
  //     activityList: [],
  // }
  layoutRes.push(...addData);
  return layoutRes;
};

const groupBy = (items: any, key: any) =>
  items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

export const clear = (layout: any[], week: number, type: string) => {
  let temp = [...layout];
  temp.sort((a: any, b: any) => {
    if (a.y === b.y) {
      return a.x - b.x;
    }
    return b.y - a.y;
  });
  let groupTemp: any[] = Object.values(groupBy(temp, 'x'));

  let lastYObj = groupTemp
    .filter((item: any) => item[0].x < 2)
    .map((value: any) => value[0].y + value[0].h - 1);
  let lastYList = lastYObj
    .filter((item, index) => lastYObj.indexOf(item) === index)
    .sort((a, b) => a - b);
  let lastY = Math.min(...lastYList);
  return layout.filter((item: any) => item.y <= lastY);
};

export const findItemById = (layout: any[], { i }: any) => {
  const result: any = layout.filter((item) => item.i === i);

  //결과가 없으면 빈 값 반환
  return result.length ? result[0] : null;
};

export const weekCount = (year: number, month: number) => {
  const firstOfMonth = new Date(year, month - 1, 1);
  const lastOfMonth = new Date(year, month, 0);

  return Math.floor((firstOfMonth.getDay() + lastOfMonth.getDate()) / 7) + 1;
};

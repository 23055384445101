import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import ActivityPlanPresenter from './ActivityPlanPresenter';
import Header from '../../../../components/header';
import Footer from '../../../../components/footer';
import { comnApi } from './../../../../api/api-comn';
import { menuApi } from './../../../../api/api-menu';
import { planApi } from '../../../../api/api-plan';
import { programApi } from '../../../../api/api-program';
import * as util from '../../../../util/util';
import * as recoilItem from '../../../../util/recoilitem';

interface ActivityPlanProps extends RouteComponentProps {}

const ActivityPlanContainer = (props: ActivityPlanProps) => {
  const token = useRecoilValue(recoilItem.token);
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const setProgramId = useSetRecoilState(recoilItem.programId);

  const blockPageNum: string = '5';
  const pagePostCount: string = '5';

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [curStep, setCurStep] = useState(1);
  const [activityNm, setActivityNm] = useState('');
  const [domain, setDomain] = useState('');
  const [domainNm, setDomainNm] = useState('');
  const [topic, setTopic] = useState('');
  const [subTopic, setSubTopic] = useState('');
  const [extension, setExtension] = useState('');
  const [note, setNote] = useState('');
  const [playEvaluation, setPlayEvaluation] = useState('');
  const [expectationList, setExpectationList] = useState(['']);
  const [factorList, setFactorList] = useState(['']);
  const [methodList, setMethodList] = useState<any[]>([
    {
      content: '',
      subMethodList: [],
    },
  ]);
  const [tempMethod, setTempMethod] = useState<string>('');

  const [domainList, setDomainList] = useState(null);
  const [iconList, setIconList] = useState(null);
  const [checkedIconCd, setCheckedIconCd] = useState(null);

  const stepList = [1, 2, 3, 4, 5, 6];

  const [fieldList, setFieldList] = useState<
    {
      menuCd: string;
      menuNm: string;
      viewOrder: number;
    }[]
  >([]);
  const getField = async () => {
    try {
      setIsLoading(true);
      await menuApi.getCd('MN03').then((res) => {
        if (res.data.rsltCd === '00') {
          setFieldList(
            res.data.ComnCd.subMenuList.map((item: any) => ({
              menuCd: item.menuCd,
              menuNm: item.menuNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageList, setPageList] = useState<number[]>([]);
  const [isFirst, setIsFirst] = useState<boolean>(false);
  const [lastPageNum, setLastPageNum] = useState<number>(1);
  const [isLast, setIsLast] = useState<boolean>(false);
  const [totalItemLength, setTotalItemLength] = useState<number>(0);

  const [selectedProgramList, setSelectedProgramList] = useState<any[]>([]);

  const [modalProgram, setModalProgram] = useState<any>(null);

  const onChangeSelectedProgramList = (program: any, icon: string) => {
    let temps = [...selectedProgramList];
    let index = temps.map((item) => item.program.programId).indexOf(program.programId);
    if (index === -1) {
      temps.push({
        program,
        icon,
      });
    } else {
      if (temps[index].icon === icon) {
        temps.splice(index, 1);
      } else {
        temps.splice(index, 1);
        temps.push({
          program,
          icon,
        });
      }
    }
    setSelectedProgramList(temps);
  };
  const onChangeSelectedProgramPage = (program: any, page: string) => {
    let temps = [...selectedProgramList];
    let index = temps.map((item) => item.program.programId).indexOf(program.programId);
    let tempProgram = selectedProgramList[index];

    temps.splice(index, 1);
    temps.push({
      ...tempProgram,
      page,
    });

    setSelectedProgramList(temps);
    setModalProgram(null);
  };
  const [programList, setProgramList] = useState<any[]>([]);
  const [programSearch, setProgramSearch] = useState<{
    keyword?: string;
    field?: string;
  }>({});

  const getProgramList = async () => {
    try {
      setIsLoading(true);
      let formData: FormData = new FormData();
      programSearch.keyword && formData.append('title', programSearch.keyword);
      programSearch.field && formData.append('field', programSearch.field);
      formData.append('page', pageNum.toString());
      formData.append('blockPageNum', blockPageNum);
      formData.append('pagePostCount', pagePostCount);

      await programApi.filterAll(token, formData).then((res) => {
        if (res.data.rsltCd === '00') {
          console.log(res.data);
          setProgramList(res.data.programList.content);
          setIsFirst(res.data.programList.first);
          setIsLast(res.data.programList.last);
          setLastPageNum(res.data.programList.totalPages);
          setPageList(res.data.pageList);
          setTotalItemLength(res.data.programList.totalElements);
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const goNextStep = () => {
    console.log(expectationList);
    console.log(factorList);
    console.log(methodList);
    if (curStep !== 6) {
      setCurStep(curStep + 1);
    }
  };

  const goPreviousStep = () => {
    if (curStep !== 1) {
      setCurStep(curStep - 1);
    }
  };

  const onChangeActivityNm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActivityNm(e.target.value);
  };

  const onChangeTopic = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTopic(e.target.value);
  };

  const onChangeSubTopic = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubTopic(e.target.value);
  };

  const onChangeExtension = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setExtension(e.target.value);
  };

  const addExpectation = () => {
    let newItem: string = '';
    let temps = [...expectationList, newItem];
    setExpectationList(temps);
  };

  const addFactor = () => {
    let newItem: string = '';
    let temps = [...factorList, newItem];
    setFactorList(temps);
  };

  const addMethod = () => {
    let newItem: any = {
      content: '',
      subMethodList: [],
    };
    let temps = [...methodList, newItem];
    setMethodList(temps);
  };

  const fetchData = async () => {
    let res1: any = null;
    let res2: any = null;
    setIsLoading(true);

    try {
      res1 = await comnApi.getCd('ICON01');
      res2 = await comnApi.getCd('TYPE0401');
      console.log(res1);
      console.log(res2);
      if (res1.data.rsltCd === '00') {
        setIconList(res1.data.ComnCd.subCdList);
      }
      if (res2.data.rsltCd === '00') {
        setDomainList(res2.data.ComnCd.subCdList);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    let res: any = null;

    if (domain === '') {
      util.makeMsg('영역을 선택해주세요.', 'error');
      setCurStep(1);
      return false;
    }

    let formData: FormData = new FormData();
    formData.append('activityNm', activityNm);
    formData.append('domain', domain);
    formData.append('topic', topic);
    formData.append('subTopic', subTopic);
    formData.append('extension', extension);
    formData.append('note', note);
    formData.append('playEvaluation', playEvaluation);

    expectationList.filter((item: string) => item !== '').map(function (item: string, index: number) {
      formData.append(`expectationList[${index}].content`, item);
      formData.append(`expectationList[${index}].sequence`, `${index + 1}`);
    });

    factorList.filter((item: string) => item !== '').map(function (item: string, index: number) {
      formData.append(`factorList[${index}].content`, item);
      formData.append(`factorList[${index}].sequence`, `${index + 1}`);
    });

    methodList.filter((item: string) => item !== '').map(function (item: any, index: number) {
      formData.append(`methodList[${index}].content`, item.content);
      formData.append(`methodList[${index}].sequence`, `${index + 1}`);
      item.subMethodList.map(function (subItem: string, subIndex: number) {
        formData.append(`methodList[${index}].subMethodList[${subIndex}].content`, subItem);
        formData.append(
          `methodList[${index}].subMethodList[${subIndex}].sequence`,
          `${subIndex + 1}`,
        );
      });
    });

    selectedProgramList.forEach((item: any, index: number) => {
      formData.append(`materialList[${index}].icon`, item.icon);
      formData.append(`materialList[${index}].program`, item.program.programId);
      item.page && formData.append(`materialList[${index}].subId`, item.page);
    });

    try {
      setIsLoading(true);
      res = await planApi.createActivityPlan(token, formData);
      console.log(res);
      if (res.data.rsltCd === '00') {
        util.makeMsg('계획안 작성이 완료되었습니다.', 'success');
        setIsLoading(false);
        props.history.push('/registerSelect');
      } else {
        util.makeMsg('실패하였습니다.', 'error');
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    getField();
  }, []);

  useEffect(() => {
    if (curStep === 6) {
      getProgramList();
    }
  }, [curStep, pageNum]);

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getProgramList();
    }
  };
  return (
    <>
      <Header {...props} />
      <ActivityPlanPresenter
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        stepList={stepList}
        curStep={curStep}
        setCurStep={setCurStep}
        goNextStep={goNextStep}
        goPreviousStep={goPreviousStep}
        activityNm={activityNm}
        domain={domain}
        setDomain={setDomain}
        domainNm={domainNm}
        setDomainNm={setDomainNm}
        topic={topic}
        subTopic={subTopic}
        extension={extension}
        expectationList={expectationList}
        setExpectationList={setExpectationList}
        onChangeActivityNm={onChangeActivityNm}
        onChangeTopic={onChangeTopic}
        onChangeSubTopic={onChangeSubTopic}
        onChangeExtension={onChangeExtension}
        addExpectation={addExpectation}
        factorList={factorList}
        setFactorList={setFactorList}
        addFactor={addFactor}
        methodList={methodList}
        setMethodList={setMethodList}
        addMethod={addMethod}
        iconList={iconList}
        domainList={domainList}
        checkedIconCd={checkedIconCd}
        setCheckedIconCd={setCheckedIconCd}
        onSubmit={onSubmit}
        getProgramList={getProgramList}
        fieldList={fieldList}
        programSearch={programSearch}
        setProgramSearch={setProgramSearch}
        programList={programList}
        pageNum={pageNum}
        setPageNum={setPageNum}
        pageList={pageList}
        lastPageNum={lastPageNum}
        isFirst={isFirst}
        isLast={isLast}
        onChangeSelectedProgramList={onChangeSelectedProgramList}
        selectedProgramList={selectedProgramList}
        onKeyPress={onKeyPress}
        note={note}
        setNote={setNote}
        playEvaluation={playEvaluation}
        setPlayEvaluation={setPlayEvaluation}
        setProgramId={setProgramId}
        modalProgram={modalProgram}
        setModalProgram={setModalProgram}
        onChangeSelectedProgramPage={onChangeSelectedProgramPage}
        tempMethod={tempMethod}
        setTempMethod={setTempMethod}
      ></ActivityPlanPresenter>
      <Footer {...props} />
    </>
  );
};

export default ActivityPlanContainer;

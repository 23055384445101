import React from 'react';

import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { RouteComponentProps } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';

import * as s from './BannerStyled';
import NotFound from '../../components/notFound';

interface BannerProps {
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  open: () => void;
  acceptedFiles: File[];
  isDragActive: boolean;
  isModifying: boolean;
  setIsModifying: React.Dispatch<React.SetStateAction<boolean>>;
  submitFileList: File[];
  submit: () => Promise<void>;
}

const BannerPresenter = (props: BannerProps) => {
  return (
    <>
      <s.Container>
        <s.ProgramHeader>
          <s.ProgramHeaderTitle>배너 관리</s.ProgramHeaderTitle>
          <s.ProgramHeaderRightArea>
            <s.ProgramHeaderRightText>
              <s.ProgramHeaderRightIcon src="/static/img/home_icon.jpg" />
              {'HOME > 마이페이지 > 글작성'}
            </s.ProgramHeaderRightText>
          </s.ProgramHeaderRightArea>
        </s.ProgramHeader>
        <s.UploadArea>
          <s.UploadAreaHeader>
            <s.UploadAreaHeaderLeft>배너 업로드</s.UploadAreaHeaderLeft>
            {/* <s.UploadAreaHeaderRight>
              <s.UploadAreaHeaderRightIcon src="/static/img/warning.png" />
              <s.UploadAreaHeaderRightText>
                교육프로그램코드_교육프로그램타입코드_월(호)코드_보이기타입_제목_놀이코드_연령코드_추가타입코드
              </s.UploadAreaHeaderRightText>
            </s.UploadAreaHeaderRight> */}
          </s.UploadAreaHeader>
          <s.UploadContentArea>
            <s.UploadContentAreaHeader>
              <s.UploadContentAreaHeaderLeft>
                <s.UploadContentAreaHeaderIcon
                  src={!props.isModifying ? '/static/img/pen.png' : '/static/img/red_pen.png'}
                />
                <s.UploadContentAreaHeaderLeftText
                  onClick={() => (!props.isModifying ? props.setIsModifying(true) : null)}
                  isColor={!props.isModifying}
                >
                  수정
                </s.UploadContentAreaHeaderLeftText>
              </s.UploadContentAreaHeaderLeft>
              <s.UploadContentAreaHeaderRight>
                {false ? (
                  <BeatLoader size={8} color={'#acacac'} />
                ) : (
                  <>
                    <s.UploadContentAreaHeaderIcon
                      src={
                        props.isModifying ? '/static/img/check.png' : '/static/img/green_check.png'
                      }
                    />
                    <s.UploadContentAreaHeaderRightText
                      onClick={() => (props.isModifying ? props.setIsModifying(false) : null)}
                      isColor={props.isModifying}
                    >
                      완료
                    </s.UploadContentAreaHeaderRightText>
                  </>
                )}
              </s.UploadContentAreaHeaderRight>
            </s.UploadContentAreaHeader>
            <s.UploadProgramArea>
              <s.UploadProgramAreaFileUploadArea
                isDrag={props.isDragActive}
                {...props.getRootProps({ className: 'dropzone' })}
              >
                <s.UploadProgramAreaFileUploadAreaInput {...props.getInputProps()} />
                {props.submitFileList.length > 0 ? (
                  <s.UploadProgramAreaFileUploadAreaButtonIcon
                    onClick={props.open}
                    src="/static/img/pdf_icon_1.png"
                  />
                ) : null}
                <s.UploadProgramAreaFileUploadAreaText>
                  {props.submitFileList.length > 0
                    ? props.submitFileList.length === 1
                      ? props.submitFileList[0].name
                      : props.submitFileList[0].name +
                        ' 외 ' +
                        (props.submitFileList.length - 1) +
                        '개'
                    : '파일을 선택해주세요.'}
                </s.UploadProgramAreaFileUploadAreaText>
                <s.UploadProgramAreaFileUploadAreaButtonIcon
                  onClick={props.open}
                  src={
                    props.submitFileList.length > 0
                      ? '/static/img/file_exist.png'
                      : '/static/img/selectFile.png'
                  }
                />
              </s.UploadProgramAreaFileUploadArea>
            </s.UploadProgramArea>
            <s.ButtonArea>
              <s.SubmitButton onClick={props.submit}>
                <s.SubmitButtonText>업로드</s.SubmitButtonText>
              </s.SubmitButton>
            </s.ButtonArea>
          </s.UploadContentArea>
        </s.UploadArea>
      </s.Container>
    </>
  );
};

export default BannerPresenter;

import * as s from '../UpdateEduStyled';

interface SelectSubTabProps {
  option: {
    holder: string;
    edu: string;
    type: string;
    subTab: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
}

const SelectSubTab = (props: SelectSubTabProps) => {
  return (
    <s.SelectArea>
      <s.SelectLabel>프로그램 서브 탭 선택</s.SelectLabel>
      <s.SelectDiv></s.SelectDiv>
      <s.SelectForm selectType={'subTab'}>
        <s.SelectItem
          key={'subTab-MN0206'}
          active={props.option.subTab === 'MN0206'}
          selectType={'subTab'}
          onClick={() => props.onChangeOption('subTab', 'MN0206')}
        >
          엔젤쁘레
        </s.SelectItem>
        <s.SelectItem
          key={'subTab-MN0208'}
          active={props.option.subTab === 'MN0208'}
          selectType={'subTab'}
          onClick={() => props.onChangeOption('subTab', 'MN0208')}
        >
          슈필매스
        </s.SelectItem>
      </s.SelectForm>
    </s.SelectArea>
  );
};

export default SelectSubTab;

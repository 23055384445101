import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import Header from '../../components/header';
import Footer from '../../components/footer';
import { userApi } from '../../api/api-user';
import { programApi } from '../../api/api-program';
import * as recoilItem from '../../util/recoilitem';
import queryString from 'query-string';
import * as util from '../../util/util';
import { shoppingApi } from '../../api/api-shopping';
import ShoppingPresenter from './ShoppingPresenter';

interface Props extends RouteComponentProps {}

const ShoppingContainer = (props: Props) => {
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const token = useRecoilValue(recoilItem.token);
  const setUserInfo = useSetRecoilState(recoilItem.userInfo);

  const [mainItems, setMainItems] = useState<any>([]);
  const [pageNum, setPageNum] = useState(1);
  const [productDv, setProductDv] = useState<string>('0');
  const [productDetailDv, setProductDetailDv] = useState<string>('0');
  const [month, setMonth] = useState<string>('0');
  const [items, setItems] = useState<any>([]);
  const [pageList, setPageList] = useState([]);
  const [isFirst, setIsFirst] = useState(false);
  const [lastPageNum, setLastPageNum] = useState(1);
  const [isLast, setIsLast] = useState(false);

  useEffect(() => {
    fetchData();
  }, [pageNum, productDetailDv, productDv, month]);

  useEffect(() => {
    setProductDetailDv('1');
    setPageNum(1);
    setMonth('0');
    setItems([]);
  }, [productDv]);

  const fetchData = async () => {
    let formData = new FormData();
    formData.append('page', String(pageNum));

    if (productDv === '-1' && month !== '0') {
      formData.append('month', month);
      let res = await shoppingApi.getOpenProductList(token, formData);
      setItems(
        res.data.prevProductList?.content.map((item: any) => {
          return {
            ...item,
            thumbnailList: item.thumbnailList.sort((a: any, b: any) => a.viewOrder - b.viewOrder),
            detailList: item.detailList.sort((a: any, b: any) => a.viewOrder - b.viewOrder),
          };
        }),
      );
      setIsFirst(res.data.prevProductList?.first);
      setIsLast(res.data.prevProductList?.last);
      setLastPageNum(res.data.prevProductList?.totalPages);
      setPageList(res.data.pageList);
    } else if (productDv !== '-1') {
      if (productDv === '0') {
        formData.append('bestYn', 'Y');
      } else if (Number(productDv) > 0) {
        formData.append('productDv', productDv);
        formData.append('productDetailDv', productDetailDv);
      }
      let res = await shoppingApi.getOpenProductList(token, formData);
      console.log(res);
      setItems(
        res.data.prevProductList?.content
          .map((item: any) => {
            return {
              ...item,
              thumbnailList: item.thumbnailList.sort((a: any, b: any) => a.viewOrder - b.viewOrder),
              detailList: item.detailList.sort((a: any, b: any) => a.viewOrder - b.viewOrder),
            };
          })
          .sort((a: any, b: any) => b.useYn - a.useYn),
      );
      setIsFirst(res.data.prevProductList?.first);
      setIsLast(res.data.prevProductList?.last);
      setLastPageNum(res.data.prevProductList?.totalPages);
      setPageList(res.data.pageList);
    }
  };

  const goToItem = (item: any) => {
    props.history.push('/shopping/open/detail', { item: item });
  };

  return (
    <>
      <Header {...props} />
      <ShoppingPresenter
        mainItems={mainItems}
        productDv={productDv}
        setProductDv={setProductDv}
        productDetailDv={productDetailDv}
        setProductDetailDv={setProductDetailDv}
        month={month}
        setMonth={setMonth}
        items={items}
        pageNum={pageNum}
        setPageNum={setPageNum}
        pageList={pageList}
        lastPageNum={lastPageNum}
        isFirst={isFirst}
        isLast={isLast}
        goToItem={goToItem}
      ></ShoppingPresenter>
      <Footer {...props} />
    </>
  );
};

export default ShoppingContainer;

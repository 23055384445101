import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import VideosPresenter from './VideosPresenter';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { comnApi } from '../../api/api-comn';
import { menuApi} from '../../api/api-menu';
import { programApi } from '../../api/api-program';
import * as recoilItem from '../../util/recoilitem';

interface VideosProps extends RouteComponentProps {}

const VideosContainer = (props: VideosProps) => {
  const token = useRecoilValue(recoilItem.token);
  const setProgramId = useSetRecoilState(recoilItem.programId);
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);

  const blockPageNum: string = '5';
  const pagePostCount: string = '15';

  const [pageNum, setPageNum] = useState(1);
  const [pageList, setPageList] = useState([]);
  const [isFirst, setIsFirst] = useState(false);
  const [lastPageNum, setLastPageNum] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [selectedAge, setSelectedAge] = useState({ comnCd: 'AUTH0101', comnCdNm: '1세' });
  const [ageList, setAgeList] = useState([]);
  const [programList, setProgramList] = useState(null);
  const [totalItemLength, setTotalItemLength] = useState(0);

  const firstFetchData = async () => {
    setIsLoading(true);

    let res1: any = null;
    let res2: any = null;

    let formData: FormData = new FormData();

    formData.append('topMenu', 'MN04');
    formData.append('subMenu', 'MN0408');
    formData.append('age', selectedAge.comnCd);
    formData.append('page', pageNum.toString());
    formData.append('blockPageNum', blockPageNum);
    formData.append('pagePostCount', pagePostCount);

    try {
      res1 = await comnApi.getCd('AUTH01');
      res2 = await programApi.filter(token, formData);
      if (res1.data.rsltCd === '00') {
        setAgeList(res1.data.ComnCd.subCdList);
      }
      if (res2.data.rsltCd === '00') {
        let resultList = res2.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res2.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setProgramList(resultList);
        setIsFirst(res2.data.programList.first);
        setIsLast(res2.data.programList.last);
        setLastPageNum(res2.data.programList.totalPages);
        setPageList(res2.data.pageList);
        setTotalItemLength(res2.data.programList.totalElements);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    let res: any = null;

    let formData: FormData = new FormData();

    formData.append('topMenu', 'MN04');
    formData.append('subMenu', 'MN0408');
    formData.append('age', selectedAge.comnCd);
    formData.append('page', pageNum.toString());
    formData.append('blockPageNum', blockPageNum);
    formData.append('pagePostCount', pagePostCount);

    try {
      res = await programApi.filter(token, formData);
      if (res.data.rsltCd === '00') {
        let resultList = res.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setProgramList(resultList);
        setIsFirst(res.data.programList.first);
        setIsLast(res.data.programList.last);
        setLastPageNum(res.data.programList.totalPages);
        setPageList(res.data.pageList);
        setTotalItemLength(res.data.programList.totalElements);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const onChangeAge = (item: any) => {
    setSelectedAge({ comnCd: item.comnCd, comnCdNm: item.comnCdNm });
    setPageNum(1);
  };

  useEffect(() => {
    firstFetchData();
  }, []);

  return (
    <>
      <Header {...props} />
      <VideosPresenter
        ageList={ageList}
        selectedAge={selectedAge}
        setSelectedAge={setSelectedAge}
        onChangeAge={onChangeAge}
        programList={programList}
        setProgramId={setProgramId}
        pageNum={pageNum}
        setPageNum={setPageNum}
        pageList={pageList}
        lastPageNum={lastPageNum}
        isFirst={isFirst}
        isLast={isLast}
      ></VideosPresenter>
      <Footer {...props} />
    </>
  );
};

export default VideosContainer;

import { loginedApi, notLoginedApi } from './api-base';

export const programApi = {

  getProgram: (token: any, programId: number, programSubId?: number) => programSubId !== null && programSubId !== undefined ?
    loginedApi.get(`/program/${programId}`, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
      params: {
        'subId': programSubId,
      },
    }) : loginedApi.get(`/program/${programId}`, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
    }),

  filter: (token: any, Data: FormData) =>
    notLoginedApi.post('/program/filter', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
    }),

  filterAll: (token: any, Data: FormData) =>
    loginedApi.post('/program/filterAll', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
    }),

  filterbylist: (token: any, Data: FormData) =>
    notLoginedApi.post('/program/filterbylist', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
    }),
  updateProgram: (token: any, Data: FormData) =>
    loginedApi.put('/program', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
    }),
  createProgram: (token: any, Data: FormData) =>
    loginedApi.post('/program', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
    }),
  createBulkProgram: (token: any, Data: FormData) =>
    loginedApi.post('/program/bulk', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
    }),

  deleteProgram: (token: any, programId: number) =>
    loginedApi.delete(`/program/${programId}`, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        "X-AUTH-REFRESH-TOKEN": token.refreshToken,
      },
    }),
};

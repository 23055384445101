import React from 'react';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { SetterOrUpdater } from 'recoil';

import ActivityAdditionalFile from '../component/ActivityAdditionalFile';
import DomainModal from './../domainModal';
import * as s from './ActivityPlanStyled';

interface ActivityPlanProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  stepList: number[];
  curStep: number;
  setCurStep: React.Dispatch<React.SetStateAction<number>>;
  goNextStep: React.MouseEventHandler<HTMLDivElement>;
  goPreviousStep: React.MouseEventHandler<HTMLDivElement>;
  activityNm: string;
  domain: string;
  setDomain: React.Dispatch<React.SetStateAction<string>>;
  domainNm: string;
  setDomainNm: React.Dispatch<React.SetStateAction<string>>;
  topic: string;
  subTopic: string;
  extension: string;
  expectationList: string[];
  setExpectationList: React.Dispatch<React.SetStateAction<string[]>>;
  onChangeActivityNm: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onChangeTopic: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onChangeSubTopic: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onChangeExtension: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  addExpectation: React.MouseEventHandler<HTMLDivElement>;
  factorList: string[];
  setFactorList: React.Dispatch<React.SetStateAction<string[]>>;
  addFactor: React.MouseEventHandler<HTMLDivElement>;
  methodList: {
    content: string;
    subMethodList: string[];
  }[];
  setMethodList: React.Dispatch<
    React.SetStateAction<{ content: string; subMethodList: any[] }[]>
  >;
  addMethod: React.MouseEventHandler<HTMLDivElement>;
  iconList: any;
  domainList: any;
  checkedIconCd: any;
  setCheckedIconCd: any;
  onSubmit: React.MouseEventHandler<HTMLDivElement>;
  getProgramList: () => Promise<void>;
  fieldList: {
    menuCd: string;
    menuNm: string;
    viewOrder: number;
  }[];
  programSearch: {
    keyword?: string | undefined;
    field?: string | undefined;
  };
  setProgramSearch: React.Dispatch<
    React.SetStateAction<{
      keyword?: string | undefined;
      field?: string | undefined;
    }>
  >;
  programList: any[];
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  pageList: number[];
  lastPageNum: number;
  isFirst: boolean;
  isLast: boolean;
  onChangeSelectedProgramList: (programId: any, icon: string) => void;
  selectedProgramList: any[];
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  note: string;
  setNote: React.Dispatch<React.SetStateAction<string>>;
  playEvaluation: string;
  setPlayEvaluation: React.Dispatch<React.SetStateAction<string>>;
  setProgramId: SetterOrUpdater<number | null>;
  modalProgram: any;
  setModalProgram: React.Dispatch<any>;
  onChangeSelectedProgramPage: (program: any, page: string) => void;
  tempMethod: string;
  setTempMethod: React.Dispatch<React.SetStateAction<string>>;
}

const ActivityPlanPresenter = (props: ActivityPlanProps) => {
  const ActivityProgramModal = () => {
    return (
      <s.ModalContainer>
        <s.SmallTitleArea>
          <s.TitleRight>
            <s.TitleRightTop>
              {props.modalProgram.age ? (
                <s.TitleRightTopText>
                  만 {props.modalProgram.age.comnCdNm} {props.modalProgram.holder.comnCdNm}
                </s.TitleRightTopText>
              ) : (
                <s.TitleRightTopText>{props.modalProgram.holder.comnCdNm}</s.TitleRightTopText>
              )}
              {props.modalProgram.field ? (
                <s.TitleRightTopIcon>{props.modalProgram.field.menuNm}</s.TitleRightTopIcon>
              ) : null}
            </s.TitleRightTop>
            <s.TitleRightBottom>{props.modalProgram.title}</s.TitleRightBottom>
          </s.TitleRight>
          <s.CloseButton onClick={() => props.setModalProgram(null)}>닫기</s.CloseButton>
        </s.SmallTitleArea>
        {props.modalProgram.programImages && props.modalProgram.programImages.length > 0 ? (
          <s.ContentArea>
            {props.modalProgram.programImages.map((item: any) => (
              <s.ContentImage
                onClick={() => props.onChangeSelectedProgramPage(props.modalProgram, item.imageId)}
                src={item.file.fileUrl}
              />
            ))}
          </s.ContentArea>
        ) : props.modalProgram.programDocuments &&
          props.modalProgram.programDocuments.length > 0 ? (
          <s.ContentArea>
            {props.modalProgram.programDocuments.map((item: any) => (
              <s.ContentImage
                onClick={() =>
                  props.onChangeSelectedProgramPage(props.modalProgram, item.documentId)
                }
                src={item.file.fileUrl}
              />
            ))}
          </s.ContentArea>
        ) : null}
      </s.ModalContainer>
    );
  };
  return (
    <s.Container>
      {props.modalProgram !== null ? <ActivityProgramModal /> : null}
      {props.isModalOpen ? (
        <DomainModal
          isModalOpen={props.isModalOpen}
          setIsModalOpen={props.setIsModalOpen}
          domain={props.domain}
          setDomain={props.setDomain}
          domainNm={props.domainNm}
          setDomainNm={props.setDomainNm}
          domainList={props.domainList}
        />
      ) : null}
      <s.ActivityPlanHeader>
        <s.ActivityPlanHeaderTitleArea>
          <s.ActivityPlanHeaderTitle>활동계획안 작성</s.ActivityPlanHeaderTitle>
        </s.ActivityPlanHeaderTitleArea>
        <s.ActivityPlanHeaderRightArea>
          <s.ActivityPlanHeaderRightText>
            <s.ActivityPlanHeaderRightIcon src="/static/img/home_icon.jpg" />
            {'HOME > 마이페이지 > 글작성'}
          </s.ActivityPlanHeaderRightText>
        </s.ActivityPlanHeaderRightArea>
      </s.ActivityPlanHeader>
      <s.RegisterContentArea>
        <s.RegisterContentStepBar>
          {props.stepList.map((item: number, index: number) => (
            <>
              {item === props.curStep ? (
                <>
                  <s.RegisterContentStep active={true}>
                    <s.RegisterContentStepNum>{item}</s.RegisterContentStepNum>
                  </s.RegisterContentStep>
                  <s.RegisterContentStepText>단계</s.RegisterContentStepText>
                </>
              ) : (
                <s.RegisterContentStep
                  onClick={() => props.setCurStep(item)}
                  active={false}
                ></s.RegisterContentStep>
              )}
            </>
          ))}
        </s.RegisterContentStepBar>
        {props.curStep !== 6 ? (
          <s.RegisterContentHeader>
            <s.RegisterContentHeaderTitle>활동계획안 작성</s.RegisterContentHeaderTitle>
          </s.RegisterContentHeader>
        ) : null}
        {props.curStep === 1 ? (
          <s.RegisterContentForm>
            <s.RegisterContentRow>
              <s.RegisterContentElement>
                <s.RegisterContentElementDomain>활동명</s.RegisterContentElementDomain>
                <s.RegisterContentElementInput
                  type="text"
                  name="activityNm"
                  value={props.activityNm}
                  onChange={props.onChangeActivityNm}
                />
              </s.RegisterContentElement>
              <s.RegisterContentElement>
                <s.RegisterContentElementDomain>영역</s.RegisterContentElementDomain>
                <s.RegisterContentElementInput
                  type="text"
                  name="domain"
                  value={props.domainNm}
                  onClick={() => props.setIsModalOpen(true)}
                  // onChange={props.onChangeActivityNm}
                />
              </s.RegisterContentElement>
            </s.RegisterContentRow>
            <s.RegisterContentRow>
              <s.RegisterContentElement>
                <s.RegisterContentElementDomain>주제</s.RegisterContentElementDomain>
                <s.RegisterContentElementInput
                  type="text"
                  name="topic"
                  value={props.topic}
                  onChange={props.onChangeTopic}
                />
              </s.RegisterContentElement>
              <s.RegisterContentElement>
                <s.RegisterContentElementDomain>소주제</s.RegisterContentElementDomain>
                <s.RegisterContentElementInput
                  type="text"
                  name="subTopic"
                  value={props.subTopic}
                  onChange={props.onChangeSubTopic}
                />
              </s.RegisterContentElement>
            </s.RegisterContentRow>
            <s.RegisterContentRow>
              <s.RegisterContentFullElement>
                <s.RegisterContentFullElementDomain>유의점</s.RegisterContentFullElementDomain>
                <s.RegisterContentElementTextArea
                  name="note"
                  value={props.note}
                  placeholder={'텍스트를 입력해주세요.'}
                  onChange={(e) => props.setNote(e.target.value)}
                />
              </s.RegisterContentFullElement>
            </s.RegisterContentRow>
            <s.RegisterContentLastRow>
              <s.RegisterContentFullElement>
                <s.RegisterContentFullElementDomain>놀이평가</s.RegisterContentFullElementDomain>
                <s.RegisterContentElementTextArea
                  name="playEvaluation"
                  value={props.playEvaluation}
                  placeholder={'텍스트를 입력해주세요.'}
                  onChange={(e) => props.setPlayEvaluation(e.target.value)}
                />
              </s.RegisterContentFullElement>
            </s.RegisterContentLastRow>
          </s.RegisterContentForm>
        ) : props.curStep === 2 ? (
          <s.RegisterContentForm>
            <s.ContentTitleArea>
              <s.ContentTitle titleType="expectation">교사의 기대</s.ContentTitle>
              <s.ContentSubTitle>교사의 기대를 한줄씩 적어주세요.</s.ContentSubTitle>
            </s.ContentTitleArea>
            <s.ContentListArea>
                <s.ContentList>
                <s.ContentListTextArea
                  value={props.expectationList.join('\n')}
                  onChange={(e) => {
                    let temp = e.target.value;
                    let temps = temp.split('\n');
                    props.setExpectationList(temps);
                  }}
                  placeholder={'텍스트를 입력해주세요.'}
                ></s.ContentListTextArea>
              </s.ContentList>
            </s.ContentListArea>
          </s.RegisterContentForm>
        ) : props.curStep === 3 ? (
          <s.RegisterContentForm>
            <s.ContentTitleArea>
              <s.ContentTitle titleType="factor">누리과정 관련요소</s.ContentTitle>
              <s.ContentSubTitle>누리과정을 한줄씩 적어주세요.</s.ContentSubTitle>
            </s.ContentTitleArea>
            <s.ContentListArea>
            <s.ContentList>
              <s.ContentListTextArea
                value={props.factorList.join('\n')}
                onChange={(e) => {
                  let temp = e.target.value;
                  let temps = temp.split('\n');
                  props.setFactorList(temps);
                }}
                placeholder={'텍스트를 입력해주세요.'}
              ></s.ContentListTextArea>
              </s.ContentList>
              
            </s.ContentListArea>
          </s.RegisterContentForm>
        ) : props.curStep === 4 ? (
          <s.RegisterContentForm>
            <s.ContentTitleArea>
              <s.ContentTitle titleType="method">활동 방법</s.ContentTitle>
              <s.ContentSubTitle>활동 방법을 한줄씩 적어주세요.{'\n'}반드시 마지막에 줄바꿈을해주세요.</s.ContentSubTitle>
            </s.ContentTitleArea>
            <s.ContentListArea>
              <s.ContentList>
              <s.ContentListTextArea
                value={props.tempMethod}
                onChange={(e) => {
                  let temp = e.target.value;
                  let temps = temp.split('\n');
                  let methodList: any[] = [];
                  let subMethod = [];
                  let content = ''

                  for (let i = 0; i < temps.length; i++) {
                    let line = temps[i].trim();
                    if (line.startsWith('－')) {
                      subMethod.push(line.replaceAll('－', '').trim());
                    } else {
                      if (i === 0) {
                        content = line;
                      } else if (i !== 0 && line !== content) {
                        methodList.push({content: content, subMethodList: subMethod});
                        subMethod = [];
                        content = line;
                      }
                    }
                  }

                  let finalMethodList: any[] = [];
                  for (let i = 0; i < methodList.length; i++) {
                    if (methodList[i].content) {
                      finalMethodList.push(methodList[i]);
                    }
                  }
                  props.setMethodList(finalMethodList);
                  props.setTempMethod(e.target.value);
                }}
                placeholder={'텍스트를 입력해주세요.'}
              ></s.ContentListTextArea>
              </s.ContentList>
            </s.ContentListArea>
          </s.RegisterContentForm>
        ) : props.curStep === 5 ? (
          <s.RegisterContentForm>
            <s.ContentTitleArea>
              <s.ContentTitle titleType="extension">확장활동</s.ContentTitle>
              <s.ContentSubTitle>확장활동을 자유롭게 작성해 주세요.</s.ContentSubTitle>
            </s.ContentTitleArea>
            <s.ContentExtensionTextArea>
              <s.ContentExtensionText
                name="activityNm"
                value={props.extension}
                placeholder={'텍스트를 입력해주세요.'}
                onChange={props.onChangeExtension}
              ></s.ContentExtensionText>
            </s.ContentExtensionTextArea>
          </s.RegisterContentForm>
        ) : props.curStep === 6 ? (
          <>
            <s.FileUploadHeader>
              <s.FileUploadHeaderTitle>놀이자료</s.FileUploadHeaderTitle>
            </s.FileUploadHeader>
            <s.FileUploadForm>
              <s.UploadProgramAreaSelectHeader>놀이 선택</s.UploadProgramAreaSelectHeader>
              <s.UploadProgramAreaSelectDv />
              <s.UploadProgramAreaHeaderSelect>
                {props.fieldList.map((subMenu: any) => (
                  <>
                    <s.UploadProgramAreaSelectType
                      id={subMenu.menuCd}
                      value={subMenu.menuCd}
                      defaultChecked={props.programSearch.field === subMenu.menuCd}
                      onChange={(e) =>
                        props.programSearch.field === e.target.value
                          ? props.setProgramSearch({ ...props.programSearch, field: '' })
                          : props.setProgramSearch({
                              ...props.programSearch,
                              field: e.target.value,
                            })
                      }
                      type={'checkbox'}
                      disabled={false}
                    />
                    <s.UploadProgramAreaSelectTypeLabel htmlFor={subMenu.menuCd}>
                      {props.programSearch.field === subMenu.menuCd ? (
                        <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                      ) : null}
                    </s.UploadProgramAreaSelectTypeLabel>
                    <s.UploadProgramAreaSelectText htmlFor={subMenu.menuCd}>
                      {subMenu.menuNm}
                    </s.UploadProgramAreaSelectText>
                  </>
                ))}
              </s.UploadProgramAreaHeaderSelect>
              <s.ContentList>
                <s.SearchText
                  type="text"
                  name={`keyword`}
                  value={props.programSearch.keyword}
                  onChange={(e) =>
                    props.setProgramSearch({ ...props.programSearch, keyword: e.target.value })
                  }
                  placeholder={'텍스트를 입력해주세요.'}
                  onKeyPress={props.onKeyPress}
                ></s.SearchText>
                <s.Button onClick={props.getProgramList}>검색</s.Button>
              </s.ContentList>
              <s.ResultContentWrapper>
                {props.programList && props.programList.length > 0 ? (
                  props.programList.map((program) => {
                    return (
                      <s.ResultEachItemWrapper key={program.programId}>
                        <s.ResultItemInfoTopic>
                          {program.field
                            ? program.field.menuNm
                            : program.subMenu
                            ? program.subMenu.menuNm
                            : program.topMenu.menuNm}
                        </s.ResultItemInfoTopic>
                        <s.ResultItemInfoNm onClick={() => props.setProgramId(program.programId)}>
                          {program.title}
                        </s.ResultItemInfoNm>
                        {props.iconList.map((icon: any) => (
                          <s.ResultItemButton
                            onClick={() => props.onChangeSelectedProgramList(program, icon.comnCd)}
                          >
                            {props.selectedProgramList.filter(
                              (item) =>
                                item.program.programId === program.programId &&
                                item.icon === icon.comnCd,
                            ).length > 0 ? (
                              <s.ResultItemButtonCheckImg src={'/static/img/check.png'} />
                            ) : (
                              <s.ResultItemButtomEmptyImg />
                            )}
                            {icon.comnCdNm}
                          </s.ResultItemButton>
                        ))}
                      </s.ResultEachItemWrapper>
                    );
                  })
                ) : (
                  <s.NoResult>검색 결과가 없습니다.</s.NoResult>
                )}
              </s.ResultContentWrapper>
              <s.UploadProgramAreaSelectHeader>페이지 선택</s.UploadProgramAreaSelectHeader>
              <s.UploadProgramAreaSelectDv />
              <s.SelectedProgramArea>
                {props.selectedProgramList.map((item) => (
                  <s.SelectedProgramButton
                    onClick={() => props.setModalProgram(item.program)}
                    icon={item.icon}
                  >
                    {item.program.title}
                  </s.SelectedProgramButton>
                ))}
              </s.SelectedProgramArea>
              <s.PagingNav>
                {props.isFirst ? null : (
                  <s.PagingLink onClick={() => props.setPageNum(props.pageNum - 1)}>
                    <s.PagingLinkImg src="/static/img/l_shaft.png" />
                  </s.PagingLink>
                )}

                <s.PagingNumArea>
                  {props.pageList.map((page: number, index: number) => (
                    <s.PagingNum
                      key={'page' + index}
                      onClick={() => props.setPageNum(page)}
                      current={props.pageNum === page}
                    >
                      {page}
                    </s.PagingNum>
                  ))}
                </s.PagingNumArea>
                {props.isLast ? null : (
                  <s.PagingLink onClick={() => props.setPageNum(props.pageNum + 1)}>
                    <s.PagingLinkImg src="/static/img/r_shaft.png" />
                  </s.PagingLink>
                )}
              </s.PagingNav>
            </s.FileUploadForm>
          </>
        ) : null}

        <s.RegisterContentButtonArea>
          {props.curStep === 1 ? (
            <s.RegisterContentButton onClick={props.goNextStep}>
              {'다음단계 >'}
            </s.RegisterContentButton>
          ) : props.curStep === 6 ? (
            <>
              <s.RegisterContentButton onClick={props.goPreviousStep}>
                {'< 이전단계'}
              </s.RegisterContentButton>
              <s.RegisterContentButton onClick={props.onSubmit}>{'업로드'}</s.RegisterContentButton>
            </>
          ) : (
            <>
              <s.RegisterContentButton onClick={props.goPreviousStep}>
                {'< 이전단계'}
              </s.RegisterContentButton>
              <s.RegisterContentButton onClick={props.goNextStep}>
                {'다음단계 >'}
              </s.RegisterContentButton>
            </>
          )}
        </s.RegisterContentButtonArea>
      </s.RegisterContentArea>
    </s.Container>
  );
};

export default ActivityPlanPresenter;

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import RegisterSelectPresenter from './RegisterSelectPresenter';

interface RegisterSelectProps extends RouteComponentProps {}

const RegisterSelectContainer = (props: RegisterSelectProps) => {
  const [level1Menu, setLevel1Menu] = useState(0);

  useEffect(() => {
    setLevel1Menu(0);
  }, []);

  const changeMenu = (menuIdx: number) => {
    setLevel1Menu(-99);
    setTimeout(() => {
      setLevel1Menu(menuIdx);
    }, 350)
  }

  return (
    <>
      <Header {...props} />
      <RegisterSelectPresenter 
      {...props}
      level1Menu={level1Menu}
      setLevel1Menu={setLevel1Menu}
      changeMenu={changeMenu}
      ></RegisterSelectPresenter>
      <Footer {...props} />
    </>
  );
};

export default RegisterSelectContainer;

import React from 'react';

import { FiMinusCircle } from 'react-icons/fi';
import { useDropzone } from 'react-dropzone';
import * as s from '../RegisterSoundStyled';

interface VideoProps {
  isModifying: boolean;
  submitVideo: { file: File; url: string; type: string; description: string };
  setSubmitVideo: (arg1: { file: File; url: string; type: string; description: string }) => void;
  deleteVideo: () => void;
}

const Video = (props: VideoProps) => {
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files: File[]) => {
      props.setSubmitVideo({ ...props.submitVideo, file: files[0] });
    },
  });
  return (
    <>
      <s.UploadProgramAreaTextHeader>
        영상 업로드
        <s.UploadProgramAreaTextHeaderIcon onClick={props.deleteVideo}>
          <FiMinusCircle color={'#F56466'} size={24} />
        </s.UploadProgramAreaTextHeaderIcon>
      </s.UploadProgramAreaTextHeader>
      <s.UploadProgramAreaHeader>
        <s.UploadProgramAreaHeaderSelect>
          <s.UploadProgramAreaHeaderLabel>분야</s.UploadProgramAreaHeaderLabel>
          <s.UploadProgramAreaHeaderInput
            value={props.submitVideo.type}
            onChange={(e) => props.setSubmitVideo({ ...props.submitVideo, type: e.target.value })}
            placeholder={'텍스트를 입력해주세요.'}
            disabled={!props.isModifying}
          />
        </s.UploadProgramAreaHeaderSelect>
      </s.UploadProgramAreaHeader>
      <s.UploadProgramAreaFileUploadArea
        isDrag={isDragActive}
        {...getRootProps({ className: 'dropzone' })}
      >
        <s.UploadProgramAreaFileUploadAreaInput {...getInputProps()} />
        {props.submitVideo.file ? (
          <s.UploadProgramAreaFileUploadAreaButtonIcon
            onClick={open}
            src="/static/img/pdf_icon_1.png"
          />
        ) : null}
        <s.UploadProgramAreaFileUploadAreaText>
          {props.submitVideo.file ? props.submitVideo.file.name : '파일을 선택해주세요.'}
        </s.UploadProgramAreaFileUploadAreaText>
        <s.UploadProgramAreaFileUploadAreaButtonIcon
          onClick={open}
          src={props.submitVideo.file ? '/static/img/file_exist.png' : '/static/img/selectFile.png'}
        />
      </s.UploadProgramAreaFileUploadArea>
      <s.UploadProgramAreaFooter>
        <s.UploadProgramAreaInputArea>
          <s.UploadProgramAreaInputLabel>URL</s.UploadProgramAreaInputLabel>
          <s.UploadProgramAreaInputInput
            value={props.submitVideo.url}
            onChange={(e) => props.setSubmitVideo({ ...props.submitVideo, url: e.target.value })}
            placeholder={'텍스트를 입력해주세요.'}
            disabled={!props.isModifying}
          />
        </s.UploadProgramAreaInputArea>
        <s.UploadProgramAreaFooterTextArea>
          <s.TextAreaInfo>
            <s.TextAreaIntoText>영상 소개 내용</s.TextAreaIntoText>
            <s.TextAreaInfoNotice>(최대 0000글자만 작성 가능)</s.TextAreaInfoNotice>
          </s.TextAreaInfo>
          <s.TextAreaTextInputWrapper>
            <s.TextAreaTextInput
              placeholder="텍스트를 입력해주세요."
              value={props.submitVideo.description}
              onChange={(e) => {
                props.setSubmitVideo({ ...props.submitVideo, description: e.target.value });
              }}
              disabled={!props.isModifying}
            />
          </s.TextAreaTextInputWrapper>
        </s.UploadProgramAreaFooterTextArea>
      </s.UploadProgramAreaFooter>
    </>
  );
};

export default Video;

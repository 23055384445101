import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { boolean } from 'yup';

export const Container = styled.div`
  width: 100%;
  min-width: 1075px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const MainImgArea = styled.div`
  width: 1091px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px auto;
  align-items: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid #e2e2e2;
`;

export const MainImg = styled.img`
  width: 1091px;
  height: 264px;
  object-fit: cover;
`;

export const FilterArea = styled.div`
  width: 1075px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FilterItem = styled.div<{ index: number }>`
  padding: 10px 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  background-color: ${(props) =>
    props.index === 0
      ? '#FFEED9'
      : props.index === 1
      ? '#FFE6D9'
      : props.index === 2
      ? '#FFE5E7'
      : props.index === 3
      ? '#FFE8F2'
      : props.index === 4
      ? '#F3E1FF'
      : props.index === 5
      ? '#E7DBFF'
      : '#D5DEFF'};
  color: ${(props) =>
    props.index === 0
      ? '#FFAC3C'
      : props.index === 1
      ? '#FF9156'
      : props.index === 2
      ? '#EF828B'
      : props.index === 3
      ? '#EF66A4'
      : props.index === 4
      ? '#9968B8'
      : props.index === 5
      ? '#8F75C1'
      : '#6675BB'};

  border-radius: ${(props) =>
    props.index === 0 ? '20px 0 0 20px' : props.index === 6 ? '0 20px 20px 0' : null};

  cursor: pointer;
`;

export const ContentsArea = styled.div`
  width: 1091px;
  margin: 15px auto 30px;
`;

export const TopArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TopAreaText = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: 16px;
  color: #4d4d4d;
  width: 40%;
`;

export const TopAreaButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  width: 60%;
`;

export const TopAreaButton = styled.div`
  border-radius: 50px;
  width: 174px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0087ed;
  color: #0087ed;
  background-color: #ffffff;
  font-size: 16px;
  cursor: pointer;
`;

export const ProgramArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 100px;
`;

export const ProgramText = styled.div<{ fontColor: string }>`
  font-size: 26px;
  color: ${(props) => props.fontColor};
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: left;
  padding-bottom: 10px;
`;

export const ProgramItemArea = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 16px;
`;

export const ProgramItem = styled(Link)`
  width: 202px;
  height: 153px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ProgramItemImg = styled.img`
  height: 90%;
  width: 90%;
  object-fit: contain;
`;

export const ProgramItemText = styled.div`
  height: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

export const DetailFilterWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 15px;
  gap: 15px;
  flex-wrap: wrap;
  border-bottom: 1px solid #bebebe;
  border-top: 1px solid #bebebe;
`;

export const DetailFilter = styled.div<{
  isActive: boolean;
  backgroundColor: string;
  fontColor: string;
}>`
  width: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 18px;
  color: ${(props) => (props.isActive ? props.fontColor : '#BEBEBE')};
  background-color: ${(props) => (props.isActive ? props.backgroundColor : '#ffffff')};
  border-radius: 50px;
  border: 1px solid #bebebe;

  &:hover {
    color: ${(props) => props.fontColor};
    background-color: ${(props) => props.backgroundColor};
  }
`;

export const MonthWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 15px;
  gap: 15px;
  flex-wrap: wrap;
  border-bottom: 1px solid #bebebe;
  border-top: 1px solid #bebebe;
`;

export const MonthItem = styled.div`
  width: 251px;
  height: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const MonthImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 1075px;
  padding: 10px;
  gap: 15px;
  flex-wrap: wrap;
  margin: 0 auto 30px;
`;

export const ProductItem = styled.div`
  width: 251px;
  height: 361px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
`;

export const ProductImage = styled.img`
  width: 100%;
  height: 251px;
  object-fit: cover;
`;

export const ProductDvText = styled.div`
  margin-top: 10px;
  color: #0091ff;
  font-size: 14px;
`;

export const ProductNmText = styled.div`
  margin-top: 10px;
  color: #4d4d4d;
  font-size: 18px;
`;

export const ProductPrice = styled.div`
  width: 100%;
  font-size: 20px;
  color: #4d4d4d;
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 5px;
`;

export const ProductPriceSpan = styled.div`
  font-weight: bold;
`;

export const NoItemsHolder = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #bebebe;
`;

export const SoldOutWrapper = styled.div`
  width: 100%;
  height: 251px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: bold;
  color: #fea0a0;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
`;

export const PagingNav = styled.div`
  width: 100%;
  height: 40px;
  padding: 3px;
  margin-bottom: 50px;
  text-align: center;
  vertical-align: middle;
`;

export const PagingLink = styled.div`
  display: inline-block;
  width: 12px;
  height: 100%;
  margin: 0 3px;

  &:hover {
    cursor: pointer;
  }
`;

export const PagingLinkImg = styled.img``;

export const PagingNumArea = styled.ul`
  display: inline-block;
  height: 100%;
  margin: auto 15px;
  font-size: 17px;
  color: #7e7e7e;
  text-align: center;
  vertical-align: middle;
`;

export const PagingNum = styled.li<{ current: boolean }>`
  display: inline-block;
  margin: 5.5px 2px;
  font-weight: ${(props) => (props.current ? '800' : '600')};
  color: ${(props) => (props.current ? '#4d4d4d' : 'default')};
  padding: 2px 6px;

  &:hover {
    cursor: pointer;
    color: #4d4d4d;
    text-decoration: underline;
    text-decoration-color: #4d4d4d;
  }
`;

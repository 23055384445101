import React, { Dispatch, SetStateAction } from 'react';

import * as util from '../../../util/util';
import * as s from './OrderListStyled';
import NotFound from '../../../components/notFound';
import { Link } from 'react-router-dom';
import DaumPostcode from 'react-daum-postcode';

interface OrderListProps {
  orderList: any[];
  openIdx: number;
  setOpenIdx: Dispatch<SetStateAction<number>>;
}

const OrderListPresenter = (props: OrderListProps) => {
  return (
    <>
      <s.Container>
        <s.OrderListHeader>
          <s.OrderListHeaderTitleArea>
            <s.OrderListHeaderTitle>주문목록</s.OrderListHeaderTitle>
          </s.OrderListHeaderTitleArea>
          <s.OrderListHeaderRightArea>
            <s.OrderListHeaderRightText>
              <s.OrderListHeaderRightIcon src="/static/img/home_icon.jpg" />
              {'HOME > 관리자 > 주문목록'}
            </s.OrderListHeaderRightText>
          </s.OrderListHeaderRightArea>
        </s.OrderListHeader>

        <s.ProgramListArea>
          <s.ProgramList>
            <s.ProgramListHeader>
              <s.ProgramListCenterCol>번호</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>상품</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>수량</s.ProgramListCenterCol>
              <s.ProgramListCenterCol>연락처</s.ProgramListCenterCol>
              <s.ProgramListCenterCol style={{ flex: 3 }}>주소</s.ProgramListCenterCol>
              <s.ProgramListCenterCol style={{ flex: 3 }}>요청사항</s.ProgramListCenterCol>
            </s.ProgramListHeader>
          </s.ProgramList>
          {!props.orderList || props.orderList.length === 0 ? (
            <NotFound />
          ) : (
            props.orderList.map((item: any, index: number) => {
              let count = 0;
              item.orderProductList.map((prod: any) => {
                count += Number(prod.count);
              });
              let prodNm = '';
              if (item.orderProductList.length > 0) {
                if (item.orderProductList.length === 1) {
                  prodNm = item.orderProductList[0].productNm;
                } else {
                  prodNm =
                    item.orderProductList[0].productNm +
                    ' 외 ' +
                    (item.orderProductList.length - 1) +
                    ' 건';
                }
              }

              return (
                <>
                  <s.ProgramForm
                    key={index}
                    onClick={() =>
                      props.openIdx === index ? props.setOpenIdx(-1) : props.setOpenIdx(index)
                    }
                  >
                    <s.ProgramListCenterCol>{index + 1}</s.ProgramListCenterCol>
                    <s.ProgramListCenterCol>{prodNm}</s.ProgramListCenterCol>
                    <s.ProgramListCenterCol>{count}</s.ProgramListCenterCol>
                    <s.ProgramListCenterCol>
                      {item.mobile ? item.mobile : item.telno}
                    </s.ProgramListCenterCol>
                    <s.ProgramListCenterCol style={{ flex: 3 }}>
                      {item.address}
                    </s.ProgramListCenterCol>
                    <s.ProgramListCenterCol style={{ flex: 3 }}>
                      {item.orderDetail}
                    </s.ProgramListCenterCol>
                  </s.ProgramForm>
                  {props.openIdx === index ? (
                    <s.DetailList>
                      {item.orderProductList.map((product: any) => (
                        <s.DetailListItem>
                          <s.ProgramListCenterCol
                            style={{
                              flex: 4,
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                            }}
                          >
                            상품명 :{product.productNm}
                          </s.ProgramListCenterCol>
                          <s.ProgramListCenterCol
                            style={{
                              flex: 6,
                              justifyContent: 'flex-start',
                              alignItems: 'flex-start',
                            }}
                          >
                            수량 : {product.count}EA
                          </s.ProgramListCenterCol>
                        </s.DetailListItem>
                      ))}
                    </s.DetailList>
                  ) : null}
                </>
              );
            })
          )}
        </s.ProgramListArea>

        <s.OrderButton to="/registerSelect">확인</s.OrderButton>
      </s.Container>
    </>
  );
};

export default OrderListPresenter;

import React, { Dispatch, SetStateAction } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import * as s from './EduStyled';

import _ from 'lodash';
import EduPlanContainer from './plan';

interface EduProps extends RouteComponentProps {
  data: {
    menu: string;
    url: string;
    length: number;
    color: string;
    circlecolor: string;
    title: string;
    subtitle: string;
  };
  tab: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
  holderList: any;
  holder: string;
  setHolder: React.Dispatch<React.SetStateAction<string>>;
  getEdu: (type: string) => Promise<void>;
  eduTitle: string;
  fileList: any;
  currentStep: any;
  setCurrentStep: Dispatch<SetStateAction<string>>;
  hspDv: string;
  setHspDv: Dispatch<SetStateAction<string>>;
}

const EduPresenter = (props: EduProps) => {
  return (
    <s.Container>
      <s.EduHeader>
        <s.EduHeaderTitleArea>
          <s.EduHeaderTitle>교육프로그램</s.EduHeaderTitle>
          <s.EduHeaderTitleDivide>|</s.EduHeaderTitleDivide>
          <s.EduHeaderTitle>{props.data.title}</s.EduHeaderTitle>
          <s.EduHeaderSubTitle>{props.data.subtitle}</s.EduHeaderSubTitle>
        </s.EduHeaderTitleArea>
        <s.EduHeaderRightArea>
          <s.EduHeaderRightText>
            <s.EduHeaderRightIcon src="/static/img/home_icon.jpg" />
            {'HOME > 교육프로그램 '}
            {'> '}
            {props.data.title}
          </s.EduHeaderRightText>
        </s.EduHeaderRightArea>
      </s.EduHeader>
      <s.EduTabArea>
        <s.EduTab
          color={props.data.color}
          onClick={() => props.setTab('intro')}
          active={props.tab === 'intro'}
        >
          프로그램 소개
        </s.EduTab>
        <s.EduTab
          color={props.data.color}
          onClick={() => props.setTab('config')}
          active={props.tab === 'config'}
        >
          교재구성
        </s.EduTab>
        <s.EduTab
          color={props.data.color}
          onClick={() => props.setTab('plan')}
          active={props.tab === 'plan'}
        >
          교육계획안
        </s.EduTab>
      </s.EduTabArea>
      <s.BannerArea>
        <s.BannerImg src={props.data.url + 'banner.png'} />
      </s.BannerArea>

      {props.tab === 'intro' ? (
        <s.MainImgArea>
          {_.range(props.data.length).map((value) => (
            <s.MainImg src={props.data.url + 'Image' + (value + 1) + '.png'} />
          ))}
        </s.MainImgArea>
      ) : props.tab === 'config' ? (
        <>
          <s.SearchConditionArea>
            {props.data.menu === 'MN0212' || props.data.menu === 'MN0211' ? null : (
              <s.SearchConditionAgeArea>
                <s.searchConditionHeader>월 선택</s.searchConditionHeader>
                <s.SearchConditionBody>
                  {props.holderList &&
                    Object.entries(props.holderList).map(([key, value]) => (
                      <s.SearchConditionItem
                        selected={props.holder === props.holderList[key].comnCd}
                        onClick={() => props.setHolder(props.holderList[key].comnCd)}
                      >
                        {props.holderList[key].comnCdNm}
                      </s.SearchConditionItem>
                    ))}
                </s.SearchConditionBody>
              </s.SearchConditionAgeArea>
            )}

            {props.data.menu === 'MN0211' ? (
              <s.SearchConditionAgeArea>
                <s.searchConditionHeader>구분 선택</s.searchConditionHeader>
                <s.SearchConditionBody>
                  <s.SearchConditionItem
                    selected={props.hspDv === ''}
                    onClick={() => props.setHspDv('')}
                  >
                    전체
                  </s.SearchConditionItem>
                  <s.SearchConditionItem
                    selected={props.hspDv === '한글'}
                    onClick={() => props.setHspDv('한글')}
                  >
                    한글
                  </s.SearchConditionItem>
                  <s.SearchConditionItem
                    selected={props.hspDv === '수학'}
                    onClick={() => props.setHspDv('수학')}
                  >
                    수학
                  </s.SearchConditionItem>
                </s.SearchConditionBody>
              </s.SearchConditionAgeArea>
            ) : null}

            {props.fileList?.filter(
              (item: any) =>
                item.currentStep !== null &&
                item.currentStep !== undefined &&
                item.currentStep !== '',
            ).length > 0 ? (
              <s.SearchConditionAgeArea>
                <s.searchConditionHeader>단계 선택</s.searchConditionHeader>
                <s.SearchConditionBody>
                  <s.SearchConditionItem
                    selected={props.currentStep === ''}
                    onClick={() => props.setCurrentStep('')}
                  >
                    전체
                  </s.SearchConditionItem>
                  {[...new Set(props.fileList.map((file: any) => file.currentStep))].map(
                    (step: any) => (
                      <s.SearchConditionItem
                        selected={props.currentStep === step}
                        onClick={() => props.setCurrentStep(step)}
                      >
                        {step}단계
                      </s.SearchConditionItem>
                    ),
                  )}
                </s.SearchConditionBody>
              </s.SearchConditionAgeArea>
            ) : null}
          </s.SearchConditionArea>
          <s.ConfigArea color={props.data.color}>
            {/* <s.ConfigData onClick={() => props.getEdu('TYPE1201')}>
              <s.ConfigDataImg src={props.data.url + 'config1.png'} />
              <s.ConfigDataTitle>지침서</s.ConfigDataTitle>
            </s.ConfigData> */}
            {props.data.menu === 'MN0212' || props.data.menu === 'MN0211' ? null : (
              <s.ConfigData onClick={() => props.getEdu('TYPE1202')}>
                <s.ConfigDataImg src={props.data.url + 'config2.png'} />
                <s.ConfigDataTitle
                  style={{ backgroundColor: '#0091FF', borderRadius: '30px', color: '#FFFFFF' }}
                >
                  워크북
                </s.ConfigDataTitle>
              </s.ConfigData>
            )}

            {/* <s.ConfigData onClick={() => props.getEdu('TYPE1203')}>
              <s.ConfigDataImg src={props.data.url + 'config3.png'} />
              <s.ConfigDataTitle>음원</s.ConfigDataTitle>
            </s.ConfigData>
            <s.ConfigVideoData onClick={() => props.getEdu('TYPE1204')}>
              <s.ConfigDataImg src={props.data.url + 'config4.png'} />
              <s.ConfigDataTitle>영상 ( 동화 )</s.ConfigDataTitle>
            </s.ConfigVideoData> */}
            {props.currentStep !== '' && props.hspDv !== ''
              ? props.fileList
                  .filter((item: any) => item.currentStep === props.currentStep)
                  .filter((item: any) => item.name.startsWith(props.hspDv))
                  .map((img: any) => (
                    <s.ConfigData>
                      <s.ConfigDataImg src={img.fileUrl} />
                      <s.ConfigDataTitle>{img.name}</s.ConfigDataTitle>
                    </s.ConfigData>
                  ))
              : props.currentStep !== ''
              ? props.fileList
                  .filter((item: any) => item.currentStep === props.currentStep)
                  .map((img: any) => (
                    <s.ConfigData>
                      <s.ConfigDataImg src={img.fileUrl} />
                      <s.ConfigDataTitle>{img.name}</s.ConfigDataTitle>
                    </s.ConfigData>
                  ))
              : props.hspDv !== ''
              ? props.fileList
                  .filter((item: any) => item.name.startsWith(props.hspDv))
                  .map((img: any) => (
                    <s.ConfigData>
                      <s.ConfigDataImg src={img.fileUrl} />
                      <s.ConfigDataTitle>{img.name}</s.ConfigDataTitle>
                    </s.ConfigData>
                  ))
              : props.fileList.map((img: any) => (
                  <s.ConfigData>
                    <s.ConfigDataImg src={img.fileUrl} />
                    <s.ConfigDataTitle>{img.name}</s.ConfigDataTitle>
                  </s.ConfigData>
                ))}
          </s.ConfigArea>
        </>
      ) : (
        <>
          <EduPlanContainer {...props} color={props.data.color} eduTitle={props.eduTitle} />
        </>
      )}
    </s.Container>
  );
};

export default EduPresenter;

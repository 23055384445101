import React from 'react';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { SetterOrUpdater } from 'recoil';

import DomainModal from './domainModal';
import * as s from './ActivityPlanStyled';

interface ActivityPlanProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  stepList: number[];
  curStep: number;
  setCurStep: React.Dispatch<React.SetStateAction<number>>;
  goNextStep: React.MouseEventHandler<HTMLDivElement>;
  goPreviousStep: React.MouseEventHandler<HTMLDivElement>;
  activityNm: string;
  domain: string;
  setDomain: React.Dispatch<React.SetStateAction<string>>;
  domainNm: string;
  setDomainNm: React.Dispatch<React.SetStateAction<string>>;
  topic: string;
  subTopic: string;
  extension: string;
  expectationList: string[];
  setExpectationList: React.Dispatch<React.SetStateAction<string[]>>;
  onChangeActivityNm: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onChangeTopic: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onChangeSubTopic: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onChangeExtension: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  addExpectation: React.MouseEventHandler<HTMLDivElement>;
  factorList: string[];
  setFactorList: React.Dispatch<React.SetStateAction<string[]>>;
  addFactor: React.MouseEventHandler<HTMLDivElement>;
  methodList: {
    content: string;
    subMethodList: string[];
  }[];
  setMethodList: React.Dispatch<
    React.SetStateAction<{ content: string; subMethodList: string[] }[]>
  >;
  addMethod: React.MouseEventHandler<HTMLDivElement>;
  iconList: any;
  domainList: any;
  checkedIconCd: any;
  setCheckedIconCd: any;
  onSubmit: React.MouseEventHandler<HTMLDivElement>;
  getProgramList: () => Promise<void>;
  fieldList: {
    menuCd: string;
    menuNm: string;
    viewOrder: number;
  }[];
  programSearch: {
    keyword?: string | undefined;
    field?: string | undefined;
  };
  setProgramSearch: React.Dispatch<
    React.SetStateAction<{
      keyword?: string | undefined;
      field?: string | undefined;
    }>
  >;
  programList: any[];
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  pageList: number[];
  lastPageNum: number;
  isFirst: boolean;
  isLast: boolean;
  onChangeSelectedProgramList: (programId: any, icon: string) => void;
  selectedProgramList: any[];
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  note: string;
  setNote: React.Dispatch<React.SetStateAction<string>>;
  playEvaluation: string;
  setPlayEvaluation: React.Dispatch<React.SetStateAction<string>>;
  setProgramId: SetterOrUpdater<number | null>;
  modalProgram: any;
  setModalProgram: React.Dispatch<any>;
  onChangeSelectedProgramPage: (program: any, page: string) => void;
  activityKeyword: string;
  setActivityKeyword: React.Dispatch<React.SetStateAction<string>>;
  onActivityKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  getActivityList: () => Promise<void>;
  activityList: any[];
  setPlanId: SetterOrUpdater<number | null>;
  activityPageNum: number;
  setActivityPageNum: React.Dispatch<React.SetStateAction<number>>;
  activityPageList: number[];
  isActivityFirst: boolean;
  isActivityLast: boolean;
  lastActivityPageNum: number;
  deleteActivity: (activityId: number) => Promise<void>;
  selectUpdateActivity: (activity: any) => void;
  selectedActivity: any;
  setSelectedActivity: React.Dispatch<any>;
}

const ActivityPlanPresenter = (props: ActivityPlanProps) => {
  const ActivityProgramModal = () => {
    return (
      <s.ModalContainer>
        <s.SmallTitleArea>
          <s.TitleRight>
            <s.TitleRightTop>
              {props.modalProgram.age ? (
                <s.TitleRightTopText>
                  만 {props.modalProgram.age.comnCdNm} {props.modalProgram.holder.comnCdNm}
                </s.TitleRightTopText>
              ) : (
                <s.TitleRightTopText>{props.modalProgram.holder.comnCdNm}</s.TitleRightTopText>
              )}
              {props.modalProgram.field ? (
                <s.TitleRightTopIcon>{props.modalProgram.field.menuNm}</s.TitleRightTopIcon>
              ) : null}
            </s.TitleRightTop>
            <s.TitleRightBottom>{props.modalProgram.title}</s.TitleRightBottom>
          </s.TitleRight>
          <s.CloseButton onClick={() => props.setModalProgram(null)}>닫기</s.CloseButton>
        </s.SmallTitleArea>
        {props.modalProgram.programImages && props.modalProgram.programImages.length > 0 ? (
          <s.ContentArea>
            {props.modalProgram.programImages.map((item: any) => (
              <s.ContentImage
                onClick={() => {
                  let temps = [...props.selectedActivity.materialList];
                  let itemIdx = temps
                    .map((item) => item.program.programId)
                    .indexOf(props.modalProgram.programId);
                  if (itemIdx === -1) {
                    props.setModalProgram(null);
                  } else {
                    temps[itemIdx] = {
                      ...temps[itemIdx],
                      subId: item.imageId,
                    };
                    props.setSelectedActivity({
                      ...props.selectedActivity,
                      materialList: temps,
                    });
                    props.setModalProgram(null);
                  }
                }}
                src={item.file.fileUrl}
              />
            ))}
          </s.ContentArea>
        ) : props.modalProgram.programDocuments &&
          props.modalProgram.programDocuments.length > 0 ? (
          <s.ContentArea>
            {props.modalProgram.programDocuments.map((item: any) => (
              <s.ContentImage
                onClick={() => {
                  let temps = [...props.selectedActivity.materialList];
                  let itemIdx = temps
                    .map((item) => item.program.programId)
                    .indexOf(props.modalProgram.programId);
                  if (itemIdx === -1) {
                    props.setModalProgram(null);
                  } else {
                    temps[itemIdx] = {
                      ...temps[itemIdx],
                      subId: item.documentId,
                    };
                    props.setSelectedActivity({
                      ...props.selectedActivity,
                      materialList: temps,
                    });
                    props.setModalProgram(null);
                  }
                }}
                src={item.file.fileUrl}
              />
            ))}
          </s.ContentArea>
        ) : null}
      </s.ModalContainer>
    );
  };
  return (
    <s.Container>
      {props.modalProgram !== null ? <ActivityProgramModal /> : null}
      {props.isModalOpen ? (
        <DomainModal
          isModalOpen={props.isModalOpen}
          setIsModalOpen={props.setIsModalOpen}
          selectedActivity={props.selectedActivity}
          setSelectedActivity={props.setSelectedActivity}
          domainList={props.domainList}
        />
      ) : null}
      <s.ActivityPlanHeader>
        <s.ActivityPlanHeaderTitleArea>
          <s.ActivityPlanHeaderTitle>활동계획안 수정</s.ActivityPlanHeaderTitle>
        </s.ActivityPlanHeaderTitleArea>
        <s.ActivityPlanHeaderRightArea>
          <s.ActivityPlanHeaderRightText>
            <s.ActivityPlanHeaderRightIcon src="/static/img/home_icon.jpg" />
            {'HOME > 마이페이지 > 글수정'}
          </s.ActivityPlanHeaderRightText>
        </s.ActivityPlanHeaderRightArea>
      </s.ActivityPlanHeader>
      <s.RegisterContentArea>
        {props.selectedActivity ? (
          <s.RegisterContentStepBar>
            {props.stepList.map((item: number, index: number) => (
              <>
                {item === props.curStep ? (
                  <>
                    <s.RegisterContentStep active={true}>
                      <s.RegisterContentStepNum>{item}</s.RegisterContentStepNum>
                    </s.RegisterContentStep>
                    <s.RegisterContentStepText>단계</s.RegisterContentStepText>
                  </>
                ) : (
                  <s.RegisterContentStep
                    onClick={() => props.setCurStep(item)}
                    active={false}
                  ></s.RegisterContentStep>
                )}
              </>
            ))}
          </s.RegisterContentStepBar>
        ) : null}
        {props.curStep !== 6 ? (
          <s.RegisterContentHeader>
            <s.RegisterContentHeaderTitle>활동계획안 수정</s.RegisterContentHeaderTitle>
          </s.RegisterContentHeader>
        ) : null}
        {props.curStep === 0 ? (
          <s.RegisterContentArea>
            <s.FileUploadForm>
              <s.ContentList>
                <s.SearchText
                  type="text"
                  name={`keyword`}
                  value={props.activityKeyword}
                  onChange={(e) => props.setActivityKeyword(e.target.value)}
                  placeholder={'텍스트를 입력해주세요.'}
                  onKeyPress={props.onActivityKeyPress}
                ></s.SearchText>
                <s.Button onClick={props.getActivityList}>검색</s.Button>
              </s.ContentList>
              <s.ResultContentWrapper>
                {props.activityList && props.activityList.length > 0 ? (
                  props.activityList.map((activity) => {
                    return (
                      <s.ResultEachItemWrapper key={activity.activityId}>
                        <s.ResultItemInfoTopic>
                          {activity.domain ? activity.domain.comnCdNm : null}
                        </s.ResultItemInfoTopic>
                        <s.ResultItemInfoNm onClick={() => props.setPlanId(activity.activityId)}>
                          {activity.activityNm}
                        </s.ResultItemInfoNm>
                        <s.ResultItemButton onClick={() => props.selectUpdateActivity(activity)}>
                          <s.ContentListButtonImg src={'/static/img/pen.png'} />
                        </s.ResultItemButton>
                        <s.ResultItemButton
                          onClick={() => props.deleteActivity(activity.activityId)}
                        >
                          <s.ContentListButtonImg src={'/static/img/button_minus.png'} />
                        </s.ResultItemButton>
                      </s.ResultEachItemWrapper>
                    );
                  })
                ) : (
                  <s.NoResult>검색 결과가 없습니다.</s.NoResult>
                )}
              </s.ResultContentWrapper>
              <s.PagingNav>
                {props.isActivityFirst ? null : (
                  <s.PagingLink onClick={() => props.setActivityPageNum(props.activityPageNum - 1)}>
                    <s.PagingLinkImg src="/static/img/l_shaft.png" />
                  </s.PagingLink>
                )}

                <s.PagingNumArea>
                  {props.activityPageList.map((page: number, index: number) => (
                    <s.PagingNum
                      key={'page' + index}
                      onClick={() => props.setActivityPageNum(page)}
                      current={props.activityPageNum === page}
                    >
                      {page}
                    </s.PagingNum>
                  ))}
                </s.PagingNumArea>
                {props.isActivityLast ? null : (
                  <s.PagingLink onClick={() => props.setActivityPageNum(props.activityPageNum + 1)}>
                    <s.PagingLinkImg src="/static/img/r_shaft.png" />
                  </s.PagingLink>
                )}
              </s.PagingNav>
            </s.FileUploadForm>
          </s.RegisterContentArea>
        ) : props.curStep === 1 ? (
          <s.RegisterContentForm>
            <s.RegisterContentRow>
              <s.RegisterContentElement>
                <s.RegisterContentElementDomain>활동명</s.RegisterContentElementDomain>
                <s.RegisterContentElementInput
                  type="text"
                  name="activityNm"
                  value={props.selectedActivity.activityNm}
                  onChange={(e) =>
                    props.setSelectedActivity({
                      ...props.selectedActivity,
                      activityNm: e.target.value,
                    })
                  }
                />
              </s.RegisterContentElement>
              <s.RegisterContentElement>
                <s.RegisterContentElementDomain>영역</s.RegisterContentElementDomain>
                <s.RegisterContentElementInput
                  type="text"
                  name="domain"
                  value={props.selectedActivity.domain.comnCdNm}
                  onClick={() => props.setIsModalOpen(true)}
                />
              </s.RegisterContentElement>
            </s.RegisterContentRow>
            <s.RegisterContentRow>
              <s.RegisterContentElement>
                <s.RegisterContentElementDomain>주제</s.RegisterContentElementDomain>
                <s.RegisterContentElementInput
                  type="text"
                  name="topic"
                  value={props.selectedActivity.topic}
                  onChange={(e) =>
                    props.setSelectedActivity({
                      ...props.selectedActivity,
                      topic: e.target.value,
                    })
                  }
                />
              </s.RegisterContentElement>
              <s.RegisterContentElement>
                <s.RegisterContentElementDomain>소주제</s.RegisterContentElementDomain>
                <s.RegisterContentElementInput
                  type="text"
                  name="subTopic"
                  value={props.selectedActivity.subTopic}
                  onChange={(e) =>
                    props.setSelectedActivity({
                      ...props.selectedActivity,
                      subTopic: e.target.value,
                    })
                  }
                />
              </s.RegisterContentElement>
            </s.RegisterContentRow>
            <s.RegisterContentRow>
              <s.RegisterContentFullElement>
                <s.RegisterContentFullElementDomain>유의점</s.RegisterContentFullElementDomain>
                <s.RegisterContentElementTextArea
                  name="note"
                  value={props.selectedActivity.note}
                  onChange={(e) =>
                    props.setSelectedActivity({
                      ...props.selectedActivity,
                      note: e.target.value,
                    })
                  }
                  placeholder={'텍스트를 입력해주세요.'}
                />
              </s.RegisterContentFullElement>
            </s.RegisterContentRow>
            <s.RegisterContentLastRow>
              <s.RegisterContentFullElement>
                <s.RegisterContentFullElementDomain>놀이평가</s.RegisterContentFullElementDomain>
                <s.RegisterContentElementTextArea
                  name="playEvaluation"
                  value={props.selectedActivity.playEvaluation}
                  onChange={(e) =>
                    props.setSelectedActivity({
                      ...props.selectedActivity,
                      playEvaluation: e.target.value,
                    })
                  }
                  placeholder={'텍스트를 입력해주세요.'}
                />
              </s.RegisterContentFullElement>
            </s.RegisterContentLastRow>
          </s.RegisterContentForm>
        ) : props.curStep === 2 ? (
          <s.RegisterContentForm>
            <s.ContentTitleArea>
              <s.ContentTitle titleType="expectation">교사의 기대</s.ContentTitle>
              <s.ContentSubTitle>교사의 기대를 한줄씩 적어주세요.</s.ContentSubTitle>
            </s.ContentTitleArea>
            <s.ContentListArea>
              {props.selectedActivity.expectationList.map((item: any, index: number) => (
                <s.ContentList>
                  <s.ContentListDot />
                  <s.ContentListText
                    type="text"
                    name={`expectation ${index}`}
                    value={item.content}
                    onChange={(e) => {
                      let temps = [...props.selectedActivity.expectationList];
                      temps[index].content = e.target.value;
                      props.setSelectedActivity({
                        ...props.selectedActivity,
                        expectationList: temps,
                      });
                    }}
                    placeholder={'텍스트를 입력해주세요.'}
                  ></s.ContentListText>
                  {index === 0 ? (
                    <s.ContentListButton></s.ContentListButton>
                  ) : (
                    <s.ContentListButton
                      onClick={() => {
                        let temps = [...props.selectedActivity.expectationList];
                        temps.splice(index, 1);
                        props.setSelectedActivity({
                          ...props.selectedActivity,
                          expectationList: temps,
                        });
                      }}
                    >
                      <s.ContentListButtonImg src={'/static/img/button_minus.png'} />
                    </s.ContentListButton>
                  )}
                </s.ContentList>
              ))}
            </s.ContentListArea>
            <s.AddTextButtonArea>
              <s.AddTextButton onClick={props.addExpectation}>
                <s.AddTextButtonImg src={'/static/img/button_text.png'} />
              </s.AddTextButton>
            </s.AddTextButtonArea>
          </s.RegisterContentForm>
        ) : props.curStep === 3 ? (
          <s.RegisterContentForm>
            <s.ContentTitleArea>
              <s.ContentTitle titleType="factor">누리과정 관련요소</s.ContentTitle>
              <s.ContentSubTitle>누리과정을 한줄씩 적어주세요.</s.ContentSubTitle>
            </s.ContentTitleArea>
            <s.ContentListArea>
              {props.selectedActivity.factorList.map((item: any, index: number) => (
                <s.ContentList>
                  <s.ContentListDot />
                  <s.ContentListText
                    type="text"
                    name={`factor ${index}`}
                    value={item.content}
                    onChange={(e) => {
                      let temps = [...props.selectedActivity.factorList];
                      temps[index].content = e.target.value;
                      props.setSelectedActivity({
                        ...props.selectedActivity,
                        factorList: temps,
                      });
                    }}
                    placeholder={'텍스트를 입력해주세요.'}
                  ></s.ContentListText>
                  {index === 0 ? (
                    <s.ContentListButton></s.ContentListButton>
                  ) : (
                    <s.ContentListButton
                      onClick={() => {
                        let temps = [...props.selectedActivity.factorList];
                        temps.splice(index, 1);
                        props.setSelectedActivity({
                          ...props.selectedActivity,
                          factorList: temps,
                        });
                      }}
                    >
                      <s.ContentListButtonImg src={'/static/img/button_minus.png'} />
                    </s.ContentListButton>
                  )}
                </s.ContentList>
              ))}
            </s.ContentListArea>
            <s.AddTextButtonArea>
              <s.AddTextButton onClick={props.addFactor}>
                <s.AddTextButtonImg src={'/static/img/button_text.png'} />
              </s.AddTextButton>
            </s.AddTextButtonArea>
          </s.RegisterContentForm>
        ) : props.curStep === 4 ? (
          <s.RegisterContentForm>
            <s.ContentTitleArea>
              <s.ContentTitle titleType="method">활동 방법</s.ContentTitle>
              <s.ContentSubTitle>활동 방법을 한줄씩 적어주세요.</s.ContentSubTitle>
            </s.ContentTitleArea>
            <s.ContentListArea>
              {props.selectedActivity.methodList.map((item: any, index: number) => (
                <s.MethodArea>
                  <s.ContentList>
                    <s.ContentListDot />
                    <s.ContentListText
                      type="text"
                      name={`method ${index}`}
                      value={item.content}
                      onChange={(e) => {
                        let temps = [...props.selectedActivity.methodList];
                        temps[index].content = e.target.value;
                        props.setSelectedActivity({
                          ...props.selectedActivity,
                          methodList: temps,
                        });
                      }}
                      placeholder={'텍스트를 입력해주세요.'}
                    ></s.ContentListText>
                    {index === 0 ? (
                      <s.ContentListButton></s.ContentListButton>
                    ) : (
                      <s.ContentListButton
                        onClick={() => {
                          let temps = [...props.selectedActivity.methodList];
                          temps.splice(index, 1);
                          props.setSelectedActivity({
                            ...props.selectedActivity,
                            methodList: temps,
                          });
                        }}
                      >
                        <s.ContentListButtonImg src={'/static/img/button_minus.png'} />
                      </s.ContentListButton>
                    )}
                  </s.ContentList>
                  {item.subMethodList ? item.subMethodList.map((subItem: any, subIndex: number) => (
                    <s.SubContentList>
                      <s.SubContentListDot />
                      <s.SubContentTextArea>
                        <s.SubContentTextMark>—</s.SubContentTextMark>
                        <s.SubContentText
                          type="text"
                          name={`subMethod ${subIndex} of method ${index}`}
                          value={subItem.content}
                          onChange={(e) => {
                            let subTemps = [...item.subMethodList];
                            subTemps[subIndex].content = e.target.value;
                            item.subMethodList = subTemps;
                            let temps = [...props.selectedActivity.methodList];
                            temps.splice(index, 1, item);
                            props.setSelectedActivity({
                              ...props.selectedActivity,
                              methodList: temps,
                            });
                          }}
                          placeholder={'텍스트를 입력해주세요.'}
                        ></s.SubContentText>
                      </s.SubContentTextArea>
                      {subIndex === 0 ? (
                        <s.ContentListButton></s.ContentListButton>
                      ) : subIndex === 1 ? (
                        <s.ContentListButton
                          onClick={() => {
                            let newItem: any = { content: '' };
                            let subTemps = [...item.subMethodList, newItem];
                            item.subMethodList = subTemps;
                            let temps = [...props.selectedActivity.methodList];
                            temps.splice(index, 1, item);
                            props.setSelectedActivity({
                              ...props.selectedActivity,
                              methodList: temps,
                            });
                          }}
                        >
                          <s.ContentListButtonImg src={'/static/img/button_plus.png'} />
                        </s.ContentListButton>
                      ) : (
                        <s.ContentListButton
                          onClick={() => {
                            let subTemps = [...item.subMethodList];
                            subTemps.splice(subIndex, 1);
                            item.subMethodList = subTemps;
                            let temps = [...props.selectedActivity.methodList];
                            temps.splice(index, 1, item);
                            props.setSelectedActivity({
                              ...props.selectedActivity,
                              methodList: temps,
                            });
                          }}
                        >
                          <s.ContentListButtonImg src={'/static/img/button_minus.png'} />
                        </s.ContentListButton>
                      )}
                    </s.SubContentList>
                  )) : null}
                </s.MethodArea>
              ))}
            </s.ContentListArea>
            <s.AddTextButtonArea>
              <s.AddTextButton onClick={props.addMethod}>
                <s.AddTextButtonImg src={'/static/img/button_text.png'} />
              </s.AddTextButton>
            </s.AddTextButtonArea>
          </s.RegisterContentForm>
        ) : props.curStep === 5 ? (
          <s.RegisterContentForm>
            <s.ContentTitleArea>
              <s.ContentTitle titleType="extension">확장활동</s.ContentTitle>
              <s.ContentSubTitle>확장활동을 자유롭게 작성해 주세요.</s.ContentSubTitle>
            </s.ContentTitleArea>
            <s.ContentExtensionTextArea>
              <s.ContentExtensionText
                name="activityNm"
                value={props.selectedActivity.extension}
                placeholder={'텍스트를 입력해주세요.'}
                onChange={(e) =>
                  props.setSelectedActivity({
                    ...props.selectedActivity,
                    extension: e.target.value,
                  })
                }
              ></s.ContentExtensionText>
            </s.ContentExtensionTextArea>
          </s.RegisterContentForm>
        ) : props.curStep === 6 ? (
          <>
            <s.FileUploadHeader>
              <s.FileUploadHeaderTitle>놀이자료</s.FileUploadHeaderTitle>
            </s.FileUploadHeader>
            <s.FileUploadForm>
              <s.UploadProgramAreaSelectHeader>놀이 선택</s.UploadProgramAreaSelectHeader>
              <s.UploadProgramAreaSelectDv />
              <s.UploadProgramAreaHeaderSelect>
                {props.fieldList.map((subMenu: any) => (
                  <>
                    <s.UploadProgramAreaSelectType
                      id={subMenu.menuCd}
                      value={subMenu.menuCd}
                      defaultChecked={props.programSearch.field === subMenu.menuCd}
                      onChange={(e) =>
                        props.programSearch.field === e.target.value
                          ? props.setProgramSearch({ ...props.programSearch, field: '' })
                          : props.setProgramSearch({
                              ...props.programSearch,
                              field: e.target.value,
                            })
                      }
                      type={'checkbox'}
                      disabled={false}
                    />
                    <s.UploadProgramAreaSelectTypeLabel htmlFor={subMenu.menuCd}>
                      {props.programSearch.field === subMenu.menuCd ? (
                        <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                      ) : null}
                    </s.UploadProgramAreaSelectTypeLabel>
                    <s.UploadProgramAreaSelectText htmlFor={subMenu.menuCd}>
                      {subMenu.menuNm}
                    </s.UploadProgramAreaSelectText>
                  </>
                ))}
              </s.UploadProgramAreaHeaderSelect>
              <s.ContentList>
                <s.SearchText
                  type="text"
                  name={`keyword`}
                  value={props.programSearch.keyword}
                  onChange={(e) =>
                    props.setProgramSearch({ ...props.programSearch, keyword: e.target.value })
                  }
                  placeholder={'텍스트를 입력해주세요.'}
                  onKeyPress={props.onKeyPress}
                ></s.SearchText>
                <s.Button onClick={props.getProgramList}>검색</s.Button>
              </s.ContentList>
              <s.ResultContentWrapper>
                {props.programList && props.programList.length > 0 ? (
                  props.programList.map((program) => {
                    return (
                      <s.ResultEachItemWrapper key={program.programId}>
                        <s.ResultItemInfoTopic>
                          {program.field
                            ? program.field.menuNm
                            : program.subMenu
                            ? program.subMenu.menuNm
                            : program.topMenu.menuNm}
                        </s.ResultItemInfoTopic>
                        <s.ResultItemInfoNm onClick={() => props.setProgramId(program.programId)}>
                          {program.title}
                        </s.ResultItemInfoNm>
                        {props.iconList.map((icon: any) => (
                          <s.ResultItemButton
                            onClick={() => {
                              let temps = [...props.selectedActivity.materialList];
                              let itemIdx = temps
                                .map((item) => item.program.programId)
                                .indexOf(program.programId);
                              if (itemIdx === -1) {
                                temps.push({
                                  icon: icon,
                                  program: program,
                                });
                              } else {
                                if (temps[itemIdx].icon.comnCd === icon.comnCd) {
                                  temps.splice(itemIdx, 1);
                                } else {
                                  temps.splice(itemIdx, 1, {
                                    icon: icon,
                                    program: program,
                                  });
                                }
                              }
                              props.setSelectedActivity({
                                ...props.selectedActivity,
                                materialList: temps,
                              });
                            }}
                          >
                            {props.selectedActivity.materialList.filter(
                              (item: any) =>
                                item.program.programId === program.programId &&
                                item.icon.comnCd === icon.comnCd,
                            ).length > 0 ? (
                              <s.ResultItemButtonCheckImg src={'/static/img/check.png'} />
                            ) : (
                              <s.ResultItemButtomEmptyImg />
                            )}
                            {icon.comnCdNm}
                          </s.ResultItemButton>
                        ))}
                      </s.ResultEachItemWrapper>
                    );
                  })
                ) : (
                  <s.NoResult>검색 결과가 없습니다.</s.NoResult>
                )}
              </s.ResultContentWrapper>
              <s.UploadProgramAreaSelectHeader>페이지 선택</s.UploadProgramAreaSelectHeader>
              <s.UploadProgramAreaSelectDv />
              <s.SelectedProgramArea>
                {props.selectedActivity.materialList.map((item: any) => (
                  <s.SelectedProgramButton
                    onClick={() => props.setModalProgram(item.program)}
                    icon={item.icon.comnCd}
                  >
                    {item.program.title}
                  </s.SelectedProgramButton>
                ))}
              </s.SelectedProgramArea>
              <s.PagingNav>
                {props.isFirst ? null : (
                  <s.PagingLink onClick={() => props.setPageNum(props.pageNum - 1)}>
                    <s.PagingLinkImg src="/static/img/l_shaft.png" />
                  </s.PagingLink>
                )}

                <s.PagingNumArea>
                  {props.pageList.map((page: number, index: number) => (
                    <s.PagingNum
                      key={'page' + index}
                      onClick={() => props.setPageNum(page)}
                      current={props.pageNum === page}
                    >
                      {page}
                    </s.PagingNum>
                  ))}
                </s.PagingNumArea>
                {props.isLast ? null : (
                  <s.PagingLink onClick={() => props.setPageNum(props.pageNum + 1)}>
                    <s.PagingLinkImg src="/static/img/r_shaft.png" />
                  </s.PagingLink>
                )}
              </s.PagingNav>
            </s.FileUploadForm>
          </>
        ) : null}

        <s.RegisterContentButtonArea>
          {props.curStep === 0 ? null : props.curStep === 6 ? (
            <>
              <s.RegisterContentButton onClick={props.goPreviousStep}>
                {'< 이전단계'}
              </s.RegisterContentButton>
              <s.RegisterContentButton onClick={props.onSubmit}>{'업로드'}</s.RegisterContentButton>
            </>
          ) : (
            <>
              <s.RegisterContentButton onClick={props.goPreviousStep}>
                {'< 이전단계'}
              </s.RegisterContentButton>
              <s.RegisterContentButton onClick={props.goNextStep}>
                {'다음단계 >'}
              </s.RegisterContentButton>
            </>
          )}
        </s.RegisterContentButtonArea>
      </s.RegisterContentArea>
    </s.Container>
  );
};

export default ActivityPlanPresenter;

import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as recoilItem from '../../../util/recoilitem';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import RegisterMultiPresenter from './RegisterMultiPresenter';
import { menuApi } from '../../../api/api-menu';
import { programApi } from '../../../api/api-program';
import { makeMsg } from '../../../util/util';

interface RegisterMultiProps extends RouteComponentProps {
  option: {
    age: string | null;
    holder: string;
    topMenu: string;
    subMenu: string;
    type: string;
    field: string;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      holder: string;
      topMenu: string;
      subMenu: string;
      type: string;
      field: string;
    }>
  >;
  ageAuths: any;
  holderList: any;
}

const RegisterMultiContainer = (props: RegisterMultiProps) => {
  const token = useRecoilValue(recoilItem.token);
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const [isModifying, setIsModifying] = useState(true);
  const [description, setDescription] = useState<string>('');
  const [subMenuList, setSubMenuList] = useState<
    {
      menuCd: string;
      menuNm: string;
      viewOrder: number;
    }[]
  >([]);
  const [fieldList, setFieldList] = useState<
    {
      menuCd: string;
      menuNm: string;
      viewOrder: number;
    }[]
  >([]);
  const [title, setTitle] = useState<string>('');

  const [programMedias, setProgramMedias] = useState<
    {
      thumbnail?: File;
      file?: File;
      description: string;
      mediaUrl?: string;
    }[]
  >([
    {
      description: '',
    },
  ]);

  const addProgramMidea = () => {
    setProgramMedias([...programMedias, { description: '' }]);
  };

  const deleteProgramMidea = (index: number) => {
    const temps = [...programMedias];
    temps.splice(index, 1);
    setProgramMedias(temps);
  };

  const onChangeProgramMedia = (index: number, name: string, value: string | File) => {
    const temps = [...programMedias];
    temps[index] = {
      ...temps[index],
      [name]: value,
    };
    setProgramMedias(temps);
  };

  const getField = async () => {
    try {
      setIsLoading(true);
      await menuApi.getCd('MN03').then((res) => {
        if (res.data.rsltCd === '00') {
          setFieldList(
            res.data.ComnCd.subMenuList.map((item: any) => ({
              menuCd: item.menuCd,
              menuNm: item.menuNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const getSubMenu = async () => {
    try {
      setIsLoading(true);
      await menuApi.getCd('MN05').then((res) => {
        if (res.data.rsltCd === '00') {
          setSubMenuList(
            res.data.ComnCd.subMenuList.map((item: any) => ({
              menuCd: item.menuCd,
              menuNm: item.menuNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getSubMenu();
    getField();
  }, []);

  const submit = async () => {
    try {
      setIsLoading(true);
      let formData: FormData = new FormData();
      if (!props.option.age) {
        makeMsg('연령을 선택해주세요.', 'error');
        return;
      }
      if (!props.option.subMenu) {
        makeMsg('분야를 선택해주세요.', 'error');
        return;
      }
      if (!props.option.holder) {
        makeMsg('월(호)를 선택해주세요.', 'error');
        return;
      }
      // if (!props.option.field) {
      //   makeMsg('놀이를 선택해주세요.', 'error');
      //   return;
      // }
      if (!title) {
        makeMsg('제목을 입력해주세요.', 'error');
        return;
      }
      formData.append('age', props.option.age);
      formData.append('topMenu', props.option.topMenu);
      formData.append('subMenu', props.option.subMenu);
      formData.append('holder', props.option.holder);
      formData.append('field', props.option.field);
      formData.append('title', title);
      formData.append('isEduTool', 'N');

      programMedias.map(
        (
          item: {
            thumbnail?: File | undefined;
            file?: File | undefined;
            description: string;
            mediaUrl?: string;
          },
          index: number,
        ) => {
          if (index === 0) {
            item.thumbnail && formData.append('thumbnail.file', item.thumbnail);
          }
          item.description && formData.append(`programMedias[${index}].title`, item.description);
          item.file && formData.append(`programMedias[${index}].file.file`, item.file);
          item.thumbnail &&
            formData.append(`programMedias[${index}].thumbnail.file`, item.thumbnail);
          item.mediaUrl && formData.append(`programMedias[${index}].mediaUrl`, item.mediaUrl);
        },
      );

      await programApi.createProgram(token, formData).then((res) => {
        if (res.data.rsltCd === '00') {
          makeMsg('업로드에 성공하였습니다.', 'success');
          setIsLoading(false);
          props.history.push('/registerSelect');
        } else {
          makeMsg('업로드에 실패하였습니다.', 'error');
          setIsLoading(false);
        }
      });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <RegisterMultiPresenter
      {...props}
      isModifying={isModifying}
      setIsModifying={setIsModifying}
      subMenuList={subMenuList}
      fieldList={fieldList}
      description={description}
      setDescription={setDescription}
      submit={submit}
      title={title}
      setTitle={setTitle}
      programMedias={programMedias}
      onChangeProgramMedia={onChangeProgramMedia}
      addProgramMidea={addProgramMidea}
      deleteProgramMidea={deleteProgramMidea}
    />
  );
};

export default RegisterMultiContainer;

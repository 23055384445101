import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import UserListPresenter from './UserListPresenter';
import * as Yup from 'yup';
import * as util from '../../../util/util';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import * as recoilItem from '../../../util/recoilitem';

import { agenApi } from '../../../api/api-agen';
import { compApi } from '../../../api/api-comp';
import { userApi } from '../../../api/api-user';
import { useRecoilValue, useRecoilState } from 'recoil';

interface UserListProps extends RouteComponentProps {}

const UserListContainer = (props: UserListProps) => {
  const token = useRecoilValue(recoilItem.token);

  const userTypeList = [
    { cd: 'MASTER', nm: '관리자' },
    { cd: 'USER', nm: '정회원' },
    { cd: 'NAIVUSER', nm: '준회원' },
  ];
  const [selectedUserType, setSelectedUserType] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [userList, setUserList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let data: FormData = new FormData();
    data.append('page', String(page - 1));

    if (selectedUserType) {
      data.append('userTypeCd', selectedUserType);
    }

    if (userName) {
      data.append('userName', '%' + userName + '%');
    }

    let res = await userApi.getUserList(token, data);
    console.log(res);
    if (res.data.rsltCd === '00') {
      setUserList(res.data.userList.content);
      setTotalCount(res.data.totalCount);
    } else {
      util.makeMsg('조회된 데이터가 없습니다.', 'error');
    }
  };

  const updateUser = async (user: any) => {
    console.log(user);

    let data: FormData = new FormData();
    data.append('userKey', user.userKey);
    data.append('userTypeCd', user.userTypeCd);

    let res = await userApi.updateUserType(token, data);

    if (res.data.rsltCd === '00') {
      util.makeMsg('저장되었습니다.', 'success');
      fetchData();
    } else {
      util.makeMsg('저장에 실패하였습니다.', 'error');
    }
  };

  const handlePageChange = async (page: number) => {
    setPage(page);
  };

  return (
    <>
      <Header {...props} />
      <UserListPresenter
        selectedUserType={selectedUserType}
        setSelectedUserType={setSelectedUserType}
        userName={userName}
        setUserName={setUserName}
        page={page}
        setPage={setPage}
        userTypeList={userTypeList}
        fetchData={fetchData}
        userList={userList}
        setUserList={setUserList}
        totalCount={totalCount}
        updateUser={updateUser}
        handlePageChange={handlePageChange}
      ></UserListPresenter>
      <Footer {...props} />
    </>
  );
};

export default UserListContainer;

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Header from '../../components/header';
import Footer from '../../components/footer';
import * as s from './UpdateEduStyled';

import SelectEdu from './component/SelectEdu';
import SelectHolder from './component/SelectHolder';
import SelectType from './component/SelectType';

import SelectVisible from './component/SelectVisible';
import SelectAge from './component/SelectAge';
import SelectField from './component/SelectField';
import SelectMedia from './component/SelectMedia';
import SelectStep from './component/SelectStep';
import SelectSubTab from './component/SelectSubTab';

interface UpdateEduProps extends RouteComponentProps {
  isModifying: boolean;
  setIsModifying: React.Dispatch<React.SetStateAction<boolean>>;
  submit: () => Promise<void>;
  ageList: any;
  fieldList: {
    menuCd: string;
    menuNm: string;
    viewOrder: number;
  }[];
  mediaSubMenuList: any;
  option: {
    edu: string;
    holder: string;
    type: string;
    field: string;
    visible: string;
    age: string;
    subTab: string;
    step: string;
    mediaSubMenu: string;
    description: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  eduList: {
    menuCd: string;
    menuNm: string;
    viewOrder: number;
  }[];
  holderList: {
    comnCd: string;
    comnCdNm: string;
    viewOrder: number;
  }[];
  typeList: {
    comnCd: string;
    comnCdNm: string;
    viewOrder: number;
  }[];
  edu: any;
  getEdu: () => Promise<void>;
  deleteEdu: () => Promise<void>;
}

const UpdateEduPresenter = (props: UpdateEduProps) => {
  return (
    <>
      <Header {...props} />
      <s.Container>
        <s.ProgramHeader>
          <s.ProgramHeaderTitle>교육프로그램 수정</s.ProgramHeaderTitle>
          <s.ProgramHeaderRightArea>
            <s.ProgramHeaderRightText>
              <s.ProgramHeaderRightIcon src="/static/img/home_icon.jpg" />
              {'HOME > 마이페이지 > 글수정'}
            </s.ProgramHeaderRightText>
          </s.ProgramHeaderRightArea>
        </s.ProgramHeader>
        <SelectEdu
          eduList={props.eduList}
          onChangeOption={props.onChangeOption}
          option={props.option}
          isModifying={props.isModifying}
        />
        <SelectHolder
          holderList={props.holderList}
          onChangeOption={props.onChangeOption}
          option={props.option}
          isModifying={props.isModifying}
        />
        <SelectType
          typeList={props.typeList}
          onChangeOption={props.onChangeOption}
          option={props.option}
          isModifying={props.isModifying}
        />
        <SelectStep
          onChangeOption={props.onChangeOption}
          option={props.option}
          isModifying={props.isModifying}
        />
        {props.option.edu === 'MN0205' ? (
          <SelectSubTab
            onChangeOption={props.onChangeOption}
            option={props.option}
            isModifying={props.isModifying}
          />
        ) : null}
        <s.UploadArea>
          <s.UploadAreaHeader>
            <s.UploadAreaHeaderLeft>교육프로그램 수정</s.UploadAreaHeaderLeft>
          </s.UploadAreaHeader>
          <s.UploadContentArea>
            <s.ButtonArea>
              <s.SubmitButton onClick={props.getEdu}>
                <s.SubmitButtonText>가져오기</s.SubmitButtonText>
              </s.SubmitButton>
            </s.ButtonArea>
          </s.UploadContentArea>
          {props.edu === null ? null : (
            <s.UploadContentArea>
              <s.UploadProgramTitle>
                {props.edu.title}
              </s.UploadProgramTitle>
              <s.UploadProgramArea>
                <s.UploadProgramAreaHeader>
                  <s.UploadProgramAreaFooterTextArea>
                    <s.TextAreaInfo>
                      <s.TextAreaIntoText>소개 내용</s.TextAreaIntoText>
                      <s.TextAreaInfoNotice>(최대 0000글자만 작성 가능)</s.TextAreaInfoNotice>
                    </s.TextAreaInfo>
                    <s.TextAreaTextInputWrapper>
                      <s.TextAreaTextInput
                        placeholder="소개를 입력해주세요."
                        value={props.option.description}
                        onChange={(e) => props.onChangeOption('description', e.target.value)}
                        disabled={!props.isModifying}
                      />
                    </s.TextAreaTextInputWrapper>
                  </s.UploadProgramAreaFooterTextArea>
                  <SelectVisible
                    option={props.option}
                    onChangeOption={props.onChangeOption}
                    isModifying={props.isModifying}
                  />
                  {props.option.visible === 'visible' ? (
                    <>
                      <SelectField
                        option={props.option}
                        onChangeOption={props.onChangeOption}
                        isModifying={props.isModifying}
                        fieldList={props.fieldList}
                      />
                      <SelectAge
                        option={props.option}
                        onChangeOption={props.onChangeOption}
                        isModifying={props.isModifying}
                        ageList={props.ageList}
                      />
                      {false ? (
                        <SelectMedia
                          option={props.option}
                          onChangeOption={props.onChangeOption}
                          isModifying={props.isModifying}
                          mediaSubMenuList={props.mediaSubMenuList}
                        />
                      ) : null}
                    </>
                  ) : null}
                </s.UploadProgramAreaHeader>
              </s.UploadProgramArea>
              <s.ButtonArea>
                <s.SubmitButton onClick={props.submit}>
                  <s.SubmitButtonText>수정</s.SubmitButtonText>
                </s.SubmitButton>
                <s.DeleteButton onClick={props.deleteEdu}>
                  <s.SubmitButtonText>삭제</s.SubmitButtonText>
                </s.DeleteButton>
              </s.ButtonArea>
            </s.UploadContentArea>
          )}
        </s.UploadArea>
      </s.Container>
      <Footer {...props} />
    </>
  );
};

export default UpdateEduPresenter;

import styled from 'styled-components';

export const Container = styled.div`
  /* width: 100%;
  height: 100%; */
  margin: 0 auto;
  text-align: center;
  z-index: 9980;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  width: 50%;
  height: 350px;
  margin: 0 auto;
  z-index: 9981;
  text-align: center;
  top: 30%;
  left: 0;
  right: 0;
  position: absolute;
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #0087ed;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export const SelectTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`;

export const SelectTbody = styled.tbody``;

export const SelectTr = styled.tr``;

export const SelectTh = styled.th`
  font-size: 14px;
  background-color: #cecece;
  padding: 10px 15px;
  text-align: left;
  width: 20%;
  border: 1px solid #ababab;
`;

export const SelectTd = styled.td`
  font-size: 14px;
  text-align: left;
  padding: 10px 15px;
  width: 80%;
  border: 1px solid #ababab;
`;

export const ContentConfirmButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 50px;
`;

export const ContentConfirmButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 40px;
  margin: 20px 0;
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background-color: #0087ed;
  cursor: pointer;
`;

export const SelectTableSelect = styled.select`
  padding: 5px 10px;
  font-size: 14px;
`;

import * as s from '../UpdateEduStyled';

interface SelectAgeProps {
  option: {
    field: string;
    visible: string;
    age: string;
    mediaSubMenu: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
  ageList: {
    comnCd: string;
    comnCdNm: string;
    viewOrder: number;
  }[];
}

const SelectAge = (props: SelectAgeProps) => {
  return (
    <s.UploadProgramAreaHeader>
      <s.UploadProgramAreaSelectHeader>연령 선택</s.UploadProgramAreaSelectHeader>
      <s.UploadProgramAreaSelectDv />
      <s.UploadProgramAreaHeaderSelect>
        {props.ageList.map((type: any) => (
          <>
            <s.UploadProgramAreaSelectType
              id={type.comnCd}
              value={type.comnCd}
              defaultChecked={props.option.age === type.comnCd}
              onChange={(e: any) =>
                props.option.age === e.target.value
                  ? props.onChangeOption('age', '')
                  : props.onChangeOption('age', e.target.value)
              }
              type={'checkbox'}
              disabled={!props.isModifying}
            />
            <s.UploadProgramAreaSelectTypeLabel htmlFor={type.comnCd}>
              {props.option.age === type.comnCd ? (
                <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
              ) : null}
            </s.UploadProgramAreaSelectTypeLabel>
            <s.UploadProgramAreaSelectText htmlFor={type.comnCd}>
              {type.comnCdNm}
            </s.UploadProgramAreaSelectText>
          </>
        ))}
      </s.UploadProgramAreaHeaderSelect>
    </s.UploadProgramAreaHeader>
  );
};

export default SelectAge;

import Axios, { AxiosInstance } from 'axios';
import { API_SERVER } from '../config/config';

import * as util from '../util/util';

export const notLoginedApi: AxiosInstance = Axios.create({
  baseURL: `${API_SERVER}`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const loginedApi: AxiosInstance = Axios.create({
  baseURL: `${API_SERVER}`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

// /*
//     1. 요청 인터셉터
//     2개의 콜백 함수를 받습니다.
// */
// notLoginedApi.interceptors.request.use(
//   function (config: any) {
//     return config;
//   },
//   function (error) {
//     // 요청 에러 직전 호출됩니다.
//     return Promise.reject(error);
//   },
// );

/*
  2. 응답 인터셉터
  2개의 콜백 함수를 받습니다.
*/
notLoginedApi.interceptors.response.use(
  function (response) {
    /*
        http status가 200인 경우
        응답 성공 직전 호출됩니다. 
        .then() 으로 이어집니다.
    */
    return response;
  },

  function (error) {
    /*
        http status가 200이 아닌 경우
        응답 에러 직전 호출됩니다.
        .catch() 으로 이어집니다.    
    */
    util.chkResultCd(error.response.data.rsltCd);
    return Promise.reject(error);
  },
);

/*
    1. 요청 인터셉터
    2개의 콜백 함수를 받습니다.
*/
loginedApi.interceptors.request.use(
  async function (config: any) {
    let token = {
      accessToken: config.headers['X-AUTH-TOKEN'],
      refreshToken: config.headers['X-AUTH-REFRESH-TOKEN']
    }
    let check = util.tokenCheck(token);
    // if (check === "refresh") {
    //   let newToken = await util.getNewToken(token);
    //   console.log("NewTokenRefresh :: ", newToken);
    //   config.headers['X-AUTH-TOKEN'] = newToken.accessToken;
    //   config.headers['X-AUTH-REFRESH-TOKEN'] = newToken.refreshToken;
    //   return config;
    // } else 
    if (check) {
      return config;
    } else {
      util.makeMsg('로그인이 필요한 서비스입니다.', 'etc');
      window.location.href = '/login';
    }
  },
  function (error) {
    // 요청 에러 직전 호출됩니다.
    return Promise.reject(error);
  },
);

/*
  2. 응답 인터셉터
  2개의 콜백 함수를 받습니다.
*/
loginedApi.interceptors.response.use(
  function (response) {
    /*
        http status가 200인 경우
        응답 성공 직전 호출됩니다.
        .then() 으로 이어집니다.
    */

    return response;
  },

  function (error) {
    /*
        http status가 200이 아닌 경우
        응답 에러 직전 호출됩니다.
        .catch() 으로 이어집니다.
    */
    util.chkResultCd(error.response.data.rsltCd);
    return Promise.reject(error);
  },
);

import Router from './views/Router';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { RecoilRoot } from 'recoil';

const App = () => {
  return (
    <RecoilRoot>
      <Router />
    </RecoilRoot>
  );
};

App.defaultProps = {};

export default App;

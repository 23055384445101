import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  min-width: 1180px;
  min-height: 700px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const FileArea = styled.div`
  width: 1180px;
  margin: 5rem auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5rem;
  flex-wrap: wrap;
`;

export const FileContainer = styled.div`
  width: 15rem;
  height: 30rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
`;

export const FileImage = styled.img`
  width: 15rem;
  height: 15rem;
  border: 1px solid #cecece;
  border-radius: 1rem;
  object-fit: cover;
`;

export const FileInputArea = styled.input`
  width: 15rem;
  height: 15rem;
  border: 1px solid #cecece;
  border-radius: 1rem;
`;

export const FileName = styled.input`
  width: 15rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #cecece;
  border-radius: 1rem;
`;

export const OrderRow = styled.div`
  width: 15rem;
  height: 2rem;
  display: felx;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;

export const OrderLabel = styled.div`
  width: 4rem;
  height: 2rem;
  font-size: 1rem;
  color: #000000;
  font-family: 'Gosanja';
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FileOrder = styled.input`
  width: 10rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #cecece;
  border-radius: 1rem;
`;

export const AddButton = styled.div`
  width: 15rem;
  height: 17rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const RemoveButton = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
  cursor: pointer;
  border-radius: 5rem;
  background-color: #fe4545;
`;

export const SaveButton = styled.div`
  padding: 1rem 1.5rem;
  width: 12rem;
  border-radius: 3rem;
  background-color: #0091ff;
  color: #ffffff;
  font-family: 'Gosanja';
  font-weight: bold;
  font-size: 2rem;
  margin: 2rem auto 5rem;
  cursor: pointer;
`;

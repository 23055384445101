import React from 'react';
import RGL from 'react-grid-layout';
import * as s from './MonthlyPlanStyled';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/pagination/pagination.scss';
import { SetterOrUpdater } from 'recoil';
import * as util from '../../../../util/util';
import NotFound from '../../../../components/notFound';

import { saveAs } from 'file-saver';
const GridLayout = RGL.WidthProvider(RGL.Responsive);

//for Img Src
const imgUrl = '/static/img/';
const imgType = '.png';

type MonthlyPlanProps = {
  notSupport: () => void;
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  monthlyPlan: any;
  tableDatas: any[];
  setPlanId: SetterOrUpdater<number | null>;
  isShowHeader: (args0: string) => boolean;
  createPlanFile: (planId: string) => Promise<void>;
  color: string;
  eduTitle: string;
};

const MonthlyPlanPresenter = (props: MonthlyPlanProps) => {
  if (!props.monthlyPlan) {
    return (
      <s.Container>
        <NotFound />
      </s.Container>
    );
  }
  return (
    <s.Container>
      <s.PlanningTitleArea color={props.color}>
        <s.PlanningTitle>
          만{' '}<s.PlanningTitleSpan color={props.color}>{props.monthlyPlan.age.comnCdNm}</s.PlanningTitleSpan>{' '}
          <s.PlanningTitleSpan color={props.color}>{props.option.year}년</s.PlanningTitleSpan> {' '}
          <s.PlanningTitleSpan color={props.color}>{props.option.month + '월'}</s.PlanningTitleSpan> 월간 교육계획안
        </s.PlanningTitle>
        <s.PlanningDownloadBtn
            color={props.color}
            onClick={() => {
              if (!props.monthlyPlan.planId) {
                util.makeMsg('권한이 부족합니다.', 'error');
              } else {
                if (props.monthlyPlan.file) {
                  saveAs(props.monthlyPlan.file.fileUrl, props.monthlyPlan.file.originalFileName);
                } else {
                  props.createPlanFile(props.monthlyPlan.planId);
                }
              }
            }}
          >
            <s.DownloadBtnIcon src="/static/img/download_button.png" />
            <s.PlanningDownloadText>월간 교육계획안 다운로드</s.PlanningDownloadText>
        </s.PlanningDownloadBtn>
      </s.PlanningTitleArea>
      
      <s.TopContentsArea color={props.color}>
        <s.TopContentsRow style={{borderTop: '0px'}}>
          <s.TopContentHorizontalSection style={{width: '50%'}}>
            <s.TopContentHeaderBox>연령</s.TopContentHeaderBox>
            <s.TopContentTextArea>만 {props.monthlyPlan.age.comnCdNm}</s.TopContentTextArea>
          </s.TopContentHorizontalSection>
          <s.TopContentHorizontalSection style={{width: '50%'}}>
            <s.TopContentHeaderBox>기간</s.TopContentHeaderBox>
            <s.TopContentTextArea>{props.option.year}년 {props.option.month}월 (
              {props.option.month}월 1일 ~{' '}
              {props.option.month + 1 <= 12 ? props.option.month + 1 : 1}월 2일)</s.TopContentTextArea>
          </s.TopContentHorizontalSection>
        </s.TopContentsRow>
        <s.TopContentsRow style={{flexDirection: 'column'}}>
          <s.TopContentHorizontalSection>
            <s.TopContentHeaderBox>생활주제</s.TopContentHeaderBox>
            <s.TopContentTextArea>{props.monthlyPlan.subject}</s.TopContentTextArea>
          </s.TopContentHorizontalSection>
          <s.TopContentHorizontalSection>
            <s.TopContentHeaderBox>주제 선정 이유</s.TopContentHeaderBox>
            <s.TopContentTextArea>
            {
              props.monthlyPlan.description ?
              props.monthlyPlan.description.split('\n').map((row: any) => {
                return <s.TopContentTextArea>{row}</s.TopContentTextArea>
              })
              : null
            }
            </s.TopContentTextArea>
            {/* <s.TopContentTextArea>{props.monthlyPlan.description}</s.TopContentTextArea> */}
          </s.TopContentHorizontalSection>
        </s.TopContentsRow>
        <s.TopContentsRow>
          <s.TopContentHorizontalSection>
            <s.TopContentHeaderBox>교사의 기대</s.TopContentHeaderBox>
            <s.TopContentTextArea>
            {
              props.monthlyPlan.expectations ?
              props.monthlyPlan.expectations.split('\n').map((row: any) => {
                return <s.TopContentTextArea>{row}</s.TopContentTextArea>
              })
              : null
            }
            </s.TopContentTextArea>
            {/* <s.TopContentTextArea>{props.monthlyPlan.expectations}</s.TopContentTextArea> */}
          </s.TopContentHorizontalSection>
        </s.TopContentsRow>
      </s.TopContentsArea>


      {/* ORG START */}
      {/* <s.TopContentsArea>
        <s.TopContentsLeft>
          <s.TopContentsLeftIcon src="/static/img/tying download_button_1.jpg" />
          <s.PlanningDownloadBtn
            onClick={() => {
              if (!props.monthlyPlan.planId) {
                util.makeMsg('권한이 부족합니다.', 'error');
              } else {
                if (props.monthlyPlan.file) {
                  saveAs(props.monthlyPlan.file.fileUrl, props.monthlyPlan.file.originalFileName);
                } else {
                  props.createPlanFile(props.monthlyPlan.planId);
                }
              }
            }}
          >
            <s.DownloadBtnIcon src="/static/img/download_button.png" />
            <s.PlanningDownloadText>월간 교육계획안 다운로드</s.PlanningDownloadText>
          </s.PlanningDownloadBtn>
        </s.TopContentsLeft>
        <s.TopContentsRight>
          <s.TopContentsRow>
            <s.TopRightTitle>연령 / 기간</s.TopRightTitle>
            <s.TopRightText>
              만 {props.monthlyPlan.age.comnCdNm} / {props.option.year}년 {props.option.month}월 (
              {props.option.month}월 1일 ~{' '}
              {props.option.month + 1 <= 12 ? props.option.month + 1 : 1}월 2일)
            </s.TopRightText>
          </s.TopContentsRow>
          <s.TopContentsRow>
            <s.TopRightTitle>생활주제</s.TopRightTitle>
            <s.TopRightText>{props.monthlyPlan.subject}</s.TopRightText>
          </s.TopContentsRow>
          <s.TopContentsRow>
            <s.TopRightTitle>주제 선정이유</s.TopRightTitle>
            <s.TopRightLineHeightText>{props.monthlyPlan.description}</s.TopRightLineHeightText>
          </s.TopContentsRow>
          <s.TopContentsRow>
            <s.TopRightTitle>교사의 기대</s.TopRightTitle>
            <s.TopRightLineHeightPre>{props.monthlyPlan.expectations}</s.TopRightLineHeightPre>
          </s.TopContentsRow>
        </s.TopContentsRight>
      </s.TopContentsArea> */}
      <s.TableIconInfoArea>
        <s.TableIconInfoImg src="/static/img/multimedia_icon.png" />
        <s.TableIconInfoText>멀티미디어</s.TableIconInfoText>
        <s.TableIconInfoImg src="/static/img/Image_icon.png" />
        <s.TableIconInfoText>사진, 동영상, 음원</s.TableIconInfoText>
        <s.TableIconInfoImg src="/static/img/activity_icon.png" />
        <s.TableIconInfoText>활동지 / 도안</s.TableIconInfoText>
        <s.TableIconInfoImg src="/static/img/book_icon.png" />
        <s.TableIconInfoText>활동교구</s.TableIconInfoText>
        <s.TableIconInfoImg src="/static/img/textbook_icon.png" />
        <s.TableIconInfoText>교재</s.TableIconInfoText>
        <s.TableIconInfoImg src="/static/img/text_icon.png" />
        <s.TableIconInfoText>문서 / 서식</s.TableIconInfoText>
      </s.TableIconInfoArea>
      <s.GridLayoutWrapper>
        <s.GridTable>
          <s.GridTr>
            <s.GridTdHeader color={props.color} colSpan={2}>주차</s.GridTdHeader>
            <s.GridTdHeader color={props.color} colSpan={2}>1주</s.GridTdHeader>
            <s.GridTdHeader color={props.color} colSpan={2}>2주</s.GridTdHeader>
            <s.GridTdHeader color={props.color} colSpan={2}>3주</s.GridTdHeader>
            {props.monthlyPlan.week >= 4 ? (
              <s.GridTdHeader color={props.color} colSpan={2}>4주</s.GridTdHeader>
            ) : null}
            {props.monthlyPlan.week >= 5 ? (
              <s.GridTdHeader color={props.color} colSpan={2}>5주</s.GridTdHeader>
            ) : null}
          </s.GridTr>
          {
            props.tableDatas.length > 0 ?
            props.tableDatas.map((row: any, indexR: number) => {
              return(
                <s.GridTr>
                  {
                    row.map((item: any, index: number) => {
                      return (
                        <s.GridTd rowSpan={item.h} colSpan={item.w}
                        colorDiv = {
                          indexR === 0 ? 'G'
                          : 
                          item.x === 0 && item.activityList.length > 0 ?
                            item.activityList[0].type.comnCdNm === '등원' || item.activityList[0].type.comnCdNm === '활동' ?
                            'Y' : 'B'
                          : 
                          item.x === 1 && item.w === 1 ? 
                            props.monthlyPlan.dataList.filter((data: any) => data.x === item.x-1 && data.w === 1).length > 0 ?
                              props.monthlyPlan.dataList.filter((data: any) => data.x === item.x-1 && data.w === 1)[0].activityList[0]?.type.comnCdNm === '등원' || props.monthlyPlan.dataList.filter((data: any) => data.x === item.x-1 && data.w === 1)[0].activityList[0]?.type.comnCdNm === '활동' ?
                                'LY'
                              : 'LB'
                            :
                            item.activityList.length > 0 ? 
                            ''
                          : ''
                          : ''}
                        isCenterAlign={true}
                        
                        >
                          {
                            item.activityList && item.activityList.length > 0 ?
                            item.activityList.map((activity: any, index: number) => {
                              
                              let containing = false;

                              if (activity.planActivity && activity.planActivity.materialList) {
                                activity.planActivity.materialList.map((item: any, idx: number) => {
                                  if (item.program && item.program.eduProgram) {
                                    item.program.eduProgram.map((eduItem: any, eduIdx: number) => {
                                      if (eduItem.subMenu.menuCd === props.eduTitle) {
                                        containing = true
                                      }
                                    })
                                  }
                                });
                              }

                              return (  
                              activity.planActivity ?
                              <s.GridTdText
                              isBold={item.x === 0 || item.w === 1 && item.x === 1}
                                isTextCenter={true}
                                color={
                                  // activity.planActivity.materialList && activity.planActivity.materialList.length > 0 &&
                                  // activity.planActivity.materialList[0].program &&
                                  // activity.planActivity.materialList[0].program.eduProgram && activity.planActivity.materialList[0].program.eduProgram.length > 0 &&
                                  // activity.planActivity.materialList[0].program.eduProgram[0].subMenu && 
                                  // activity.planActivity.materialList[0].program.eduProgram[0].subMenu.menuCd === props.eduTitle ?
                                  containing ?
                                  props.color : '#ABABAB'
                                }
                                style={{
                                  textAlign: 'left',
                                  width: '100%'}}
                                disabled={ 
                                  // !(activity.planActivity.materialList && activity.planActivity.materialList.length > 0 &&
                                  // activity.planActivity.materialList[0].program &&
                                  // activity.planActivity.materialList[0].program.eduProgram && activity.planActivity.materialList[0].program.eduProgram.length > 0 &&
                                  // activity.planActivity.materialList[0].program.eduProgram[0].subMenu && 
                                  // activity.planActivity.materialList[0].program.eduProgram[0].subMenu.menuCd === props.eduTitle)
                                  !containing
                                }
                                onClick={() => {
                                  // if (activity.planActivity.activityId && activity.planActivity.materialList && activity.planActivity.materialList.length > 0 &&
                                  //   activity.planActivity.materialList[0].program &&
                                  //   activity.planActivity.materialList[0].program.eduProgram && activity.planActivity.materialList[0].program.eduProgram.length > 0 &&
                                  //   activity.planActivity.materialList[0].program.eduProgram[0].subMenu && 
                                  //   activity.planActivity.materialList[0].program.eduProgram[0].subMenu.menuCd === props.eduTitle) {
                                    if (containing) {
                                    props.setPlanId(activity.planActivity.activityId)
                                  }
                                }}>
                                <s.GridTdTextDot/>
                                {activity.planActivity.activityNm}
                                {activity.planActivity.materialList.map((material: any, index: number) => {
                                  if (material.program.eduProgram && material.program.eduProgram.length > 0) {
                                    console.log(props.eduTitle);
                                    console.log(material.program.eduProgram[0].subMenu.menuCd)
                                    if (material.program.eduProgram[0].subMenu.menuCd === props.eduTitle) {
                                      return (
                                        <s.GridImage
                                          key={'material' + material.icon.comnCd}
                                          src={`${imgUrl}${material.icon.comnCd}${imgType}`}
                                        />
                                      );
                                    }
                                  }
                                  
                              })}
                              </s.GridTdText>
                              :
                              <s.GridTdText
                                isBold={item.x === 0 || item.w === 1 && item.x === 1}
                                isTextCenter={true}
                                color={
                                  '#ABABAB'
                                }
                                disabled={true}
                                >
                                {
                                  activity.type.comnCdNm.split(' ').map((name: string) => (
                                    <>
                                      {name}
                                      <br />
                                    </>
                                  ))
                                }
                              </s.GridTdText>
                            )})
                            :
                            <s.GridTdText
                              isBold={false} isTextCenter={true} color={'#ABABAB'} disabled={true}>
                              {item.description}
                            </s.GridTdText>
                          }
                        </s.GridTd>
                        
                      )
                    })
                  }
                </s.GridTr>
              )
            })
            :
            null
          }
            
        </s.GridTable>


{/* 

        <GridLayout
          rowHeight={120}
          autoSize={true}
          cols={{
            lg: (props.monthlyPlan.week + 1) * 2,
            md: (props.monthlyPlan.week + 1) * 2,
            sm: (props.monthlyPlan.week + 1) * 2,
            xs: 4,
            xxs: 2,
          }}
          breakpoints={{ lg: 1200, md: 970, sm: 768, xs: 480, xxs: 30 }}
          isDraggable={false}
          isResizable={false}
          isDroppable={false}
          margin={[0, 0]}
          useCSSTransforms={true}
          // measureBeforeMount = {false}
          // onDragStart = {(e, moveItem) => console.log('move : ', moveItem)}
          // onResizeStart = {(e, resizeItem) => console.log('resize : ', resizeItem)}
        >
          {props.monthlyPlan.dataList.map((data: any, index: number) => (
            <s.GridLayoutDiv
              key={data.dataId}
              data-grid={{
                ...data,
                isResizable: false,
                isBounded: false,
              }}
              // color={
              //   data.activityList && data.activityList[0] && data.activityList[0].color
              //     ? data.activityList[0].color.comnCdNm.split('/')[0]
              //     : '#ddd'
              // }
              colorDiv = {
                data.y === 0 ? 'G'
                : 
                data.x === 0 && data.activityList.length > 0 ?
                  data.activityList[0].type.comnCdNm === '등원' || data.activityList[0].type.comnCdNm === '활동' ?
                  'Y' : 'B'
                : 
                data.x === 1 && data.w === 1 ? 
                  props.monthlyPlan.dataList.filter((item: any) => item.x === data.x-1 && item.w === 1).length > 0 ?
                    props.monthlyPlan.dataList.filter((item: any) => item.x === data.x-1 && item.w === 1)[0].activityList[0]?.type.comnCdNm === '등원' || props.monthlyPlan.dataList.filter((item: any) => item.x === data.x-1 && item.w === 1)[0].activityList[0]?.type.comnCdNm === '활동' ?
                      'LY'
                    : 'LB'
                  :
                  data.activityList.length > 0 ? 
                  ''
                : ''
                : ''}
            >
              {data.activityList && data.activityList.length > 0 ? (
                data.activityList.map((activity: any) => {
                  return data.x + data.w <= 2 ? (
                    <s.GridButton
                      onClick={() => {
                        activity.planActivity.activityId
                          ? props.setPlanId(activity.planActivity.activityId)
                          : util.makeMsg('권한이 부족합니다.', 'error');
                      }}
                      disabled={true}
                      isCenterAlign={true}
                    >
                      <s.GridTextStyle
                        isBold={data.x === 0 || data.w === 1 && data.x === 1}
                        isTextCenter={true}
                        color={
                          activity.color && activity.color.comnCdNm
                            ? activity.color.comnCdNm.split('/')[0]
                            : '#ddd'
                        }
                      >
                        {activity.type.comnCdNm.split(' ').map((name: string) => (
                          <>
                            {name}
                            <br />
                          </>
                        ))}
                      </s.GridTextStyle>
                    </s.GridButton>
                  ) : (
                    <>
                      {props.isShowHeader(activity.planActivity.domain.comnCd) ? (
                        <s.GridTextHeader
                          color={
                            activity.color && activity.color.comnCdNm
                              ? activity.color.comnCdNm.split('/')[0]
                              : '#ddd'
                          }
                        >
                          {`[${activity.planActivity.domain.comnCdNm}]`}
                        </s.GridTextHeader>
                      ) : null}
                      <s.GridButton
                        onClick={() => {
                          activity.planActivity.activityId
                            ? props.setPlanId(activity.planActivity.activityId)
                            : util.makeMsg('권한이 부족합니다.', 'error');
                        }}
                        disabled={false}
                        isCenterAlign={data.w > 2}
                      >
                          <s.GridTextStyle
                            isBold={activity.planActivity.domain.comnCdNm === '등원'}
                            color={
                              activity.color && activity.color.comnCdNm
                                ? activity.color.comnCdNm.split('/')[0]
                                : '#ddd'
                            }
                            isTextCenter={false}
                          >
                          </s.GridTextStyle>
                          {activity.planActivity.activityNm}
                              <s.GridImageWrapper>
                              {activity.planActivity.materialList.map((material: any) => {
                                return (
                                  <s.GridImage
                                    key={'material' + material.icon.comnCd}
                                    src={`${imgUrl}${material.icon.comnCd}${imgType}`}
                                  />
                                );
                              })}
                            </s.GridImageWrapper>
                      </s.GridButton>
                    </>
                  );
                })
              ) : data.description ? (
                <s.GridButton disabled={true} isCenterAlign={true}>
                  {data.h === 1 ? (
                    <s.GridTextStyle isBold={false} isTextCenter={true} color={'#ddd'}>
                      {data.description}
                    </s.GridTextStyle>
                  ) : (
                    <s.GridBigTextStyle>
                      {data.description.split(' ').map((name: string) => (
                        <>
                          {name}
                          <br />
                          <br />
                          <br />
                        </>
                      ))}
                    </s.GridBigTextStyle>
                  )}
                </s.GridButton>
              ) : null}
            </s.GridLayoutDiv>
          ))}
        </GridLayout> */}
      </s.GridLayoutWrapper>
      {/* ORG END */}
    </s.Container>
  );
};

MonthlyPlanPresenter.defaultProps = {};

export default MonthlyPlanPresenter;

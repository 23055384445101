import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 9990;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(100, 100, 100, 0.5);
`;

export const ModalContainer = styled.div`
  width: 1080px;
  height: 90%;
  margin: 0 auto;
  z-index: 9991;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(45, 45, 45, 0.7);
  border-radius: 20px;
  padding: 20px 50px;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #3c3c3c;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: grey;
    border-radius: 20px;
  }
`;


export const AudioModalContainer = styled.div`
  width: 550px;
  height: auto;
  margin: 0 auto;
  z-index: 9991;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(45, 45, 45, 0.7);
  border-radius: 20px;
  padding: 20px 50px;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #3c3c3c;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: grey;
    border-radius: 20px;
  } 
`

export const SlideThumbnailArea = styled.div<{ current: boolean }>`
  width: 80px;
  height: 80px;
  margin: 0 5px;
  background-color: #7c7c7c;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: ${(props) => (props.current ? '1px solid #ff1500' : 'none')};
`;

export const SlideThumbnail = styled.img`
  width: 100%;
  height: 100%;
  margin: 0 10px;
  border-radius: 10px;
  object-fit: fill;
`;

export const SlideBtn = styled.img`
  width: 10px;
  height: 20px;
  cursor: pointer;
`;

export const MultContentArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 824px;
  margin-bottom: 50px;
`;

export const TitleArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 60px;
  margin: 0 10px;
`;

export const SmallTitleArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TitleLeft = styled.div`
  margin-right: 15px;
  width: 85px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TitleLeftIcon = styled.img`
  height: 100%;
  margin: 0px 5px;
`;

export const TitleLeftText = styled.div`
  color: #fff;
  font-family: 'Gosanja';
  font-size: 20px;
`;
export const SmallTitleLeftIcon = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px 5px;
  object-fit: 'cover';
`;

export const TitleSeparator = styled.div`
  background-color: #fff;
  width: 2px;
  height: 10px;
  margin: 0 10px;
`;

export const TitleRight = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
`;
export const TitleRightTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 30px;
  align-items: center;
  margin-bottom: 12px;
`;
export const TitleRightTopText = styled.div`
  color: #fff;
  font-size: 18px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const TitleRightTopIcon = styled.div`
  color: #fff;
  font-size: 14px;
  background-color: #c55c65;
  padding: 5px 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const TitleRightBottom = styled.div`
  color: #fff;
  font-family: 'Gosanja';
  font-size: 24px;
  white-space: normal;
`;

export const TitleFull = styled.div`
  flex: 1;
  background-color: #fff;
  font-family: 'Gosanja';
  font-size: 24px;
  white-space: normal;
  padding: 10px;
  border-radius: 10px;
`;

export const TitleFullBlue = styled.div`
  flex: 1;
  background-color: #0087ed;
  color: #fff;
  font-family: 'Gosanja';
  font-size: 24px;
  white-space: normal;
  padding: 10px;
  border-radius: 10px;
`;

export const SubTitleArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  flex-direction: row;
  width: 100%;
  height: 15px;
  margin: 10px;
  padding: 0 10px;
`;

export const SubTitleSeparator = styled.div`
  background-color: #a2a2a2;
  width: 1px;
  height: 5px;
  margin: 0 10px;
`;

export const SubTitleText = styled.div`
  width: auto;
  color: #FFFFFF;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 12px;
  white-space: normal;
`;

export const ContentArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 900px;
  margin-top: 20px;
`;

export const VideoContentArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 824;
  margin-bottom: 50px;
`;

export const ContentImage = styled.img`
  max-height: 850px;
  width: auto;
  max-width: 100%;
  border-radius: 10px;
`;
// export const ContentImage = styled.img`
//   height: auto;
//   width: 100%;
//   border-radius: 10px;
// `;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50px;
  margin-bottom: 20px;
  gap: 0 10px;
`;

export const WhiteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 310px;
  height: 66px;
  border-radius: 40px;
  border: 1px solid #0087ed;
  color: #4D4D4D;
  background-color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin: 0 5px 10px;
  cursor: pointer;
`;

export const BlueButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 310px;
  height: 66px;
  border-radius: 40px;
  border: 1px solid #0087ed;
  color: #fff;
  background-color: #0087ed;
  font-size: 30px;
  font-weight: 500;
  margin: 0 5px 10px;
  cursor: pointer;
`;

export const DownloadBtnIcon = styled.img`
  width: 24px;
  height: 20px;
  margin-right: 15px;
`;

export const DownloadBtnIconBigButton = styled.img`
  width: 20px;
  height: 18px;
  margin-right: 12px;
`;

export const BigWhiteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 45px;
  border-radius: 40px;
  border: 1px solid #0087ed;
  color: #2c2c2c;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const BigBlueButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 45px;
  border-radius: 40px;
  border: 1px solid #0087ed;
  color: #fff;
  background-color: #0087ed;
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const SliderDivArea = styled.div`
  width: 100%;
  height: auto
  align-tems: center
  justify-content: center
  display: flex
`;

export const AudioArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-height: 600px;
  border-radius: 5px;
  padding-bottom: 20px;
  align-items: center;
  justify-content: center;
`;

export const VideoArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 500px;
  max-height: 600px;
  border-radius: 5px;
  background-color: #fff;
  margin: 5px;
  padding: 20px;
`;
export const VideoAreaFile = styled.div`
  width: 145px;
  height: 600px;
`;

export const VideoAreaType = styled.div`
  height: auto;
  font-size: 18px;
  color: #004071;
  font-weight: 800;
`;

export const VideoAreaTitle = styled.div`
  height: auto;
  font-size: 12px;
  color: #6a6a6a;
  font-weight: 600;
  margin: 10px 0;
`;

export const VideoAreaBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const VideoAreaDescription = styled.div`
  display: inline-block;
  width: 85%;
  font-size: 10px;
  color: #6a6a6a;
  font-weight: 600;
  margin: 10px 0;
  text-align: left;
  word-break: break-all;
  white-space: normal;
`;

export const ArrowRight = styled.div`
  display: inline-block;
  border: solid #0087ed;
  border-width: 0 2px 2px 0;
  display: inline-block;
  margin: 12px 10px 12px 0;
  padding: 2px;
  height: 2px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;










export const AudioImageArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction column;
  position: relative;
`;

export const AudioImage = styled.img`
  height: auto;
  width: 100%;
  object-fit: contain;
`;

export const AudioDefaultDataArea = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 30px;
  left: 30px;
  align-items: center;
  justify-content: center;
`;

export const AudioDefaultIcon = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`;

export const AudioInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
`;

export const AudioInfoTitle = styled.div`
  font-size: 20px;
  font-family: 'Gosanja';
  font-weight: 500;
  color: #000000;
`;

export const AudioInfoDetails = styled.div`
  font-size: 12px;
  color: #000000;
  margin-top: 10px;
`;


export const TitleRightTitle = styled.div`
  color: #fff;
  font-family: 'Gosanja';
  font-size: 36px;
  white-space: normal;
`;
export const TitleRightDescription = styled.div`
  color: #cecece;
  font-family: 'Gosanja';
  font-size: 24px;
  white-space: normal;
  margin-top: 12px;
`;


export const SubSelectArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 30px;
  align-items: center;
  margin-bottom: 12px;
  gap: 10px;
  padding: 0 20px;
`;
export const SubSelectLabel = styled.div`
  color: #fff;
  font-size: 16px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const SubSelect = styled.div<{
  color: string;
  active: boolean;
}>`
  color: ${(props) => (!props.active ? '#fff' : props.color)};
  background-color: ${(props) => (!props.active ? props.color : '#fff')};
  border: 1px solid ${(props) => props.color};
  font-size: 18px;
  font-family: 'Gosanja';
  width: 145px;
  height: 45px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const StepSelect = styled.div<{
  color: string;
  active: boolean;
}>`
  color: ${(props) => (!props.active ? '#fff' : props.color)};
  background-color: ${(props) => (!props.active ? props.color : '#fff')};
  border: 1px solid ${(props) => props.color};
  font-size: 18px;
  font-family: 'Gosanja';
  width: 90px;
  height: 40px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;


export const noContentToShow = styled.div`
  width: auto;
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
  font-family: 'Gosanja';
`;
import { loginedApi, notLoginedApi } from './api-base';

export const shoppingApi = {
  getList: (token: any, Data: FormData) =>
    loginedApi.post('/shopping/getList', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  registerOrder: (token: any, Data: FormData) =>
    loginedApi.post('/shopping/registerOrder', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  getOrderList: (token: any, Data: FormData) =>
    loginedApi.post('/shopping/getOrderList', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  createOpenProduct: (token: any, Data: FormData) =>
    loginedApi.post('/shopping/createOpenProduct', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  updateOpenProduct: (token: any, Data: FormData) =>
    loginedApi.post('/shopping/updateOpenProduct', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  getOpenProductList: (token: any, Data: FormData) =>
    loginedApi.post('/shopping/getOpenProductList', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  addToCart: (token: any, Data: FormData) =>
    loginedApi.post('/shopping/addToCart', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  updateCart: (token: any, Data: FormData) =>
    loginedApi.post('/shopping/updateCart', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  deleteCart: (token: any, Data: FormData) =>
    loginedApi.post('/shopping/deleteCart', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  getCartList: (token: any, Data: FormData) =>
    loginedApi.post('/shopping/getCartList', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  orderProducts: (token: any, Data: FormData) =>
    loginedApi.post('/shopping/orderProducts', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  getOpenOrderList: (token: any, Data: FormData) =>
    loginedApi.post('/shopping/getOpenOrderList', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  updateOpenOrder: (token: any, Data: FormData) =>
    loginedApi.post('/shopping/updateOpenOrder', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
};

import * as s from '../RegisterEduStyled';

interface SelectMediaSubMenuProps {
  option: {
    holder: string;
    subMenu: string;
    type: string;
    field: string;
    visible: string;
    age: string;
    mediaSubMenu: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
  mediaSubMenuList: {
    menuCd: string;
    menuNm: string;
    viewOrder: number;
  }[];
}

const SelectMediaSubMenu = (props: SelectMediaSubMenuProps) => {
  return (
    <s.UploadProgramAreaHeaderSelect>
      {props.mediaSubMenuList &&
        props.mediaSubMenuList.map((subMenu: any) => (
          <>
            <s.UploadProgramAreaSelectType
              id={subMenu.menuCd}
              value={subMenu.menuCd}
              defaultChecked={props.option.mediaSubMenu === subMenu.menuCd}
              onChange={(e: any) =>
                props.option.mediaSubMenu === e.target.value
                  ? props.onChangeOption('mediaSubMenu', '')
                  : props.onChangeOption('mediaSubMenu', e.target.value)
              }
              type={'checkbox'}
              disabled={!props.isModifying}
            />
            <s.UploadProgramAreaSelectTypeLabel htmlFor={subMenu.menuCd}>
              {props.option.mediaSubMenu === subMenu.menuCd ? (
                <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
              ) : null}
            </s.UploadProgramAreaSelectTypeLabel>
            <s.UploadProgramAreaSelectText htmlFor={subMenu.menuCd}>
              {subMenu.menuNm}
            </s.UploadProgramAreaSelectText>
          </>
        ))}
    </s.UploadProgramAreaHeaderSelect>
  );
};

export default SelectMediaSubMenu;

import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import * as recoilItem from '../../../util/recoilitem';
import { useRecoilValue, useRecoilState } from 'recoil';
import { planApi } from '../../../api/api-plan';

import YearlyPlanPresenter from './YearlyPlanPresenter';
import * as util from './../../../util/util';

import { saveAs } from 'file-saver';

type YearlyPlanProps = {
  notSupport: () => void;
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      year: number;
      month: number;
      week: number;
      day: number;
    }>
  >;
  ageAuths: any;
};

const YearlyPlanContainer = (props: YearlyPlanProps) => {
  const token = useRecoilValue(recoilItem.token);
  const yearList = [2021, 2022, 2023, 2024, 2025].reverse();

  const [yearlyPlan, setYearlyPlan] = useState<any>(null);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const fetchData = async () => {
    let res: any = null;

    try {
      setIsLoading(true);
      res = await planApi.getPlanYear(
        token,
        props.option.age ? props.option.age : '',
        props.option.year,
      );
      console.log(props.option.age);
      console.log(props.option.year);
      console.log(res.data);
      if (res.data.rsltCd === '00') {
        setYearlyPlan(res.data.yearlyPlan);
      } else {
        setYearlyPlan(null);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const createPlanFile = async (planId: string) => {
    try {
      setIsLoading(true);
      let data: FormData = new FormData();
      data.append('planId', planId);
      await planApi.createPlanYearlyFile(token, data).then((res) => {
        if (res.data.rsltCd === '00') {
          setYearlyPlan(res.data.yearlyPlan);
          saveAs(res.data.yearlyPlan.file.fileUrl, res.data.yearlyPlan.file.originalFileName);
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const getMonthlyPlanFile = async (month: number) => {
    try {
      setIsLoading(true);
      await planApi
        .getPlanMonthFile(token, String(props.option.age), month, props.option.year)
        .then((res) => {
          if (res.data.rsltCd === '00') {
            saveAs(
              res.data.monthlyPlanFile.file.fileUrl,
              res.data.monthlyPlanFile.file.originalFileName,
            );
            return true;
          }
        });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
    return false;
  };
  const createMonthlyPlanFile = async (month: string) => {
    try {
      setIsLoading(true);
      let data: FormData = new FormData();
      data.append('year', String(props.option.year));
      data.append('month', month);
      data.append('age', String(props.option.age));
      await planApi.createPlanMonthlyFile(token, data).then((res) => {
        if (res.data.rsltCd === '00') {
          saveAs(
            res.data.monthlyPlanFile.file.fileUrl,
            res.data.monthlyPlanFile.file.originalFileName,
          );
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  const getWeeklyPlanFile = async (month: number, week: number) => {
    try {
      setIsLoading(true);
      await planApi
        .getPlanWeeklyFile(token, String(props.option.age), week, month, props.option.year)
        .then((res) => {
          if (res.data.rsltCd === '00') {
            saveAs(
              res.data.weeklyPlanFile.file.fileUrl,
              res.data.weeklyPlanFile.file.originalFileName,
            );
            return true;
          }
        });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
    return false;
  };

  const createWeeklyPlanFile = async (month: string, week: string) => {
    try {
      setIsLoading(true);
      let data: FormData = new FormData();
      data.append('year', String(props.option.year));
      data.append('month', month);
      data.append('week', week);
      data.append('age', String(props.option.age));
      await planApi.createPlanWeeklyFile(token, data).then((res) => {
        if (res.data.rsltCd === '00') {
          saveAs(
            res.data.weeklyPlanFile.file.fileUrl,
            res.data.weeklyPlanFile.file.originalFileName,
          );
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setYearlyPlan(null);
    fetchData();
  }, [props.option.age, props.option.year]);

  return (
    <YearlyPlanPresenter
      notSupport={props.notSupport}
      yearList={yearList}
      option={props.option}
      ageAuths={props.ageAuths}
      yearlyPlan={yearlyPlan}
      createPlanFile={createPlanFile}
      createMonthlyPlanFile={createMonthlyPlanFile}
      createWeeklyPlanFile={createWeeklyPlanFile}
      getMonthlyPlanFile={getMonthlyPlanFile}
      getWeeklyPlanFile={getWeeklyPlanFile}
    />
  );
};

YearlyPlanContainer.defaultProps = {
  type: 'yearly',
};

export default YearlyPlanContainer;

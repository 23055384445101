import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';

import MultiPresenter from './MultiPresenter';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { comnApi } from './../../api/api-comn';
import { menuApi } from './../../api/api-menu';
import { programApi } from './../../api/api-program';
import * as recoilItem from '../../util/recoilitem';
import queryString from 'query-string';

interface MultiProps extends RouteComponentProps {}

const MultiContainer = (props: MultiProps) => {
  const token = useRecoilValue(recoilItem.token);
  const [programId, setProgramId] = useRecoilState(recoilItem.programId);

  const blockPageNum: string = '5';
  const pagePostCount: string = '16';

  const [ageList, setAgeList] = useState([]);
  const [subMenuList, setSubMenuList] = useState([]);
  const [holderList, setHolderList] = useState([]);

  const [selectedAge, setSelectedAge] = useState({ comnCd: 'AUTH0101', comnCdNm: '1세' });

  const [selectedHolderList, setSelectedHolderList] = useState<
    { comnCd: string; comnCdNm: string }[]
  >([]);

  const [selectedSubMenuList, setSelectedSubMenuList] = useState<
    { menuNm: String; menuCd: String }[]
  >([]);

  const [programList, setProgramList] = useState(null);

  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [pageNum, setPageNum] = useState(1);
  const [pageList, setPageList] = useState([]);
  const [isFirst, setIsFirst] = useState(false);
  const [lastPageNum, setLastPageNum] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [totalItemLength, setTotalItemLength] = useState(0);

  const onChangeAge = (item: any) => {
    setSelectedAge({ comnCd: item.comnCd, comnCdNm: item.comnCdNm });
    setPageNum(1);
  };

  const onChangeSubMenuList = (item: any) => {
    if (!item) {
      setSelectedSubMenuList([]);
      return;
    }
    const subMenu = { menuCd: item.menuCd, menuNm: item.menuNm };

    let tempSubMenuList = [...selectedSubMenuList];

    // if (tempSubMenuList.map((item: any) => item.menuCd).includes(subMenu.menuCd)) {
    //   const idx: number = tempSubMenuList.map((item: any) => item.menuCd).indexOf(subMenu.menuCd);
    //   tempSubMenuList.splice(idx, 1);
    // } else {
    //   tempSubMenuList.push(subMenu);
    // }
    // setSelectedSubMenuList(tempSubMenuList);
    setSelectedSubMenuList([subMenu]);
    setPageNum(1);
  };

  const onChangeHolderList = (item: any) => {
    if (!item) {
      setSelectedHolderList([]);
      return;
    }
    const holder = { comnCd: item.comnCd, comnCdNm: item.comnCdNm };

    // let tempHolderList = [...selectedHolderList];

    // if (tempHolderList.map((item: any) => item.comnCd).includes(holder.comnCd)) {
    //   const idx: number = tempHolderList.map((item: any) => item.comnCd).indexOf(holder.comnCd);
    //   tempHolderList.splice(idx, 1);
    // } else {
    //   tempHolderList.push(holder);
    // }

    // setSelectedHolderList(tempHolderList);
    setSelectedHolderList([holder]);
    setPageNum(1);
  };

  const firstFetchData = async () => {
    setIsLoading(true);

    let res1: any = null;
    let res2: any = null;
    let res3: any = null;
    let res4: any = null;

    let formData: FormData = new FormData();

    formData.append('topMenu', 'MN05');
    formData.append('age', selectedAge.comnCd);
    formData.append('page', pageNum.toString());
    formData.append('blockPageNum', blockPageNum);
    formData.append('pagePostCount', pagePostCount);

    try {
      res1 = await comnApi.getCd('AUTH01');
      res2 = await menuApi.getCd('MN05');
      res3 = await comnApi.getCd('HOL01');
      res4 = await programApi.filter(token, formData);
      if (res1.data.rsltCd === '00') {
        setAgeList(res1.data.ComnCd.subCdList);
      }
      if (res2.data.rsltCd === '00') {
        setSubMenuList(res2.data.ComnCd.subMenuList);
      }
      if (res3.data.rsltCd === '00') {
        setHolderList(res3.data.ComnCd.subCdList);
      }
      if (res4.data.rsltCd === '00') {
        let resultList = res4.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res4.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setProgramList(resultList);
        setIsFirst(res4.data.programList.first);
        setIsLast(res4.data.programList.last);
        setLastPageNum(res4.data.programList.totalPages);
        setPageList(res4.data.pageList);
        setTotalItemLength(res4.data.programList.totalElements);
      }
      getQuerySubMenu(res2.data.ComnCd.subMenuList);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    let res: any = null;

    let formData: FormData = new FormData();

    formData.append('topMenu', 'MN05');
    formData.append('age', selectedAge.comnCd);
    formData.append('page', pageNum.toString());
    formData.append('blockPageNum', blockPageNum);
    formData.append('pagePostCount', pagePostCount);
    formData.append('subMenu', selectedSubMenuList.map((item: any) => item.menuCd).join(','));
    formData.append('holder', selectedHolderList.map((item: any) => item.comnCd).join(','));

    try {
      res = await programApi.filter(token, formData);
      if (res.data.rsltCd === '00') {
        let resultList = res.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setProgramList(resultList);
        setIsFirst(res.data.programList.first);
        setIsLast(res.data.programList.last);
        setLastPageNum(res.data.programList.totalPages);
        setPageList(res.data.pageList);
        setTotalItemLength(res.data.programList.totalElements);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const getQuerySubMenu = (subMenuList: any[]) => {
    let tempList = String(queryString.parse(props.location.search).menu).split(',');
    if (tempList) {
      let resultList: any[] = [];
      tempList.map((item: any) => {
        const idx: number = subMenuList.map((subMenu: any) => subMenu.menuCd).indexOf(item);
        resultList.push({ menuCd: subMenuList[idx].menuCd, menuNm: subMenuList[idx].menuNm });
      });
      setSelectedSubMenuList(resultList);
    }
  };

  useEffect(() => {
    firstFetchData();
  }, [props.location]);

  useEffect(() => {
    fetchData();
  }, [selectedAge, selectedSubMenuList, selectedHolderList, pageNum]);

  return (
    <>
      <Header {...props} />
      <MultiPresenter
        ageList={ageList}
        subMenuList={subMenuList}
        holderList={holderList}
        selectedAge={selectedAge}
        setSelectedAge={setSelectedAge}
        selectedSubMenuList={selectedSubMenuList}
        selectedHolderList={selectedHolderList}
        setSelectedSubMenuList={setSelectedSubMenuList}
        onChangeAge={onChangeAge}
        onChangeSubMenuList={onChangeSubMenuList}
        onChangeHolderList={onChangeHolderList}
        programList={programList}
        setProgramId={setProgramId}
        pageNum={pageNum}
        setPageNum={setPageNum}
        pageList={pageList}
        lastPageNum={lastPageNum}
        isFirst={isFirst}
        isLast={isLast}
      ></MultiPresenter>
      <Footer {...props} />
    </>
  );
};

export default MultiContainer;

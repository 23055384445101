import { notLoginedApi } from './api-base';

export const tokenApi = {

    getNewToken: (token: any) =>
        notLoginedApi.post(`/token/refresh`, null, {
            headers: {
                'X-AUTH-TOKEN': token.accessToken,
                "X-AUTH-REFRESH-TOKEN": token.refreshToken,
            },
        }),
};

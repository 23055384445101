import React, { useEffect, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as recoilUtil from '../../../../../util/recoilitem';

import LoadModalPresenter from './LoadModalPresenter';
import { planApi } from '../../../../../api/api-plan';

interface LoadModalProps {
  ageAuths: any;

  setIsOpenLoadModal: (arg0: boolean) => void;
  loadDailyPlan: (age: any, year: any, month: any, week: any, day: any) => void;
}

const LoadModalContainer = (props: LoadModalProps) => {
  const [age, setAge] = useState<any>('');
  const [year, setYear] = useState<any>('');
  const [month, setMonth] = useState<any>('');
  const [day, setDay] = useState<any>('');
  const [dayList, setDayList] = useState<any[]>([]);

  const ageList = [
    { cd: 'AUTH0101', nm: '1세' },
    { cd: 'AUTH0102', nm: '2세' },
    { cd: 'AUTH0103', nm: '3세' },
    { cd: 'AUTH0104', nm: '4세' },
    { cd: 'AUTH0105', nm: '5세' },
  ];
  const yearList = [
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
    new Date().getFullYear() + 1,
    new Date().getFullYear() + 2,
    new Date().getFullYear() + 3,
  ].reverse();
  const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  useEffect(() => {
    makeDays();
  }, [year, month]);

  const makeDays = () => {
    console.log(1);
    console.log(year, month);
    let days: number[] = [];
    if (
      month === 1 ||
      month === 3 ||
      month === 5 ||
      month === 7 ||
      month === 8 ||
      month === 10 ||
      month === 12
    ) {
      days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31,
      ];
    } else if (month === 4 || month === 6 || month === 9 || month === 11) {
      days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30,
      ];
    } else if (month === 2 && year % 4 === 0) {
      if (year % 400 !== 0 && year % 100 === 0) {
        days = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
          26, 27, 28,
        ];
      } else {
        days = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
          26, 27, 28, 29,
        ];
      }
    } else if (month === 2) {
      days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28,
      ];
    }

    setDayList([...days]);
  };
  const getWeek = (date: any) => {
    const currentDate = date.getDate();
    const firstDay = new Date(date.setDate(1)).getDay();

    return Math.ceil((currentDate + firstDay) / 7);
  };

  const onSave = async () => {
    let week = getWeek(new Date(year, month - 1, day));
    console.log(week);
    props.loadDailyPlan(age, year, month, week, day);
    props.setIsOpenLoadModal(false);
  };

  return (
    <LoadModalPresenter
      ageAuths={props.ageAuths}
      setIsOpenLoadModal={props.setIsOpenLoadModal}
      age={age}
      setAge={setAge}
      year={year}
      setYear={setYear}
      month={month}
      setMonth={setMonth}
      day={day}
      setDay={setDay}
      ageList={ageList}
      yearList={yearList}
      monthList={monthList}
      dayList={dayList}
      onSave={onSave}
    />
  );
};

export default LoadModalContainer;

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';

import ActivityPresenter from './ActivityPresenter';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { comnApi } from './../../api/api-comn';
import { menuApi } from './../../api/api-menu';
import { programApi } from './../../api/api-program';
import * as recoilItem from '../../util/recoilitem';
import * as util from '../../util/util';
import queryString from 'query-string';

interface ActivityProps extends RouteComponentProps {}

const infantCdList = [
  'MN0301',
  'MN0302',
  'MN0303',
  'MN0304',
  'MN0305',
  'MN0306',
  'MN0307',
  'MN0314',
  'MN0315',
  'MN0316',
  'MN0317',
];
const childCdList = [
  'MN0302',
  'MN0303',
  'MN0304',
  'MN0305',
  'MN0308',
  'MN0313',
  'MN0306',
  'MN0309',
  'MN0310',
  'MN0311',
  'MN0312',
  'MN0314',
  'MN0315',
  'MN0316',
  'MN0317',
  'MN0318',
  'MN0319',
  'MN0320',
  'MN0321',
  'MN0322',
  'MN0323',
  'MN0324'
];

const ActivityContainer = (props: ActivityProps) => {
  const token = useRecoilValue(recoilItem.token);
  const [programId, setProgramId] = useRecoilState(recoilItem.programId);

  const blockPageNum: string = '5';
  const pagePostCount: string = '15';

  const [ageList, setAgeList] = useState([]);
  const [subMenuList, setSubMenuList] = useState<string[]>([]);
  const [holderList, setHolderList] = useState([]);

  const [programList, setProgramList] = useState(null);

  const [selectedAge, setSelectedAge] = useState({ comnCd: 'AUTH0101', comnCdNm: '1세' });

  const [selectedMenu, setSelectedMenu] = useState<any>('MN0401');

  const [selectedSubMenuList, setSelectedSubMenuList] = useState<
    { menuNm: String; menuCd: String }[]
  >([{ menuNm: '신체놀이', menuCd: 'MN0301' }]);

  const [selectedHolderList, setSelectedHolderList] = useState<
    { comnCd: string; comnCdNm: string }[]
  >([]);

  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [pageNum, setPageNum] = useState(1);
  const [pageList, setPageList] = useState([]);
  const [isFirst, setIsFirst] = useState(false);
  const [lastPageNum, setLastPageNum] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [totalItemLength, setTotalItemLength] = useState(0);

  const onChangeAge = (item: any) => {
    setSelectedAge({ comnCd: item.comnCd, comnCdNm: item.comnCdNm });
    setPageNum(1);
  };

  const onChangeSubMenuList = (item: any) => {
    if (!item) {
      setSelectedSubMenuList([]);
      onChangeSubMenuKeyWord();
      return;
    }
    const subMenu = { menuCd: item.menuCd, menuNm: item.menuNm };

    // let tempSubMenuList = [...selectedSubMenuList];

    // if (tempSubMenuList.map((item: any) => item.menuCd).includes(subMenu.menuCd)) {
    //   const idx: number = tempSubMenuList.map((item: any) => item.menuCd).indexOf(subMenu.menuCd);
    //   tempSubMenuList.splice(idx, 1);
    // } else {
    //   tempSubMenuList.push(subMenu);
    // }
    // setSelectedSubMenuList(tempSubMenuList);
    setSelectedSubMenuList([subMenu]);
    onChangeSubMenuKeyWord();
    setPageNum(1);
  };
  const onChangeHolderList = (item: any) => {
    if (!item) {
      setSelectedHolderList([]);
      return;
    }
    const holder = { comnCd: item.comnCd, comnCdNm: item.comnCdNm };

    // let tempHolderList = [...selectedHolderList];

    // if (tempHolderList.map((item: any) => item.comnCd).includes(holder.comnCd)) {
    //   const idx: number = tempHolderList.map((item: any) => item.comnCd).indexOf(holder.comnCd);
    //   tempHolderList.splice(idx, 1);
    // } else {
    //   tempHolderList.push(holder);
    // }

    // setSelectedHolderList(tempHolderList);
    setSelectedHolderList([holder]);
    setPageNum(1);
  };

  const onChangeSubMenuKeyWord = () => {};

  const unableDownload = () => {
    util.makeMsg('해당하는 파일이 없습니다.', 'error');
  };

  const firstFetchData = async () => {
    setIsLoading(true);

    let res1: any = null;
    let res2: any = null;
    let res3: any = null;
    let res4: any = null;

    let formData: FormData = new FormData();

    formData.append('field', selectedSubMenuList.map((item: any) => item.menuCd).join(','));
    formData.append('age', selectedAge.comnCd);
    formData.append('page', pageNum.toString());
    formData.append('blockPageNum', blockPageNum);
    formData.append('pagePostCount', pagePostCount);

    try {
      res1 = await comnApi.getCd('AUTH01');
      res2 = await menuApi.getCd('MN03');
      res3 = await programApi.filter(token, formData);
      res4 = await comnApi.getCd('HOL01');
      console.log(res2);
      console.log(res3);

      if (res1.data.rsltCd === '00') {
        setAgeList(res1.data.ComnCd.subCdList);
      }
      if (res2.data.rsltCd === '00') {
        setSubMenuList(res2.data.ComnCd.subMenuList);
      }
      if (res4.data.rsltCd === '00') {
        setHolderList(res4.data.ComnCd.subCdList);
      }
      if (res3.data.rsltCd === '00') {
        let resultList = res3.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res3.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setProgramList(resultList);
        setIsFirst(res3.data.programList.first);
        setIsLast(res3.data.programList.last);
        setLastPageNum(res3.data.programList.totalPages);
        setPageList(res3.data.pageList);
        setTotalItemLength(res3.data.programList.totalElements);
      }
      getQueryField(res2.data.ComnCd.subMenuList);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  const fetchData = async () => {
    setIsLoading(true);

    let res: any = null;

    let formData: FormData = new FormData();

    if (selectedSubMenuList.map((item: any) => item.menuCd).length > 0) {
      formData.append('field', selectedSubMenuList.map((item: any) => item.menuCd).join(','));
    } else {
      formData.append('field', subMenuList.map((item: any) => item.menuCd).join(','));
    }
    if (selectedMenu !== null) {
      if (selectedMenu.length === 4) {
        formData.append('topMenu', selectedMenu);
      } else {
        formData.append('subMenu', selectedMenu);
      }
    }
    formData.append('age', selectedAge.comnCd);
    formData.append('page', pageNum.toString());
    formData.append('blockPageNum', blockPageNum);
    formData.append('pagePostCount', pagePostCount);
    if (selectedHolderList.length > 0) {
      formData.append('holder', selectedHolderList.map((item: any) => item.comnCd).join(','));
    }

    try {
      res = await programApi.filter(token, formData);
      if (res.data.rsltCd === '00') {
        let resultList = res.data.programList.content;
        resultList = resultList.map((item: any, index: number) => {
          item.no = (pageNum - 1) * res.data.programList.pageable.pageSize + index + 1;
          return item;
        });
        setProgramList(resultList);
        setIsFirst(res.data.programList.first);
        setIsLast(res.data.programList.last);
        setLastPageNum(res.data.programList.totalPages);
        setPageList(res.data.pageList);
        setTotalItemLength(res.data.programList.totalElements);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const getQueryField = (fieldList: any[]) => {
    let tempList = String(queryString.parse(props.location.search).field).split(',');
    if (tempList) {
      let resultList: any[] = [];
      tempList.map((item: any) => {
        const idx: number = fieldList.map((field: any) => field.menuCd).indexOf(item);
        resultList.push({ menuCd: fieldList[idx].menuCd, menuNm: fieldList[idx].menuNm });
      });
      setSelectedSubMenuList(resultList);
    }
  };

  useEffect(() => {
    firstFetchData();
  }, [props.location]);

  useEffect(() => {
    fetchData();
  }, [selectedAge, selectedSubMenuList, pageNum, selectedHolderList, selectedMenu]);

  useEffect(() => {
    setSelectedSubMenuList([
      ...selectedSubMenuList.filter((item: any) =>
        selectedAge.comnCd === 'AUTH0101' || selectedAge.comnCd === 'AUTH0102'
          ? infantCdList.includes(item.menuCd)
          : childCdList.includes(item.menuCd),
      ),
    ]);
  }, [selectedAge]);

  return (
    <>
      <Header {...props} />
      <ActivityPresenter
        ageList={ageList}
        subMenuList={subMenuList}
        selectedAge={selectedAge}
        setSelectedAge={setSelectedAge}
        selectedSubMenuList={selectedSubMenuList}
        setSelectedSubMenuList={setSelectedSubMenuList}
        onChangeAge={onChangeAge}
        onChangeSubMenuList={onChangeSubMenuList}
        programList={programList}
        setProgramId={setProgramId}
        unableDownload={unableDownload}
        pageNum={pageNum}
        setPageNum={setPageNum}
        pageList={pageList}
        lastPageNum={lastPageNum}
        isFirst={isFirst}
        isLast={isLast}
        holderList={holderList}
        selectedHolderList={selectedHolderList}
        onChangeHolderList={onChangeHolderList}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        infantCdList={infantCdList}
        childCdList={childCdList}
      ></ActivityPresenter>

      <Footer {...props} />
    </>
  );
};

export default ActivityContainer;

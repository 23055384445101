import React, { Dispatch, SetStateAction } from 'react';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { RouteComponentProps } from 'react-router-dom';

import BeatLoader from 'react-spinners/BeatLoader';
import * as s from './RegisterSoundStyled';
import LyricsUpload from './component/Lyrics';
import SheetUpload from './component/Sheet';
import VideoUpload from './component/Video';

interface RegisterSoundProps extends RouteComponentProps {
  option: {
    age: string | null;
    holder: string;
    topMenu: string;
    subMenu: string;
    type: string;
    field: string;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      holder: string;
      topMenu: string;
      subMenu: string;
      type: string;
      field: string;
    }>
  >;
  ageAuths: any;
  holderList: any;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  open: () => void;
  acceptedFiles: File[];
  isDragActive: boolean;
  isModifying: boolean;
  setIsModifying: React.Dispatch<React.SetStateAction<boolean>>;
  submitFileList: File[];
  submit: () => Promise<void>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  submitForm: {
    lyricist: string;
    composer: string;
    tune: string;
    time: string;
  };
  fieldList: {
    menuCd: string;
    menuNm: string;
    viewOrder: number;
  }[];
  setSubmitForm: React.Dispatch<
    React.SetStateAction<{
      lyricist: string;
      composer: string;
      tune: string;
      time: string;
    }>
  >;
  hasVideo: boolean;
  setHasVideo: React.Dispatch<React.SetStateAction<boolean>>;
  isTune: boolean;
  setIsTune: React.Dispatch<React.SetStateAction<boolean>>;
  submitVideoList: {
    files: File[];
    url: string;
    type: string;
    description: string;
  }[];
  setSubmitVideoList: React.Dispatch<
    React.SetStateAction<
      {
        files: File[];
        url: string;
        type: string;
        description: string;
      }[]
    >
  >;
  submitLyricsList: File[];
  setSubmitLyricsList: React.Dispatch<React.SetStateAction<File[]>>;
  submitSheetList: File[];
  setSubmitSheetList: React.Dispatch<React.SetStateAction<File[]>>;
  addVideo: () => void;
  deleteVideo: (index: number) => void;
}

const RegisterSoundPresenter = (props: RegisterSoundProps) => {
  return (
    <s.Container>
      <s.TitleArea>
        <s.Title>
          만{' '}
          <s.TitleSpan>
            {!props.ageAuths || props.ageAuths.length === 0
              ? null
              : props.option.age
              ? props.ageAuths[props.option.age].comnCdNm
              : null}
          </s.TitleSpan>{' '}
          <s.TitleSpan>
            {!props.holderList || props.holderList.length === 0
              ? null
              : props.holderList[props.option.holder].comnCdNm}
          </s.TitleSpan>{' '}
          동요/음원
        </s.Title>
      </s.TitleArea>

      <s.UploadArea>
        <s.UploadAreaHeader>
          <s.UploadAreaHeaderLeft>업로드</s.UploadAreaHeaderLeft>
          <s.UploadAreaHeaderRight>
            <s.UploadAreaHeaderRightIcon src="/static/img/warning.png" />
            <s.UploadAreaHeaderRightText>ZIP파일은 업로드 불가입니다.</s.UploadAreaHeaderRightText>
          </s.UploadAreaHeaderRight>
        </s.UploadAreaHeader>
        <s.UploadContentArea>
          <s.UploadContentAreaHeader>
            <s.UploadContentAreaHeaderLeft>
              <s.UploadContentAreaHeaderIcon
                src={!props.isModifying ? '/static/img/pen.png' : '/static/img/red_pen.png'}
              />
              <s.UploadContentAreaHeaderLeftText
                onClick={() => (!props.isModifying ? props.setIsModifying(true) : null)}
                isColor={!props.isModifying}
              >
                수정
              </s.UploadContentAreaHeaderLeftText>
            </s.UploadContentAreaHeaderLeft>
            <s.UploadContentAreaHeaderRight>
              {false ? (
                <BeatLoader size={8} color={'#acacac'} />
              ) : (
                <>
                  <s.UploadContentAreaHeaderIcon
                    src={
                      props.isModifying ? '/static/img/check.png' : '/static/img/green_check.png'
                    }
                  />
                  <s.UploadContentAreaHeaderRightText
                    onClick={() => (props.isModifying ? props.setIsModifying(false) : null)}
                    isColor={props.isModifying}
                  >
                    완료
                  </s.UploadContentAreaHeaderRightText>
                </>
              )}
            </s.UploadContentAreaHeaderRight>
          </s.UploadContentAreaHeader>
          <s.UploadProgramArea>
            <s.UploadProgramAreaHeader>
              <s.UploadProgramAreaSelectHeader>놀이 선택</s.UploadProgramAreaSelectHeader>
              <s.UploadProgramAreaSelectDv />
              <s.UploadProgramAreaHeaderSelect>
                {props.fieldList.map((type: any) => (
                  <>
                    <s.UploadProgramAreaSelectType
                      id={type.menuCd}
                      value={type.menuCd}
                      defaultChecked={props.option.field === type.menuCd}
                      onChange={(e) =>
                        props.option.field === e.target.value
                          ? props.setOption({ ...props.option, field: '' })
                          : props.setOption({ ...props.option, field: e.target.value })
                      }
                      type={'checkbox'}
                      disabled={!props.isModifying}
                    />
                    <s.UploadProgramAreaSelectTypeLabel htmlFor={type.menuCd}>
                      {props.option.field === type.menuCd ? (
                        <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                      ) : null}
                    </s.UploadProgramAreaSelectTypeLabel>
                    <s.UploadProgramAreaSelectText htmlFor={type.menuCd}>
                      {type.menuNm}
                    </s.UploadProgramAreaSelectText>
                  </>
                ))}
              </s.UploadProgramAreaHeaderSelect>
              <s.UploadProgramAreaHeaderSelect>
                <s.UploadProgramAreaHeaderLabel>제목</s.UploadProgramAreaHeaderLabel>
                <s.UploadProgramAreaHeaderInput
                  value={props.title}
                  onChange={(e) => props.setTitle(e.target.value)}
                  placeholder={'텍스트를 입력해주세요.'}
                  disabled={!props.isModifying}
                />
                <s.UploadProgramAreaHeaderLabel>시간</s.UploadProgramAreaHeaderLabel>
                <s.UploadProgramAreaSmallInput
                  value={props.submitForm.time}
                  onChange={(e) =>
                    props.setSubmitForm({ ...props.submitForm, time: e.target.value })
                  }
                  placeholder={'시간'}
                  disabled={!props.isModifying}
                />
                <s.UploadProgramAreaSelectType
                  id={'hasVideo'}
                  defaultChecked={props.hasVideo}
                  onChange={(e) => props.setHasVideo(e.target.checked)}
                  type={'checkbox'}
                  disabled={!props.isModifying}
                />
                <s.UploadProgramAreaSelectTypeLabel htmlFor={'hasVideo'}>
                  {props.hasVideo ? (
                    <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                  ) : null}
                </s.UploadProgramAreaSelectTypeLabel>
                <s.UploadProgramAreaSelectText htmlFor={'hasVideo'}>
                  영상포함
                </s.UploadProgramAreaSelectText>
              </s.UploadProgramAreaHeaderSelect>
            </s.UploadProgramAreaHeader>
            <s.UploadProgramAreaFileUploadArea
              isDrag={props.isDragActive}
              {...props.getRootProps({ className: 'dropzone' })}
            >
              <s.UploadProgramAreaFileUploadAreaInput {...props.getInputProps()} />
              {props.submitFileList.length > 0 ? (
                <s.UploadProgramAreaFileUploadAreaButtonIcon
                  onClick={props.open}
                  src="/static/img/pdf_icon_1.png"
                />
              ) : null}
              <s.UploadProgramAreaFileUploadAreaText>
                {props.submitFileList.length > 0
                  ? props.submitFileList.length === 1
                    ? props.submitFileList[0].name
                    : props.submitFileList[0].name +
                      ' 외 ' +
                      (props.submitFileList.length - 1) +
                      '개'
                  : '파일을 선택해주세요.'}
              </s.UploadProgramAreaFileUploadAreaText>
              <s.UploadProgramAreaFileUploadAreaButtonIcon
                onClick={props.open}
                src={
                  props.submitFileList.length > 0
                    ? '/static/img/file_exist.png'
                    : '/static/img/selectFile.png'
                }
              />
            </s.UploadProgramAreaFileUploadArea>
            <s.UploadProgramAreaFooter>
              <s.UploadProgramAreaFooterInputArea>
                {!props.isTune ? (
                  <>
                    <s.UploadProgramAreaFooterLabel>작사</s.UploadProgramAreaFooterLabel>
                    <s.UploadProgramAreaFooterInput
                      value={props.submitForm.lyricist}
                      onChange={(e) =>
                        props.setSubmitForm({ ...props.submitForm, lyricist: e.target.value })
                      }
                      placeholder={'텍스트를 입력해주세요.'}
                      disabled={!props.isModifying}
                    />
                    <s.UploadProgramAreaFooterLabel>작곡</s.UploadProgramAreaFooterLabel>
                    <s.UploadProgramAreaFooterInput
                      value={props.submitForm.composer}
                      onChange={(e) =>
                        props.setSubmitForm({ ...props.submitForm, composer: e.target.value })
                      }
                      placeholder={'텍스트를 입력해주세요.'}
                      disabled={!props.isModifying}
                    />
                  </>
                ) : (
                  <>
                    <s.UploadProgramAreaFooterLabel>연주곡</s.UploadProgramAreaFooterLabel>
                    <s.UploadProgramAreaFooterLongInput
                      value={props.submitForm.tune}
                      onChange={(e) =>
                        props.setSubmitForm({ ...props.submitForm, tune: e.target.value })
                      }
                      placeholder={'텍스트를 입력해주세요.'}
                      disabled={!props.isModifying}
                    />
                  </>
                )}
                <s.UploadProgramAreaSelectType
                  id={'isTune'}
                  defaultChecked={props.isTune}
                  onChange={(e) => props.setIsTune(e.target.checked)}
                  type={'checkbox'}
                  disabled={!props.isModifying}
                />
                <s.UploadProgramAreaSelectTypeLabel htmlFor={'isTune'}>
                  {props.isTune ? (
                    <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
                  ) : null}
                </s.UploadProgramAreaSelectTypeLabel>
                <s.UploadProgramAreaSelectText htmlFor={'isTune'}>
                  연주곡
                </s.UploadProgramAreaSelectText>
              </s.UploadProgramAreaFooterInputArea>
            </s.UploadProgramAreaFooter>
          </s.UploadProgramArea>
          <LyricsUpload
            isModifying={props.isModifying}
            submitFileList={props.submitLyricsList}
            setSubmitFileList={props.setSubmitLyricsList}
          />
          <SheetUpload
            isModifying={props.isModifying}
            submitFileList={props.submitSheetList}
            setSubmitFileList={props.setSubmitSheetList}
          />
          {props.hasVideo
            ? props.submitVideoList.map(
                (
                  item: {
                    files: File[];
                    url: string;
                    type: string;
                    description: string;
                  },
                  index: number,
                ) => (
                  <VideoUpload
                    isModifying={props.isModifying}
                    submitVideo={props.submitVideoList[index]}
                    setSubmitVideo={(arg1: {
                      files: File[];
                      url: string;
                      type: string;
                      description: string;
                    }) => {
                      let tempList = [...props.submitVideoList];
                      tempList[index] = arg1;
                      props.setSubmitVideoList(tempList);
                    }}
                    deleteVideo={() => props.deleteVideo(index)}
                  />
                ),
              )
            : null}
          <s.ButtonArea>
            {props.hasVideo ? (
              <s.SubmitButton onClick={props.addVideo}>
                <s.SubmitButtonText>영상 추가</s.SubmitButtonText>
              </s.SubmitButton>
            ) : null}
            <s.SubmitButton onClick={props.submit}>
              <s.SubmitButtonText>업로드</s.SubmitButtonText>
            </s.SubmitButton>
          </s.ButtonArea>
        </s.UploadContentArea>
      </s.UploadArea>
    </s.Container>
  );
};

export default RegisterSoundPresenter;

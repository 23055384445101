import React from 'react';

import RGL from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import * as s from './WeeklyPlanStyled';
import SearchModal from '../searchModal';
import PrevPlanModal from '../prevPlanModal';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';

const warning = '/static/img/warning.png';
const pen = '/static/img/pen.png';
const check = '/static/img/check.png';
const selectFile = '/static/img/selectFile.png';

const GridLayout = RGL.WidthProvider(RGL);

interface WeeklyPlanProps {
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  ageAuths: any;

  uploadFile: () => void;

  submitForm: {
    description: string;
    subject: string;
    goal: string;
    file: File | any;
  };
  onChangeAge: React.ChangeEventHandler<HTMLTextAreaElement>;
  onChangeSubject: React.ChangeEventHandler<HTMLTextAreaElement>;
  onChangeDescription: React.ChangeEventHandler<HTMLTextAreaElement>;
  onChangeGoal: React.ChangeEventHandler<HTMLTextAreaElement>;

  isEditing: boolean;
  setIsEditing: (arg0: any) => void;

  layout: [];
  onLayoutChange: (arg0: any) => void;
  onInitializeLayout: () => void;
  onClear: () => void;
  addLayoutData: () => void;
  onGetLastData: () => void;
  onSubmit: () => void;
  popupOn: any;
  setPopupOn: (arg0: any) => void;

  topicList: any;
  subTopicList: any;
  colorList: any;
  onPushActivityInLayout: (arg0: any[], arg1: any) => void;

  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  isDragActive: boolean;

  yearList: number[];
  prevPlanData: any;
  setPrevPlanData: (arg0: any) => void;
  prevPlanModalOn: boolean;
  setPrevPlanModalOn: (arg0: boolean) => void;
  initializeLayout:() => void;
}

const WeeklyPlanPresenter = (props: WeeklyPlanProps) => {
  return (
    <>
      <s.Container>
        <s.PlanCreateHeaderWrapper>
          <s.PlanCreateHeader>
            <s.PlanCreateHeaderText color="#0087ed">{props.option.year}년 </s.PlanCreateHeaderText>
            <s.PlanCreateHeaderText color="#4d4d4d"> 만 </s.PlanCreateHeaderText>
            <s.PlanCreateHeaderText color="#0087ed">
              {props.option.age ? props.ageAuths[props.option.age].comnCdNm : ''}
            </s.PlanCreateHeaderText>
            <s.PlanCreateHeaderText color="#0087ed">
              {' '}
              {props.option.month}월{' '}
            </s.PlanCreateHeaderText>
            <s.PlanCreateHeaderText color="#4d4d4d">주간 교육 계획안 </s.PlanCreateHeaderText>
          </s.PlanCreateHeader>
        </s.PlanCreateHeaderWrapper>
        {/* <s.PlanFileUploadWrapper>
          <s.UploadHead>
            <s.UploadHeadTitle>주간교육계획안 업로드</s.UploadHeadTitle>
            <s.UploadHeadInfo>
              <s.UploadHeadInfoImg src={warning} />
              <s.UploadHeadInfoText>ZIP파일 · 단일 jpg는 업로드 불가입니다.</s.UploadHeadInfoText>
            </s.UploadHeadInfo>
          </s.UploadHead>
          <s.FileUploadArea {...props.getRootProps()} isDragActive={props.isDragActive}>
            <s.FileUploadAreaInput {...props.getInputProps()} />
            {props.submitForm.file ? (
              <s.FileUploadAreaButtonIcon
                onClick={props.uploadFile}
                src="/static/img/pdf_icon_1.png"
              />
            ) : null}
            <s.FileUploadAreaText>
              {props.submitForm.file ? props.submitForm.file[0].name : '파일을 업로드 해주세요.'}
            </s.FileUploadAreaText>
            <s.FileUploadAreaButtonIcon
              onClick={props.uploadFile}
              src={
                props.submitForm.file ? '/static/img/file_exist.png' : '/static/img/selectFile.png'
              }
            />
          </s.FileUploadArea>
        </s.PlanFileUploadWrapper> */}
        <s.PlanWriteWrapper>
          <s.UploadHead>
            <s.UploadHeadTitle>주간교육계획안 작성</s.UploadHeadTitle>
            <s.UploadHeadInfo>
              <s.UploadHeadInfoImg src={warning} />
              <s.UploadHeadInfoText>ZIP파일 · 단일 jpg는 업로드 불가입니다.</s.UploadHeadInfoText>
            </s.UploadHeadInfo>
          </s.UploadHead>
          <s.UploadBody isDragActive={false}>
            <s.WriteButtonWrapper>
              <s.UploadContentAreaHeader>
                <s.UploadContentAreaHeaderLeft>
                  <s.UploadContentAreaHeaderIcon
                    src={!props.isEditing ? '/static/img/pen.png' : '/static/img/red_pen.png'}
                  />
                  <s.UploadContentAreaHeaderLeftText
                    onClick={() => (!props.isEditing ? props.setIsEditing(true) : null)}
                    isColor={!props.isEditing}
                  >
                    수정
                  </s.UploadContentAreaHeaderLeftText>
                </s.UploadContentAreaHeaderLeft>
                <s.UploadContentAreaHeaderRight>
                  <s.UploadContentAreaHeaderIcon
                    src={props.isEditing ? '/static/img/check.png' : '/static/img/green_check.png'}
                  />
                  <s.UploadContentAreaHeaderRightText
                    onClick={() => (props.isEditing ? props.setIsEditing(false) : null)}
                    isColor={props.isEditing}
                  >
                    완료
                  </s.UploadContentAreaHeaderRightText>
                </s.UploadContentAreaHeaderRight>
              </s.UploadContentAreaHeader>
            </s.WriteButtonWrapper>
            <s.WritingBoardWrapper>
              <s.WritingBoardContent>
                <s.WritingBoardInfo style={{ height: '50px', justifyContent: 'center' }}>
                  <s.WritingBoardIntoText>생활주제</s.WritingBoardIntoText>
                </s.WritingBoardInfo>
                <s.WritingBoardTextInputWrapper>
                  <s.WritingBoardTextInput
                    placeholder="텍스트를 입력해주세요."
                    style={{ height: '40px' }}
                    disabled={!props.isEditing}
                    value={props.submitForm.subject}
                    onChange={props.onChangeSubject}
                  />
                </s.WritingBoardTextInputWrapper>
              </s.WritingBoardContent>
              <s.WritingBoardContent>
                <s.WritingBoardInfo>
                  <s.WritingBoardIntoText>주제 선정이유</s.WritingBoardIntoText>
                  <s.WritingBoardInfoNotice>(최대 0000글자만 작성 가능)</s.WritingBoardInfoNotice>
                </s.WritingBoardInfo>
                <s.WritingBoardTextInputWrapper>
                  <s.WritingBoardTextInput
                    placeholder="텍스트를 입력해주세요."
                    disabled={!props.isEditing}
                    value={props.submitForm.description}
                    onChange={props.onChangeDescription}
                  />
                </s.WritingBoardTextInputWrapper>
              </s.WritingBoardContent>
              <s.WritingBoardContent>
                <s.WritingBoardInfo>
                  <s.WritingBoardIntoText>교사의 기대</s.WritingBoardIntoText>
                  <s.WritingBoardInfoNotice>(최대 0000글자만 작성 가능)</s.WritingBoardInfoNotice>
                </s.WritingBoardInfo>
                <s.WritingBoardTextInputWrapper>
                  <s.WritingBoardTextInput
                    placeholder="텍스트를 입력해주세요."
                    disabled={!props.isEditing}
                    value={props.submitForm.goal}
                    onChange={props.onChangeGoal}
                  />
                </s.WritingBoardTextInputWrapper>
              </s.WritingBoardContent>
            </s.WritingBoardWrapper>
          </s.UploadBody>
        </s.PlanWriteWrapper>
        <s.PlanWriteSpecifyWrapper>
        <s.CleanUpTableButton onClick={props.initializeLayout}>초기화</s.CleanUpTableButton>
          <s.UploadHead>
            <s.UploadHeadTitle>주간교육계획안 주차 계획표 작성</s.UploadHeadTitle>
            <s.UploadHeadInfo>
              <s.UploadHeadInfoImg src={warning} />
              <s.UploadHeadInfoText>ZIP파일 · 단일 jpg는 업로드 불가입니다.</s.UploadHeadInfoText>
            </s.UploadHeadInfo>
          </s.UploadHead>
          <s.WeeklyPlanTable>
            <s.WeeklyPlanTableHeadRow>
              <s.WeeklyPlanTableHeadHeader>요일</s.WeeklyPlanTableHeadHeader>
              <s.WeeklyPlanTableHeadHeader>월요일</s.WeeklyPlanTableHeadHeader>
              <s.WeeklyPlanTableHeadHeader>화요일</s.WeeklyPlanTableHeadHeader>
              <s.WeeklyPlanTableHeadHeader>수요일</s.WeeklyPlanTableHeadHeader>
              <s.WeeklyPlanTableHeadHeader>목요일</s.WeeklyPlanTableHeadHeader>
              <s.WeeklyPlanTableHeadHeader>금요일</s.WeeklyPlanTableHeadHeader>
            </s.WeeklyPlanTableHeadRow>
            <s.WeeklyPlanTableBodyWrapper>
              {props.popupOn.x !== null && props.popupOn.y !== null ? (
                <SearchModal
                  type="monthly"
                  ageAuths={props.ageAuths}
                  option={props.option}
                  topicList={props.topicList}
                  subTopicList={props.subTopicList}
                  colorList={props.colorList}
                  layout={props.layout}
                  popupOn={props.popupOn}
                  setPopupOn={props.setPopupOn}
                  onPushActivityInLayout={props.onPushActivityInLayout}
                />
              ) : null}
              {props.prevPlanModalOn ? (
                <PrevPlanModal
                  type="weekly"
                  yearList={props.yearList}
                  ageAuths={props.ageAuths}
                  prevPlanData={props.prevPlanData}
                  setPrevPlanData={props.setPrevPlanData}
                  setPrevPlanModalOn={props.setPrevPlanModalOn}
                />
              ) : null}
              <s.GridLayoutWrapper>
                <GridLayout
                  rowHeight={60}
                  cols={12}
                  layout={props.layout}
                  onLayoutChange={props.onLayoutChange}
                  margin={[-1, 0]}
                >
                  {props.layout.map((item: any) => {
                    return item.x < 2 ? (
                      <s.GridLayoutEach key={item.i}>
                        {item.activityList[0] || item.description ? (
                          <s.GridLayoutTopicButton
                            onClick={() =>
                              props.setPopupOn({
                                x: item.x,
                                y: item.y,
                                activityList: item.activityList,
                              })
                            }
                            style={{
                              border: '1px solid transparent',
                              fontSize: '15px',
                              color: '#0087eb',
                            }}
                          >
                            {item.activityList.length > 0
                              ? item.activityList[0].typeCdNm
                              : item.description}
                          </s.GridLayoutTopicButton>
                        ) : (
                          <s.GridLayoutTopicButton
                            onClick={() =>
                              props.setPopupOn({
                                x: item.x,
                                y: item.y,
                                activityList: item.activityList,
                              })
                            }
                          >
                            {item.x === 0 ? '대주제' : '소주제'}
                          </s.GridLayoutTopicButton>
                        )}
                      </s.GridLayoutEach>
                    ) : (
                      <s.GridLayoutEach key={item.i}>
                        {(item.activityList && item.activityList.length) || item.description ? (
                          <s.GridLayoutEachButton
                            onClick={() =>
                              props.setPopupOn({
                                x: item.x,
                                y: item.y,
                                activityList: item.activityList,
                                description: item.description,
                              })
                            }
                            style={{
                              backgroundColor: '#0087ed',
                              color: '#fff',
                              border: '1px solid transparent',
                            }}
                          >
                            완료
                          </s.GridLayoutEachButton>
                        ) : (
                          <s.GridLayoutEachButton
                            onClick={() =>
                              props.setPopupOn({
                                x: item.x,
                                y: item.y,
                                activityList: item.activityList,
                                description: item.description,
                              })
                            }
                          >
                            입력
                          </s.GridLayoutEachButton>
                        )}
                      </s.GridLayoutEach>
                    );
                  })}
                </GridLayout>
              </s.GridLayoutWrapper>
            </s.WeeklyPlanTableBodyWrapper>
          </s.WeeklyPlanTable>
          <s.FooterButtonWrapper>
            <s.GridLayoutSettingButtonWrapper>
              <s.GridLayoutSettingButton onClick={props.addLayoutData}>
                데이터 추가
              </s.GridLayoutSettingButton>
              <s.GridLayoutSettingButton onClick={props.onGetLastData}>
                이전 데이터 불러오기
              </s.GridLayoutSettingButton>
              <s.GridLayoutSettingButton onClick={props.onInitializeLayout}>
                초기화
              </s.GridLayoutSettingButton>
              <s.GridLayoutSettingButton onClick={props.onClear}>정리</s.GridLayoutSettingButton>
            </s.GridLayoutSettingButtonWrapper>
            <s.FooterSubmitButton onClick={props.onSubmit}>데이터 저장</s.FooterSubmitButton>
          </s.FooterButtonWrapper>
        </s.PlanWriteSpecifyWrapper>
      </s.Container>
    </>
  );
};

export default WeeklyPlanPresenter;

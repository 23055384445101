import React, { useState, useEffect } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import CartPresenter from './CartPresenter';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Floating from '../../../components/floating';
import { useRecoilValue, useRecoilState } from 'recoil';

import * as recoilItem from '../../../util/recoilitem';
import * as util from '../../../util/util';
import { shoppingApi } from '../../../api/api-shopping';

interface CartProps extends RouteComponentProps {}

const CartContainer = (props: CartProps) => {
  const [token, setToken] = useRecoilState(recoilItem.token);
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [cartItems, setCartItems] = useState<any>([]);
  const [totalPrices, setTotalPrices] = useState<number>(0);

  useEffect(() => {
    console.log(userInfo);
    fetchData();
  }, []);

  const fetchData = async () => {
    let params: FormData = new FormData();

    let res = await shoppingApi.getCartList(token, params);
    console.log(res);
    if (res.data.rsltCd === '00') {
      setCartItems(res.data.cartItems);
      let total = 0;

      res.data.cartItems.forEach((item: any) => {
        total += item.totalPrice;
      });
      setTotalPrices(total);
    } else {
      setCartItems([]);
      setTotalPrices(0);
    }
  };

  const toggleAllItems = async () => {
    let checkAll: any = $('#cartAll')[0];
    let cartItems: any = document.getElementsByName('cartItem');

    if (checkAll.checked === true) {
      cartItems.forEach((item: any) => {
        item.checked = true;
      });
    } else {
      cartItems.forEach((item: any) => {
        item.checked = false;
      });
    }
  };

  const toggleItem = async (id: string) => {
    let item: any = $(`#${id}`)[0];
    let checkAll: any = $('#cartAll')[0];
    let cartItems: any = document.getElementsByName('cartItem');

    if (item.checked === false) {
      checkAll.checked = false;
    } else {
      let allChecked = true;
      for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i].checked === false) {
          allChecked = false;
        }
      }

      if (allChecked) {
        checkAll.checked = true;
      }
    }
  };

  const disSelectAll = async () => {
    let checkAll: any = $('#cartAll')[0];
    let cartItems: any = document.getElementsByName('cartItem');

    checkAll.checked = false;
    for (let i = 0; i < cartItems.length; i++) {
      cartItems[i].checked = false;
    }
  };

  const updateCount = async (productId: any, count: number) => {
    let params: FormData = new FormData();
    params.append('productId', productId);
    params.append('count', String(count));

    let res = await shoppingApi.updateCart(token, params);
    if (res.data.rsltCd === '00') {
      fetchData();
    }
  };

  const deleteSelectedItem = async () => {
    let selected: any[] = [];
    let cartItems: any = document.getElementsByName('cartItem');
    for (let i = 0; i < cartItems.length; i++) {
      if (cartItems[i].checked === true) {
        selected.push(cartItems[i].value);
      }
    }

    try {
      setIsLoading(true);
      for (let i = 0; i < selected.length; i++) {
        let param: FormData = new FormData();
        param.append('productId', selected[i]);

        let res = await shoppingApi.deleteCart(token, param);
      }

      fetchData();
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const orderSelected = async () => {
    let selected: any[] = [];
    let cartItem: any = document.getElementsByName('cartItem');
    for (let i = 0; i < cartItem.length; i++) {
      if (cartItem[i].checked === true) {
        selected.push(cartItem[i].value);
      }
    }

    if (selected.length < 1) {
      util.makeMsg('선택된 상품이 없습니다', 'error');
      return;
    }

    let passItems: any[] = [];
    for (let i = 0; i < selected.length; i++) {
      passItems.push(cartItems.find((item: any) => String(item.productId) === selected[i]));
    }

    props.history.push('/mypage/order', { passItems: passItems });
  };

  const orderAll = async () => {
    if (cartItems.length < 1) {
      util.makeMsg('장바구니에 상품이 없습니다', 'error');
      return;
    }

    props.history.push('/mypage/order', { passItems: cartItems });
  };

  return (
    <>
      <Header {...props} />
      <Floating {...props} />
      <CartPresenter
        {...props}
        userInfo={userInfo}
        cartItems={cartItems}
        setCartItems={setCartItems}
        toggleAllItems={toggleAllItems}
        toggleItem={toggleItem}
        updateCount={updateCount}
        totalPrices={totalPrices}
        deleteSelectedItem={deleteSelectedItem}
        orderSelected={orderSelected}
        orderAll={orderAll}
      />
      <Footer {...props} />
    </>
  );
};

export default CartContainer;

import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import * as s from './DataPackageStyled';
import * as util from '../../util/util';
import NotFound from '../../components/notFound';
import saveAs from 'file-saver';

interface DataPackageProps {
  token: any;
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  pageList: never[];
  lastPageNum: number;
  isFirst: boolean;
  isLast: boolean;

  programList: any;
  userInfo: any;
}

const DataPackagePresenter = (props: DataPackageProps) => {
  return (
    <s.Container>
      <s.DataPackageHeader>
        <s.DataPackageHeaderTitleArea>
          <s.DataPackageHeaderTitle>교수자료실</s.DataPackageHeaderTitle>
          <s.DataPackageHeaderTitleDivide>|</s.DataPackageHeaderTitleDivide>
          <s.DataPackageHeaderTitle>묶음자료</s.DataPackageHeaderTitle>
        </s.DataPackageHeaderTitleArea>
        <s.DataPackageHeaderRightArea>
          <s.DataPackageHeaderRightText>
            <s.DataPackageHeaderRightIcon src="/static/img/home_icon.jpg" />
            {'HOME > 교수자료실 > 묶음자료'}
          </s.DataPackageHeaderRightText>
        </s.DataPackageHeaderRightArea>
      </s.DataPackageHeader>

      <s.ProgramListArea>
        <s.ProgramList>
          {!props.programList || props.programList.length === 0 ? (
            <NotFound />
          ) : (
            props.programList.map((item: any, index: number) => {
              console.log(item);
              return (
                <s.ProgramForm key={index}>
                  <s.ProgramListStartCol>
                    {index + (props.pageNum - 1) * 10 + 1}
                  </s.ProgramListStartCol>
                  <s.ProgramListCenterCol>{item.title}</s.ProgramListCenterCol>
                  <s.ProgramListLastCol>
                    <s.ScrapButton>스크랩</s.ScrapButton>
                    <s.DownloadButton
                      onClick={() => {
                        if (
                          util.tokenCheck(props.token) &&
                          props.userInfo.userTypeCd !== 'NAIVUSER'
                        ) {
                          console.log(item);
                          saveAs(
                            item.programDocuments[0].file.fileUrl,
                            item.programDocuments[0].file.originalFileName,
                          );
                        } else {
                          util.makeMsg('권한이 부족합니다.', 'error');
                        }
                      }}
                    >
                      다운로드
                    </s.DownloadButton>
                  </s.ProgramListLastCol>
                </s.ProgramForm>
              );
            })
          )}
        </s.ProgramList>
      </s.ProgramListArea>
      <s.PagingNav>
        {props.isFirst ? null : (
          <s.PagingLink onClick={() => props.setPageNum(props.pageNum - 1)}>
            <s.PagingLinkImg src="/static/img/l_shaft.png" />
          </s.PagingLink>
        )}

        <s.PagingNumArea>
          {props.pageList.map((page: number, index: number) => (
            <s.PagingNum
              key={index}
              onClick={() => props.setPageNum(page)}
              current={props.pageNum === page}
            >
              {page}
            </s.PagingNum>
          ))}
        </s.PagingNumArea>
        {props.isLast ? null : (
          <s.PagingLink onClick={() => props.setPageNum(props.pageNum + 1)}>
            <s.PagingLinkImg src="/static/img/r_shaft.png" />
          </s.PagingLink>
        )}
      </s.PagingNav>
    </s.Container>
  );
};

export default DataPackagePresenter;

import React, { Dispatch, SetStateAction } from 'react';

import * as util from '../../util/util';
import * as s from './ShoppingStyled';
import NotFound from '../../components/notFound';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

interface Props {
  mainItems: any[];
  productDv: string;
  setProductDv: Dispatch<SetStateAction<string>>;
  productDetailDv: string;
  setProductDetailDv: Dispatch<SetStateAction<string>>;
  month: string;
  setMonth: Dispatch<SetStateAction<string>>;
  items: any[];
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  pageList: never[];
  lastPageNum: number;
  isFirst: boolean;
  isLast: boolean;
  goToItem: (item: any) => void;
}

const productDvList = [
  { value: '0', name: '베스트상품', fontColor: '#FFAC3C' },
  { value: '-1', name: '특색프로그램', fontColor: '#FF9156' },
  { value: '1', name: '영역별 교구', fontColor: '#EF828B' },
  { value: '2', name: '만들기', fontColor: '#EF66A4' },
  { value: '3', name: '환경구성', fontColor: '#9968B8' },
  { value: '4', name: '시설교구/사무기기', fontColor: '#8F75C1' },
  { value: '5', name: '교재/도서', fontColor: '#6675BB' },
];

const productDetailDvList = [
  [
    { value: '1', name: '신체놀이', backgroundColor: '#FFA4AB', fontColor: '#91383F' },
    { value: '2', name: '쌓기/블록놀이', backgroundColor: '#FFA4AB', fontColor: '#91383F' },
    { value: '3', name: '역할놀이', backgroundColor: '#FFA4AB', fontColor: '#91383F' },
    { value: '4', name: '언어놀이', backgroundColor: '#FFA4AB', fontColor: '#91383F' },
    { value: '5', name: '과학놀이', backgroundColor: '#FFA4AB', fontColor: '#91383F' },
    { value: '6', name: '수/조작놀이', backgroundColor: '#FFA4AB', fontColor: '#91383F' },
    { value: '7', name: '미술놀이', backgroundColor: '#FFA4AB', fontColor: '#91383F' },
    { value: '8', name: '음률놀이', backgroundColor: '#FFA4AB', fontColor: '#91383F' },
  ],
  [
    { value: '1', name: '우리원/입학/졸업', backgroundColor: '#FFBAD8', fontColor: '#B84678' },
    { value: '2', name: '봄/여름', backgroundColor: '#FFBAD8', fontColor: '#B84678' },
    {
      value: '3',
      name: '가족/어버이날/스승의날',
      backgroundColor: '#FFBAD8',
      fontColor: '#B84678',
    },
    {
      value: '4',
      name: '우리동네/직업/교통기관',
      backgroundColor: '#FFBAD8',
      fontColor: '#B84678',
    },
    { value: '5', name: '우리나라/환경과생활', backgroundColor: '#FFBAD8', fontColor: '#B84678' },
    { value: '6', name: '가을/생활도구', backgroundColor: '#FFBAD8', fontColor: '#B84678' },
    { value: '7', name: '우리나라/세계여러나라', backgroundColor: '#FFBAD8', fontColor: '#B84678' },
    { value: '8', name: '겨울/성탄절', backgroundColor: '#FFBAD8', fontColor: '#B84678' },
  ],
  [
    { value: '1', name: '환경판/날짜판', backgroundColor: '#E4BEFF', fontColor: '#7A3DA5' },
    { value: '2', name: '출석판/투약함', backgroundColor: '#E4BEFF', fontColor: '#7A3DA5' },
    { value: '3', name: '오늘의 활동', backgroundColor: '#E4BEFF', fontColor: '#7A3DA5' },
    { value: '4', name: '위생/안전', backgroundColor: '#E4BEFF', fontColor: '#7A3DA5' },
    { value: '5', name: '스티커', backgroundColor: '#E4BEFF', fontColor: '#7A3DA5' },
    { value: '6', name: '역할놀이/현수막', backgroundColor: '#E4BEFF', fontColor: '#7A3DA5' },
    { value: '7', name: '기타', backgroundColor: '#E4BEFF', fontColor: '#7A3DA5' },
  ],
  [
    { value: '1', name: '교구장/사물함', backgroundColor: '#D7C2FF', fontColor: '#5A379D' },
    { value: '2', name: '정리함', backgroundColor: '#D7C2FF', fontColor: '#5A379D' },
    { value: '3', name: '책상/의자', backgroundColor: '#D7C2FF', fontColor: '#5A379D' },
    { value: '4', name: '주방놀이/소꿉놀이', backgroundColor: '#D7C2FF', fontColor: '#5A379D' },
    { value: '5', name: '칼라보드/칠판', backgroundColor: '#D7C2FF', fontColor: '#5A379D' },
    { value: '6', name: '환경판/게시판', backgroundColor: '#D7C2FF', fontColor: '#5A379D' },
    { value: '7', name: '사무기기', backgroundColor: '#D7C2FF', fontColor: '#5A379D' },
    { value: '8', name: '생활가전/생활용품', backgroundColor: '#D7C2FF', fontColor: '#5A379D' },
  ],
  [
    { value: '1', name: '엄지교육', backgroundColor: '#A8B8FF', fontColor: '#2D3E8A' },
    { value: '2', name: '도서', backgroundColor: '#A8B8FF', fontColor: '#2D3E8A' },
    { value: '3', name: '독서/수학교재', backgroundColor: '#A8B8FF', fontColor: '#2D3E8A' },
    { value: '4', name: '블럭교재', backgroundColor: '#A8B8FF', fontColor: '#2D3E8A' },
    { value: '5', name: '미술교재', backgroundColor: '#A8B8FF', fontColor: '#2D3E8A' },
    { value: '6', name: '과학교재', backgroundColor: '#A8B8FF', fontColor: '#2D3E8A' },
  ],
];

const ShoppingPresenter = (props: Props) => {
  return (
    <s.Container>
      <s.MainImgArea>
        <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          spaceBetween={20}
          effect={'fade'}
          pagination={{
            clickable: true,
          }}
        >
          {[
            'static/img/shopping/banner1.png',
            'static/img/shopping/banner2.jpg',
            'static/img/shopping/banner3.jpg',
          ].map((item) => (
            <SwiperSlide>
              <s.MainImg src={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </s.MainImgArea>

      <s.FilterArea>
        {productDvList.map((item: any, index: number) => (
          <s.FilterItem
            index={index}
            onClick={() => {
              props.setProductDv(item.value);
            }}
          >
            {item.name}
          </s.FilterItem>
        ))}
      </s.FilterArea>

      <s.ContentsArea>
        <s.ProgramText
          fontColor={
            productDvList.find((item: any) => item.value === props.productDv)?.fontColor ||
            '#FFAC3C'
          }
        >
          {productDvList.find((item: any) => item.value === props.productDv)?.name}
        </s.ProgramText>
        {Number(props.productDv) > 0 ? (
          <s.DetailFilterWrapper>
            {productDetailDvList[Number(props.productDv) - 1].map((detailDv: any) => (
              <s.DetailFilter
                isActive={props.productDetailDv === detailDv.value}
                backgroundColor={detailDv.backgroundColor}
                fontColor={detailDv.fontColor}
                onClick={() => props.setProductDetailDv(detailDv.value)}
              >
                {detailDv.name}
              </s.DetailFilter>
            ))}
          </s.DetailFilterWrapper>
        ) : null}
        {props.productDv === '-1' && props.month === '0' ? (
          <s.MonthWrapper>
            <s.MonthItem onClick={() => props.setMonth('3')}>
              <s.MonthImage src={'static/img/shopping/m3.png'} />
            </s.MonthItem>
            <s.MonthItem onClick={() => props.setMonth('4')}>
              <s.MonthImage src={'static/img/shopping/m4.png'} />
            </s.MonthItem>
            <s.MonthItem onClick={() => props.setMonth('5')}>
              <s.MonthImage src={'static/img/shopping/m5.png'} />
            </s.MonthItem>
            <s.MonthItem onClick={() => props.setMonth('6')}>
              <s.MonthImage src={'static/img/shopping/m6.png'} />
            </s.MonthItem>
            <s.MonthItem onClick={() => props.setMonth('7')}>
              <s.MonthImage src={'static/img/shopping/m7.png'} />
            </s.MonthItem>
            <s.MonthItem onClick={() => props.setMonth('8')}>
              <s.MonthImage src={'static/img/shopping/m8.png'} />
            </s.MonthItem>
            <s.MonthItem onClick={() => props.setMonth('9')}>
              <s.MonthImage src={'static/img/shopping/m9.png'} />
            </s.MonthItem>
            <s.MonthItem onClick={() => props.setMonth('10')}>
              <s.MonthImage src={'static/img/shopping/m10.png'} />
            </s.MonthItem>
            <s.MonthItem onClick={() => props.setMonth('11')}>
              <s.MonthImage src={'static/img/shopping/m11.png'} />
            </s.MonthItem>
            <s.MonthItem onClick={() => props.setMonth('12')}>
              <s.MonthImage src={'static/img/shopping/m12.png'} />
            </s.MonthItem>
            <s.MonthItem onClick={() => props.setMonth('1')}>
              <s.MonthImage src={'static/img/shopping/m1.png'} />
            </s.MonthItem>
            <s.MonthItem onClick={() => props.setMonth('2')}>
              <s.MonthImage src={'static/img/shopping/m2.png'} />
            </s.MonthItem>
          </s.MonthWrapper>
        ) : null}
      </s.ContentsArea>
      <s.ItemsWrapper>
        {props.items && props.items.length > 0 ? (
          props.items.map((item: any) => {
            let targetThumbnail = item.thumbnailList[0]?.fileUrl;

            return (
              <s.ProductItem onClick={() => props.goToItem(item)}>
                <s.ProductImage src={targetThumbnail} />
                <s.ProductDvText>
                  {productDetailDvList[Number(props.productDv) - 1]
                    ? productDetailDvList[Number(props.productDv) - 1].find(
                        (item: any) => item.value === props.productDetailDv,
                      )?.name
                    : null}
                </s.ProductDvText>
                <s.ProductNmText>{item.productNm}</s.ProductNmText>
                <s.ProductPrice>
                  <s.ProductPriceSpan>{util.priceToString(item.price)}</s.ProductPriceSpan>원
                </s.ProductPrice>
                {item.useYn === 'N' ? <s.SoldOutWrapper>품절</s.SoldOutWrapper> : null}
              </s.ProductItem>
            );
          })
        ) : props.productDv !== '-1' || (props.productDv === '-1' && props.month !== '0') ? (
          <s.NoItemsHolder>조회된 상품이 없습니다.</s.NoItemsHolder>
        ) : null}
      </s.ItemsWrapper>
      {props.items && props.items.length > 0 ? (
        <s.PagingNav>
          {props.isFirst ? null : (
            <s.PagingLink onClick={() => props.setPageNum(props.pageNum - 1)}>
              <s.PagingLinkImg src="/static/img/l_shaft.png" />
            </s.PagingLink>
          )}

          <s.PagingNumArea>
            {props.pageList.map((page: number, index: number) => (
              <s.PagingNum
                key={index}
                onClick={() => props.setPageNum(page)}
                current={props.pageNum === page}
              >
                {page}
              </s.PagingNum>
            ))}
          </s.PagingNumArea>
          {props.isLast ? null : (
            <s.PagingLink onClick={() => props.setPageNum(props.pageNum + 1)}>
              <s.PagingLinkImg src="/static/img/r_shaft.png" />
            </s.PagingLink>
          )}
        </s.PagingNav>
      ) : null}
    </s.Container>
  );
};

export default ShoppingPresenter;

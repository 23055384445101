import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import RegisterEduImagePresenter from './RegisterEduImagePresenter';
import { menuApi } from '../../api/api-menu';
import { comnApi } from '../../api/api-comn';
import { makeMsg } from '../../util/util';
import { eduApi } from '../../api/api-edu';
import { useRecoilValue } from 'recoil';
import * as recoilItem from '../../util/recoilitem';

interface RegisterEduImageProps extends RouteComponentProps {}

const RegisterEduImageContainer = (props: RegisterEduImageProps) => {
  const token = useRecoilValue(recoilItem.token);

  const [option, setOption] = useState<{
    holder: string;
    subMenu: string;
    type: string;
    field: string;
    visible: string;
    age: string;
    mediaSubMenu: string;
    subTab: string;
    stepLength: number;
    currentStep: number;
  }>({
    holder: 'HOL0101',
    subMenu: '',
    type: '',
    field: '',
    visible: 'none',
    age: '',
    mediaSubMenu: '',
    subTab: '',
    stepLength: 0,
    currentStep: 0,
  });
  const [eduList, setEduList] = useState<any[]>([]);
  const [holderList, setHolderList] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [deletedFileList, setDeletedFileList] = useState<any[]>([]);
  const [isModifying, setIsModifying] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (option.subMenu && option.holder) {
      getImages();
    }
  }, [option]);

  const getImages = async () => {
    let formData: FormData = new FormData();
    formData.append('holder', option.holder);
    formData.append('subMenu', option.subMenu);
    let result = await eduApi.getEduPictures(token, formData);
    console.log(result.data);

    if (result.data.rsltCd === '00') {
      console.log(result.data.eduImageList);
      setFileList(result.data.eduImageList.sort((a: any, b: any) => a.viewOrder - b.viewOrder));
    }
  };

  const fetchData = async () => {
    // 1. get Edu List
    await menuApi.getCd('MN02').then((res) => {
      if (res.data.rsltCd === '00') {
        let eduList: any = {};
        res.data.ComnCd.subMenuList.map((item: any) => {
          eduList[item.menuCd] = {
            menuCd: item.menuCd,
            menuNm: item.menuNm,
            viewOrder: item.viewOrder,
          };
        });
        setEduList(eduList);
      }
    });

    await comnApi.getCd('HOL01').then((res) => {
      if (res.data.rsltCd === '00') {
        let holderList: any = {};
        res.data.ComnCd.subCdList.map((item: any) => {
          holderList[item.comnCd] = {
            comnCd: item.comnCd,
            comnCdNm: item.comnCdNm,
            viewOrder: item.viewOrder,
          };
        });
        setHolderList(holderList);
      }
    });
  };

  const onChangeOption = (optionNm: string, optionValue: string) => {
    if (optionNm === 'stepLength' || optionNm === 'currentStep') {
      setOption({
        ...option,
        [optionNm]: optionValue === '' ? 0 : Number(optionValue),
      });
    } else {
      setOption({
        ...option,
        [optionNm]: optionValue,
      });
    }
  };

  const onSubmit = async () => {
    let dupOrder = false;
    let noNames = false;
    let noImage = false;
    let orderArray: any[] = [];

    let newFiles: any[] = [];
    let updateFiles: any[] = [];

    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i];
      if (!orderArray.includes({ order: file.viewOrder, step: file.currentStep })) {
        orderArray.push({ order: file.viewOrder, step: file.currentStep });
      } else {
        dupOrder = true;
        break;
      }

      if (!file.name) {
        noNames = true;
        break;
      }

      if (!file.fileId && !file.file) {
        noImage = true;
        break;
      }

      if (!file.fileId) {
        newFiles.push(file);
      } else {
        updateFiles.push(file);
      }
    }

    if (dupOrder) {
      makeMsg('중복된 순서가 있습니다!', 'error');
      return;
    }

    if (noNames) {
      makeMsg('사진이름은 필수입니다!', 'error');
      return;
    }

    if (noImage) {
      makeMsg('사진은 반드시 업로드하셔야합니다.', 'error');
      return;
    }

    console.log(newFiles);
    console.log(updateFiles);
    console.log(deletedFileList);
    console.log(option);

    let formData: FormData = new FormData();
    formData.append('holder', option.holder);
    formData.append('subMenu', option.subMenu);
    for (let i = 0; i < newFiles.length; i++) {
      let newFile = newFiles[i];
      formData.append(`uploadFiles[${i}].name`, newFile.name);
      formData.append(`uploadFiles[${i}].viewOrder`, newFile.viewOrder);
      formData.append(`uploadFiles[${i}].file`, newFile.file);
      if (newFile.currentStep) {
        formData.append(`uploadFiles[${i}].currentStep`, newFile.currentStep);
      }
    }

    for (let i = 0; i < updateFiles.length; i++) {
      let updateFile = updateFiles[i];
      formData.append(`updateFiles[${i}].fileId`, updateFile.fileId);
      formData.append(`updateFiles[${i}].name`, updateFile.name);
      formData.append(`updateFiles[${i}].viewOrder`, updateFile.viewOrder);
      if (updateFile.currentStep) {
        formData.append(`updateFiles[${i}].currentStep`, updateFile.currentStep);
      }
    }

    for (let i = 0; i < deletedFileList.length; i++) {
      let deleteFile = deletedFileList[i];
      formData.append(`deletedFiles[${i}]`, deleteFile);
    }

    console.log(formData);

    let res = await eduApi.updateEduPictures(token, formData);
    console.log(res);

    if (res.data.rsltCd === '00') {
      makeMsg('저장되었습니다.', 'success');
      props.history.push('/registerSelect');
    } else {
      makeMsg('저장에 실패하였습니다.', 'error');
    }
  };

  return (
    <>
      <Header {...props} />
      <RegisterEduImagePresenter
        option={option}
        {...props}
        eduList={eduList}
        fileList={fileList}
        holderList={holderList}
        setFileList={setFileList}
        onChangeOption={onChangeOption}
        isModifying={isModifying}
        onSubmit={onSubmit}
        deletedFileList={deletedFileList}
        setDeletedFileList={setDeletedFileList}
      ></RegisterEduImagePresenter>
      <Footer {...props} />
    </>
  );
};

export default RegisterEduImageContainer;

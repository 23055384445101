import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as recoilItem from '../../../util/recoilitem';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useDropzone } from 'react-dropzone';

import RegisterDocuformPresenter from './RegisterDocuformPresenter';
import { programApi } from '../../../api/api-program';
import { comnApi } from '../../../api/api-comn';
import { menuApi } from '../../../api/api-menu';
import { makeMsg } from '../../../util/util';

interface RegisterDocuformProps extends RouteComponentProps {
  option: {
    age: string | null;
    holder: string;
    topMenu: string;
    subMenu: string;
    type: string;
    field: string;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      holder: string;
      topMenu: string;
      subMenu: string;
      type: string;
      field: string;
    }>
  >;
  ageAuths: any;
  holderList: any;
}

const RegisterDocuformContainer = (props: RegisterDocuformProps) => {
  const token = useRecoilValue(recoilItem.token);
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: (files) => {
      setSubmitFileList(files);
    },
  });
  const [isModifying, setIsModifying] = useState(true);
  const [typeList, setTypeList] = useState<
    {
      comnCd: string;
      comnCdNm: string;
      viewOrder: number;
    }[]
  >([]);

  const [fieldList, setFieldList] = useState<
    {
      menuCd: string;
      menuNm: string;
      viewOrder: number;
    }[]
  >([]);
  const [submitFileList, setSubmitFileList] = useState<File[]>([]);
  const [title, setTitle] = useState<string>('');
  const [thumbnail, setThumbnail] = useState<File[]>([]);

  const getField = async () => {
    try {
      setIsLoading(true);
      await menuApi.getCd('MN03').then((res) => {
        if (res.data.rsltCd === '00') {
          setFieldList(
            res.data.ComnCd.subMenuList.map((item: any) => ({
              menuCd: item.menuCd,
              menuNm: item.menuNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const getType = async () => {
    try {
      await comnApi.getCd('TYPE03').then((res) => {
        if (res.data.rsltCd === '00') {
          setTypeList(
            res.data.ComnCd.subCdList.map((item: any) => ({
              comnCd: item.comnCd,
              comnCdNm: item.comnCdNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getType();
    getField();
  }, []);

  const submit = async () => {
    try {
      setIsLoading(true);
      let formData: FormData = new FormData();
      if (!props.option.age) {
        makeMsg('연령을 선택해주세요.', 'error');
        return;
      }
      if (!props.option.subMenu) {
        makeMsg('분야를 선택해주세요.', 'error');
        return;
      }
      if (!props.option.type) {
        makeMsg('타입을 선택해주세요.', 'error');
        return;
      }
      if (!props.option.holder) {
        makeMsg('월(호)를 선택해주세요.', 'error');
        return;
      }
      if (!title) {
        makeMsg('제목을 입력해주세요.', 'error');
        return;
      }
      formData.append('age', props.option.age);
      formData.append('topMenu', props.option.topMenu);
      formData.append('subMenu', props.option.subMenu);
      if (props.option.field) {
        formData.append('field', props.option.field);
      }
      formData.append('type', props.option.type);
      formData.append('holder', props.option.holder);
      formData.append('title', title);
      formData.append('isEduTool', 'N');

      formData.append('thumbnail.file', thumbnail[0]);
      formData.append('programDocuments[0].title', title);
      formData.append('programDocuments[0].file.file', submitFileList[0]);

      await programApi.createProgram(token, formData).then((res) => {
        if (res.data.rsltCd === '00') {
          makeMsg('업로드에 성공하였습니다.', 'success');
          setIsLoading(false);
          props.history.push('/registerSelect');
        } else {
          console.log(res);
          makeMsg('업로드에 실패하였습니다.', 'error');
          setIsLoading(false);
        }
      });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <RegisterDocuformPresenter
      {...props}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      open={open}
      acceptedFiles={acceptedFiles}
      isDragActive={isDragActive}
      isModifying={isModifying}
      setIsModifying={setIsModifying}
      submitFileList={submitFileList}
      typeList={typeList}
      fieldList={fieldList}
      submit={submit}
      title={title}
      setTitle={setTitle}
      thumbnail={thumbnail}
      setThumbnail={setThumbnail}
    />
  );
};

export default RegisterDocuformContainer;

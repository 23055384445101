import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as recoilItem from '../../../util/recoilitem';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useDropzone } from 'react-dropzone';

import RegisterProgramPresenter from './RegisterProgramPresenter';
import { programApi } from '../../../api/api-program';
import { makeMsg } from '../../../util/util';

interface RegisterProgramProps extends RouteComponentProps {}

const RegisterProgramContainer = (props: RegisterProgramProps) => {
  const token = useRecoilValue(recoilItem.token);
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: (files) => {
      setSubmitFileList(files);
    },
  });
  const [isModifying, setIsModifying] = useState(true);

  const [submitFileList, setSubmitFileList] = useState<File[]>([]);

  const groupBy = (xs: any[], key: string | number) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  const submit = async () => {
    try {
      setIsLoading(true);
      let formData: FormData = new FormData();

      let groupingList = groupBy(
        submitFileList.map((item) => ({
          grouping:
            item.name.split('.')[0].split('_')[0] +
            ',' +
            item.name.split('.')[0].split('_')[1] +
            ',' +
            item.name.split('.')[0].split('_')[2] +
            ',' +
            item.name.split('.')[0].split('_')[3] +
            ',' +
            item.name.split('.')[0].split('_')[4] +
            ',' +
            item.name.split('.')[0].split('_')[5].split(';;')[0],
          file: item,
        })),
        'grouping',
      );
      let i = 0;
      Object.keys(groupingList).map((key: string) => {
        for (let j = 0; j < groupingList[key].length; j++) {
          formData.append(`files[${i}][${j}]`, groupingList[key][j].file);
        }
        i = i + 1;
      });

      await programApi.createBulkProgram(token, formData).then((res) => {
        console.log(res);
        if (res.data.rsltCd === '00') {
          makeMsg('업로드에 성공하였습니다.', 'success');
          setIsLoading(false);
          props.history.push('/registerSelect');
        } else {
          makeMsg('업로드에 실패하였습니다.', 'error');
          setIsLoading(false);
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <RegisterProgramPresenter
      {...props}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      open={open}
      acceptedFiles={acceptedFiles}
      isDragActive={isDragActive}
      isModifying={isModifying}
      setIsModifying={setIsModifying}
      submitFileList={submitFileList}
      submit={submit}
    />
  );
};

export default RegisterProgramContainer;

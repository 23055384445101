import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0px 0px 10px #cdcdcd;
  z-index: 99;
  position: relative;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;



export const HeaderTopArea = styled.div`
  width: 1000px;
  display: inline-block;
  vertical-align: middle;
  margin: 25px auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const HeaderTopBtnArea = styled.div`
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 220px;
  height: 29px;
  margin-left: 20px;
`;

export const HeaderTopBtn = styled.div`
  display: inline-block;
  font-size: 15px;
  color: #7e7e7e;
  cursor: pointer;
`;

export const HeaderContentArea = styled.div`
  width: 1180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 10px;
  text-align: center;
`;

export const SectionHigh = styled.div`
  width: 100%;
  height: 20%;
`;

export const SectionLow = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SectionLowTextSection = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 250px;
  height: 29px;
  margin-left: 30px;
`;

export const SectionLowTextSectionHigh = styled.div`
  height: 50%;
  width: 100%;
  font-size: 12px;
  color: #0087ED;
  text-align: left;
`;

export const SectionLowTextSectionLow = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const SectionLowTextSectionLowLinks = styled.div`
  font-size: 12px;
  color: #838383;
  margin-right: 10px;
  &:hover {
    color: #0087ED;
  }
`;

export const LogoImg = styled.img`
  width: 123px;
  height: auto;
  object-fit: contain;
  cursor: pointer;
  margin-left: 50px;
`;

export const SearchBar = styled.div`
  margin-left: 80px;
  border: 2px solid #0087ed;
  width: 550px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SearchSelect = styled.div`
  padding: 5px 1px;
  width: 20%;
  cursor: pointer;
  color: #1e1e1e;
  font-size: 12px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 300;
`;

export const SearchSelectIcon = styled.div`
  padding: 5px 0px;
  display: inline-block;
  width: 5%;
  vertical-align: middle;
  cursor: pointer;
  color: #0087ed;
`;

export const SearchTextArea = styled.div`
  padding: 5px 10px;
  display: inline-block;
  width: 65%;
`;

export const SearchTextInput = styled.input`
  width: 100%;
  border: 0;
  vertical-align: middle;
  font-size: 12px;
  text-align: left;
  outline: none;
  color: #1e1e1e;

  &::placeholder {
    color: #e2e2e2;
    letter-spacing: 1.5px;
  }
`;
export const SearchIcon = styled.div`
  height: 53px;
  width: 57px;
  vertical-align: middle;
  background-color: #0087ed;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const HeaderNavArea = styled.div`
  width: 1200px;
  display: inline-block;
  margin: 20px auto 0px;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;

export const HeaderNavBar = styled.ul`
  display: inline-block;
  width: 100%;
`;

export const HeaderNavBarItem = styled.li<{ length: number }>`
  display: inline-block;
  /* width: ${(props) => 100 / props.length + '%'}; */
  width: 170px;
  height: 53px;
  padding: 15px 25px;
  font-size: 18px;
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  color: #1e1e1e;
  position: relative;

  p {
    color: #1e1e1e;
  }

  &:hover {
    background: #0186eb;
    color: #ffffff;
    p {
      color: #ffffff;
    }
  }
`;
export const HeaderNavBarItemText = styled.div``;
// export const HeaderHoverContentArea = styled.div<{ visible: boolean }>`
//   width: 100%;
//   position: absolute;
//   visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
//   top: 175px;
//   left: 0%;
//   justify-content: center;
//   text-align: center;
//   z-index: 999;
// `;

// export const HeaderHoverContent = styled.div`
//   -webkit-transition: all 0.1s ease-out 0s;
//   -moz-transition: all 0.1s ease-out 0s;
//   -ms-transition: all 0.1s ease-out 0s;
//   -o-transition: all 0.1s ease-out 0s;
//   transition: all 0.1s ease-out 0s;
//   overflow: hidden;
//   /* position: absolute; */
//   display: inline-block;
//   background: #fff;
//   border-top: 1px solid #0087ed;
//   width: 1200px;
//   /* width: 1180px; */
//   /* left: 0%; */
//   /* top: 175px; */
//   height: auto;
//   z-index: 999;
// `;

// export const HeaderHoverContentRow = styled.ul`
//   display: inline-block;
//   padding: 15px 40px;
//   vertical-align: top;
//   text-align: left;
//   width: 196px;
// `;

// export const HeaderHoverContentCol = styled.li`
//   -webkit-transition: color 0.2s ease-out 0s;
//   -moz-transition: color 0.2s ease-out 0s;
//   -ms-transition: color 0.2s ease-out 0s;
//   -o-transition: color 0.2s ease-out 0s;
//   transition: color 0.2s ease-out 0s;
//   font-size: 13px;
//   padding: 7px 0;
//   color: #1e1e1e;

//   &::before {
//     content: '•';
//     margin: 0 4px;
//   }
//   &:hover {
//     color: #0087ed;
//   }
// `;

// export const HeaderHoverContentRowArea = styled.div``;

// export const HeaderHoverContentRowRow = styled.li`
//   -webkit-transition: color 0.2s ease-out 0s;
//   -moz-transition: color 0.2s ease-out 0s;
//   -ms-transition: color 0.2s ease-out 0s;
//   -o-transition: color 0.2s ease-out 0s;
//   transition: color 0.2s ease-out 0s;
//   font-size: 13px;
//   padding: 7px 0;
//   color: #1e1e1e;

//   &::before {
//     font-size: 8px;
//     font-weight: bold;
//     content: '>';
//     margin: 0 7px;
//   }
//   &:hover {
//     color: #0087ed;
//     &::before {
//       content: 'ᐯ';
//     }
//   }
// `;
// export const HeaderHoverContentRowCol = styled.li<{ visible: boolean }>`
//   display: ${(props) => (props.visible ? 'block' : 'none')};
//   -webkit-transition: color 0.2s ease-out 0s;
//   -moz-transition: color 0.2s ease-out 0s;
//   -ms-transition: color 0.2s ease-out 0s;
//   -o-transition: color 0.2s ease-out 0s;
//   transition: color 0.2s ease-out 0s;
//   font-size: 13px;
//   padding: 7px 2px 7px 0px;
//   color: #1e1e1e;

//   &::before {
//     content: '•';
//     margin: 0 4px;
//   }
//   &:hover {
//     color: #0087ed;
//   }
// `;

export const HeaderHoverContentArea = styled.div<{ visible: boolean }>`
  position: absolute;
  width: 100%;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  /* top: 178px; */
  top: 50px;
  left: 0%;
  justify-content: center;
  text-align: center;
  z-index: 999;
  font-weight: 400;
`;

export const HeaderHoverContent = styled.div`
  -webkit-transition: all 0.1s ease-out 0s;
  -moz-transition: all 0.1s ease-out 0s;
  -ms-transition: all 0.1s ease-out 0s;
  -o-transition: all 0.1s ease-out 0s;
  transition: all 0.1s ease-out 0s;
  overflow: hidden;
  /* position: absolute; */
  display: inline-block;
  background: #fff;
  border-top: 1px solid #0087ed;
  border-bottom: 1px solid #0087ed;
  width: 100%;
  height: 440px;
  z-index: 999;
`;

export const HeaderHoverContentRow = styled.ul`
  display: inline-block;
  vertical-align: top;
  padding: 10px 0 0 0;
  text-align: left;
`;

export const HeaderHoverContentCol = styled.li`
  -webkit-transition: color 0.2s ease-out 0s;
  -moz-transition: color 0.2s ease-out 0s;
  -ms-transition: color 0.2s ease-out 0s;
  -o-transition: color 0.2s ease-out 0s;
  transition: color 0.2s ease-out 0s;
  font-size: 16px;
  padding: 5px 0;
  color: #1e1e1e;
  word-wrap: normal;

  /* &::before {
    content: '•';
    margin: 0 4px;
  } */
  &:hover {
    color: #0087ed;
  }
`;

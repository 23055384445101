import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const topToBottom = keyframes`
  0%: {
    opacity: 0;
  }
  6%: {
    opacity: 0;
    transform: translateY(-30px);
  }
  10%: {
    opacity: 1;
    transform: translateY(0px);
  }
  25%: {
    opacity: 1;
    transform: translateY(0px);
  }
  29%: {
    opacity: 0;
    transform: translateY(30px);
  }
  80%: {
    opacity: 0;
  }
  100%: {
    opacity: 0;
  }
`;

export const Conatiner = styled.div`
  position: fixed; 
  width: 122px;
  height: 682px;
  display: inline-block; 
  right: 5%;  
  top: 195px;
  margin: 0;
  z-index: 999;
  animation: ${fadeIn} 0.7s ease-out;

  @media screen and (max-width: 1650px) {
    display: none;
  }

  @media screen and (min-width: 1900px) {
    right: 11%;
  }
`

export const AdvArea = styled.div`
  width: 122px;
  height: 141px;
  border-radius: 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const PlanArea = styled.div`
  width: 122px;
  height: 142px;
  background-color: #FFFFFF;
  border-radius: 10px;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;

export const PlanTitle = styled.div`
  width: 122px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  background-color: #0087ED;
  border-radius: 10px 10px 0px 0px;
`;

export const PlanItem = styled.div`
  width: 122px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #838383;
  cursor: pointer;
  border-bottom: 1px solid #BBBBBB;
  border-left: 1px solid #BBBBBB;
  border-right: 1px solid #BBBBBB;
  padding: 0px;

  &:hover {
    color: #0087ED;
  }
`;


export const WeatherArea = styled.div`
  width: 122px;
  height: 385px;
  margin-top: 5px;
  border-radius: 10px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #D9D9D9;
`; 

export const SupportWeatherCityArea = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

export const SupportWeatherCityBtn = styled.div`
  display: inline-block;
  width: 9px;
  height: 12px;
  cursor: pointer;
`

export const SupportWeatherCityBtnImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const SupportCityTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #4d4d4d;
  margin-left: 30px;
  margin-right: 30px;
`;



export const WeatherTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #838383;
  margin-top: 15px;
`;

export const WeatherIconType1 = styled.img`
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const WeatherIconType2 = styled.img`
  width: 46px;
  height: 46px;
  object-fit: contain;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const WeatherText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
`;

export const AdImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
`;
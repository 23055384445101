import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as recoilItem from '../../../../util/recoilitem';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useDropzone } from 'react-dropzone';

import YearlyPlanPresenter from './YearlyPlanPresenter';
import { planApi } from '../../../../api/api-plan';
import { makeMsg } from '../../../../util/util';

interface YearlyPlanProps extends RouteComponentProps {
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  setOption: Dispatch<
    SetStateAction<{
      age: string | null;
      year: number;
      month: number;
      week: number;
      day: number;
    }>
  >;
  ageAuths: any;
}

const YearlyPlanContainer = (props: YearlyPlanProps) => {
  const token = useRecoilValue(recoilItem.token);
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: (files) => {
      setYearlyPlanFile(files[0]);
      setUploadStatus('exist');
    },
  });

  const [uploadStatus, setUploadStatus] = useState<string>('ready');
  const [yearlyPlan, setYearlyPlan] = useState<any>(null);
  const [yearlyPlanFile, setYearlyPlanFile] = useState<any>();

  const [months, setMonths] = useState<
    {
      monthId: number | undefined;
      month: number | undefined;
      num: number | undefined;
      week: number;
      file: any;
      subject: string | undefined;
      uploadStatus: string;
      weeks: {
        weekId: number | undefined;
        week: number | undefined;
        subject: string | undefined;
        file: any;
        uploadStatus: string;
      }[];
    }[]
  >([
    {
      monthId: undefined,
      month: undefined,
      num: undefined,
      week: 0,
      file: undefined,
      subject: undefined,
      weeks: [],
      uploadStatus: 'ready',
    },
  ]);

  const addMonth = () => {
    let tempList = [...months];
    tempList.push({
      monthId: undefined,
      month: undefined,
      num: undefined,
      week: 0,
      file: undefined,
      subject: undefined,
      uploadStatus: 'ready',
      weeks: [],
    });
    setMonths(tempList);
  };
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);

  const fetchData = async () => {
    if (!props.option.age) {
      makeMsg('연령을 선택해주세요.', 'error');
      return false;
    }
    try {
      setIsLoading(true);
      setYearlyPlanFile(undefined);
      setYearlyPlan(undefined);
      setUploadStatus('ready');
      setMonths([]);

      await planApi.getPlanYear(token, props.option.age, props.option.year).then((res) => {
        if (res.data.rsltCd === '00') {
          setYearlyPlan({ ...res.data.yearlyPlan });
          // if (res.data.yearlyPlan.file) {
          //   setYearlyPlanFile(res.data.yearlyPlan.file);
          //   setUploadStatus('complete');
          // }
          res.data.yearlyPlan.months.length > 0
            ? setMonths(
                res.data.yearlyPlan.months
                  .map((item: any, index: number) => ({
                    monthId: item.monthId,
                    month: item.month,
                    num: item.num,
                    week: item.weeks.length,
                    // file: item.file,
                    subject: item.subject,
                    // uploadStatus: item.file ? 'complete' : 'ready',
                    weeks: item.weeks.map((week: any) => ({
                      weekId: week.weekId,
                      week: week.week,
                      subject: week.subject,
                      // file: week.file,
                      // uploadStatus: week.file ? 'complete' : 'ready',
                    })),
                  }))
                  .sort((a: any, b: any) => a.num > b.num),
              )
            : setMonths([
                {
                  monthId: undefined,
                  month: undefined,
                  num: undefined,
                  week: 0,
                  file: undefined,
                  subject: undefined,
                  uploadStatus: 'ready',
                  weeks: [],
                },
              ]);
        } else {
          setMonths([
            {
              monthId: undefined,
              month: undefined,
              num: undefined,
              week: 0,
              file: undefined,
              subject: undefined,
              uploadStatus: 'ready',
              weeks: [],
            },
          ]);
        }
        setIsLoading(false);
      });
    } catch (e) {
      console.log(e);
      setMonths([
        {
          monthId: undefined,
          month: undefined,
          num: undefined,
          week: 0,
          file: undefined,
          subject: undefined,
          uploadStatus: 'ready',
          weeks: [],
        },
      ]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.option.age, props.option.year]);

  const submitYearlyPlanFile = async () => {
    let data: FormData = new FormData();
    if (!props.option.age) {
      makeMsg('연령을 선택해주세요.', 'error');
      return false;
    }
    if (!yearlyPlanFile) {
      makeMsg('파일을 선택해주세요.', 'error');
      return false;
    }
    if (yearlyPlan) {
      data.append('planId', yearlyPlan.planId);
    }
    data.append('age', props.option.age);
    data.append('year', String(props.option.year));
    data.append('file.file', yearlyPlanFile);
    try {
      setUploadStatus('loading');
      await planApi.createPlanYearly(token, data).then((res) => {
        if (res.data.rsltCd === '00') {
          setUploadStatus('complete');
          return true;
        } else {
          makeMsg('업로드에 실패하였습니다. 다시 시도해주세요.', 'error');
          setUploadStatus('ready');
          return false;
        }
      });
    } catch (e) {
      console.log(e);
      makeMsg('업로드에 실패하였습니다. 다시 시도해주세요.', 'error');
      setUploadStatus('ready');
      return false;
    }
  };

  const removeYearlyPlanMonth = async (index: number) => {
    let deleteMonth = months[index];
    let tempMonths = [];
    for (let i = 0; i < months.length; i++) {
      if (i !== index) {
        tempMonths.push(months[i]);
      }
    }

    console.log(deleteMonth);
    console.log(yearlyPlan);
    let data = new FormData();
    data.append('monthId', String(deleteMonth.monthId));
    data.append('planId', String(yearlyPlan.planId));
    console.log(deleteMonth.monthId);
    let res = await planApi.deletePlanYearlyMonth(token, data);
    if (res && res.data.rsltCd === '00') {
      makeMsg('삭제에 성공하였습니다.', 'success');
    } else {
      makeMsg('삭제에 실패하였습니다.', 'error');
      return;
    }

    setMonths(tempMonths);

    return true;
  }

  const submitYearlyPlanMonth = async (index: number) => {
    let data: FormData = new FormData();
    if (!props.option.age) {
      makeMsg('연령을 선택해주세요.', 'error');
      return false;
    }
    if (yearlyPlan) {
      data.append('planId', yearlyPlan.planId);
    }
    if (!months[index].month) {
      makeMsg('월을 입력해주세요.', 'error');
      return false;
    }
    if (!months[index].subject) {
      makeMsg('생활주제를 입력해주세요.', 'error');
      return false;
    }
    if (months[index].monthId) {
      data.append('months[0].monthId', String(months[index].monthId));
    }
    // if (months[index].uploadStatus === 'exist') {
    //   data.append('months[0].file.file', months[index].file);
    // }
    data.append('age', props.option.age);
    data.append('year', String(props.option.year));
    data.append('months[0].month', String(months[index].month));
    data.append('months[0].num', String(months[index].num));
    data.append('months[0].subject', String(months[index].subject));
    months[index].weeks.forEach((item: any, weekIndex: number) => {
      if (item.weekId) {
        data.append(`months[0].weeks[${weekIndex}].weekId`, item.weekId);
      }
      data.append(`months[0].weeks[${weekIndex}].subject`, item.subject);
      data.append(`months[0].weeks[${weekIndex}].week`, item.week);
      // if (item.uploadStatus === 'exist') {
      //   data.append(`months[0].weeks[${weekIndex}].file.file`, item.file);
      // }
    });

    try {
      let tempList = [...months];
      tempList[index].uploadStatus = 'loading';
      setMonths(tempList);
      await planApi.createPlanYearly(token, data).then((res) => {
        if (res.data.rsltCd === '00') {
          let tempList = [...months];
          tempList[index].uploadStatus = 'complete';
          tempList[index].weeks.forEach(
            (week, weekIndex) => (tempList[index].weeks[weekIndex].uploadStatus = 'complete'),
          );
          setMonths(tempList);
          return true;
        } else {
          makeMsg('업로드에 실패하였습니다. 다시 시도해주세요.', 'error');
          let tempList = [...months];
          tempList[index].uploadStatus = 'ready';
          tempList[index].weeks.forEach(
            (week, weekIndex) => (tempList[index].weeks[weekIndex].uploadStatus = 'ready'),
          );
          setMonths(tempList);
          return false;
        }
      });
    } catch (e) {
      console.log(e);
      makeMsg('업로드에 실패하였습니다. 다시 시도해주세요.', 'error');
      let tempList = [...months];
      tempList[index].uploadStatus = 'ready';
      tempList[index].weeks.forEach(
        (week, weekIndex) => (tempList[index].weeks[weekIndex].uploadStatus = 'ready'),
      );
      setMonths(tempList);
      return false;
    }
  };

  return (
    <YearlyPlanPresenter
      {...props}
      option={props.option}
      ageAuths={props.ageAuths}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      open={open}
      acceptedFiles={acceptedFiles}
      isDragActive={isDragActive}
      months={months}
      setMonths={setMonths}
      addMonth={addMonth}
      uploadStatus={uploadStatus}
      setUploadStatus={setUploadStatus}
      yearlyPlanFile={yearlyPlanFile}
      setYearlyPlanFile={setYearlyPlanFile}
      submitYearlyPlanFile={submitYearlyPlanFile}
      submitYearlyPlanMonth={submitYearlyPlanMonth}
      removeYearlyPlanMonth={removeYearlyPlanMonth}
    />
  );
};

export default YearlyPlanContainer;

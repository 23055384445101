import React from 'react';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { RouteComponentProps } from 'react-router-dom';
import YearlyMonth from './component/YearlyMonth';

import { FiPlusCircle } from 'react-icons/fi';
import BeatLoader from 'react-spinners/BeatLoader';
import * as s from './YearltPlanStyled';

interface YearltPlanProps extends RouteComponentProps {
  option: {
    age: string | null;
    year: number;
    month: number;
    week: number;
    day: number;
  };
  ageAuths: any;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  open: () => void;
  acceptedFiles: File[];
  months: {
    monthId: number | undefined;
    month: number | undefined;
    num: number | undefined;
    week: number;
    file: any;
    subject: string | undefined;
    uploadStatus: string;
    weeks: {
      weekId: number | undefined;
      week: number | undefined;
      subject: string | undefined;
      file: any;
      uploadStatus: string;
    }[];
  }[];
  setMonths: React.Dispatch<
    React.SetStateAction<
      {
        monthId: number | undefined;
        month: number | undefined;
        num: number | undefined;
        week: number;
        file: any;
        subject: string | undefined;
        uploadStatus: string;
        weeks: {
          weekId: number | undefined;
          week: number | undefined;
          subject: string | undefined;
          file: any;
          uploadStatus: string;
        }[];
      }[]
    >
  >;
  addMonth: () => void;
  uploadStatus: string;
  setUploadStatus: React.Dispatch<React.SetStateAction<string>>;
  isDragActive: boolean;
  yearlyPlanFile: any;
  setYearlyPlanFile: React.Dispatch<React.SetStateAction<any>>;
  submitYearlyPlanFile: () => Promise<false | undefined>;
  submitYearlyPlanMonth: (index: number) => Promise<false | undefined>;
  removeYearlyPlanMonth: (index: number) => void;
}

const YearltPlanPresenter = (props: YearltPlanProps) => {
  return (
    <s.Container>
      <s.PlanningTitleArea>
        <s.PlanningTitle>
          <s.PlanningTitleSpan>{props.option.year}년</s.PlanningTitleSpan> 만{' '}
          <s.PlanningTitleSpan>
            {!props.ageAuths || props.ageAuths.length === 0
              ? null
              : props.option.age
              ? props.ageAuths[props.option.age].comnCdNm
              : null}
          </s.PlanningTitleSpan>{' '}
          연간 교육계획안
        </s.PlanningTitle>
      </s.PlanningTitleArea>
      {/* <s.YearlyPlanFileUploadArea>
        <s.YearlyPlanFileUploadAreaHeader>
          <s.YearlyPlanFileUploadAreaHeaderLeft>
            연간교육계획안 묶음 업로드
          </s.YearlyPlanFileUploadAreaHeaderLeft>
          <s.YearlyPlanFileUploadAreaHeaderRight>
            <s.YearlyPlanFileUploadAreaHeaderRightIcon src="/static/img/warning.png" />
            <s.YearlyPlanFileUploadAreaHeaderRightText>
              ZIP파일•단일 jpg는 업로드 불가입니다.
            </s.YearlyPlanFileUploadAreaHeaderRightText>
          </s.YearlyPlanFileUploadAreaHeaderRight>
        </s.YearlyPlanFileUploadAreaHeader>
        <s.YearlyPlanFileUploadInputArea
          isDrag={props.isDragActive}
          {...props.getRootProps({ className: 'dropzone' })}
        >
          <s.YearlyPlanFileUploadInput {...props.getInputProps()} />
          {props.uploadStatus !== 'ready' ? (
            <s.YealyPlanUploadMonthAreaFileUploadAreaButtonIcon
              onClick={props.open}
              src="/static/img/pdf_icon_1.png"
            />
          ) : null}
          <s.YearlyPlanFileUploadStatusName>
            {props.uploadStatus !== 'ready'
              ? props.yearlyPlanFile?.name
                ? props.yearlyPlanFile?.name
                : props.yearlyPlanFile?.originalFileName
              : null}
          </s.YearlyPlanFileUploadStatusName>
          <s.YearlyPlanFileUploadInputButtonIcon
            onClick={props.open}
            src={
              props.uploadStatus !== 'ready'
                ? '/static/img/file_exist.png'
                : '/static/img/selectFile.png'
            }
          />
        </s.YearlyPlanFileUploadInputArea>
        <s.YearlyPlanFileUploadStatusArea>
          <s.YearlyPlanFileUploadStatusName>
            {props.uploadStatus !== 'ready'
              ? props.yearlyPlanFile?.name
                ? props.yearlyPlanFile?.name
                : props.yearlyPlanFile?.originalFileName
              : '파일을 업로드해주세요.'}
          </s.YearlyPlanFileUploadStatusName>
          <s.YearlyPlanFileUploadStatus
            status={props.uploadStatus}
            onClick={() => (props.uploadStatus === 'exist' ? props.submitYearlyPlanFile() : null)}
          >
            {props.uploadStatus === 'loading' ? (
              <BeatLoader size={8} color={'#acacac'} />
            ) : props.uploadStatus === 'complete' ? (
              '완료'
            ) : props.uploadStatus === 'exist' ? (
              '업로드'
            ) : (
              '대기'
            )}
          </s.YearlyPlanFileUploadStatus>
          <s.YearlyPlanFileUploadStatusCancle
            onClick={() => {
              if (props.setYearlyPlanFile) {
                props.acceptedFiles.pop();
                props.setUploadStatus('ready');
                props.setYearlyPlanFile(undefined);
              }
            }}
          >
            취소
          </s.YearlyPlanFileUploadStatusCancle>
        </s.YearlyPlanFileUploadStatusArea>
      </s.YearlyPlanFileUploadArea> */}
      <s.YearlyPlanUploadArea>
        <s.YearlyPlanUploadAreaHeader>
          <s.YearlyPlanUploadAreaHeaderLeft>
            연간교육계획안 월(호) 작성
          </s.YearlyPlanUploadAreaHeaderLeft>
          <s.YearlyPlanUploadAreaHeaderRight>
            <s.YearlyPlanUploadAreaHeaderRightIcon src="/static/img/warning.png" />
            <s.YearlyPlanUploadAreaHeaderRightText>
              ZIP파일•단일 jpg는 업로드 불가입니다.
            </s.YearlyPlanUploadAreaHeaderRightText>
          </s.YearlyPlanUploadAreaHeaderRight>
        </s.YearlyPlanUploadAreaHeader>
        <s.YealyPlanUploadContent>
          {props.months.map((month, monthIndex) => (
            <YearlyMonth
              key={'Month' + monthIndex}
              {...props}
              monthIndex={monthIndex}
              submitYearlyPlanMonth={props.submitYearlyPlanMonth}
              removeYearlyPlanMonth={props.removeYearlyPlanMonth}
            />
          ))}
        </s.YealyPlanUploadContent>
        <s.MonthAddButtonArea>
          <s.MonthAddButton onClick={props.addMonth}>
            <FiPlusCircle size={16} color={'#fff'} />
            <s.MonthAddButtonText>월(호) 추가하기</s.MonthAddButtonText>
          </s.MonthAddButton>
        </s.MonthAddButtonArea>
      </s.YearlyPlanUploadArea>
    </s.Container>
  );
};

export default YearltPlanPresenter;

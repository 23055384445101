import React from 'react';

import * as util from '../../util/util';
import * as s from './MultiStyled';
import NotFound from '../../components/notFound';

interface MultiProps {
  ageList: any;
  subMenuList: never[];
  holderList: never[];
  selectedAge: {
    comnCd: string;
    comnCdNm: string;
  };
  setSelectedAge: React.Dispatch<
    React.SetStateAction<{
      comnCd: string;
      comnCdNm: string;
    }>
  >;
  selectedSubMenuList: any;
  setSelectedSubMenuList: any;
  selectedHolderList: any;
  onChangeAge: React.MouseEventHandler<HTMLLIElement>;
  onChangeSubMenuList: (item: any) => void;
  onChangeHolderList: (item: any) => void;
  programList: any;
  setProgramId: any;
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  pageList: never[];
  lastPageNum: number;
  isFirst: boolean;
  isLast: boolean;
}

const MultiPresenter = (props: MultiProps) => {
  return (
    <s.Container>
      <s.MultiHeader>
        <s.MultiHeaderTitle>멀티미디어</s.MultiHeaderTitle>
        <s.MultiHeaderRightArea>
          <s.MultiHeaderRightText>
            <s.MultiHeaderRightIcon src="/static/img/home_icon.jpg" />
            {'HOME > 멀티미디어'}
          </s.MultiHeaderRightText>
        </s.MultiHeaderRightArea>
      </s.MultiHeader>
      <s.SearchConditionArea>
        <s.SearchConditionAgeArea>
          <s.searchConditionHeader>연령 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            {
              props.ageList.map((item: any, index: number) => (
                <s.SearchConditionItem 
                  selected={item.comnCd === props.selectedAge.comnCd}
                  onClick={() => props.onChangeAge(item)}
                  >{'만 ' + item.comnCdNm}</s.SearchConditionItem>
              ))
            }
          </s.SearchConditionBody>
        </s.SearchConditionAgeArea>
        <s.SearchConditionMonthArea>
          <s.searchConditionHeader>월 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            <s.SearchConditionItem
              selected={props.selectedHolderList.length === 0}
              onClick={() => props.onChangeHolderList(null)}
            >
              전체
            </s.SearchConditionItem>
            {
              props.holderList.map((item: any, index: number) => (
                <s.SearchConditionItem 
                  selected={props.selectedHolderList
                    .map((item: any) => item.comnCd)
                    .includes(item.comnCd)}
                  onClick={() => props.onChangeHolderList(item)}
                  >{item.comnCdNm}</s.SearchConditionItem>
              ))
            }
          </s.SearchConditionBody>
        </s.SearchConditionMonthArea>
        <s.SearchConditionMediaArea>
          <s.searchConditionHeader>미디어 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            <s.SearchConditionItem
              onClick={() => props.onChangeSubMenuList(null)}
              selected={props.selectedSubMenuList.length === 0}
            >
              전체
            </s.SearchConditionItem>
            {props.subMenuList.map((item: any, index: number) => (
              <s.SearchConditionItem
                key={'subMenu' + index}
                selected={props.selectedSubMenuList
                  .map((item: any) => item.menuCd)
                  .includes(item.menuCd)}
                onClick={() => props.onChangeSubMenuList(item)}
              >
                {item.menuNm}
              </s.SearchConditionItem>
            ))}
          </s.SearchConditionBody>
        </s.SearchConditionMediaArea>
      </s.SearchConditionArea>
      
      
      {/* <s.MultiTabArea>
        {props.ageList.map((item: any, index: number) => (
          <s.MultiTab
            key={index}
            active={item.comnCd === props.selectedAge.comnCd}
            onClick={() => props.onChangeAge(item)}
          >
            {'만 ' + item.comnCdNm}
          </s.MultiTab>
        ))}
      </s.MultiTabArea>
      <s.SelectArea>
        <s.SelectLabel>월(호) 선택</s.SelectLabel>
        <s.SelectDiv></s.SelectDiv>
        <s.SelectForm selectType={'holder'}>
          <s.selectItem
            key={'holder99'}
            active={props.selectedHolderList.length === 0}
            selectType={'holder'}
            onClick={() => props.onChangeHolderList(null)}
          >
            전체
          </s.selectItem>
          {props.holderList.map((item: any, index: number) => (
            <s.selectItem
              key={'holder' + index}
              active={props.selectedHolderList
                .map((item: any) => item.comnCd)
                .includes(item.comnCd)}
              selectType={'holder'}
              onClick={() => props.onChangeHolderList(item)}
            >
              {item.comnCdNm}
            </s.selectItem>
          ))}
        </s.SelectForm>
      </s.SelectArea>
      <s.SelectArea>
        <s.SelectLabel>미디어 선택</s.SelectLabel>
        <s.SelectDiv></s.SelectDiv>
        <s.SelectForm selectType={'media'}>
          <s.selectItem
            onClick={() => props.onChangeSubMenuList(null)}
            active={props.selectedSubMenuList.length === 0}
            selectType={'media'}
          >
            전체
          </s.selectItem>
          {props.subMenuList.map((item: any, index: number) => (
            <s.selectItem
              key={'subMenu' + index}
              active={props.selectedSubMenuList
                .map((item: any) => item.menuCd)
                .includes(item.menuCd)}
              selectType={'field'}
              onClick={() => props.onChangeSubMenuList(item)}
            >
              {item.menuNm}
            </s.selectItem>
          ))}
        </s.SelectForm>
      </s.SelectArea> */}



      <s.ProgramListArea>
        <s.ProgramTypeTitleArea>
          <s.ProgramTypeTitleFixedPart>멀티미디어 만</s.ProgramTypeTitleFixedPart>
          <s.ProgramTypeTitlePart>{props.selectedAge.comnCdNm}</s.ProgramTypeTitlePart>
          {props.selectedSubMenuList.map((item: any, index: number) => (
            <s.ProgramTypeTitlePart key={'SubMenu' + index}>{item.menuNm}</s.ProgramTypeTitlePart>
          ))}
          {props.selectedHolderList.map((item: any, index: number) => (
            <s.ProgramTypeTitlePart key={'Holder' + index}>{item.comnCdNm}</s.ProgramTypeTitlePart>
          ))}
          <s.ProgramTypeTitleFixedPart>프로그램</s.ProgramTypeTitleFixedPart>
          <s.ProgramSearchTextArea>
            <s.ProgramSearchText type="text" name="keyword" />
            <s.ProgramSearchButton>
              <s.ProgramSearchImg src="/static/img/lens_2.png" />
            </s.ProgramSearchButton>
          </s.ProgramSearchTextArea>
        </s.ProgramTypeTitleArea>
        {
          <s.ProgramList>
            {!props.programList || props.programList.length === 0 ? (
              <NotFound />
            ) : (
              props.programList.map((item: any, index: number) => {
                console.log(item.title, index);
                return(
                <s.ProgramForm key={index} idx={index}>
                  <s.ProgramThumbnailArea
                    onClick={() =>
                      !item.programId
                        ? util.makeMsg('권한이 부족합니다.', 'error')
                        : props.setProgramId(item.programId)
                    }
                  >
                    {item.thumbnail !== null ? (
                      <s.ProgramThumbnail imgsrc={item.thumbnail.fileUrl}>
                        <s.ProgramDarkness>
                          <s.ProgramLensImg src={'/static/img/lens.png'} />
                        </s.ProgramDarkness>
                      </s.ProgramThumbnail>
                    ) : null}
                  </s.ProgramThumbnailArea>
                  <s.ProgramTitle>{item.title}</s.ProgramTitle>
                  <s.ProgramLinkArea>
                    <s.ProgramScrapLinkArea
                      onClick={() => util.makeMsg('준비중인 기능입니다.', 'info')}
                    >
                      <s.ProgramScrapLink>스크랩</s.ProgramScrapLink>
                    </s.ProgramScrapLinkArea>
                    <s.ProgramDownloadLinkArea>
                      <s.ProgramDownloadLink>활동안</s.ProgramDownloadLink>
                    </s.ProgramDownloadLinkArea>
                  </s.ProgramLinkArea>
                </s.ProgramForm>
              )})
            )}
          </s.ProgramList>
        }
      </s.ProgramListArea>
      <s.PagingNav>
        {props.isFirst ? null : (
          <s.PagingLink onClick={() => props.setPageNum(props.pageNum - 1)}>
            <s.PagingLinkImg src="/static/img/l_shaft.png" />
          </s.PagingLink>
        )}

        <s.PagingNumArea>
          {props.pageList.map((page: number, index: number) => (
            <s.PagingNum
              key={index}
              onClick={() => props.setPageNum(page)}
              current={props.pageNum === page}
            >
              {page}
            </s.PagingNum>
          ))}
        </s.PagingNumArea>
        {props.isLast ? null : (
          <s.PagingLink onClick={() => props.setPageNum(props.pageNum + 1)}>
            <s.PagingLinkImg src="/static/img/r_shaft.png" />
          </s.PagingLink>
        )}
      </s.PagingNav>
    </s.Container>
  );
};

export default MultiPresenter;

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as recoilItem from '../../util/recoilitem';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import UpdateEduPresenter from './UpdateEduPresenter';
import { eduApi } from '../../api/api-edu';
import { makeMsg } from '../../util/util';
import { comnApi } from '../../api/api-comn';
import { menuApi } from '../../api/api-menu';
import * as util from '../../util/util';

interface UpdateEduProps extends RouteComponentProps {}

const UpdateEduContainer = (props: UpdateEduProps) => {
  const token = useRecoilValue(recoilItem.token);
  const setIsLoading = useSetRecoilState(recoilItem.isLoading);
  const [isModifying, setIsModifying] = useState(true);

  const [edu, setEdu] = useState<any>(null);

  const [eduList, setEduList] = useState<
    {
      menuCd: string;
      menuNm: string;
      viewOrder: number;
    }[]
  >([]);
  const [holderList, setHolderList] = useState<
    {
      comnCd: string;
      comnCdNm: string;
      viewOrder: number;
    }[]
  >([]);
  const [typeList, setTypeList] = useState<
    {
      comnCd: string;
      comnCdNm: string;
      viewOrder: number;
    }[]
  >([]);

  const getEdu = async () => {
    try {
      setIsLoading(true);
      if (option.edu === '') {
        setIsLoading(false);
        util.makeMsg('교육프로그램을 선택해주세요.', 'warn');
        return;
      }
      if (option.holder === '') {
        setIsLoading(false);
        util.makeMsg('월(호)를 선택해주세요.', 'warn');
        return;
      }
      if (option.type === '') {
        setIsLoading(false);
        util.makeMsg('프로그램 유형을 선택해주세요.', 'warn');
        return;
      }
      if (option.step === '') {
        setIsLoading(false);
        util.makeMsg('프로그램 단계를 선택해주세요.', 'warn');
        return;
      }
      console.log(option.edu);

      if (option.edu === 'MN0205') {
        await eduApi
          .getEduWithSubTabAndStep(
            token,
            option.edu,
            option.type,
            option.holder,
            option.subTab,
            option.step,
          )
          .then((res) => {
            console.log(res);
            if (res.data.rsltCd === '00') {
              setEdu(res.data.edu);
              setOption({
                ...option,
                field: res.data.edu.programs[0].field?.menuCd,
                visible: res.data.edu.programs[0].visibleType,
                age: res.data.edu.programs[0].age?.comnCd,
                mediaSubMenu: res.data.edu.programs[0].subMenu?.menuCd,
                description: res.data.edu.description,
              });
            } else {
              util.makeMsg('존재하지 않는 자료입니다.', 'warn');
            }
          });
      } else {
        if (option.step === 'none') {
          await eduApi.getEdu(token, option.edu, option.type, option.holder).then((res) => {
            console.log(res);
            if (res.data.rsltCd === '00') {
              setEdu(res.data.edu);
              setOption({
                ...option,
                field: res.data.edu.programs[0].field?.menuCd,
                visible: res.data.edu.programs[0].visibleType,
                age: res.data.edu.programs[0].age?.comnCd,
                mediaSubMenu: res.data.edu.programs[0].subMenu?.menuCd,
                description: res.data.edu.description,
              });
            } else {
              util.makeMsg('존재하지 않는 자료입니다.', 'warn');
            }
          });
        } else {
          await eduApi
            .getEduWithStep(token, option.edu, option.type, option.holder, option.step)
            .then((res) => {
              console.log(res);
              if (res.data.rsltCd === '00') {
                setEdu(res.data.edu);
                setOption({
                  ...option,
                  field: res.data.edu.programs[0].field?.menuCd,
                  visible: res.data.edu.programs[0].visibleType,
                  age: res.data.edu.programs[0].age?.comnCd,
                  mediaSubMenu: res.data.edu.programs[0].subMenu?.menuCd,
                  description: res.data.edu.description,
                });
              } else {
                util.makeMsg('존재하지 않는 자료입니다.', 'warn');
              }
            });
        }
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      await comnApi.getCd('HOL01').then((res) => {
        if (res.data.rsltCd === '00') {
          let holderList: any = {};
          res.data.ComnCd.subCdList.forEach((item: any) => {
            holderList[item.comnCd] = {
              comnCd: item.comnCd,
              comnCdNm: item.comnCdNm,
              viewOrder: item.viewOrder,
            };
          });
          setHolderList(holderList);
        }
      });
      await comnApi.getCd('TYPE12').then((res) => {
        if (res.data.rsltCd === '00') {
          let typeList: any = {};
          res.data.ComnCd.subCdList.forEach((item: any) => {
            typeList[item.comnCd] = {
              comnCd: item.comnCd,
              comnCdNm: item.comnCdNm,
              viewOrder: item.viewOrder,
            };
          });
          setTypeList(typeList);
        }
        setIsLoading(false);
      });
      await menuApi.getCd('MN02').then((res) => {
        if (res.data.rsltCd === '00') {
          let eduList: any = {};
          res.data.ComnCd.subMenuList.forEach((item: any) => {
            eduList[item.menuCd] = {
              menuCd: item.menuCd,
              menuNm: item.menuNm,
              viewOrder: item.viewOrder,
            };
          });
          setEduList(eduList);
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  const [mediaSubMenuList, setMediaSubMenuList] = useState<any>([]);
  const [ageList, setAgeList] = useState<any>([]);
  const [fieldList, setFieldList] = useState<
    {
      menuCd: string;
      menuNm: string;
      viewOrder: number;
    }[]
  >([]);

  const [option, setOption] = useState<{
    edu: string;
    holder: string;
    type: string;
    field: string;
    visible: string;
    age: string;
    subTab: string;
    step: string;
    mediaSubMenu: string;
    description: string;
  }>({
    edu: '',
    holder: '',
    type: '',
    field: '',
    visible: 'none',
    age: '',
    subTab: '',
    step: '',
    mediaSubMenu: '',
    description: '',
  });

  const onChangeOption = (optionNm: string, optionValue: string) => {
    setOption({
      ...option,
      [optionNm]: optionValue,
    });
  };

  const getAge = async () => {
    try {
      setIsLoading(true);
      await comnApi.getCd('AUTH01').then((res) => {
        if (res.data.rsltCd === '00') {
          setAgeList(
            res.data.ComnCd.subCdList.map((item: any) => ({
              comnCd: item.comnCd,
              comnCdNm: item.comnCdNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  const getField = async () => {
    try {
      setIsLoading(true);
      await menuApi.getCd('MN03').then((res) => {
        if (res.data.rsltCd === '00') {
          setFieldList(
            res.data.ComnCd.subMenuList.map((item: any) => ({
              menuCd: item.menuCd,
              menuNm: item.menuNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const getMediaSubMenu = async () => {
    try {
      setIsLoading(true);
      await menuApi.getCd('MN05').then((res) => {
        if (res.data.rsltCd === '00') {
          setMediaSubMenuList(
            res.data.ComnCd.subMenuList.map((item: any) => ({
              menuCd: item.menuCd,
              menuNm: item.menuNm,
              viewOrder: item.viewOrder,
            })),
          );
        }
        setIsLoading(false);
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const submit = async () => {
    try {
      setIsLoading(true);
      let formData: FormData = new FormData();
      formData.append('eduId', edu.eduId);
      if (option.field && option.field !== '') {
        formData.append('field', option.field);
      }
      if (option.age && option.age !== '') {
        formData.append('age', option.age);
      }
      if (option.visible && option.visible !== '') {
        formData.append('visibleType', option.visible);
      }
      formData.append('description', option.description ? option.description : '');

      await eduApi.updateVisible(token, formData).then((res) => {
        if (res.data.rsltCd === '00') {
          makeMsg('업로드에 성공하였습니다.', 'success');
          setIsLoading(false);
          props.history.push('/registerSelect');
        } else {
          makeMsg('업로드에 실패하였습니다.', 'error');
          setIsLoading(false);
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  const deleteEdu = async () => {
    try {
      setIsLoading(true);
      await eduApi.deleteEdu(token, edu.eduId).then((res) => {
        if (res.data.rsltCd === '00') {
          makeMsg('삭제에 성공하였습니다.', 'success');
          setOption({
            edu: '',
            holder: '',
            type: '',
            field: '',
            visible: 'none',
            age: '',
            subTab: '',
            step: '',
            mediaSubMenu: '',
            description: '',
          });
          setEdu(null);
          setIsLoading(false);
          props.history.push('/registerSelect');
        } else {
          makeMsg('삭제에 실패하였습니다.', 'error');
          setIsLoading(false);
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setOption({
      edu: '',
      holder: '',
      type: '',
      field: '',
      visible: 'none',
      age: '',
      subTab: '',
      step: '',
      mediaSubMenu: '',
      description: '',
    });
    setEdu(null);
    setIsLoading(false);
    fetchData();
    getAge();
    getField();
    getMediaSubMenu();
  }, []);

  return (
    <UpdateEduPresenter
      {...props}
      isModifying={isModifying}
      setIsModifying={setIsModifying}
      submit={submit}
      ageList={ageList}
      fieldList={fieldList}
      mediaSubMenuList={mediaSubMenuList}
      option={option}
      onChangeOption={onChangeOption}
      eduList={eduList}
      holderList={holderList}
      typeList={typeList}
      edu={edu}
      getEdu={getEdu}
      deleteEdu={deleteEdu}
    />
  );
};

export default UpdateEduContainer;

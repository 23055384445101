import styled from 'styled-components';

export const Container = styled.div`
  width: 1180px;
  margin: 0 auto;
  text-align: center;
  border: none;
`;

export const PlanCreateHeaderWrapper = styled.div`
  width: 80%;
  border: none;
  border-bottom: 1px solid #0087ed;
  display: inline-block;
  flex-direction: row;
  margin-top: 10px;
`;

export const PlanCreateHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
`;

export const PlanCreateHeaderText = styled.div<{ color: string }>`
  font-size: 18px;
  color: ${(props) => props.color};
  font-weight: 600;
`;

export const PlanFileUploadWrapper = styled.div`
  width: 80%;
  display: inline-block;
  flex-direction: column;
  margin-top: 20px;
`;

export const UploadHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0087ed;
  width: 100%;

  padding: 10px 50px;

  &:first-of-type {
    border-top-left-radius: 3px;
  }
  &:last-of-type {
    border-top-right-radius: 3px;
  }
`;

export const UploadHeadTitle = styled.div`
  flex: 1;
  font-size: 15px;
  font-weight: 800;
  color: #fff;
  text-align: left;
  letter-spacing: 0.7px;
`;

export const UploadHeadInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const UploadHeadInfoImg = styled.img`
  width: 26px;
  height: 26px;
  margin-right: 5px;
  border: none;
  display: inline-block;
  object-fit: contain;
`;

export const UploadHeadInfoText = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #fff;
`;

export const FileUploadArea = styled.div<{ isDragActive: boolean }>`
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: #f2f2f2;
  opacity: ${(props) => (props.isDragActive ? 0.5 : 1)};
`;
export const FileUploadAreaText = styled.div`
  font-size: 18px;
  color: #9c9c9c;
  font-weight: 600;
`;
export const FileUploadAreaInput = styled.input``;
export const FileUploadAreaButton = styled.div``;
export const FileUploadAreaButtonIcon = styled.img`
  width: auto;
  height: auto;
  cursor: pointer;
`;

export const UploadBody = styled.div<{ isDragActive: boolean }>`
  width: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: ${(props) => (props.isDragActive ? '1px solid #0087de' : '1px solid transparent')};
  opacity: ${(props) => (props.isDragActive ? '.5' : '1')};
`;

export const UploadButton = styled.button`
  margin: 40px 0;
  border: none;
  background-color: transparent;

  transition: 0.25s all;

  &:hover {
    opacity: 0.5;
  }
`;

export const UploadButtonImg = styled.img`
  height: 30px;
  width: 100px;
`;

export const UploadFooter = styled.div`
  width: 100%;
  backgrond-color: #fff;
  border: 1px solid #e2e2e2;
  padding: 10px 0;
`;

export const PlanWriteWrapper = styled.div`
  width: 80%;
  display: inline-block;
  flex-direction: column;
  margin-top: 20px;
`;

export const WriteButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
`;

export const WriteEditButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
  background-color: transparent;

  transition: 0.25s all;

  &:hover {
    opacity: 0.5;
  }
`;

export const WriteEditButtonImg = styled.img<{ choose: boolean }>`
  height: 15px;
  width: 15px;
  display: inline-block;
  margin-right: 3px;
`;

export const WriteEditButtonText = styled.div<{ choose: boolean }>`
  font-weight: ${(props) => (props.choose ? 800 : 400)};
  font-size: ${(props) => (props.choose ? '16px' : '14px')};
  color: #4d4d4d;
`;

export const WritingBoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  border: 1px solid #e2e2e2;
  border-bottom: none;

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  width: 95%;

  margin-bottom: 40px;
`;

export const WritingBoardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  border-bottom: 1px solid #e2e2e2;
  width: 100%;

  padding: 10px 0;
`;

export const WritingBoardCheckboxBody = styled.div`
  flex: 7;
  display: flex;
  flex-direction: row;
  align-items: center;

  border: none;
  background-color: transparent;

  padding: 10px 30px;

  gap: 5%;
`;

export const WritingBoardCheckboxWrapper = styled.div`
  // flex : 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: transparent;
`;

export const WritingBoardCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  background-color: transparent;
  border: 1px solid transparent;
  margin: 0 5px 0 0;
`;

export const WritingBoardCheckboxText = styled.div`
  color: #343434;
  font-weight: 500;
  font-size: 14px;
`;

export const WritingBoardInfo = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  height: 150px;
`;

export const WritingBoardIntoText = styled.div`
  text-align: right;
  letter-spacing: 1px;
  color: #4d4d4d;
`;

export const WritingBoardInfoNotice = styled.div`
  text-align: right;
  letter-spacing: 1px;
  color: #b0b0b0;
  margin-top: 5px;
`;

export const WritingBoardTextInputWrapper = styled.div`
  flex: 7;
  display: flex;
  align-items: flex-start;
  border: none;
`;

export const WritingBoardTextInput = styled.textarea`
  resize: none;
  width: 80%;
  padding: 10px;
  height: 150px;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  ::placeholder {
    color: #e2e2e2;
  }
`;

export const PlanWriteSpecifyWrapper = styled.div`
  width: 80%;
  display: inline-block;
  margin-top: 20px;
  flex-direction: column;
`;

export const MonthlyPlanTable = styled.div`
  margin: 20px auto 0 auto;
  border: none;
  *border-collapse: collapse; /* IE7 and lower */
  border-spacing: 0;
`;

export const MonthlyPlanTableHeadRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const MonthlyPlanTableHeadHeader = styled.div`
  flex: 1;
  color: #fff;
  font-size: 15px;
  font-weight: 800;
  padding: 10px 10px;
  background-color: #0087ed;
  border-right: 1px solid #ddd;

  &:first-of-type {
    border-top-left-radius: 6px;
  }
  &:last-of-type {
    border-top-right-radius: 6px;
  }
`;

export const MonthlyPlanTableBodyWrapper = styled.div`
  border: transparent;
  border-left: 1px solid #ddd;

  margin: 0 0 30px 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GridLayoutWrapper = styled.div`
  width: 100%;
  hegiht: 100%;
`;

export const GridLayoutEach = styled.div`
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const GridLayoutEachButton = styled.button`
  border: 1px solid #ddd;
  background-color: transparent;
  padding: 4px 30px;
  border-radius: 20px;

  font-size: 14px;
  color: #4d4d4d;
  font-weight: 700;

  transition: 0.25s all;

  &:hover {
    background-color: #0087ed;
    color: #fff;
    border: 1px solid transparent;
  }
`;

export const GridLayoutTopicButton = styled.button`
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: transparent;
  padding: 5px;

  font-size: 13px;
  color: #4d4d4d;
  font-weight: 700;
`;

export const GridLayoutSubTopicButton = styled.button`
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: transparent;
  padding: 5px;

  font-size: 11px;
  color: #4d4d4d;
  font-weight: 600;
`;

export const GridLayoutSettingButtonWrapper = styled.div`
  border: none;
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 10px;
`;

export const GridLayoutSettingButton = styled.button`
  margin: 0 10px 0 0;

  border: 1px solid #0087ed;
  border-radius: 30px;

  background-color: transparent;
  color: #0087ed;

  font-size: 15px;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 4px 16px;

  cursor: pointer;

  transition: 0.25s all;

  &:hover {
    background-color: #0087ed;
    color: #fff;
  }
`;

export const FooterButtonWrapper = styled.div`
  margin: 20px 0 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FooterButtonLastData = styled.button`
  margin: 0 0 0 0;

  width: 140px;
  height: 30px;

  border: 1px solid #0087ed;
  border-radius: 40px;

  background-color: transparent;
  color: #0087ed;

  font-size: 13px;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: 0.25s all;

  &:hover {
    opacity: 0.5;
  }
`;

export const FooterSubmitButton = styled.button`
  margin: 0 0 0 0;

  width: 140px;
  height: 30px;

  border: 1px solid #0087ed;
  border-radius: 40px;

  background-color: #0087ed;
  color: #fff;

  font-size: 13px;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: 0.25s all;

  &:hover {
    opacity: 0.5;
  }
`;

export const SelectWeekLabelText = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: #7d7d7d;
  margin-right: 20px;
  cursor: pointer;
`;
export const SelectWeek = styled.input`
  display: none;
`;
export const SelectWeekLabel = styled.label`
  border: 1px solid #dfdfdf;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
`;
export const SelectWeekLabelIcon = styled.img`
  width: 60%;
  height: 60%;
`;

export const UploadContentAreaHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const UploadContentAreaHeaderLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UploadContentAreaHeaderRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UploadContentAreaHeaderIcon = styled.img`
  width: 15px;
  height: auto;
  margin: 5px;
  cursor: pointer;
`;

export const UploadContentAreaHeaderLeftText = styled.div<{ isColor: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.isColor ? '#7d7d7d' : '#F56466')};
  cursor: pointer;
`;

export const UploadContentAreaHeaderRightText = styled.div<{ isColor: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.isColor ? '#7d7d7d' : '#4CBC41')};
  cursor: pointer;
`;


export const CleanUpTableButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  padding: 10px 15px;
  background-color: #0087ED;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  margin: 10px auto;
  cursor: pointer;
  border-radius: 10px;
`;
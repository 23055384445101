import React from 'react';
import * as util from '../../util/util';

import * as s from './DocuformStyled';
import NotFound from '../../components/notFound';

import { saveAs } from 'file-saver';
interface DocuformProps {
  typeList: any;
  holderList: never[];
  selectedType: any;
  setSelectedType: React.Dispatch<any>;
  selectedHolderList: any;
  onChangeType: React.MouseEventHandler<HTMLLIElement>;
  onChangeHolderList: (item: any) => void;
  programList: any;
  setProgramId: any;
  unableDownload: React.MouseEventHandler<HTMLDivElement>;
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  pageList: never[];
  lastPageNum: number;
  isFirst: boolean;
  isLast: boolean;
}

const DocuformPresenter = (props: DocuformProps) => {
  return (
    <s.Container>
      <s.DocuformHeader>
        <s.DocuformHeaderTitleArea>
          <s.DocuformHeaderTitle>교수자료실</s.DocuformHeaderTitle>
          <s.DocuformHeaderTitleDivide>|</s.DocuformHeaderTitleDivide>
          <s.DocuformHeaderTitle>문서·서식</s.DocuformHeaderTitle>
        </s.DocuformHeaderTitleArea>
        <s.DocuformHeaderRightArea>
          <s.DocuformHeaderRightText>
            <s.DocuformHeaderRightIcon src="/static/img/home_icon.jpg" />
            {'HOME > 교수자료실 > 문서·서식'}
          </s.DocuformHeaderRightText>
        </s.DocuformHeaderRightArea>
      </s.DocuformHeader>
      <s.SearchConditionArea>
        <s.SearchConditionAgeArea>
          <s.searchConditionHeader>종류 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            <s.SearchConditionItem
              selected={props.selectedType === null}
              onClick={() => props.setSelectedType(null)}
            >
              전체
            </s.SearchConditionItem>
            {
              props.typeList.map((item: any, index: number) => (
                <s.SearchConditionItem 
                  selected={props.selectedType !== null ? item.comnCd === props.selectedType.comnCd : false}
                  onClick={() => props.onChangeType(item)}
                  >{item.comnCdNm}</s.SearchConditionItem>
              ))
            }
          </s.SearchConditionBody>
        </s.SearchConditionAgeArea>
      </s.SearchConditionArea>
      {/* <s.DocuformTabArea>
        <s.DocuformTab
          active={props.selectedType === null}
          onClick={() => props.setSelectedType(null)}
          tabType={'전체'}
        >
          전체
        </s.DocuformTab>
        {props.typeList.map((item: any, index: number) => (
          <s.DocuformTab
            key={index}
            active={props.selectedType !== null ? item.comnCd === props.selectedType.comnCd : false}
            tabType={item.comnCd}
            onClick={() => props.onChangeType(item)}
          >
            {item.comnCdNm}
          </s.DocuformTab>
        ))}
      </s.DocuformTabArea>
      <s.SelectArea>
        <s.SelectLabel>월(호) 선택</s.SelectLabel>
        <s.SelectDiv></s.SelectDiv>
        <s.HolderSelectForm>
          <s.HolderTypeItem
            onClick={() => props.onChangeHolderList(null)}
            active={props.selectedHolderList.length === 0}
          >
            전체
          </s.HolderTypeItem>
          {props.holderList.map((item: any, index: number) => (
            <s.HolderTypeItem
              key={index}
              active={props.selectedHolderList
                .map((item: any) => item.comnCd)
                .includes(item.comnCd)}
              onClick={() => props.onChangeHolderList(item)}
            >
              {item.comnCdNm}
            </s.HolderTypeItem>
          ))}
        </s.HolderSelectForm>
      </s.SelectArea> */}
      <s.ProgramListArea>
      <s.ProgramTypeTitleArea>
          <s.ProgramTypeTitlePart>{props.selectedType ? props.selectedType.comnCdNm : '전체'}</s.ProgramTypeTitlePart>
          <s.ProgramTypeTitleFixedPart>문서 서식</s.ProgramTypeTitleFixedPart>
          {props.selectedHolderList.map((item: any, index: number) => (
            <s.ProgramTypeTitlePart>{item.comnCdNm}</s.ProgramTypeTitlePart>
          ))}
          {/* {props.selectedTypeList.map((item: any, index: number) => (
            <s.ProgramTypeTitlePart>{item}</s.ProgramTypeTitlePart>
          ))} */}
          <s.ProgramSearchTextArea>
            <s.ProgramSearchText type="text" name="keyword" />
            <s.ProgramSearchButton onClick={props.unableDownload}>
              <s.ProgramSearchImg src="/static/img/lens_2.png" />
            </s.ProgramSearchButton>
          </s.ProgramSearchTextArea>
        </s.ProgramTypeTitleArea>
        <s.ProgramOptionArea>
          <s.ProgramOptionRight>
            이름순
            <s.ProgramOptionImg src={'/static/img/d_shaft.png'} />
          </s.ProgramOptionRight>
        </s.ProgramOptionArea>
        <s.ProgramList>
          {!props.programList || props.programList.length === 0 ? (
            <NotFound />
          ) : (
            props.programList.map((item: any, index: number) => (
              <s.ProgramForm key={index} idx={index}>
                <s.ProgramThumbnailArea
                  onClick={() =>
                    !item.programId
                      ? util.makeMsg('권한이 부족합니다.', 'error')
                      : props.setProgramId(item.programId)
                  }
                >
                  {item.thumbnail !== null ? (
                    <s.ProgramThumbnail imgsrc={item.thumbnail.fileUrl}>
                      <s.ProgramDarkness>
                        <s.ProgramType type={item.type.comnCd}>{item.type.comnCdNm}</s.ProgramType>
                        <s.ProgramLensImg src={'/static/img/lens.png'} />
                      </s.ProgramDarkness>
                    </s.ProgramThumbnail>
                  ) : null}
                </s.ProgramThumbnailArea>
                <s.ProgramTitle>{item.title}</s.ProgramTitle>
                <s.ProgramLinkArea>
                  <s.ProgramScrapLinkArea
                    onClick={() => util.makeMsg('준비중인 기능입니다.', 'info')}
                  >
                    <s.ProgramScrapLink>스크랩</s.ProgramScrapLink>
                  </s.ProgramScrapLinkArea>
                  <s.ProgramDownloadLinkArea>
                    {!item.programDocuments ? (
                      <s.ProgramDownloadLink
                        onClick={() => util.makeMsg('권한이 부족합니다.', 'error')}
                      >
                        다운로드
                      </s.ProgramDownloadLink>
                    ) : item.programDocuments.length !== 0 ? (
                      <s.ProgramDownloadLink
                        onClick={() =>
                          saveAs(
                            item.programDocuments[0].file.fileUrl,
                            item.programDocuments[0].file.originalFileName,
                          )
                        }
                      >
                        다운로드
                      </s.ProgramDownloadLink>
                    ) : (
                      <s.ProgramDownloadLink onClick={props.unableDownload}>
                        다운로드
                      </s.ProgramDownloadLink>
                    )}
                  </s.ProgramDownloadLinkArea>
                </s.ProgramLinkArea>
              </s.ProgramForm>
            ))
          )}
        </s.ProgramList>
      </s.ProgramListArea>
      <s.PagingNav>
        {props.isFirst ? null : (
          <s.PagingLink onClick={() => props.setPageNum(props.pageNum - 1)}>
            <s.PagingLinkImg src="/static/img/l_shaft.png" />
          </s.PagingLink>
        )}

        <s.PagingNumArea>
          {props.pageList.map((page: number, index: number) => (
            <s.PagingNum
              key={index}
              onClick={() => props.setPageNum(page)}
              current={props.pageNum === page}
            >
              {page}
            </s.PagingNum>
          ))}
        </s.PagingNumArea>
        {props.isLast ? null : (
          <s.PagingLink onClick={() => props.setPageNum(props.pageNum + 1)}>
            <s.PagingLinkImg src="/static/img/r_shaft.png" />
          </s.PagingLink>
        )}
      </s.PagingNav>
    </s.Container>
  );
};

export default DocuformPresenter;

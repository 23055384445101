import * as s from '../RegisterEduStyled';

interface SelectAgeProps {
  option: {
    holder: string;
    subMenu: string;
    type: string;
    field: string;
    visible: string;
    age: string;
  };
  onChangeOption: (optionNm: string, optionValue: string) => void;
  isModifying: boolean;
  ageList: {
    comnCd: string;
    comnCdNm: string;
    viewOrder: number;
  }[];
}

const SelectAge = (props: SelectAgeProps) => {
  return (
    <s.UploadProgramAreaHeaderSelect>
      {props.ageList &&
        props.ageList.map((age: any) => (
          <>
            <s.UploadProgramAreaSelectType
              id={age.comnCd}
              value={age.comnCd}
              defaultChecked={props.option.age === age.comnCd}
              onChange={(e: any) =>
                props.option.age === e.target.value
                  ? props.onChangeOption('age', '')
                  : props.onChangeOption('age', e.target.value)
              }
              type={'checkbox'}
              disabled={!props.isModifying}
            />
            <s.UploadProgramAreaSelectTypeLabel htmlFor={age.comnCd}>
              {props.option.age === age.comnCd ? (
                <s.UploadProgramAreaSelectTypeLabelIcon src="/static/img/check.png" />
              ) : null}
            </s.UploadProgramAreaSelectTypeLabel>
            <s.UploadProgramAreaSelectText htmlFor={age.comnCd}>
              {age.comnCdNm}
            </s.UploadProgramAreaSelectText>
          </>
        ))}
    </s.UploadProgramAreaHeaderSelect>
  );
};

export default SelectAge;

import React from 'react';
import * as util from '../../util/util';

import * as s from './GuideBookStyled';
import NotFound from '../../components/notFound';

import { saveAs } from 'file-saver';
interface GuideBookProps {
  ageList: any;
  holderList: never[];
  selectedAge: {
    comnCd: string;
    comnCdNm: string;
  };
  setSelectedAge: React.Dispatch<
    React.SetStateAction<{
      comnCd: string;
      comnCdNm: string;
    }>
  >;
  selectedHolderList: any;
  onChangeAge: React.MouseEventHandler<HTMLLIElement>;
  onChangeHolderList: (item: any) => void;
  programList: any;
  setProgramId: any;
  unableDownload: React.MouseEventHandler<HTMLDivElement>;
  pageNum: number;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  pageList: never[];
  lastPageNum: number;
  isFirst: boolean;
  isLast: boolean;
}

const GuideBookPresenter = (props: GuideBookProps) => {
  return (
    <s.Container>
      <s.ActsheetHeader>
        <s.ActsheetHeaderTitleArea>
          <s.ActsheetHeaderTitle>교수자료실</s.ActsheetHeaderTitle>
          <s.ActsheetHeaderTitleDivide>|</s.ActsheetHeaderTitleDivide>
          <s.ActsheetHeaderTitle>지침서</s.ActsheetHeaderTitle>
          {/* <s.ActsheetHeaderSubTitle>활동지와 함께하는 교육프로그램</s.ActsheetHeaderSubTitle> */}
        </s.ActsheetHeaderTitleArea>
        <s.ActsheetHeaderRightArea>
          <s.ActsheetHeaderRightText>
            <s.ActsheetHeaderRightIcon src="/static/img/home_icon.jpg" />
            {'HOME > 교수자료실 > 지침서'}
          </s.ActsheetHeaderRightText>
        </s.ActsheetHeaderRightArea>
      </s.ActsheetHeader>

      <s.SearchConditionArea>
        <s.SearchConditionAgeArea>
          <s.searchConditionHeader>연령 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            {
              props.ageList.map((item: any, index: number) => (
                <s.SearchConditionItem 
                  selected={item.comnCd === props.selectedAge.comnCd}
                  onClick={() => props.onChangeAge(item)}
                  >{'만 ' + item.comnCdNm}</s.SearchConditionItem>
              ))
            }
          </s.SearchConditionBody>
        </s.SearchConditionAgeArea>
        <s.SearchConditionMonthArea>
          <s.searchConditionHeader>월 선택</s.searchConditionHeader>
          <s.SearchConditionBody>
            <s.SearchConditionItem
              selected={props.selectedHolderList.length === 0}
              onClick={() => props.onChangeHolderList(null)}
            >
              전체
            </s.SearchConditionItem>
            {
              props.holderList.map((item: any, index: number) => (
                <s.SearchConditionItem 
                  selected={props.selectedHolderList
                    .map((item: any) => item.comnCd)
                    .includes(item.comnCd)}
                  onClick={() => props.onChangeHolderList(item)}
                  >{item.comnCdNm}</s.SearchConditionItem>
              ))
            }
          </s.SearchConditionBody>
        </s.SearchConditionMonthArea>
      </s.SearchConditionArea>

      {/* <s.ActsheetTabArea>
        {props.ageList.map((item: any, index: number) => (
          <s.ActsheetTab
            key={'age' + index}
            active={item.comnCd === props.selectedAge.comnCd}
            onClick={() => props.onChangeAge(item)}
          >
            {'만 ' + item.comnCdNm}
          </s.ActsheetTab>
        ))}
      </s.ActsheetTabArea>
      <s.SelectArea>
        <s.SelectLabel>월(호) 선택</s.SelectLabel>
        <s.SelectDiv></s.SelectDiv>
        <s.SelectForm selectType={'holder'}>
          <s.SelectItem
            onClick={() => props.onChangeHolderList(null)}
            active={props.selectedHolderList.length === 0}
            selectType={'holder'}
          >
            전체
          </s.SelectItem>
          {props.holderList.map((item: any, index: number) => (
            <s.SelectItem
              key={index}
              active={props.selectedHolderList
                .map((item: any) => item.comnCd)
                .includes(item.comnCd)}
              selectType={'holder'}
              onClick={() => props.onChangeHolderList(item)}
            >
              {item.comnCdNm}
            </s.SelectItem>
          ))}
        </s.SelectForm>
      </s.SelectArea> */}
      <s.ProgramListArea>
        <s.ProgramTypeTitleArea>
          <s.ProgramTypeTitleFixedPart>만</s.ProgramTypeTitleFixedPart>
          <s.ProgramTypeTitlePart>{props.selectedAge.comnCdNm}</s.ProgramTypeTitlePart>
          {props.selectedHolderList.map((item: any, index: number) => (
            <s.ProgramTypeTitlePart>{item.comnCdNm}</s.ProgramTypeTitlePart>
          ))}
          {/* {props.selectedTypeList.map((item: any, index: number) => (
            <s.ProgramTypeTitlePart>{item}</s.ProgramTypeTitlePart>
          ))} */}
          <s.ProgramTypeTitleFixedPart>지침서</s.ProgramTypeTitleFixedPart>
          <s.ProgramSearchTextArea>
            <s.ProgramSearchText type="text" name="keyword" />
            <s.ProgramSearchButton onClick={props.unableDownload}>
              <s.ProgramSearchImg src="/static/img/lens_2.png" />
            </s.ProgramSearchButton>
          </s.ProgramSearchTextArea>
        </s.ProgramTypeTitleArea>
        <s.ProgramOptionArea>
          <s.ProgramOptionRight>
            이름순
            <s.ProgramOptionImg src={'/static/img/d_shaft.png'} />
          </s.ProgramOptionRight>
        </s.ProgramOptionArea>
        <s.ProgramList>
          {!props.programList || props.programList.length === 0 ? (
            <NotFound />
          ) : (
            props.programList.map((item: any, index: number) => (
              <s.ProgramForm key={index} idx={index}>
                <s.ProgramThumbnailArea
                  onClick={() =>
                    !item.programId
                      ? util.makeMsg('권한이 부족합니다.', 'error')
                      : props.setProgramId(item.programId)
                  }
                >
                  {item.thumbnail !== null ? (
                    <s.ProgramThumbnail imgsrc={item.thumbnail.fileUrl}>
                      <s.ProgramDarkness>
                        <s.ProgramLensImg src={'/static/img/lens.png'} />
                      </s.ProgramDarkness>
                    </s.ProgramThumbnail>
                  ) : null}
                </s.ProgramThumbnailArea>
                <s.ProgramTitle>{item.title}</s.ProgramTitle>
                <s.ProgramLinkArea>
                  <s.ProgramScrapLinkArea
                    onClick={() => util.makeMsg('준비중인 기능입니다.', 'info')}
                  >
                    <s.ProgramScrapLink>스크랩</s.ProgramScrapLink>
                  </s.ProgramScrapLinkArea>
                  <s.ProgramDownloadLinkArea>
                    {!item.programImages ? (
                      <s.ProgramDownloadLink
                        onClick={() => util.makeMsg('권한이 부족합니다.', 'error')}
                      >
                        다운로드
                      </s.ProgramDownloadLink>
                    ) : item.programImages.length !== 0 ? (
                      <s.ProgramDownloadLink
                        onClick={() => {
                          for (let i = 0; i < item.programImages.length; i++) {
                            setTimeout(() => {
                              saveAs(item.programImages[i].file.fileUrl, item.programImages[i].file.originalFileName);
                            }, 500 * i)
                          }
                        }
                          // saveAs(
                          //   item.programImages[0].file.fileUrl,
                          //   item.programImages[0].file.originalFileName,
                          // )
                        }
                      >
                        다운로드
                      </s.ProgramDownloadLink>
                    ) : (
                      <s.ProgramDownloadLink onClick={props.unableDownload}>
                        다운로드
                      </s.ProgramDownloadLink>
                    )}
                  </s.ProgramDownloadLinkArea>
                </s.ProgramLinkArea>
              </s.ProgramForm>
            ))
          )}
        </s.ProgramList>
      </s.ProgramListArea>
      <s.PagingNav>
        {props.isFirst ? null : (
          <s.PagingLink onClick={() => props.setPageNum(props.pageNum - 1)}>
            <s.PagingLinkImg src="/static/img/l_shaft.png" />
          </s.PagingLink>
        )}

        <s.PagingNumArea>
          {props.pageList.map((page: number, index: number) => (
            <s.PagingNum
              key={index}
              onClick={() => props.setPageNum(page)}
              current={props.pageNum === page}
            >
              {page}
            </s.PagingNum>
          ))}
        </s.PagingNumArea>
        {props.isLast ? null : (
          <s.PagingLink onClick={() => props.setPageNum(props.pageNum + 1)}>
            <s.PagingLinkImg src="/static/img/r_shaft.png" />
          </s.PagingLink>
        )}
      </s.PagingNav>
    </s.Container>
  );
};

export default GuideBookPresenter;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 1380px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1180px) {
    min-width: 100%;
  }
`;

export const UserInfoArea = styled.div`
  width: 1240px;
  height: 200px;
  padding: 50px;
  display: flex;
  align-items: center;
  background-color: #dedede;
  border-bottom: 1px solid #bebebe;
  margin: 0 auto 30px;
`;

export const UserInfoLeft = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const UserIdText = styled.div`
  font-size: 16px;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const UserNmArea = styled.div`
  font-size: 20px;
  color: #4d4d4d;
  gap: 12px;
  margin: 20px 0;
  display: flex;
  align-items: flex-end;
`;

export const UserNmSpan = styled.span`
  font-size: 28px;
  font-weight: bold;
  color: #4d4d4d;
`;

export const UserInfoCenter = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
`;

export const UserInfoRight = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
`;

export const DetailRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const DetailLabel = styled.div`
  width: 30%;
  font-size: 14px;
  color: #4d4d4d;
  display: flex;
`;

export const DetailInfo = styled.div`
  width: 70%;
  font-size: 14px;
  color: #acacac;
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const DetailInfoSpan = styled.span`
  font-size: 18px;
  color: #0087ed;
  cursor: pointer;
`;

export const OrderListArea = styled.div`
  width: 1240px;

  display: flex;
  flex-direction: column;
  margin: 30px auto 100px;
`;

export const TopTitle = styled.div`
  margin: 0 0 20px;
  font-size: 28px;
  font-weight: bold;
  color: #4d4d4d;
  text-align: left;
`;

export const OrderListTable = styled.table`
  border-top: 1px solid #4d4d4d;
  border-spacing: 0px;
  border-collapse: collapse;
`;

export const OrderListTbody = styled.tbody``;

export const OrderListTr = styled.tr``;

export const OrderListTh = styled.th`
  border-bottom: 1px solid #cecece;
  font-size: 16px;
  color: #4d4d4d;
  background-color: #efefef;
  text-align: center;
  padding: 10px;
`;

export const OrderListTd = styled.td`
  border-bottom: 1px solid #cecece;
  font-size: 14px;
  color: #4d4d4d;
  text-align: center;
  padding: 10px;
`;

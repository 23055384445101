import styled from 'styled-components';

export const Container = styled.div`
  width: 1180px;
  margin: 0 auto;
  text-align: center;
`;

export const TitleArea = styled.div`
  width: 80%;
  margin: 30px auto 0;
  height: auto;
  border-bottom: 1px solid #0087ed;
  padding: 10px 0 5px;
  display: inline-block;
  text-align: left;
  vertical-align: baseline;
`;

export const Title = styled.div`
  font-size: 20px;
  display: inline-block;
  line-height: 32px;
  font-weight: 800;
`;

export const TitleSpan = styled.span`
  color: #0087ed;
`;

export const UploadContentArea = styled.div`
  padding: 20px;
`;

export const UploadContentAreaHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const UploadContentAreaHeaderLeft = styled.div`
  padding: 10px 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UploadContentAreaHeaderRight = styled.div`
  padding: 10px 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UploadContentAreaHeaderIcon = styled.img`
  width: 15px;
  height: auto;
  margin: 5px;
  cursor: pointer;
`;

export const UploadContentAreaHeaderLeftText = styled.div<{ isColor: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.isColor ? '#7d7d7d' : '#F56466')};
  cursor: pointer;
`;

export const UploadContentAreaHeaderRightText = styled.div<{ isColor: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.isColor ? '#7d7d7d' : '#4CBC41')};
  cursor: pointer;
`;

export const UploadProgramArea = styled.div`
  border: 1px solid #acacac;
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const UploadProgramAreaFooter = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid #dcdcdc;
`;

export const UploadProgramAreaHeaderSelect = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: 10px;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const UploadProgramAreaFooterTextArea = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const UploadProgramAreaInput = styled.input`
  width: 40px;
  height: 30px;
  border: 1px solid #c6c6c6;
  border-radius: 2px;
  text-align: center;
`;
export const UploadProgramAreaSelectHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #7d7d7d;
  padding: 10px;
  margin: 10px 0 0;
`;
export const UploadProgramAreaSelectDv = styled.div`
  width: 20px;
  border: 1px solid #cdcdcd;
  height: 1px;
`;
export const UploadProgramAreaSelectText = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: #7d7d7d;
  margin-right: 10px;
  cursor: pointer;
`;
export const UploadProgramAreaSelectType = styled.input`
  display: none;
`;
export const UploadProgramAreaSelectTypeLabel = styled.label`
  border: 1px solid #dfdfdf;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
`;
export const UploadProgramAreaSelectTypeLabelIcon = styled.img`
  width: 60%;
  height: 60%;
`;

export const UploadProgramAreaFileUploadArea = styled.div<{ isDrag: boolean }>`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  opacity: ${(props) => (props.isDrag ? 0.5 : 1)};
`;
export const UploadProgramAreaFileUploadAreaText = styled.div`
  font-size: 18px;
  color: #9c9c9c;
  font-weight: 600;
`;
export const UploadProgramAreaFileUploadAreaInput = styled.input``;
export const UploadProgramAreaFileUploadAreaButton = styled.div`
  padding: 10px 20px;
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
export const UploadProgramAreaFileUploadAreaButtonIcon = styled.img`
  width: auto;
  height: auto;
  cursor: pointer;
`;
export const UploadProgramAreaFileUploadAreaButtonText = styled.div``;

export const UploadProgramAreaHeader = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;
export const UploadProgramAreaHeaderLabel = styled.label`
  color: #4c4c4c;
  margin-right: 10px;
`;
export const UploadProgramAreaHeaderInput = styled.input`
  height: 30px;
  width: 400px;
  padding: 10px;
  border: 1px solid #c6c6c6;
  border-radius: 2px;
`;
export const UploadProgramAreaHeaderText = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #7d7d7d;
  margin-right: 20px;
  cursor: pointer;
`;

export const UploadArea = styled.div`
  width: 80%;
  margin: 30px auto 0;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding-bottom: 20px;
`;

export const UploadAreaHeader = styled.div`
  width: 100%;
  padding: 12px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #0087ed;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const UploadAreaHeaderLeft = styled.div`
  font-size: 18px;
  color: #fff;
  font-weight: 600;
`;

export const UploadAreaHeaderRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UploadAreaHeaderRightIcon = styled.img`
  width: 30px;
  height: auto;
  margin-right: 10px;
`;

export const UploadAreaHeaderRightText = styled.div`
  font-size: 16px;
  color: #fff;
  font-weight: 500;
`;

export const TextAreaInfo = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  height: 150px;
`;

export const TextAreaIntoFileName = styled.div`
  text-align: right;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  color: #7a7a7a;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const TextAreaIntoText = styled.div`
  text-align: right;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  color: #7d7d7d;
`;

export const TextAreaInfoNotice = styled.div`
  text-align: right;
  letter-spacing: 1px;
  color: #b0b0b0;
  margin-top: 5px;
`;

export const TextAreaTextInputWrapper = styled.div`
  flex: 10;
  display: flex;
  align-items: flex-start;
  border: none;
`;

export const TextAreaTextInput = styled.textarea`
  resize: none;
  width: 85%;
  padding: 10px;
  height: 150px;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  ::placeholder {
    color: #e2e2e2;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const SubmitButton = styled.div`
  background-color: #0087ed;
  padding: 10px 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border-radius: 30px;
  cursor: pointer;
`;
export const SubmitButtonIcon = styled.img``;
export const SubmitButtonText = styled.div`
  color: #fff;
  font-size: 15px;
  font-weight: 600;
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 50%;
  height: 520px;
  z-index: 99;
  position: absolute;
  top: 50px;
  left: 25%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: #fff;

  border: 0.8px solid #ff9043;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

export const TitleWrapper = styled.div`
  font-size: 20px;
  font-weight: 800;
  color: #4d4d4d;

  margin: 0 0 20px 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 80%;
`;

export const EachContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 0;
`;

export const EachContentButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  margin: 0 0 10px 0;
`;

export const EachContentButton = styled.button`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  border: none;
  background-color: transparent;

  color: #ff9043;

  transition: 0.25s all;

  padding: 5px;

  &:hover {
    color: #ff9043;
  }
`;

export const EachContentButtonImg = styled.img`
  height: 20px;
  width: 20px;

  margin: 0 5px 0 0;
`;

export const EachContentButtonText = styled.div`
  font-weight: 700;
`;

export const ColorChooseWrapper = styled.div`
  display: flex;
  flex-direction: row;

  padding: 3px 0;
  border: none;
`;

export const ColorChooseMsg = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: #a0a0a0;
`;

export const ColorButton = styled.button<{ color: string; isChoose: boolean }>`
  border: ${(props) => (props.isChoose ? '2px solid #ff9043' : '2px solid transparent')};
  height: 12px;
  width: 12px;
  border-radius: 100px;

  background-color: ${(props) => props.color};

  margin: 0 0 0 10px;
`;

export const EachContentSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ff9043;
  padding: 5px;

  justify-content: space-between;
`;

export const EachContentSearchBar = styled.input`
  border: none;
  width: 80%;
`;

export const EachContentSearchButton = styled.button`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 5px;

  border: none;
  background-color: transparent;

  transition: 0.25s all;

  &:hover {
    color: #ff9043;
  }
`;

export const EachContentSearchButtonImg = styled.img`
  height: 18px;
  width: 18px;

  margin: 0 5px 0 0;
`;

export const EachContentSearchButtonText = styled.div`
  font-size: 13px;
`;

export const SelectActivityWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin: 20px 0 0 0;

  height: 40px;
  padding: 1px 0px;

  align-items: center;
`;

export const SelectActivityInfoText = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
`;

export const SelectActivityListWrapper = styled.div`
  overflow-x: auto;

  height: 100%;
  flex: 4.5;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  color: #a0a0a0;
  font-size: 10px;

  &::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff9043;
  }
`;

export const AcitivityWrapper = styled.div`
  background-color: #f2f2f2;
  border: none;
  border-radius: 10px;

  min-width: 110px;

  margin: 0 0 0 5px;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  padding: 3px 10px;
`;

export const ActivityTitle = styled.div`
  color: #a0a0a0;
  font-weight: 700;
`;

export const ActivityDeleteButton = styled.button`
  display: flex;

  justify-content: center;
  align-items: center;
  pading: 1px;

  border: none;
  background-color: transparent;
  margin: 0 0 0 3px;
`;

export const ActivityDeleteButtonImg = styled.img`
  height: 10px;
  width: 10px;
`;

export const ResultWrapper = styled.div`
  overflow: scroll;
  margin: 10px 0 0 0;

  border: 1px solid #dedede;
  border-radius: 3px;
  height: 240px;

  &::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff9043;
  }
`;

export const NoResult = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  margin: 0 0 0 3px;

  height: 100%;

  color: #a0a0a0;
`;

export const ResultEachItemWrapper = styled.div`
  margin: 0 0 0 3px;
  display: flex;
  flex-direction: row;

  align-items: center;

  border: none;
  border-bottom: 1px solid #ddd;
  background-color: transparent;

  height: 30px;
  width: 100%;

  &:last-of-type {
    border-right: none;
  }
`;

export const ResultSeperatorLine = styled.div`
  height: 30px;
  border: none;
  border-right: 1px solid #ddd;
`;

export const ResultItemInfoNm = styled.div`
  flex: 1;
  margin: 0px 15px;

  font-size: 13px;
  font-weight: 500;
`;

export const ResultItemButton = styled.button`
  border: none;
  background-color: transparent;
  margin: 0 4px;

  font-size: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.25s all;

  &:hover {
    opacity: 0.5;
  }
`;

export const ResultItemButtonImg = styled.img`
  height: 20px;
  width: 20px;
`;

export const ResultTopicWrapper = styled.button<{ isSelect: boolean }>`
  margin: 0 0 0 3px;
  display: flex;
  flex-direction: row;

  align-items: center;

  border: none;
  border-bottom: 1px solid #ddd;
  background-color: ${(props) => (props.isSelect ? '#ff9043' : 'transparent')};
  color: ${(props) => (props.isSelect ? '#fff' : '#000')};

  height: 30px;
  width: 100%;

  transition: 0.25s all;

  &:hover {
    color: #fff;
    background-color: #ff9043;
  }

  &:last-of-type {
    border-right: none;
  }
`;

export const BigButtonWrapper = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

export const BigButton = styled.button<{ isCloseButton: boolean }>`
  height: 40px;
  width: 90px;

  border-radius: 3px;

  border: 1px solid ${(props) => (props.isCloseButton ? '#ff9043' : 'transparent')};
  background-color: ${(props) => (props.isCloseButton ? 'transparent' : '#ff9043')};
  color: ${(props) => (props.isCloseButton ? '#ff9043' : '#fff')};

  margin: 0 5px;

  transition: 0.25s all;
`;

export const BigButtonText = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

import React from 'react';
import * as s from './RegisterStyled';
import { Link } from 'react-router-dom';

interface RegisterProps {
  onChangeId: React.ChangeEventHandler<HTMLInputElement>;
  onChangePw: React.ChangeEventHandler<HTMLInputElement>;
  onChangePwConf: React.ChangeEventHandler<HTMLInputElement>;
  onChangeName: React.ChangeEventHandler<HTMLInputElement>;
  agenNm: string;
  compNm: string;
  onChangeAgenNm: React.ChangeEventHandler<HTMLInputElement>;
  onChangeAgenAddr: React.ChangeEventHandler<HTMLInputElement>;
  onChangeAgenContact: React.ChangeEventHandler<HTMLInputElement>;
  onChangeCompCd: React.ChangeEventHandler<HTMLInputElement>;
  onChangeCompNm: React.ChangeEventHandler<HTMLInputElement>;
  onChangeCompAddr: React.ChangeEventHandler<HTMLInputElement>;
  checkHandler: any;
  setAgenId: any;
  selectAgency: any;
  setCompId: any;
  selectCompany: any;
  onChangeKeyWord: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress: React.KeyboardEventHandler<HTMLInputElement>;
  onSearchKeyPress: React.KeyboardEventHandler<HTMLInputElement>;
  onAddKeyPress: React.KeyboardEventHandler<HTMLInputElement>;
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
  onSearchSubmit: React.MouseEventHandler<HTMLDivElement>;
  onAddSubmit: React.MouseEventHandler<HTMLButtonElement>;
  userType: string;
  onClickAdmin: React.ChangeEventHandler<HTMLInputElement>;
  onClickUser: React.ChangeEventHandler<HTMLInputElement>;
  openSearchModal: any;
  closeSearchModal: React.MouseEventHandler<HTMLDivElement>;
  openAddModal: React.MouseEventHandler<HTMLButtonElement>;
  closeAddModal: React.MouseEventHandler<HTMLDivElement>;
  switchModal: React.MouseEventHandler<HTMLButtonElement>;
  refreshCompanyList: React.MouseEventHandler<HTMLDivElement>;
  isSearchModalOpen: boolean;
  isAddModalOpen: boolean;
  setKeyWordForSearch: any;
  agenList: any;
  compList: any;
  authList: any;
  setAuthList: any;
  pageNum: number;
  setPageNum: any;
  pageList: Array<number>;
  isFirst: boolean;
  isLast: boolean;
  lastPageNum: number;
  totalItemLength: number;
  addAuthList: React.MouseEventHandler<HTMLDivElement>;
  userList: string[];
}

const RegisterPresenter = (props: RegisterProps) => {
  return (
    <s.Container imgsrc={'/static/img/login_back.png'}>
      {props.isSearchModalOpen ? (
        <s.ModalBackGround>
          <s.ModalForm>
            <s.ModalHeader>
              <s.ModalHeaderText>지점 검색</s.ModalHeaderText>
              <s.ModalExitArea onClick={props.closeSearchModal}>
                <s.ModalExitImg src="/static/img/exit-icon.png"></s.ModalExitImg>
              </s.ModalExitArea>
            </s.ModalHeader>
            <s.ModalNavBar>
              <s.ModalNavSearchArea>
                <s.ModalNavRefresh onClick={props.refreshCompanyList}>
                  <s.ModalNavSearchImg src="/static/img/refresh-icon.png" />
                </s.ModalNavRefresh>
                <s.ModalNavSearchTextArea>
                  <s.ModalNavSearchText
                    type="text"
                    name="keyword"
                    placeholder="검색어를 입력하세요"
                    onChange={props.onChangeKeyWord}
                    onKeyPress={props.onSearchKeyPress}
                  />
                  <s.ModalNavSearchButton onClick={props.onSearchSubmit}>
                    <s.ModalNavSearchImg src="/static/img/search-icon.png" />
                  </s.ModalNavSearchButton>
                </s.ModalNavSearchTextArea>
              </s.ModalNavSearchArea>
              <s.ModalAddButtonArea>
                {props.userType === 'ADMIN' ? (
                  <s.ModalAddCompanyButton onClick={props.switchModal}>
                    지점 추가
                  </s.ModalAddCompanyButton>
                ) : null}
              </s.ModalAddButtonArea>
            </s.ModalNavBar>
            <s.ModalListArea>
              <s.ModalColumnArea>
                <s.ModalListItemName>코드</s.ModalListItemName>
                <s.ModalListItemAddr>이름</s.ModalListItemAddr>
                <s.ModalListItemContact>주소</s.ModalListItemContact>
              </s.ModalColumnArea>
              <s.ModalContentsList>
                {props.compList.map((item: any, index: number) => (
                  <s.ModalListItem
                    key={index}
                    onClick={() => props.selectCompany(item.compId, item.compNm)}
                  >
                    <s.ModalListItemName>{item.compCd}</s.ModalListItemName>
                    <s.ModalListItemAddr>{item.compNm}</s.ModalListItemAddr>
                    <s.ModalListItemContact>{item.compAddr}</s.ModalListItemContact>
                  </s.ModalListItem>
                ))}
              </s.ModalContentsList>
              <s.ModalPagingNav>
                <s.ModalPagingLink onClick={() => props.setPageNum(1)}>
                  <s.ModalPagingLinkImg src="/static/img/double_prev.png" />
                </s.ModalPagingLink>
                <s.ModalPagingLink onClick={() => props.setPageNum(props.pageNum - 1)}>
                  <s.ModalPagingLinkImg src="/static/img/single_prev.png" />
                </s.ModalPagingLink>
                <s.ModalPagingNumArea>
                  {props.pageList.map((page: number, index: number) => (
                    <s.ModalPagingNum
                      key={index}
                      onClick={() => props.setPageNum(page)}
                      current={props.pageNum === page}
                    >
                      {page}
                    </s.ModalPagingNum>
                  ))}
                </s.ModalPagingNumArea>
                <s.ModalPagingLink onClick={() => props.setPageNum(props.pageNum + 1)}>
                  <s.ModalPagingLinkImg src="/static/img/single_next.png" />
                </s.ModalPagingLink>
                <s.ModalPagingLink onClick={() => props.setPageNum(props.lastPageNum)}>
                  <s.ModalPagingLinkImg src="/static/img/double_next.png" />
                </s.ModalPagingLink>
              </s.ModalPagingNav>
            </s.ModalListArea>
          </s.ModalForm>
        </s.ModalBackGround>
      ) : props.isAddModalOpen ? (
        <s.ModalBackGround>
          <s.ModalForm>
            <s.ModalHeader>
              <s.ModalHeaderText>지점 추가</s.ModalHeaderText>
            </s.ModalHeader>
            <s.ModalExitArea onClick={props.closeAddModal}>
              <s.ModalExitImg src="/static/img/exit-icon.png"></s.ModalExitImg>
            </s.ModalExitArea>
            <s.ModalNavBar>
              <s.ModalAddButtonArea>
                <s.ModalAddCompanyButton onClick={props.switchModal}>
                  지점 검색
                </s.ModalAddCompanyButton>
              </s.ModalAddButtonArea>
            </s.ModalNavBar>
            <s.ModalListArea>
              <s.ModalAddCompanyRow>
                <s.ModalAddCompanyTitle>지점 코드</s.ModalAddCompanyTitle>
                <s.ModalAddCompanyTextInput
                  type="text"
                  name="compCd"
                  onChange={props.onChangeCompCd}
                  onKeyPress={props.onAddKeyPress}
                />
              </s.ModalAddCompanyRow>
              <s.ModalAddCompanyRow>
                <s.ModalAddCompanyTitle>지점 이름</s.ModalAddCompanyTitle>
                <s.ModalAddCompanyTextInput
                  type="text"
                  name="compNm"
                  onChange={props.onChangeCompNm}
                  onKeyPress={props.onAddKeyPress}
                />
              </s.ModalAddCompanyRow>
              <s.ModalAddCompanyRow>
                <s.ModalAddCompanyTitle>지점 주소</s.ModalAddCompanyTitle>
                <s.ModalAddCompanyTextInput
                  type="text"
                  name="compAddr"
                  onChange={props.onChangeCompAddr}
                  onKeyPress={props.onAddKeyPress}
                />
              </s.ModalAddCompanyRow>
              <s.ModalAddCompanyCfBtnArea>
                <s.ModalAddCompanyConfirmBtn onClick={props.onAddSubmit}>
                  확 인
                </s.ModalAddCompanyConfirmBtn>
              </s.ModalAddCompanyCfBtnArea>
            </s.ModalListArea>
          </s.ModalForm>
        </s.ModalBackGround>
      ) : null}
      {props.userList.length > 0 ? (
        <s.RegisterContentArea>
          <s.RegisterContent>
            <Link to="/">
              <s.LogoImg alt="Logo" src="/static/img/logo.png" />
            </Link>
            <s.ResultContentArea>
              <s.ResultContent>
                <s.ResultContentItem>
                  <s.ResultContentItemId>ID</s.ResultContentItemId>
                  <s.ResultContentItemPw>PW</s.ResultContentItemPw>
                </s.ResultContentItem>
                {props.userList.map((user) => (
                  <s.ResultContentItem>
                    <s.ResultContentItemId>{user}</s.ResultContentItemId>
                    <s.ResultContentItemPw>{user}!</s.ResultContentItemPw>
                  </s.ResultContentItem>
                ))}
              </s.ResultContent>
            </s.ResultContentArea>
          </s.RegisterContent>
        </s.RegisterContentArea>
      ) : (
        <s.RegisterContentArea>
          <s.RegisterContent>
            <Link to="/">
              <s.LogoImg alt="Logo" src="/static/img/logo.png" />
            </Link>
            <s.RegisterRow>
              <s.RegisterTitle>유저 타입</s.RegisterTitle>
              <s.RegisterRadioContent>
                <s.RegisterRadioInput
                  type="radio"
                  name="select"
                  id="admin"
                  onChange={props.onClickAdmin}
                />
                <s.RegisterRadioInput
                  type="radio"
                  name="select"
                  id="user"
                  onChange={props.onClickUser}
                />
                <s.RegisterRadioLabel1
                  htmlFor="admin"
                  className="usertype-admin"
                  userType={props.userType}
                >
                  <s.RegisterRadioDot1 userType={props.userType}></s.RegisterRadioDot1>
                  <s.RegisterRadioText1 userType={props.userType}>관리자</s.RegisterRadioText1>
                </s.RegisterRadioLabel1>
                <s.RegisterRadioLabel2
                  htmlFor="user"
                  className="usertype-user"
                  userType={props.userType}
                >
                  <s.RegisterRadioDot2 userType={props.userType}></s.RegisterRadioDot2>
                  <s.RegisterRadioText2 userType={props.userType}>사용자</s.RegisterRadioText2>
                </s.RegisterRadioLabel2>
              </s.RegisterRadioContent>
            </s.RegisterRow>
            <s.RegisterRow>
              <s.RegisterTitle>지점 선택</s.RegisterTitle>
              <s.RegisterCompanyContent onClick={props.openSearchModal}>
                <s.RegisterTextInput
                  type="text"
                  name="id"
                  placeholder={props.compNm}
                  onChange={props.onChangeId}
                  onKeyPress={props.onKeyPress}
                  disabled
                />
              </s.RegisterCompanyContent>
            </s.RegisterRow>
            {props.userType === 'USER' && props.compNm !== '' ? (
              <s.RegisterRow>
                <s.RegisterTitle>권한 선택</s.RegisterTitle>
                <s.AuthListArea>
                  <s.AuthColumnArea>
                    <s.AuthListItemDetailArea>1세</s.AuthListItemDetailArea>
                    <s.AuthListItemDetailArea>2세</s.AuthListItemDetailArea>
                    <s.AuthListItemDetailArea>3세</s.AuthListItemDetailArea>
                    <s.AuthListItemDetailArea>4세</s.AuthListItemDetailArea>
                    <s.AuthListItemDetailArea>5세</s.AuthListItemDetailArea>
                    <s.AuthListItemSizeArea>유저 수</s.AuthListItemSizeArea>
                    <s.AuthEmptyArea></s.AuthEmptyArea>
                  </s.AuthColumnArea>
                  <s.AuthContentsList>
                    {props.authList.map((item: any, index: number) => (
                      <s.AuthListItem key={index}>
                        <s.AuthCheckInput
                          type="checkbox"
                          id={'age1' + index}
                          onChange={() => {
                            let temps = [...props.authList];
                            temps[index].age1 = !temps[index].age1;
                            props.setAuthList(temps);
                          }}
                        />
                        <s.AuthCheckInput
                          type="checkbox"
                          id={'age2' + index}
                          onChange={() => {
                            let temps = [...props.authList];
                            temps[index].age2 = !temps[index].age2;
                            props.setAuthList(temps);
                          }}
                        />
                        <s.AuthCheckInput
                          type="checkbox"
                          id={'age3' + index}
                          onChange={() => {
                            let temps = [...props.authList];
                            temps[index].age3 = !temps[index].age3;
                            props.setAuthList(temps);
                          }}
                        />
                        <s.AuthCheckInput
                          type="checkbox"
                          id={'age4' + index}
                          onChange={() => {
                            let temps = [...props.authList];
                            temps[index].age4 = !temps[index].age4;
                            props.setAuthList(temps);
                          }}
                        />
                        <s.AuthCheckInput
                          type="checkbox"
                          id={'age5' + index}
                          onChange={() => {
                            let temps = [...props.authList];
                            temps[index].age5 = !temps[index].age5;
                            props.setAuthList(temps);
                          }}
                        />
                        <s.AuthCheckLabel htmlFor={'age1' + index}>
                          <s.AuthCheckBox
                            className="authcheck-age1"
                            checked={item.age1}
                          ></s.AuthCheckBox>
                        </s.AuthCheckLabel>
                        <s.AuthCheckLabel htmlFor={'age2' + index}>
                          <s.AuthCheckBox
                            className="authcheck-age2"
                            checked={item.age2}
                          ></s.AuthCheckBox>
                        </s.AuthCheckLabel>
                        <s.AuthCheckLabel htmlFor={'age3' + index}>
                          <s.AuthCheckBox
                            className="authcheck-age3"
                            checked={item.age3}
                          ></s.AuthCheckBox>
                        </s.AuthCheckLabel>
                        <s.AuthCheckLabel htmlFor={'age4' + index}>
                          <s.AuthCheckBox
                            className="authcheck-age4"
                            checked={item.age4}
                          ></s.AuthCheckBox>
                        </s.AuthCheckLabel>
                        <s.AuthCheckLabel htmlFor={'age5' + index}>
                          <s.AuthCheckBox
                            className="authcheck-age5"
                            checked={item.age5}
                          ></s.AuthCheckBox>
                        </s.AuthCheckLabel>
                        <s.AuthSizeInputLabel>
                          <s.AuthSizeInput
                            type="number"
                            min="0"
                            max="99"
                            id="size"
                            onChange={(e) => {
                              let temps = [...props.authList];
                              temps[index].userSize = e.target.value;
                              props.setAuthList(temps);
                            }}
                          />
                        </s.AuthSizeInputLabel>
                        <s.AuthListButtonLabel>
                          {props.authList.length !== 1 ? (
                            <s.AuthListDeleteButton
                              onClick={() => {
                                let temps = [...props.authList];
                                temps.splice(index, 1);
                                props.setAuthList(temps);
                              }}
                            >
                              -
                            </s.AuthListDeleteButton>
                          ) : null}
                        </s.AuthListButtonLabel>
                        <s.AuthListButtonLabel>
                          {props.authList.length === index + 1 ? (
                            <s.AuthListAddButton onClick={props.addAuthList}>+</s.AuthListAddButton>
                          ) : null}
                        </s.AuthListButtonLabel>
                      </s.AuthListItem>
                    ))}
                  </s.AuthContentsList>
                </s.AuthListArea>
              </s.RegisterRow>
            ) : null}

            <s.RegisterSubmit>
              <s.RegisterConfirmButton onClick={props.onSubmit}>회원 가입</s.RegisterConfirmButton>
            </s.RegisterSubmit>
          </s.RegisterContent>
        </s.RegisterContentArea>
      )}
    </s.Container>
  );
};

export default RegisterPresenter;
